import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './StudentLessonViewScreen.scss';
import { Unit } from '../../models/Unit';
import { useHistory, useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import LoadingIndicator from '../../components/LoadingIndicator';

const StudentUnitViewScreen = () => {
  const lessonId = window.location.pathname.split('/')[5];
  const [units, setUnits] = useState<Unit[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams() as { subjectId: string; lessonId: string };
  const store = useFirestore();

  React.useEffect(() => {
    setLoading(true);
    store
      .collection('units')
      .where('lessonId', '==', lessonId)
      .get()
      .then((val) => {
        const data: Unit[] = [];
        val.docs.forEach((d) => {
          data.push({ id: d.id, ...d.data() } as Unit);
        });
        setLoading(false);
        setUnits(data);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId]);

  return (
    <Container style={{ marginTop: '4rem' }} fluid>
      {loading ? <LoadingIndicator /> : <></>}
      <div style={{ height: 'calc(100vh - 155px)', overflowY: 'auto', overflowX: 'hidden' }}>
        {units.map((unit, idx) => (
          <Row style={{ marginBottom: '1.5rem' }} key={idx}>
            <Col>
              <div
                className="stu-units-card"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(
                    `/my-subjects/subject/${params.subjectId}/Lessons/${params.lessonId}/My Units/${unit.id}/Content`
                  );
                }}
              >
                <img className="stu-units-card-img" alt="lesson cover" src={unit.coverImage} />
                <div className="stu-units-content">
                  <h4 className="stu-units-content-title">{unit.name}</h4>
                  <p className="stu-units-content-text">{unit.description}</p>
                  {/* <div className="stu-units-content-progressbar-container">
                    <ProgressBar
                      className="stu-units-content-progressbar"
                      variant="success"
                      now={unit.percentage || 0}
                    />
                    <p className="stu-units-content-progressbar-text">
                      {unit.percentage || 0}% Complete
                    </p>
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </Container>
  );
};

export default StudentUnitViewScreen;
