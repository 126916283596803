import firebase from 'firebase';
import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {Label} from '../models/Label';

export interface LabelContext {
  labelList: Label[];
  setLabelList: (list: Label[]) => void;
}

interface State {
  labelList: Label[];
}

const initialContext: LabelContext = {
  labelList: [],
  setLabelList: (list: Label[]) => {},
};

const initialState: State = {
  labelList: [],
};

const Context = createContext<LabelContext>(initialContext);

export const LabelProvider: FC = ({children}) => {
  const [state, setState] = useState(initialState);
  const setLabelList = (list: Label[]) => {
    setState((pS) => ({
      ...pS,
      labelList: list,
    }));
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection('labels')
      .get()
      .then((data) => {
        let listOfLabels: Label[] = [];

        data.docs.forEach((item) => {
          listOfLabels.push({id: item.id, ...item.data()} as Label);
        });

        setLabelList(listOfLabels);
      })
      .catch(() => {
        console.log('ERROR');
      });
  }, []);

  return (
    <Context.Provider
      value={{
        ...state,
        setLabelList,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useLabels = (): LabelContext => useContext(Context);
