import React, {FC} from 'react';
import {PaperInfoData} from '../interfaces';
import {Paper} from '../models/Paper';
import {getAbbrivation} from '../utils';
import './ExamPapersPopUp.scss';

interface Props {
  paper: Paper;
  setPapersInfoDataHandler: (value: PaperInfoData | null) => void;
  onClose: () => void;
  examId:string;
}

const ExamPapersPopUp: FC<Props> = (props) => {
  return (
    <div className="grading-papers-container">
      <div
        className="paper-content"
        onClick={() => {
          props.setPapersInfoDataHandler({
            paperId: props.paper?.id || '',
            paperTitle: props.paper?.paperTitle,
            uid: '',
            examId:props.examId,
            index:0,
            paperType: props.paper?.paperType,
          });
          props.onClose();
        }}
      >
        <div className="paper-type-tag">
          <div className="paper-type-name">{getAbbrivation(props.paper.paperType)}</div>
        </div>
        <div className="grading-paper-name">{props.paper?.paperTitle}</div>
      </div>
      <hr />
    </div>
  );
};

export default ExamPapersPopUp;
