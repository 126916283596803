import React, {FC} from 'react';
import './SwitchBtn.scss';

interface Props {
  changeHandler: (event: any) => void;
  checked: boolean | undefined;
  id?: number;
  loading?: boolean;
  lableClassName?: string;
  showOutLine?: boolean;
  className?: string;
  disabled?: boolean;
  notClickabled?: boolean;
}

const SwitchBtn: FC<Props> = (props) => {
  return (
    <div
      className={`switch-btn-container ${props.className}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <input
        type="checkbox"
        id={`switch${props.id}`}
        name="theme"
        onChange={(e) => {
          if (!props.loading && !props.disabled && !props.notClickabled) {
            props.changeHandler(e.target.checked);

            e.stopPropagation();
          }
        }}
        checked={props.checked}
        disabled={props.loading || props.disabled}
        className={props.loading ? 'disabled-input' : ''}
      />
      <label
        htmlFor={`switch${props.id}`}
        className={`${props.loading ? 'mb-0 disabled-switch' : 'mb-0'} ${props.lableClassName} ${
          props.showOutLine ? 'outline' : ''
        }`}
      ></label>
    </div>
  );
};

export default SwitchBtn;
