import PrimaryNav from '../../components/PrimaryNav';
import UnitSecondaryNav from '../../components/UnitSecondaryNav';
import LayoutNew from '../../layouts/LayoutNew';
import BreadCrumbUpdate from '../../shared/BreadCrumbUpdate';

const AdminLessonUnitScreen = () => {
  const primaryNavigation = <PrimaryNav />;
  const secondaryNavigation = <UnitSecondaryNav />;
  const appBody = <Content />;
  const appHeader = <BreadCrumbUpdate />;

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

const Content = () => {
  return <div>
    <div>
      <div>Content</div>
      <div>
        <div></div>
        <div><button>Add Content</button></div>
      </div>
    </div>
    <div></div>
  </div>;
};



// const UnitContentCard = ()=>{
//   return <div>
//     <div></div>
//     <div>
//       <div></div>
//       <div></div>
//     </div>
//   </div>
// }

export default AdminLessonUnitScreen;
