import React, {FC, useState} from 'react';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import {titleCase} from 'title-case';
import './PasswordInput.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  stateName: string;
  stateValue: string;
  state: any;
  setState: Function;
  noEye?: boolean;
  error?: string;
  placeHolder?: string;
  onKeyPress?: (evt: any) => void;
  errorStyles?: React.CSSProperties;
  noErrorText?: boolean;
}

const PasswordInput: FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputChangeHandler = (state: any, name: string, value: string) => {
    props.setState({
      ...state,
      [name]: {...state[name], value: value},
    });
  };

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: {...state[name], error: ''},
    });
  };

  const displayPassword = () => {
    setShowPassword(!showPassword);
  };

  let errorJSX = null;

  if (props.error !== 'NO-ERROR') {
    errorJSX = <div className={`w-100 err-styles`}>{props.error}</div>;
  }

  let borderColor =
    props.error === 'NO-ERROR'
      ? {borderBottom: '1px solid green'}
      : props.error === ''
      ? {}
      : {borderBottom: '1px solid #dc3545'};

  return (
    <div className={`w-100 ${props.className}`}>
      <div className="passwordinput" style={props.style}>
        {(props.stateName === 'password' || props.stateName === 'confirmPassword') &&
        showPassword ? (
          //this condition was used to align text with the bottom border of the input
          <input
            type="text"
            name="none"
            autoComplete="off"
            required
            onChange={(e: any) => {
              inputChangeHandler(props.state, props.stateName, e.target.value);
            }}
            value={props.stateValue}
            onFocus={() => {
              resetError(props.state, props.stateName);
            }}
            onKeyPress={props.onKeyPress}
          />
        ) : (
          <input
            type="password"
            name={props.stateName}
            autoComplete="off"
            required
            onChange={(e: any) => {
              inputChangeHandler(props.state, props.stateName, e.target.value);
            }}
            value={props.stateValue}
            onFocus={() => {
              resetError(props.state, props.stateName);
            }}
            onKeyPress={props.onKeyPress}
          />
        )}

        <label htmlFor={props.stateName} className="passwordinput-label" style={borderColor}>
          <span className="passwordinput-label-text">
            {props.placeHolder ? titleCase(props.placeHolder) : titleCase(props.stateName)}
          </span>
        </label>

        {!props.noEye &&
        (props.stateName === 'password' || props.stateName === 'confirmPassword') ? (
          showPassword ? (
            <FaEyeSlash
              color="#246BFD"
              size={13}
              className="eye"
              onClick={displayPassword}
              style={{ position: 'absolute', right: 0, top: '1.2rem' }}
            />
          ) : (
            <FaEye
              color="#246BFD"
              size={13}
              className="eye"
              onClick={displayPassword}
              style={{ position: 'absolute', right: 0, top: '1.2rem' }}
            />
          )
        ) : (
          <></>
        )}
      </div>

      {!props.noErrorText && errorJSX}
    </div>
  );
};

export default PasswordInput;
