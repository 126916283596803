import React, {FC, Fragment} from 'react';
import {FaPlusCircle} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import {useLessons} from '../context/LessonsContext';
import BreadCrumbUpdate from '../shared/BreadCrumbUpdate';
import Button from '../shared/Button';
import './LessonDirectiveHeader.scss';
import LoadingIndicator from './LoadingIndicator';

interface Props {
  isLoading?: boolean;
  isOpenUnitModel?: boolean;
  constainsUnits?: boolean;
  unitModelHandler?: (value: boolean) => void;
}

const LessonDirectiveHeader: FC<Props> = (props) => {
  const params = useParams() as {lessonId: string};

  const appLessons = useLessons();

  const lessons = appLessons.lessons.find((l) => l.id === params.lessonId)?.isLessonContainsUnits;

  //Get the selected tab from the url
  const selectedTab = window.location.href.split('/').pop();

  return (
    <Fragment>
      <div className="ld-header">
        <BreadCrumbUpdate />
        {lessons && selectedTab === 'My%20Units' ? (
          <Button
            style={{
              width: '158px',
              height: '36px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            text="Add Unit"
            icon={{icon: FaPlusCircle, isLeft: true, size: 16, gap: 8}}
            onClick={() => {
              props.unitModelHandler && props.unitModelHandler(true);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      {props.isLoading ? <LoadingIndicator /> : null}
    </Fragment>
  );
};

export default LessonDirectiveHeader;
