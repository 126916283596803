import React, {FC, useEffect, useState} from 'react';
import './ExamInfoPill.scss';

interface Props {
  className?: string;
  type: 'DURATION' | 'Q-COUNT' | 'START-TIME' | 'END-TIME';
  count?: number;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  duration?: {days: number; hours: number; minutes: number; seconds: number};
}

const ExamInfoPill: FC<Props> = (props) => {
  // console.log('duration ', Math.round(props.duration || 0 - Math.round(props.duration || 0)) * 60);

  const [durationState, setDurationState] = useState<any[]>([]);

  useEffect(() => {
    const JSXArray: any[] = [];

    for (var key of Object.keys(props?.duration || '')) {
      const k = key as 'days' | 'hours' | 'minutes' | 'seconds';

      if (props?.duration) {
        if (props.duration[k]) {
          let text = k;

          if (props.duration[k] === 1) {
            text = k.substring(0, k.length - 1) as any;
          }

          JSXArray.push(
            <div style={{marginTop: 3}} key={k}>
              {props.duration[k]} {text}
            </div>
          );
        }
      }
    }

    setDurationState(JSXArray);
  }, [props.duration]);

  //LEGACY DATA SUPPORT

  return (
    <div className={`exam-info-pill ${props.className}`}>
      <div className={props.type === 'Q-COUNT' ? 'exam-info-pill-count' : 'd-none'}>
        {props.count ?? 0} <span className="ml-2">Questions</span>
      </div>
      <div className={props.type === 'START-TIME' ? 'exam-info-pill-time' : 'd-none'}>
        <div>Start</div>
        <div>{props.startDate}</div>
        <div>{props.startTime}</div>
      </div>
      <div className={props.type === 'END-TIME' ? 'exam-info-pill-time' : 'd-none'}>
        <div>End</div>
        <div>{props.endDate}</div>
        <div>{props.endTime}</div>
      </div>
      <div className={props.type === 'DURATION' ? 'exam-info-pill-duration' : 'd-none'}>
        <div>Duration</div>

        {durationState?.map((value) => {
          return value;
        })}
      </div>
    </div>
  );
};

export default ExamInfoPill;
