import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { FaAngleLeft, FaAngleRight, FaFilePdf } from 'react-icons/fa';
import './StudentPaperResultScreen.scss';
import throttle from 'lodash/throttle';
import StudentQuestionResultsHeader from '../../components/StudentQuestionResultsHeader';
import { PaperTypes } from '../../enums/paperTypes';
import { useSubjects } from '../../context/SubjectsContext';
import { useLocation } from 'react-router-dom';
import { PaperDTO } from '../../dtos/PaperDTO';
import { ExamToDoDTO } from '../../dtos/ExamToDoDTO';
import PrimaryNav from '../../components/PrimaryNav';
import LayoutNew from '../../layouts/LayoutNew';
import { SCREEN } from '../../constants';
import QuestionResult from '../../components/QuestionResult';

const StudentPaperResultScreen = () => {
  const subjects = useSubjects();

  const dataFromLocation = useLocation().state as { paper: PaperDTO; exam: ExamToDoDTO };

  const [allQuestionState, setAllQuestionsState] = useState<any[]>([]);

  const subjectName =
    subjects.allSubjects.find((sub) => sub.id === dataFromLocation.paper?.subjectId)?.name ||
    'Paper subject name';

  useEffect(() => {
    let allQuestions: any[] = [];

    if (
      dataFromLocation.paper.paperType === PaperTypes.MCQ ||
      dataFromLocation.paper.paperType === PaperTypes.TRUEFALSE
    ) {
      dataFromLocation.paper.questions?.forEach((q, idx) => {
        allQuestions.push(q);
      });

      setAllQuestionsState(allQuestions);
    }
  }, [dataFromLocation.paper]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const incrementPageNo = (e: any) => {
    if (!(numPages === pageNumber)) {
      setPageNumber(pageNumber + 1);
    }
    e.stopPropagation();
  };

  const decrementPageNo = (e: any) => {
    if (!(pageNumber === 1)) {
      setPageNumber(pageNumber - 1);
    }
    e.stopPropagation();
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const primaryNav = <PrimaryNav />;

  const header = (
    <StudentQuestionResultsHeader
      examTitle={dataFromLocation?.paper?.paperTitle}
      subjectName={subjectName}
      score={dataFromLocation?.paper?.score}
      points={dataFromLocation?.paper.paperPoints}
      totalPoints={dataFromLocation?.paper.marks || 0}
      examType={dataFromLocation.paper.paperType}
      exam={dataFromLocation.exam}
    />
  );

  const [initialWidth, setInitialWidth] = useState<any>(null);
  const pdfWrapper = useRef<any>(null);

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 3000));
    };
  }, []);

  const body =
    dataFromLocation.paper.paperType === PaperTypes.MCQ ||
    dataFromLocation.paper.paperType === PaperTypes.TRUEFALSE ? (
      <>
        {allQuestionState?.map((item, idx) => (
          <QuestionResult className="mt-4" question={item} idx={idx} key={idx} />
        ))}
      </>
    ) : (
      <div className="stuct-essay-result mt-3">
        <div className="stuct-essay-result__pdf-view">
          <div className="mr-2">
            <div className="material-container d-flex flex-column text-white" id="pdfWrapper">
              <Document
                file={dataFromLocation.paper.structEssayData?.markedAnswerSheetUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                className="m-auto"
              >
                <Page
                  pageNumber={pageNumber}
                  width={initialWidth}
                  onClick={() => {
                    window.open(dataFromLocation.paper.structEssayData?.markedAnswerSheetUrl);
                  }}
                  className="cursor-pointer"
                  renderAnnotationLayer={false}
                />
              </Document>

              <div className="text-center d-flex justify-content-center mt-3">
                <FaAngleLeft size={40} onClick={decrementPageNo} style={{ cursor: 'pointer' }} />
                <FaAngleRight size={40} onClick={incrementPageNo} style={{ cursor: 'pointer' }} />
              </div>
              <p className="text-center mb-0 mt-2">
                Page {pageNumber} of {numPages}
              </p>
            </div>

            <div className="text-center text-white">
              <span className="material-name" style={{ fontSize: '14px' }}>
                {dataFromLocation.paper.structEssayData?.markedAnswerSheetName}
              </span>
            </div>
          </div>
        </div>
        <a
          className="stuct-essay-result__pdf-icon"
          href={dataFromLocation.paper.structEssayData?.markedAnswerSheetUrl}
          target="_blank"
          rel="noreferrer"
        >
          <div className="stuct-essay-result__fontawesome-icon-wrapper">
            <FaFilePdf className="stuct-essay-result__fontawesome-icon" size={80} />
            <div className="stuct-essay-result__fontawesome-text mt-2">Click to view</div>
          </div>
        </a>
        <div className="stuct-essay-result__feedback" style={{ paddingBottom: '100px' }}>
          <div className="stuct-essay-result__feedback-title">Feedback</div>
          <div className="stuct-essay-result__feedback-body">{dataFromLocation.paper.feedback}</div>
        </div>
      </div>
    );

  return (
    <LayoutNew
      primaryNav={primaryNav}
      header={header}
      body={body}
      screen={SCREEN.SCORE_SCREEN}
      secondaryNav={null}
    />
  );
};
export default StudentPaperResultScreen;
