import React, {FC} from 'react';

import {titleCase} from 'title-case';
import './NumberInput.scss';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaCheckCircle} from 'react-icons/fa';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  stateName: string;
  stateValue: string;
  state: any;
  setState: Function;
  verified?: boolean;
  error?: string;
  placeHolder?: string;
  onKeyPress?: (evt: any) => void;
  onChangeCallback?: () => void;
  maxLength?: number;
  unitType?: string;
  readonly?: boolean;
}

const PhoneNumberInput: FC<Props> = (props) => {
  const inputChangeHandler = (state: any, name: string, value: string) => {
    if (props.maxLength) {
      value = value.length > props.maxLength ? (value = value.slice(0, props.maxLength)) : value;

      if (+value < 0) {
        value = '0';
      }
    }

    props.setState({
      ...state,
      [name]: {...state[name], value: value},
    });

    props.onChangeCallback && props.onChangeCallback();
  };

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: {...state[name], error: ''},
    });
  };

  let errorJSX = null;

  if (props.error !== 'NO-ERROR') {
    errorJSX = <div className={`w-100 err-styles`}>{props.error}</div>;
  }

  let borderColor =
    props.error === 'NO-ERROR'
      ? {borderBottom: '1px solid #20D167'}
      : props.error === ''
        ? {}
        : {borderBottom: '1px solid #dc3545'};

  let showPhoneVerifiedicon = null;

  if (props.verified) {
    showPhoneVerifiedicon = (
      <OverlayTrigger placement='top' overlay={<Tooltip id='verifiedId'>verified</Tooltip>}>
        <FaCheckCircle
          color='#246BFD'
          size={13}
          className='eye'
          style={{position: 'absolute', right: 0, top: 3.5}}
        />
      </OverlayTrigger>
    );
  }

  return (
    <div className='position-relative' style={{width: '100%', ...props.style}}>
      <div className={`number-input ${props.className}`} style={props.style}>
        {props.unitType && props.stateValue?.length > 0 && (
          <span className='unit-type'>{props.unitType} &nbsp;</span>
        )}
        <input
          name={props.stateName}
          autoComplete='off'
          required
          maxLength={props.maxLength || 10}
          onChange={(e: any) => {
            const val = e.target.value;
            if (val.includes('.')) {
              return;
            }
            if (e.target.value === '' || e.target.value === '+') {
              inputChangeHandler(props.state, props.stateName, e.target.value);
            } else if (!isNaN(e.target.value)) {
              inputChangeHandler(props.state, props.stateName, e.target.value);
            }
          }}
          value={props.stateValue}
          onFocus={() => {
            resetError(props.state, props.stateName);
          }}
        />

        <label htmlFor={props.stateName} className="number-input-label" style={borderColor}>
          <span className="number-input-label-text">
            {props.placeHolder ? titleCase(props.placeHolder) : titleCase(props.stateName)}
          </span>
        </label>
        {showPhoneVerifiedicon}
      </div>

      {errorJSX}
    </div>
  );
};

export default PhoneNumberInput;
