import React, {FC, Fragment} from 'react';
import {useAppUser} from '../context/UserContext';
import {PACKAGE_TYPE_TABS} from '../enums';
import './PackageTypesTabs.scss';

interface Props {
  changeTabHandelr?: (tab: PACKAGE_TYPE_TABS) => void;
  tab: PACKAGE_TYPE_TABS;
}

const PackageTypesTabs: FC<Props> = (props) => {
  const {CURRENT_PACKAGES, EXPIRED_PACKAGES, MY_PACKAGES, ALL_PACKAGES} = PACKAGE_TYPE_TABS;

  const user = useAppUser();

  return (
    <Fragment>
      {user.firestoreUser?.userRole?.isAdmin || user.firestoreUser?.userRole?.isInstructor ? (
        <div className="packageTypes-tabs">
          <div
            className={`packageTypes-tabs__item ${
              props.tab === CURRENT_PACKAGES && 'packageTypes-tabs__item--active'
            }`}
            onClick={() => {
              props.changeTabHandelr && props.changeTabHandelr(CURRENT_PACKAGES);
            }}
          >
            Current Packages
          </div>
          <div
            className={`packageTypes-tabs__item  ${
              props.tab === EXPIRED_PACKAGES && 'packageTypes-tabs__item--active'
            }`}
            onClick={() => {
              props.changeTabHandelr && props.changeTabHandelr(EXPIRED_PACKAGES);
            }}
          >
            Expired Packages
          </div>
        </div>
      ) : null}

      {user.firestoreUser?.userRole?.isStudent ? (
        <div className="packageTypes-tabs">
          <div
            className={`packageTypes-tabs__item ${
              props.tab === MY_PACKAGES && 'packageTypes-tabs__item--active'
            }`}
            onClick={() => {
              props.changeTabHandelr && props.changeTabHandelr(MY_PACKAGES);
            }}
          >
            My Packages
          </div>
          <div
            className={`packageTypes-tabs__item  ${
              props.tab === ALL_PACKAGES && 'packageTypes-tabs__item--active'
            }`}
            onClick={() => {
              props.changeTabHandelr && props.changeTabHandelr(ALL_PACKAGES);
            }}
          >
            All Packages
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default PackageTypesTabs;
