import React, {FC, useState} from 'react';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa';
import {Col, Row} from 'react-bootstrap';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import './StructuredEssayPDFPreview.scss';
// import {Document} from 'react-pdf';

interface Props {
  paperName?: string;
  paperURL?: string;
  markingSchemeName?: string;
  markingSchemeURL?: string;
}

const StructuredEssayPDFPreview: FC<Props> = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [numMarkingSchemePages, setNumMarkingSchemePages] = useState(null);
  const [MarkingSchemePageNumber, setMarkingSchemePageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}: any) {
    setNumPages(numPages);
  }

  function onDocumenMarkingSchemeLoadSuccess({numPages}: any) {
    setNumMarkingSchemePages(numPages);
  }

  const incrementPageNo = (e: any) => {
    if (!(numPages === pageNumber)) {
      setPageNumber(pageNumber + 1);
    }
    e.stopPropagation();
  };

  const decrementPageNo = (e: any) => {
    if (!(pageNumber === 1)) {
      setPageNumber(pageNumber - 1);
    }
    e.stopPropagation();
  };

  const incrementMarkingSchemeNo = (e: any) => {
    if (!(numMarkingSchemePages === MarkingSchemePageNumber)) {
      setMarkingSchemePageNumber(MarkingSchemePageNumber + 1);
    }
    e.stopPropagation();
  };

  const decrementMarkingSchemeNo = (e: any) => {
    if (!(MarkingSchemePageNumber === 1)) {
      setMarkingSchemePageNumber(MarkingSchemePageNumber - 1);
    }
    e.stopPropagation();
  };

  return (
    <Row className="d-flex no-gutters mt-4">
      <Col xs={6}>
        <div className="mr-2 text-white">
          <div className="material-container d-flex flex-column">
            <div
              style={{cursor: 'pointer'}}
              onClick={() => {
                window.open(props.paperURL);
              }}
            >
              <Document
                file={props.paperURL}
                onLoadSuccess={onDocumentLoadSuccess}
                className="m-auto"
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
            <div className="text-center d-flex justify-content-center mt-3">
              <FaAngleLeft size={40} onClick={decrementPageNo} style={{cursor: 'pointer'}} />
              <FaAngleRight size={40} onClick={incrementPageNo} style={{cursor: 'pointer'}} />
            </div>
            <p className="text-center mb-0 mt-2">
              Page {pageNumber} of {numPages}
            </p>
          </div>
          <div className="text-center">
            <span className="material-name" style={{fontSize: '14px'}}>
              {props.paperName || props.markingSchemeName}
            </span>
          </div>
        </div>
      </Col>

      <Col xs={6}>
        <div className="mr-2 text-white">
          <div className="material-container d-flex flex-column ">
            <div
              style={{cursor: 'pointer'}}
              onClick={() => {
                window.open(props.markingSchemeURL);
              }}
            >
              <Document
                file={props.markingSchemeURL}
                onLoadSuccess={onDocumenMarkingSchemeLoadSuccess}
                className="m-auto"
              >
                <Page pageNumber={MarkingSchemePageNumber} />
              </Document>
            </div>
            <div className="text-center d-flex justify-content-center mt-3">
              <FaAngleLeft
                size={40}
                onClick={decrementMarkingSchemeNo}
                style={{cursor: 'pointer'}}
              />
              <FaAngleRight
                size={40}
                onClick={incrementMarkingSchemeNo}
                style={{cursor: 'pointer'}}
              />
            </div>
            <p className="text-center mb-0 mt-2">
              Page {MarkingSchemePageNumber} of {numMarkingSchemePages}
            </p>
          </div>
          <div className="text-center">
            <span className="material-name" style={{fontSize: '14px'}}>
              {props.markingSchemeName || props.markingSchemeName}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default StructuredEssayPDFPreview;
