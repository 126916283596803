import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { LESSON_SECONDARY_NAV_MAIN_ITEMS } from '../constants';
import { useLessons } from '../context/LessonsContext';
import { useSubjects } from '../context/SubjectsContext';
import { useUnits } from '../context/UnitsContext';
import { useAppUser } from '../context/UserContext';
import { isValidLessonForUser, isValidSubjectForUser } from '../utils';
import Backarrow from './Backarrow';
import './LessonSecondaryNav.scss';

const LessonSecondaryNav = () => {
  const { MY_UNITS, MODAL_PAPERS } = LESSON_SECONDARY_NAV_MAIN_ITEMS;

  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUnits = useUnits();
  const user = useAppUser().firestoreUser;
  const history = useHistory();

  const { subjectId, lessonId, unitId } = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
  };

  const lessonNameRef = useRef('');

  const { lessonName } = isValidLessonForUser({
    lessonId: lessonId,
    lessons: appLessons.lessons,
    subjectId: subjectId,
  });

  useEffect(() => {
    const isValidSubject = isValidSubjectForUser({
      user: user,
      subjectId: subjectId,
      subjects: appSubjects.allSubjects,
    });

    const { isValidLesson, lessonName } = isValidLessonForUser({
      lessonId: lessonId,
      lessons: appLessons.lessons,
      subjectId: subjectId,
    });

    lessonNameRef.current = lessonName;

    if (
      !isValidSubject &&
      !isValidLesson &&
      appSubjects.allSubjects.length &&
      appLessons.lessons.length
    ) {
      history.push('/404');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSubjects.allSubjects, appLessons.lessons]);

  let navItems = [MY_UNITS, MODAL_PAPERS];
  if (user?.userRole?.isStudent) {
    navItems = [MY_UNITS];
  }

  // if (!concernedLesson?.isLessonContainsUnits) {
  //   navItems = [MODAL_PAPERS, CONTENT];
  // }

  const localBaseUrl = `my-subjects/subject/${subjectId}/lesson/${lessonId}`;
  const lastSegment = decodeURI(history.location.pathname.split('/').pop() || '');

  return (
    <aside className="lesson-snav">
      <header className="lesson-snav__header">
        <Backarrow
          onClick={() => {
            history.push(`/my-subjects/subject/${subjectId}/Lessons`);
          }}
        />
        <h6>{appUnits.units.find((val) => val.id === unitId)?.name || lessonName}</h6>
      </header>
      <li className="lesson-snav__items">
        {navItems.map((item, idx) => (
          <ul
            className={`lesson-snav__item  ${lastSegment === item && 'lesson-snav__item--active'} ${
              history.location.pathname.includes(item) && 'lesson-snav__item--active'
            } `}
            onClick={() => {
              history.push(`/${localBaseUrl}/${item}`);
            }}
            key={idx}
          >
            {item}
          </ul>
        ))}
      </li>
    </aside>
  );
};

export default LessonSecondaryNav;
