import React, {FC, useState} from 'react';
import {FaChevronRight} from 'react-icons/fa';
import {GradingType} from '../constants';
import {usePaymentSwitch} from '../context/PaymentContext';
import {useAppUser} from '../context/UserContext';
import {ExamToDoDTO} from '../dtos/ExamToDoDTO';
import {PaperTypes} from '../enums/paperTypes';
import {getAbbrivationExam, removeDecimalSection, round} from '../utils';

import './StudentExtraExamCard.scss';

interface Props {
  exam: ExamToDoDTO;
  isLoading: boolean;
  notCompleted?: boolean;
  setSelectedExamsHandlers: (exam: ExamToDoDTO | null) => void;
}

const StudentExtraExamCard: FC<Props> = (props) => {
  // ------------------------------
  const hideOverallMark = props.exam.papers.filter((p) => {
    if (
      (p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED) &&
      !p.structEssayData?.answerSheetUrl
    ) {
      return false;
    } else if (
      (p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED) &&
      !p.released
    ) {
      return true;
    }
    return false;
  }).length;

  // ------------------------------
  const [state, setState] = useState(false);

  const paySwitch = usePaymentSwitch();

  const examTypesArray = props.exam.papers.map((value) => {
    if (value.paperType === 'TRUEFALSE') {
      return 'T/F';
    } else {
      return value.paperType;
    }
  });

  const examTypes = examTypesArray.map((x) => {
    return getAbbrivationExam(x);
  }) as string[];

  const set = new Set(examTypes);

  const appUser = useAppUser();

  return (
    <div
      className="extra-exam-card"
      onClick={() => {
        props.setSelectedExamsHandlers(props.exam);
      }}
    >
      <div className="extra-exam-card__visible">
        <div className="extra-exam-card__title d-block d-md-none d-xl-block">
          {props.exam?.examTitle}
        </div>
        <div className="extra-exam-card__price ml-auto d-md-none">
          {props.exam?.price && paySwitch.enabled ? (
            <>
              <span>Rs.</span>
              {props.exam?.price}
            </>
          ) : (
            'Free'
          )}
        </div>
        <div className="extra-exam-card__icon d-md-none">
          <FaChevronRight
            color="#687674"
            size={21}
            onClick={(e) => {
              setState(!state);
              e.stopPropagation();
            }}
            style={state ? {transform: 'rotate(90deg)'} : {transform: 'rotate(0deg)'}}
          />
        </div>

        <div className="d-none d-md-block extra-exam-card-md__type mr-auto">
          {Array.from(set).map((value, idx) => (
            <div key={idx}>{value}</div>
          ))}
        </div>

        <div className={`d-none d-md-block extra-exam-card-md__score`}>
          {props.notCompleted ? (
            <div className="d-flex align-items-center h-100">
              {props.exam?.usersExamState?.find((u) => u.userId === appUser.fireUser?.uid) ? (
                <span style={{color: '#20D167'}}>In Progress</span>
              ) : (
                <span style={{color: '#F3B619'}}>Open for Submission</span>
              )}
            </div>
          ) : (
            <>
              <div className="extra-exam-card-md__score-title ">
                {props.notCompleted ? '' : 'Score'}
              </div>
              {hideOverallMark ? (
                <div
                  className="extra-exam-card-md__score-value exam-card-md__score-value__pending"
                  style={{color: '#F3B619'}}
                >
                  Pending...
                </div>
              ) : (
                <div className="extra-exam-card-md__score-value">
                  {props.notCompleted
                    ? ''
                    : props.exam.gradingType === GradingType.PERCENTAGE
                    ? (props.exam.score ? round(props.exam.score, 2, 2).toString() : '00.00') + '%'
                    : `${props.exam.points + '/' + props.exam.totalPoints}`}
                </div>
              )}
            </>
          )}
        </div>

        <div className="extra-exam-card__price ml-auto d-none d-md-flex">
          {props.exam?.price && paySwitch.enabled ? (
            <>
              <span>Rs.</span>
              {props.exam?.price}
            </>
          ) : (
            'Free'
          )}
        </div>
      </div>
      <div className="mt-2 d-none d-md-block d-xl-none">{props.exam?.examTitle}</div>

      <div
        className={`extra-exam-card__hidden d-md-none align-items-center ${!state ? 'd-none' : ''}`}
      >
        <div className={`d-md-flex align-items-center mr-3  ${!props.notCompleted && 'd-none'}`}>
          {props.exam?.usersExamState?.find((u) => u.userId === appUser.fireUser?.uid) ? (
            <span style={{color: '#20D167'}}>In Progress</span>
          ) : (
            <span style={{color: '#F3B619'}}>Open for Submission</span>
          )}
        </div>

        {!props.notCompleted ? (
          <div className="extra-exam-card-md__score ">
            {hideOverallMark ? (
              <>
                <div className="exam-card-md__score-title">Score</div>
                <div className="exam-card-md__score-value exam-card-md__score-value__pending">
                  Pending...
                </div>
              </>
            ) : (
              <>
                <div className="exam-card-md__score-title">Score</div>
                <div className="exam-card-md__score-value">
                  {props.exam.gradingType === GradingType.PERCENTAGE
                    ? removeDecimalSection(props.exam.score?.toString() || '0') + ' %'
                    : `${props.exam.points + '/' + props.exam.totalPoints}`}
                </div>{' '}
              </>
            )}
          </div>
        ) : (
          <></>
        )}
        <div className="extra-exam-card__type ml-3">
          {Array.from(set).map((value, idx) => (
            <div key={idx}>{value}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentExtraExamCard;
