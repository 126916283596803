import React, {FC} from 'react';
import {Col, ProgressBar, Row} from 'react-bootstrap';
import Backarrow from './Backarrow';
import './ExamHeaderNew.scss';
import HamburgerIcon from './HamburgerIcon';

interface Props {
  title: string;
  onBack?: () => void;
  totalNoOfQuestions: number;
  currentQuestion: number;
  preventGoingBack?: boolean;
}

const ExamHeaderNew: FC<Props> = (props) => {
  const progress = (props.currentQuestion / props.totalNoOfQuestions) * 100;

  return (
    <Row className="exam-header no-gutters">
      <Col
        className="exam-header__exam-title pr-lg-3 d-flex justify-content-between"
        md={12}
        lg={6}
      >
        <div className="d-flex align-items-center">
          <Backarrow onClick={props.onBack} />
          <span>{props.title}</span>
        </div>
        {/* <Backarrow onClick={props.onBack} className="d-lg-none ml-auto" /> */}
        <HamburgerIcon className="d-md-none" />
      </Col>

      <Col className="exam-header__status mt-4 mt-lg-0" md={12} lg={6}>
        <Row className="h-100 align-items-center no-gutters">
          <Col lg={6} xs={7} className="offset-lg-2 exam-header__progress-bar pr-2 pr-lg-0">
            <ProgressBar now={progress} />
          </Col>
          <Col className="text-right " lg={4} xs={5}>
            <span className="exam-header__completed-q-count">{props.currentQuestion}</span>
            <span className="exam-header__total-q-count">
              /{props.totalNoOfQuestions} Completed
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default ExamHeaderNew;
