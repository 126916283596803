import React, {FC} from 'react';
import {Droppable} from 'react-beautiful-dnd';
import './MCQQuestionCardColumn.scss';
import MCQQuestionCardSection from './MCQQuestionCardSection';
import MCQQuestionCardSelectedSection from './MCQQuestionCardSelectedSection';

interface Props {
  column: any;
  tasks: any;
  type?: 'selected';
  onRemove?: (taskId: string) => void;
  onReplace?: (taskId: string) => void;
  searchText?: string;
}

const MCQQuestionCardColumn: FC<Props> = (props) => {
  const callback = (taskId: string) => {};

  return (
    <div className="column-container-mcq-paper-questions-search">
      <Droppable droppableId={props.column.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="column-container__list-mcq-paper-questions-search"
          >
            {props.tasks.map((task: any, index: number) => {
              if (props.type === 'selected') {
                return (
                  <MCQQuestionCardSelectedSection
                    key={task.id}
                    task={task}
                    index={index}
                    onRemove={props.onRemove || callback}
                    onReplace={props.onReplace || callback}
                  />
                );
              } else {
                return (
                  <MCQQuestionCardSection
                    key={task.id}
                    task={task}
                    index={index}
                    searchText={props.searchText}
                  />
                );
              }
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default MCQQuestionCardColumn;
