import React, { FC, Fragment } from 'react';
import TinyMCE from '../../components/TinyMCE';
import { useAppContent } from '../../context/ContentContext';
import { Item } from '../../interfaces/LocalContentState';
import AddContentItemShell from '../../layout/AddContentItemShell';
import { CONTENT_MEDIA_TYPE } from '../../utils';

interface Props {
  contentItemDroppableId: string;
  droppableId: string;
}

const TextContext: FC<Props> = (props) => {
  const appContent = useAppContent();

  const textData = appContent.contentData
    .find((val) => val.droppableId === props.droppableId)
    ?.contentItems.find((val) => val.contentItemDroppableId === props.contentItemDroppableId) as {
    textContent: Item;
    mediaType: CONTENT_MEDIA_TYPE;
    contentItemDroppableId: string;
    component: any;
  };

  //   const textContentHandler = (
  //     droppableId: string,
  //     contentItemDroppableId: string,
  //     inputText: string
  //   ) => {
  //     const textData = appContent.contentData
  //       .find((val) => val.droppableId === droppableId)
  //       ?.contentItems.find((val) => val.contentItemDroppableId === contentItemDroppableId) as {
  //       textContent: Item;
  //       mediaType: CONTENT_MEDIA_TYPE;
  //       contentItemDroppableId: string;
  //       component: any;
  //     };

  //     textData.textContent.value = inputText;

  //     const idx = appContent.contentData
  //       .find((val) => val.droppableId === props.droppableId)
  //       ?.contentItems.indexOf(textData)!;

  //     const stateCopy = [
  //       ...appContent.contentData.find((val) => val.droppableId === props.droppableId)?.contentItems!,
  //     ];

  //     stateCopy[idx] = textData;

  //     appContent.setState && appContent.setState((ps: any) => ({ ...ps, contentItems: stateCopy }));
  //   };

  //   const resetQuestionErrors = () => {
  //     appContent.setState &&
  //       appContent.setState((ps: any) => {
  //         return { ...ps, textContent: { ...ps.textContent, error: '' } };
  //       });
  //   };

  return (
    <Fragment>
      <AddContentItemShell
        componentName="Text Content"
        componentType={CONTENT_MEDIA_TYPE.TEXT}
        className="mt-4"
        droppableId={props.droppableId}
        contentItemDroppableId={props.contentItemDroppableId}
      >
        <TinyMCE
          onChange={(e) => {
            appContent.textContentHandler(props.droppableId, props.contentItemDroppableId, e);
          }}
          className="mt-1"
          value={textData.textContent.value}
          error={textData.textContent.error}
          onFocus={() => {
            appContent.resetTextContentErrorHandler(
              props.droppableId,
              props.contentItemDroppableId
            );
          }}
        />
      </AddContentItemShell>
    </Fragment>
  );
};

export default React.memo(TextContext);
