import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

import { EXAM_CREATION_STATE, PaperPayment } from '../interfaces';
import FilterCheckBox from './FilterCheckBox';

import { useFunctions } from 'reactfire';
import { ExtraExamCreationDto } from '../dtos/ExtraExamCreationDto';

import { useHistory, useParams } from 'react-router-dom';
import { GRADE_TYPES } from '../constants';
import { getSubmissionTypes } from '../utils';
import { ExamType } from '../enums/ExamType';
import { useAppUser } from '../context/UserContext';
import { ExamStatus } from '../enums/ExamStatus';
import Notification from './../components/Notification';
import { PaperTypes } from '../enums/paperTypes';
import DropDown from './DropDown';
import NumberInput from './NumberInput';
import { validationResult } from '../utils';
import ButtonComp from './ButtonComp';
interface Props {
  state: EXAM_CREATION_STATE;
  setState: React.Dispatch<React.SetStateAction<EXAM_CREATION_STATE>>;
  onBack: () => void;
  paperPayments?: PaperPayment[];
  update?: boolean;
}

const ExamCreationStepThreeExtra: FC<Props> = (props) => {
  const appUser = useAppUser();

  const history = useHistory();

  const { state, setState } = props;

  const params = useParams() as { subjectId: string; lessonId: string; unitId: string };

  const functions = useFunctions();
  const createExamRef = functions.httpsCallable('createExam');
  const updateExamRef = functions.httpsCallable('updateExam');

  const onSave = (status: ExamStatus) => {
    const validationOutput = validationResult({
      ...state,
    });

    setState(validationOutput.state);

    if (validationOutput.formValidity.trim().length > 0) {
      setState((pS) => ({
        ...pS,
        isLoading: { ...pS.isLoading, value: false },
      }));
      return;
    }
    setState((ps) => {
      return { ...ps, isLoading: { ...ps.isLoading, value: true } };
    });

    const { subTypeMCQ, subTypeTF, subTypeSTR, subTypeESY } = getSubmissionTypes(
      state.papers.value
    );

    const paymentAddedPapers = state.papers.value.map((paper) => {
      const val = props.paperPayments?.find((p) => p.paperId === paper.id);
      let p = paper;
      if (paper.paperType === PaperTypes.STRUCTURED || paper.paperType === PaperTypes.ESSAY) {
        p = {
          ...paper,
          taPayment: { amount: val?.payment.amount || 0, isFree: !!val?.payment.isFree },
        };
      }

      return p;
    });
    const dataToSave: ExtraExamCreationDto = {
      authorName: appUser.firestoreUser?.username || '',
      description: state.description.value,
      examDuration: state.examDuration.value,
      examTitle: state.examTitle.value,
      examType: ExamType.EXTRA_EXAM,
      grade: state.grade.value.id,
      gradingType: state.gradingType.value.id,
      lessonId: state.lesson.value.id,
      unitId: state.unit.value.id,
      lessonIds: state.lessons.value.map((l) => l.id),
      unitIds: state.units.value.map((l) => l.id),
      overallMark: parseInt(state.overallMark.value),
      papers: paymentAddedPapers,
      price: state.price.value,
      status: status,
      subjectId: state.subject.value.id,
      submissionType: {
        MCQ: subTypeMCQ,
        TrueFalse: subTypeTF,
        File: subTypeSTR || subTypeESY,
      },
    };

    if (state.id) {
      updateExamRef({ ...dataToSave, id: state.id })
        .then(() => {
          if (status === ExamStatus.PUBLISHED) {
            Notification({
              isSuccess: true,
              message: 'Exam updated successfully',
              id: 'tid',
            });
          } else {
            Notification({
              isSuccess: true,
              message: 'Exam updated successfully as a draft',
              id: 'tid',
            });
          }

          setState((ps) => {
            return { ...ps, isLoading: { ...ps.isLoading, value: false } };
          });

          history.push(`/my-subjects/subject/${state.subject.value.id}/Examinations?name=extra`);
        })
        .catch((err: any) => {
          console.log(err);
          setState((ps) => {
            return { ...ps, isLoading: { ...ps.isLoading, value: false } };
          });
        });
    } else {
      createExamRef(dataToSave)
        .then(() => {
          if (status === ExamStatus.PUBLISHED) {
            Notification({
              isSuccess: true,
              message: 'Exam created successfully',
              id: 'tid',
            });
          } else {
            Notification({
              isSuccess: true,
              message: 'Exam created successfully as a draft',
              id: 'tid',
            });
          }

          setState((ps) => {
            return { ...ps, isLoading: { ...ps.isLoading, value: false } };
          });

          if (params.subjectId && params.lessonId && params.unitId) {
            history.push(
              `/my-subjects/subject/${state.subject.value.id}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations?name=extra`
            );
          } else if (params.subjectId && params.lessonId) {
            history.push(
              `/my-subjects/subject/${state.subject.value.id}/lesson/${params.lessonId}/Examinations?name=extra`
            );
          } else {
            history.push(`/my-subjects/subject/${state.subject.value.id}/Examinations?name=extra`);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setState((ps) => {
            return { ...ps, isLoading: { ...ps.isLoading, value: false } };
          });
        });
    }
  };

  return (
    <>
      <Row className="section-container">
        <Col>
          <Row className="pt-3 mt-3 pb-3 ">
            <Col className="section-card mt-5">
              <Row>
                <Col>
                  <span className="section-title">Submission type</span>
                </Col>
              </Row>
              <Row className="text-white mt-3">
                <Col className="col-auto ">
                  <FilterCheckBox
                    onClick={() => {}}
                    item={{
                      label: 'MCQ',
                      name: PaperTypes.MCQ,
                    }}
                    active={
                      !!props.state.papers.value.find((val) => val.paperType === PaperTypes.MCQ)
                    }
                    isDisabled
                  />
                </Col>
                <Col className="col-auto ">
                  <FilterCheckBox
                    onClick={() => {}}
                    item={{
                      label: 'True/False',
                      name: PaperTypes.TRUEFALSE,
                    }}
                    active={
                      !!props.state.papers.value.find(
                        (val) => val.paperType === PaperTypes.TRUEFALSE
                      )
                    }
                    isDisabled
                  />
                </Col>
                <Col className="col-auto">
                  <FilterCheckBox
                    onClick={() => {}}
                    item={{
                      label: 'Structured',
                      name: PaperTypes.STRUCTURED,
                    }}
                    active={
                      !!props.state.papers.value.find(
                        (val) => val.paperType === PaperTypes.STRUCTURED
                      )
                    }
                    isDisabled
                  />
                </Col>

                <Col className="col-auto">
                  <FilterCheckBox
                    onClick={() => {}}
                    item={{
                      label: 'Essay',
                      name: PaperTypes.ESSAY,
                    }}
                    active={
                      !!props.state.papers.value.find((val) => val.paperType === PaperTypes.ESSAY)
                    }
                    isDisabled
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="section-card mt-4">
              <Row>
                <Col>
                  <DropDown
                    name="Grade Type"
                    noValueText="Select Grade type"
                    stateName={'gradingType'}
                    stateValue={state.gradingType.value}
                    state={state}
                    setState={setState}
                    optionsArray={GRADE_TYPES}
                    error={state.gradingType.error}
                  />
                </Col>

                <Col>
                  <NumberInput
                    unitType="Rs."
                    style={{ marginTop: 4 }}
                    placeHolder="price"
                    stateName="price"
                    stateValue={parseInt(state.price.value) === 0 ? '' : state.price.value}
                    state={state}
                    setState={setState}
                    error={state.price.error}
                  />
                </Col>
                <Col className="col-auto">
                  <NumberInput
                    style={{ marginTop: 4, width: 80 }}
                    placeHolder="Full Marks"
                    stateName="overallMark"
                    stateValue={state.overallMark.value}
                    state={state}
                    setState={setState}
                    error={state.overallMark.error}
                    readonly
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="position-absolute bottom-0 button-container">
        <Col className="col-auto pl-1 pr-1">
          <ButtonComp
            text={props.update ? 'Update and publish' : 'Save and publish'}
            onClick={() => {
              onSave(ExamStatus.PUBLISHED);
            }}
            type={'one'}
          />
        </Col>
        <Col className="col-auto pl-1 pr-1">
          <ButtonComp
            text={props.update ? 'Update as draft' : 'Save as draft'}
            onClick={() => {
              onSave(ExamStatus.DRAFT);
            }}
            type={'one'}
          />
        </Col>
        <Col className="col-auto pl-1 pr-1">
          <ButtonComp
            text="Back"
            onClick={() => {
              props.onBack();
            }}
            type={'one'}
            style={{ background: 'transparent', gap: '20px' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ExamCreationStepThreeExtra;
