import React, { FC } from 'react';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
import { formatScore } from '../utils';
import './TopStudents.scss';

interface Props {
  list: { uid: string; score: number }[];
  className?: string;
  gradeType?: string;
  totalMark?: number;
  stillToMark: boolean;
  isExamEnded: boolean;
}
const removeTraillingZero = (number: number | string) => {
  return number.toString().replace(/\.00$/, '');
};

const TopStudents: FC<Props> = (props) => {
  const appUsers = useAdminsAndInstructors();

  return (
    <div className={`top-students ${props.className}`}>
      <div className="top-students__header d-flex justify-content-between">
        <div className="top-students__header-title">Top Students</div>
        {props.gradeType !== 'Percentage' && (
          <div
            className="top-students__total-points"
            style={{
              color: '#979797',
            }}
          >
            Total Points: {props.totalMark}
          </div>
        )}
      </div>
      <div className="top-students__list">
        {props.list.map((stu, idx) => (
          <div className="top-students__list-item" key={idx}>
            <div className="top-students__student-name">{`${idx + 1}. ${
              appUsers.allAdminsAndInstructors.find((val) => val.uid === stu.uid)?.username
            }`}</div>
            <div className="top-students__student-score">
              {props.gradeType === 'Percentage'
                ? `${removeTraillingZero(formatScore(stu.score))}%`
                : formatScore(stu.score)}
            </div>
          </div>
        ))}
      </div>

      {!props.isExamEnded ? (
        <div className="text-center mt-5" style={{ fontSize: 12, color: '#246bfd' }}>
          The Exam is currently in progress. The top students list might change after the exam is
          ended.
        </div>
      ) : (
        props.stillToMark &&
        props.list.length > 0 && (
          <div className="text-center mt-5" style={{ fontSize: 12, color: '#246bfd' }}>
            Paper marking is still in progress. <br /> Top students list might change after marking
            is completed.
          </div>
        )
      )}
    </div>
  );
};

export default TopStudents;
