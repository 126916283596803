import React, { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa';
import truncate from 'truncate';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
import { roundNumberWithDigits } from '../utils';
import './STUStudentLeaderboardCard.scss';

interface Props {
  name: string;
  uid: string;
  score: number;
  position: number;
  className?: string;
  active?: boolean;
  colorOfPosition?: React.CSSProperties;
  isPoint: boolean;
}

const STUStudentLeaderboardCard: FC<Props> = (props) => {
  const appUsers = useAdminsAndInstructors();

  return (
    <div
      className={`stu-leader-card ${props.className} ${props.active ? 'stu-leader-card--mine' : ''
        }`}
    >
      <div className="stu-leader-card__position" style={props.colorOfPosition}>
        {props.position}
      </div>

      {appUsers.allAdminsAndInstructors.find((val) => val.uid === props.uid)?.photoURL ? (
        <img
          src={appUsers.allAdminsAndInstructors.find((val) => val.uid === props.uid)?.photoURL}
          className="stu-leader-card__img-two"
          alt={appUsers.allAdminsAndInstructors.find((val) => val.uid === props.uid)?.username}
        />
      ) : (
        <div className="stu-leader-card__img">
          {appUsers.allAdminsAndInstructors.find((val) => val.uid === props.uid)?.photoURL ? (
            appUsers.allAdminsAndInstructors.find((val) => val.uid === props.uid)?.photoURL
          ) : (
            <FaUserAlt color="#000" />
          )}
        </div>
      )}

      <div className="stu-leader-card__name-marks">
        <div className="stu-leader-card__name">
          <OverlayTrigger placement="top" overlay={<Tooltip id="verifiedId">{props.name}</Tooltip>}>
            <div>{truncate(props.name || '', 15)}</div>
          </OverlayTrigger>
        </div>
        <div className="stu-leader-card__marks">
          {roundNumberWithDigits(props.score)}
          {props.isPoint ? '' : '%'}
        </div>
      </div>
    </div>
  );
};

export default STUStudentLeaderboardCard;
