import React from 'react';
import './YouLabel.scss';

const YouLabel = () => {
  return (
    <div className="u-label">
      <div className="u-label__text">YOU</div>
    </div>
  );
};

export default YouLabel;
