class AdminAndInstructorContentServices {
  private _createContentRef: any = null;

  constructor(createContentRef: firebase.functions.HttpsCallable) {
    this._createContentRef = createContentRef;
  }

  createContent = (data: any, onProgress?: () => void, onFinish?: (val?: any) => void) => {
    onProgress && onProgress();

    this._createContentRef(data)
      .then((val: any) => {
        onFinish && onFinish(val);
      })
      .catch((e: any) => {
        onFinish && onFinish(e);
      });
  };
}

export default AdminAndInstructorContentServices;

// private _createContentRef = useFunctions().httpsCallable('createContent');
