import React, {FC} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useHistory, useParams} from 'react-router-dom';
import {useMobileNav} from '../context/MobNavContext';
import {ExamToDoDTO} from '../dtos/ExamToDoDTO';
import {removeDecimalSection} from '../utils';
import Backarrow from './Backarrow';
import HamburgerIcon from './HamburgerIcon';
import ScoreTile from './ScoreTile';
import './StudentQuestionResultsHeader.scss';

interface Props {
  examTitle: string;
  subjectName: string;
  score: number | undefined;
  points: number | undefined;
  totalPoints: number;
  examType?: string;
  exam?: ExamToDoDTO;
}

const StudentQuestionResultsHeader: FC<Props> = (props) => {
  const history = useHistory();

  const appMobileNav = useMobileNav();

  const params = useParams() as {subjectId: string; examId: string};

  return (
    <Row className="question-results-header align-items-center no-gutters">
      <Col md={7} className="d-flex d-md-block align-items-center">
        <div className="d-flex">
          <Backarrow
            onClick={() => {
              history.push(
                `/my-subjects/subject/${params.subjectId}/exam/result/${params.examId}`,
                {exam: props.exam}
              );
            }}
            className=" mr-2"
          />
          <div className="question-results-header__details ml-auto ml-md-0">
            <div>{props.examTitle}</div>
            <div className="mt-1">{props.subjectName}</div>
          </div>
        </div>

        <HamburgerIcon
          className="d-md-none mr-2 ml-auto"
          onClick={() => {
            appMobileNav.setOpenMobileNav(!appMobileNav.openMobileNav);
          }}
        />
      </Col>
      <Col className="d-flex align-items-center justify-content-center mt-4 mt-md-0" md={5}>
        <div className={`question-results-header__score `}>
          <ScoreTile
            score={
              props.exam?.gradingType === 'Percentage'
                ? props.score === undefined
                  ? 'NOT MARKED'
                  : removeDecimalSection(props.score.toString() || '') + '%'
                : props.points === undefined
                ? 'NOT MARKED'
                : `${props.points + '/' + props.totalPoints} `
            }
          />
        </div>
      </Col>
    </Row>
  );
};

export default StudentQuestionResultsHeader;
