import React, { FC, useEffect, useState } from 'react';
import { UserFirestore } from '../models/UserFirestore';
import { splitArrayIntoChunksOfLen } from '../utils';
import LoadingIndicator from './LoadingIndicator';
import Pagination from './Pagination';
import SearchBox from './SearchBox';

import './SettingsManageUsersBody.scss';
import UserCard from './UserCard';

interface Props {
  users: UserFirestore[];
  setUsers: React.Dispatch<React.SetStateAction<any[]>>;
}
interface State {
  searchText: string;
  users: any[];
  loading: boolean;
  page: number;
  totalPages: number;
  filtered: boolean;
}

const SettingsValidateInstructorsAndTeachingAssistant: FC<Props> = (props) => {
  const [state, setState] = useState<State>({
    searchText: '',
    users: props.users,
    loading: false,
    page: 0,
    totalPages: 0,
    filtered: false,
  });

  useEffect(() => {
    setState((ps) => ({
      ...ps,
      users: props.users,
    }));
  }, [props.users]);

  const fetchDataCall = (filter?: boolean) => {
    if (typeof state.totalPages === 'number') {
      setState((ps) => {
        return { ...ps, loading: true };
      });

      const pageToSend = filter ? 0 : state.page - 1 < 0 ? 0 : state.page - 1;

      let filteredList = [...props.users];

      let searchTextResult: UserFirestore[] = [];
      let duplicatesRemovedArray: UserFirestore[];

      if (state?.searchText?.trim()) {
        searchTextResult = [
          ...filteredList.filter(
            (item) =>
              item.firstName.toUpperCase().startsWith(state.searchText.trim().toUpperCase()) ||
              item.lastName.toUpperCase().startsWith(state.searchText.trim().toUpperCase())
          ),
        ];

        duplicatesRemovedArray = Array.from(new Set(searchTextResult));
      } else {
        duplicatesRemovedArray = filteredList;
      }

      let pagesList: any[] = [];

      if (duplicatesRemovedArray.length) {
        pagesList = splitArrayIntoChunksOfLen(duplicatesRemovedArray, 10);
      }

      const totalPages = pagesList.length;

      setState((ps) => {
        return {
          ...ps,
          loading: false,
          users: pagesList[pageToSend] || [],
          totalPages: totalPages,
          filtered: filter ? true : false,
        };
      });
    }
  };

  const setCurrentPage = (page: number) => {
    setState((ps) => {
      return { ...ps, page: page };
    });
  };

  useEffect(() => {
    if (props.users.length) {
      fetchDataCall();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, props.users]);

  useEffect(() => {
    setState((ps) => {
      return { ...ps, filtered: false };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filtered]);

  useEffect(() => {
    fetchDataCall(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchText]);

  return (
    <div style={{ marginTop: 65 }}>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <div className="d-flex position-relative">
          <SearchBox
            onSearch={(text) => {
              setState((ps) => {
                return { ...ps, searchText: text };
              });
            }}
            placeHolderText="Search users"
          />
        </div>
      </div>
      {state.loading && <LoadingIndicator className="mt-3" />}
      <div className="user-card-table-header d-flex">
        <div>
          <div>First Name</div>
          <div></div>
          <div>Last Name</div>
          <div>Registered Date</div>
        </div>
      </div>

      <div className="user-list">
        {state.users?.map((user: any, idx: any) => {
          let text = '';
          let validated = undefined;

          if (user.userRole?.isInstructor) {
            text = 'Instructor';
            validated = user.isApproved;
          } else if (user.userRole?.isTeachingAssistant) {
            text = 'Teaching Assistant';
            validated = user.isApproved;
          }

          return (
            <UserCard
              key={idx}
              index={idx}
              userType={{ text, validated }}
              user={user}
              users={props.users}
              isInstructorValidation
              setUsers={props.setUsers}
              searchText={state.searchText}
            />
          );
        })}
      </div>
      {!state.filtered && state.totalPages > 1 ? (
        <Pagination pages={state.totalPages} setCurrentPage={setCurrentPage} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SettingsValidateInstructorsAndTeachingAssistant;
