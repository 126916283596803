import React, {FC} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaTimes} from 'react-icons/fa';
import truncate from 'truncate';
import {useLabels} from '../context/LabelContext';
import './Label.scss';

interface Props {
  id: string;
  onClose?: Function;
  noIcon?: boolean;
}

const Label: FC<Props> = (props) => {
  const labels = useLabels();

  const labelName = labels.labelList.find((label) => label.id === props.id)?.labelName;

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="verifiedId">{labelName}</Tooltip>}>
      <div className="label-tag mr-2 mb-2">
        <div className="label-tag__name">{truncate(labelName || '', 20)}</div>
        {!props.noIcon ? (
          <div
            className="label-tag__icon"
            onClick={() => {
              props.onClose && props.onClose();
            }}
          >
            <FaTimes size={13} className="text-white" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default Label;
