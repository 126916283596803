import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useLessons } from '../../context/LessonsContext';
import AdminAndInstructorContent from '../../modules/content/screen/adminAndInstructor/AdminAndInstructorContent';
import PageNotFoundScreen from '../common/PageNotFoundScreen';
import AdminExamDetailsAndStatsScreen from './AdminExamDetailsAndStatsScreen';
import AdminLessonModalPapersScreen from './AdminLessonModalPapersScreen';
import AdminLessonMyUnitScreen from './AdminLessonMyUnitScreen';
// import AdminLessonScreenContent from './AdminLessonScreenContent';
import AdminLessonUnitScreen from './AdminLessonUnitScreen';
import AdminUpdateExamScreen from './AdminUpdateExamScreen';
import ExamsCreateScreen from './ExamsCreateScreen';

const AdminLessonScreen = () => {
  const params = useParams() as { lessonId: string };

  const isLessonContainsUnits = useLessons().lessons.find(
    (l) => l.id === params.lessonId
  )?.isLessonContainsUnits;

  //   <Route exact path="/my-subjects/subject/:subjectId/lesson/:lessonId/Content">

  //  <AdminLessonScreenContent />
  // </Route>

  return (
    <Switch>
      <Route
        exact
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/create-extra-exam/Examinations',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/create-live-exam/Examinations',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/create-practise-exam/Examinations',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Examinations/create-extra-exam/Examinations',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Examinations/create-live-exam/Examinations',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Examinations/create-practise-exam/Examinations',
        ]}
      >
        <ExamsCreateScreen level="LESSON" unitsPresent={isLessonContainsUnits} />
      </Route>
      {isLessonContainsUnits ? (
        <Route exact path="/my-subjects/subject/:subjectId/lesson/:lessonId/My Units">
          <AdminLessonMyUnitScreen />
        </Route>
      ) : (
        <Route path="/my-subjects/subject/:subjectId/lesson/:lessonId/Content">
          <AdminAndInstructorContent />
        </Route>
      )}
      <Route path="/my-subjects/subject/:subjectId/lesson/:lessonId/Examinations/:examId">
        <AdminExamDetailsAndStatsScreen hierarchyLeveL="LESSON" />
      </Route>
      <Route path="/my-subjects/subject/:subjectId/lesson/:lessonId/Examinations">
        <AdminLessonModalPapersScreen />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Contents">
        <AdminLessonUnitScreen />
      </Route>
      <Route
        exact
        path="/my-subjects/subject/:subjectId/lesson/:lessonId/update-exam/:examId/Examinations"
      >
        <AdminUpdateExamScreen level="LESSON" />
      </Route>

      <Route path="*" component={PageNotFoundScreen} />
    </Switch>
  );
};

export default AdminLessonScreen;
