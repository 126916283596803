import firebase from 'firebase';
import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {Lesson} from '../models/Lesson';
import {sortByLessonsName} from '../utils';

export interface LessonsContext {
  lessons: Lesson[];
  isLoading: boolean;
  fetchLessons: () => void;
  setLoading: (value: boolean) => void;
  addLesson: (lesson: Lesson) => void;
  updateLesson: (lesson: Lesson) => void;
  removeLesson: (id: string) => void;
}

interface State {
  lessons: Lesson[];
  isLoading: boolean;
}

const initialContext: LessonsContext = {
  lessons: [],
  isLoading: false,
  fetchLessons: () => {},
  setLoading: (value: boolean) => {},
  addLesson: (lesson: Lesson) => {},
  updateLesson: (lesson: Lesson) => {},
  removeLesson: (id: string) => {},
};

const initialState: State = {
  lessons: [],
  isLoading: false,
};

const Context = createContext<LessonsContext>(initialContext);

function getUniqueListBy(arr: any[]) {
  const ids = arr.map((o: any) => o.id);
  const filtered = arr.filter(({id}: any, index: any) => !ids.includes(id, index + 1));
  return filtered;
}

export const LessonProvider: FC = ({children}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    // let unsub: any = null;

    // setState((ps) => {
    //   return {
    //     ...ps,
    //     isLoading: true,
    //   };
    // });

    // unsub = firebase
    //   .firestore()
    //   .collection('lessons')
    //   .onSnapshot((sp) => {
    //     let lessons: Lesson[] = [];

    //     sp.forEach((doc) => {
    //       lessons.push({id: doc.id, ...doc.data()} as any);
    //     });

    //     sp.docChanges().forEach((change) => {
    //       if (change.type === 'removed') {
    //         setState((ps) => {
    //           return {
    //             ...ps,
    //             lessons: getUniqueListBy(
    //               sortByLessonsName([...lessons].filter((value) => value.id !== change.doc.id))
    //             ),
    //             isLoading: false,
    //           };
    //         });
    //       }

    //       if (change.type === 'added' || change.type === 'modified') {
    //         setState((ps) => {
    //           return {
    //             ...ps,
    //             lessons: getUniqueListBy(sortByLessonsName([...lessons])),
    //             isLoading: false,
    //           };
    //         });
    //       }
    //     });
    //   });
    // return () => {
    //   unsub && unsub();
    // };

    fetchLessons();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLessons = () => {
    const lessons: Lesson[] = [];
    firebase
      .firestore()
      .collection('lessons')
      .get()
      .then((data) => {
        data.forEach((doc) => {
          lessons.push({id: doc.id, ...doc.data()} as any);
        });

        setState((ps) => {
          return {
            ...ps,
            lessons: getUniqueListBy(lessons),
          };
        });
      })
      .catch(() => {
        console.log('ERROR');
      });
  };

  const addLesson = (lesson: Lesson) => {
    const currentLessons = [...state.lessons];
    currentLessons.push(lesson);
    setState((ps) => {
      return {
        ...ps,
        lessons: sortByLessonsName(currentLessons),
      };
    });
  };

  const updateLesson = (lesson: Lesson) => {
    const currentLessons = [...state.lessons];

    const updatedArray = currentLessons.filter((sub) => sub.id !== lesson.id);

    updatedArray.push(lesson);
    setState((ps) => {
      return {
        ...ps,
        lessons: sortByLessonsName(updatedArray),
      };
    });
  };

  const removeLesson = (id: string) => {
    const currentSubjects = [...state.lessons];

    const updatedArray = currentSubjects.filter((sub) => sub.id !== id);

    setState((ps) => {
      return {
        ...ps,
        lessons: sortByLessonsName(updatedArray),
      };
    });
  };

  const setLoading = (value: boolean) => {
    setState((ps) => ({
      ...ps,
      isLoading: value,
    }));
  };

  return (
    <Context.Provider
      value={{
        ...state,
        fetchLessons,
        setLoading,
        addLesson,
        updateLesson,
        removeLesson,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useLessons = (): LessonsContext => useContext(Context);
