import React, { FC } from 'react';
import useDimensions from 'use-element-dimensions';
import PaperExamBreadCrum from './PaperExamBreadCrum';
import './PaperHeader.scss';
import { PACKAGE_BREADCRUMB_STEPS } from '../enums/packageBreadCrum';

interface Props {
  name: string;
  onClick?: () => void;
  paperIdPresent?: boolean;
  loading?: boolean;
  active: PACKAGE_BREADCRUMB_STEPS;
  onBack?: () => void;
}

const PaperHeader: FC<Props> = (props) => {
  const [{ width }, ref] = useDimensions();
  return (
    <div ref={ref}>
      <div className="header" style={{ width: width }}>
        <div className="d-flex">
          {props.onBack && (
            <div className="mr-2">
              <button id="blackarrow" color="#fff" onClick={props.onBack}>
                <svg
                  width="11"
                  height="14"
                  viewBox="0 0 11 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.499999 7.86602C-0.166668 7.48112 -0.166667 6.51888 0.5 6.13397L9.5 0.937821C10.1667 0.552921 11 1.03405 11 1.80385L11 12.1962C11 12.966 10.1667 13.4471 9.5 13.0622L0.499999 7.86602Z"
                    fill="#246BFD"
                  />
                </svg>
              </button>
            </div>
          )}
          <div className="newpackage">{props.name}</div>
        </div>
        <PaperExamBreadCrum
          active={props.active}
          stepOneName="General"
          stepTwoName="Select Lessons"
          stepThreeName="Payment"
        />
      </div>
    </div>
  );
};

export default PaperHeader;
