import React, { FC, Fragment, useState } from 'react';
import { FaCheck, FaChevronRight } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import Backarrow from '../../../components/Backarrow';
import { CONTENT_SECONDARY_NAV_MAIN_ITEMS } from '../../../constants';
import { useLessons } from '../../../context/LessonsContext';
import { useUnits } from '../../../context/UnitsContext';
import { useAppContent } from '../context/ContentContext';
import './ContentMainPreviewSecondaryNav.scss';

const ContentMainPreviewSecondaryNav: FC = () => {
  const appContent = useAppContent();

  const { CONTENT } = CONTENT_SECONDARY_NAV_MAIN_ITEMS;
  const navItems = [CONTENT];

  const subNavItems = appContent.contentData;

  const [displaysubNavItems, setDisplaysubNavItems] = useState<boolean>(true);

  const appLessons = useLessons();
  const appUnits = useUnits();
  const history = useHistory();

  const { lessonId, unitId } = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
  };

  const lastSegment = decodeURI(history.location.pathname.split('/').pop() || '');

  const unitName = appUnits.units.find((u) => u.id === unitId)?.name;
  const lessonName = appLessons.lessons.find((l) => l.id === lessonId)?.name;

  return (
    <aside className="content-main-preview-snav">
      <header className="content-main-preview-snav__header">
        <Backarrow
          onClick={() => {
            history.push(appContent.previousPathToPreview);
          }}
        />
        <h6>{unitName || lessonName}</h6>
      </header>
      <ul className="content-main-preview-snav__items">
        {navItems.map((item, idx) => (
          <Fragment key={idx}>
            <li
              className={`content-main-preview-snav__item  ${
                lastSegment === item && 'content-main-preview-snav__item--active'
              } ${
                item === 'Content' &&
                lastSegment === 'Create' &&
                'content-main-preview-snav__item--active'
              } ${
                history.location.pathname.includes(item) &&
                'content-main-preview-snav__item--active'
              } `}
              onClick={() => {}}
              key={idx}
            >
              <div className="content-main-preview-snav__text-content">
                {item}
                <FaChevronRight
                  size={15}
                  style={{ fontWeight: '100' }}
                  className={`content-main-preview-snav__text-content__icon ${
                    displaysubNavItems && 'content-main-preview-snav__text-content__icon--reveal'
                  }`}
                  onClick={() => {
                    setDisplaysubNavItems(!displaysubNavItems);
                  }}
                />
              </div>
            </li>

            <ul
              className={`content-main-preview-snav__sublist  ${
                displaysubNavItems && 'content-main-preview-snav__sublist--show'
              }`}
            >
              {subNavItems?.map((val, index) => {
                return (
                  <li
                    className={`content-main-preview-snav__sublist-item  ${
                      val.droppableId === appContent.mainDroppableId
                        ? 'content-main-preview-snav__sublist-item--active'
                        : ''
                    }`}
                    key={index}
                    onClick={() => {
                      appContent.setMainDroppableId(val.droppableId);
                    }}
                  >
                    <FaCheck
                      color="#20D167"
                      size={11}
                      className={`mr-2 d-none ${val.inPreviewChapterReadCheck && 'd-block'}`}
                    />

                    <span className={`content-main-preview-snav__sublist-sub-item `}>
                      {index + 1}
                      {'. ' + val.contentTitle.value}
                    </span>
                  </li>
                );
              })}
            </ul>
          </Fragment>
        ))}
      </ul>
    </aside>
  );
};

export default ContentMainPreviewSecondaryNav;
