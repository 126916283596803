import React, {FC} from 'react';
import {PaperQuestionDTO} from '../dtos/PaperQuestionDTO';
import {QuestionTypes} from '../enums/questionTypes';
import {MCQPaperQuestion} from '../models/Question';

import './QuestionNavigatorBtn.scss';

interface Props {
  className?: string;
  idx: number;
  isCurrentQuestion: boolean;
  onClick: (idx: number) => void;
  question: PaperQuestionDTO;
}

const QuestionNavigatorBtn: FC<Props> = (props) => {
  let notAnswered: boolean = false;

  if (props.question.questionType === QuestionTypes.MCQ) {
    const question = props.question as MCQPaperQuestion;

    let flag = false;

    question.answers.forEach((i) => {
      if (i.isSelected) {
        flag = true;
      }
    });

    if (!flag) {
      notAnswered = true;
    }
  } else if (props.question.questionType === QuestionTypes.TRUEFALSE) {
    const question = props.question as PaperQuestionDTO;

    if (question.answers[0].isSelected === false && question.answers[1].isSelected === false) {
      notAnswered = true;
    }
  }

  return (
    <div
      className={`question-navigator-btn ${props.className} ${
        notAnswered && 'question-navigator-btn--not-answered'
      } ${props.isCurrentQuestion && 'question-navigator-btn--current-question'} ${
        props.question.seen ? '' : 'question-navigator-btn--not-seen'
      }`}
      onClick={() => {
        props.onClick(props.idx - 1);
      }}
    >
      <span>{props.idx < 10 ? `0${props.idx}` : props.idx}</span>
    </div>
  );
};

export default QuestionNavigatorBtn;
