import React, {FC} from 'react';
import {Droppable} from 'react-beautiful-dnd';
import {PaperTypes} from '../enums/paperTypes';
import './DropableCol.scss';
import DroppablePaper from './DropablePaper';

interface Props {
  column: any;
  tasks: any[];
  outLined?: boolean;
  isShowAction?: boolean;
  replacePaper?: (id: string, type: PaperTypes) => void;
  removePaper?: (id: string) => void;
}

const DroppableCol: FC<Props> = (props) => {
  return (
    <div
      className={[
        'column-container',
        props.outLined && props.tasks.length < 1 ? 'dashed-bg' : '',
      ].join(' ')}
    >
      <Droppable droppableId={props.column?.id}>
        {(provided) =>
          props.tasks.length > 0 ? (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="column-container__list"
            >
              {props.tasks.map((task: any, index: number) => (
                <DroppablePaper
                  removePaper={props.removePaper}
                  replacePaper={props.replacePaper}
                  isShowAction={props.isShowAction}
                  key={task.id}
                  task={task}
                  index={index}
                />
              ))}
              {provided.placeholder}
            </div>
          ) : (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="column-container__list--empty"
            >
              <span style={{color: '#73847F'}}>Drag And Drop Papers Here</span>
            </div>
          )
        }
      </Droppable>
    </div>
  );
};

export default DroppableCol;
