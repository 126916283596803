import firebase from 'firebase';

export interface StructEssayData {
  paperName: string;
  paperUrl: string;
  markingSchemeName?: string;
  markingSchemeUrl?: string;
  answerSheetName?: string;
  answerSheetUrl?: string;
  markedAnswerSheetName?: string;
  markedAnswerSheetUrl?: string;
  gradedBy?: string;
  gradedAt?: firebase.firestore.Timestamp;
  status?: StructEssayDataPaperStatus | null;
  disapproveFeedback?: string;
}

export enum StructEssayDataPaperStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  NOT_MARKED = 'NOT_MARKED',
}
