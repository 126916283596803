import React, {FC, useEffect, useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';
import truncate from 'truncate';
import {DropDown} from '../interfaces';
import './FilterDropDown.scss';

interface Props {
  placeHolder: string;
  stateChangeHandler?: (value: DropDown) => void;
  options?: DropDown[];
  uniqueId?: string;
  value?: DropDown;
  truncateUpTo?: number;
  disabled?: boolean;
}

const FilterDropDown: FC<Props> = (props) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    var ignoreClickOnMeElement = document.getElementById(`id${props.uniqueId}`);

    document.addEventListener('click', function (event) {
      var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target as Node);
      if (!isClickInsideElement) {
        setState(false);
      }
    });
  }, [props.uniqueId]);

  return (
    <div
      className={`custom-dropdown `}
      id={`id${props.uniqueId}`}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        setState(true);
      }}
    >
      <div className="custom-dropdown__line">
        <div style={{paddingTop: 3, paddingBottom: 2.5}}>
          {truncate(props.value?.displayValue || props.placeHolder, props.truncateUpTo || 30)}
        </div>

        <FaChevronDown
          color="#246BFD"
          size={12}
          className="eye"
          style={{position: 'absolute', right: 0, top: -4}}
        />
      </div>

      <div className="custom-dropdown__name">{props.placeHolder}</div>
      <div
        className="position-absolute custom-dropdown-list"
        style={{
          display: state ? 'block' : 'none',
        }}
      >
        {[...(props.options || [])]?.map((item, index) => {
          let className = 'dropdown-item-first';
          if (index) {
            className = 'dropdown-item-rest';
          }

          return (
            <div
              className={className}
              onClick={(e) => {
                const evt: any = e.target;
                const dropdownValue = {
                  displayValue: evt.innerHTML,
                  id: evt.id,
                };

                props.stateChangeHandler && props.stateChangeHandler(dropdownValue);

                e.stopPropagation();
                setState(false);
              }}
              key={index}
              id={item.id}
            >
              {item.displayValue === '' || item.id === ''
                ? props.placeHolder
                : truncate(item.displayValue, props.truncateUpTo || 40)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterDropDown;
