import React, {createContext, FC, useContext, useEffect, useState} from 'react';

import {PaperTypes} from '../enums/paperTypes';
import {Paper} from '../models/Paper';
import {StructEssayPaper} from '../models/StructEssayPaper';
import PaperService from '../services/PaperService';

export interface PapersContext {
  allMCQTFPapers: Paper[];
  mcqPapers: Paper[];
  tfPapers: Paper[];
  allStructuredEssayPapers: StructEssayPaper[];
  structuredPapers: StructEssayPaper[];
  essayPapers: StructEssayPaper[];
  fetchPapers: () => void;
  fetchMCQsAndTFs: () => void;
  fetchStructuredEssay: () => void;
  removeById: (id: string) => void;
}

interface State {
  allMCQTFPapers: Paper[];
  mcqPapers: Paper[];
  tfPapers: Paper[];
  allStructuredEssayPapers: StructEssayPaper[];
  structuredPapers: StructEssayPaper[];
  essayPapers: StructEssayPaper[];
}

const initialContext: PapersContext = {
  allMCQTFPapers: [],
  mcqPapers: [],
  tfPapers: [],
  allStructuredEssayPapers: [],
  structuredPapers: [],
  essayPapers: [],
  fetchPapers: () => {},
  fetchMCQsAndTFs: () => {},
  fetchStructuredEssay: () => {},
  removeById: (id: string) => {},
};

const initialState: State = {
  allMCQTFPapers: [],
  mcqPapers: [],
  tfPapers: [],
  allStructuredEssayPapers: [],
  structuredPapers: [],
  essayPapers: [],
};

const Context = createContext<PapersContext>(initialContext);

export const PapersProvider: FC = (props) => {
  const [state, setState] = useState(initialState);

  const fetchPapers = async () => {
    try {
      const allMCQTFPapers: Paper[] = [];

      const responseMCQTF = await PaperService.getAllMCQTFPapers();

      responseMCQTF.forEach((doc) => {
        const paper = {id: doc.id, ...doc.data()} as Paper;

        allMCQTFPapers.push(paper);
      });

      const allStructuredEssayPapers: StructEssayPaper[] = [];

      const responseStructuredEssay = await PaperService.getAllStructuredEssayPapers();

      responseStructuredEssay.forEach((doc) => {
        const structuredEssayPaper = {id: doc.id, ...doc.data()} as StructEssayPaper;

        allStructuredEssayPapers.push(structuredEssayPaper);
      });

      const mcqs: Paper[] = allMCQTFPapers.filter((q) => q.paperType === PaperTypes.MCQ);
      const tfs: Paper[] = allMCQTFPapers.filter((q) => q.paperType === PaperTypes.TRUEFALSE);

      const sts: StructEssayPaper[] = allStructuredEssayPapers.filter(
        (q) => q.paperType === PaperTypes.STRUCTURED
      );
      const essays: StructEssayPaper[] = allStructuredEssayPapers.filter(
        (q) => q.paperType === PaperTypes.ESSAY
      );

      setState((ps) => ({
        ...ps,
        allMCQTFPapers: allMCQTFPapers,
        mcqPapers: mcqs,
        tfPapers: tfs,
        allStructuredEssayPapers: allStructuredEssayPapers,
        structuredPapers: sts,
        essayPapers: essays,
      }));
    } catch (e) {
      console.log('Fetch Error - ', e);
    }
  };

  const fetchMCQsAndTFs = async () => {
    const allMCQTFPapers: Paper[] = [];

    const responseMCQTF = await PaperService.getAllMCQTFPapers();

    responseMCQTF.forEach((doc) => {
      const paper = {id: doc.id, ...doc.data()} as Paper;

      allMCQTFPapers.push(paper);
    });

    const mcqs: Paper[] = allMCQTFPapers.filter((q) => q.paperType === PaperTypes.MCQ);
    const tfs: Paper[] = allMCQTFPapers.filter((q) => q.paperType === PaperTypes.TRUEFALSE);

    setState((ps) => ({
      ...ps,
      allMCQTFPapers: allMCQTFPapers,
      mcqPapers: mcqs,
      tfPapers: tfs,
    }));
  };

  const fetchStructuredEssay = async () => {
    const allStructuredEssayPapers: StructEssayPaper[] = [];

    const responseStructuredEssay = await PaperService.getAllStructuredEssayPapers();

    responseStructuredEssay.forEach((doc) => {
      const structuredEssayPaper = {id: doc.id, ...doc.data()} as StructEssayPaper;

      allStructuredEssayPapers.push(structuredEssayPaper);
    });

    const sts: StructEssayPaper[] = allStructuredEssayPapers.filter(
      (q) => q.paperType === PaperTypes.STRUCTURED
    );
    const essays: StructEssayPaper[] = allStructuredEssayPapers.filter(
      (q) => q.paperType === PaperTypes.ESSAY
    );

    setState((ps) => ({
      ...ps,
      allStructuredEssayPapers: allStructuredEssayPapers,
      structuredPapers: sts,
      essayPapers: essays,
    }));
  };

  const removeById = (id: string) => {
    const removedFromAllMCQTFPapers = state.allMCQTFPapers.filter((p) => p.id !== id);

    //if available remove
    const removedFromMCQPapers = state.mcqPapers.filter((p) => p.id !== id);
    const removedFromTFPapers = state.tfPapers.filter((p) => p.id !== id);

    setState((ps) => ({
      ...ps,
      allMCQTFPapers: removedFromAllMCQTFPapers,
      mcqPapers: removedFromMCQPapers,
      tfPapers: removedFromTFPapers,
    }));

    const removedFromAllStructuredEssayPapers = state.allStructuredEssayPapers.filter(
      (p) => p.id !== id
    );

    //if available remove
    const removedFromStructuredPapers = state.structuredPapers.filter((p) => p.id !== id);
    const removedFromEssayPapers = state.essayPapers.filter((p) => p.id !== id);

    setState((ps) => ({
      ...ps,
      allStructuredEssayPapers: removedFromAllStructuredEssayPapers,
      structuredPapers: removedFromStructuredPapers,
      essayPapers: removedFromEssayPapers,
    }));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchPapers();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Context.Provider
      value={{
        ...state,
        fetchPapers,
        fetchStructuredEssay,
        fetchMCQsAndTFs,
        removeById,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const usePapers = (): PapersContext => useContext(Context);
