import {useEffect, useState} from 'react';
import {IoCaretBack, IoDocumentText} from 'react-icons/io5';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import './ViewNotice.scss';
import renderHTML from 'react-render-html';
import moment from 'moment';
import truncate from 'truncate';
import {gradeOrder} from './CreateNotice';
import {Announcement, useAnnouncements} from '../context/AnnouncementContext';
import {useSubjects} from '../context/SubjectsContext';
import {useAppUser} from '../context/UserContext';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import {Subject} from '../models/Subject';

const ViewNotice = () => {
  const [state, setState] = useState<{data: Announcement}>();
  const {allSubjects} = useSubjects();
  const [subjectNames, setSubjectNames] = useState<string[]>([]);
  const {push} = useHistory();
  const [announcedToUsers, setAnnouncedToUsers] = useState<string[]>([]);
  const {firestoreUser} = useAppUser();

  const {allAdminsAndInstructors} = useAdminsAndInstructors();
  const {id} = useParams() as any;
  const {announcements} = useAnnouncements();
  //Get the user from the context
  const appUser = useAppUser();
  const subject = (useLocation().state as {subjectName: string; subjectId: string}) || undefined;
  useEffect(() => {
    const [a] = announcements.filter((x) => x.id === id);
    a?.grades?.filter((g) => a.subjects);
    setState({data: a});
  }, [id, announcements]);

  useEffect(() => {
    const names: string[] = [];
    const grades: string[] = [];
    state?.data?.subjects?.forEach((id) => {
      const [subject] = allSubjects.filter((s) => s.id === id);
      if (
        appUser.firestoreUser?.userRole?.isTeachingAssistant ||
        appUser.firestoreUser?.userRole?.isStudent
      ) {
        if (
          subject.enrolledStudents?.includes(appUser.firestoreUser.uid) ||
          subject.assignedUsers?.includes(appUser.firestoreUser.uid)
        ) {
          console.log('working');
          if (subject) {
            names.push(subject.name);
            grades.push(subject.grade);
          }
        }
      } else {
        console.log('not working');
        if (subject) names.push(subject.name);
      }
    });
    setSubjectNames(names);
  }, [allSubjects, state?.data?.subjects, appUser.firestoreUser]);

  useEffect(() => {
    if (
      appUser.firestoreUser?.userRole?.isTeachingAssistant ||
      appUser.firestoreUser?.userRole?.isStudent
    ) {
      setState((ps) => {
        const grades: string[] = [];
        ps?.data?.subjects?.forEach((id) => {
          const [subject] = allSubjects.filter((s) => s.id === id);
          if (
            subject.enrolledStudents?.includes(appUser.firestoreUser?.uid || '') ||
            subject.assignedUsers?.includes(appUser.firestoreUser?.uid || '')
          ) {
            console.log('working');
            if (subject) {
              grades.push(subject.grade);
            }
          }
        });
        const ug = grades.filter(function (item, pos) {
          return grades.indexOf(item) === pos;
        });

        const x = {...ps?.data};
        x.grades = ug;

        return {data: x} as any;
      });
    }
  }, [allSubjects, appUser.firestoreUser]);

  useEffect(() => {
    const subjects: Subject[] = [];
    state?.data?.subjects?.forEach((id) => {
      const [subject] = allSubjects.filter((s) => s.id === id);
      if (subject) subjects.push(subject);
    });

    if (subjects?.length > 0) {
      const userNames = subjects
        .map((s) => allAdminsAndInstructors.filter((u) => s.enrolledStudents?.includes(u.uid) || s.assignedUsers?.includes(u.uid)))
        .reduce((p, n) => p.concat(n))
        .filter((value, index, self) => index === self.findIndex((t) => t.uid === value.uid))
        .map((s) => s.username || '');
      setAnnouncedToUsers(userNames);
    }
  }, [allAdminsAndInstructors, state?.data?.subjects, allSubjects]);

  return (
    <div
      style={{
        paddingBottom: '30px',
      }}
    >
      <div className="s0 mb-4">
        <div
          className="btn-back"
          onClick={() => {
            if (subject) {
              push(`/my-subjects/subject/${subject.subjectId}/Announcements`, {
                subjectId: subject.subjectId,
              });
              // push(`/my-subjects/${subject.subjectName}`, {subjectId: subject.subjectId});
            } else {
              push('/announcements');
            }
          }}
        >
          <IoCaretBack />
        </div>
      </div>
      <div
        className="view-notice-wrapper"
        style={{marginLeft: '1rem', marginRight: '-1rem', marginTop: '2rem'}}
      >
        <div className="left-wrapper">
          <h2>{state?.data?.title}</h2>
          <div>{renderHTML(state?.data?.description || '')}</div>
          {(firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) && (
            <div className="lw-attachment">
              <label>Attachments</label>
              <div className="attachments-grid">
                {state?.data?.fileURLs?.map((f) => (
                  <FileIcon fileName={f.fileName} url={f.url} />
                ))}
              </div>
            </div>
          )}
          <div className="lw-sendto">
            <label className="st-title">Send to</label>
            <div>
              <label className="st-subtitle">Grade</label>
              {state?.data?.grades
                ?.sort((a, b) => {
                  const [an] = gradeOrder.filter((s) => s[0] === a);
                  const [bn] = gradeOrder.filter((s) => s[0] === b);
                  return (an[1] as number) - (bn[1] as number);
                })
                .map((g, index) => (
                  <label className="st-value m-2" key={index}>
                    {g}{' '}
                  </label>
                ))}
            </div>
            <div>
              <label className="st-subtitle">Subjects</label>{' '}
              <div
                style={{
                  maxHeight: '400px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  maxWidth: '400px',
                }}
              >
                {subjectNames.map((s, index) => (
                  <div className="st-value" key={index}>
                    {truncate(s || '', 50)}{' '}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="rigth-wrapper">
          <div className="rw-label">
            <label className="title">Announced on:</label>
            <label className="value">
              {moment(state?.data?.date?.toDate()).format('MM/DD/YYYY hh:mm a')}
            </label>
          </div>
          <div className="rw-label">
            <label className="title">Announced by:</label>
            <label className="value">{state?.data?.createdByName}</label>
          </div>

          {state?.data?.duration === 1 ? (
            <div className="rw-label">
              <label className="title">Duration:</label>
              <label className="value">{state?.data?.duration} Day</label>
            </div>
          ) : (
            <div className="rw-label">
              <label className="title">Duration:</label>
              <label className="value">{state?.data?.duration} Days</label>
            </div>
          )}
          {(firestoreUser?.userRole?.isStudent || firestoreUser?.userRole?.isTeachingAssistant) && (
            <div className="rw-attachment">
              <label>Attachments</label>
              <div>
                {state?.data?.fileURLs?.map((f) => (
                  <FileIcon fileName={f.fileName} url={f.url} />
                ))}
              </div>
            </div>
          )}
          {appUser.firestoreUser?.userRole?.isAdmin ||
          appUser.firestoreUser?.userRole?.isTeachingAssistant ||
          appUser.firestoreUser?.userRole?.isInstructor ? (
            <div className="rw-annto">
              <label>Announced To:</label>
              <div className="rw-users">
                <div style={{maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden'}}>
                  {announcedToUsers.map((username, index) => (
                    <div key={index}>{truncate(username || '', 35)}</div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ViewNotice;

const FileIcon = (props: {fileName: string; url: string}) => {
  return (
    <div
      className="fi-wrapper"
      onClick={() => {
        const link = document.createElement('a');
        link.href = props.url;
        link.setAttribute('download', props.fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      }}
    >
      <div className="file-icon">
        <div>
          {/* <MdClose className="close" /> */}
          <IoDocumentText className="micon" />
        </div>
      </div>
      <div className="fifn" style={{wordBreak:"break-all"}}>{props.fileName}</div>
    </div>
  );
};
