import React, {FC} from 'react';
import './UserRosterModalPackageCard.scss';

interface Props {
  id: string;
  title: string;
  packageType: string;
  remainingTime: string;
}

const UserRosterModalPackageCard: FC<Props> = (props) => {
  return (
    <div>
      <div className="packages-roster-modal-package-card" key={props.id}>
        <div className="d-flex">
          <img
            className="packages-roster-modal-package-card__img"
            src="https://img.freepik.com/free-vector/lesson-concept-illustration_114360-7917.jpg?w=2000"
            alt="package name"
          />
          <div className="packages-roster-modal-package-card__align-text-field">
            <div className="packages-roster-modal-package-card__content">
              <div className="packages-roster-modal-package-card__package-text">{props.title}</div>
              <div className="packages-roster-modal-package-card__package-text">
                {props.packageType}
              </div>
            </div>
            <div className="packages-roster-modal-package-card__expired-date">
              Time Left : {props.remainingTime}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRosterModalPackageCard;
