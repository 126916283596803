import React, {FC} from 'react';
import {FaCheck, FaCheckCircle, FaRegCircle, FaTimes} from 'react-icons/fa';
import renderHTML from 'react-render-html';
import {Answer, AnswerDTO} from '../models/Answer';
import './TrueFalseAnswerCard.scss';

interface Props {
  answer?: AnswerDTO;
  answerNo: number;
  className?: string;
  tfAnswerSelect?: (answerLabel: string) => void;
  static?: boolean;
}

const TrueFalseAnswerCard: FC<Props> = (props) => {
  let content = <></>;

  if (props.static) {
    const ans = props.answer as Answer;

    if (ans?.isCorrect && ans.isSelected) {
      content = (
        <div
          className={`answer-card ${props.className} ${
            props.answer?.isSelected && 'answer-card--selected'
          }`}
        >
          {props.answer?.isSelected ? (
            <FaCheckCircle color="#246bfd" className="answer-card__selected" />
          ) : (
            <FaRegCircle color="#979797" className="answer-card__not-selected-circle" />
          )}

          <div className="answer-card__question-no">{props.answerNo + 1 + '.'}</div>
          <div className="answer-card__answer">{renderHTML(props.answer?.answerText || '')}</div>
        </div>
      );
    }

    if (!ans?.isCorrect && props.answer?.isSelected) {
      content = (
        <div
          className={`answer-card ${props.className} ${
            props.answer?.isSelected && 'answer-card--selected'
          }`}
        >
          {props.answer?.isSelected ? (
            <FaCheckCircle color="#246bfd" className="answer-card__selected" />
          ) : (
            <FaRegCircle color="#979797" className="answer-card__not-selected-circle" />
          )}

          <div className="answer-card__question-no">{props.answerNo + 1 + '.'}</div>
          <div className="answer-card__answer d-flex">
            {renderHTML(props.answer?.answerText || '')}
          </div>
          <FaTimes className="answer-card__times-icon ml-auto" color="#e3646c" />
        </div>
      );
    }

    if (ans?.isCorrect && !ans.isSelected) {
      content = (
        <div
          className={`answer-card ${props.className} ${
            props.answer?.isSelected && 'answer-card--selected'
          }`}
          style={{background: '#172d17'}}
        >
          {props.answer?.isSelected ? (
            <FaCheckCircle color="#246bfd" className="answer-card__selected" />
          ) : (
            <FaRegCircle color="#979797" className="answer-card__not-selected-circle" />
          )}

          <div className="answer-card__question-no">{props.answerNo + 1 + '.'}</div>
          <div className="answer-card__answer d-flex">
            {renderHTML(props.answer?.answerText || '')}
          </div>
          <FaCheck className="answer-card__correct-tick ml-auto" color="#049b04" />
        </div>
      );
    }

    if (!ans?.isCorrect && !props.answer?.isSelected) {
      content = (
        <div className={`answer-card ${props.className}`}>
          {props.answer?.isSelected ? (
            <FaCheckCircle color="#246bfd" className="answer-card__selected" />
          ) : (
            <FaRegCircle color="#979797" className="answer-card__not-selected-circle" />
          )}

          <div className="answer-card__question-no">{props.answerNo + 1 + '.'}</div>
          <div className="answer-card__answer">{renderHTML(props.answer?.answerText || '')}</div>
        </div>
      );
    }
  } else {
    content = (
      <div
        className={`answer-card ${props.className} ${
          props.answer?.isSelected && 'answer-card--selected'
        }`}
        onClick={() => {
          props.answer && props.tfAnswerSelect && props.tfAnswerSelect(props.answer.answerText);
        }}
      >
        {props.answer?.isSelected ? (
          <FaCheckCircle color="#246bfd" className="answer-card__selected" />
        ) : (
          <FaRegCircle color="#979797" className="answer-card__not-selected-circle" />
        )}

        <div className="answer-card__question-no">{props.answerNo + 1 + '.'}</div>
        <div className="answer-card__answer">{renderHTML(props.answer?.answerText || '')}</div>
      </div>
    );
  }

  return content;
};

export default TrueFalseAnswerCard;
