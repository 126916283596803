import React, {FC} from 'react';
import './ListLayout.scss';

interface Props {
  style?: React.CSSProperties;
  className?: string;
}

const ListLayout: FC<Props> = (props) => {
  return (
    <div className={props.className || 'list-layout'} style={props.style}>
      <div className="list-layout-div">{props.children}</div>
    </div>
  );
};

export default ListLayout;
