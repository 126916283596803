import React, {FC} from 'react';
import {SETTINGS_MENU_ITEMS} from '../constants';
import {getMenuItems} from '../utils';
import Backarrow from './Backarrow';
import Tab from './Tab';

interface Props {
  onCurrentTabChange: (tab: string) => void;
  currentTab: string;
  screen: string;
}

const SettingsSecondaryNav: FC<Props> = (props) => {
  return (
    <>
      <div className="d-flex ml-3">
        <Backarrow onGoBack />
        <div style={{color: '#fff', fontSize: 20, marginBottom: 50}} className="ml-2">
          Settings
        </div>
      </div>

      {getMenuItems(
        SETTINGS_MENU_ITEMS,
        props.currentTab,
        props.onCurrentTabChange,
        props.screen
      ).map((item) => (
        <Tab
          text={item.text}
          onClick={props.onCurrentTabChange}
          key={item.text}
          active={item.active}
        />
      ))}
    </>
  );
};

export default SettingsSecondaryNav;
