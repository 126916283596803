import React, { useState } from 'react';
import './ForgotPassword.scss';
import { useHistory } from 'react-router';
import { useAuth } from 'reactfire';
import { ClipLoader } from 'react-spinners';
import { validateEmail } from '../validation/Validation';
import { validationResult } from '../utils';
import Notification from './../components/Notification';
import Backarrow from './Backarrow';
import TextInput from './TextInput';
import MainLayout from '../layouts/MainLayout';
import { passwordResetURL } from '../configs';

const ForgotPassword = () => {
  const auth = useAuth();

  const initialState = {
    loading: false,
    email: {
      value: '',
      error: '',
      validations: [validateEmail],
      label: 'Email',
    },
  };

  const history = useHistory();

  const [state, setState] = useState(initialState);

  const submitHandler = async () => {
    const validationOutput = validationResult({ ...state, loading: true });

    setState(validationOutput.state);

    //guard - 1
    if (!!validationOutput.formValidity) {
      return setState((pS) => ({
        ...pS,
        loading: false,
      }));
    }

    try {
      await auth.sendPasswordResetEmail(state.email.value, {
        url: `${passwordResetURL}/signin`,
      });
      // await forgotPassword(state.email.value);
      history.push(`/password-verification/${state.email.value}`);
    } catch (e) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      Notification({
        isSuccess: false,
        message: 'Failed to send password reset email',
      });
    }
  };

  const enterKeyDownHandler = (evt: any) => {
    if (evt.key === 'Enter') {
      submitHandler();
    }
  };

  return (
    <MainLayout rightHeightClassName="fp-right">
      <Backarrow onGoBack className="d-none d-lg-block" />
      <div className="forgot-password">
        <div className="forgot-password__title">Forgot Password?</div>
        <div className="forgot-password__subtitle">Enter your email address</div>

        <TextInput
          stateName="email"
          stateValue={state.email.value}
          state={state}
          setState={setState}
          error={state.email.error}
          onKeyPress={enterKeyDownHandler}
          placeHolder="Email"
          noErrorText
        />

        <div className="forgot-password__btn" onClick={submitHandler}>
          <span className="d-inline-block mr-2">Continue</span>{' '}
          <ClipLoader color={'purple'} loading={state.loading} size={18} />
        </div>
      </div>
    </MainLayout>
  );
};

export default ForgotPassword;
