import React, { FC, Fragment, useEffect, useState } from 'react';
import { useInterval } from 'react-interval-hook';
import { useParams } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import PrimaryNav from '../../components/PrimaryNav';
import StudentExtraExamView from '../../components/StudentExtraExamView';
import StudentLiveExamView from '../../components/StudentLiveExamView';
import StudentPractiseExamView from '../../components/StudentPractiseExamView';
import { ExamToDoDTO } from '../../dtos/ExamToDoDTO';
import { ExamProgressState } from '../../enums/ExamProgressState';
import { ExamType } from '../../enums/ExamType';
import LayoutNew from '../../layouts/LayoutNew';
import { Exam } from '../../models/Exam';
import BreadCrumbUpdate from '../../shared/BreadCrumbUpdate';
import { convertTimeStampToDate, convertUTCDateToLocalDate } from '../../utils';

interface Props {
  secondaryNav: any;
  selectedSubMenuItem: string;
  setSelectedExamsHandlers: (exam: ExamToDoDTO | null) => void;
  loading: boolean;
}

const StudentModalPapersScreen: FC<Props> = (props) => {
  const params = useParams() as { subjectId: string };

  const [examState, setExamState] = useState<{
    examList: ExamToDoDTO[];
    completedLiveAndPracs: ExamToDoDTO[];
    isLoading: boolean;
  }>({
    examList: [],
    completedLiveAndPracs: [],
    isLoading: false,
  });

  const [loadingState, setLoadingState] = useState(false);

  let secondaryNavigation = props.secondaryNav;

  let primaryNavigation = <PrimaryNav />;
  let appHeader = <BreadCrumbUpdate />;

  let appBody = null;

  const getHoursAndMinutes = (inputTime: string) => {
    const hours = inputTime.split(':')[0];
    const minutes = inputTime.split(':')[1];

    return [+hours, +minutes];
  };

  const getExamsRelatedUserSubjectsRef = useFunctions().httpsCallable(
    'getExamsRelatedUserSubjects'
  );

  const getCompletedLiveAndPracsExamsRef = useFunctions().httpsCallable(
    'getCompletedLiveAndPracsExams'
  );

  const examsFilterLogic = (examList: ExamToDoDTO[]) => {
    Array.isArray(examList) &&
      examList.forEach((exam, idx) => {
        if (exam.examType !== ExamType.EXTRA_EXAM) {
          const now = new Date();

          let startDate = new Date();
          let endDate = new Date();

          if (typeof exam.startDate !== 'string' && exam.examType === ExamType.LIVE_EXAM) {
            startDate = convertTimeStampToDate(exam.startDate);
            endDate = convertTimeStampToDate(exam.startDate);
          } else if (
            typeof exam.startDate !== 'string' &&
            exam.examType === ExamType.PRACTICE_EXAM
          ) {
            startDate = convertTimeStampToDate(exam.startDate);
            endDate = convertTimeStampToDate(exam.endDate);
          }

          const startTimeHoursAndMinutes = getHoursAndMinutes(exam?.startTime || '00:00');
          const endTimeHoursAndMinutes = getHoursAndMinutes(exam?.endTime || '00:00');

          const sd = startDate;
          sd.setHours(startTimeHoursAndMinutes[0]);
          sd.setMinutes(startTimeHoursAndMinutes[1]);
          sd.setSeconds(0);

          const ed = endDate;
          ed.setHours(endTimeHoursAndMinutes[0]);
          ed.setMinutes(endTimeHoursAndMinutes[1]);
          ed.setSeconds(0);

          //LEGACY DATA SUPPORT
          //needs sd

          if (+now > +convertUTCDateToLocalDate(exam.beginTime)) {
            if (
              exam?.examProgressState === ExamProgressState.UPCOMING ||
              exam?.examProgressState === 'UNDERMINDED'
            ) {
              setExamState((ps) => {
                exam.examProgressState = ExamProgressState.IN_PROGRESS;

                let updatedList;

                examState.examList[idx] = exam;
                updatedList = [...examState.examList];

                return {
                  ...ps,
                  examList: [...updatedList],
                };
              });
            }
          } else if (+now > +sd) {
            if (
              exam?.examProgressState === ExamProgressState.UPCOMING ||
              exam?.examProgressState === 'UNDERMINDED'
            ) {
              setExamState((ps) => {
                exam.examProgressState = ExamProgressState.IN_PROGRESS;

                let updatedList;

                examState.examList[idx] = exam;
                updatedList = [...examState.examList];

                return {
                  ...ps,
                  examList: [...updatedList],
                };
              });
            }
          }

          if (+now <= +convertUTCDateToLocalDate(exam.beginTime)) {
            setExamState((ps) => {
              exam.examProgressState = ExamProgressState.UPCOMING;

              let updatedList;

              examState.examList[idx] = exam;
              updatedList = [...examState.examList];

              return {
                ...ps,
                examList: [...updatedList],
              };
            });
          } else if (+now <= +sd) {
            setExamState((ps) => {
              exam.examProgressState = ExamProgressState.UPCOMING;

              let updatedList;

              examState.examList[idx] = exam;
              updatedList = [...examState.examList];

              return {
                ...ps,
                examList: [...updatedList],
              };
            });
          }

          // console.log(`exam id ${exam.id} - now ${+now} ed ${+et} val ${+now >= +et}`);

          //LEGACY DATA SUPPORT
          //needs ed

          if (exam.finishTime && +now >= +convertUTCDateToLocalDate(exam.finishTime)) {
            setExamState((ps) => {
              const rest = examState.examList?.filter((ex) => ex.id !== exam.id);

              return {
                ...ps,
                examList: [...rest],
              };
            });
          } else if (+now >= +ed) {
            setExamState((ps) => {
              const rest = examState.examList?.filter((ex) => ex.id !== exam.id);

              return {
                ...ps,
                examList: [...rest],
              };
            });
          }
        }
      });
  };

  useInterval(
    () => {
      examsFilterLogic(examState.examList);
    },
    1000,
    {
      autoStart: true,
      immediate: false,
      selfCorrecting: false,
      onFinish: () => {},
    }
  );

  useEffect(() => {
    setLoadingState(true);
    if (params.subjectId) {
      getExamsRelatedUserSubjectsRef({
        enrolledSubjectId: params.subjectId,
      })
        .then((res) => {
          //add the filter logic
          const allExams: ExamToDoDTO[] = res.data.filter((exam: Exam) => {
            const now = new Date();

            let endDate = new Date();

            if (exam.examType === ExamType.LIVE_EXAM) {
              endDate = convertTimeStampToDate(exam.startDate);
            } else if (exam.examType === ExamType.PRACTICE_EXAM) {
              endDate = convertTimeStampToDate(exam.endDate);
            } else {
              return true;
            }

            const endTimeHoursAndMinutes = getHoursAndMinutes(exam?.endTime || '00:00');

            const ed = endDate;
            ed.setHours(endTimeHoursAndMinutes[0]);
            ed.setMinutes(endTimeHoursAndMinutes[1]);
            ed.setSeconds(0);

            //LEGACY DATA SUPPORT
            //needs ed

            if (exam.beginTime && +now >= +convertUTCDateToLocalDate(exam.finishTime)) {
              return false;
            } else if (+now >= +ed) {
              return false;
            } else {
              return true;
            }
          });

          // console.log('completedArrays ', completedArrays);
          // console.log('allExams ', allExams);
          // console.log('stillToComplete ', stillToComplete);

          getCompletedLiveAndPracsExamsRef(params.subjectId)
            .then((res) => {
              const completedArrays: ExamToDoDTO[] = res.data;
              const stillToComplete = allExams.filter((val) => {
                if (completedArrays.map((cA) => cA.examId).includes(val.id)) {
                  return false;
                } else {
                  return true;
                }
              });

              // console.log('completedArrays ', completedArrays);
              // console.log('allExams ', allExams);
              // console.log('stillToComplete ', stillToComplete);

              setExamState((ps) => ({
                ...ps,
                completedLiveAndPracs: completedArrays,
                examList: stillToComplete,
              }));
              setLoadingState(false);
            })
            .catch((e) => {
              console.log('ERROR ', e);

              setLoadingState(false);
            });
        })
        .catch((e) => {
          console.log('ERROR ', e);

          setLoadingState(false);
          // props.setIsLoading(false);
        });
    } else {
      setLoadingState(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
      getExamsRelatedUserSubjectsRef({
        enrolledSubjectId: params.subjectId,
      })
        .then((res) => {
          //add the filter logic
          const allExams: ExamToDoDTO[] = res.data.filter((exam: Exam) => {
            const now = new Date();

            let endDate = new Date();

            if (exam.examType === ExamType.LIVE_EXAM) {
              endDate = convertTimeStampToDate(exam.startDate);
            } else if (exam.examType === ExamType.PRACTICE_EXAM) {
              endDate = convertTimeStampToDate(exam.endDate);
            } else {
              return true;
            }

            const endTimeHoursAndMinutes = getHoursAndMinutes(exam?.endTime || '00:00');

            const ed = endDate;
            ed.setHours(endTimeHoursAndMinutes[0]);
            ed.setMinutes(endTimeHoursAndMinutes[1]);
            ed.setSeconds(0);

            //LEGACY DATA SUPPORT
            //needs ed

            if (exam.beginTime && +now >= +convertUTCDateToLocalDate(exam.finishTime)) {
              return false;
            } else if (+now >= +ed) {
              return false;
            } else {
              return true;
            }
          });

          getCompletedLiveAndPracsExamsRef(params.subjectId)
            .then((res) => {
              const completedArrays: ExamToDoDTO[] = res.data;
              const stillToComplete = allExams.filter((val) => {
                if (completedArrays.map((cA) => cA.examId).includes(val.id)) {
                  return false;
                } else {
                  return true;
                }
              });

              setExamState((ps) => ({
                ...ps,
                completedLiveAndPracs: completedArrays,
                examList: stillToComplete,
                isLoading: false,
              }));
            })
            .catch((e) => {
              console.log('ERROR ', e);

              setLoading(false);
            });
        })
        .catch((e) => {
          console.log('ERROR ', e);

          setExamState((ps) => ({ ...ps, isLoading: false }));
          // props.setIsLoading(false);
        });
    }, 60000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setExamState((ps) => ({ ...ps, isLoading: props.loading }));
  }, [props.loading]);

  const setLoading = (value: boolean) => {
    setExamState((ps) => {
      return { ...ps, isLoading: value };
    });
  };

  if (props.selectedSubMenuItem === 'Live Exams') {
    appBody = (
      <Fragment>
        <StudentLiveExamView
          examsList={examState.examList}
          completedExamsList={examState.completedLiveAndPracs || []}
          subjectId={params.subjectId}
          isLoading={examState.isLoading}
          setSelectedExamsHandlers={props.setSelectedExamsHandlers}
        />
      </Fragment>
    );
  } else if (props.selectedSubMenuItem === 'Practice Exams') {
    appBody = (
      <Fragment>
        <StudentPractiseExamView
          examsList={examState.examList}
          completedExamsList={examState.completedLiveAndPracs || []}
          subjectId={params.subjectId}
          isLoading={examState.isLoading}
          setSelectedExamsHandlers={props.setSelectedExamsHandlers}
        />
      </Fragment>
    );
  } else if (props.selectedSubMenuItem === 'Extra Exams') {
    appBody = (
      <Fragment>
        <StudentExtraExamView
          examsList={examState.examList}
          completedExamsList={examState.completedLiveAndPracs || []}
          subjectId={params.subjectId}
          isLoading={examState.isLoading}
          setSelectedExamsHandlers={props.setSelectedExamsHandlers}
        />
      </Fragment>
    );
  }

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
      isLoading={loadingState}
    />
  );
};

export default React.memo(StudentModalPapersScreen);
