import React, { FC, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import BreadCrumbUpdate from '../shared/BreadCrumbUpdate';
import Button from '../shared/Button';
import LoadingIndicator from './LoadingIndicator';
import './SubjectDirectiveHeader.scss';
import { useAppUser } from '../context/UserContext';
import { FaPlusCircle } from 'react-icons/fa';

interface Props {
  isLoading?: boolean;
  isExamLoading?: boolean;
  isOpenLessonModel?: boolean;
  lessonModelHandler?: (value: boolean) => void;
  onClickAddPackage?: () => void;
}

const SubjectDirectiveHeader: FC<Props> = (props) => {
  const user = useAppUser();
  const history = useHistory();

  const lastSegment = decodeURI(history.location.pathname.split('/').pop() || '');

  return (
    <Fragment>
      <div className="sd-header">
        <BreadCrumbUpdate />
        {lastSegment === 'Lessons' ? (
          <Button
            style={{
              width: '158px',
              height: '36px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            text="Add Lesson"
            icon={{ icon: FaPlusCircle, isLeft: true, size: 16, gap: 8 }}
            onClick={() => {
              props.lessonModelHandler && props.lessonModelHandler(true);
            }}
          />
        ) : null}

        {user.firestoreUser?.userRole?.isAdmin && lastSegment === 'Packages' ? (
          <Button
            style={{
              width: '158px',
              height: '36px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            icon={{ icon: FaPlusCircle, isLeft: true, size: 16, gap: 8 }}
            text="Add Package"
            onClick={() => {
              props.onClickAddPackage && props.onClickAddPackage();
            }}
          />
        ) : null}
      </div>
      {props.isLoading ? <LoadingIndicator /> : null}
    </Fragment>
  );
};

export default SubjectDirectiveHeader;
