import firebase from 'firebase';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { GradingType } from '../constants';
import { usePaymentSwitch } from '../context/PaymentContext';
import { useAppUser } from '../context/UserContext';
import { ExamToDoDTO } from '../dtos/ExamToDoDTO';
import { PaperTypes } from '../enums/paperTypes';
import {
  convertStringToTwelveHoursClock,
  convertTimeStampToDate,
  getAbbrivationExam,
  removeDecimalSection,
} from '../utils';
import AlertPopup from './AlertPopup';
import './StudentPraciseExamCard.scss';

interface Props {
  exam: ExamToDoDTO;
  type: 'CURRENT' | 'UPCOMING' | 'COMPLETED';
  isLoading: boolean;
  setSelectedExamsHandlers?: (exam: ExamToDoDTO | null) => void;
}

const StudentPraciseExamCard: FC<Props> = (props) => {
  const history = useHistory();

  const params = useParams() as { subjectId: string };

  const hideOverallMark = props.exam.papers.filter((p) => {
    if (
      (p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED) &&
      !p.structEssayData?.answerSheetUrl
    ) {
      return false;
    } else if (
      (p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED) &&
      !p.released
    ) {
      return true;
    }
    return false;
  }).length;

  const [state, setState] = useState(false);

  const paySwitch = usePaymentSwitch();

  const appUser = useAppUser();

  const examTypesArray = props.exam.papers.map((value) => {
    if (value.paperType === 'TRUEFALSE') {
      return 'T/F';
    } else {
      return value.paperType;
    }
  });

  const examTypes = examTypesArray.map((x) => {
    return getAbbrivationExam(x);
  }) as string[];

  const set = new Set(examTypes);

  //LEGACY DATA SUPPORT
  const time =
    props.exam.beginTime && props.exam.finishTime
      ? {
          startDate: moment(props.exam.beginTime).format('DD / MM / YYYY'),
          startTime: moment(props.exam.beginTime).format('LT'),
          endDate: moment(props.exam.finishTime).format('DD / MM / YYYY'),
          endTime: moment(props.exam.finishTime).format('LT'),
        }
      : {
          startDate: moment(convertTimeStampToDate(props.exam.startDate)).format('DD / MM / YYYY'),
          startTime: convertStringToTwelveHoursClock(props.exam?.startTime || '00:00'),
          endDate: moment(convertTimeStampToDate(props.exam?.endDate)).format('DD / MM / YYYY'),
          endTime: convertStringToTwelveHoursClock(props.exam?.endTime || '00:00'),
        };

  //
  const endDate = convertTimeStampToDate(props.exam?.endDate);
  const serverDate = firebase.firestore.Timestamp.now().toDate();
  const [show, setShow] = useState(false);
  const [temp, setTemp] = useState(false);

  return (
    <div
      className="practise-exam-card "
      onClick={() => {
        if (props.type !== 'COMPLETED') {
          props.setSelectedExamsHandlers && props.setSelectedExamsHandlers(props.exam);
        } else if (serverDate < endDate) {
          if (temp) {
            return;
          } else {
            setShow(true);
            setTemp(true);
          }
        } else {
          history.push({
            pathname: `/my-subjects/subject/${params.subjectId}/exam/result/${
              props.exam?.id || props.exam?.examId
            }`,
            state: { exam: props.exam },
          });
        }
      }}
    >
      <div className="practise-exam-card__visible">
        <div className="practise-exam-card__date-time">
          <div className="practise-exam-card__date">
            <div className="practise-exam-card__start-date-con">
              <div className="practise-exam-card__start-label">Start</div>
              <div className="practise-exam-card__start-date">{time.startDate}</div>
              <div className="practise-exam-card__start-time">{time.startTime}</div>
            </div>
            <div className="practise-exam-card__end-date-com">
              <div className="practise-exam-card__end-label">End</div>
              <div className="practise-exam-card__end-date">{time.endDate}</div>
              <div className="practise-exam-card__end-time">{time.endTime}</div>
            </div>
          </div>
        </div>
        <div className="practise-exam-card__title d-none d-sm-block d-md-none d-xl-block">
          {props.exam?.examTitle}
        </div>
        <div className="practise-exam-card__price ml-auto d-md-none">
          {props.exam?.price && paySwitch.enabled ? (
            <>
              <span>Rs.</span>
              {props.exam?.price}
            </>
          ) : (
            'Free'
          )}
        </div>
        <div className="practise-exam-card__icon d-md-none">
          <FaChevronRight
            color="#687674"
            size={21}
            onClick={(e) => {
              setState(!state);
              e.stopPropagation();
            }}
            style={state ? { transform: 'rotate(90deg)' } : { transform: 'rotate(0deg)' }}
          />
        </div>

        <div className="d-none d-md-block practise-exam-card-md__type ml-auto">
          {Array.from(set).map((value, index) => (
            <div key={index}>{value}</div>
          ))}
        </div>
        {props.type === 'CURRENT' ? (
          <div className="d-none d-md-block practise-exam-card-md__status">
            {' '}
            {props.exam?.usersExamState?.find((u) => u.userId === appUser.fireUser?.uid) ? (
              'In Progress'
            ) : (
              <span style={{ color: '#F3B619' }}>Open for Submission</span>
            )}
          </div>
        ) : props.type === 'UPCOMING' ? (
          <div className="d-none d-md-block practise-exam-card-md__status"></div>
        ) : (
          <div className="d-none d-md-block practise-exam-card-md__score">
            {hideOverallMark ? (
              <>
                <div className="practise-exam-card-md__score-title">Score</div>
                <div className="practise-exam-card-md__score-value" style={{ color: '#F3B619' }}>
                  Pending...
                </div>
              </>
            ) : (
              <>
                <div className="practise-exam-card-md__score-title">Score</div>
                <div className="practise-exam-card-md__score-value">
                  {props.exam.gradingType === GradingType.PERCENTAGE
                    ? removeDecimalSection(props.exam.score?.toString() || '0') + ' %'
                    : `${props.exam.points + '/' + props.exam.totalPoints}`}
                </div>
              </>
            )}
          </div>
        )}

        <div className="practise-exam-card__price ml-auto d-none d-md-flex">
          {props.exam?.price && paySwitch.enabled ? (
            <>
              <span>Rs.</span>
              {props.exam?.price}
            </>
          ) : (
            'Free'
          )}
        </div>
      </div>
      <div className="mt-2 d-sm-none d-md-block d-xl-none">{props.exam?.examTitle}</div>
      <div className={`practise-exam-card__hidden d-md-none ${!state ? 'd-none' : ''}`}>
        {props.type === 'CURRENT' ? (
          <div className="practise-exam-card__status">
            {' '}
            {props.exam?.usersExamState?.find((u) => u.userId === appUser.fireUser?.uid) ? (
              'In Progress'
            ) : (
              <span style={{ color: '#F3B619' }}>Open for Submission</span>
            )}
          </div>
        ) : props.type === 'COMPLETED' ? (
          <>
            {hideOverallMark ? (
              <div className="practise-exam-card__status-pending-mobile">
                <div>Score</div>
                <div>Pending...</div>
              </div>
            ) : (
              <div className="practise-exam-card__status">
                Score{' '}
                <span>
                  {props.exam.gradingType === GradingType.PERCENTAGE
                    ? removeDecimalSection(props.exam.score?.toString() || '0') + ' %'
                    : `${props.exam.points + '/' + props.exam.totalPoints}`}
                </span>
              </div>
            )}
          </>
        ) : (
          <></>
        )}

        <div className="practise-exam-card__type">
          {Array.from(set).map((value, index) => (
            <div key={index}>{value}</div>
          ))}
        </div>
      </div>
      <AlertPopup
        message={'This exam is still in progress. Marks will be shown once the exam is over'}
        header="Attention"
        isShow={show}
        onClose={() => {
          setShow(false);
          setTemp(false);
        }}
        type="NO_BUTTON"
      />
    </div>
  );
};

export default StudentPraciseExamCard;
