import React, {FC} from 'react';
import useDimensions from 'use-element-dimensions';
import PaperExamBreadCrum from './PaperExamBreadCrum';
import './PaperHeader.scss';
import {PACKAGE_BREADCRUMB_STEPS} from '../enums/packageBreadCrum';
import {PAPER_BREADCRUM_STEPS} from '../enums/PaperBreadCrum';

interface Props {
  name: string;
  onClick?: () => void;
  paperIdPresent?: boolean;
  loading?: boolean;
  active: PACKAGE_BREADCRUMB_STEPS | PAPER_BREADCRUM_STEPS;
}

const PaperHeader: FC<Props> = (props) => {
  const [{width}, ref] = useDimensions();
  return (
    <div ref={ref}>
      <div className="header" style={{width: width}}>
        <div>{props.name}</div>
        <PaperExamBreadCrum
          active={props.active}
          stepOneName="General"
          stepTwoName="Creation"
          stepThreeName="Paper"
        />
      </div>
    </div>
  );
};

export default PaperHeader;
