import React, { FC } from 'react';
import { titleCase } from 'title-case';
import './ContentTextBox.scss';

interface Props {
  stateName: string;
  stateValue: string;
  onChangeHandler: Function;
  resetErrorHandler: Function;
  className?: string;
  style?: React.CSSProperties;
  error?: any;
  placeHolder?: string;
  onKeyPress?: (evt: any) => void;
  readonly?: boolean;
  noErrorText?: boolean;
  compulsory?: boolean;
}

const ContentTextBox: FC<Props> = (props) => {
  let errorJSX = null;

  if (props.error !== 'NO-ERROR') {
    errorJSX = <div className={`w-100 err-styles`}>{props.error}</div>;
  }

  let borderColor =
    props.error === 'NO-ERROR'
      ? { borderBottom: '1px solid #20D167' }
      : props.error === ''
      ? {}
      : { borderBottom: '1px solid #dc3545' };

  return (
    <div className={`w-100 ${props.className}`}>
      <div className="textinput" style={props.style}>
        <input
          type="text"
          name={props.stateName}
          autoComplete="off"
          required
          onChange={(e: any) => {
            props.onChangeHandler(e);
          }}
          value={props.stateValue}
          onFocus={() => {
            props.resetErrorHandler();
          }}
          onKeyDown={props.onKeyPress}
          readOnly={props.readonly}
        />

        <label htmlFor={props.stateName} className="textinput-label" style={borderColor}>
          <span style={{ transition: 'all 0.5s ease' }} className={`textinput-label-text`}>
            {props.placeHolder ? titleCase(props.placeHolder) : titleCase(props.stateName)}
          </span>
        </label>
      </div>

      {!props.noErrorText && errorJSX}
    </div>
  );
};

export default ContentTextBox;
