import React, {Fragment, useEffect, useState} from 'react';
import {PACKAGE_BREADCRUMB_STEPS} from '../../enums/packageBreadCrum';
import {Col, Row} from 'react-bootstrap';
import './AdminCreatePackage.scss';
import TextFieldInput from '../../components/TextFieldInput';
import {validateMinLength, validateString} from '../../validation/Validation';
import {FaImage} from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';
import truncate from 'truncate';
import ImageField from '../../components/ImageField';
import Notification from '../../components/Notification';
import {useFunctions, useStorage} from 'reactfire';
import {useAppUser} from '../../context/UserContext';
import DropDown from '../../components/DropDown';
import TreeView, {SELECT_TYPE, SelectedPair} from '../../components/ThreeView';
import DatePickerWithIcon from '../../components/DatePickerWithIcon';
import TimeInputComp from '../../components/TimeInputComp';
import {validationResult} from '../../utils';
import {SubjectHierarchy} from '../../dtos/HierarchyDTO';
import Button from '../../shared/Button';
import LoadingIndicator from '../../components/LoadingIndicator';
import {Package} from '../../models/Package';
interface FormState {
  loading: boolean;
  packageTitle: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
    max: number;
  };
  description: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
    max: number;
    min: number;
  };
  language: {
    value: {displayValue: string; id: string};
    error: string;
    label: string;
    type: string;
  };
  coverImage: {
    file: null | File;
    value: string;
    error: string;
    validations: Function[];
    label: string;
    loading: boolean;
  };
  packagePrice: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
    max: number;
  };
  packageType: {
    value: {displayValue: string; id: string};
    error: string;
    label: string;
    type: string;
  };
  startTime: {
    value: {hours: string | null; minutes: string | null};
    error: string;
    validations: any[];
    label: '';
  };
  startDate: {
    value: Date;
    error: string;
    validations: any[];
    label: string;
  };

  endTime: {
    value: {hours: string | null; minutes: string | null};
    error: string;
    validations: any[];
    label: string;
  };
  endDate: {
    value: Date;
    error: string;
    validations: any[];
    label: string;
  };
  duration: {
    value: {days: null | string; hours: string | null; minutes: string | null};
    error: string;
    validations: any[];
    label: string;
  };
  contents: {
    value: any[];
  };
  hierarchy: {
    value: SelectedPair[];
    materials: string[];
  };
}

interface CommonProps {
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

interface StepOneProps extends CommonProps {
  onNext: () => void;
}

const StepOne = (props: StepOneProps) => {
  const {formState, setFormState} = props;
  const appUser = useAppUser();
  const storage = useStorage();
  const types = ['image/png', 'image/jpeg'];

  const changeHandler = (e: any) => {
    let selected = e.target.files[0];
    if (selected && types.includes(selected.type)) {
      const size = selected.size / 1024 / 1024;
      if (size > 1) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
        return;
      }

      const storageRef = storage
        .ref()
        .child(`subjectImages/${appUser.fireUser?.uid}_${+new Date()}`);

      let subjectURL: any = null;

      setFormState((ps) => ({
        ...ps,
        coverImage: {...ps.coverImage, loading: true},
      }));

      storageRef.put(selected).on(
        'state_changed',
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          console.log('upload percentage ', percentage);
        },
        () => {
          Notification({
            isSuccess: false,
            message: 'Failed to upload image. ',
          });
          setFormState((ps) => ({
            ...ps,
            coverImage: {
              ...ps.coverImage,
              file: null,
              error: 'Failed to upload image',
              value: '',
              loading: false,
            },
          }));
        },
        async () => {
          subjectURL = await storageRef.getDownloadURL();

          setFormState((ps) => ({
            ...ps,
            coverImage: {
              ...ps.coverImage,
              file: selected,
              error: '',
              value: subjectURL || '',
              loading: false,
            },
          }));
        }
      );
    } else {
      setFormState((ps) => ({
        ...ps,
        coverImage: {
          ...ps.coverImage,
          file: null,
          error: 'Please select an image file (png or jpeg)',
          value: '',
        },
      }));
    }
  };

  const onNext = () => {
    const {packageTitle, description, coverImage} = formState;
    console.log(description);
    const validation = validationResult({packageTitle, description, coverImage});
    setFormState((ps) => ({
      ...ps,
      packageTitle: {...ps.packageTitle, error: validation.state.packageTitle.error},
      description: {...ps.description, error: validation.state.description.error},
      coverImage: {...ps.coverImage, error: validation.state.coverImage.error},
    }));

    if (validation.formValidity === '') {
      props.onNext();
    }
    console.log('validation', validation);
  };
  return (
    <Fragment>
      <div className="step_one">
        <Row>
          <Col className="d-flex">
            <div className="step_one_content-card" style={{padding: '15px 30px'}}>
              <div className="step_one_content-card__title">Subject</div>
              <div className="step_one_content-card__content">
                Information and Communication Technology
              </div>
            </div>
            <div className="step_one_content-card text-center">
              <div className="step_one_content-card__title">Year</div>
              <div className="step_one_content-card__content">2022</div>
            </div>
            <div className="step_one_content-card text-center">
              <div className="step_one_content-card__title">Grade</div>
              <div className="step_one_content-card__content">8</div>
            </div>
          </Col>
        </Row>
        <Row className="space__top-sm">
          <Col>
            <TextFieldInput
              name={'Package Title'}
              stateName={'packageTitle'}
              stateValue={formState.packageTitle.value}
              state={formState}
              setState={setFormState}
              error={formState.packageTitle.error}
            />
          </Col>
        </Row>
        <Row className="space__top-sm">
          <Col>
            <TextFieldInput
              name="Description"
              stateName={'description'}
              stateValue={formState.description.value}
              state={formState}
              setState={setFormState}
              error={formState.description.error}
            />
          </Col>
        </Row>
        <Row>
          <Col className="space__top-sm">
            <Row>
              <Col>
                <DropDown
                  name="Language"
                  noValueText="Select Language"
                  stateName="language"
                  stateValue={formState.language.value}
                  state={formState}
                  setState={setFormState}
                  style={{marginTop: 8}}
                  optionsArray={[
                    {
                      displayValue: 'English',
                      id: 'English',
                    },
                    {
                      displayValue: 'Sinhala',
                      id: 'Sinhala',
                    },
                  ]}
                  error={formState.language.error}
                />
              </Col>
            </Row>
            <ImageField
              style={{marginTop: '2.5%'}}
              stateName="coverImage"
              stateValue={truncate(formState.coverImage.file?.name || '', 15)}
              state={formState}
              setState={setFormState}
              error={formState.coverImage.error}
              placeHolder="Cover Image"
              fileLoading={formState.coverImage.loading}
              formLoading={formState.loading}
              onChange={changeHandler}
              readonly={true}
            />
          </Col>
          <Col md="auto">
            <div
              className="step_one_image position-relative"
              style={{backgroundImage: `url(${formState.coverImage.value})`}}
            >
              {!formState.coverImage.value && (
                <FaImage
                  color="#474A66"
                  style={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}
                  className="position-absolute"
                  size={40}
                />
              )}
              {formState.coverImage.loading && (
                <div
                  style={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}
                  className="position-absolute"
                >
                  <ClipLoader color="#246bfd" loading={true} size={90} speedMultiplier={0.7} />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="btn-medium" text={'Next'} onClick={onNext} />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

interface StepTwoProps extends CommonProps {
  onNext: () => void;
  onBack: () => void;
  subjectId: string;
}

const StepTwo = (props: StepTwoProps) => {
  const [hierarchy, setHierarchy] = useState<SubjectHierarchy | null>(null);
  const [selected, setSelected] = useState<SelectedPair[]>(props.formState.hierarchy.value);
  const [subjectLevelMaterial, setSubjectLevelMaterial] = useState<string[]>(
    props.formState.hierarchy.materials
  );
  const getPackageHierarchy = useFunctions().httpsCallable('getPackageHierarchy');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getPackageHierarchy({subjectId: props.subjectId}).then((res) => {
      setHierarchy(res.data.data.subject);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subjectId]);
  useEffect(() => {
    props.setFormState((ps) => ({
      ...ps,
      hierarchy: {value: selected, materials: ps.hierarchy.materials},
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    props.setFormState((ps) => ({
      ...ps,
      hierarchy: {value: ps.hierarchy.value, materials: subjectLevelMaterial},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectLevelMaterial]);

  const onNext = () => {
    if (selected.length > 0) {
      props.onNext();
    } else {
      // TODO: show error
    }
  };
  return (
    <Fragment>
      <div className="step_two">
        {loading ? <LoadingIndicator /> : null}
        <div style={{marginTop: '2rem', marginBottom: '2rem'}}>
          {hierarchy && (
            <TreeView
              data={hierarchy}
              selectedPairs={selected}
              setSelectedPairs={setSelected}
              setSubjectLevelMaterial={setSubjectLevelMaterial}
              subjectLevelMaterial={subjectLevelMaterial}
            />
          )}
        </div>
        <div className="d-flex">
          <div className="mr-3">
            <Button
              className="shared-btn-pkgcreate"
              text="Next"
              onClick={onNext}
              style={loading ? {display: 'none'} : {marginBottom: '2rem'}}
            />
          </div>
          <div>
            <Button
              className="shared-btn-pkgcreate shared-btn-pkgcreate--back"
              text="Back"
              onClick={props.onBack}
              style={loading ? {display: 'none'} : {marginBottom: '2rem'}}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

interface StepThreeProps extends CommonProps {
  onSave: (isPublish: boolean) => void;
  onBack: () => void;
}

const StepThree = (props: StepThreeProps) => {
  const {formState, setFormState} = props;
  useEffect(() => {
    if (
      formState.startTime.value &&
      formState.endTime.value &&
      formState.startDate.value &&
      formState.startDate.value
    ) {
      const start = new Date();
      start.setFullYear(formState.startDate.value.getFullYear());
      start.setMonth(formState.startDate.value.getMonth());
      start.setDate(formState.startDate.value.getDate());
      if (formState.startTime.value.hours) {
        start.setHours(parseInt(formState.startTime.value.hours));
      }
      if (formState.startTime.value.minutes) {
        start.setMinutes(parseInt(formState.startTime.value.minutes));
      }

      const end = new Date();
      end.setFullYear(formState.endDate.value.getFullYear());
      end.setMonth(formState.endDate.value.getMonth());
      end.setDate(formState.endDate.value.getDate());
      if (formState.endTime.value.hours) {
        end.setHours(parseInt(formState.endTime.value.hours));
      }
      if (formState.endTime.value.minutes) {
        end.setMinutes(parseInt(formState.endTime.value.minutes));
      }

      const diff = end.getTime() - start.getTime();
      if (
        !formState.duration.value.days ||
        !formState.duration.value.hours ||
        !formState.duration.value.minutes
      ) {
        setFormState((ps) => ({
          ...ps,
          duration: {
            ...ps.duration,
            value: {
              days: Math.floor(diff / (1000 * 60 * 60 * 24)).toString(),
              hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString(),
              minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)).toString(),
            },
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formState.startDate.value,
    formState.startTime.value,
    formState.endDate.value,
    formState.endTime.value,
  ]);

  return (
    <Fragment>
      <div className="step_three">
        <Row>
          <Col>
            <div className="step_three_content-card d-flex align-items-center">
              <div className="mr-5">
                <img alt="subject cover" src="https://picsum.photos/200/150" />
              </div>
              <div>
                <div className="step_three_content-card__title">
                  Technology Stream : With Engineering technology and Information Technology
                </div>
                <div className="step_three_content-card__content">
                  Graphics Processing Units, or GPUs for short, have been essential in enabling rich
                  rendering and computational application
                </div>
                <div className="mt-5">
                  <div className="d-flex">
                    <div className="step_three_content-card__content--year">2023</div>
                    <div className="step_three_content-card__content--author">
                      By Haritha Perera
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="step_three_content-card__content--subject">
                      Information and Communication Technology
                    </div>
                    <div className="step_three_content-card__content--grade">Grade 8</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <TextFieldInput
              name={'Package Price'}
              stateName={'packagePrice'}
              stateValue={formState.packagePrice.value}
              state={formState}
              setState={setFormState}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <DropDown
              name="Year"
              noValueText="Select Package Life Time"
              stateName="packageType"
              stateValue={formState.packageType.value}
              state={formState}
              setState={setFormState}
              style={{marginTop: 8}}
              optionsArray={[
                {
                  displayValue: 'Limited',
                  id: 'Limited',
                },
                {
                  displayValue: 'Unlimited',
                  id: 'Unlimited',
                },
              ]}
              error={formState.packageType.error}
            />
          </Col>
        </Row>
        {formState.packageType.value.id === 'Limited' && (
          <>
            <Row className="mt-5">
              <Col md={3}>
                <DatePickerWithIcon
                  minDate={new Date()}
                  name="Start Date"
                  stateName="startDate"
                  stateValue={formState.startDate.value}
                  state={formState}
                  setState={setFormState}
                  error={formState.startDate.error}
                />
              </Col>
              <Col md={2}>
                <TimeInputComp
                  layoutStyles={{marginTop: -4}}
                  stateName="startTime"
                  text="Start time"
                  state={formState}
                  setState={setFormState}
                  style={{}}
                  error={formState.startTime.error}
                  dateTime
                />
              </Col>
              <Col md={3}>
                <DatePickerWithIcon
                  name="End Date"
                  stateName="endDate"
                  stateValue={formState.endDate.value}
                  state={formState}
                  setState={setFormState}
                  error={formState.endDate.error}
                  minDate={formState.startDate.value}
                />
              </Col>
              <Col md={2}>
                <TimeInputComp
                  dateTime
                  layoutStyles={{marginTop: -4}}
                  stateName="endTime"
                  text="End Time"
                  state={formState}
                  setState={setFormState}
                  style={{}}
                  error={formState.endTime.error}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <TimeInputComp
                  dateTime
                  layoutStyles={{marginTop: -4}}
                  stateName="duration"
                  text="Duration"
                  state={formState}
                  setState={setFormState}
                  style={{}}
                  error={formState.duration.error}
                  isDays={true}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-5">
          <Col>
            <div className="d-flex">
              <div className="mr-2">
                <Button
                  text="Save"
                  onClick={() => {
                    props.onSave(false);
                  }}
                />
              </div>
              <div className="mr-2">
                <Button
                  text="Save and Publish"
                  onClick={() => {
                    props.onSave(true);
                  }}
                />
              </div>
              <div className="mr-2">
                <Button text="Back" onClick={props.onBack} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

interface Props {
  active: PACKAGE_BREADCRUMB_STEPS;
  setStep: (step: PACKAGE_BREADCRUMB_STEPS) => void;
  onSubmit: (data: any) => void;
  subjectId: string;
  data?: Package[];
}

const AdminPackageCreate = (props: Props) => {
  console.log(props.data);

  const startDate =
    props.data && props.data[0].startDate ? new Date(props.data[0].startDate) : new Date();
  const endDate =
    props.data && props.data[0].endDate ? new Date(props.data[0].endDate) : new Date();
  const startTime =
    props.data && props.data[0].startTime ? props.data[0].startTime.split(':') : [null, null];
  const endTime =
    props.data && props.data[0].endTime ? props.data[0].endTime.split(':') : [null, null];
  const initialState: FormState = {
    loading: false,
    packageTitle: {
      value: `${props.data ? props.data[0].title : ''}`,
      error: '',
      validations: [validateString],
      label: 'Package Title',
      max: 100,
    },
    description: {
      value: `${props.data ? props.data[0].description : ''}`,
      error: '',
      validations: [validateString, validateMinLength],
      label: 'Description',
      max: 1000,
      min: 20,
    },
    language: {
      value: {
        displayValue: `${props.data ? props.data[0].language : ''}`,
        id: `${props.data ? props.data[0].language : ''}`,
      },
      error: '',
      label: 'Year',
      type: 'dropdown',
    },
    coverImage: {
      file: null,
      value: `${props.data ? props.data[0].coverImage : ''}`,
      error: '',
      validations: [validateString],
      label: 'Cover image',
      loading: false,
    },
    packagePrice: {
      value: `${props.data ? props.data[0].price : ''}`,
      error: '',
      validations: [validateString],
      label: 'Package Price',
      max: 100,
    },
    packageType: {
      value: {
        displayValue: `${props.data ? props.data[0].type : ''}`,
        id: `${props.data ? props.data[0].type : ''}`,
      },
      error: '',
      label: 'Year',
      type: 'dropdown',
    },
    startDate: {
      value: startDate,
      error: '',
      validations: [validateString],
      label: 'start date',
    },
    startTime: {
      value: {
        hours: startTime[0],
        minutes: startTime[1],
      },
      error: '',
      validations: [validateString],
      label: '',
    },
    endDate: {
      value: endDate,
      error: 'NO-ERROR',
      validations: [validateString],
      label: 'start date',
    },
    endTime: {
      value: {
        hours: endTime[0],
        minutes: endTime[1],
      },
      error: '',
      validations: [validateString],
      label: '',
    },
    duration: {
      value: {
        days: props.data && props.data[0].duration ? props.data[0].duration['days'] : null,
        hours: props.data && props.data[0].duration ? props.data[0].duration['hours'] : null,
        minutes: props.data && props.data[0].duration ? props.data[0].duration['minutes'] : null,
      },
      error: '',
      validations: [validateString],
      label: '',
    },
    contents: {
      value: [],
    },
    hierarchy: {
      value: props.data && props.data[0].contents?.lessons ? props.data[0].contents?.lessons : [],
      materials:
        props.data && props.data[0].contents?.materials ? props.data[0].contents?.materials : [],
    },
  };

  const [formState, setFormState] = useState<FormState>(initialState);

  const onSave = (isPublish: boolean) => {
    const filteredContents = formState.hierarchy.value.filter(
      (item) => item.selectedType !== SELECT_TYPE.NONE
    );
    const data = {
      title: formState.packageTitle.value,
      description: formState.description.value,
      language: formState.language.value.id,
      coverImage: formState.coverImage.value,
      price: formState.packagePrice.value,
      type: formState.packageType.value.id,
      startDate: formState.startDate.value.toISOString(),
      startTime: formState.startTime.value.hours + ':' + formState.startTime.value.minutes,
      endDate: formState.endDate.value.toDateString(),
      endTime: formState.endTime.value.hours + ':' + formState.endTime.value.minutes,
      duration: formState.duration.value,
      contents: {
        lessons: filteredContents,
        materials: formState.hierarchy.materials,
      },
      isPublish: isPublish,
      subjectId: props.subjectId,
    };
    console.log('OnSave', data);
    props.onSubmit(data);
  };
  if (props.active === PACKAGE_BREADCRUMB_STEPS.STEP_ONE) {
    return (
      <StepOne
        onNext={() => {
          props.setStep(PACKAGE_BREADCRUMB_STEPS.STEP_TWO);
        }}
        formState={formState}
        setFormState={setFormState}
      />
    );
  } else if (props.active === PACKAGE_BREADCRUMB_STEPS.STEP_TWO) {
    return (
      <StepTwo
        setFormState={setFormState}
        formState={formState}
        onNext={() => {
          props.setStep(PACKAGE_BREADCRUMB_STEPS.STEP_THREE);
        }}
        onBack={() => {
          props.setStep(PACKAGE_BREADCRUMB_STEPS.STEP_ONE);
        }}
        subjectId={props.subjectId}
      />
    );
  } else if (props.active === PACKAGE_BREADCRUMB_STEPS.STEP_THREE) {
    return (
      <StepThree
        formState={formState}
        setFormState={setFormState}
        onSave={onSave}
        onBack={() => {
          props.setStep(PACKAGE_BREADCRUMB_STEPS.STEP_TWO);
        }}
      />
    );
  }
  return <Fragment></Fragment>;
};
export default AdminPackageCreate;
