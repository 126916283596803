import React, {FC, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaCheckCircle} from 'react-icons/fa';
import {titleCase} from 'title-case';
import './TextInput.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  stateName: string;
  stateValue: string;
  state: any;
  setState: Function;
  verified?: boolean;
  error?: any;
  placeHolder?: string;
  onKeyPress?: (evt: any) => void;
  readonly?: boolean;
  noErrorText?: boolean;
  disabled?: boolean;
}

const TextInput: FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputChangeHandler = (state: any, name: string, value: string) => {
    props.setState({
      ...state,
      [name]: {...state[name], value: value},
    });
  };

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: {...state[name], error: ''},
    });
  };

  const displayPassword = () => {
    setShowPassword(!showPassword);
  };

  let errorJSX = null;

  if (props.error !== 'NO-ERROR') {
    errorJSX = <div className={`w-100 err-styles`}>{props.error}</div>;
  }

  let showEmailVerifiedicon = null;

  if (props.stateName === 'email' && props.verified) {
    showEmailVerifiedicon = (
      <OverlayTrigger placement="top" overlay={<Tooltip id="verifiedId">verified</Tooltip>}>
        <FaCheckCircle
          color="#246BFD"
          size={13}
          className="eye"
          onClick={displayPassword}
          style={{position: 'absolute', right: 0, top: 3.5}}
        />
      </OverlayTrigger>
    );
  }

  let borderColor =
    props.error === 'NO-ERROR'
      ? {borderBottom: '1px solid #20D167'}
      : props.error === ''
      ? {}
      : {borderBottom: '1px solid #dc3545'};

  return (
    <div className={`w-100 ${props.className}`}>
      <div className="textinput" style={props.style}>
        <input
          type="text"
          name={props.stateName}
          autoComplete="off"
          required
          onChange={(e: any) => {
            inputChangeHandler(props.state, props.stateName, e.target.value);
          }}
          value={props.stateValue}
          onKeyUp={() => {
            resetError(props.state, props.stateName);
          }}
          onKeyDown={props.onKeyPress}
          readOnly={props.readonly}
        />

        <label htmlFor={props.stateName} className="textinput-label" style={borderColor}>
          <span className="textinput-label-text">
            {props.placeHolder ? titleCase(props.placeHolder) : titleCase(props.stateName)}
          </span>
        </label>
        {showEmailVerifiedicon}
      </div>

      {!props.noErrorText && errorJSX}
    </div>
  );
};

export default TextInput;
