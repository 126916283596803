import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {useFirestore} from 'reactfire';
import {Payment} from '../models/Payment';

export interface PaymentContext {
  enabled: boolean;
  payments: Payment[];
}

interface State {
  enabled: boolean;
  payments: Payment[];
}

const initialContext: PaymentContext = {
  enabled: false,
  payments: [],
};

const initialState: State = {
  enabled: false,
  payments: [],
};
function removeDuplicates(array: Payment[]) {
  let uniq: any = {};
  return array.filter((obj) => !uniq[obj.id] && (uniq[obj.id] = true));
}

const Context = createContext<PaymentContext>(initialContext);

export const PaymentSwitchProvider: FC = ({children}) => {
  const firestore = useFirestore();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let pmts: Payment[] = [];

    firestore.collection('payments').onSnapshot((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        pmts.push({id: doc.id, ResourceID: doc.data().ResourceID, userID: doc.data().userID});
      });

      const arrUniq = removeDuplicates(pmts);

      setState((ps) => {
        return {...ps, payments: arrUniq};
      });
    });
  }, [firestore]);

  return (
    <Context.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const usePaymentSwitch = (): PaymentContext => useContext(Context);
