import React, {FC} from 'react';
import {RESOURCE_MENU_ITEMS} from '../constants';
import {getMenuItems} from '../utils';
import Backarrow from './Backarrow';
import Tab from './Tab';

interface Props {
  onCurrentTabChange: (tab: string) => void;
  onCurrentSubTabChange?: (subTab: string) => void;
  currentTab: string;
  currentSubTab?: string;
  screen: string;
}

const ResourceSecondaryNav: FC<Props> = (props) => {
  return (
    <>
      <div className="d-flex ml-3">
        <Backarrow onGoBack />
        <div style={{color: '#fff', fontSize: 20, marginBottom: 50}} className="ml-2">
          Resources
        </div>
      </div>

      {getMenuItems(
        RESOURCE_MENU_ITEMS,
        props.currentTab,
        props.onCurrentTabChange,
        props.screen,
        props.onCurrentSubTabChange,
        props.currentSubTab
      ).map((item) => (
        <Tab
          text={item.text}
          onClick={props.onCurrentTabChange}
          onSubTabClick={props.onCurrentSubTabChange}
          key={item.text}
          active={item.active}
          subMenuItems={item.subMenuItems}
        />
      ))}
    </>
  );
};

export default ResourceSecondaryNav;
