import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {useFirestore} from 'reactfire';
import {UserFirestore} from '../models/UserFirestore';

export interface AdminsAndInstructors {
  allAdminsAndInstructors: UserFirestore[];
  fetchAdminsAndInstructors: () => void;
  setUsers: (users: UserFirestore[]) => void;
}

interface State {
  allAdminsAndInstructors: UserFirestore[];
}

const initialContext: AdminsAndInstructors = {
  allAdminsAndInstructors: [],
  fetchAdminsAndInstructors: () => {},
  setUsers: (users: UserFirestore[]) => {},
};

const initialState: State = {
  allAdminsAndInstructors: [],
};

const Context = createContext<AdminsAndInstructors>(initialContext);

export const AdminsAndInstructorsProvider: FC = ({children}) => {
  const [state, setState] = useState(initialState);

  const fetchAdminsAndInstructors = () => {
    store
      .collection('users')
      .get()
      .then((data) => {
        const users: UserFirestore[] = [];

        data.forEach((d) => {
          users.push({id: d.id, ...d.data()} as UserFirestore);
        });

        setState((ps) => ({
          ...ps,
          allAdminsAndInstructors: users,
        }));
      })
      .catch((err) => {
        console.log('ERROR : ', err);
      });
  };

  const store = useFirestore();

  const setUsers = (users: UserFirestore[]) => {
    setState(() => {
      return {...state, allAdminsAndInstructors: users};
    });
  };

  useEffect(() => {
    fetchAdminsAndInstructors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Context.Provider
      value={{
        ...state,
        fetchAdminsAndInstructors,
        setUsers,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAdminsAndInstructors = (): AdminsAndInstructors => useContext(Context);
