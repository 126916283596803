import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './TRQuestionTab.scss';
import { FaFilter, FaPlusCircle } from 'react-icons/fa';
import { QuestionData } from '../interfaces';
import { useQuestions } from '../context/QuestionsContext';
import { useAppUser } from '../context/UserContext';
import { useSubjects } from '../context/SubjectsContext';
import { checkArrayContains, getTFQuestionBasedOnRole, splitArrayIntoChunksOfLen } from '../utils';
import { Question, TFQuestion } from '../models/Question';
import SearchBox from './SearchBox';
import LoadingIndicator from './LoadingIndicator';
import QuestionCard from './QuestionCard';
import Pagination from './Pagination';
import DropDown from './DropDown';
import { GRADES, QUESTION_SOURCES_OPTIONS } from '../constants';
import TinyMCEEditor from './TinyMCEEditor';
import TrueFalseAnswer from './TrueFalseAnswer';
import LabelDropDown from './LabelDropDown';
import TextInput from './TextInput';
import QuestionService from '../services/QuestionService';
import FilterQuestionsPopUp from './FilterQuestionsPopUp';
import Label from './Label';
import ComplexityRangeComp from './ComplexityRangeComp';
import { useLabels } from '../context/LabelContext';
import PreviewHeaderActionBar from './PreviewHeaderActionBar';
import QuestionBody from './QuestionBody';
import AnswerCard from './AnswerCard';
import ComplexityIndicator from './ComplexityIndicator';
import { useLessons } from '../context/LessonsContext';
import SubjectDropDown from './SubjectDropDown';
import LessonDropDown from './LessonDropDown';
import UnitDropDown from './UnitDropDown';

interface Props {
  state?: any;
  setState: Function;
  subjects: any;
  lessons: any;
  units: any;
  questionTextChangeHandler: (e: string) => void;
  onComplexityChange: (complexity: number) => void;
  onChange: (value: boolean) => void;
  onChangeDefaultTab: (
    value: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT',
    questionData: QuestionData | null
  ) => void;
  tabState: { mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT'; questionData: QuestionData | null };
  labels: any;
  scrollToTopRef: React.RefObject<HTMLDivElement>;
}

const TRQuestionTab: FC<Props> = (props) => {
  let content = null;

  if (props.tabState.mode === 'VIEW') {
    content = <DisplayTFQuestions {...props} />;
  }

  if (props.tabState.mode === 'CREATE' || props.tabState.mode === 'EDIT') {
    content = <TFCreateQuestion {...props} />;
  }

  if (props.tabState.mode === 'PREVIEW') {
    content = <PreviewQuestionTF {...props} />;
  }

  return content;
};

export interface StateDisplayTF {
  questions: any;
  loading: boolean;
  searchText: string;
  grade: string | null;
  subject?: { value: { displayValue: string; id: string } };
  lesson?: { value: { displayValue: string; id: string } };
  unit?: { value: { displayValue: string; id: string } };
  labels?: { value: { displayValue: string; id: string }[] };
  user?: { value: { displayValue: string; id: string } };
  complexity?: number;
  page: number;
  totalPages: number;
  filtered: boolean;
}

const DisplayTFQuestions: FC<Props> = (props) => {
  const appQuestions = useQuestions();
  const appUser = useAppUser();
  const appSubjects = useSubjects();

  const initialState: StateDisplayTF = {
    questions: [],
    loading: false,
    searchText: '',
    grade: null,
    subject: { value: { displayValue: '', id: '' } },
    lesson: { value: { displayValue: '', id: '' } },
    unit: { value: { displayValue: '', id: '' } },
    labels: { value: [] },
    user: { value: { displayValue: '', id: '' } },
    complexity: undefined,
    page: 0,
    totalPages: 0,
    filtered: false,
  };

  const [state, setState] = useState<StateDisplayTF>(initialState);

  const setCurrentPage = (page: number) => {
    setState((ps) => {
      return { ...ps, page: page };
    });
  };

  const [show, setShow] = useState(false);

  const openModalHandelr = () => {
    setShow(true);
  };

  const closeModalHander = () => {
    setShow(false);
  };

  const fetchDataCall = (filter?: boolean) => {
    if (typeof state.totalPages === 'number') {
      setState((ps) => {
        return { ...ps, loading: true };
      });

      const pageToSend = filter ? 0 : state.page - 1 < 0 ? 0 : state.page - 1;

      let filteredList = getTFQuestionBasedOnRole(
        appUser.firestoreUser?.uid || '',
        appSubjects.allSubjects,
        appUser.firestoreUser?.userRole,
        appQuestions.tfQuestions
      );

      if (state?.grade?.length) {
        filteredList = filteredList.filter((item) => state?.grade?.includes(item.grade));
      }

      if (state?.subject?.value.id) {
        filteredList = filteredList.filter((item) => item.subjectId === state?.subject?.value.id);
      }

      if (state.lesson?.value.id) {
        filteredList = filteredList.filter((item) => item.lessonId === state.lesson?.value.id);
      }

      if (state.unit?.value.id) {
        filteredList = filteredList.filter((item) => item.unitId === state.unit?.value.id);
      }

      if (state.labels?.value.length) {
        filteredList = filteredList.filter((item) => {
          return checkArrayContains(item.labels || [], state.labels?.value.map((v) => v.id) || []);
        });
      }

      // if (state.labels?.value.length) {
      //   filteredList = filteredList.filter((item) => {
      //     let value = false;

      //     state.labels?.value.forEach((l) => {
      //       if (item.labels?.includes(l.id)) {
      //         value = true;
      //       }
      //     });

      //     return value;
      //   });
      // }

      if (!(state?.complexity === undefined || state?.complexity === null)) {
        filteredList = filteredList.filter((item) => item.complexity === state.complexity);
      }

      if (state?.user?.value.id) {
        filteredList = filteredList.filter((item) => item.createdBy === state?.user?.value.id);
      }

      // let searchTextResult: Question[] = [];
      let duplicatesRemovedArray: Question[];

      if (state?.searchText?.trim()) {
        // const words = state?.searchText.trim()?.split(' ');

        // for (const word in words) {

        //   searchTextResult = [
        //     ...searchTextResult,
        //     ...filteredList.filter(
        //       (item) => matchSorter(item.question.split(' '), words[word].trim() || '').length
        //     ),
        //   ];
        // }

        // duplicatesRemovedArray = Array.from(new Set(searchTextResult));

        duplicatesRemovedArray = filteredList.filter((item) =>
          item.question
            .replace(/<[^>]+>/g, '')
            .replace(/&nbsp;/g, '')
            .toUpperCase()
            .startsWith(state.searchText.trim().toUpperCase())
        );
      } else {
        duplicatesRemovedArray = filteredList;
      }

      let pagesList: Question[] = [];

      if (duplicatesRemovedArray.length) {
        pagesList = splitArrayIntoChunksOfLen(duplicatesRemovedArray, 10);
      }

      const totalPages = pagesList.length;

      setState((ps) => {
        return {
          ...ps,
          loading: false,
          questions: pagesList[pageToSend] || [],
          totalPages: totalPages,
          filtered: filter ? true : false,
        };
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (appQuestions.allQuestions.length) {
        fetchDataCall();
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabState.mode, state.page]);

  const applyFiltersHandler = () => {
    fetchDataCall(true);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setState((ps) => {
        return { ...ps, filtered: false };
      });
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filtered]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (appQuestions.allQuestions.length) {
        fetchDataCall(true);
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchText, appQuestions.allQuestions.length]);

  return (
    <div style={{ marginTop: 90 }}>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <div className="d-flex position-relative">
          <SearchBox
            onSearch={(text) => {
              setState((ps) => {
                return { ...ps, searchText: text };
              });
            }}
            placeHolderText="Search Question"
          />
          <button className="filter_button" onClick={openModalHandelr}>
            Filters &nbsp;
            <FaFilter />
          </button>
          <FilterQuestionsPopUp
            show={show}
            onCloseModal={closeModalHander}
            setState={setState}
            state={state}
            initialState={initialState}
            onApplyfilter={applyFiltersHandler}
          />
        </div>
        <button
          className="create-question-btn"
          onClick={() => {
            props.onChangeDefaultTab('CREATE', null);
          }}
        >
          <FaPlusCircle /> Create T/F Question
        </button>
      </div>
      {state.loading && <LoadingIndicator className="mt-3" />}
      <div
        className="q-card-tbl-titles d-flex"
        style={{ marginTop: state.loading ? 30 : 45, transition: 'none' }}
      >
        <div>Type</div>
        <div>Title</div>
        <div>Complexity</div>
      </div>

      <div className="q-card-list">
        {state.questions.map((qtn: any, idx: any) => (
          <QuestionCard
            qtn={qtn}
            complexity={qtn.complexity}
            type="T/F"
            onClick={() => {
              const qdata: QuestionData = {
                questionId: qtn.id,
                subjectId: qtn.subjectId,
                lessonId: qtn.lessonId || '',
                unitId: qtn.unitId || '',
                grade: qtn.grade || '',
                createdBy: qtn.createdBy || '',
                labelIds: qtn.labels || [],
              };

              props.onChangeDefaultTab('PREVIEW', qdata);
            }}
            key={idx}
            searchText={state.searchText}
          />
        ))}
      </div>
      {!state.filtered && state.totalPages > 1 ? (
        <Pagination pages={state.totalPages} setCurrentPage={setCurrentPage} />
      ) : (
        <></>
      )}
    </div>
  );
};

const TFCreateQuestion: FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const appLessons = useLessons();

  const resetQuestionErrors = () => {
    props.setState &&
      props.setState((ps: any) => {
        return { ...ps, question: { ...ps.question, error: '' } };
      });
  };
  const func = () => {};

  const inputChangeHandler = (state: any, name: string, id: string) => {
    const array = state[name].value.filter((value: any) => value.id !== id);

    props.setState &&
      props.setState({
        ...state,
        [name]: { ...state[name], value: array },
      });
  };

  return (
    <div className="tr-edit-page">
      <Row className="tr-dropdown-component no-gutters">
        {props.state.loading && <LoadingIndicator />}
        <Col md={3}>
          <DropDown
            name="Grade"
            noValueText="Select grade"
            className="textinput-margin-signup px-0"
            stateName="grade"
            stateValue={props.state.grade.value}
            state={props.state}
            setState={props.setState}
            error={props.state.grade.error}
            optionsArray={GRADES}
          />
        </Col>
        <Col md={4} className="mx-auto">
          {/* <DropDown
            name="Subject"
            noValueText="Select subject"
            className="textinput-margin-signup"
            stateName="subject"
            stateValue={props.state.subject.value}
            state={props.state}
            setState={props.setState}
            error={props.state.subject.error}
            optionsArray={props.subjects}
          /> */}

          <SubjectDropDown
            name="Subject"
            noValueText="Select subject"
            className="textinput-margin-signup"
            stateName="subject"
            stateValue={props.state.subject.value}
            state={props.state}
            setState={props.setState || func}
            error={props.state.subject.error}
            optionsArray={props.subjects}
          />
        </Col>
        <Col md={4}>
          {/* <DropDown
            name="Lesson"
            noValueText="Select lesson"
            className="textinput-margin-signup"
            stateName="lesson"
            stateValue={props.state.lesson.value}
            state={props.state}
            setState={props.setState}
            error={props.state.lesson.error}
            optionsArray={props.lessons}
          /> */}
          <LessonDropDown
            name="Lesson"
            noValueText="Select lesson"
            className="textinput-margin-signup"
            stateName="lesson"
            stateValue={props.state.lesson.value}
            state={props.state}
            setState={props.setState || func}
            error={props.state.lesson.error}
            optionsArray={props.lessons}
          />
        </Col>
      </Row>

      {
        // appLessons.lessons.find((l) => l.id === props.state.lesson.value.id)
        //   ?.isLessonContainsUnits &&
        props.units.length > 0 ? (
          <Row className="no-gutters">
            <Col md={3}>
              {/* <DropDown
              name="Unit"
              noValueText="Select unit"
              className="textinput-margin-signup"
              stateName="unit"
              stateValue={props.state.unit.value}
              state={props.state}
              setState={props.setState || func}
              error={props.state.unit.error}
              optionsArray={props.units}
            /> */}
              <UnitDropDown
                name="Unit"
                noValueText="Select unit"
                className="textinput-margin-signup"
                stateName="unit"
                stateValue={props.state.unit.value}
                state={props.state}
                setState={props.setState || func}
                error={props.state.unit.error}
                optionsArray={props.units}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )
      }

      <div className="tf-answer-container" ref={props.scrollToTopRef}>
        <div className="mt-4 pt-1" style={{ color: '#73847f' }}>
          Question
        </div>

        <TinyMCEEditor
          onChange={props.questionTextChangeHandler}
          className="mt-1"
          value={props.state.question.value}
          error={props.state.question.error}
          onFocus={resetQuestionErrors}
        />

        <div className="mt-3 text-white">
          <div style={{ color: '#73847f' }}>Answer</div>
          <div className="d-flex justify-content-between mt-1">
            <TrueFalseAnswer
              onChange={props.onChange}
              value={props.state.trueFalseAnswer.value}
              type={true}
            />
            <TrueFalseAnswer
              onChange={props.onChange}
              value={props.state.trueFalseAnswer.value}
              type={false}
            />
          </div>
        </div>

        <Row className="mt-4 pt-1 mr-0 ml-auto no-gutters">
          <Col md={4} className="pr-5">
            <DropDown
              name={props.state.sourceOfQuestion.label}
              noValueText="Source of the question"
              className="textinput-margin-signup"
              stateName="sourceOfQuestion"
              stateValue={props.state.sourceOfQuestion.value}
              state={props.state}
              setState={props.setState}
              error={props.state.sourceOfQuestion.error}
              optionsArray={QUESTION_SOURCES_OPTIONS}
            />
          </Col>
          <Col md={4}>
            <LabelDropDown
              name="Label"
              noValueText="Select Label"
              className="textinput-margin-signup"
              stateName="labels"
              stateValue={props.state.labels.value}
              state={props.state}
              setState={props.setState || func}
              error={props.state.labels.error}
              optionsArray={
                props.labels
                  ? [...props.labels].filter(
                      (l) => !props.state.labels.value.map((val: any) => val.id).includes(l.id)
                    )
                  : []
              }
            />
            <div className="d-flex w-100 flex-wrap">
              {props.state.labels.value.map((l: any) => (
                <Label
                  id={l.id}
                  onClose={() => {
                    inputChangeHandler(props.state, 'labels', l.id);
                  }}
                />
              ))}
            </div>
          </Col>
          <Col md={4} className="pl-5" style={{ color: '#fff' }}>
            <div className="complexity-label">Complexity</div>
            <ComplexityRangeComp
              onChange={props.onComplexityChange}
              value={props.state.complexity.value}
            />
          </Col>
          <Col md={12}>
            <TextInput
              className="textinput-margin-signup"
              stateName="source"
              stateValue={props.state.source.value}
              state={props.state}
              setState={props.setState}
              error={props.state.source.error}
              placeHolder="Source"
            />
          </Col>
          <Col md={12}>
            <TextInput
              className="textinput-margin-signup"
              stateName="resolveGuide"
              stateValue={props.state.resolveGuide.value}
              state={props.state}
              setState={props.setState}
              error={props.state.resolveGuide.error}
              placeHolder="Resolve Guide"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const PreviewQuestionTF: FC<Props> = (props) => {
  const [question, setQuestion] = useState<TFQuestion | null>(null);

  const fetchQuestion = async (id: string) => {
    const doc = await QuestionService.getQuestionById(id);
    if (doc.exists) {
      const question = { id: doc.id, ...doc.data() } as TFQuestion;
      setQuestion(question);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchQuestion(props?.tabState?.questionData?.questionId || '');
    }

    return () => {
      isMounted = false;
    };
  }, [props?.tabState?.questionData?.questionId]);

  const appLabels = useLabels();

  const labelArray = appLabels.labelList
    .filter((label) => question?.labels?.includes(label.id))
    .map((l, idx) => {
      return <Label id={l.id} key={idx} noIcon></Label>;
    });

  return (
    <>
      <PreviewHeaderActionBar
        className="mt-2"
        onAction={props.onChangeDefaultTab}
        question={question}
      />
      <div className="preview-content">
        <QuestionBody questionContent={question?.question || ''} />

        <AnswerCard className="mt-4" type="TF" name="TRUE" isCorrect={question?.answer === true} />
        <AnswerCard
          className="mt-4"
          type="TF"
          name="FALSE"
          isCorrect={question?.answer === false}
        />

        <Row className="preview-bottom no-gutters">
          <Col xs={1}>
            <ComplexityIndicator value={question?.complexity || 0} />
          </Col>
          <Col xs={3}>
            <div className="source-of-question">Source of the question</div>
            <div className="source-of-question-val">{question?.sourceOfQuestion || 'Unknown'}</div>
          </Col>
          <Col style={{ overflow: 'hidden' }}>
            <div className="source">Source</div>
            <div className="source-val">{question?.source || 'Unknown'}</div>
          </Col>
          <Col xs={4} style={{ overflow: 'hidden' }}>
            <div className="source">Label</div>
            <div className="source-val">
              {labelArray.length ? (
                labelArray
              ) : (
                <div style={{ width: 30 }} className="text-center">
                  -
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TRQuestionTab;
