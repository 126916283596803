import React, { useState } from 'react';
import PrimaryNav from '../../../../components/PrimaryNav';
import { SCREEN } from '../../../../constants';
import LayoutNew from '../../../../layouts/LayoutNew';
import { ContentProvider } from '../../context/ContentContext';
import ContentBody from './ContentBody';
import ContentHeader from './ContentHeader';
import ContentSecondaryNav from './ContentSecondaryNav';

const AdminAndInstructorContent = () => {
  const [contentId, setContentId] = useState<string>('');

  const setContentIdHandler = (data: string) => {
    setContentId(data);
  };

  const [isLoading, setIsLoading] = useState(false);

  const setLoadingHandler = (value: boolean) => {
    setIsLoading(value);
  };

  const primaryNav = <PrimaryNav />;
  const secondaryNav = <ContentSecondaryNav />;
  const header = <ContentHeader />;
  const body = (
    <ContentBody
      contentId={contentId}
      setContentIdHandler={setContentIdHandler}
      setLoadingHandler={setLoadingHandler}
    />
  );

  return (
    <ContentProvider>
      <LayoutNew
        primaryNav={primaryNav}
        secondaryNav={secondaryNav}
        header={header}
        body={body}
        screen={SCREEN.CONTENT_PREVIEW}
        isLoading={isLoading}
      />
    </ContentProvider>
  );
};

export default AdminAndInstructorContent;
