import moment from 'moment';
import React, {FC} from 'react';
import {convertTimeStampToDate} from '../utils';

import './TAExamCard.scss';

interface Props {
  onClick: () => void;
  examTitle: string;
  assignedTime?: firebase.firestore.Timestamp;
  startDate?: firebase.firestore.Timestamp;
  startTime?: string;
  endDate?: firebase.firestore.Timestamp;
  endTime?: string;
}

const TAExamCardMarkingCompleted: FC<Props> = (props) => {
  const startDate = convertTimeStampToDate(props.startDate);
  const formattedStartDate = moment(startDate).format('ll');

  const endDate = convertTimeStampToDate(props.endDate);
  const formattedEndDate = moment(endDate).format('ll');

  // const assignedOn = convertTimeStampToDate(props.assignedTime);

  // const formattedAssignedOn = moment(assignedOn).format('lll');

  return (
    <div className="TA-assigned-papers" onClick={props.onClick}>
      <div className="TA-assigned-papers__exam-card">
        <div className="TA-assigned-papers__exam-card-exam-duration">
          <div className="TA-assigned-papers__exam-card-exam-duration-start">
            <span
              style={{
                width: '40px',
              }}
            >
              Start
            </span>
            <span
              style={{
                color: '#ffffff',
              }}
            >
              {formattedStartDate}
            </span>
            <span>{props.startTime}</span>
          </div>
          <div className="TA-assigned-papers__exam-card-exam-duration-end">
            <span
              style={{
                width: '40px',
              }}
            >
              End
            </span>
            <span
              style={{
                color: '#ffffff',
              }}
            >
              {formattedEndDate}
            </span>
            <span>{props.endTime}</span>
          </div>
        </div>

        <div className="TA-assigned-papers__exam-card-exam-content">
          <div className="TA-assigned-papers__exam-card-exam-content-title">
            <span>{props.examTitle}</span>
          </div>
          {/* <div className="TA-assigned-papers__exam-card-exam-content-exam-assignedon">
            <span>Assigned on {formattedAssignedOn}</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TAExamCardMarkingCompleted;
