import firebase from 'firebase';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { GradingType } from '../constants';
import { usePaymentSwitch } from '../context/PaymentContext';
import { useAppUser } from '../context/UserContext';
import { ExamToDoDTO } from '../dtos/ExamToDoDTO';
import { ExamProgressState } from '../enums/ExamProgressState';
import { ExamType } from '../enums/ExamType';
import { PaperTypes } from '../enums/paperTypes';
import {
  convertTimeStampToDate,
  convertUTCDateToLocalDate,
  getAbbrivationExam,
  removeDecimalSection,
  roundNumberWithDigits,
} from '../utils';
import AlertPopup from './AlertPopup';

import './StudentLiveExamCard.scss';

interface Props {
  exam: ExamToDoDTO;
  type: ExamProgressState;
  isLoading: boolean;
  setSelectedExamsHandlers?: (exam: ExamToDoDTO | null) => void;
}

const StudentLiveExamCard: FC<Props> = (props) => {
  const hideOverallMark = props.exam.papers.filter((p) => {
    if (
      (p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED) &&
      !p.structEssayData?.answerSheetUrl
    ) {
      return false;
    } else if (
      (p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED) &&
      !p.released
    ) {
      return true;
    }
    return false;
  }).length;

  const isExamOver =
    props.exam.examType === ExamType.EXTRA_EXAM
      ? true
      : +new Date(props.exam.finishTime || new Date()) < +new Date();

  const [state, setState] = useState(false);

  const paySwitch = usePaymentSwitch();

  const appUser = useAppUser();

  const history = useHistory();

  const params = useParams() as { subjectId: string };

  const convertStringToDate = (inputDate: string) => {
    const hours = inputDate.split(':')[0];
    const minutes = inputDate.split(':')[1];

    let date = new Date();

    date.setHours(+hours);
    date.setMinutes(+minutes);
    date.setSeconds(0);

    return date;
  };

  const examTypesArray = props.exam.papers.map((value) => {
    if (value.paperType === 'TRUEFALSE') {
      return 'T/F';
    } else {
      return value.paperType;
    }
  });

  const examTypes = examTypesArray.map((x) => {
    return getAbbrivationExam(x);
  }) as string[];

  const set = new Set(examTypes);

  //LEGACY DATA SUPPORT
  const time =
    props.exam.beginTime && props.exam.finishTime
      ? {
          startDate: moment(convertUTCDateToLocalDate(props.exam.beginTime)).format(
            'MM / DD / YYYY'
          ),
          duration: `${moment(convertUTCDateToLocalDate(props.exam.beginTime)).format(
            'LT'
          )} - ${moment(convertUTCDateToLocalDate(props.exam.finishTime)).format('LT')}`,
        }
      : {
          startDate: moment(convertTimeStampToDate(props.exam?.startDate)).format('MM / DD / YYYY'),
          duration: `${moment(convertStringToDate(props.exam.startTime || '00:00')).format(
            'LT'
          )} - ${moment(convertStringToDate(props.exam.endTime || '00:00')).format('LT')}`,
        };

  //
  const endDate = convertTimeStampToDate(props.exam?.endDate);
  const serverDate = firebase.firestore.Timestamp.now().toDate();
  const [show, setShow] = useState(false);
  const [temp, setTemp] = useState(false);

  return (
    <div
      className="live-exam-card "
      onClick={(e) => {
        if (props.type !== 'COMPLETED') {
          props.setSelectedExamsHandlers && props.setSelectedExamsHandlers(props.exam);
        } else if (serverDate < endDate) {
          if (temp) {
            return;
          } else {
            setShow(true);
            setTemp(true);
          }
        } else {
          history.push({
            pathname: `/my-subjects/subject/${params.subjectId}/exam/result/${
              props.exam?.id || props.exam?.examId
            }`,
            state: { exam: props.exam },
          });
        }
        e.stopPropagation();
      }}
    >
      <div className="live-exam-card__visible">
        <div className="live-exam-card__date-time">
          <div className="live-exam-card__date">{time.startDate}</div>
          <div className={`live-exam-card__time ${!props.exam.startTime ? 'd-none' : ''}`}>
            {time.duration}
          </div>
        </div>
        <div className="live-exam-card__title d-none d-sm-block d-md-none d-xl-block">
          {props.exam.examTitle}
        </div>
        <div className="live-exam-card__price ml-auto d-md-none">
          {props.exam.price && paySwitch.enabled ? (
            <>
              <span>Rs.</span>
              {props.exam.price}
            </>
          ) : (
            'Free'
          )}
        </div>
        <div className="live-exam-card__icon d-md-none">
          <FaChevronRight
            color="#687674"
            size={21}
            onClick={(e) => {
              setState(!state);
              e.stopPropagation();
            }}
            style={state ? { transform: 'rotate(90deg)' } : { transform: 'rotate(0deg)' }}
          />
        </div>

        <div className="d-none d-md-block live-exam-card-md__type ml-auto">
          {Array.from(set).map((value, index) => (
            <div key={index}>{value}</div>
          ))}
        </div>
        {props.type === ExamProgressState.IN_PROGRESS ? (
          <div className="d-none d-md-block live-exam-card-md__status">
            {props.exam.usersExamState?.find((u) => u.userId === appUser.fireUser?.uid) ? (
              'In Progress'
            ) : (
              <span style={{ color: '#F3B619' }}>Open for Submission</span>
            )}
          </div>
        ) : props.type === 'UPCOMING' ? (
          <div className="d-none d-md-block live-exam-card-md__status"></div>
        ) : (
          <div className="d-none d-md-block live-exam-card-md__score">
            {hideOverallMark || !isExamOver ? (
              <>
                <div className="live-exam-card-md__score-title">Score</div>
                <div className="live-exam-card-md__score-value" style={{ color: '#F3B619' }}>
                  Pending...
                </div>
              </>
            ) : (
              <>
                <div className="live-exam-card-md__score-title">Score</div>
                <div className="live-exam-card-md__score-value">
                  {props.exam.gradingType === GradingType.PERCENTAGE
                    ? roundNumberWithDigits(props.exam.score || 0) + ' %'
                    : `${props.exam.points + '/' + props.exam.totalPoints}`}
                </div>
              </>
            )}
          </div>
        )}

        <div className="live-exam-card__price ml-auto d-none d-md-flex">
          {props.exam.price && paySwitch.enabled ? (
            <>
              <span>Rs.</span>
              {props.exam.price}
            </>
          ) : (
            'Free'
          )}
        </div>
      </div>
      <div className="mt-2 d-sm-none d-md-block d-xl-none">{props.exam.examTitle}</div>
      <div className={`live-exam-card__hidden d-md-none ${!state ? 'd-none' : ''}`}>
        {props.type === ExamProgressState.IN_PROGRESS ? (
          <div className="live-exam-card__status">
            {props.exam.usersExamState?.find((u) => u.userId === appUser.fireUser?.uid) ? (
              'In Progress'
            ) : (
              <span style={{ color: '#F3B619' }}>Open for Submission</span>
            )}
          </div>
        ) : props.type === 'COMPLETED' ? (
          <div className="live-exam-card__score">
            Score{' '}
            {hideOverallMark || !isExamOver ? (
              <div style={{ color: '#F3B619' }}>Pending...</div>
            ) : (
              <span>
                {props.exam.gradingType === GradingType.PERCENTAGE
                  ? removeDecimalSection(props.exam.score?.toString() || '0') + ' %'
                  : `${props.exam.points + '/' + props.exam.totalPoints}`}
              </span>
            )}
          </div>
        ) : (
          <></>
        )}

        <div className="live-exam-card__type">
          {Array.from(set).map((value, index) => (
            <div key={index}>{value}</div>
          ))}
        </div>
      </div>
      <AlertPopup
        message={'This exam is still in progress. Marks will be shown once the exam is over'}
        header="Attention"
        isShow={show}
        onClose={() => {
          setShow(false);
          setTemp(false);
        }}
        type="NO_BUTTON"
      />
    </div>
  );
};

export default StudentLiveExamCard;
