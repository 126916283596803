import React, {FC, useEffect, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaChevronRight} from 'react-icons/fa';
import truncate from 'truncate';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import StudentTAAssigmentCheckbox from './StudentTAAssigmentCheckbox';
import StudentTAAssigmentRadioBtn from './StudentTAAssigmentRadioBtn';
import './StudentTAAssignmentCard.scss';

interface Props {
  list: {
    paperId: string;
    paperTitle: string;
    isMarked?: boolean;
    assignedTAUid:
      | {
          taUid: string;
          assignedTime?: firebase.firestore.Timestamp;
        }
      | null
      | undefined;
  }[];
  studentId: string;
  tasForExam: string[];
  assignTAsHandler: (value: {stuUid: string; taUid: string; paperId: string}) => void;
  assignStudentAllPapersHander: (value: {stuUid: string; taUid: string}) => void;
  removeStudentAllPapersHander: (stuUid: string) => void;
  isAllPaperMarked?: boolean;
}
export enum CHECKBOXSTATUS {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  NONE = 'NONE',
  DISABLED = 'DISABLED',
}
const StudentTAAssignmentCard: FC<Props> = (props) => {
  useEffect(() => {
    let st: string[] = [];
    props.list.forEach((v) => {
      if (v.assignedTAUid) {
        st.push(v.assignedTAUid?.taUid);
      }
    });

    setState({tas: st, papersArrayLength: props.list.length});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.list)]);

  const appUsers = useAdminsAndInstructors();

  const [state, setState] = useState<{tas: string[]; papersArrayLength: number}>({
    tas: [],
    papersArrayLength: 0,
  });
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="stu-taa-card mt-1" style={open ? {} : {height: 40}}>
      <section className="stu-taa-card__head">
        <div className="stu-taa-card__stu-name">
          {/* {appUsers.allAdminsAndInstructors.find((val) => val.uid === props.studentId)?.username} */}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="verifiedId">
                {
                  appUsers.allAdminsAndInstructors.find((val) => val.uid === props.studentId)
                    ?.username
                }
              </Tooltip>
            }
          >
            <div>
              {truncate(
                appUsers.allAdminsAndInstructors.find((val) => val.uid === props.studentId)
                  ?.username || '',
                15
              )}
            </div>
          </OverlayTrigger>
        </div>
        <div className="stu-taa-card__cb-list">
          {props.tasForExam.map((v, idx) => {
            const len = state.tas.filter((taId) => taId === v).length;

            const status =
              state.papersArrayLength === len
                ? CHECKBOXSTATUS.FULL
                : len === 0
                ? CHECKBOXSTATUS.NONE
                : CHECKBOXSTATUS.PARTIAL;

            return (
              <StudentTAAssigmentCheckbox
                disabled={props.isAllPaperMarked}
                status={status}
                onAssign={() => {
                  props.assignStudentAllPapersHander({stuUid: props.studentId, taUid: v});
                }}
                onRemove={() => {
                  props.removeStudentAllPapersHander(props.studentId);
                }}
                className="stu-taa-card__cb-item"
                key={idx}
              />
            );
          })}
        </div>

        <FaChevronRight
          className="ml-auto stu-taa-card__arrow-btn"
          onClick={() => {
            setOpen(!open);
          }}
          style={open ? {transform: 'rotate(90deg)'} : {}}
        />
      </section>
      <section className="stu-taa-card__body">
        {props.list.map((val, index) => {
          return (
            <div className="stu-taa-card__papers" key={index}>
              <div className="stu-taa-card__paper-name">{val.paperTitle}</div>
              <div className="stu-taa-card__radio-list">
                {props.tasForExam.map((taId, index) => {
                  const active = !!(val.assignedTAUid?.taUid === taId);
                  return (
                    <StudentTAAssigmentRadioBtn
                      onClick={() => {
                        props.assignTAsHandler({
                          stuUid: props.studentId,
                          taUid: taId,
                          paperId: val.paperId,
                        });
                      }}
                      disabled={val.isMarked}
                      active={active}
                      className="stu-taa-card__radio-list-item"
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default StudentTAAssignmentCard;
