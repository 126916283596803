import firebase from 'firebase';
import {QuestionTypes} from '../enums/questionTypes';

const getQuestionById = (
  questionId: string
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> => {
  return firebase.firestore().collection('questions').doc(questionId).get();
};

const fetchAllMCQQuestions = (): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> => {
  return firebase
    .firestore()
    .collection('questions')
    .orderBy('createdAt', 'desc')
    .where('questionType', '==', `${QuestionTypes.MCQ}`)
    .get();
};

const fetchAllTFQuestions = (): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> => {
  return firebase
    .firestore()
    .collection('questions')
    .orderBy('createdAt', 'desc')
    .where('questionType', '==', `${QuestionTypes.TRUEFALSE}`)
    .get();
};

const getAllQtns = (): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> => {
  return firebase.firestore().collection('questions').orderBy('createdAt', 'desc').get();
};

const QuestionService = {
  getQuestionById,
  fetchAllMCQQuestions,
  fetchAllTFQuestions,
  getAllQtns,
};

export default QuestionService;
