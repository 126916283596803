import {useState, useEffect} from 'react';

function useDebounce<T>(value: T, delay: any, callback: (v: T) => void) {
  const [delayedValue, setDelayedValue] = useState(value);
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  if (delayedValue === value && delayedValue !== debouncedValue) {
    setDebouncedValue(delayedValue);
    callback(delayedValue);
  }
  return debouncedValue;
}

export default useDebounce;
