import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAppUser } from '../context/UserContext';

import { EXAM_DASH_GRADE_SETTINGS_TA_SCREEN } from '../utils';
import './IncludeDashboardGradeSettingsTATabs.scss';

interface Props {
  essayOrStructuredPaperPresent: boolean;
  hierarchyLeveL?: 'LESSON' | 'UNIT';
}

const IncludeDashboardGradeSettingsTATabs: FC<Props> = (props) => {
  const params = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
    examId: string;
  };

  const history = useHistory();

  const appUser = useAppUser();

  const location = useLocation();

  const lastSegment = location.pathname.split('/').pop();

  return (
    <div className="idgst-tabs">
      <div className="idgst-tabs__tabs">
        <div
          className={`idgst-tabs__tabs-dashboard ${
            lastSegment === EXAM_DASH_GRADE_SETTINGS_TA_SCREEN.DASH && 'idgst-tabs__tabs-active'
          }`}
          onClick={() => {
            if (props.hierarchyLeveL === 'LESSON') {
              history.push(
                `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Examinations/${params.examId}/dashboard`
              );
            } else if (props.hierarchyLeveL === 'UNIT') {
              history.push(
                `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations/${params.examId}/dashboard`
              );
            } else {
              history.push(
                `/my-subjects/subject/${params.subjectId}/Examinations/${params.examId}/dashboard`
              );
            }
          }}
        >
          Dashboard
        </div>
        {appUser.firestoreUser?.userRole?.isTeachingAssistant && (
          <div
            className={`idgst-tabs__tabs-pay-grade ${
              lastSegment === EXAM_DASH_GRADE_SETTINGS_TA_SCREEN.PAYMENTS &&
              'idgst-tabs__tabs-active'
            }`}
            onClick={() => {
              if (props.hierarchyLeveL === 'LESSON') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Examinations/${params.examId}/payments`
                );
              } else if (props.hierarchyLeveL === 'UNIT') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations/${params.examId}/payments`
                );
              } else {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/Examinations/${params.examId}/payments`
                );
              }
            }}
          >
            Payments
          </div>
        )}
        {!appUser.firestoreUser?.userRole?.isTeachingAssistant && (
          <div
            className={`idgst-tabs__tabs-pay-grade ${
              lastSegment === EXAM_DASH_GRADE_SETTINGS_TA_SCREEN.GRADE && 'idgst-tabs__tabs-active'
            }`}
            onClick={() => {
              if (props.hierarchyLeveL === 'LESSON') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Examinations/${params.examId}/grades`
                );
              } else if (props.hierarchyLeveL === 'UNIT') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations/${params.examId}/grades`
                );
              } else {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/Examinations/${params.examId}/grades`
                );
              }
            }}
          >
            Grades
          </div>
        )}
        <div
          className={`idgst-tabs__tabs-settings ${
            lastSegment === EXAM_DASH_GRADE_SETTINGS_TA_SCREEN.SETTINGS && 'idgst-tabs__tabs-active'
          }`}
          onClick={() => {
            if (props.hierarchyLeveL === 'LESSON') {
              history.push(
                `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Examinations/${params.examId}/settings`
              );
            } else if (props.hierarchyLeveL === 'UNIT') {
              history.push(
                `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations/${params.examId}/settings`
              );
            } else {
              history.push(
                `/my-subjects/subject/${params.subjectId}/Examinations/${params.examId}/settings`
              );
            }
          }}
        >
          Settings
        </div>

        {!appUser.firestoreUser?.userRole?.isTeachingAssistant &&
        props.essayOrStructuredPaperPresent ? (
          <div
            className={`idgst-tabs__tabs-ta-payments ${
              lastSegment === EXAM_DASH_GRADE_SETTINGS_TA_SCREEN.TA && 'idgst-tabs__tabs-active'
            }`}
            onClick={() => {
              if (props.hierarchyLeveL === 'LESSON') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Examinations/${params.examId}/ta`
                );
              } else if (props.hierarchyLeveL === 'UNIT') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations/${params.examId}/ta`
                );
              } else {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/Examinations/${params.examId}/ta`
                );
              }
            }}
          >
            Teaching Assistant
          </div>
        ) : null}
      </div>

      <div className="idgst-tabs__body">{props.children}</div>
    </div>
  );
};

export default IncludeDashboardGradeSettingsTATabs;
