import React, {FC} from 'react';
import './QuestionPreviewModal.scss';
import Modal from 'react-modal';
import {FaCheckCircle, FaWindowClose} from 'react-icons/fa';
import renderHTML from 'react-render-html';
import ComplexityIndicator from './ComplexityIndicator';
import {QuestionTypes} from '../enums/questionTypes';

interface Props {
  show: boolean;
  closeModalHandler: () => void;
  content: any;
}

const QuestionPreviewModal: FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="question-preview-modal p-3"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="text-right">
        {' '}
        <FaWindowClose
          color="#FF4C6C"
          onClick={props.closeModalHandler}
          size={25}
          style={{cursor: 'pointer'}}
        />
      </div>
      <div className="question-preview-modal__question mt-2">
        {props.content && renderHTML(props.content.question)}
      </div>
      <div className="question-preview-modal__answers ml-3 mt-2">
        {props.content.questionType === QuestionTypes.MCQ ? (
          props.content.answers.map((ans: any, index: number) => (
            <div className="d-flex align-items-center" key={index}>
              <div style={{width: 25}}>
                {ans.isCorrect ? <FaCheckCircle size={20} color="20D167" /> : <></>}
              </div>
              <span className="open-collapse-answer-no">{index + 1 + '.'}</span>
              <div className="open-collapse-answer-text">{renderHTML(ans.answerText)}</div>
            </div>
          ))
        ) : (
          <>
            <div className="d-flex align-items-center">
              <div style={{width: 25}}>
                {props.content.answer ? <FaCheckCircle size={20} color="20D167" /> : <></>}
              </div>
              <span className="open-collapse-answer-no">{1 + '.'}</span>
              <div className="open-collapse-answer-text">True</div>
            </div>
            <div className="d-flex align-items-center">
              <div style={{width: 25}}>
                {!props.content.answer ? <FaCheckCircle size={20} color="20D167" /> : <></>}
              </div>
              <span className="open-collapse-answer-no">{2 + '.'}</span>
              <div className="open-collapse-answer-text">False</div>
            </div>
          </>
        )}
      </div>
      <div className="question-preview-modal__footer d-flex  mt-2">
        <div className="question-preview-modal__q-type mr-3 ml-auto">
          {props.content.questionType}
        </div>
        <div className="question-preview-modal__q-complexity tex">
          <ComplexityIndicator value={props.content.complexity} />
        </div>
      </div>
    </Modal>
  );
};

export default QuestionPreviewModal;
