import React, {FC, useEffect} from 'react';
import {useAppUser} from '../context/UserContext';
import {PACKAGE_TYPE_TABS} from '../enums';
import {Package} from '../models/Package';
import PackageCard from './PackageCard';
import './PackageList.scss';
import {useFunctions} from 'reactfire';
import {useHistory, useParams} from 'react-router-dom';
import firebase from 'firebase';

interface Props {
  tab: PACKAGE_TYPE_TABS;
  loadingHandler?: (e: boolean) => void;
}

const PackageList: FC<Props> = (props) => {
  const user = useAppUser();
  const history = useHistory();
  const {MY_PACKAGES} = PACKAGE_TYPE_TABS;
  const {CURRENT_PACKAGES} = PACKAGE_TYPE_TABS;
  const getPackagesBySubject = useFunctions().httpsCallable('getPackagesBySubject');
  const [appPackages, setAppPackages] = React.useState<Package[]>([]);
  const {subjectId} = useParams() as {subjectId: string};
  useEffect(() => {
    props.loadingHandler && props.loadingHandler(true);
    if (subjectId) {
      getPackagesBySubject({subjectId: subjectId})
        .then((res) => {
          console.log(res.data.data.packages);
          setAppPackages(res.data.data.packages);
          props.loadingHandler && props.loadingHandler(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (appPackages.length > 0) {
  //     const expiredPackages:Package[] = []
  //     const currentPackages:Package[] = []
  //     appPackages.forEach((p) => {
  //       if(new Date(p.endDate) < new Date()){
  //         expiredPackages.push(p);
  //       }
  //       else{
  //         currentPackages.push(p);
  //       }
  //     });
  //     if(props.tab === PACKAGE_TYPE_TABS.CURRENT_PACKAGES){
  //       setVisiblePackages(currentPackages);
  //     }else {
  //       setVisiblePackages(expiredPackages);
  //     }
  //   }
  // },[appPackages, props.tab]);


  const onCardClick = (id?: string) => {
    if (id) {
      const currentUrl = history.location.pathname;
      history.push(`${currentUrl}/${id}`);
    }
  };
  const predicate = (packge: Package) => packge.type === props.tab;
  const timeServer = new Date(firebase.firestore.Timestamp.now().toDate().setHours(0, 0, 0));
  return (
    <>
      {user.firestoreUser?.userRole?.isAdmin || user.firestoreUser?.userRole?.isInstructor ? (
        <div className="package-list">
          {props.tab === CURRENT_PACKAGES ? (
            <div className="package-list__list">
              {appPackages
                .filter(
                  (packge) => new Date(packge.endDate) >= timeServer || packge.type === 'Unlimited'
                )
                .map((packge) => {
                  return (
                    <PackageCard
                      packge={packge}
                      key={packge.id}
                      onClick={() => {
                        onCardClick(packge.id);
                      }}
                    />
                  );
                })}
            </div>
          ) : (
            <div className="package-list__list">
              {appPackages
                .filter(
                  (packge) => new Date(packge.endDate) < timeServer && packge.type !== 'Unlimited'
                )
                .map((packge) => {
                  return (
                    <PackageCard
                      packge={packge}
                      key={packge.id}
                      onClick={() => {
                        onCardClick(packge.id);
                      }}
                    />
                  );
                })}
            </div>
          )}
        </div>
      ) : null}

      {user.firestoreUser?.userRole?.isStudent ? (
        <div className="package-list">
          {props.tab === MY_PACKAGES ? (
            <div className="package-list__list">
              {appPackages.filter(predicate).map((packge) => {
                return (
                  <PackageCard
                    packge={packge}
                    key={packge.id}
                    tab={props.tab}
                    onClick={() => {
                      onCardClick(packge.id);
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <div className="package-list__list">
              {appPackages.map((packge) => {
                return (
                  <PackageCard
                    packge={packge}
                    key={packge.id}
                    tab={props.tab}
                    onClick={() => {
                      onCardClick(packge.id);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default PackageList;
