import './PackageInfo.scss';

interface Props {
  title?: string;
  description?: string;
  date?: Date;
  grade?: string;
  language?: string;

}
const Info = (props: Props) => {
  return (
    <div className="content1">
      <h1 className="content1__header">{props.title ? props.title : 'Information Technology'}</h1>
      <p className="content1__text">
        {props.description
          ? props.description
          : 'This course contains full access to science for technology, engineering technology and Information technology. This course contains full access to science for technology,'}
      </p>
      <div className="flex-container gap margin-top-sm">
        <div className="content1__year flex-child-2">
          <h2 className="content1__year-text">
            { props.date && props.date.getFullYear() ? props.date.getFullYear() : '2023'}
          </h2>
        </div>
        <div className="content1__grade flex-child-2">
          <p className="content1__grade-text">{props.grade}</p>
        </div>
        <div className="content1__langauge flex-child-2">
          <p className="content1__langauge-text">
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.01409 7.842L5.51318 6.336L5.53091 6.318C6.55909 5.154 7.29182 3.816 7.72318 2.4H9.45455V1.2H5.31818V0H4.13636V1.2H0V2.394H6.60045C6.20455 3.552 5.57818 4.65 4.72727 5.61C4.17773 4.992 3.72273 4.314 3.36227 3.6H2.18045C2.61182 4.578 3.20273 5.502 3.94136 6.336L0.933636 9.348L1.77273 10.2L4.72727 7.2L6.565 9.066L7.01409 7.842ZM10.3409 4.8H9.15909L6.5 12H7.68182L8.34364 10.2H11.1505L11.8182 12H13L10.3409 4.8ZM8.79273 9L9.75 6.402L10.7073 9H8.79273Z"
                fill="white"
              />
            </svg>{' '}
            {props.language ? props.language : 'English'}
          </p>
        </div>
        <div className="content1__countdown flex-child-2">
          <p className="content1__countdown-text">
            Package Ends: { props.date && props.date.getDate() ? props.date.getDate() : '01'}{' '}
            {props.date && props.date.toLocaleString('default', {month: 'short'})
              ? props.date.toLocaleString('default', {month: 'short'})
              : 'Jan'}{' '}
            {props.date && props.date.getFullYear() ? props.date.getFullYear() : '2023'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
