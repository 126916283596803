import React, {FC} from 'react';
import {PaperTypes} from '../enums/paperTypes';
import {Paper} from '../models/Paper';
import {getPaperType} from '../utils';
import FilterCheckboxLeft from './FilterCheckboxLeft';
import './StudentExamPreviewPaperCard.scss';

interface Props {
  className?: string;
  paperTitle?: string;
  onClick: (paper: Paper) => void;
  paper: Paper;
  isEditingPayment?: boolean;
  updatePaperMarkingAmountHandler?: (pId: string, value: string) => void;
  updatePaperMarkingIsFreeHandler?: (pId: string, isPayment: boolean) => void;
}

const StudentExamPreviewPaperCard: FC<Props> = (props) => {
  return (
    <div className={`stu-exam-preview-papercard justify-content-between ${props.className}`}>
      <div
        className="d-flex w-100"
        onClick={() => {
          props.onClick(props.paper);
        }}
      >
        <div className="stu-exam-preview-papercard__tag-con">
          <div className="stu-exam-preview-papercard__tag">
            {getPaperType(props.paper.paperType || '')}
          </div>
        </div>
        <div className="stu-exam-preview-papercard__papername">{props.paperTitle}</div>
      </div>
      {props.isEditingPayment
        ? (props.paper.paperType === PaperTypes.ESSAY ||
            props.paper.paperType === PaperTypes.STRUCTURED) && (
            <div className="stu-exam-preview-papercard__payment">
              <div>
                <div className="stu-exam-preview-papercard__payment-label">TA Payment (Rs)</div>
                <input
                  value={props.paper.taPayment?.amount + ''.replace(/^0+/, '')}
                  onChange={(e) => {
                    props.updatePaperMarkingAmountHandler &&
                      props.updatePaperMarkingAmountHandler(
                        props.paper.id || '',
                        Number(e.target.value).toFixed(2)
                      );
                  }}
                  type="number"
                  style={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    border: '1px solid #73847F',
                    borderRadius: '5px',
                    color: 'white',
                    marginTop: '5px',
                    paddingLeft: '5px',
                  }}
                />
              </div>
              <div className="stu-exam-preview-papercard__isFree">
                <FilterCheckboxLeft
                  isChecked={props.paper.taPayment?.isFree}
                  onClick={() => {
                    props.updatePaperMarkingIsFreeHandler &&
                      props.updatePaperMarkingIsFreeHandler(
                        props.paper.id || '',
                        !props.paper.taPayment?.isFree
                      );
                  }}
                  item={{ label: '', name: 'free' }}
                />
                <div className="stu-exam-preview-papercard__isfree_tag">Free</div>
              </div>
            </div>
          )
        : (props.paper.paperType === PaperTypes.ESSAY ||
            props.paper.paperType === PaperTypes.STRUCTURED) && (
            <>
              <div
                style={{
                  width: 100,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className="text-center"
              >
                <div className="stu-exam-preview-papercard__payment-label">TA Payment (Rs)</div>
                <div className="stu-exam-preview-papercard__payment-amount text-white">
                  {props.paper.taPayment?.amount === 0 && props.paper.taPayment?.isFree
                    ? 'Free'
                    : props.paper.taPayment?.amount === 0
                    ? '-'
                    : props.paper.taPayment?.amount}
                </div>
              </div>
            </>
          )}
    </div>
  );
};

export default StudentExamPreviewPaperCard;
