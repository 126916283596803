import React, {FC, useEffect} from 'react';
import {Col} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {GRADES} from '../constants';
import {useLessons} from '../context/LessonsContext';
import {useSubjects} from '../context/SubjectsContext';
import {useUnits} from '../context/UnitsContext';
import {DropDown} from '../interfaces';
import Button from '../shared/Button';
import FilterDropDown from '../shared/FilterDropDown';
import FilterRadioButton from './FilterRadioButton';
import './SubjectDirectiveFilterBox.scss';

interface Props {
  open?: boolean;
  selectedLesson?: DropDown;
  selectedUnit?: DropDown;
  hierarchyLevel?: 'LESSON' | 'UNIT';
  openFilterHander?: (value: boolean) => void;
  clearFiltersHandler?: () => void;
  applyFiltersHandler?: () => void;
  onSelectLessonFilterHandler?: (value: DropDown) => void;
  onSelectUnitFilterHandler?: (value: DropDown) => void;
}

const SubjectDirectiveFilterBox: FC<Props> = (props) => {
  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUnits = useUnits();

  const params = useParams() as {subjectId: string; lessonId: string; unitId: string};

  const sub = appSubjects.allSubjects.find((sub) => sub.id === params.subjectId);

  const lessonOptions = appLessons.lessons
    .filter((less) => less.subjectId === params.subjectId)
    .map((val) => {
      return {
        displayValue: val.name || '',
        id: val.id || '',
      };
    });

  const unitOptions = appUnits.units
    .filter(
      (unit) =>
        unit.subjectId === params.subjectId &&
        (unit.lessonId === props.selectedLesson?.id || unit.lessonId === params.lessonId)
    )
    .map((val) => {
      return {
        displayValue: val.name || '',
        id: val.id || '',
      };
    });

  useEffect(() => {
    if (lessonOptions.length) {
      const sLesson = lessonOptions.find((l) => l.id === params.lessonId);
      if (sLesson) {
        props.onSelectLessonFilterHandler && props.onSelectLessonFilterHandler(sLesson);

        if (unitOptions.length) {
          const sUnit = unitOptions.find((u) => u.id === params.unitId);

          if (sUnit) {
            props.onSelectUnitFilterHandler && props.onSelectUnitFilterHandler(sUnit);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!params.unitId) {
      props.onSelectUnitFilterHandler &&
        props.onSelectUnitFilterHandler({displayValue: '', id: ''});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedLesson?.id]);

  const subj = {displayValue: sub?.name || '', id: sub?.id || ''};

  return (
    <div className={`sdb ${!props.open && 'd-none'}`}>
      <div className="sdb__grade">
        <div className="sdb__grade-label">Grade</div>
        <div className="sdb__grade-radios">
          {GRADES.map((item, idx) => (
            <Col className="col-3" key={idx}>
              <FilterRadioButton
                key={idx}
                item={{label: item.displayValue, name: item.id}}
                onClick={() => {}}
                isChecked={sub?.grade === item.id}
                disabled
              />
            </Col>
          ))}
        </div>
      </div>
      <div className="sdb__dropdowns">
        <FilterDropDown placeHolder="Select Subject" value={subj} disabled />
        <FilterDropDown
          stateChangeHandler={props.onSelectLessonFilterHandler}
          options={lessonOptions}
          placeHolder="Select Lesson"
          value={props.selectedLesson}
          disabled={props.hierarchyLevel === 'LESSON' || props.hierarchyLevel === 'UNIT'}
          uniqueId="lessonDropdown"
        />
      </div>
      <div className="sdb__dropdowns">
        <FilterDropDown
          stateChangeHandler={props.onSelectUnitFilterHandler}
          options={unitOptions}
          placeHolder="Select Unit"
          value={props.selectedUnit}
          disabled={props.hierarchyLevel === 'UNIT'}
          uniqueId="unitDropdown"
        />
      </div>
      <div className="sdb__buttons">
        <div
          className="sdb__clear"
          onClick={() => {
            props.clearFiltersHandler && props.clearFiltersHandler();
          }}
        >
          Clear Filter
        </div>
        <div className="sdb__cancel-apply">
          <div
            className="sdb__cancel"
            onClick={() => {
              props.openFilterHander && props.openFilterHander(false);
            }}
          >
            Cancel
          </div>
          <Button
            text="Apply"
            onClick={() => {
              props.applyFiltersHandler && props.applyFiltersHandler();
            }}
            className="ml-2"
          />
        </div>
      </div>
    </div>
  );
};

export default SubjectDirectiveFilterBox;
