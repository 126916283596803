import React, { FC, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import './TextFieldInput.scss';

interface Props {
  name: string;
  className?: string;
  style?: React.CSSProperties;
  stateName: string;
  stateValue: string;
  state: any;
  setState: Function;
  error?: string;
  type?: 'bio';
  limit?: number;
  isUpdate?: boolean;
}

const TextFieldInput: FC<Props> = (props) => {
  const [state, setState] = useState({ className: 'd-none', initial: true, showLabel: 'd-block' });

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputHeight, setInputHeight] = useState(0);

  useEffect(() => {
    if (inputRef.current) {
      setInputHeight(inputRef.current.scrollHeight);
    }
  }, [props.stateValue]);

  const inputChangeHandler = (state: any, name: string, value: string) => {
    const filter = /[~`!@#$%^*+=\-[\]\\';/{}|\\":<>?]/g;
    if (props.type === 'bio' && filter.test(value)) {
      return;
    }

    props.setState({
      ...state,
      [name]: { ...state[name], value: value, error: '' },
    });
  };

  const setMaxLimitReachedError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: { ...state[name], error: `Maximum character length of ${props.limit} is reached` },
    });
  };

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: { ...state[name], error: '' },
    });
  };

  let errorJSX = null;

  if (props.error !== 'NO-ERROR') {
    errorJSX = <div className={`w-100 err-styles`}>{props.error}</div>;
  }

  let borderColor =
    props.error === 'NO-ERROR'
      ? { borderBottom: '1px solid #20D167' }
      : props.error === ''
      ? {}
      : { borderBottom: '1px solid #dc3545' };

  return (
    <div>
      <div className={`textarea ${props.className} `} style={{ ...props.style, paddingTop: 10 }}>
        <TextareaAutosize
          className="textarea__elmt"
          name="textarea"
          onChange={(e: any) => {
            if (props.limit && e.target.value.length > props.limit) {
              setMaxLimitReachedError(props.state, props.stateName);
              return;
            }

            inputChangeHandler(props.state, props.stateName, e.target.value);
          }}
          maxRows={15}
          onHeightChange={() => {
            if (state.initial) {
              setState((pS) => ({
                ...pS,
                initial: false,
              }));
            } else {
              setState((ps) => ({
                ...ps,
                className: 'd-block',
                showLabel: 'd-none',
              }));
            }
          }}
          onBlur={() => {
            if (!props.stateValue) {
              setState({ className: 'd-none', initial: false, showLabel: 'd-block' });
            }
          }}
          required
          value={props.stateValue}
          onKeyDown={() => {
            resetError(props.state, props.stateName);
          }}
          style={borderColor}
          ref={inputRef}
        />
        <label htmlFor="textarea" className={`textarea__label ${state.showLabel}`}>
          {props.isUpdate && props.isUpdate ? (
            <span
              className="textarea__content"
              style={{ transform: `translateY(-${5 * inputHeight}%)` }}
            >
              {props.name.substring(0, 1).toLocaleUpperCase() + props.name.substring(1)}
            </span>
          ) : (
            <span className="textarea__content">
              {props.name.substring(0, 1).toLocaleUpperCase() + props.name.substring(1)}
            </span>
          )}
        </label>
        <label
          className={`position-absolute ${state.className}`}
          style={{ color: '#73847f', left: -0.7, top: -7, fontSize: 12, fontWeight: 500 }}
        >
          {props.name}
        </label>
      </div>
      {errorJSX}
    </div>
  );
};

export default TextFieldInput;
