import React, {FC} from 'react';
import {Col} from 'react-bootstrap';
import './ExamPapersInformationCard.scss';

interface Props {
  className?: string;
  paperTitle: string;
  tag: string;
  numberOfQtns: number;
  hours: number;
  minutes: number;
  onClick: () => void;
  paperStarted: boolean;
}

const ExamPapersInformationCard: FC<Props> = (props) => {
  return (
    <Col className={`${props.className} ex-papers-info-card`}>
      <div className="d-flex">
        <div className="ex-papers-info-card__title">{props.paperTitle}</div>
        <div className="ex-papers-info-card__tags">{props.tag}</div>
      </div>
      <div className="ex-papers-info-card__qustion-nos-time">
        <div>
          {props.numberOfQtns} <span className="ex-papers-info-card__qtn-label">Questions</span>
        </div>
        <div>
          {props.hours}
          <span className="ex-papers-info-card__paper-time">H</span> {props.minutes}
          <span className="ex-papers-info-card__paper-time">M</span>
        </div>
      </div>
      <button
        className="ex-papers-info-card__start-btn"
        onClick={() => {
          props.onClick();
        }}
      >
        {props.paperStarted ? 'Continue' : 'Start'}
      </button>
    </Col>
  );
};

export default ExamPapersInformationCard;
