import React, {FC} from 'react';
import {FaChevronRight} from 'react-icons/fa';
import {SUBJECT_TAB} from '../constants';
import {useMobileNav} from '../context/MobNavContext';

import './Tab.scss';

interface Props {
  text: string;
  style?: React.CSSProperties;
  onClick?: (tab: string) => void;
  onSubTabClick?: (subTab: string) => void;
  active?: boolean;
  subMenuItems?: {text: string; active: boolean}[];
}

const Tab: FC<Props> = (props) => {
  const activeMainTab = props.active ? 'active-tab' : '';

  const mobileNav = useMobileNav();

  let content = (
    <div
      className={`tab  ${activeMainTab}`}
      style={props.style}
      onClick={() => {
        mobileNav.setOpenMobileNav(false);
        props.onClick && props.onClick(props.text);
      }}
    >
      {props.text}
    </div>
  );

  if (props?.subMenuItems?.length) {
    content = (
      <>
        <div
          className={`tab ${activeMainTab} d-flex align-items-center justify-content-between`}
          style={props.style}
          onClick={() => {
            if (SUBJECT_TAB.MODAL_PAPERS === props.text && mobileNav.openMobileNav) {
              props.onClick && props.onClick(props.text);
            } else {
              mobileNav.setOpenMobileNav(false);
              props.onClick && props.onClick(props.text);
            }
          }}
        >
          {props.text}{' '}
          <FaChevronRight
            size={15}
            style={{fontWeight: 200, marginRight: 20}}
            color="#fff"
            className={activeMainTab ? 'animate-menu-arrow' : ''}
          />
        </div>
        {activeMainTab &&
          props.subMenuItems.map((item, idx) => {
            const activeSubTab = item.active ? 'active-sub-tab' : '';

            return (
              <div
                className={`sub-tab ${activeSubTab}`}
                onClick={() => {
                  mobileNav.setOpenMobileNav(false);
                  props.onSubTabClick && props.onSubTabClick(item.text);
                }}
                key={idx}
                style={{marginTop: idx === 0 ? 10 : 0}}
              >
                {item.text}
              </div>
            );
          })}
      </>
    );
  }

  return content;
};

export default Tab;
