import React, {FC} from 'react';
import truncate from 'truncate';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import {Subject} from '../models/Subject';
import './SubjectDropdownCard.scss';

interface Props {
  className: string;
  inputChangeHandler: (
    state: any,
    name: string,
    value: {
      displayValue: string;
      id: string;
    }
  ) => void;
  setState: (value: React.SetStateAction<boolean>) => void;
  id: string;
  state: any;
  stateName: string;
  noValueText: string;
  subject?: Subject;
  truncateNumber?: number;
}

const SubjectDropdownCard: FC<Props> = (props) => {
  const users = useAdminsAndInstructors();

  const createdBy = users.allAdminsAndInstructors.find(
    (u) => u.uid === props.subject?.createdBy
  )?.username;

  return (
    <div
      className={`subject-dropdown-card ${props.className}`}
      onClick={(e) => {
        props.inputChangeHandler(props.state, props.stateName, {
          displayValue: props.subject?.name || '',
          id: props.subject?.id || '',
        });
        props.setState(false);
        e.stopPropagation();
      }}
      id={props.id}
    >
      <div className="subject-dropdown-card__img">
        <img src={`${props.subject?.coverImage}`} alt="subject" />
      </div>

      <div className="subject-dropdown-card__right">
        <div className="subject-dropdown-card__right-title">
          {props.subject?.id === ''
            ? props.noValueText
            : truncate(props.subject?.name || '', props.truncateNumber || 40)}
        </div>

        <div className="subject-dropdown-card__right-instructor">By {createdBy}</div>

        <div className="subject-dropdown-card__right-year">{props.subject?.year}</div>
      </div>
    </div>
  );
};

export default SubjectDropdownCard;
