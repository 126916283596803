import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageNotFoundScreen from '../common/PageNotFoundScreen';
import StudentUnitListScreen from './StudentUnitListScreen';

const StudentLessonScreen = () => {
  return (
    <Switch>
      <Route exact path="/my-subjects/subject/:subjectId/Lessons/:lessonId/My Units">
        <StudentUnitListScreen />
      </Route>
      <Route path="*" component={PageNotFoundScreen} />
    </Switch>
  );
};

export default StudentLessonScreen;
