import React, {FC} from 'react';
import {IconType} from 'react-icons';
import './Button.scss';

interface Props {
  text: string;
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  icon?: {icon: IconType; isLeft?: boolean; size?: number; gap?: number};
}

const Button: FC<Props> = (props) => {
  return (
    <button
      style={props.style}
      className={`shared-btn ${props.className} ${
        props.isLoading || props.isDisabled ? 'shared-btn--disabled' : ''
      } `}
      onClick={() => {
        if (props.isLoading || props.isDisabled) {
          return;
        }
        props.onClick();
      }}
    >
      {props.icon && props.icon.isLeft && (
        <props.icon.icon size={props.icon.size} style={{marginRight: props.icon.gap}} />
      )}{' '}
      {props.text}
      {props.icon && !props.icon.isLeft && (
        <props.icon.icon size={props.icon.size} style={{marginLeft: props.icon.gap}} />
      )}
    </button>
  );
};

export default Button;
