import React, {FC} from 'react';

import {titleCase} from 'title-case';
import './NumberInput.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  stateName: string;
  stateValue: string;
  state: any;
  setState: Function;
  verified?: boolean;
  error?: string;
  placeHolder?: string;
  onKeyPress?: (evt: any) => void;
  onChangeCallback?: () => void;
  maxLength?: number;
  unitType?: string;
  readonly?: boolean;
}

const NumberInput: FC<Props> = (props) => {
  const inputChangeHandler = (state: any, name: string, value: string) => {
    if (props.maxLength) {
      value = value.length > props.maxLength ? (value = value.slice(0, props.maxLength)) : value;

      if (+value < 0) {
        value = '0';
      }
    }
    props.setState({
      ...state,
      [name]: {...state[name], value: value},
    });

    props.onChangeCallback && props.onChangeCallback();
  };

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: {...state[name], error: ''},
    });
  };

  const onKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
    ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault();
    isNaN(Number(evt.key)) && evt.preventDefault();
  };

  let errorJSX = null;

  if (props.error !== 'NO-ERROR') {
    errorJSX = <div className={`w-100 err-styles`}>{props.error}</div>;
  }

  let borderColor =
    props.error === 'NO-ERROR'
      ? {borderBottom: '1px solid #20D167'}
      : props.error === ''
      ? {}
      : {borderBottom: '1px solid #dc3545'};

  const removeLeadingZero = (value: string) => {
    if (value === '00') {
      value = '0';
    }
    if (value === '01') {
      value = '1';
    }
    if (value === '02') {
      value = '2';
    }
    if (value === '03') {
      value = '3';
    }
    if (value === '04') {
      value = '4';
    }
    if (value === '05') {
      value = '5';
    }
    if (value === '06') {
      value = '6';
    }
    if (value === '07') {
      value = '7';
    }
    if (value === '08') {
      value = '8';
    }
    if (value === '09') {
      value = '9';
    }
    return value;
  };

  return (
    <div
      className={`position-relative ${props.style?.width ? '' : 'w-100'}`}
      style={{ ...props.style }}
    >
      <div className={`number-input ${props.className}`} style={props.style}>
        {props.unitType && props.stateValue?.length > 0 && (
          <span className="unit-type">{props.unitType} &nbsp;</span>
        )}
        <input
          type="number"
          name={props.stateName}
          autoComplete="off"
          required
          className={`${props.readonly ? 'disabled' : ''}`}
          maxLength={props.maxLength || 10}
          onChange={(e: any) => {
            let value = e.target.value.replace(/[^0-9.]/g, '');

            if (isNaN(Number(value))) {
              return;
            }

            !props.readonly &&
              inputChangeHandler(props.state, props.stateName, removeLeadingZero(value));
          }}
          value={props.stateValue}
          onKeyDown={() => {
            resetError(props.state, props.stateName);
          }}
          onKeyPress={onKeyDown}
        />

        <label htmlFor={props.stateName} className="number-input-label" style={borderColor}>
          <span className="number-input-label-text">
            {props.placeHolder ? titleCase(props.placeHolder) : titleCase(props.stateName)}
          </span>
        </label>
      </div>

      {errorJSX}
    </div>
  );
};

export default NumberInput;
