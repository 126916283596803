import React, {FC} from 'react';
import {FaFilePdf} from 'react-icons/fa';
import './UploadedPaper.scss';

interface Props {
  title: string;
  url: string | undefined;
}

const UploadedPaper: FC<Props> = (props) => {
  return (
    <a
      href={props.url}
      target="_blank"
      rel="noreferrer"
      className="uploaded-paper"
      style={!props.url ? {pointerEvents: 'none'} : {}}
    >
      <div className="text-center">
        <div className="uploaded-paper__icon-wrapper">
          <FaFilePdf className="uploaded-paper__pdf-icon" size={40} color="#474A66" />
          {!props.url ? <div className="uploaded-paper__no-upload-tag">NOT UPLOADED</div> : <></>}
        </div>
        <div className="text-white">{props.title}</div>
      </div>
    </a>
  );
};

export default UploadedPaper;
