import {useEffect} from 'react';
import {useFirestore} from 'reactfire';

import {EXAM_TYPE_TABS} from '../enums';
import {ExamStatus} from '../enums/ExamStatus';
import {ExamType} from '../enums/ExamType';
import {SUBJECT_DIRECTIVE_STATE} from '../interfaces';
import {Exam} from '../models/Exam';

const useFetch = (
  state: SUBJECT_DIRECTIVE_STATE,
  setState: React.Dispatch<React.SetStateAction<SUBJECT_DIRECTIVE_STATE>>,
  subjectId: string = ''
) => {
  const store = useFirestore();

  useEffect(() => {
    let type = ExamType.LIVE_EXAM;

    if (state.tab === EXAM_TYPE_TABS.PRACTISE) {
      type = ExamType.PRACTICE_EXAM;
    } else if (state.tab === EXAM_TYPE_TABS.EXTRA) {
      type = ExamType.EXTRA_EXAM;
    }
    setState((ps) => ({
      ...ps,
      isLoading: true,
    }));

    const commonRef = store
      .collection('exams')
      .orderBy('createdAt', 'desc')
      .where('examType', '==', type)
      .where('subjectId', '==', subjectId);

    commonRef
      .get()
      .then((res) => {
        const publishedList: Exam[] = [];
        const draftList: Exam[] = [];

        res.docs.forEach((exam) => {
          const ex = {id: exam.id, ...exam.data()} as Exam;

          ex.usersWhoStartedExamCount = ex.usersExamState?.length;
          ex.completedUsersCount = ex.completedUsers?.length;

          if (ex.status === ExamStatus.DRAFT) {
            draftList.push(ex);
          } else {
            publishedList.push(ex);
          }
        });

        setState((ps) => ({
          ...ps,
          draftList: draftList,
          publishedList: publishedList,
          isLoading: false,
        }));
      })
      .catch(() => {
        setState((ps) => ({
          ...ps,
          isLoading: false,
        }));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tab]);

  return state;
};

export default useFetch;
