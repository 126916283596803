import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useFunctions} from 'reactfire';
import PackageHeader from '../../components/PackageHeader';
import PrimaryNav from '../../components/PrimaryNav';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import {PACKAGE_BREADCRUMB_STEPS} from '../../enums/packageBreadCrum';
import LayoutNew from '../../layouts/LayoutNew';
import PackageLayout from '../../layouts/PackageLayout';
import {Package} from '../../models/Package';
import AdminPackageCreate from './AdminPackageCreate';

const AdminPackageViewScreen = () => {
  const {subjectId} = useParams() as {subjectId: string};
  const {packageId} = useParams() as {packageId: string};
  const [isEditPackage, setIsEditPackage] = useState(false);
  const [packageEditStep, setPackageEditStep] = useState<PACKAGE_BREADCRUMB_STEPS>(
    PACKAGE_BREADCRUMB_STEPS.STEP_ONE
  );
  const [packageData, setPackageData] = useState<Package[]>([]);
  const editPackage = useFunctions().httpsCallable('editPackage');
  const editHandler = () => {
    setIsEditPackage(true);
  };

  const packageHandler = (data: Package[]) => {
    setPackageData(data);
  };
  const [appBody, setAppBody] = useState(
    <PackageLayout editHandler={editHandler} packageHandler={packageHandler} />
  );
  const onPackageCreateStepOneSubmit = (data: any) => {
    editPackage({data: data, packageId: packageId})
      .then((res) => {
        setIsEditPackage(false);
        setPackageEditStep(PACKAGE_BREADCRUMB_STEPS.STEP_ONE);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let primaryNavigation = null;
  let secondaryNavigation = null;
  const [appHeader, setAppHeader] = useState<any>(null);
  primaryNavigation = <PrimaryNav />;
  secondaryNavigation = <SubjectSecondaryNav />;

  useEffect(() => {
    console.log(isEditPackage);
    if (isEditPackage) {
      console.log('package edit');
      setAppBody(
        <AdminPackageCreate
          active={packageEditStep}
          setStep={setPackageEditStep}
          onSubmit={onPackageCreateStepOneSubmit}
          subjectId={subjectId}
          data={packageData}
        />
      );
      setAppHeader(
        <PackageHeader
          name={'Edit Package'}
          active={packageEditStep}
          onBack={() => {
            setIsEditPackage(false);
          }}
        />
      );
    } else {
      setAppBody(<PackageLayout editHandler={editHandler} packageHandler={packageHandler} />);
      setAppHeader(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPackage, packageData, packageEditStep, subjectId]);

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

export default AdminPackageViewScreen;
