import {Key} from 'react';
import {ExamToDoDTO} from '../dtos/ExamToDoDTO';
import {ExamProgressState} from '../enums/ExamProgressState';
import {ExamType} from '../enums/ExamType';
import StudentPraciseExamCard from './StudentPraciseExamCard';

const StudentPracticeExamFilter = (props: {
  filterValue: string;
  examState: ExamProgressState;
  type: 'CURRENT' | 'UPCOMING' | 'COMPLETED';
  className: string | undefined;
  examsList: any[];
  isLoading: boolean;
  setSelectedExamsHandlers: ((exam: ExamToDoDTO | null) => void) | undefined;
}) => {
  return (
    <div className={props.className}>
      {props.examsList
        .filter(
          (ex: {examType: ExamType; examProgressState: ExamProgressState}, idx: any) =>
            ex.examType === ExamType.PRACTICE_EXAM && ex.examProgressState === props.examState
        )
        .filter((text, index, array) => {
          if (
            props.filterValue === '' ||
            props.filterValue === null ||
            props.filterValue === undefined
          ) {
            return array;
          } else {
            return text.examTitle
              .toLowerCase()
              .trim()
              .includes(props.filterValue.toLowerCase().trim());
          }
        })
        .map((exam: ExamToDoDTO, idx: Key | null | undefined) => (
          <StudentPraciseExamCard
            type={props.type}
            exam={exam}
            isLoading={props.isLoading}
            key={idx}
            setSelectedExamsHandlers={props.setSelectedExamsHandlers}
          />
        ))}
    </div>
  );
};

export const StudentPracticeCompletedExamFilter = (props: {
  filterValue: string;
  examState: ExamProgressState;
  type: 'CURRENT' | 'UPCOMING' | 'COMPLETED';
  className: string | undefined;
  examsList: any[];
  isLoading: boolean;
  setSelectedExamsHandlers: ((exam: ExamToDoDTO | null) => void) | undefined;
}) => {
  return (
    <div className={props.className}>
      {props.examsList
        .filter(
          (ex: { examType: ExamType; examProgressState: ExamProgressState }, idx: any) =>
            ex.examType === ExamType.PRACTICE_EXAM
        )
        .filter((text, index, array) => {
          if (
            props.filterValue === '' ||
            props.filterValue === null ||
            props.filterValue === undefined
          ) {
            return array;
          } else {
            return text.examTitle
              .toLowerCase()
              .trim()
              .includes(props.filterValue.toLowerCase().trim());
          }
        })
        .sort((a, b) => {
          return new Date(b.finishTime).getTime() - new Date(a.finishTime).getTime();
        })
        .map((exam: ExamToDoDTO, idx: Key | null | undefined) => (
          <StudentPraciseExamCard
            type={props.type}
            exam={exam}
            isLoading={props.isLoading}
            key={idx}
            setSelectedExamsHandlers={props.setSelectedExamsHandlers}
          />
        ))}
    </div>
  );
};

export default StudentPracticeExamFilter;
