import React, {FC} from 'react';
import PrimaryNav from '../../components/PrimaryNav';
import LayoutNew from '../../layouts/LayoutNew';
import BreadCrumbUpdate from '../../shared/BreadCrumbUpdate';
import {StudentLessonViewScreen} from './StudentLessonViewScreen';

interface Props {
  secondaryNav: any;
}

const StudentLessonListScreen: FC<Props> = (props) => {
  const primaryNav = <PrimaryNav />;
  const secondaryNavigation = props.secondaryNav;
  const body = <StudentLessonViewScreen />;
  let header = <BreadCrumbUpdate />;

  return (
    <LayoutNew
      header={header}
      primaryNav={primaryNav}
      secondaryNav={secondaryNavigation}
      body={body}
    />
  );
};

export default StudentLessonListScreen;
