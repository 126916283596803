import React, { FC } from 'react';
import './RejectFeedbackModal.scss';
import Modal from 'react-modal';
import { FaWindowClose } from 'react-icons/fa';
import './RejectFeedbackModal.scss';

interface Props {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  declineFeedback: string;
  onChange: (val: string) => void;
}

const RejectFeedbackModal: FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="add-subject-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="add-subject-modal__header">
        <FaWindowClose
          color="#FF4C6C"
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            props.onCancel();
          }}
          className="ml-auto"
        />
      </div>

      <div className="reject-feedback-wrapper">
        <div className="reject-feedback__header">Feedback</div>

        <textarea
          className="reject-feedback-textarea"
          placeholder="Enter feedback here (min 10 characters)"
          minLength={10}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          value={props.declineFeedback}
        />

        <div className="reject-feedback__footer">
          <button
            onClick={() => {
              if (props.declineFeedback.trim().length > 10) {
                props.onConfirm();
              }
            }}
            className="confirm-btn"
            style={
              props.declineFeedback.trim().length > 10
                ? { width: '30%', cursor: 'pointer', height: '30px' }
                : { background: 'grey', cursor: 'not-allowed', width: '30%', height: '30px' }
            }
          >
            Confirm
          </button>
          <button
            onClick={props.onCancel}
            className="cancel-btn"
            style={{
              width: '30%',
              cursor: 'pointer',
              height: '30px',
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RejectFeedbackModal;
