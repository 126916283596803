import React, { FC, useEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import ExamDashboard from '../../components/ExamDashboard';
import ExamGrades from '../../components/ExamGrades';
import SelectedExamDashboardHeader from '../../components/SelectedExamDashboardHeader';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import { PaperInfoData } from '../../interfaces';
import IncludeDashboardGradeSettingsTATabs from '../../layouts/IncludeDashboardGradeSettingsTATabs';
import ExamSettings from '../../components/ExamSettings';
import ExamTAAssignmentChart, {
  StudentTAAssignmentInfo,
} from '../../components/ExamTAAssignmentChart';
import { Exam } from '../../models/Exam';
import { ExamToDoDTO } from '../../dtos/ExamToDoDTO';
import PrimaryNav from '../../components/PrimaryNav';
import LayoutNew from '../../layouts/LayoutNew';
import { Paper } from '../../models/Paper';
import StudentRoster from '../../components/StudentRoster';
import { useAdminsAndInstructors } from '../../context/AdminsAndInstructors';
import PageNotFoundScreen from '../common/PageNotFoundScreen';
import LessonSecondaryNav from '../../components/LessonSecondaryNav';
import UnitSecondaryNav from '../../components/UnitSecondaryNav';
import { useLessons } from '../../context/LessonsContext';
import AdminContentSecondaryNav from '../../modules/content/components/AdminContentSecondaryNav';

interface State {
  exam: Exam | null;
  isLoading: boolean;
  reload: boolean;
  completedExams: ExamToDoDTO[];
  paperInfoData: PaperInfoData | null;
}

const initialState = {
  exam: null,
  isLoading: false,
  reload: false,
  completedExams: [],
  paperInfoData: null,
};

interface Props {
  hierarchyLeveL?: 'LESSON' | 'UNIT';
}

const AdminExamDetailsAndStatsScreen: FC<Props> = (props) => {
  const params = useParams() as {
    examId: string;
    lessonId: string;
    subjectId: string;
    unitId: string;
  };

  const store = useFirestore();

  const [state, setState] = useState<State>(initialState);

  const appLessons = useLessons();

  const selectedLessonHasUnits = appLessons.lessons.find(
    (l) => l.id === params.lessonId
  )?.isLessonContainsUnits;

  //methods
  const setLoading = (value: boolean) => {
    setState((ps) => {
      return { ...ps, isLoading: value };
    });
  };

  const setPapersInfoDataHandler = (value: PaperInfoData | null) => {
    setState((ps) => {
      return { ...ps, paperInfoData: value };
    });
  };

  const setCompletedExamsHandler = (exams: ExamToDoDTO[]) => {
    setState((ps) => {
      return { ...ps, completedExams: exams };
    });
  };

  const reloadHandler = () => {
    setState((ps) => ({
      ...ps,
      reload: !state.reload,
    }));
  };

  useEffect(() => {
    setLoading(true);

    store
      .collection('completedExams')
      .where('examId', '==', params.examId)
      .get()
      .then((res) => {
        const examsToDo: ExamToDoDTO[] = [];

        res.forEach((doc) => {
          const examToPush = { ...doc.data(), id: doc.id } as ExamToDoDTO;

          examsToDo.push(examToPush);
        });

        setState((ps) => {
          return {
            ...ps,
            isLoading: false,
            completedExams: examsToDo,
          };
        });
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reload]);

  useEffect(() => {
    setLoading(true);

    store
      .collection('exams')
      .doc(params.examId || '')
      .get()
      .then((response) => {
        return response.ref
          .collection('userExamCommencedTime')
          .get()
          .then((v) => {
            const list: any[] = [];

            v.docs.forEach((st) => {
              list.push({ ...st.data() });
            });

            const exam = { id: response.id, ...response.data() } as Exam;
            exam.usersExamState = list;
            exam.usersWhoStartedExamCount = list.length;

            setState((ps) => {
              return {
                ...ps,
                exam: exam,
                isLoading: false,
              };
            });
          })
          .catch(() => {
            setLoading(false);
          });
      })

      .catch(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reload]);

  const setUpdatedPaper = (papers: Paper[]) => {
    const exam = { ...state?.exam };

    if (exam && exam.papers) {
      exam.papers = papers;
      setState((ps) => {
        return { ...ps, exam: exam as Exam };
      });
    }
  };

  const primaryNavigation = <PrimaryNav />;
  const secondaryNavigation =
    props.hierarchyLeveL === 'LESSON' ? (
      selectedLessonHasUnits ? (
        <LessonSecondaryNav />
      ) : (
        <AdminContentSecondaryNav
          subjectId={params.subjectId}
          lessonId={params.lessonId}
          unitId={params.unitId}
        />
      )
    ) : props.hierarchyLeveL === 'UNIT' ? (
      <UnitSecondaryNav />
    ) : (
      <SubjectSecondaryNav />
    );
  const appHeader = (
    <SelectedExamDashboardHeader
      exam={state.exam}
      loading={state.isLoading}
      setLoading={setLoading}
      setPapersInfoDataHandler={setPapersInfoDataHandler}
      hierarchyLevel={props.hierarchyLeveL}
    />
  );

  // function removeDuplicatesFromStringArray(arr: string[]) {
  //   return arr.filter((item, index) => arr.indexOf(item) === index);
  // }

  const appAdminsAndInstructors = useAdminsAndInstructors();

  const updatestudentTAAssignmentInfoHandler = (input: StudentTAAssignmentInfo[]) => {
    setState((ps) => ({
      ...ps,
      exam: { ...ps.exam, studentTAAssignmentInfo: input } as Exam,
    }));
  };

  const completedExamUids = state.completedExams.map((cE) => cE.userId || '');

  // console.log(
  //   'completedExamUids removed',
  //   removeDuplicatesFromStringArray(completedExamUids?.sort())
  // );
  // console.log('completedExamUids Len', completedExamUids.length);

  const completedUsers = appAdminsAndInstructors.allAdminsAndInstructors.filter((u) =>
    completedExamUids?.includes(u.uid || '')
  );

  // console.log(
  //   'state.exam?.usersExamState ',
  //   removeDuplicatesFromStringArray(state.exam?.usersExamState?.map((u) => u.userId)?.sort() || [])
  // );
  // console.log(
  //   'state.exam?.usersExamState Len',
  //   state.exam?.usersExamState?.map((u) => u.userId)?.length
  // );

  const yetToSubmitIds = state.exam?.usersExamState
    ?.map((mU) => mU.userId)
    ?.filter((u) => !completedExamUids?.includes(u || ''));

  // const yetToSubmitIdsInverse = completedExamUids?.filter(
  //   (u) => !state.exam?.usersExamState?.map((uE) => uE.userId).includes(u || '')
  // );

  // console.log('yetToSubmitIds ', removeDuplicatesFromStringArray(yetToSubmitIds?.sort() || []));
  // console.log('yetToSubmitIds Len', yetToSubmitIds?.length);

  // console.log(
  //   'yetToSubmitIdsInverse ',
  //   removeDuplicatesFromStringArray(yetToSubmitIdsInverse?.sort() || [])
  // );
  // console.log('yetToSubmitIdsInverse Len', yetToSubmitIdsInverse?.length);

  const yetToSubmitUsers = appAdminsAndInstructors.allAdminsAndInstructors.filter((u) =>
    yetToSubmitIds?.includes(u.uid || '')
  );

  // const yetToSubmitUsersInverse = appAdminsAndInstructors.allAdminsAndInstructors.filter((u) =>
  //   yetToSubmitIdsInverse?.includes(u.uid || '')
  // );

  // console.log('DODODO ', yetToSubmitUsers);

  const routes =
    props.hierarchyLeveL === 'LESSON' ? (
      <Switch>
        <Route
          exact
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/Examinations/:examId/dashboard"
        >
          <ExamDashboard isLoading={state.isLoading} gradeType={state.exam?.gradingType} />
        </Route>
        <Route
          exact
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/Examinations/:examId/grades"
        >
          <ExamGrades selectedExam={state.exam} />
        </Route>
        <Route
          exact
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/Examinations/:examId/settings"
        >
          <ExamSettings selectedExam={state.exam} setUpdatedPaper={setUpdatedPaper} />
        </Route>
        <Route
          exact
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/Examinations/:examId/ta"
        >
          <ExamTAAssignmentChart
            updatestudentTAAssignmentInfoHandler={updatestudentTAAssignmentInfoHandler}
            hierarchyLevel={props.hierarchyLeveL}
          />
        </Route>
        <Route path="*" component={PageNotFoundScreen} />
      </Switch>
    ) : props.hierarchyLeveL === 'UNIT' ? (
      <Switch>
        <Route
          exact
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Examinations/:examId/dashboard"
        >
          <ExamDashboard isLoading={state.isLoading} gradeType={state.exam?.gradingType} />
        </Route>
        <Route
          exact
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Examinations/:examId/grades"
        >
          <ExamGrades selectedExam={state.exam} />
        </Route>
        <Route
          exact
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Examinations/:examId/settings"
        >
          <ExamSettings selectedExam={state.exam} setUpdatedPaper={setUpdatedPaper} />
        </Route>
        <Route
          exact
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Examinations/:examId/ta"
        >
          <ExamTAAssignmentChart
            updatestudentTAAssignmentInfoHandler={updatestudentTAAssignmentInfoHandler}
            hierarchyLevel={props.hierarchyLeveL}
          />
        </Route>
        <Route path="*" component={PageNotFoundScreen} />
      </Switch>
    ) : (
      <Switch>
        <Route exact path="/my-subjects/subject/:subjectId/Examinations/:examId/dashboard">
          <ExamDashboard isLoading={state.isLoading} gradeType={state.exam?.gradingType} />
        </Route>
        <Route exact path="/my-subjects/subject/:subjectId/Examinations/:examId/grades">
          <ExamGrades selectedExam={state.exam} />
        </Route>
        <Route exact path="/my-subjects/subject/:subjectId/Examinations/:examId/settings">
          <ExamSettings selectedExam={state.exam} setUpdatedPaper={setUpdatedPaper} />
        </Route>
        <Route exact path="/my-subjects/subject/:subjectId/Examinations/:examId/ta">
          <ExamTAAssignmentChart
            updatestudentTAAssignmentInfoHandler={updatestudentTAAssignmentInfoHandler}
            hierarchyLevel={props.hierarchyLeveL}
          />
        </Route>
        <Route path="*" component={PageNotFoundScreen} />
      </Switch>
    );

  const appBody = (
    <IncludeDashboardGradeSettingsTATabs
      essayOrStructuredPaperPresent={!!state.exam?.papers.find((val) => val.paperUrl)}
      hierarchyLeveL={props.hierarchyLeveL}
    >
      {routes}

      {state.paperInfoData ? (
        <StudentRoster
          papers={state.exam?.papers || []}
          subjectId={state.exam?.subjectId || ''}
          examId={state.exam?.id || ''}
          completedExams={state.completedExams}
          usersYetToSubmit={yetToSubmitUsers}
          completedUsers={completedUsers}
          paperInfoData={state.paperInfoData}
          setPaperInfoData={setPapersInfoDataHandler}
          setCompletedExams={setCompletedExamsHandler}
          setIsLoading={setLoading}
          isLoading={state.isLoading}
          studentTAAssignmentInfo={state.exam?.studentTAAssignmentInfo}
          reloadHandler={reloadHandler}
          reload={state.reload}
        />
      ) : (
        <></>
      )}
    </IncludeDashboardGradeSettingsTATabs>
  );

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

export default AdminExamDetailsAndStatsScreen;
