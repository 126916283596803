import moment from 'moment';
import React, {FC} from 'react';
import {Col, OverlayTrigger, ProgressBar, Row, Tooltip} from 'react-bootstrap';
import {FaCoins} from 'react-icons/fa';
import {ExamStatus} from '../enums/ExamStatus';
import {ExamType} from '../enums/ExamType';
import {Exam} from '../models/Exam';
import {getExamSubmissionStats, getSubmissionTypeArray} from '../utils';
import './ExamCard.scss';

interface Props {
  exam: Exam;
  onClick: () => void;
}

const ExamCard: FC<Props> = (props) => {
  const {LIVE_EXAM, PRACTICE_EXAM} = ExamType;

  const {submittedPercentage, notSubmittedPercentage, completedUsers, startedUsers} =
    getExamSubmissionStats(props.exam);

  const submissionTypeArray = getSubmissionTypeArray(props.exam.submissionType);

  const progressbars = (
    <ProgressBar className="rounded-pill" style={{backgroundColor: '#5B5D6F', transition: 'none'}}>
      <ProgressBar
        striped
        variant="progress_first"
        now={props.exam?.status === ExamStatus.DRAFT || !completedUsers ? 0 : submittedPercentage}
        key={1}
        style={{transition: 'none'}}
      />

      <ProgressBar
        striped
        style={{backgroundColor: '#5B5D6F', transition: 'none'}}
        now={
          props.exam?.status === ExamStatus.DRAFT || !completedUsers ? 0 : notSubmittedPercentage
        }
        key={2}
      />
    </ProgressBar>
  );

  const renderProgressBar = (startedUsers: any, completedUsers: any) => {
    if (props.exam?.status === ExamStatus.DRAFT) {
      return progressbars;
    } else {
      return (
        <OverlayTrigger
          placement="top"
          overlay={(props) => {
            return (
              <Tooltip {...props} id="tooltip">
                {startedUsers
                  ? completedUsers +
                    ' submitted. ' +
                    (startedUsers - completedUsers) +
                    ' not submitted.'
                  : '0 joined'}
              </Tooltip>
            );
          }}
        >
          {progressbars}
        </OverlayTrigger>
      );
    }
  };

  return (
    <Row
      className="row_card pt-4 pb-4 text-left mx-0 align-items-center instructor-exam-card"
      style={{cursor: 'pointer'}}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.exam?.examType === LIVE_EXAM && (
        <Col className="col-auto">
          <div style={{width: 150}}>
            {moment(props.exam?.startDate?.toDate()).format('DD / MM / YYYY')}
            <br />
            <span className="time">
              {moment(props.exam?.beginTime).format('LT') +
                ' - ' +
                moment(props.exam?.finishTime).format('LT')}
              {/* {convertStringToTwelveHoursClock(props.exam?.startTime || '00:00') +
                ' - ' +
                convertStringToTwelveHoursClock(props.exam?.endTime || '00:00')} */}
            </span>
          </div>
        </Col>
      )}
      {props.exam?.examType === PRACTICE_EXAM && (
        <Col className="col-auto">
          <div style={{width: 180}}>
            <Row>
              <Col>
                <span className="time">Start &nbsp;</span>
                <span className="time" style={{color: '#fff'}}>
                  {props.exam?.startDate?.toDate().toLocaleDateString()}
                </span>
                &nbsp;
                <span className="time">
                  {moment(props.exam?.beginTime).format('LT')}
                  {/* {convertStringToTwelveHoursClock(props.exam?.startTime || '00:00')} */}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="time">End &nbsp; &nbsp;</span>
                <span className="time" style={{color: '#fff'}}>
                  {props.exam?.endDate?.toDate().toLocaleDateString()}
                </span>
                &nbsp;
                <span className="time">
                  {moment(props.exam?.finishTime).format('LT')}
                  {/* {convertStringToTwelveHoursClock(props.exam?.endTime || '00:00')} */}
                </span>
              </Col>
            </Row>
          </div>
        </Col>
      )}
      <Col>{props.exam?.examTitle}</Col>
      <Col className="col-2">{submissionTypeArray?.join(', ')}</Col>
      <Col className="col-3">{renderProgressBar(startedUsers, completedUsers)}</Col>
      <Col className="col-1">
        <FaCoins size={20} color={props.exam?.price > 0 ? '#FFC01D' : '#7C7C7C'} />
      </Col>
    </Row>
  );
};

export default ExamCard;
