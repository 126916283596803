import React from 'react';
import {useParams} from 'react-router-dom';
import PrimaryNav from '../../components/PrimaryNav';

import SubjectDirectiveHeader from '../../components/SubjectDirectiveHeader';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import ViewNotices from '../../components/ViewNotices';
import LayoutNew from '../../layouts/LayoutNew';

const InstructorSubjectAnnouncementsScreen = () => {
  const {subjectId} = useParams() as {subjectId: string};
  const appBody = <ViewNotices subjectid={subjectId} />;
  let primaryNavigation = <PrimaryNav />;
  let secondaryNavigation = <SubjectSecondaryNav />;
  let appHeader = <SubjectDirectiveHeader />;

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

export default InstructorSubjectAnnouncementsScreen;
