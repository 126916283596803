import React, {FC} from 'react';
import {PaperQuestionDTO} from '../dtos/PaperQuestionDTO';
import {ExamType} from '../enums/ExamType';
import {PaperTypes} from '../enums/paperTypes';
import {PaperState} from '../interfaces';
import NavigatorPaperCard from './NavigatorPaperCard';
import './NavigatorRightPanelContent.scss';
import QuestionNavigatorBtn from './QuestionNavigatorBtn';

interface Props {
  timeArray: number[];
  timeTakenArray: number[];
  questions: PaperQuestionDTO[];
  currentQuestionIdx: number;
  moveToSpecificQuestion: (idx: number) => void;
  redo?: boolean;
  changePaper: (index: number) => void;
  selectedIndex: number;
  paperType: PaperTypes;
  currentPaper: PaperState;
  examPapers: PaperState[];
  examType: ExamType;
}

const NavigatorRightPanelContent: FC<Props> = React.memo((props) => {
  let timeBlock = props.timeArray.map((value, idx) => {
    if (value) {
      if (idx === 0) {
        return (
          <>
            <span>D</span>
            {value}
          </>
        );
      } else if (idx === 1) {
        return (
          <>
            {' '}
            <span>H</span>
            {value}
          </>
        );
      } else if (idx === 2) {
        return (
          <>
            {' '}
            <span>M</span>
            {value}
          </>
        );
      } else {
        return (
          <>
            {' '}
            <span>S</span>
            {value}
          </>
        );
      }
    } else {
      return <></>;
    }
  });

  let timeTakenBlock = props.timeTakenArray.map((value, idx) => {
    if (value) {
      if (idx === 0) {
        return (
          <>
            <span>D</span>
            {value}
          </>
        );
      } else if (idx === 1) {
        return (
          <>
            {' '}
            <span>H</span>
            {value}
          </>
        );
      } else if (idx === 2) {
        return (
          <>
            {' '}
            <span>M</span>
            {value}
          </>
        );
      } else {
        return (
          <>
            {' '}
            <span>S</span>
            {value}
          </>
        );
      }
    } else {
      return <></>;
    }
  });

  // console.log('ARRAY ', props.timeArray);
  // console.log('EXTRA EXAM TIME BLOCK ', timeBlock);

  return (
    <div className="navigator-right-panel">
      <div className="navigator-right-panel__counter">
        {/* <Hasith
          timeBlock={props.timeArray}
          timeTakenBlock={props.timeTakenArray}
          examType={props.examType}
        /> */}
        {props.examType === ExamType.EXTRA_EXAM ? timeTakenBlock : timeBlock}
      </div>
      <div className="navigator-right-panel__text">Question Navigator</div>
      <div className="navigator-right-panel__labels">
        <div className="navigator-right-panel__answered">
          <div></div>
          <div>Answered</div>
        </div>
        <div className="navigator-right-panel__not-answered">
          <div></div>
          <div>Not Answered</div>
        </div>
      </div>
      <div className="navigator-right-panel__answer-grid" style={{marginBottom: '-12px'}}>
        {props.questions.map((q, index) => {
          return (
            <QuestionNavigatorBtn
              idx={index + 1}
              key={index}
              isCurrentQuestion={props.currentQuestionIdx === index}
              onClick={props.moveToSpecificQuestion}
              question={q}
            />
          );
        })}
      </div>
      <div style={{overflow: 'auto', height: 180, marginTop: 40}}>
        {props.examPapers.map((paper, index) => (
          <NavigatorPaperCard
            selectedIndex={props.selectedIndex}
            index={index}
            changePaper={props.changePaper}
            paper={paper}
            key={index}
          />
        ))}
      </div>
    </div>
  );
});

// interface HasithProps {
//   timeBlock: number[];
//   timeTakenBlock: number[];
//   examType: ExamType;
// }

// const Hasith: FC<HasithProps> = (props) => {
//   const arrayToIterate =
//     ExamType.EXTRA_EXAM === props.examType ? props.timeTakenBlock : props.timeBlock;
//   let comps: any = <></>;

//   comps = arrayToIterate.map((value, idx) => {
//     if (value) {
//       if (idx === 0) {
//         return (
//           <>
//             <span>D</span>
//             {value}
//           </>
//         );
//       } else if (idx === 1) {
//         return (
//           <>
//             {' '}
//             <span>H</span>
//             {value}
//           </>
//         );
//       } else if (idx === 2) {
//         return (
//           <>
//             {' '}
//             <span>M</span>
//             {value}
//           </>
//         );
//       } else {
//         return (
//           <>
//             {' '}
//             <span>S</span>
//             {value}
//           </>
//         );
//       }
//     } else {
//       return <></>;
//     }
//   });

//   return comps;
// };

export default NavigatorRightPanelContent;
