export enum PAPER_BREADCRUM_STEPS {
  STEP_ONE = 'STEP_ONE',
  STEP_TWO = 'STEP_TWO',
  STEP_THREE = 'STEP_THREE',
}

export enum ST_ES_PAPER_BREADCRUM_STEPS {
  STEP_ONE = 'STEP_ONE',
  STEP_TWO = 'STEP_TWO',
}
