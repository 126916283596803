import React, {FC} from 'react';
import {FaBookOpen, FaCog, FaFileContract, FaUserAlt} from 'react-icons/fa';
import {useHistory, useLocation} from 'react-router';

import BookIcon from './../assets/book.png';

import './PrimaryNavMobile.scss';
import {AiFillNotification} from 'react-icons/ai';
import {useAppUser} from '../context/UserContext';
import {useAnnouncements} from '../context/AnnouncementContext';
import SideNavItem from './PrimaryNavItem';

interface Props {
  className?: string;
  restrictions?: {value: boolean; onClick: Function};
}

const PrimaryNavMobile: FC<Props> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const a = useAnnouncements();

  const account = <FaUserAlt size={16} color="#fff" />;
  const resources = <FaFileContract size={16} color="#fff" />;
  const subjects = <FaBookOpen size={16} color="#fff" />;
  const announcement = <AiFillNotification size={16} color="#fff" />;
  // const notices = <FaBullhorn size={16} color="#fff" />;
  const settings = <FaCog size={16} color="#fff" />;
  // const forum = <FaCommentAlt size={16} color="#fff" />;

  const appUser = useAppUser();

  let navItemsArray = [
    {icon: account, name: 'Account', path: '/account'},
    {
      icon: subjects,
      name: 'Subjects',
      path: '/my-subjects',
    },
    {
      icon: announcement,
      name: 'Announcements',
      path: '/announcements',
      label: a.announcements.filter((a) => !a.read).length + '',
    },
    // {icon: notices, name: 'Notices', path: '/notices'},
    // {icon: forum, name: 'Forum', path: '/forum'},
  ];

  if (appUser.firestoreUser?.userRole?.isAdmin) {
    navItemsArray = [
      {icon: account, name: 'Account', path: '/account'},
      {
        icon: subjects,
        name: 'Subjects',
        path: '/my-subjects',
      },
      {icon: resources, name: 'Resource', path: '/resource'},

      // {icon: notices, name: 'Notices', path: '/announcements'},
      {icon: settings, name: 'Settings', path: '/settings'},
      // {icon: forum, name: 'Forum', path: '/forum'},
    ];
  }

  if (appUser.firestoreUser?.userRole?.isInstructor) {
    navItemsArray = [
      {icon: account, name: 'Account', path: '/account'},
      {
        icon: subjects,
        name: 'Subjects',
        path: '/my-subjects',
      },
      {icon: resources, name: 'Resource', path: '/resource'},

      // {icon: notices, name: 'Notices', path: '/announcements'},
      // {icon: forum, name: 'Forum', path: '/forum'},
    ];
  }

  return (
    <div
      className={`primary-nav-mobile d-flex ${
        appUser.firestoreUser?.userRole?.isStudent ||
        appUser.firestoreUser?.userRole?.isTeachingAssistant
          ? ''
          : 'justify-content-between'
      }`}
    >
      <div className="text-center">
        <img src={BookIcon} width="28px" alt="sb-icon" />
      </div>
      {navItemsArray.map((item, idx) => {
        return (
          <SideNavItem
            icon={item.icon}
            name={item.name}
            active={item.path === '/' + location.pathname.split('/')[1]}
            navigationHandler={() => {
              if (!props.restrictions || !props.restrictions.value) {
                history.push(item.path);
              } else {
                props.restrictions.onClick(item.path);
              }
            }}
            key={idx}
            className={
              appUser.firestoreUser?.userRole?.isStudent ||
              appUser.firestoreUser?.userRole?.isTeachingAssistant
                ? 'mt-5'
                : ''
            }
            label={item.label}
          />
        );
      })}
    </div>
  );
};

export default PrimaryNavMobile;
