import React, {FC, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router';
import {ClipLoader} from 'react-spinners';
import {useFunctions} from 'reactfire';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import './EmailVerification.scss';
import {authError, validateEmail, validateString} from '../validation/Validation';
import useSessionStorage from '../hooks/useSessionStorage';
import {validationResult} from '../utils';
import Notification from './../components/Notification';
import MainLayout from '../layouts/MainLayout';
import TextInput from './TextInput';

const EmailVerification: FC = () => {
  const userActivateEmailRef = useFunctions().httpsCallable('userActivateEmail');

  const location = useLocation();

  const locationData = location.state as {
    email: string;
    uid: string;
    action: string;
    password: string;
  };

  const [show, setShow] = useState(false);

  const initialState = {
    loading: false,
    uid: {value: locationData?.uid || '', validations: [validateString], error: '', label: 'UID'},
    email: {
      value: locationData?.email || '',
      error: '',
      validations: [validateEmail],
      label: 'Email',
    },
    action: locationData?.action,
  };

  const [state, setState] = useSessionStorage('email-verfication', initialState);

  useEffect(() => {
    setState(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isCounter, setIsCounter] = useSessionStorage('counter', false);

  const submitHandler = async () => {
    if (isCounter) {
      return;
    }

    const validationOutput = validationResult({...state, loading: true});

    setState(validationOutput.state);

    //guard - 1
    if (!!validationOutput.formValidity) {
      return setState((ps: any) => ({
        ...ps,
        loading: false,
      }));
    }

    try {
      await userActivateEmailRef({
        email: state.email.value,
        uid: state.uid.value,
        password: locationData.password,
      });
      setIsCounter(true);
      setState((ps: any) => ({
        ...ps,
        loading: false,
      }));
    } catch (e: any) {
      console.log('error ', e.message);
      console.log(authError.get(e.message));
      setState((ps: any) => ({
        ...ps,
        loading: false,
      }));
      Notification({
        isSuccess: false,
        message: 'Failed to send activation email',
      });
    }
  };

  const timerProps = {
    isPlaying: true,
    size: 30,
    strokeWidth: 2,
  };

  useEffect(() => {
    return sessionStorage.removeItem('email-verfication');
  }, []);

  const disable =
    !state.email.value || !state.uid.value || !state.action ? 'resend__btn-disable' : '';

  return (
    <MainLayout>
      <div className="email-verification pb-2">
        {state.action === 'signUp' ? (
          <div>
            An email has been sent to your email <br></br> to
            <span className="verfiy-email-txt"> verify email </span> address
          </div>
        ) : (
          <div>Activate your email address</div>
        )}
        <div className="email-verification__description">
          <div>
            {state.action === 'signUp' ? <>Please check your email </> : ''}{' '}
            <a href="/" style={{pointerEvents: 'none'}}>
              <span> {locationData?.email}</span>
            </a>
          </div>

          <div
            onClick={() => {
              setShow(true);
            }}
            style={{cursor: 'pointer'}}
          >
            It will take less than 5 minutes to receive the email. If you still did not receive the
            email after 5 minutes.{' '}
            <strong style={{color: '#246bfd'}}>Click here to resend the email</strong>
          </div>
          {show ? (
            <div className="email-verification__form">
              <div className="input-wrapper">
                <TextInput
                  className="textinput-margin-signup"
                  stateName="email"
                  stateValue={state.email.value}
                  state={state}
                  setState={setState}
                  error={state.email.error}
                  placeHolder="Email"
                />
              </div>

              {isCounter && (
                <div className="email-resend-counter mt-4 ">
                  <div className="mr-2">Retry in</div>
                  <CountdownCircleTimer
                    duration={60}
                    colors="#246bfd"
                    {...timerProps}
                    onComplete={() => {
                      setIsCounter(false);
                    }}
                  >
                    {RenderTime}
                  </CountdownCircleTimer>
                </div>
              )}

              <div
                className={`resend__btn ${isCounter && 'resend__btn-disable'} ${disable}`}
                onClick={submitHandler}
              >
                {state.action === 'signUp' ? 'Resend Email' : 'Send Email'}
                &nbsp;
                <ClipLoader color={'purple'} loading={state.loading} size={18} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

const RenderTime: FC = ({remainingTime}: any) => {
  const currentTime = useRef(remainingTime);
  const prevTime = useRef(null);
  const isNewTimeFirstTick = useRef(false);
  const [, setOneLastRerender] = useState(0);

  if (currentTime.current !== remainingTime) {
    isNewTimeFirstTick.current = true;
    prevTime.current = currentTime.current;
    currentTime.current = remainingTime;
  } else {
    isNewTimeFirstTick.current = false;
  }

  // force one last re-render when the time is over to tirgger the last animation
  if (remainingTime === 0) {
    setTimeout(() => {
      setOneLastRerender((val) => val + 1);
    }, 20);
  }

  return (
    <div className="time-wrapper">
      <div key={remainingTime} className="time">
        {remainingTime}
      </div>
    </div>
  );
};

export default EmailVerification;
