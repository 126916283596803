import React, { FC, useEffect, useState } from 'react';
import './FilterQuestionsPopUp.scss';
import firebase from 'firebase';
import { StateDisplayMCQPapers } from './MCQTrueFalsePapersTab';
import { useSubjects } from '../context/SubjectsContext';
import { useLessons } from '../context/LessonsContext';
import { useAppUser } from '../context/UserContext';
import { Subject } from '../models/Subject';
import {
  sortLessonsByNameUsingContext,
  sortSubjectsByNameUsingContext,
  sortUserByName,
} from '../utils';
import FilterRadioButton from './FilterRadioButton';
import DropDown from './DropDown';
import { useUnits } from '../context/UnitsContext';
import { GRADES_NUMERIC_LABELS } from '../constants';

interface Props {
  show: boolean;
  onCloseModal: () => void;
  setState: (state: any) => void;
  state: StateDisplayMCQPapers;
  initialState: StateDisplayMCQPapers;
  onApplyfilter: () => void;
}

const FilterPapersPopUp: FC<Props> = (props) => {
  const appUser = useAppUser();
  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUnits = useUnits();

  const [subjects, setSubjects] = useState<{ id: string; displayValue: string }[]>([]);
  const [lessons, setLessons] = useState<{ id: string; displayValue: string }[]>([]);
  const [units, setUnits] = useState<{ id: string; displayValue: string }[]>([]);
  const [users, setUsers] = useState<{ id: string; displayValue: string }[]>([]);

  const [userSubjects, setUserSubjects] = useState<Subject[]>([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      firebase
        .firestore()
        .collection('users')
        .get()
        .then((data) => {
          const users = sortUserByName(data);

          setUsers(users);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [appUser.firestoreUser?.uid]);

  const availability = [
    { name: 'ALL', label: 'All' },
    { name: 'PUBLISHED', label: 'Pulished' },
    { name: 'DRAFT', label: 'Draft' },
  ];

  const isAdmin = appUser.firestoreUser?.userRole?.isAdmin;
  const isInstructor = appUser.firestoreUser?.userRole?.isInstructor;
  const isTeachingAssistant = appUser.firestoreUser?.userRole?.isTeachingAssistant;

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (props.state.grade) {
        const subs: Subject[] = appSubjects.allSubjects.filter((s) => {
          return isAdmin
            ? s.grade === props.state.grade
            : isInstructor
            ? (s.grade === props.state.grade && s.createdBy === appUser.firestoreUser?.uid) ||
              (s.grade === props.state.grade &&
                s.assignedUsers?.includes(appUser.firestoreUser?.uid || ''))
            : s.grade === props.state.grade &&
              s.assignedUsers?.includes(appUser.firestoreUser?.uid || '');
        });

        if (!isAdmin) {
          setUserSubjects(subs);
        }

        const items = sortSubjectsByNameUsingContext(subs);

        props.setState((ps: any) => {
          return { ...ps, subject: { ...ps.subject, value: { displayValue: '', id: '' } } };
        });

        setSubjects(items);
      } else {
        const subs: Subject[] = appSubjects.allSubjects.filter((s) => {
          return isInstructor
            ? s.createdBy === appUser.firestoreUser?.uid ||
                s.assignedUsers?.includes(appUser.firestoreUser?.uid || '')
            : isTeachingAssistant
            ? s.assignedUsers?.includes(appUser.firestoreUser?.uid || '')
            : [];
        });

        if (!isAdmin) {
          setUserSubjects(subs);
        }

        const items = sortSubjectsByNameUsingContext(subs);

        props.setState((ps: any) => {
          return { ...ps, subject: { ...ps.subject, value: { displayValue: '', id: '' } } };
        });

        setSubjects(items);
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state.grade]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (props.state.subject?.value.id) {
        const filteredLessons = appLessons.lessons.filter(
          (les) => les.subjectId === props.state.subject?.value.id
        );

        const sortedLessons = sortLessonsByNameUsingContext(filteredLessons);

        props.setState((ps: any) => {
          return { ...ps, lesson: { ...ps.lesson, value: { displayValue: '', id: '' } } };
        });

        setLessons(sortedLessons);
      } else {
        const subs: Subject[] = appSubjects.allSubjects.filter((s) => {
          return isInstructor
            ? s.createdBy === appUser.firestoreUser?.uid ||
                s.assignedUsers?.includes(appUser.firestoreUser?.uid || '')
            : isTeachingAssistant
            ? s.assignedUsers?.includes(appUser.firestoreUser?.uid || '')
            : [];
        });

        const sortedLessons = sortLessonsByNameUsingContext(
          appLessons.lessons.filter((les) => subs.filter((sub) => les.subjectId === sub.id).length)
        );

        props.setState((ps: any) => {
          return { ...ps, lesson: { ...ps.lesson, value: { displayValue: '', id: '' } } };
        });

        setLessons(sortedLessons);
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state.subject?.value.id]);

  useEffect(() => {
    if (props.state.lesson?.value.id) {
      const units = appUnits.units
        .filter((u) => u.lessonId === props.state.lesson?.value.id)
        .map((u) => ({ displayValue: u.name || '', id: u.id || '' }));

      setUnits(units);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state.lesson?.value.id]);

  return (
    <div className={`fq-popup position-absolute ${props.show ? 'd-block' : 'd-none'}`}>
      <div className="fq-popup__grade">
        <div className="grade">Grade</div>
        <div className="d-flex">
          {GRADES_NUMERIC_LABELS.map((item, idx) => (
            <div key={idx}>
              <FilterRadioButton
                onClick={() => {
                  props.setState((ps: any) => {
                    return { ...ps, grade: item.name };
                  });
                }}
                item={item}
                value={props.state.grade}
              />
            </div>
          ))}
        </div>
      </div>
      <br />
      <div className="fq-popup__grade">
        <div className="grade">Availability</div>
        <div className="d-flex availability-filter" style={{ marginLeft: '-42px' }}>
          {availability.map((item, idx) => (
            <div
              key={idx}
              style={{
                margin: '0px 20px 0px 0px',
              }}
            >
              <FilterRadioButton
                onClick={() => {
                  props.setState((ps: any) => {
                    return { ...ps, status: item.name };
                  });
                }}
                item={item}
                value={props.state.status}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="fq-popup__sub-less">
        <div>
          <DropDown
            name="Subject"
            noValueText="Select subject"
            className="textinput-margin-signup"
            stateName="subject"
            stateValue={props.state.subject?.value || { displayValue: '', id: '' }}
            state={props.state}
            setState={props.setState}
            error={''}
            optionsArray={subjects}
            style={{ width: '100%' }}
            labelSize={{ fontSize: 15, top: -5 }}
            // searchable={true}
            // initialState={props.initialState}
          />
        </div>
        <div>
          <DropDown
            name="Lesson"
            noValueText="Select lesson"
            className="textinput-margin-signup"
            stateName="lesson"
            stateValue={props.state.lesson?.value || { displayValue: '', id: '' }}
            state={props.state}
            setState={props.setState}
            error={''}
            optionsArray={lessons}
            labelSize={{ fontSize: 15, top: -5 }}
            // searchable={true}
          />
        </div>
      </div>
      <div className="fq-popup__sub-less">
        <div>
          <DropDown
            name="Unit"
            noValueText="Select unit"
            className="textinput-margin-signup"
            stateName="unit"
            stateValue={props.state.unit?.value || { displayValue: '', id: '' }}
            state={props.state}
            setState={props.setState}
            error={''}
            optionsArray={units}
            style={{ width: '100%' }}
            labelSize={{ fontSize: 15, top: -5 }}
          />
        </div>
      </div>
      <div style={{ marginTop: 15 }}>
        <div>
          <DropDown
            name="Created by"
            noValueText="Select author"
            stateName="user"
            stateValue={props.state.user?.value || { displayValue: '', id: '' }}
            state={props.state}
            setState={props.setState}
            error={''}
            optionsArray={
              isAdmin
                ? users
                : users.filter((u) => !!userSubjects.find((s) => s.createdBy === u.id))
            }
            labelSize={{ fontSize: 15, top: -5 }}
          />
        </div>
      </div>
      <div className="fq-popup__btns">
        <div
          onClick={() => {
            props.setState({
              ...props.initialState,
              papers: props.state.papers,
              page: props.state.page,
              totalPages: props.state.totalPages,
            });
            // props.onApplyfilter();
          }}
        >
          Clear Filters
        </div>
        <div>
          <div style={{ cursor: 'pointer' }} onClick={props.onCloseModal}>
            Cancel
          </div>
          <button
            className="apply"
            onClick={() => {
              props.onApplyfilter();
              props.onCloseModal();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPapersPopUp;
