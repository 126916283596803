import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../components/LoadingIndicator';
import MobileNav from '../components/MobileNav';
import { SCREEN } from '../constants';
import { useMobileNav } from '../context/MobNavContext';
import { useAppUser } from '../context/UserContext';
import Overlay from '../shared/Overlay';
import './Layout.scss';

interface Props {
  primaryNav: any;
  secondaryNav?: any;
  header: any;
  body: any;
  rightPanel?: { type: 'NAV_CONTAINER' | null; component: any };
  isLoading?: boolean;
  screen?: string;
  style?: any;
}

const LayoutNew: FC<Props> = (props) => {
  const mobileNav = useMobileNav();
  const history = useHistory();
  const user = useAppUser();

  if (props.screen === SCREEN.SCORE_SCREEN) {
    return (
      <div className="layout">
        <MobileNav
          className={`d-md-none ${!mobileNav.openMobileNav && 'd-none'}`}
          secondaryNav={props.secondaryNav}
          loading={props.isLoading}
        />
        <div>
          <div className="score-screen__side d-none d-md-block">{props.primaryNav}</div>

          <div className="score-screen__main">
            {props.isLoading && <LoadingIndicator className="d-none d-md-block" />}
            {props.header}

            {props.body}
          </div>
        </div>
      </div>
    );
  }

  if (props.screen === SCREEN.ANSWER_SCREEN) {
    return (
      <div className="layout">
        <MobileNav
          className={`d-md-none ${!mobileNav.openMobileNav && 'd-none'}`}
          secondaryNav={props.secondaryNav}
          loading={props.isLoading}
        />
        <div>
          <div className="answer-screen__side d-none d-md-block">{props.primaryNav}</div>

          <div className="answer-screen__main">
            {props.isLoading && <Overlay msg="Submitting" />}
            {props.header}
            {props.body}
          </div>

          <div className="answer-screen__right d-none d-md-block">
            <div>{props.rightPanel?.component}</div>
          </div>

          <div className="d-md-none mobile-view-q-navigator">{props.rightPanel?.component}</div>
        </div>
      </div>
    );
  }

  if (props.screen === SCREEN.DASHBOARD) {
    return (
      <div className="layout">
        <div>
          <div className="dash-screen__side d-none d-md-block">{props.primaryNav}</div>

          <div className="dash-screen__main">
            {props.isLoading && <LoadingIndicator />}
            {props.header}

            {props.body}
          </div>
        </div>
      </div>
    );
  }

  if (props.screen === SCREEN.EXAM_DETAILS_SCREEN) {
    return (
      <div className="layout">
        <div>
          <div className="exam-details-screen__side d-none d-md-block">
            {props.primaryNav}
            <div className="secondary-nav d-none d-md-block"> {props.secondaryNav}</div>
          </div>

          <div className="exam-details-screen__main">
            {props.isLoading && <LoadingIndicator />}
            {props.header}

            {props.body}
          </div>
        </div>
      </div>
    );
  }

  if (props.screen === SCREEN.EXAM_INFO_SCREEN) {
    return (
      <div className="layout">
        <MobileNav
          className={`d-md-none ${!mobileNav.openMobileNav && 'd-none'}`}
          secondaryNav={props.secondaryNav}
          loading={props.isLoading}
        />
        <div>
          <div className="exam-info-screen__side d-none d-md-block">{props.primaryNav}</div>
          <div className="exam-info-screen__main">
            {props.isLoading && <LoadingIndicator className="d-none d-md-block" />}
            {props.header}
            {props.body}
          </div>
        </div>
      </div>
    );
  }

  if (props.screen === SCREEN.CONTENT_PREVIEW) {
    const style =
      history.location.pathname.split('/').at(-1) === 'MainPreview' ||
      (history.location.pathname.split('/').includes('Preview') &&
        history.location.pathname.split('/').includes('Content')) ||
      (user.firestoreUser?.userRole?.isStudent &&
        history.location.pathname.split('/').at(-1) === 'Content')
        ? { marginLeft: '.5rem', marginRight: 0 }
        : {};
    return (
      <div className="layout">
        <MobileNav
          className={`d-md-none ${!mobileNav.openMobileNav && 'd-none'}`}
          secondaryNav={props.secondaryNav}
          loading={props.isLoading}
        />
        <div>
          <div
            className={`${props.secondaryNav ? 'side' : 'side--no-sidenav'} ${
              props.rightPanel?.type === 'NAV_CONTAINER' ? 'd-none d-lg-block' : ''
            }`}
          >
            {props.primaryNav}

            {props.secondaryNav && (
              <div className="secondary-nav d-none d-md-block"> {props.secondaryNav}</div>
            )}
          </div>

          <div
            style={style}
            className={`layout-content-main ${props.secondaryNav ? '' : 'main--no-sidenav'} `}
          >
            {props.isLoading && <LoadingIndicator className="d-none d-md-block" />}
            {props.header}

            {props.body}
          </div>

          {props.rightPanel ? (
            <div className="right">
              <div>{props.rightPanel}</div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="layout">
      <MobileNav
        className={`d-md-none ${!mobileNav.openMobileNav && 'd-none'}`}
        secondaryNav={props.secondaryNav}
        loading={props.isLoading}
      />
      <div>
        <div
          className={`${props.secondaryNav ? 'side' : 'side--no-sidenav'} ${
            props.rightPanel?.type === 'NAV_CONTAINER' ? 'd-none d-lg-block' : ''
          }`}
        >
          {props.primaryNav}

          {props.secondaryNav && (
            <div className="secondary-nav d-none d-md-block"> {props.secondaryNav}</div>
          )}
        </div>

        <div
          className={`main ${props.secondaryNav ? '' : 'main--no-sidenav'}  ${
            props.rightPanel ? 'main-right' : ''
          }`}
        >
          {props.isLoading && <LoadingIndicator className="d-none d-md-block" />}
          {props.header}
          {props.body}
        </div>

        {props.rightPanel ? (
          <div className="right">
            <div>{props.rightPanel}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LayoutNew;
