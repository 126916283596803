import React, { FC, useEffect, useState } from 'react';
import { useFirestore, useFunctions } from 'reactfire';
import moment from 'moment';
import './UserCard.scss';
import UserInformationModal from './UserInformationModal';
import InstructorBadge from './InstructorBadge';
import firebase from 'firebase';
import { UserFirestore } from '../models/UserFirestore';
import SwitchBtn from './SwitchBtn';
import { truncateText } from '../utils';
import Notification from '../components/Notification';
interface Props {
  index: number;
  userType?: { text: string; validated: boolean | undefined | null };
  setUsers?: React.Dispatch<React.SetStateAction<any[]>>;
  users?: UserFirestore[];
  user: UserFirestore;
  isInstructorValidation?: boolean;
  isTeachingAssistantApproved?: boolean;
  setLoading?: () => void;
  endLoading?: () => void;
  loading?: boolean;
  searchText: string;
}

const UserCard: FC<Props> = (props) => {
  const store = useFirestore();

  const [isShow, setIsShow] = useState(false);

  const changeUserEnableStatusRef = useFunctions().httpsCallable('changeUserEnableStatus');

  const [state, setState] = useState<{ checked: boolean | undefined }>({
    checked: props.user?.enabled,
  });

  useEffect(() => {
    setState((ps) => {
      return { ...ps, checked: props.user?.enabled };
    });
  }, [props.user.enabled]);

  const changeHandler = (value: boolean) => {
    props.setLoading && props.setLoading();

    changeUserEnableStatusRef({
      id: props.user.uid,
      value: value,
    })
      .then((res) => {
        const usersFromDB: any[] = [];
        store
          .collection('users')
          .orderBy('lowercaseFirstName')
          .get()
          .then((res) => {
            res.forEach((doc) => {
              usersFromDB.push({ ...doc.data(), id: doc.id });
            });

            props.setUsers && props.setUsers(usersFromDB);
          })
          .catch(() => {
            console.log('Error occured fetching user from DB');
          });
        // setState((ps) => ({...ps, checked: res.data.data.enabled}));
        Notification({
          isSuccess: true,
          message: value
            ? 'User was activated successfully!'
            : 'User was deactivated successfully!',
        });
      })
      .catch((e) => {
        Notification({
          isSuccess: false,
          message: `${
            value ? 'Activation failed!' : 'Deactivation failed!'
          }, Please try again later.`,
        });
        console.log('Error ', e);
      })
      .finally(() => {
        props.endLoading && props.endLoading();
      });
  };

  let content = null;

  if (props.isInstructorValidation) {
    let date: any;

    if (props.user.createdAt) {
      const da: firebase.firestore.Timestamp = props.user.createdAt;
      try {
        date = da?.toDate();
      } catch (e) {}
    }

    content = (
      <div
        className="user-card"
        onClick={() => {
          setIsShow(true);
        }}
      >
        <div>
          <div className="user-card__firstname">{truncateText(props.user.firstName, 17) || ''}</div>
          <div className="user-card__margin"></div>
          <div className="user-card__lastname">{truncateText(props.user.lastName, 17) || ''}</div>
          <div className="user-card__registered-date">{moment(date).format('L')}</div>
        </div>
        <div></div>
      </div>
    );
  } else {
    content = (
      <div
        className="user-card"
        onClick={() => {
          setIsShow(true);
        }}
      >
        <div>
          <div className="user-card__firstname">{truncateText(props.user.firstName, 17) || ''}</div>
          <div className="user-card__margin"></div>
          <div className="user-card__lastname">{truncateText(props.user.lastName, 17) || ''}</div>
          <div className="user-card__instructor">
            <InstructorBadge userType={props.userType} />
          </div>
        </div>
        <div className="pl-1 mr-3">
          <SwitchBtn
            changeHandler={changeHandler}
            checked={state.checked}
            id={props.index}
            loading={props.loading}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {content}

      {props.users?.length && (
        <UserInformationModal
          isShow={isShow}
          onClose={() => {
            setIsShow(false);
          }}
          isInstructorValidation={props.isInstructorValidation}
          isTeachingAssistantApproved={props.isTeachingAssistantApproved}
          role={props.userType}
          index={props.index}
          setUsers={props.setUsers}
          users={props.users}
          user={props.user}
          changeHandler={changeHandler}
          loading={props.loading}
        />
      )}
    </>
  );
};

export default UserCard;
