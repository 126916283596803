import React from 'react';
import {Route, Switch} from 'react-router-dom';
import CreateNotice from '../../components/CreateNotice';
import PrimaryNav from '../../components/PrimaryNav';
import ViewNotice from '../../components/ViewNotice';
import ViewNotices from '../../components/ViewNotices';
import {useAppUser} from '../../context/UserContext';
import LayoutNew from '../../layouts/LayoutNew';

// import NoticeSideBar from './components/NoticeSideBar';
import './Notices.scss';
import PageNotFoundScreen from './PageNotFoundScreen';

// import UnderConstruction from '../../shared/ui/UnderConstructionCom/UnderConstruction';

const Notices = () => {
  const primaryNav = <PrimaryNav />;
  return <LayoutNew header={null} primaryNav={primaryNav} body={<NoticeLayout />} />;
};

export default Notices;

const NoticeLayout = () => {
  const {firestoreUser} = useAppUser();
  return (
    <div className="notice-layout-wrapper">
      {/* <NoticeSideBar /> */}
      <div
        className="notice-content-wrapper"
        style={{
          overflowY: 'auto',
          paddingRight: '1rem',
        }}
      >
        <Switch>
          {(firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) && (
            <Route
              path="/announcements/create"
              exact
              component={() => <CreateNotice type="create" />}
            />
          )}
          {/* {(firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) && (
            <Route
              path="/announcements/create/:subjectId"
              exact
              component={() => <CreateNotice type="create" />}
            />
          )} */}
          {(firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) && (
            <Route
              path="/announcements/edit/:id"
              exact
              component={() => <CreateNotice type="update" />}
            />
          )}
          {/* {(firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) && (
            <Route
              path="/announcements/edit/:id/:subjectId"
              exact
              component={() => <CreateNotice type="update" />}
            />
          )} */}

          <Route path="/announcements" exact component={ViewNotices} />
          <Route exact path="/Announcements/subject/:subjectId/:id" component={ViewNotice} />
          <Route path="/announcements/:id" component={ViewNotice} />
          <Route path="*" component={PageNotFoundScreen} />
        </Switch>
      </div>
    </div>
  );
};
