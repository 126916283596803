import React, {FC} from 'react';
import './FilterCheckboxLeft.scss';

interface Props {
  onClick: () => void;
  item: {label: string; name: string};
  value?: string | null;
  isChecked?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

const FilterCheckboxLeft: FC<Props> = (props: Props) => {
  return (
    <>
      <div className="checkbox-container">
        <div
          className={`d-inline-block checkbox-text ${props.isDisabled ? 'disabled' : ''} ${props.isReadOnly ? 'read-only' : ''}`}
          onClick={props.onClick}>
        <span>
          {props.item.label}
        </span>
        </div>
        <div onClick={() => {
          !props.isDisabled && props.onClick();
        }}
             className={['tick-mark d-inline-block ', props.isChecked ? 'checked' : '', props.isDisabled ? 'disabled' : '', props.isReadOnly ? 'read-only' : ''].join(' ')} />
      </div>
    </>
  );
};

export default FilterCheckboxLeft;
