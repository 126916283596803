import React, { FC, useEffect, useState } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import Modal from 'react-modal';
import { UserFirestore } from '../models/UserFirestore';
import './AddNewUserModal.scss';
import ButtonComp from './ButtonComp';
import DropDown from './DropDown';
import { INSTRUCTOR_USER_TYPES, USER_TYPES } from '../constants';
import { addUserInitialState } from '../interfaces';
import { useFunctions } from 'reactfire';
import Notification from './../components/Notification';
import LoadingIndicator from './LoadingIndicator';
import { validationResult } from '../utils';
import { useAppUser } from '../context/UserContext';

interface Props {
  show: boolean;
  users: UserFirestore[];
  subjectId: string;
  addNewUserModalHandler?: (value: boolean) => void;
  onAddNewUser: (id: string) => void;
}

const AddNewUserModal: FC<Props> = (props) => {
  const appUser = useAppUser();
  const [state, setState] = useState(addUserInitialState);
  const [users, setUsers] = useState<{ displayValue: string; id: string }[]>([]);

  const assignSubjectsToInstructorAndTARef = useFunctions().httpsCallable(
    'assignSubjectsToInstructorAndTA'
  );

  useEffect(() => {
    const filterUsers = props.users
      .filter((user: UserFirestore) => {
        if (state.userType.value.id === 'instructor') {
          return user.userRole?.isInstructor;
        } else {
          return user.userRole?.isTeachingAssistant;
        }
      })
      .map((user: UserFirestore) => {
        return { displayValue: user.username, id: user.uid };
      });

    setState((ps) => ({
      ...ps,
      ...addUserInitialState,
      userType: {
        ...addUserInitialState.userType,
        value: { displayValue: state.userType.value.displayValue, id: state.userType.value.id },
      },
      user: { ...addUserInitialState.user, value: { displayValue: '', id: '' } },
    }));

    setUsers(filterUsers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userType.value.id]);

  const addUserHandler = async () => {
    const validationOutput = validationResult({ ...state, loading: true });

    setState(validationOutput.state);
    let addUserFormValidty = true;

    if (!!validationOutput.formValidity) {
      addUserFormValidty = false;
    }

    if (!addUserFormValidty) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    assignSubjectsToInstructorAndTARef({
      assignedSubject: props.subjectId,
      uid: state.user.value.id,
    })
      .then((data) => {
        props.onAddNewUser(data.data.data);
        Notification({
          isSuccess: true,
          message: `Assigned ${state.user.value.displayValue} to the subject`,
          id: 'tid',
        });
      })
      .catch(() => {
        Notification({
          isSuccess: false,
          message: `Failed to Assigned ${state.user.value.displayValue} to the subject`,
          id: 'tid',
        });
      })
      .finally(() => {
        setState((pS) => ({
          ...pS,
          loading: false,
        }));

        setState(addUserInitialState);
        props.addNewUserModalHandler && props.addNewUserModalHandler(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        contentLabel="Example Modal"
        className={'add-new-user-modal'}
        overlayClassName="r-overlay"
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        <div className="add-new-user-modal-header">
          Add User
          <FaWindowClose
            color="#FF4C6C"
            size={20}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setState(addUserInitialState);
              props.addNewUserModalHandler && props.addNewUserModalHandler(false);
            }}
          />
        </div>

        {state.loading && <LoadingIndicator />}

        <div className="add-new-user-modal_user-role-input-field">
          {appUser.firestoreUser?.userRole?.isAdmin ? (
            <DropDown
              name={'User Type'}
              noValueText={'Select User Type'}
              stateName="userType"
              stateValue={state.userType.value}
              state={state}
              optionsArray={USER_TYPES}
              setState={setState}
              error={state.userType.error}
            />
          ) : (
            <DropDown
              name={'User Type'}
              noValueText={'Select User Type'}
              stateName="userType"
              stateValue={state.userType.value}
              state={state}
              optionsArray={INSTRUCTOR_USER_TYPES}
              setState={setState}
              error={state.userType.error}
            />
          )}
        </div>

        <div className="add-new-user-modal_users-input-field">
          <DropDown
            name={'Select User'}
            noValueText={'Select User'}
            stateName="user"
            stateValue={state.user.value}
            state={state}
            optionsArray={users}
            setState={setState}
            error={state.user.error}
            disabled={!state.userType.value.id}
          />
        </div>

        <div className="add-new-user-modal-confirm-button">
          <ButtonComp
            className={`${state.loading ? `add-new-user-modal-button-disabled` : ''}`}
            type={'one'}
            text={'Add User'}
            onClick={() => {
              if (!state.loading) {
                addUserHandler();
              }
            }}
          />

          <ButtonComp
            className={`${state.loading ? `add-new-user-modal-button-disabled` : ''}`}
            type={'one'}
            text={'Cancel'}
            onClick={() => {
              if (!state.loading) {
                setState(addUserInitialState);
                props.addNewUserModalHandler && props.addNewUserModalHandler(false);
              }
            }}
            style={{ background: 'transparent', gap: '20px' }}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddNewUserModal;
