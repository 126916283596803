import React from 'react';
import {useHistory} from 'react-router-dom';
import Backarrow from './Backarrow';
import './PageNotFound.scss';
import Img404 from './../assets/pageNotFound.png';
import ButtonComp from './ButtonComp';

const PageNotFound = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  return (
    <div className="page-not-found">
      <div className="page-not-found__img-404">
        <img src={Img404} alt="Page not found" />
      </div>
      <div className="page-not-found__main-title">PAGE NOT FOUND</div>
      <div className="page-not-found__info">
        We could not find the page you are looking for, The page might have been removed/changed or
        URL is wrong.
      </div>

      <div className="d-flex flex-row btn-group-404">
        <Backarrow onClick={handleClick} />
        <div className="ml-2">
          <ButtonComp
            text="Bring Me to Home Page"
            onClick={handleClick}
            style={{height: '40px', width: '266px', borderRadius: '8px', fontSize: '20px'}}
            type={'one'}
          />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
