import React, { FC } from 'react';
import { FaEye, FaPlusCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { useAppContent } from '../../context/ContentContext';
import RoutingPaths from '../../routes/RoutingPaths';
import './ContentListHeader.scss';

interface Props {
  subId?: string;
  lessId?: string;
  unitId?: string;
}

const ContentListHeader: FC<Props> = (props) => {
  const history = useHistory();
  const appContent = useAppContent();
  const PATHS = new RoutingPaths(props.subId || '', props.lessId || '', props.unitId || '');

  return appContent.contentData.length > 0 ? (
    <>
      <header className="content-list-header">
        <h5 className="content-list-header__title" style={{ fontSize: '1.5rem' }}>
          Content
        </h5>
        <div className="content-list-header__actions">
          <div
            className="content-list-header__eye"
            onClick={() => {
              appContent.setPreviousPathToPreview(PATHS.baseURL);

              history.push(
                new RoutingPaths(
                  props?.subId || '-',
                  props.lessId || '-',
                  props?.unitId
                ).previewContent()
              );

              if (appContent.contentData.length) {
                appContent.setMainDroppableId(appContent.contentData[0].droppableId);
              }
            }}
          >
            <FaEye />
          </div>
          <div
            className="content-list-header__btn ml-2"
            onClick={() => {
              appContent.setPreviousPathToPreview(history.location.pathname);
              history.push(
                new RoutingPaths(
                  props?.subId || '-',
                  props.lessId || '-',
                  props?.unitId
                ).createContent()
              );
            }}
          >
            <FaPlusCircle className="mr-2" />
            <span>Add Content</span>
          </div>
        </div>
      </header>
      {appContent.loading ? <LoadingIndicator /> : <></>}
    </>
  ) : (
    <></>
  );
};

export default ContentListHeader;
