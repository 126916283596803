import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { GRADE_TYPES } from '../constants';
import { PractiseExamCreationDto } from '../dtos/PractiseExamCreationDto';
import { EXAM_CREATION_STATE, PaperPayment } from '../interfaces';
import FilterCheckBox from './FilterCheckBox';
import { useHistory, useParams } from 'react-router-dom';
import {
  appendZeroInFront,
  getDateToSave,
  getSubmissionTypes,
  setInitialDatesAndTimesForLiveExams,
} from '../utils';
import { useFunctions } from 'reactfire';
import { useAppUser } from '../context/UserContext';
import { ExamStatus } from '../enums/ExamStatus';
import { ExamType } from '../enums/ExamType';
import Notification from './../components/Notification';
import TimeInputComp from './TimeInputComp';
import { PaperTypes } from '../enums/paperTypes';
import DropDown from './DropDown';
import NumberInput from './NumberInput';
import DatePickerWithIcon from './DatePickerWithIcon';
import { validationResult } from '../utils';
import AlertPopup from './AlertPopup';
import firebase from 'firebase';
import ButtonComp from './ButtonComp';

interface Props {
  state: EXAM_CREATION_STATE;
  setState: React.Dispatch<React.SetStateAction<EXAM_CREATION_STATE>>;
  onBack: () => void;
  paperPayments?: PaperPayment[];
  update?: boolean;
}

const ExamCreationStepThreePractise: FC<Props> = (props) => {
  const { state, setState } = props;

  const appUser = useAppUser();

  const history = useHistory();

  const params = useParams() as { subjectId: string; lessonId: string; unitId: string };

  const [errorState, setErrorState] = useState('');
  const [warningState, setWarningState] = useState<{
    examStatus: ExamStatus;
    message: string;
  } | null>(null);

  // console.log('Start date', state.startDate.value);
  // console.log('End date', state.endDate.value);
  // console.log('Start time', state.startTime.value);
  // console.log('End time', state.endTime.value);

  //set fifteen mins
  useEffect(() => {
    //set initial times

    if (!state.endTime.value.hours) setInitialDatesAndTimesForLiveExams(state, setState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerOnChange = (beginTime: Date) => {
    let tHours = 0;
    let tMins = 0;
    let totalTimeInMinsOfAllPapers = 0;

    state.papers.value.forEach((p) => {
      tHours = tHours + +p.allocatedTime.split(':')[0];
      tMins = tMins + +p.allocatedTime.split(':')[1];
    });

    totalTimeInMinsOfAllPapers = tHours * 60 + tMins;

    const dateToday = new Date();
    dateToday.setHours(23);
    dateToday.setMinutes(59);
    dateToday.setSeconds(0);
    dateToday.setMilliseconds(0);

    const finalTimeMiliseconds = totalTimeInMinsOfAllPapers * 60_000;

    const finalTime = new Date(+beginTime + +finalTimeMiliseconds);

    const endHours = new Date(+finalTime).getHours();
    const endMins = new Date(+finalTime).getMinutes();

    setState((ps) => {
      return {
        ...ps,
        endTime: {
          ...ps.endTime,
          value: {
            hours: appendZeroInFront(endHours + ''),
            minutes: appendZeroInFront(endMins + ''),
          },
        },
      };
    });
  };

  const getDuration = () => {
    const startDate = new Date(state.startDate.value);
    const endDate = new Date(state.startDate.value);
    const startTime = state.startTime.value;
    const endTime = state.endTime.value;
    const startDateTime = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      parseInt(startTime.hours || '0'),
      parseInt(startTime.minutes || '0')
    );
    const endDateTime = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      parseInt(endTime.hours || '0'),
      parseInt(endTime.minutes || '0')
    );
    const duration = endDateTime.getTime() - startDateTime.getTime();
    const durationInMinutes = duration / (1000 * 60);
    const minutes = durationInMinutes % 60;
    const hours = (durationInMinutes - minutes) / 60;
    return `${('0' + hours.toString()).slice(-2)}h ${('0' + minutes.toString()).slice(-2)}m`;
  };

  const functions = useFunctions();
  const createExamRef = functions.httpsCallable('createExam');
  const updateExamRef = functions.httpsCallable('updateExam');

  const onSave = (status: ExamStatus, byPassCondition?: boolean) => {
    const nowDate = new Date();
    nowDate.setHours(0);
    nowDate.setMinutes(0);
    nowDate.setSeconds(0);
    nowDate.setMilliseconds(0);

    const startDate = new Date(
      state.startDate.value.getFullYear(),
      state.startDate.value.getMonth(),
      state.startDate.value.getDate(),
      parseInt(state.startTime.value.hours || '0'),
      parseInt(state.startTime.value.minutes || '0'),
      0,
      0
    );

    const endDate = new Date(
      state.endDate.value.getFullYear(),
      state.endDate.value.getMonth(),
      state.endDate.value.getDate(),
      parseInt(state.endTime.value.hours || '0'),
      parseInt(state.endTime.value.minutes || '0'),
      0,
      0
    );

    const beginTimeToSave = getDateToSave(
      startDate,
      `${state.startTime.value.hours}:${state.startTime.value.minutes}`
    );

    const finishTimeToSave = getDateToSave(
      endDate,
      `${state.endTime.value.hours}:${state.endTime.value.minutes}`
    );

    let tHours = 0;
    let tMins = 0;
    let totalTimeInMinsOfAllPapers = 0;

    state.papers.value.forEach((p) => {
      tHours = tHours + +p.allocatedTime.split(':')[0];
      tMins = tMins + +p.allocatedTime.split(':')[1];
    });

    totalTimeInMinsOfAllPapers = tHours * 60 + tMins;

    if (+startDate <= +nowDate) {
      setErrorState("The date should be greater than or equal to today's date");
    } else if (+beginTimeToSave < +new Date()) {
      setErrorState('Start time should be greater than current time');
    } else if (+startDate > +endDate) {
      setErrorState('End date should be greater than start date');
    } else if (+beginTimeToSave >= +finishTimeToSave) {
      setErrorState('End time should be greater than start time');
    } else if (+finishTimeToSave - +beginTimeToSave < 900000) {
      setErrorState('An exam should be at least 15 minutes long');
    } else if (
      +finishTimeToSave - +beginTimeToSave < totalTimeInMinsOfAllPapers * 60_000 &&
      !byPassCondition
    ) {
      setWarningState({
        examStatus: status,
        message:
          'The exam contains less time than the time allocated for the papers. Click OK to proceed with reduced time',
      });
    } else {
      const validationOutput = validationResult({
        ...state,
      });

      setState(validationOutput.state);

      if (validationOutput.formValidity.trim().length > 0) {
        setState((pS) => ({
          ...pS,
          isLoading: { ...pS.isLoading, value: false },
        }));
        return;
      }

      setState((ps) => {
        return { ...ps, isLoading: { ...ps.isLoading, value: true } };
      });

      const { subTypeMCQ, subTypeTF, subTypeSTR, subTypeESY } = getSubmissionTypes(
        state.papers.value
      );

      const paymentAddedPapers = state.papers.value.map((paper) => {
        const val = props.paperPayments?.find((p) => p.paperId === paper.id);
        let p = paper;
        if (paper.paperType === PaperTypes.STRUCTURED || paper.paperType === PaperTypes.ESSAY) {
          p = {
            ...paper,
            taPayment: { amount: val?.payment.amount || 0, isFree: !!val?.payment.isFree },
          };
        }

        return p;
      });

      console.log('');

      const dataToSave: PractiseExamCreationDto = {
        authorName: appUser.firestoreUser?.username || '',
        description: state.description.value,
        examDuration: getDuration(),
        examTitle: state.examTitle.value,
        examType: ExamType.PRACTICE_EXAM,
        grade: state.grade.value.id,
        gradingType: state.gradingType.value.id,
        lessonId: state.lesson.value.id,
        unitId: state.unit.value.id,
        lessonIds: state.lessons.value.map((l) => l.id),
        unitIds: state.units.value.map((u) => u.id),
        overallMark: parseInt(state.overallMark.value),
        papers: paymentAddedPapers,
        price: state.price.value,
        status: status,
        subjectId: state.subject.value.id,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        startTime: `${state.startTime.value.hours}:${state.startTime.value.minutes}`,
        endTime: `${state.endTime.value.hours}:${state.endTime.value.minutes}`,
        beginTime: beginTimeToSave.toUTCString(),
        finishTime: finishTimeToSave.toUTCString(),
        beginTimeTimeStamp: firebase.firestore.Timestamp.fromDate(beginTimeToSave),
        finishTimeTimeStamp: firebase.firestore.Timestamp.fromDate(finishTimeToSave),
        submissionType: {
          MCQ: subTypeMCQ,
          TrueFalse: subTypeTF,
          File: subTypeSTR || subTypeESY,
        },
      };

      if (state.id) {
        updateExamRef({ ...dataToSave, id: state.id })
          .then(() => {
            if (status === ExamStatus.PUBLISHED) {
              Notification({
                isSuccess: true,
                message: 'Exam updated successfully',
                id: 'tid',
              });
            } else {
              Notification({
                isSuccess: true,
                message: 'Exam updated successfully as a draft',
                id: 'tid',
              });
            }

            setState((ps) => {
              return { ...ps, isLoading: { ...ps.isLoading, value: false } };
            });

            if (params.subjectId && params.lessonId && params.unitId) {
              history.push(
                `/my-subjects/subject/${state.subject.value.id}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations?name=practise`
              );
            } else if (params.subjectId && params.lessonId) {
              history.push(
                `/my-subjects/subject/${state.subject.value.id}/lesson/${params.lessonId}/Examinations?name=practise`
              );
            } else {
              history.push(
                `/my-subjects/subject/${state.subject.value.id}/Examinations?name=practise`
              );
            }

            // history.push(
            //   `/my-subjects/subject/${state.subject.value.id}/Examinations?name=practise`
            // );
          })
          .catch((err: any) => {
            console.log(err);
            setState((ps) => {
              return { ...ps, isLoading: { ...ps.isLoading, value: false } };
            });
          });
      } else {
        createExamRef(dataToSave)
          .then(() => {
            if (status === ExamStatus.PUBLISHED) {
              Notification({
                isSuccess: true,
                message: 'Exam created successfully',
                id: 'tid',
              });
            } else {
              Notification({
                isSuccess: true,
                message: 'Exam created successfully as a draft',
                id: 'tid',
              });
            }

            setState((ps) => {
              return { ...ps, isLoading: { ...ps.isLoading, value: false } };
            });

            if (params.subjectId && params.lessonId && params.unitId) {
              history.push(
                `/my-subjects/subject/${state.subject.value.id}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations?name=practise`
              );
            } else if (params.subjectId && params.lessonId) {
              history.push(
                `/my-subjects/subject/${state.subject.value.id}/lesson/${params.lessonId}/Examinations?name=practise`
              );
            } else {
              history.push(
                `/my-subjects/subject/${state.subject.value.id}/Examinations?name=practise`
              );
            }

            // history.push(
            //   `/my-subjects/subject/${state.subject.value.id}/Examinations?name=practise`
            // );
          })
          .catch((err: any) => {
            console.log(err);
            setState((ps) => {
              return { ...ps, isLoading: { ...ps.isLoading, value: false } };
            });
          });
      }
    }
  };

  return (
    <>
      <Row className=" mt-1 pb-3 section-container">
        <Col>
          <Row>
            <Col className="section-card mt-5">
              <Row>
                <Col md={3}>
                  <DatePickerWithIcon
                    name="Start Date"
                    stateName="startDate"
                    stateValue={state.startDate.value}
                    state={state}
                    setState={setState}
                    error={state.startDate.error}
                    minDate={new Date()}
                  />
                </Col>
                <Col md={2}>
                  <TimeInputComp
                    dateTime
                    layoutStyles={{ marginTop: -4 }}
                    stateName="startTime"
                    text="Start Time"
                    state={state}
                    setState={setState}
                    style={{}}
                    error={state.startTime.error}
                    triggerOnChange={triggerOnChange}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={3}>
                  <DatePickerWithIcon
                    name="End Date"
                    stateName="endDate"
                    stateValue={state.endDate.value}
                    state={state}
                    setState={setState}
                    error={state.endDate.error}
                    minDate={state.startDate.value}
                  />
                </Col>
                <Col md={2}>
                  <TimeInputComp
                    dateTime
                    layoutStyles={{ marginTop: -4 }}
                    stateName="endTime"
                    text="End Time"
                    state={state}
                    setState={setState}
                    style={{}}
                    error={state.endTime.error}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="section-card mt-4">
              <Row>
                <Col>
                  <span className="section-title">Submission type</span>
                </Col>
              </Row>
              <Row className="text-white mt-3">
                <Col className="col-auto ">
                  <FilterCheckBox
                    onClick={() => {}}
                    item={{
                      label: 'MCQ',
                      name: PaperTypes.MCQ,
                    }}
                    active={
                      !!props.state.papers.value.find((val) => val.paperType === PaperTypes.MCQ)
                    }
                  />
                </Col>
                <Col className="col-auto ">
                  <FilterCheckBox
                    onClick={() => {}}
                    item={{
                      label: 'True/False',
                      name: PaperTypes.TRUEFALSE,
                    }}
                    active={
                      !!props.state.papers.value.find(
                        (val) => val.paperType === PaperTypes.TRUEFALSE
                      )
                    }
                  />
                </Col>
                <Col className="col-auto">
                  <FilterCheckBox
                    onClick={() => {}}
                    item={{
                      label: 'Structured',
                      name: PaperTypes.STRUCTURED,
                    }}
                    active={
                      !!props.state.papers.value.find(
                        (val) => val.paperType === PaperTypes.STRUCTURED
                      )
                    }
                  />
                </Col>

                <Col className="col-auto">
                  <FilterCheckBox
                    onClick={() => {}}
                    item={{
                      label: 'Essay',
                      name: PaperTypes.ESSAY,
                    }}
                    active={
                      !!props.state.papers.value.find((val) => val.paperType === PaperTypes.ESSAY)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="section-card mt-4">
              <Row>
                <Col>
                  <DropDown
                    name="Grade Type"
                    noValueText="Select Grade type"
                    stateName="gradingType"
                    stateValue={state.gradingType.value}
                    state={state}
                    setState={setState}
                    optionsArray={GRADE_TYPES}
                    error={state.gradingType.error}
                  />
                </Col>
                <Col>
                  <NumberInput
                    unitType="Rs."
                    placeHolder="price"
                    stateName="price"
                    stateValue={parseInt(state.price.value) === 0 ? '' : state.price.value}
                    state={state}
                    setState={setState}
                    error={state.price.error}
                  />
                </Col>
                <Col>
                  <NumberInput
                    placeHolder="Full Marks"
                    stateName="overallMark"
                    stateValue={state.overallMark.value}
                    state={state}
                    setState={setState}
                    error={state.overallMark.error}
                    readonly
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="position-absolute bottom-0 button-container">
        <Col className="col-auto pl-1 pr-1">
          <ButtonComp
            text={props.update ? 'Update and publish' : 'Save and publish'}
            onClick={() => {
              onSave(ExamStatus.PUBLISHED);
            }}
            type={'one'}
          />
        </Col>
        <Col className="col-auto pl-1 pr-1">
          <ButtonComp
            text={props.update ? 'Update as draft' : 'Save as draft'}
            onClick={() => {
              onSave(ExamStatus.DRAFT);
            }}
            type={'one'}
          />
        </Col>
        <Col className="col-auto pl-1 pr-1">
          <ButtonComp
            text="Back"
            onClick={props.onBack}
            type={'one'}
            style={{ background: 'transparent', gap: '20px' }}
          />
        </Col>
      </Row>
      <AlertPopup
        message={warningState?.message || ''}
        header="Attention"
        isShow={!!warningState}
        onClose={() => {
          setWarningState(null);
        }}
        onOk={() => {
          onSave(warningState?.examStatus || ExamStatus.PUBLISHED, true);
          setWarningState(null);
        }}
      />
      <AlertPopup
        message={errorState}
        header="Attention"
        isShow={!!errorState}
        onClose={() => {
          setErrorState('');
        }}
        type="NO_BUTTON"
      />
    </>
  );
};

export default ExamCreationStepThreePractise;
