import React, { FC, useState } from 'react';
import { useFunctions } from 'reactfire';
import renderHTML from 'react-render-html';
import './ExamSettings.scss';
import { FaFileAlt } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { Exam } from '../models/Exam';
import { Paper } from '../models/Paper';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
import { useLessons } from '../context/LessonsContext';
import Notification from './../components/Notification';
import LoadingIndicator from './LoadingIndicator';
import { ExamType } from '../enums/ExamType';
import StudentExamPreviewPaperCard from './StudentExamPreviewPaperCard';
import FilterCheckboxLeft from './FilterCheckboxLeft';
import PaperPreviewModel from './PaperPreviewModel';
import moment from 'moment';
import { PaperTypes } from '../enums/paperTypes';
import { useUnits } from '../context/UnitsContext';
import AlertPopup from './AlertPopup';
import { StructEssayDataPaperStatus } from '../dtos/StructEssayData';

interface Props {
  selectedExam: Exam | null;
  setUpdatedPaper: (papers: Paper[]) => void;
}

const ExamSettings: FC<Props> = (props) => {
  const [isShowPaperInfo, setIsShowPaperInfo] = useState(false);
  const [selectedPaper, setSelectedPaper] = useState<Paper | undefined>(undefined);
  const [isEditingPayment, setIsEditingPayment] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const appUsers = useAdminsAndInstructors();
  const appLessons = useLessons();
  const appUnits = useUnits();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const saveExamPaymentRef = useFunctions().httpsCallable('saveExamPayment');
  const onClickPaper = (paper: Paper) => {
    setSelectedPaper(paper);
    setIsShowPaperInfo(true);
  };

  const updatePaperMarkingAmountHandler = (pId: string, value: string) => {
    const examPapers = props.selectedExam?.papers && [...props.selectedExam?.papers];
    const index = examPapers?.map((p) => p.id).indexOf(pId);
    const paperToUpdate = examPapers?.find((p) => p.id === pId);

    if (
      paperToUpdate &&
      paperToUpdate?.taPayment &&
      index !== undefined &&
      examPapers &&
      !isNaN(+value)
    ) {
      paperToUpdate.taPayment.amount = +value;
      paperToUpdate.taPayment.isFree = +value === 0 ? true : false;
      examPapers[index] = paperToUpdate;
      props.setUpdatedPaper(examPapers);
    }
  };

  const updatePaperMarkingIsFreeHandler = (pId: string, isFree: boolean) => {
    const examPapers = props.selectedExam?.papers && [...props.selectedExam?.papers];
    const index = examPapers?.map((p) => p.id).indexOf(pId);
    const paperToUpdate = examPapers?.find((p) => p.id === pId);

    if (paperToUpdate && paperToUpdate?.taPayment && index !== undefined && examPapers) {
      paperToUpdate.taPayment.isFree = !!isFree;
      paperToUpdate.taPayment.amount = !!isFree ? 0 : paperToUpdate.taPayment.amount || 0;

      examPapers[index] = paperToUpdate;
      props.setUpdatedPaper(examPapers);
    }
  };

  //LEGACY DATA SUPPORT
  // const getTimeUnit = (time: string) => {
  //   const timeArr = time.split(':');
  //   const hours = parseInt(timeArr[0]);
  //   const minutes = parseInt(timeArr[1]);
  //   const ampm = hours >= 12 ? 'PM' : 'AM';
  //   return `${('0' + (hours % 12).toString()).slice(-2)}:${('0' + minutes.toString()).slice(
  //     -2
  //   )} ${ampm}`;
  // };

  const getMaterialName = (name: string) => {
    const nameArr = name.split('.');
    const extension = nameArr.pop();
    const fileName = nameArr.join('');
    if (fileName.length > 6) {
      return `${fileName.substr(0, 6)}...${extension}`;
    }
    return `${fileName}${extension}`;
  };

  const onSavePayment = () => {
    const payArray = props.selectedExam?.papers
      .filter((p) => p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED)
      .map((p) => ({
        paperId: p.id,
        payment: p.taPayment?.amount || 0,
        isFree: !!p.taPayment?.isFree,
      }));

    const isError = payArray?.find((p) => {
      if (!p?.payment && !p?.isFree) {
        return true;
      } else {
        return false;
      }
    });

    if (!!isError) {
      return setError(
        'Please provide an amount which is not zero. If you are attempting to get the paper marked free of charge, please check the checkbox with the label "Free"'
      );
    }

    if (props.selectedExam?.id) {
      const paymentDetailsDto = {
        examId: props.selectedExam.id,
        paperPaymentDetails: payArray,
      };
      setIsLoading(true);
      saveExamPaymentRef(paymentDetailsDto)
        .then((res) => {
          setIsLoading(false);
          setIsEditingPayment(false);
          props.setUpdatedPaper(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          Notification({
            isSuccess: false,
            id: 'Payment-Adding-success',
            message: 'Error Adding Payments to Papers',
          });
        });
      // console.log(paymentDetailsDto);
    }
  };
  const getIsAssigned = (paperId: string): boolean => {
    if (props.selectedExam?.studentTAAssignmentInfo) {
      return props.selectedExam?.studentTAAssignmentInfo.some((pa) => {
        return pa.papers.some((p) => {
          return p.paperId === paperId && p.assignedTAUid?.taUid;
        });
      });
    }
    return false;
  };
  const getIsAllAssigned = (): boolean => {
    if (props.selectedExam?.studentTAAssignmentInfo) {
      return props.selectedExam?.studentTAAssignmentInfo.every((pa) => {
        return pa.papers.every((p) => {
          return p.assignedTAUid?.taUid;
        });
      });
    }
    return false;
  };
  return (
    <>
      {paymentLoading && <LoadingIndicator />}
      <div
        className="stu-exam-prev"
        style={paymentLoading ? { position: 'relative', zIndex: '10000' } : {}}
      >
        {/*{*/}
        {/*  props.selectedExam?.papers && props.selectedExam.id &&*/}
        {/*  <AddPaymentDetailModel examId={props.selectedExam.id} isOpen={isOpenPaymentModel}*/}
        {/*                         onClose={setIsOpenPaymentModel} Papers={props.selectedExam?.papers} />*/}
        {/*}*/}
        {isLoading && <LoadingIndicator />}
        <div className="stu-exam-prev__marks-author">
          <div className="stu-exam-prev__marks">
            <div className="stu-exam-prev__marks-title">
              {props.selectedExam?.lessonIds && props.selectedExam?.lessonIds?.length > 1
                ? 'Lessons'
                : 'Lesson'}
            </div>
            <div className="stu-exam-prev__marks-value">
              {props.selectedExam?.lessonIds?.length
                ? props.selectedExam?.lessonIds?.map(
                    (l, idx) =>
                      appLessons.lessons.find((les) => les.id === l)?.name +
                      (props.selectedExam?.lessonIds?.length === idx + 1 ? '' : ', ')
                  )
                : '-'}
            </div>
          </div>
          <div className="stu-exam-prev__author">
            <div className="stu-exam-prev__author-title">
              {props.selectedExam?.unitIds && props.selectedExam?.unitIds?.length > 1
                ? 'Units'
                : 'Unit'}
            </div>
            <div className="stu-exam-prev__author-value">
              {props.selectedExam?.unitIds?.length
                ? props.selectedExam?.unitIds?.map(
                    (l, idx) =>
                      appUnits.units.find((unit) => unit.id === l)?.name +
                      (props.selectedExam?.unitIds?.length === idx + 1 ? '' : ', ')
                  )
                : '-'}
            </div>
          </div>
          <div className="stu-exam-prev__author">
            <div className="stu-exam-prev__author-title">Author</div>
            <div className="stu-exam-prev__author-value">
              {
                appUsers.allAdminsAndInstructors.find(
                  (val) => val.uid === props.selectedExam?.createdBy
                )?.username
              }
            </div>
          </div>
        </div>
        <div
          className="stu-exam-prev__title"
          style={{
            marginTop: '10px',
          }}
        >
          <div className="stu-exam-prev__title-text">Exam Title</div>
          <div className="stu-exam-prev__title-value">{props.selectedExam?.examTitle}</div>
        </div>

        <div className="stu-exam-prev__description">
          <div className="stu-exam-prev__description-text">Description</div>
          <div className="stu-exam-prev__description-value">
            {renderHTML(props.selectedExam?.description || '')}
          </div>
        </div>

        {props.selectedExam?.materials && props.selectedExam?.materials.length > 0 && (
          <>
            <div className="stu-exam-prev__papers-title mt-3">Additional Materials</div>
            <div className="d-flex">
              {props.selectedExam?.materials.map((mat, index) => (
                <div
                  className="col-auto mt-3 mb-3"
                  key={index}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.open(mat.url, '_blank');
                  }}
                >
                  <div className="d-inline-block material-container">
                    <FaFileAlt
                      style={{
                        color: '#474A66',
                      }}
                    />
                  </div>
                  <div className="text-center">
                    <span className="material-name">{getMaterialName(mat.name)}</span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {props.selectedExam?.examType !== ExamType.EXTRA_EXAM && (
          <div className="stu-exam-prev__time">
            <div className="stu-exam-prev__time-allocated-time">
              <div className="stu-exam-prev__time-allocated-time-title">Allocated Time</div>
              <div className="stu-exam-prev__time-allocated-time-value">
                {props.selectedExam?.examDuration}
              </div>
            </div>
            <div className="stu-exam-prev__time-start-date">
              <div className="stu-exam-prev__time-start-date-title">Start Date</div>
              <div className="stu-exam-prev__time-start-date-value">
                {moment(props.selectedExam?.beginTime).format('L')}
              </div>
            </div>
            <div className="stu-exam-prev__time-start-time">
              <div className="stu-exam-prev__time-start-time-title">Start Time</div>
              <div className="stu-exam-prev__time-start-time-value">
                {moment(props.selectedExam?.beginTime).format('LT')}
              </div>
            </div>
            <div className="stu-exam-prev__time-end-date">
              <div className="stu-exam-prev__time-end-date-title">End Date</div>
              <div className="stu-exam-prev__time-end-date-value">
                {moment(props.selectedExam?.finishTime).format('L')}
              </div>
            </div>
            <div className="stu-exam-prev__time-end-time">
              <div className="stu-exam-prev__time-end-time-title">End Time</div>
              <div className="stu-exam-prev__time-end-time-value">
                {moment(props.selectedExam?.finishTime).format('LT')}
              </div>
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center">
          <div className="stu-exam-prev__papers-title">Papers in Exam</div>
          {!getIsAllAssigned() &&
            (isEditingPayment ? (
              <div className={'mt-2'}>
                <Button
                  onClick={() => {
                    setShow(true);
                    onSavePayment();
                    setPaymentLoading(false);
                  }}
                  style={{
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Save
                  </span>
                </Button>
              </div>
            ) : props.selectedExam?.papers.some(
                (p) => p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED
              ) &&
              props.selectedExam?.examDetailsForTA &&
              props.selectedExam?.examDetailsForTA.every(
                (details) => details.status === StructEssayDataPaperStatus.NOT_MARKED
              ) ? (
              <div
                className="stu-exam-prev__papers-add-payment mt-2"
                onClick={() => {
                  setIsEditingPayment(true);
                  setPaymentLoading(true);
                }}
              >
                Assign Payment
              </div>
            ) : props.selectedExam?.papers.some(
                (p) => p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED
              ) && !props.selectedExam?.examDetailsForTA ? (
              <div
                className="stu-exam-prev__papers-add-payment mt-2"
                onClick={() => {
                  setIsEditingPayment(true);
                  setPaymentLoading(true);
                }}
              >
                Assign Payment
              </div>
            ) : (
              <></>
            ))}
        </div>

        <div className="stu-exam-prev__papers">
          {props.selectedExam?.papers.map((p, idx) => {
            return (
              <StudentExamPreviewPaperCard
                paper={p}
                paperTitle={p.paperTitle}
                onClick={onClickPaper}
                key={idx}
                isEditingPayment={isEditingPayment && !getIsAssigned(p.id || '')}
                updatePaperMarkingAmountHandler={updatePaperMarkingAmountHandler}
                updatePaperMarkingIsFreeHandler={updatePaperMarkingIsFreeHandler}
              />
            );
          })}
        </div>
        <div className="stu-exam-prev__sub-info">
          <div className="stu-exam-prev__sub-info-upfiles">
            <div className="stu-exam-prev__sub-info-upfiles-title">
              Max. Number of Uploaded Files
            </div>
            <div className="stu-exam-prev__sub-info-upfiles-value">
              {props.selectedExam?.maxFiles ? props.selectedExam?.maxFiles : '0'}
            </div>
          </div>
          <div className="stu-exam-prev__sub-info-subfiles">
            <div className="stu-exam-prev__sub-info-subfiles-title">Maximum Submission Size</div>
            <div className="stu-exam-prev__sub-info-subfiles-value">
              {props.selectedExam?.maxSize}
            </div>
          </div>
          <div className="stu-exam-prev__sub-info-filetype">
            <div className="stu-exam-prev__sub-info-filetype-title">Accepted File Type</div>
            <div className="stu-exam-prev__sub-info-filetype-value">
              {props.selectedExam?.fileTypes && props.selectedExam?.fileTypes.length > 0
                ? props.selectedExam?.fileTypes.join(' ')
                : '-'}
            </div>
          </div>
        </div>
        <div className="stu-exam-prev__other-info">
          <div className="stu-exam-prev__other-info-top">
            <div className="stu-exam-prev__other-info-gradetype">
              <div className="stu-exam-prev__other-info-gradetype-title">Grade Type</div>
              <div className="stu-exam-prev__other-info-gradetype-value">
                {props.selectedExam?.gradingType}
              </div>
            </div>
            <div className="stu-exam-prev__other-info-feedback">
              <div className="stu-exam-prev__other-info-feedback-title">Feedback Type</div>
              <div className="stu-exam-prev__other-info-feedback-value">
                {props.selectedExam?.feedbackType}
              </div>
            </div>
            <div className="stu-exam-prev__other-info-price">
              <div className="stu-exam-prev__other-info-price-title">Price</div>
              <div className="stu-exam-prev__other-info-price-value">
                Rs. {props.selectedExam?.price}
              </div>
            </div>
          </div>

          <div className="stu-exam-prev__other-info-bottom">
            <div className="stu-exam-prev__other-info-bottom-fullmarks">
              <div className="stu-exam-prev__other-info-bottom-fullmarks-title">Total Marks</div>
              <div className="stu-exam-prev__other-info-bottom-fullmarks-value">
                {props.selectedExam?.overallMark}
              </div>
            </div>
            <div className="stu-exam-prev__other-info-bottom-notifications">
              <div className="stu-exam-prev__other-info-bottom-fullmarks-title">Notifications</div>
              <div className="stu-exam-prev__other-info-bottom-fullmarks-content">
                <div className="stu-exam-prev__other-info-bottom-fullmarks-content-one">
                  <FilterCheckboxLeft
                    onClick={() => {}}
                    item={{
                      label: 'Notify graders about late submissions',
                      name: 'Notify graders about late submissions',
                    }}
                    isChecked={props.selectedExam?.notifyLateSubmission}
                  />
                </div>
                <div className="stu-exam-prev__other-info-bottom-fullmarks-content-two">
                  <FilterCheckboxLeft
                    onClick={() => {}}
                    item={{
                      label: 'Notify graders about submissions',
                      name: 'Notify graders about submissions',
                    }}
                    isChecked={props.selectedExam?.notifyGraders}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {selectedPaper && (
          <PaperPreviewModel
            paper={selectedPaper}
            isShow={isShowPaperInfo}
            setIsShow={setIsShowPaperInfo}
          />
        )}
        <AlertPopup
          message={
            error
              ? error
              : show
              ? `Once you have assigned Teaching assistants to students' papers, it will not be possible to modify the payment details`
              : ''
          }
          header="Attention"
          isShow={!!error || show}
          onOk={() => {
            setError('');
          }}
          onClose={() => {
            if (show) {
              setShow(false);
              Notification({
                isSuccess: true,
                id: 'Payment-Adding-success',
                message: 'Successfully Added Payments to Papers',
              });
            }
            setError('');
          }}
          type="OK"
        />
      </div>
    </>
  );
};

export default ExamSettings;
