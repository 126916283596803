import React, {FC, useEffect, useState} from 'react';
import * as FaIcons from 'react-icons/fa';
import {FaCaretLeft, FaCaretRight, FaCheckCircle, FaExclamationTriangle} from 'react-icons/fa';
import {useFunctions, useStorage} from 'reactfire';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import {useAppUser} from '../context/UserContext';
import {ExamToDoDTO} from '../dtos/ExamToDoDTO';
import {PaperDTO} from '../dtos/PaperDTO';
import {PaperTypes} from '../enums/paperTypes';
import {PaperInfoData, PaperStats} from '../interfaces';
import {StudentTAAssignmentInfo} from './ExamTAAssignmentChart';
import Notification from './../components/Notification';
import {StructEssayDataPaperStatus} from '../dtos/StructEssayData';
import truncate from 'truncate';
import {getStatusOfPaper} from '../utils';
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import AlertPopup from './AlertPopup';
import BoxIcon from './BoxIcon';
import QuestionResult from './QuestionResult';
import LoadingIndicator from './LoadingIndicator';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import UploadedPaper from './UploadedPaper';
import RejectFeedbackModal from './RejectFeedbackModal';
import {StatusOfPaper} from '../enums';
import './ModelExamGradingPreview.scss';
import {IconType} from 'react-icons';

interface Props {
  examId: string;
  subjectName: string;
  paperInfoData: PaperInfoData;
  completedExams?: ExamToDoDTO[];
  studentTAAssignmentInfo?: StudentTAAssignmentInfo[];
  paperStats: PaperStats;
  reload?: boolean;
  setOpenModal: (value: React.SetStateAction<boolean>) => void;
  setCompletedExams: (exams: ExamToDoDTO[]) => void;
  setPaperStatsHandler: (value: PaperStats) => void;
  setPaperInfoData: (value: PaperInfoData | null) => void;
  reloadHandler?: () => void;
}

const ModelExamGradingPreview: FC<Props> = (props) => {
  const appUsers = useAdminsAndInstructors();
  const appUser = useAppUser();

  const [state, setState] = useState<State>(() => {
    return {
      file: null,
      error: '',
      progress: 0,
      index: 0,
      loading: false,
      selectedPaper: undefined,
      selectedExam: null,
    };
  });

  const [isShowConfirm, setIsShowConfirm] = useState(false);

  useEffect(() => {
    let idx = 0;

    const selectedExam = props.completedExams
      ? props.completedExams.find((e, i) => {
          if (e.examId === props.paperInfoData.examId && e.userId === props.paperInfoData.uid) {
            idx = i;
            return true;
          } else {
            return false;
          }
        })
      : null;

    let paper = selectedExam?.papers.find((p) => p.id === props.paperInfoData.paperId);

    setState((ps) => ({
      ...ps,
      file: null,
      error: '',
      progress: 0,
      loading: false,
      index: idx,
      selectedPaper: paper,
      selectedExam: selectedExam,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paperInfoData.paperId, props.reload]);

  useEffect(() => {
    let allQuestions: any[] = [];

    if (
      state.selectedPaper?.paperType === PaperTypes.MCQ ||
      state.selectedPaper?.paperType === PaperTypes.TRUEFALSE
    ) {
      state.selectedPaper?.questions?.forEach((q) => {
        allQuestions.push(q);
      });

      setState((ps) => ({
        ...ps,
        selectedPaper: state.selectedPaper,
      }));
    }

    setAllQuestionsState(allQuestions);
  }, [state.selectedPaper]);

  const moveForwardHandler = () => {
    const newVal = state.index + 1;

    let selectedExam: ExamToDoDTO | null = null;

    if (props.completedExams?.length && newVal <= props.completedExams?.length - 1) {
      selectedExam = props.completedExams ? props.completedExams[newVal] : null;
      props.setPaperInfoData({
        ...props.paperInfoData,
        index: newVal,
        uid: selectedExam?.userId || '',
      });
      setState((ps) => ({...ps, index: newVal}));
    } else {
      selectedExam = props.completedExams ? props.completedExams[0] : null;
      props.setPaperInfoData({...props.paperInfoData, index: 0, uid: selectedExam?.userId || ''});
      setState((ps) => ({...ps, index: 0}));
    }

    let paper = selectedExam?.papers.find((p) => p.id === props.paperInfoData.paperId);

    setState((ps) => ({
      ...ps,
      file: null,
      error: '',
      progress: 0,
      loading: false,
      selectedPaper: paper,
      selectedExam: selectedExam,
    }));
  };

  const moveBackwardHandler = () => {
    const newVal = state.index - 1;
    let selectedExam: ExamToDoDTO | null = null;
    if (props.completedExams?.length && newVal >= 0) {
      selectedExam = props.completedExams ? props.completedExams[newVal] : null;
      props.setPaperInfoData({
        ...props.paperInfoData,
        index: newVal,
        uid: selectedExam?.userId || '',
      });
      setState((ps) => ({...ps, index: newVal}));
    } else {
      selectedExam = props.completedExams
        ? props.completedExams[props.completedExams.length - 1]
        : null;
      props.setPaperInfoData({
        ...props.paperInfoData,
        index: props.completedExams ? props.completedExams.length - 1 : 0,
        uid: selectedExam?.userId || '',
      });
      setState((ps) => ({
        ...ps,
        index: props.completedExams ? props.completedExams.length - 1 : 0,
      }));
    }

    let paper = selectedExam?.papers.find((p) => p.id === props.paperInfoData.paperId);

    setState((ps) => ({
      ...ps,
      file: null,
      error: '',
      progress: 0,
      loading: false,
      selectedPaper: paper,
      selectedExam: selectedExam,
    }));
  };

  const [allQuestionState, setAllQuestionsState] = useState<any[]>([]);

  const gradingType = state.selectedExam?.gradingType;
  const displayScore =
    gradingType === 'Percentage'
      ? state.selectedPaper?.score?.toFixed(0) + '%'
      : `${state.selectedPaper?.paperPoints} / ${state.selectedPaper?.marks}`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}: any) {
    setNumPages(numPages);
  }

  const incrementPageNo = (e: any) => {
    if (!(numPages === pageNumber)) {
      setPageNumber(pageNumber + 1);
    }
    e.stopPropagation();
  };

  const decrementPageNo = (e: any) => {
    if (!(pageNumber === 1)) {
      setPageNumber(pageNumber - 1);
    }
    e.stopPropagation();
  };

  const types = ['application/pdf'];
  const storage = useStorage();

  interface State {
    file: File | null;
    error: string;
    progress: number;
    loading: boolean;
    index: number;
    selectedPaper: PaperDTO | undefined;
    selectedExam: ExamToDoDTO | null | undefined;
  }

  const pointsChangeHandler = (points: string) => {
    let existingSelectedPaper = {
      ...state.selectedPaper,
      paperPoints: +points,
    } as PaperDTO;

    if (isNaN(+points)) {
      return;
    }

    if (+points === 0 && state.selectedPaper?.paperPoints === 0) {
      existingSelectedPaper = {
        ...state.selectedPaper,
        paperPoints: 0,
      } as PaperDTO;
    }

    if (state?.selectedPaper?.marks && +points > state?.selectedPaper?.marks) {
      return;
    }

    setState((ps) => ({
      ...ps,
      selectedPaper: existingSelectedPaper,
    }));
  };

  const feedBackChangeHandler = (feedback: string) => {
    const existingSelectedPaper = {
      ...state.selectedPaper,
      feedback: feedback,
    } as PaperDTO;

    setState((ps) => ({
      ...ps,
      selectedPaper: existingSelectedPaper,
    }));
  };

  const resetSavedMarkedAnswerSheetRef = useFunctions().httpsCallable('resetSaveMarkedAnswerSheet');

  const saveMarkedAnswerSheetRef = useFunctions().httpsCallable('saveMarkedAnswerSheet');

  const saveByAdminInsRef = useFunctions().httpsCallable('saveByAdminIns');

  const markedByTARef = useFunctions().httpsCallable('markedByTA');

  const approveDisapproveByAdminInsRef = useFunctions().httpsCallable(
    'approveDisapproveByAdminIns'
  );

  const onAnnotatedPdfUpload = (e: any) => {
    let selected = e.target.files[0] as File;

    if (selected && types.includes(selected.type)) {
      const size = selected.size / 1024 / 1024;
      if (size > 1) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
        return;
      }

      const storageRef = storage.ref().child(`AnnotedAnswerSheet/${+new Date()}_${selected?.name}`);

      let annotedPdfSheetUrl: any = null;

      setState((ps) => ({...ps, loading: true}));

      storageRef.put(selected).on(
        'state_changed',
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;

          setState((ps) => ({...ps, progress: percentage}));
        },
        () => {
          Notification({
            isSuccess: false,
            message: 'Failed to upload PDF. ',
          });

          setState((ps) => ({
            ...ps,
            file: null,
            error: 'Failed to upload PDF',
            progress: 0,
            loading: false,
          }));
        },
        async () => {
          annotedPdfSheetUrl = await storageRef.getDownloadURL();

          const existingStructEssayData = state.selectedPaper?.structEssayData;

          const existingSelectedPaper = {
            ...state.selectedPaper,
            structEssayData: {
              ...existingStructEssayData,
              markedAnswerSheetName: selected.name,
              markedAnswerSheetUrl: annotedPdfSheetUrl,
            },
            updatedAt: state.selectedPaper?.updatedAt,
          } as PaperDTO;

          setState((ps) => ({
            ...ps,
            file: selected,
            error: '',
            loading: false,
            selectedPaper: existingSelectedPaper,
          }));

          Notification({
            isSuccess: true,
            message: 'Marked paper uploaded successfully',
          });
        }
      );
    } else {
      // const existingSelectedPaper = {...state.selectedPaper,markedAnswerSheetName:selected.name, markedAnswerSheetUrl:annotedPdfSheetUrl} as PaperDTO;
      if (types.includes(selected.type)) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
      } else {
        Notification({
          isSuccess: false,
          message: 'Only PDF files are accepted for upload',
        });
      }

      setState((ps) => ({
        ...ps,
        file: null,
        error: 'Please select an PDF file',
      }));
    }
  };

  const [show, setShow] = useState(false);

  const submitPaper = (released: boolean, isUnreleasing?: boolean) => {
    if (!state.selectedPaper?.structEssayData?.answerSheetUrl) {
      return;
    }

    if (!state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) {
      return setShow(true);
    }

    if (state.loading) {
      return;
    }

    setState((ps) => ({
      ...ps,
      loading: true,
    }));

    return saveMarkedAnswerSheetRef({
      score: state.selectedPaper?.paperPoints || 0,
      feedback: state.selectedPaper?.feedback,
      examId: state.selectedExam?.id,
      paperId: state.selectedPaper?.id,
      markedAnswerSheetName: state.selectedPaper?.structEssayData?.markedAnswerSheetName,
      markedAnswerSheetUrl: state.selectedPaper?.structEssayData?.markedAnswerSheetUrl,
      released: released,
      studentId: props.paperInfoData.uid,
    })
      .then((data) => {
        const updatedExam = data.data.data as ExamToDoDTO;

        setState((ps) => ({
          ...ps,
          loading: false,
        }));

        Notification({
          isSuccess: true,
          message: `${
            released ? 'Released' : isUnreleasing ? 'Unreleased' : 'Saved'
          } marked paper successfully.`,
        });

        const comExams = [...(props.completedExams || [])];

        const idx = comExams?.findIndex((value) => value.id === updatedExam.id) || 0;

        if (comExams && idx !== undefined && idx !== null) {
          comExams[idx] = updatedExam;
          props.setCompletedExams([...comExams]);
        }

        // props.setTrigger(!props.trigger);
        props.reloadHandler && props.reloadHandler();
      })
      .catch(() => {
        setState((ps) => ({
          ...ps,
          loading: false,
        }));

        Notification({
          isSuccess: false,
          message: 'Failed to save marked paper.',
        });
      });
  };

  const submitTAMarkedPaper = () => {
    //guard

    if (!state.selectedPaper?.structEssayData?.answerSheetUrl) {
      return;
    }

    if (!state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) {
      return setShow(true);
    }

    if (state.loading) {
      return;
    }

    setState((ps) => ({
      ...ps,
      loading: true,
    }));

    return markedByTARef({
      score: state.selectedPaper?.paperPoints || 0,
      feedback: state.selectedPaper?.feedback,
      completedExamId: state.selectedExam?.id,
      examId: props.examId,
      paperId: state.selectedPaper?.id,
      markedAnswerSheetName: state.selectedPaper?.structEssayData?.markedAnswerSheetName,
      markedAnswerSheetUrl: state.selectedPaper?.structEssayData?.markedAnswerSheetUrl,
      studentId: props.paperInfoData.uid,
    })
      .then((data) => {
        const updatedExam = data.data.data as ExamToDoDTO;

        const existingStructEssayData = state.selectedPaper?.structEssayData;

        const existingSelectedPaper = {
          ...state.selectedPaper,
          structEssayData: {
            ...existingStructEssayData,
            status: StructEssayDataPaperStatus.PENDING,
          },
        } as PaperDTO;

        setState((ps) => ({
          ...ps,
          selectedPaper: existingSelectedPaper,
          loading: false,
        }));

        Notification({
          isSuccess: true,
          message: `Saved marked paper successfully.`,
        });

        const comExams = [...(props.completedExams || [])];

        const idx = comExams?.findIndex((value) => value.id === updatedExam.id) || 0;

        if (comExams && idx !== undefined && idx !== null) {
          comExams[idx] = updatedExam;

          props.setCompletedExams([...comExams]);
        }

        // props.setTrigger(!props.trigger);
        props.reloadHandler && props.reloadHandler();
      })
      .catch((err) => {
        setState((ps) => ({
          ...ps,
          loading: false,
        }));

        Notification({
          isSuccess: false,
          message: 'Failed to save marked paper.',
        });
      });
  };

  const submitSaveByAdminIns = (unrelease?: boolean) => {
    //guard

    if (!state.selectedPaper?.structEssayData?.answerSheetUrl) {
      return;
    }

    if (!state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) {
      return setShow(true);
    }

    if (state.loading) {
      return;
    }

    setState((ps) => ({
      ...ps,
      loading: true,
    }));

    return saveByAdminInsRef({
      score: state.selectedPaper?.paperPoints || 0,
      feedback: state.selectedPaper?.feedback,
      completedExamId: state.selectedExam?.id,
      examId: props.examId,
      paperId: state.selectedPaper?.id,
      markedAnswerSheetName: state.selectedPaper?.structEssayData?.markedAnswerSheetName,
      markedAnswerSheetUrl: state.selectedPaper?.structEssayData?.markedAnswerSheetUrl,
      studentId: props.paperInfoData.uid,
      assigned: props.paperInfoData.assigned,
    })
      .then((data) => {
        const updatedExam = data.data.data as ExamToDoDTO;

        setState((ps) => ({
          ...ps,
          loading: false,
        }));

        Notification({
          isSuccess: true,
          message: `${
            unrelease ? 'Paper unreleased successfully. ' : 'Saved marked paper successfully.'
          }`,
        });

        const comExams = [...(props.completedExams || [])];

        const idx = comExams?.findIndex((value) => value.id === updatedExam.id) || 0;

        if (comExams && idx !== undefined && idx !== null) {
          comExams[idx] = updatedExam;
          props.setCompletedExams([...comExams]);
        }

        // props.setTrigger(!props.trigger);
        props.reloadHandler && props.reloadHandler();
      })
      .catch((err) => {
        setState((ps) => ({
          ...ps,
          loading: false,
        }));

        Notification({
          isSuccess: false,
          message: 'Failed to save marked paper.',
        });
      });
  };

  const submitApproveDissaproveOfPaper = (status: StructEssayDataPaperStatus) => {
    //guard

    if (!state.selectedPaper?.structEssayData?.answerSheetUrl) {
      return;
    }

    if (!state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) {
      return setShow(true);
    }

    if (state.loading) {
      return;
    }

    setState((ps) => ({
      ...ps,
      loading: true,
    }));

    return approveDisapproveByAdminInsRef({
      score: state.selectedPaper?.paperPoints || 0,
      feedback: state.selectedPaper?.feedback,
      completedExamId: state.selectedExam?.id,
      examId: props.examId,
      paperId: state.selectedPaper?.id,
      markedAnswerSheetName: state.selectedPaper?.structEssayData?.markedAnswerSheetName,
      markedAnswerSheetUrl: state.selectedPaper?.structEssayData?.markedAnswerSheetUrl,
      studentId: props.paperInfoData.uid,
      assigned: props.paperInfoData.assigned,
      status: status,
      disapproveFeedback: modalState.feedback,
    })
      .then((data) => {
        const updatedExam = data.data.data as ExamToDoDTO;

        const existingStructEssayData = state.selectedPaper?.structEssayData;

        const existingSelectedPaper = {
          ...state.selectedPaper,
          structEssayData: {
            ...existingStructEssayData,
            disapproveFeedback: modalState.feedback,
            status: status,
          },
        } as PaperDTO;

        setState((ps) => ({
          ...ps,
          selectedPaper: existingSelectedPaper,
          loading: false,
        }));

        Notification({
          isSuccess: true,
          message:
            status === StructEssayDataPaperStatus.APPROVED
              ? 'Approved marked paper successfully.'
              : status === StructEssayDataPaperStatus.REJECTED
              ? 'Rejected marked paper successfully.'
              : '',
        });

        const comExams = [...(props.completedExams || [])];

        const idx = comExams?.findIndex((value) => value.id === updatedExam.id) || 0;

        if (comExams && idx !== undefined && idx !== null) {
          comExams[idx] = updatedExam;
          props.setCompletedExams([...comExams]);
        }
      })
      .catch((err) => {
        setState((ps) => ({
          ...ps,
          loading: false,
        }));

        Notification({
          isSuccess: false,
          message:
            status === StructEssayDataPaperStatus.APPROVED
              ? 'Failed to approve marked paper.'
              : status === StructEssayDataPaperStatus.REJECTED
              ? 'Failed to rejected marked paper.'
              : '',
        });
      });
  };

  const resetSavedAnswer = () => {
    setState((ps) => ({
      ...ps,
      loading: true,
    }));
    return resetSavedMarkedAnswerSheetRef({
      examId: state.selectedExam?.id,
      paperId: state.selectedPaper?.id,
      studentId: props.paperInfoData.uid,
    })
      .then((data) => {
        const updatedExam = data.data.data as ExamToDoDTO;

        setState((ps) => ({
          ...ps,
          loading: false,
          file: null,
        }));

        Notification({
          isSuccess: true,
          message: `Reset marked paper successfully.`,
        });

        const comExams = [...(props.completedExams || [])];

        const idx = comExams?.findIndex((value) => value.id === updatedExam.id) || 0;

        // console.log('COMPLETED EXAM ', comExams);
        // console.log('IDX ', idx);

        if (comExams && idx !== undefined && idx !== null) {
          comExams[idx] = updatedExam;
          props.setCompletedExams([...comExams]);
        }

        // props.setTrigger(!props.trigger);
        props.reloadHandler && props.reloadHandler();

        // console.log('UPDATED EXAM ', updatedExam);
      })
      .catch(() => {
        setState((ps) => ({
          ...ps,
          loading: false,
        }));

        Notification({
          isSuccess: false,
          message: 'Failed to Reset marked paper.',
        });
      });
  };

  const gradedBy =
    appUsers.allAdminsAndInstructors.find(
      (u) => u.uid === state.selectedPaper?.structEssayData?.gradedBy
    )?.username || '';

  const gradedAt = state.selectedPaper?.structEssayData?.gradedAt?.seconds
    ? new Date(state.selectedPaper?.structEssayData?.gradedAt?.seconds * 1000)
    : state.selectedPaper?.structEssayData?.gradedAt;

  const gradedTruncated = truncate(gradedBy, 30);

  const isGradeTypePercentage = state.selectedExam?.gradingType === 'Percentage';
  const role = appUser.firestoreUser?.userRole?.isTeachingAssistant ? 'TA' : 'ADMININS';

  interface PaperStatusInformation {
    icon: {
      iconVal: IconType;
      color: string;
    } | null;
    status: StatusOfPaper;
    score: string;
    notAssignedCount?: undefined;
  }

  const [paperStatusInformation, setPaperStatusInformation] =
    useState<PaperStatusInformation | null>(null);

  useEffect(() => {
    let onlyAdmin = undefined;

    const array = props.studentTAAssignmentInfo?.map((v, o) => {
      if (
        v.papers.find((p) => {
          return p.assignedTAUid?.taUid && p.paperId === state.selectedPaper?.id;
        })
      ) {
        return v.stuUid;
      } else {
        return '';
      }
    });

    if (array?.includes(state.selectedExam?.userId || '')) {
      onlyAdmin = 'ASSIGNED';
    }

    const paperStatusInformation = getStatusOfPaper(
      role,
      isGradeTypePercentage,
      state.selectedPaper,
      !!onlyAdmin,
      state.selectedPaper?.paperType === PaperTypes.MCQ ||
        state.selectedPaper?.paperType === PaperTypes.TRUEFALSE
    );

    setPaperStatusInformation(paperStatusInformation as PaperStatusInformation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedPaper]);

  const UnReleasePaper = () => {
    //guard

    if (!state.selectedPaper?.structEssayData?.answerSheetUrl) {
      return;
    }

    if (!state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) {
      return setShow(true);
    }

    if (state.loading) {
      return;
    }

    setState((ps) => ({
      ...ps,
      loading: true,
    }));

    // submitPaper(false, true);
    submitSaveByAdminIns(true);
    setIsShowConfirm(false);
  };

  const [modalState, setModalState] = useState({show: false, feedback: ''});

  const openModal = () => {
    setModalState((ps) => ({...ps, show: true}));
  };

  const closeModal = () => {
    setModalState((ps) => ({...ps, show: false, feedback: ''}));
  };

  const feedbackHandler = (value: string) => {
    setModalState((ps) => ({...ps, feedback: value}));
  };

  const getFooter = () => {
    const ST = paperStatusInformation?.status;

    if (role === 'TA') {
      return ST === StatusOfPaper.NOT_UPLOADED ? (
        <></>
      ) : ST === StatusOfPaper.APPROVED ? (
        <div className="model-exam-grading-preivew__footer--taapproved">
          <div className="approve">
            <FaCheckCircle />
            Paper Approved
          </div>
        </div>
      ) : ST === StatusOfPaper.REJECTED ? (
        <div className="model-exam-grading-preivew__footer--tarejected">
          <div className="d-flex">
            <div
              className={`save ${state.loading && 'btn-inactive'}`}
              onClick={() => {
                submitTAMarkedPaper();
              }}
            >
              Save
            </div>

            <div
              className={`reset ${state.loading && 'btn-inactive-text'}`}
              onClick={() => {
                if (state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) {
                  // removeAnnotedPdfFromStorage(
                  //   state.selectedPaper?.structEssayData?.markedAnswerSheetUrl,
                  //   selectedPaper?.id,
                  //   selectedExam?.id
                  // );

                  resetSavedAnswer();
                }
              }}
            >
              Reset
            </div>
          </div>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="verifiedId">
                {state.selectedPaper?.structEssayData?.disapproveFeedback}
              </Tooltip>
            }
          >
            <div className="rejected">
              {' '}
              <FaExclamationTriangle />
              Paper Rejected
            </div>
          </OverlayTrigger>
        </div>
      ) : ST === StatusOfPaper.PENDING ? (
        <div className="model-exam-grading-preivew__footer--tapending">
          <div className="pending">Pending Approval</div>
        </div>
      ) : (
        <div className="model-exam-grading-preivew__footer--notmarked">
          <div
            className={`save ${state.loading && 'btn-inactive'}`}
            onClick={() => {
              submitTAMarkedPaper();
            }}
          >
            Save
          </div>
          <div
            className={`reset ${state.loading && 'btn-inactive-text'}`}
            onClick={() => {
              if (state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) {
                // removeAnnotedPdfFromStorage(
                //   state.selectedPaper?.structEssayData?.markedAnswerSheetUrl,
                //   selectedPaper?.id,
                //   selectedExam?.id
                // );

                resetSavedAnswer();
              }
            }}
          >
            Reset
          </div>
        </div>
      );
    } else {
      return ST === StatusOfPaper.NOT_UPLOADED ? (
        <></>
      ) : ST === StatusOfPaper.RELEASED ? (
        <div className="model-exam-grading-preivew__footer--adinreleased">
          <div
            className={`unrelease ${state.loading && 'btn-inactive'}`}
            onClick={() => {
              setIsShowConfirm(true);
            }}
          >
            Undo/Unrelease
          </div>
          <div
            className={`reset ${state.loading && 'btn-inactive-text'}`}
            onClick={() => {
              if (state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) {
                // removeAnnotedPdfFromStorage(
                //   state.selectedPaper?.structEssayData?.markedAnswerSheetUrl,
                //   selectedPaper?.id,
                //   selectedExam?.id
                // );

                resetSavedAnswer();
              }
            }}
          >
            Reset
          </div>
        </div>
      ) : ST === StatusOfPaper.RELEASED_ASSIGNED ? (
        <div className="model-exam-grading-preivew__footer--adinreleasedassigned">
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              className={`unrelease ${state.loading && 'btn-inactive'}`}
              onClick={() => {
                setIsShowConfirm(true);
              }}
            >
              Undo/Unrelease
            </div>
          </div>
          <div>
            <button className="approve">
              <FaCheckCircle />
              Paper Approved
            </button>
          </div>
        </div>
      ) : ST === StatusOfPaper.APPROVED ? (
        <div className="model-exam-grading-preivew__footer--adinapproved">
          <button
            className={`release ${state.loading && 'btn-inactive'}`}
            onClick={() => {
              submitPaper(true);
            }}
          >
            Release
          </button>
          <button className="approve">
            <FaCheckCircle />
            Paper Approved
          </button>
        </div>
      ) : ST === StatusOfPaper.REJECTED ? (
        <div className="model-exam-grading-preivew__footer--adinrejected">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="verifiedId">
                {state.selectedPaper?.structEssayData?.disapproveFeedback}
              </Tooltip>
            }
          >
            <div className="rejected">
              <FaExclamationTriangle />
              Paper Rejected
            </div>
          </OverlayTrigger>
        </div>
      ) : ST === StatusOfPaper.PENDING ? (
        <div className="model-exam-grading-preivew__footer--adinpending">
          <button
            className="reject"
            onClick={() => {
              openModal();
            }}
          >
            <FaExclamationTriangle />
            Reject
          </button>
          <button
            className="approve"
            onClick={() => {
              submitApproveDissaproveOfPaper(StructEssayDataPaperStatus.APPROVED);
            }}
          >
            <FaCheckCircle />
            Approve
          </button>
        </div>
      ) : ST === StatusOfPaper.ASSIGNED ? (
        <div className="model-exam-grading-preivew__footer--adinassigned">
          <></>
        </div>
      ) : (
        <div className="model-exam-grading-preivew__footer--adinnotassigned">
          <div
            className={`save ${state.loading && 'btn-inactive'}`}
            onClick={() => {
              submitPaper(false);
            }}
          >
            Save
          </div>
          <div
            className={`release ${state.loading && 'btn-inactive'}`}
            onClick={() => {
              submitPaper(true);
            }}
          >
            Release
          </div>
          <div
            className={`reset ${state.loading && 'btn-inactive-text'}`}
            onClick={() => {
              if (state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) {
                // removeAnnotedPdfFromStorage(
                //   state.selectedPaper?.structEssayData?.markedAnswerSheetUrl,
                //   selectedPaper?.id,
                //   selectedExam?.id
                // );

                resetSavedAnswer();
              }
            }}
          >
            Reset
          </div>
        </div>
      );
    }
  };

  const disabled =
    !state.selectedPaper?.structEssayData?.answerSheetUrl ||
    (!appUser.firestoreUser?.userRole?.isTeachingAssistant &&
      paperStatusInformation?.status === StatusOfPaper.ASSIGNED) ||
    paperStatusInformation?.status === StatusOfPaper.PENDING ||
    paperStatusInformation?.status === StatusOfPaper.APPROVED ||
    paperStatusInformation?.status === StatusOfPaper.REJECTED ||
    paperStatusInformation?.status === StatusOfPaper.RELEASED_ASSIGNED ||
    paperStatusInformation?.status === StatusOfPaper.RELEASED;

  return (
    <div className="ModelExamGradingPreview-Container">
      <AlertPopup
        message="Are you sure you want to unreleased this paper?"
        header="Confirm"
        isShow={isShowConfirm}
        primaryButtonText="Yes"
        isConformation={true}
        onOk={UnReleasePaper}
        onClose={() => {
          setIsShowConfirm(false);
        }}
      />
      <div className="ModelExamGradingPreview-Header">
        <div className="ModelExamGradingPreview-Header-Title">
          {props.paperInfoData.paperTitle}
          <div className="ModelExamGradingPreview-Header-Title-Text">{props.subjectName}</div>
        </div>
        <div className="ModelExamGradingPreview-StudentName-Section">
          <div className="ModelExamGradingPreview-StudentName-Section-Previous-Icon">
            <FaCaretLeft
              onClick={() => {
                moveBackwardHandler();
              }}
            />
          </div>
          <div className="ModelExamGradingPreview-StudentName-Section-Title">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="verifiedId">
                  {
                    appUsers.allAdminsAndInstructors.find(
                      (u) => u.uid === state.selectedExam?.userId
                    )?.username
                  }
                </Tooltip>
              }
            >
              <div>
                {truncate(
                  appUsers.allAdminsAndInstructors.find((u) => u.uid === state.selectedExam?.userId)
                    ?.username || '',
                  20
                )}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="verifiedId">
                  {appUsers.allAdminsAndInstructors.find(
                    (u) => u.uid === state.selectedExam?.userId
                  )?.email || ''}
                </Tooltip>
              }
            >
              <span>
                {appUsers.allAdminsAndInstructors.find((u) => u.uid === state.selectedExam?.userId)
                  ?.email || ''}
              </span>
            </OverlayTrigger>
          </div>
          <div className="ModelExamGradingPreview-StudentName-Section-Next-Icon">
            <FaCaretRight
              onClick={() => {
                moveForwardHandler();
              }}
            />
          </div>
        </div>
        <div>
          {state.selectedPaper?.paperType === PaperTypes.MCQ ||
          state.selectedPaper?.paperType === PaperTypes.TRUEFALSE ? (
            <div className="Students-Grade-preview-container">
              <span style={{marginRight: '5px', marginLeft: '20px'}}>{displayScore}</span>
              <span style={{color: 'blue', marginRight: '20px', marginLeft: '5px'}}>Grade</span>
            </div>
          ) : gradedBy && gradedAt ? (
            <div style={{fontSize: 14, color: '#73847F'}}>
              <div style={{maxWidth: 250, overflow: 'hidden'}}>
                <span style={{fontWeight: 500}}>Graded by</span>{' '}
                <span style={{fontWeight: 300}}>{gradedTruncated}</span>
                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  overlay={<Tooltip id="verifiedId">{gradedBy}</Tooltip>}*/}
                {/*>*/}
                {/*  <div*/}
                {/*    style={{*/}
                {/*      fontWeight: 400,*/}
                {/*      letterSpacing: '1px',*/}
                {/*      maxWidth: '300px',*/}
                {/*      overflowWrap: 'break-word',*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    {gradedTruncated}*/}
                {/*  </div>*/}
                {/*</OverlayTrigger>*/}
              </div>
              <div style={{maxWidth: 250, overflow: 'hidden'}}>
                {/* <span style={{fontWeight: 500}}>Graded at</span>{' '}
                <span style={{fontWeight: 300}}>
                  {gradedAt && moment(gradedAt).format('MM-DD-YYYY')}
                </span> */}
                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  overlay={<Tooltip id="verifiedId">{gradedBy}</Tooltip>}*/}
                {/*>*/}
                {/*  <div*/}
                {/*    style={{*/}
                {/*      fontWeight: 400,*/}
                {/*      letterSpacing: '1px',*/}
                {/*      maxWidth: '300px',*/}
                {/*      overflowWrap: 'break-word',*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    {gradedTruncated}*/}
                {/*  </div>*/}
                {/*</OverlayTrigger>*/}
              </div>
              {/*<div className="text-white" style={{maxWidth: 250, overflow: 'hidden'}}>*/}
              {/*  Graded At{""}*/}
              {/*  <OverlayTrigger*/}
              {/*    placement="bottom"*/}
              {/*    overlay={<Tooltip id="verifiedId">{""}</Tooltip>}*/}
              {/*  >*/}
              {/*    <div*/}
              {/*      style={{*/}
              {/*        color: '#73847F',*/}
              {/*        fontWeight: 400,*/}
              {/*        letterSpacing: '1px',*/}
              {/*        maxWidth: '300px',*/}
              {/*        overflowWrap: 'break-word',*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      {gradedAt && moment(gradedAt).format('MMMM Do YYYY, h:mm a')}*/}
              {/*    </div>*/}
              {/*  </OverlayTrigger>*/}
              {/*</div>*/}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="ModelExamGradingPreview-Header-Close">
          <BoxIcon
            icon={FaIcons.FaWindowClose}
            size={30}
            style={{backgroundColor: '#fff'}}
            color="#FF4C6C"
            onClick={() => {
              props.setOpenModal(false);
            }}
          />
        </div>
      </div>

      {state.selectedPaper?.paperType === PaperTypes.MCQ ||
      state.selectedPaper?.paperType === PaperTypes.TRUEFALSE ? (
        <div className="ModelExamGradingPreview-Body">
          <div className="MCQ-TF-Grading-Body">
            <div className="MCQ-TF-Grading-Body-content">
              {allQuestionState?.map((item, idx) => (
                <QuestionResult className="mt-4" question={item} idx={idx} key={idx} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="stru-ess-marking">
          <div className="stru-ess-marking__progress-bar">
            {state.loading ? <LoadingIndicator style={{marginBottom: 5}} /> : <></>}
          </div>
          <div className="stru-ess-marking__body">
            <div className="stru-ess-marking__upload-area">
              {(state.selectedPaper?.structEssayData?.markedAnswerSheetUrl !== 'NAN' &&
                state.selectedPaper?.structEssayData?.markedAnswerSheetUrl) ||
              state.file ? (
                <Row className="d-flex no-gutters mt-4 mx-4" style={{height: '100%'}}>
                  <Col xs={12} style={{height: '100%'}}>
                    <div style={{height: '100%'}} className="text-white">
                      <div
                        className="material-container d-flex flex-column"
                        style={{height: '75%', overflow: 'auto'}}
                      >
                        <Document
                          file={state.selectedPaper?.structEssayData?.markedAnswerSheetUrl}
                          onLoadSuccess={onDocumentLoadSuccess}
                          className="m-auto"
                        >
                          <Page
                            pageNumber={pageNumber}
                            onClick={() => {
                              window.open(
                                state.selectedPaper?.structEssayData?.markedAnswerSheetUrl
                              );
                            }}
                            className="cursor-pointer-megp"
                          />
                        </Document>
                      </div>
                      <div className="text-center">
                        <span className="material-name" style={{fontSize: '14px'}}>
                          {state.selectedPaper?.structEssayData?.markedAnswerSheetName}
                        </span>
                      </div>
                      <div className="text-center d-flex justify-content-center ">
                        <FaIcons.FaAngleLeft
                          size={30}
                          onClick={decrementPageNo}
                          style={{cursor: 'pointer', color: '#fff'}}
                        />
                        <FaIcons.FaAngleRight
                          size={30}
                          onClick={incrementPageNo}
                          style={{cursor: 'pointer', color: '#fff'}}
                        />
                      </div>
                      <p
                        className="text-center text-white mb-0"
                        style={{letterSpacing: '1px', fontSize: '14px'}}
                      >
                        Page <span style={{color: '#246BFD'}}>{pageNumber}</span> of{' '}
                        <span style={{color: '#246BFD'}}>{numPages}</span>
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : (
                <>
                  <div className="stru-ess-marking__up-btn-wrapper">
                    {!appUser.firestoreUser?.userRole?.isTeachingAssistant &&
                    paperStatusInformation?.status === StatusOfPaper.ASSIGNED ? (
                      <div
                        style={{
                          color: 'white',
                          zIndex: 800,
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          textAlign: 'center',
                          transform: 'translate(-50%,-50%)',
                          fontSize: 30,
                        }}
                      >
                        Paper Assigned
                      </div>
                    ) : state.selectedPaper?.structEssayData?.answerSheetUrl ? (
                      <div className="stru-ess-marking__up-btn-core">
                        <FaIcons.FaArrowUp
                          color="#fff"
                          size={50}
                          style={{
                            zIndex: 800,
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                          }}
                          className="mt-4"
                        />
                        <input
                          type="file"
                          className="stru-ess-marking__up-input"
                          style={{opacity: 0}}
                          onChange={(e) => {
                            onAnnotatedPdfUpload(e);
                          }}
                        />
                        <button className="stru-ess-marking__up-btn ">
                          <div className="mt-5"> {'Upload Marked \n Answer Sheet'}</div>
                        </button>
                      </div>
                    ) : (
                      <div
                        style={{
                          color: 'white',
                          zIndex: 800,
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          textAlign: 'center',
                          transform: 'translate(-50%,-50%)',
                          fontSize: 30,
                        }}
                      >
                        Student has not submitted an answer
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="stru-ess-marking__marks-and-comments">
              <div className="stru-ess-marking__download-label">Downloads</div>
              <div className="stru-ess-marking__papers">
                <UploadedPaper
                  title="Answer"
                  url={state.selectedPaper?.structEssayData?.answerSheetUrl}
                />
                <UploadedPaper title="Paper" url={state.selectedPaper?.paperUrl} />
                <UploadedPaper
                  title="Marking"
                  url={state.selectedPaper?.structEssayData?.markingSchemeUrl}
                />
              </div>
              {/* <div className="stru-ess-marking__delete-btn">Removed uploaded marked paper</div> */}
              <div className="stru-ess-marking__marks-label">Marks</div>
              <div className="stru-ess-marking__marks-input">
                <input
                  className={`stru-ess-marking__score ${
                    disabled ? 'stru-ess-marking__score--disabled' : ''
                  }`}
                  value={state.selectedPaper?.paperPoints || 0}
                  onChange={(e) => {
                    pointsChangeHandler(e.target.value);
                  }}
                  style={
                    state.selectedPaper?.structEssayData?.answerSheetUrl ? {} : {color: 'grey'}
                  }
                  disabled={disabled}
                />
                <input
                  className="stru-ess-marking__marks"
                  value={'/' + state.selectedPaper?.marks}
                  disabled
                />
              </div>
              <div className="stru-ess-marking__comments-label">Feedback</div>

              <textarea
                className="stru-ess-marking__tinymce"
                value={state.selectedPaper?.feedback || ''}
                onChange={(e) => {
                  feedBackChangeHandler(e.target.value);
                }}
                disabled={disabled}
              />
            </div>
          </div>

          {getFooter()}
        </div>
      )}

      <AlertPopup
        message="Please upload the marked answer sheet"
        header="Attention"
        isShow={show}
        onOk={() => {
          setShow(false);
        }}
        onClose={() => {
          setShow(false);
        }}
        type="OK"
      />
      <RejectFeedbackModal
        show={modalState.show}
        onConfirm={() => {
          submitApproveDissaproveOfPaper(StructEssayDataPaperStatus.REJECTED);
          closeModal();
        }}
        onCancel={closeModal}
        declineFeedback={modalState.feedback}
        onChange={feedbackHandler}
      />
    </div>
  );
};

export default ModelExamGradingPreview;
