import { matchSorter } from 'match-sorter';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaFilter, FaPlusCircle } from 'react-icons/fa';
import { useFunctions } from 'reactfire';
import firebase from 'firebase';
import './MCQTrueFalsePapersTab.scss';
import Notification from './../components/Notification';
import Label from './Label';
import { PaperData, ResourcePaperState } from '../interfaces';
import { PAPER_BREADCRUM_STEPS } from '../enums/PaperBreadCrum';
import { GRADES, RESOURCE_TAB_SUB_PAPERS } from '../constants';
import { PAPER_CREATION_MODE } from '../enums/PaperCreationMode';
import { usePapers } from '../context/PapersContext';
import {
  checkArrayContains,
  formatAllocatedTimeHoursAndMinutes,
  getMCQPapersBasedOnRole,
  getTFPapersBasedOnRole,
  shuffle,
  splitArrayIntoChunksOfLen,
  suffixAllocatedTime,
  validationResult,
} from '../utils';
import { Paper } from '../models/Paper';
import SearchBox from './SearchBox';
import FilterPapersPopUp from './FilterPapersPopUp';
import { PaperQuestion, Question } from '../models/Question';
import LoadingIndicator from './LoadingIndicator';
import Pagination from './Pagination';
import AlertPopup from './AlertPopup';
import { useAppUser } from '../context/UserContext';
import { useSubjects } from '../context/SubjectsContext';
import PaperCard from './PaperCard';
import { useQuestions } from '../context/QuestionsContext';
import { useLabels } from '../context/LabelContext';
import DropDown from './DropDown';
import TextInput from './TextInput';
import TextFieldInput from './TextFieldInput';
import NumberInput from './NumberInput';
import TimeInputComp from './TimeInputComp';
import LabelDropDown from './LabelDropDown';
import ButtonComp from './ButtonComp';
import ComplexityIndicator from './ComplexityIndicator';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { CreatePaperDTO, UpdatePaperDTO } from '../dtos/CreateUpdatePaperDTOs';
import MCQQuestionCardColumn from './MCQQuestionCardColumn';
import QuestionCardInPaper from './QuestionCardInPaper';
import { PaperStatus } from '../enums/PaperStatus';
import { PaperTypes } from '../enums/paperTypes';
import PaperPreviewHeaderActionBar from './PaperPreviewHeaderActionBar';
import QuestionCard from './QuestionCard';
import PaperService from '../services/PaperService';
import { useLessons } from '../context/LessonsContext';
import SubjectDropDown from './SubjectDropDown';
import LessonDropDownMultiple from './LessonDropDownMultiple';
import UnitDropDownMultiple from './UnitDropDownMultiple';

// 'MCQ Papers' | 'True/False Papers'
interface Props {
  state: ResourcePaperState;
  setState: React.Dispatch<React.SetStateAction<ResourcePaperState>>;
  paperNaviationData: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
    paperComplexity: { complexityArray: number[]; isComplexityCorrect: boolean };
  };
  setPaperNaviationData: (para: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
    paperComplexity: { complexityArray: number[]; isComplexityCorrect: boolean };
  }) => void;
  lessons: { displayValue: string; id: string }[];
  subjects: { displayValue: string; id: string }[];
  units: { displayValue: string; id: string }[];
  currentSubTab: string;
}

export interface StateDisplayMCQPapers {
  papers: any;
  loading: boolean;
  searchText: string;
  grade: string | null;
  subject?: { value: { displayValue: string; id: string } };
  lesson?: { value: { displayValue: string; id: string } };
  unit?: { value: { displayValue: string; id: string } };
  user?: { value: { displayValue: string; id: string } };
  complexity?: number;
  page: number;
  totalPages: number;
  filtered: boolean;
  status?: string | null;
}

const MCQTrueFalsePapersTab: FC<Props> = (props) => {
  let content = null;
  if (props.paperNaviationData.mode === 'VIEW') {
    if (props.currentSubTab === RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS) {
      content = <DisplayMCQPapers {...props} />;
    } else if (props.currentSubTab === RESOURCE_TAB_SUB_PAPERS.TRUE_FALSE_PAPERS) {
      content = <DisplayTrueFalsePapers {...props} />;
    }
  } else if (
    (props.paperNaviationData.mode === 'CREATE' || props.paperNaviationData.mode === 'EDIT') &&
    props.paperNaviationData.step === PAPER_BREADCRUM_STEPS.STEP_ONE
  ) {
    content = <CreateMCQTrueFalsePaper {...props} />;
  } else if (
    ((props.paperNaviationData.mode === 'CREATE' || props.paperNaviationData.mode === 'EDIT') &&
      props.state.paperCreationMode.value.id === PAPER_CREATION_MODE.MANUAL &&
      props.paperNaviationData.step === PAPER_BREADCRUM_STEPS.STEP_TWO) ||
    ((props.paperNaviationData.mode === 'CREATE' || props.paperNaviationData.mode === 'EDIT') &&
      props.state.paperCreationMode.value.id === PAPER_CREATION_MODE.AUTOMATIC &&
      props.paperNaviationData.step === PAPER_BREADCRUM_STEPS.STEP_TWO &&
      !props.paperNaviationData.paperComplexity.isComplexityCorrect)
  ) {
    content = <ManualMCQTrueFalsePaperCreation {...props} />;
  } else if (
    (props.paperNaviationData.mode === 'CREATE' || props.paperNaviationData.mode === 'EDIT') &&
    props.paperNaviationData.step === PAPER_BREADCRUM_STEPS.STEP_TWO &&
    props.state.paperCreationMode.value.id === PAPER_CREATION_MODE.AUTOMATIC &&
    props.paperNaviationData.paperComplexity.isComplexityCorrect
  ) {
    content = <MCQTrueFalsePaperComplexity {...props} />;
  } else if (
    (props.paperNaviationData.mode === 'CREATE' || props.paperNaviationData.mode === 'EDIT') &&
    props.paperNaviationData.step === PAPER_BREADCRUM_STEPS.STEP_THREE
  ) {
    content = <MCQTrueFalsePaperQuestionSelection {...props} />;
  } else if (props.paperNaviationData.mode === 'PREVIEW') {
    content = <MCQTrueFalsePaperPreview {...props} />;
  }

  return content;
};

const DisplayMCQPapers: FC<Props> = (props) => {
  const appPapers = usePapers();
  const appSubjects = useSubjects();
  const appUser = useAppUser();

  const [showAlert, setShowAlert] = useState(false);

  const setLoading = (val: boolean) => {
    setState((ps) => {
      return { ...ps, loading: val };
    });
  };

  const showAlertHandler = () => {
    setShowAlert(true);
  };

  const closeAlertHandler = () => {
    setShowAlert(false);
  };

  const deletePaperRef = firebase.functions().httpsCallable('deletePaper');

  const onPaperDelete = async (pid: string) => {
    setLoading(true);
    try {
      const { data } = await deletePaperRef({
        pid: pid,
      });

      appPapers.removeById(data.data);

      Notification({
        isSuccess: true,
        message: 'Paper deleted successfully',
      });
      setLoading(false);
      closeAlertHandler();
    } catch (e) {
      Notification({
        isSuccess: false,
        message: 'Failed to delete paper',
      });
      setLoading(false);
      closeAlertHandler();
    }
  };

  const initialState: StateDisplayMCQPapers = {
    papers: [],
    loading: false,
    searchText: '',
    grade: null,
    subject: { value: { displayValue: '', id: '' } },
    lesson: { value: { displayValue: '', id: '' } },
    unit: { value: { displayValue: '', id: '' } },
    user: { value: { displayValue: '', id: '' } },
    page: 0,
    totalPages: 0,
    filtered: false,
    status: null,
  };

  const [state, setState] = useState<StateDisplayMCQPapers>(initialState);

  const setCurrentPage = (page: number) => {
    setState((ps) => {
      return { ...ps, page: page };
    });
  };

  const [show, setShow] = useState(false);

  const [deleteId, setDeleteId] = useState('');

  const openModalHandler = () => {
    setShow(true);
  };

  const closeModalHander = () => {
    setShow(false);
  };

  const fetchDataCall = (filter?: boolean) => {
    if (typeof state.totalPages === 'number') {
      setState((ps) => {
        return { ...ps, loading: true };
      });

      const pageToSend = filter ? 0 : state.page - 1 < 0 ? 0 : state.page - 1;

      let filteredList = getMCQPapersBasedOnRole(
        appUser.firestoreUser?.uid || '',
        appSubjects.allSubjects,
        appUser.firestoreUser?.userRole,
        appPapers.mcqPapers
      );

      if (state?.grade?.length) {
        filteredList = filteredList.filter((item) => state?.grade?.includes(item.grade));
      }

      if (state?.subject?.value.id) {
        filteredList = filteredList.filter((item) => item.subjectId === state?.subject?.value.id);
      }

      if (state.lesson?.value.id) {
        filteredList = filteredList.filter((item) =>
          item.lessonIds?.includes(state.lesson?.value.id || '')
        );
      }

      if (state.unit?.value.id) {
        filteredList = filteredList.filter((item) =>
          item.unitIds?.includes(state.unit?.value.id || '')
        );
      }

      if (state?.user?.value.id) {
        filteredList = filteredList.filter((item) => item.createdBy === state?.user?.value.id);
      }

      if (state?.status === 'PUBLISHED' || state?.status === 'DRAFT') {
        filteredList = filteredList.filter((item) => item.status === state?.status);
      }

      // let searchTextResult: Paper[] = [];
      let duplicatesRemovedArray: Paper[];

      if (state?.searchText?.trim()) {
        // const words = state?.searchText.trim()?.split(' ');

        // for (const word in words) {
        //   searchTextResult = [
        //     ...searchTextResult,
        //     ...filteredList.filter(
        //       (item) => matchSorter(item.paperTitle.split(' '), words[word].trim() || '').length
        //     ),
        //   ];
        // }

        // duplicatesRemovedArray = Array.from(new Set(searchTextResult));
        duplicatesRemovedArray = filteredList.filter((item) =>
          item.paperTitle
            .replace(/<[^>]+>/g, '')
            .replace(/&nbsp;/g, '')
            .toUpperCase()
            .startsWith(state.searchText.trim().toUpperCase())
        );
      } else {
        duplicatesRemovedArray = filteredList;
      }

      let pagesList: Paper[] = [];

      if (duplicatesRemovedArray.length) {
        pagesList = splitArrayIntoChunksOfLen(duplicatesRemovedArray, 10);
      }

      const totalPages = pagesList.length;

      setState((ps) => {
        return {
          ...ps,
          loading: false,
          papers: pagesList[pageToSend] || [],
          totalPages: totalPages,
          filtered: filter ? true : false,
        };
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (appPapers.allMCQTFPapers.length) {
        fetchDataCall();
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paperNaviationData.mode, state.page, appPapers.allMCQTFPapers.length]);

  const applyFiltersHandler = () => {
    fetchDataCall(true);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setState((ps) => {
        return { ...ps, filtered: false };
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filtered]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchDataCall(true);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchText]);

  return (
    <div style={{ marginTop: 90 }}>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <div className="d-flex position-relative">
          <SearchBox
            onSearch={(text) => {
              setState((ps) => {
                return { ...ps, searchText: text };
              });
            }}
            placeHolderText="Search Papers"
          />
          <button className="filter_button" onClick={openModalHandler}>
            Filters &nbsp;
            <FaFilter />
          </button>
          <FilterPapersPopUp
            show={show}
            onCloseModal={closeModalHander}
            setState={setState}
            state={state}
            initialState={initialState}
            onApplyfilter={applyFiltersHandler}
          />
        </div>
        <button
          className="create-question-btn"
          onClick={() => {
            props.setPaperNaviationData({
              ...props.paperNaviationData,
              mode: 'CREATE',
              step: PAPER_BREADCRUM_STEPS.STEP_ONE,
              paperData: null,
            });
          }}
        >
          <FaPlusCircle /> {'Create MCQ Paper'}
        </button>
      </div>
      {state.loading && <LoadingIndicator className="mt-3" />}
      <div
        className="q-card-table-header d-flex"
        style={{ marginTop: state.loading ? 30 : 45, transition: 'none' }}
      >
        <div>Title</div>
      </div>

      <div className="paper-card-list">
        {state.papers.map((paper: Paper, idx: any) => {
          const questionsArray = paper.questions as Question[];

          const labels = questionsArray[0]?.labels || [];

          return (
            <PaperCard
              key={paper.id}
              paperName={paper.paperTitle}
              onClick={() => {
                props.setPaperNaviationData({
                  ...props.paperNaviationData,
                  mode: 'PREVIEW',
                  paperData: {
                    unitId: paper.unitId || '',
                    unitIds: paper.unitIds || [],
                    lessonId: paper.lessonId || '',
                    lessonIds: paper.lessonIds || [],
                    subjectId: paper.subjectId,
                    createdBy: paper.createdBy || '',
                    paperId: paper.id || '',
                    grade: paper.grade,
                    labels: labels,
                  },
                });
              }}
              paperData={{
                unitId: paper.unitId || '',
                unitIds: paper.unitIds || [],
                lessonId: paper.lessonId || '',
                lessonIds: paper.lessonIds || [],
                subjectId: paper.subjectId,
                createdBy: paper.createdBy || '',
                paperId: paper.id || '',
                grade: paper.grade,
                labels: labels,
              }}
              setPaperNaviationData={props.setPaperNaviationData}
              paperNaviationData={props.paperNaviationData}
              searchText={state.searchText}
              status={paper.status}
              paperAuthor={paper.createdBy}
              onDelete={() => {
                showAlertHandler();
                setDeleteId(paper.id || '');
              }}
            />
          );
        })}
      </div>
      {!state.filtered && state.totalPages > 1 ? (
        <Pagination pages={state.totalPages} setCurrentPage={setCurrentPage} />
      ) : (
        <></>
      )}
      <AlertPopup
        message="Are you sure you want to delete this paper?"
        header="Attention"
        isShow={showAlert}
        primaryButtonText="Confirm"
        onOk={() => {
          onPaperDelete(deleteId);
          closeModalHander();
        }}
        onClose={closeAlertHandler}
      />
    </div>
  );
};

//Display Paper List
const DisplayTrueFalsePapers: FC<Props> = (props) => {
  const appPapers = usePapers();
  const appUser = useAppUser();
  const appSubjects = useSubjects();

  const [showAlert, setShowAlert] = useState(false);

  const setLoading = (val: boolean) => {
    setState((ps) => {
      return { ...ps, loading: val };
    });
  };

  const showAlertHandler = () => {
    setShowAlert(true);
  };

  const closeAlertHandler = () => {
    setShowAlert(false);
  };

  const deletePaperRef = firebase.functions().httpsCallable('deletePaper');

  const onPaperDelete = async (pid: string) => {
    setLoading(true);
    try {
      const { data } = await deletePaperRef({
        pid: pid,
      });

      appPapers.removeById(data.data);

      Notification({
        isSuccess: true,
        message: 'Paper deleted successfully',
      });
      setLoading(false);
      closeAlertHandler();
    } catch (e) {
      Notification({
        isSuccess: false,
        message: 'Failed to delete paper',
      });
      setLoading(false);
      closeAlertHandler();
    }
  };

  const initialState: StateDisplayMCQPapers = {
    papers: [],
    loading: false,
    searchText: '',
    grade: null,
    subject: { value: { displayValue: '', id: '' } },
    lesson: { value: { displayValue: '', id: '' } },
    unit: { value: { displayValue: '', id: '' } },
    user: { value: { displayValue: '', id: '' } },
    page: 0,
    totalPages: 0,
    filtered: false,
    status: null,
  };

  const [state, setState] = useState<StateDisplayMCQPapers>(initialState);

  const setCurrentPage = (page: number) => {
    setState((ps) => {
      return { ...ps, page: page };
    });
  };

  const [show, setShow] = useState(false);

  const [deleteId, setDeleteId] = useState('');

  const openModalHandler = () => {
    setShow(true);
  };

  const closeModalHander = () => {
    setShow(false);
  };

  const fetchDataCall = (filter?: boolean) => {
    if (typeof state.totalPages === 'number') {
      setState((ps) => {
        return { ...ps, loading: true };
      });

      const pageToSend = filter ? 0 : state.page - 1 < 0 ? 0 : state.page - 1;

      let filteredList = getTFPapersBasedOnRole(
        appUser.firestoreUser?.uid || '',
        appSubjects.allSubjects,
        appUser.firestoreUser?.userRole,
        appPapers.tfPapers
      );

      if (state?.grade?.length) {
        filteredList = filteredList.filter((item) => state?.grade?.includes(item.grade));
      }

      if (state?.subject?.value.id) {
        filteredList = filteredList.filter((item) => item.subjectId === state?.subject?.value.id);
      }

      if (state.lesson?.value.id) {
        filteredList = filteredList.filter((item) =>
          item.lessonIds?.includes(state.lesson?.value.id || '')
        );
      }

      if (state.unit?.value.id) {
        filteredList = filteredList.filter((item) =>
          item.unitIds?.includes(state.unit?.value.id || '')
        );
      }

      if (state?.user?.value.id) {
        filteredList = filteredList.filter((item) => item.createdBy === state?.user?.value.id);
      }

      if (state?.status === 'PUBLISHED' || state?.status === 'DRAFT') {
        filteredList = filteredList.filter((item) => item.status === state?.status);
      }

      // let searchTextResult: Paper[] = [];
      let duplicatesRemovedArray: Paper[];

      if (state?.searchText?.trim()) {
        // const words = state?.searchText.trim()?.split(' ');

        // for (const word in words) {
        //   searchTextResult = [
        //     ...searchTextResult,
        //     ...filteredList.filter(
        //       (item) => matchSorter(item.paperTitle.split(' '), words[word].trim() || '').length
        //     ),
        //   ];
        // }

        // duplicatesRemovedArray = Array.from(new Set(searchTextResult));

        duplicatesRemovedArray = filteredList.filter((item) =>
          item.paperTitle
            .replace(/<[^>]+>/g, '')
            .replace(/&nbsp;/g, '')
            .toUpperCase()
            .startsWith(state.searchText.trim().toUpperCase())
        );
      } else {
        duplicatesRemovedArray = filteredList;
      }

      let pagesList: Paper[] = [];

      if (duplicatesRemovedArray.length) {
        pagesList = splitArrayIntoChunksOfLen(duplicatesRemovedArray, 10);
      }

      const totalPages = pagesList.length;

      setState((ps) => {
        return {
          ...ps,
          loading: false,
          papers: pagesList[pageToSend] || [],
          totalPages: totalPages,
          filtered: filter ? true : false,
        };
      });
    }
  };

  useEffect(() => {
    if (appPapers.tfPapers.length) {
      fetchDataCall();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paperNaviationData.mode, state.page, appPapers.tfPapers.length]);

  const applyFiltersHandler = () => {
    fetchDataCall(true);
  };

  useEffect(() => {
    setState((ps) => {
      return { ...ps, filtered: false };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filtered]);

  useEffect(() => {
    fetchDataCall(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchText]);

  return (
    <div style={{ marginTop: 90 }}>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <div className="d-flex position-relative">
          <SearchBox
            onSearch={(text) => {
              setState((ps) => {
                return { ...ps, searchText: text };
              });
            }}
            placeHolderText="Search Papers"
          />
          <button className="filter_button" onClick={openModalHandler}>
            Filters &nbsp;
            <FaFilter />
          </button>
          <FilterPapersPopUp
            show={show}
            onCloseModal={closeModalHander}
            setState={setState}
            state={state}
            initialState={initialState}
            onApplyfilter={applyFiltersHandler}
          />
        </div>
        <button
          className="create-question-btn"
          onClick={() => {
            props.setPaperNaviationData({
              ...props.paperNaviationData,
              mode: 'CREATE',
              step: PAPER_BREADCRUM_STEPS.STEP_ONE,
              paperData: null,
            });
          }}
        >
          <FaPlusCircle /> {'Create T/F Paper'}
        </button>
      </div>
      {state.loading && <LoadingIndicator className="mt-3" />}
      <div
        className="q-card-table-header d-flex"
        style={{ marginTop: state.loading ? 30 : 45, transition: 'none' }}
      >
        <div>Title</div>
      </div>

      <div className="paper-card-list">
        {state.papers.map((paper: Paper, idx: any) => {
          const questionsArray = paper.questions as Question[];

          const labels = questionsArray[0]?.labels || [];

          return (
            <PaperCard
              key={paper.id}
              paperName={paper.paperTitle}
              onClick={() => {
                props.setPaperNaviationData({
                  ...props.paperNaviationData,
                  mode: 'PREVIEW',
                  paperData: {
                    unitId: paper.unitId || '',
                    lessonId: paper.lessonId || '',
                    unitIds: paper.unitIds || [],
                    lessonIds: paper.lessonIds || [],
                    subjectId: paper.subjectId,
                    createdBy: paper.createdBy || '',
                    paperId: paper.id || '',
                    grade: paper.grade,
                    labels: labels,
                  },
                });
              }}
              setPaperNaviationData={props.setPaperNaviationData}
              paperNaviationData={props.paperNaviationData}
              paperData={{
                unitId: paper.unitId || '',
                lessonId: paper.lessonId || '',
                unitIds: paper.unitIds || [],
                lessonIds: paper.lessonIds || [],
                subjectId: paper.subjectId,
                createdBy: paper.createdBy || '',
                paperId: paper.id || '',
                grade: paper.grade,
                labels: labels,
              }}
              searchText={state.searchText}
              status={paper.status}
              paperAuthor={paper.createdBy}
              onDelete={() => {
                showAlertHandler();
                setDeleteId(paper.id || '');
              }}
            />
          );
        })}
      </div>
      {!state.filtered && state.totalPages > 1 ? (
        <Pagination pages={state.totalPages} setCurrentPage={setCurrentPage} />
      ) : (
        <></>
      )}
      <AlertPopup
        message="Are you sure you want to delete this paper?"
        header="Attention"
        isShow={showAlert}
        primaryButtonText="Confirm"
        onOk={() => {
          onPaperDelete(deleteId);
          closeModalHander();
        }}
        onClose={closeAlertHandler}
      />
    </div>
  );
};

//--------------Paper Creation-------------------------
const CreateMCQTrueFalsePaper: FC<Props> = (props) => {
  const appQuestions = useQuestions();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const appLessons = useLessons();

  const isMCQ = props.currentSubTab === RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS;

  const [show, setShow] = useState(false);

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const resetErrorStates = () => {
    props.setState((ps) => {
      return {
        ...ps,
        paperTitle: { ...ps.paperTitle, error: '' },
        description: { ...ps.description, error: '' },
        grade: { ...ps.grade, error: '' },
        subject: { ...ps.subject, error: '' },
        lesson: { ...ps.lesson, error: '' },
        totalNoOfQuestions: { ...ps.totalNoOfQuestions, error: '' },
        allocatedTime: { ...ps.allocatedTime, error: '' },
      };
    });
  };

  const [labels, setLabels] = useState<{ displayValue: string; id: string }[]>([]);

  const appLabels = useLabels();
  const inputChangeHandler = (state: any, name: string, id: string) => {
    const array = state[name].value.filter((value: any) => value.id !== id);

    props.setState &&
      props.setState({
        ...state,
        [name]: { ...state[name], value: array },
      });
  };

  useEffect(() => {
    const list = appLabels.labelList
      .filter((value) => {
        return value.subjectId === props.state.subject.value.id;
      })
      .map((l) => ({ displayValue: l.labelName, id: l.id }));

    const labels = list && list.length ? list : [];

    const selectedLabels = labels.filter((val) => {
      return props.state.labels.value.map((l) => l.id).includes(val.id);
    });

    props.setState((ps) => ({
      ...ps,
      labels: {
        ...ps.labels,
        value: selectedLabels,
        error: '',
      },
    }));

    setLabels(labels);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLabels.labelList, props.state.subject.value.id]);

  const func = () => {};

  return (
    <div className="create-mcq-tf-paper">
      <div className="create-mcq-tf-paper__main">
        <div className="create-mcq-tf-paper__fields">
          <Row className="no-gutters">
            {props.state.loading.value && <LoadingIndicator />}
            <Col md={3}>
              <DropDown
                name="Grade"
                noValueText="Select grade"
                className="textinput-margin-signup px-0"
                stateName="grade"
                stateValue={props.state.grade.value}
                state={props.state}
                setState={props.setState}
                error={props.state.grade.error}
                optionsArray={GRADES}
              />
            </Col>
            <Col md={8} className="mx-auto">
              <SubjectDropDown
                name="Subject"
                noValueText="Select subject"
                className="textinput-margin-signup"
                stateName="subject"
                stateValue={props.state.subject.value}
                state={props.state}
                setState={props.setState || func}
                error={props.state.subject.error}
                optionsArray={props.subjects}
              />
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col md={6}>
              <LessonDropDownMultiple
                name="Lessons"
                noValueText="Select lesson"
                className="textinput-margin-signup"
                stateName="lessons"
                stateValue={props.state.lessons.value}
                state={props.state}
                setState={props.setState || func}
                error={props.state.lessons.error}
                optionsArray={props.state.subject.value.id === '' ? [] : props.lessons}
                truncate={15}
                lessonLimit={true}
              />
            </Col>
            {props.state.lessons.value.length === 1 &&
            // appLessons.lessons.find((l) => l.id === props.state.lessons.value[0]?.id)
            //   ?.isLessonContainsUnits
            props.units.length > 0 ? (
              <Col md={5} className="mx-auto">
                <UnitDropDownMultiple
                  name="Units"
                  noValueText="Select unit"
                  className="textinput-margin-signup"
                  stateName="units"
                  stateValue={props.state.units.value}
                  state={props.state}
                  setState={props.setState || func}
                  error={props.state.units.error}
                  optionsArray={props.units}
                  truncate={15}
                  unitLimit={true}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <TextInput
            className="textinput-margin-signup"
            stateName="paperTitle"
            stateValue={props.state.paperTitle.value}
            state={props.state}
            setState={props.setState}
            error={props.state.paperTitle.error}
            placeHolder="Paper Title"
            style={{ marginTop: 25 }}
          />

          <TextFieldInput
            name="Description"
            stateName="description"
            stateValue={props.state.description.value}
            state={props.state}
            setState={props.setState}
            error={props.state.description.error}
            style={{ marginTop: 25, width: '96%' }}
            limit={100}
          />

          <Row className="no-gutters" style={{ marginTop: 25 }}>
            <Col md={3}>
              <NumberInput
                className="textinput-margin-signup"
                stateName="totalNoOfQuestions"
                stateValue={props.state.totalNoOfQuestions.value}
                state={props.state}
                setState={props.setState}
                error={props.state.totalNoOfQuestions.error}
                placeHolder="Total number of questions"
              />
            </Col>
            <Col md={4} className="mx-auto">
              <TimeInputComp
                text="Allocated Time"
                stateName="allocatedTime"
                state={props.state}
                setState={props.setState}
                dateTime
                style={{ marginTop: 6 }}
                error={props.state.allocatedTime.error}
                layoutStyles={{ width: 150, margin: 'auto' }}
              />
            </Col>
            <Col md={4}>
              <DropDown
                name="Paper Creation Type"
                noValueText="Select mode"
                stateName="paperCreationMode"
                stateValue={props.state.paperCreationMode.value}
                state={props.state}
                setState={props.setState}
                optionsArray={[
                  {
                    displayValue: PAPER_CREATION_MODE.AUTOMATIC,
                    id: PAPER_CREATION_MODE.AUTOMATIC,
                  },
                  { displayValue: PAPER_CREATION_MODE.MANUAL, id: PAPER_CREATION_MODE.MANUAL },
                ]}
                error=""
                style={{ marginTop: 4, width: '88%' }}
              />
            </Col>
          </Row>

          <Row className="no-gutters" style={{ marginTop: 25 }}>
            <Col md={3}>
              <LabelDropDown
                name="Label"
                noValueText="Select Label"
                className="textinput-margin-signup"
                stateName="labels"
                stateValue={props.state.labels.value}
                state={props.state}
                setState={props.setState || func}
                error={props.state.labels.error}
                optionsArray={
                  labels
                    ? labels.filter(
                        (l) => !props.state.labels.value.map((val: any) => val.id).includes(l.id)
                      )
                    : []
                }
                onInputChange={() => {
                  props.setState((ps) => ({ ...ps, questions: { ...ps.questions, value: [] } }));
                }}
              />
              <div className="d-flex w-100 flex-wrap">
                {props.state.labels.value.map((l: any, idx: number) => (
                  <Label
                    id={l.id}
                    onClose={() => {
                      inputChangeHandler(props.state, 'labels', l.id);
                    }}
                    key={idx}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </div>

        <div className="create-mcq-tf-paper__buttons">
          <ButtonComp
            type="one"
            text="Next"
            style={{ marginTop: 'auto' }}
            onClick={() => {
              const array = isMCQ
                ? appQuestions.mcqQuestions.length
                : appQuestions.tfQuestions.length;

              if (+props.state.totalNoOfQuestions.value > array) {
                openModal();
              } else {
                const validationOutput = validationResult(props.state, ['marks']);

                const hours = props.state.allocatedTime.value.hours || '00';
                const minutes = props.state.allocatedTime.value.minutes || '00';

                let error = '';

                if (+hours) {
                  error = 'NO-ERROR';
                } else if (+minutes < 30) {
                  error = `Allocated time should be greater than 30 minutes`;
                } else {
                  error = 'NO-ERROR';
                }

                const intermediateState = {
                  ...validationOutput.state,
                  allocatedTime: {
                    ...validationOutput.state.allocatedTime,
                    error: error,
                  },
                };

                let hasError = false;

                if (error !== 'NO-ERROR') {
                  hasError = true;
                }

                props.setState(intermediateState);

                if (!!validationOutput.formValidity || hasError) {
                  props.setState((pS: any) => ({
                    ...pS,
                    loading: false,
                  }));
                  return;
                }

                resetErrorStates();

                if (props.state.paperCreationMode.value.id === PAPER_CREATION_MODE.AUTOMATIC) {
                  props.setPaperNaviationData({
                    ...props.paperNaviationData,
                    step: PAPER_BREADCRUM_STEPS.STEP_TWO,
                  });
                } else {
                  props.setPaperNaviationData({
                    ...props.paperNaviationData,
                    step: PAPER_BREADCRUM_STEPS.STEP_TWO,
                    paperComplexity: { isComplexityCorrect: false, complexityArray: [] },
                  });
                }
              }
            }}
          />
        </div>
        <AlertPopup
          message={`Total number of ${isMCQ ? 'MCQ' : 'T/F'} questions available is ${
            isMCQ ? appQuestions.mcqQuestions.length : appQuestions.tfQuestions.length
          }`}
          header="Invalid Question count"
          isShow={show}
          primaryButtonText="OK"
          onOk={closeModal}
          onClose={() => {
            closeModal();
          }}
          type="OK"
        />
      </div>
    </div>
  );
};

const MCQTrueFalsePaperComplexity: FC<Props> = (props) => {
  const appQuestions = useQuestions();

  const isMCQ = props.currentSubTab === RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS;

  const [show, setShow] = useState<{ isShow: boolean; message: any }>({
    isShow: false,
    message: '',
  });

  const [complexity, setComplexity] = useState([0, 0, 0, 0, 0]);

  const [isComplexityChangedOnEdit, setIsComplexityChangedOnEdit] = useState(false);

  const filterOutReleventQtnsForGradeSubjectLessonUnit = useCallback(
    (qtns: Question[]) => {
      qtns = qtns.filter(
        (q) =>
          q.grade === props.state.grade.value.id && q.subjectId === props.state.subject.value.id
      );

      if (props.state.lessons.value.length) {
        qtns = qtns.filter((q) =>
          props.state.lessons.value.map((q) => q.id).includes(q.lessonId || '')
        );
      }

      if (props.state.units.value.length) {
        qtns = qtns.filter((q) =>
          props.state.units.value.map((q) => q.id).includes(q.unitId || '')
        );
      }

      qtns = props.state.labels.value.length
        ? qtns.filter((q) => {
            return checkArrayContains(
              q.labels || [],
              props.state.labels.value.map((l) => l.id) || []
            );
          })
        : qtns;

      return qtns;
    },
    [
      props.state.grade.value.id,
      props.state.units.value,
      props.state.lessons.value,
      props.state.subject.value.id,
      props.state.labels.value,
    ]
  );

  const closeModal = () => {
    setShow((ps) => {
      return { ...ps, isShow: false };
    });
  };

  const getAllDifferentComplexities = (array: Question[]) => {
    const compLevelOne = array.filter((i) => i.complexity! === 0).length;
    const compLevelTwo = array.filter((i) => i.complexity! === 25).length;
    const compLevelThree = array.filter((i) => i.complexity! === 50).length;
    const compLevelFour = array.filter((i) => i.complexity! === 75).length;
    const compLevelFive = array.filter((i) => i.complexity! === 100).length;

    return {
      compLevelOne,
      compLevelTwo,
      compLevelThree,
      compLevelFour,
      compLevelFive,
    };
  };

  useEffect(() => {
    if (
      (props.paperNaviationData.mode === 'EDIT' || props.paperNaviationData.mode === 'CREATE') &&
      props.state.questions.value.length
    ) {
      // TIP;
      const questionArray = (props.state.questions.value as PaperQuestion[]).filter(
        (q) =>
          q.grade === props.state.grade.value.id &&
          q.subjectId === props.state.subject.value.id &&
          props.state.lessons.value.map((v) => v.id).includes(q.lessonId || '')
      );

      const veryLow = questionArray.filter((i) => i.complexity === 0).length;
      const low = questionArray.filter((i) => i.complexity === 25).length;
      const medium = questionArray.filter((i) => i.complexity === 50).length;
      const high = questionArray.filter((i) => i.complexity === 75).length;
      const veryHigh = questionArray.filter((i) => i.complexity === 100).length;

      setComplexity([veryLow, low, medium, high, veryHigh]);
    }
  }, [
    props.paperNaviationData.mode,
    props.state.questions.value,
    props.state.grade.value.id,
    props.state.lessons.value,
    props.state.units.value,
    props.state.subject.value.id,
  ]);

  useEffect(() => {
    // TIPs;
    const array = filterOutReleventQtnsForGradeSubjectLessonUnit(
      props.state.questions.value as Question[]
    );

    const { compLevelOne, compLevelTwo, compLevelThree, compLevelFour, compLevelFive } =
      getAllDifferentComplexities(array);

    if (
      !(
        compLevelOne === complexity[0] &&
        compLevelTwo === complexity[1] &&
        compLevelThree === complexity[2] &&
        compLevelFour === complexity[3] &&
        compLevelFive === complexity[4]
      )
    ) {
      setIsComplexityChangedOnEdit(true);
    } else {
      setIsComplexityChangedOnEdit(false);
    }
  }, [complexity, filterOutReleventQtnsForGradeSubjectLessonUnit, props.state.questions.value]);

  const changeComplexity = (idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(+e.target.value)) {
      const com = [...complexity];

      com[idx] = Math.abs(+e.target.value);

      setComplexity(com);
    }
  };

  const calculateComplexity = (): number => {
    return complexity.reduce((total, num) => {
      return total + num;
    }, 0);
  };

  const arrayValue = isMCQ
    ? filterOutReleventQtnsForGradeSubjectLessonUnit(appQuestions.mcqQuestions as Question[])
    : filterOutReleventQtnsForGradeSubjectLessonUnit(appQuestions.tfQuestions as Question[]);

  const { compLevelOne, compLevelTwo, compLevelThree, compLevelFour, compLevelFive } =
    getAllDifferentComplexities(arrayValue);

  const complexityCheck = (): { message: any; hasError: boolean } => {
    let array = isMCQ
      ? filterOutReleventQtnsForGradeSubjectLessonUnit(appQuestions.mcqQuestions as Question[])
      : filterOutReleventQtnsForGradeSubjectLessonUnit(appQuestions.tfQuestions as Question[]);

    const { compLevelOne, compLevelTwo, compLevelThree, compLevelFour, compLevelFive } =
      getAllDifferentComplexities(array);

    let message1: any = '';
    let message2: any = '';
    let message3: any = '';
    let message4: any = '';
    let message5: any = '';
    let hasError: boolean = false;

    if (compLevelOne < complexity[0]) {
      message1 = (
        <li>{`Total very low level questions available is ${compLevelOne}. Provided ${complexity[0]}`}</li>
      );
      hasError = true;
    }
    if (compLevelTwo < complexity[1]) {
      message2 = (
        <li>{`Total low level questions available is ${compLevelTwo}. Provided ${complexity[1]}`}</li>
      );
      hasError = true;
    }
    if (compLevelThree < complexity[2]) {
      message3 = (
        <li>{`Total medium level questions available is ${compLevelThree}. Provided ${complexity[2]}`}</li>
      );
      hasError = true;
    }
    if (compLevelFour < complexity[3]) {
      message4 = (
        <li>{`Total high level questions available is ${compLevelFour}. Provided ${complexity[3]}`}</li>
      );
      hasError = true;
    }
    if (compLevelFive < complexity[4]) {
      message5 = (
        <li>{`Total very high level questions available is ${compLevelFive}. Provided ${complexity[4]}`}</li>
      );
      hasError = true;
    }

    return {
      message: (
        <ul style={{ paddingLeft: 20 }}>
          {message1}
          {message2}
          {message3}
          {message4}
          {message5}
        </ul>
      ),
      hasError: hasError,
    };
  };

  return (
    <div style={{ marginTop: 50 }}>
      <div className="question-complexity-selection ">{`Question Complexity (${calculateComplexity()} / ${
        props.state.totalNoOfQuestions.value
      })`}</div>

      <div className="d-flex ">
        <div
          className="d-flex flex-column justify-content-between mt-4 pt-2"
          style={{ height: 300 }}
        >
          <div className="d-flex align-items-end">
            <ComplexityIndicator value={0} />{' '}
            <input
              onChange={(e) => {
                changeComplexity(0, e);
              }}
              type="number"
              className="paper-questions-complexity-input"
              value={complexity[0]}
            />
          </div>
          <div className="d-flex align-items-end">
            <ComplexityIndicator value={25} />{' '}
            <input
              onChange={(e) => {
                changeComplexity(1, e);
              }}
              type="number"
              className="paper-questions-complexity-input"
              value={complexity[1]}
            />
          </div>
          <div className="d-flex align-items-end">
            <ComplexityIndicator value={50} />{' '}
            <input
              onChange={(e) => {
                changeComplexity(2, e);
              }}
              type="number"
              className="paper-questions-complexity-input"
              value={complexity[2]}
            />
          </div>
          <div className="d-flex align-items-end">
            <ComplexityIndicator value={75} />{' '}
            <input
              onChange={(e) => {
                changeComplexity(3, e);
              }}
              type="number"
              className="paper-questions-complexity-input"
              value={complexity[3]}
            />
          </div>
          <div className="d-flex align-items-end">
            <ComplexityIndicator value={100} />
            <input
              onChange={(e) => {
                changeComplexity(4, e);
              }}
              type="number"
              className="paper-questions-complexity-input"
              value={complexity[4]}
            />
          </div>
        </div>

        <div className="ml-auto question-complexity-info">
          <div className="d-flex">
            <div className="question-complexity-info__label" style={{ color: '#20d167' }}>
              Very Low Complexity
            </div>
            <div className=" question-complexity-info__value" style={{ color: '#20d167' }}>
              {compLevelOne}
            </div>
          </div>
          <div className="d-flex">
            <div className="question-complexity-info__label" style={{ color: '#96ea2f' }}>
              Low Complexity
            </div>
            <div className="question-complexity-info__value" style={{ color: '#96ea2f' }}>
              {compLevelTwo}
            </div>
          </div>
          <div className="d-flex">
            <div className="question-complexity-info__label" style={{ color: '#fafe00' }}>
              Medium Complexity
            </div>
            <div className="question-complexity-info__value" style={{ color: '#fafe00' }}>
              {compLevelThree}
            </div>
          </div>
          <div className="d-flex">
            <div className="question-complexity-info__label" style={{ color: '#efae32' }}>
              High Complexity
            </div>
            <div className=" question-complexity-info__value" style={{ color: '#efae32' }}>
              {compLevelFour}
            </div>
          </div>
          <div className="d-flex">
            <div className="question-complexity-info__label" style={{ color: '#e56261' }}>
              Very High Complexity
            </div>
            <div className="question-complexity-info__value" style={{ color: '#e56261' }}>
              {compLevelFive}
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 58 }}>
        <ButtonComp
          type="one"
          text="Generate Paper"
          onClick={() => {
            if (+props.state.totalNoOfQuestions.value !== calculateComplexity()) {
              setShow((ps) => {
                return {
                  ...ps,
                  message: `Total amount of questions should match ${
                    props.state.totalNoOfQuestions.value
                  }. Provided ${calculateComplexity()}`,
                  isShow: true,
                };
              });
            } else if (complexityCheck().hasError) {
              setShow((ps) => {
                return {
                  ...ps,
                  message: complexityCheck().message,
                  isShow: true,
                };
              });
            } else {
              props.setPaperNaviationData({
                ...props.paperNaviationData,
                step: PAPER_BREADCRUM_STEPS.STEP_THREE,
                paperComplexity: {
                  ...props.paperNaviationData.paperComplexity,
                  complexityArray: complexity,
                },
              });

              let veryLowShuffledArray: Question[] = [];
              let lowArray: Question[] = [];
              let mediumArray: Question[] = [];
              let highArray: Question[] = [];
              let veryHighArray: Question[] = [];
              // TIP
              let array: Question[] = isMCQ
                ? filterOutReleventQtnsForGradeSubjectLessonUnit(appQuestions.mcqQuestions)
                : filterOutReleventQtnsForGradeSubjectLessonUnit(appQuestions.tfQuestions);

              if (props.paperNaviationData.mode === 'EDIT' && !isComplexityChangedOnEdit) {
                array = props.state.questions.value;

                props.setState((ps: any) => {
                  return { ...ps, questions: { value: array } };
                });
                return;
              }

              complexity.forEach((i, idx) => {
                if (idx === 0 && i !== 0) {
                  const filteredArray = array.filter((i) => i.complexity === 0);

                  // const shuffled = shuffle(filteredArray);

                  // veryLowShuffledArray = shuffled.slice(0, i);

                  veryLowShuffledArray = filteredArray.slice(0, i);
                }
                if (idx === 1 && i !== 0) {
                  const filteredArray = array.filter((i) => i.complexity === 25);

                  // const shuffled = shuffle(filteredArray);

                  // lowArray = shuffled.slice(0, i);

                  lowArray = filteredArray.slice(0, i);
                }
                if (idx === 2 && i !== 0) {
                  const filteredArray = array.filter((i) => i.complexity === 50);

                  // const shuffled = shuffle(filteredArray);

                  // mediumArray = shuffled.slice(0, i);

                  mediumArray = filteredArray.slice(0, i);
                }
                if (idx === 3 && i !== 0) {
                  const filteredArray = array.filter((i) => i.complexity === 75);

                  // const shuffled = shuffle(filteredArray);

                  highArray = filteredArray.slice(0, i);
                }
                if (idx === 4 && i !== 0) {
                  const filteredArray = array.filter((i) => i.complexity === 100);

                  // const shuffled = shuffle(filteredArray);

                  veryHighArray = filteredArray.slice(0, i);
                }
              });

              const finalResult = [
                ...veryLowShuffledArray,
                ...lowArray,
                ...mediumArray,
                ...highArray,
                ...veryHighArray,
              ].sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                if (b.createdAt && a.createdAt) {
                  return +new Date(a.createdAt?.toDate()) - +new Date(b.createdAt?.toDate());
                }
                return 0;
              });

              props.setState((ps: any) => {
                return { ...ps, questions: { value: finalResult } };
              });
            }
          }}
        />
        <ButtonComp
          type="one"
          text="Back"
          outline
          onClick={() => {
            props.setPaperNaviationData({
              ...props.paperNaviationData,
              step: PAPER_BREADCRUM_STEPS.STEP_ONE,
            });
            props.setState((ps) => {
              return { ...props.state, questions: { ...props.state.questions, value: [] } };
            });
          }}
          className="ml-2"
        />
      </div>
      <AlertPopup
        message={show.message}
        header="Complexity Mismatch"
        isShow={show.isShow}
        primaryButtonText="OK"
        onOk={closeModal}
        onClose={() => {
          closeModal();
        }}
        type="OK"
      />
    </div>
  );
};

const ManualMCQTrueFalsePaperCreation: FC<Props> = (props) => {
  const appQuestions = useQuestions();
  const isMCQ = props.currentSubTab === RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS;

  const array = isMCQ ? appQuestions.mcqQuestions : appQuestions.tfQuestions;

  array.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    if (b.createdAt && a.createdAt) {
      return +new Date(a.createdAt?.toDate()) - +new Date(b.createdAt?.toDate());
    }
    return 0;
  });

  const [search, setSearch] = useState<{ searchText: string }>({
    searchText: '',
  });

  useEffect(() => {
    let qtns = array as Question[];

    qtns = qtns.filter(
      (q) => props.state.subject.value.id === q.subjectId && props.state.grade.value.id === q.grade
    );

    if (props.state.lessons.value.length) {
      qtns = qtns.filter((q) =>
        props.state.lessons.value.map((l) => l.id).includes(q.lessonId || '')
      );
    }

    if (props.state.units.value.length) {
      qtns = qtns.filter((q) => props.state.units.value.map((u) => u.id).includes(q.unitId || ''));
    }

    const labelIds = props.state.labels.value.map((l) => l.id);
    qtns = props.state.labels.value.length
      ? qtns.filter((q) => {
          return checkArrayContains(q.labels || [], labelIds || []);
        })
      : qtns;

    const indexesInColumnTwo = data.columns['column-2'].taskIds;

    const totalIds: string[] = [];

    const contentInColumnTwo = Object.entries(data.tasks)
      .map((item: any, index) => {
        totalIds.push(item[1].id);
        if (indexesInColumnTwo.includes((index + 1).toString())) {
          return item[1].content;
        } else {
          return null;
        }
      })
      .filter((i) => i !== null);

    let searchTextResult: Question[] = [];
    let duplicatesRemovedArray: Question[];

    if (search?.searchText?.trim()) {
      const words = search?.searchText.trim()?.split(' ');

      for (const word in words) {
        searchTextResult = [
          ...searchTextResult,
          ...qtns.filter(
            (item) => matchSorter(item.question.split(' '), words[word].trim() || '').length
          ),
        ];
      }

      duplicatesRemovedArray = Array.from(new Set(searchTextResult));
    } else {
      duplicatesRemovedArray = qtns;
    }

    const onlyIdsArrayOfSelectedContent = contentInColumnTwo.map((item: any) => item.id);

    const removedAlreadySelectedItems = duplicatesRemovedArray.filter((i) => {
      return !onlyIdsArrayOfSelectedContent.includes(i.id);
    });

    setData(constructUpdateData(removedAlreadySelectedItems));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.searchText]);

  const constructInitialData = (array: Question[]) => {
    let value: any = {
      tasks: {},
      columns: {
        'column-1': { id: 'column-1', taskIds: [] },
        'column-2': { id: 'column-2', taskIds: [] },
      },
      columnOrder: ['column-1', 'column-2'],
    };

    const idOfAlreadySelectedQuestions = props.state.questions.value.map((i) => i.id);

    const updatedQuestions: Question[] = [];

    props.state.questions.value.forEach((q) => {
      appQuestions.allQuestions.forEach((qtn) => {
        if (q.id === qtn.id) {
          updatedQuestions.push(q);
        }
      });
    });

    array = array.map((item) => {
      if (idOfAlreadySelectedQuestions.includes(item.id)) {
        return updatedQuestions.filter((i) => i.id === item.id)[0];
      } else {
        return item;
      }
    });

    const collectIndexOfUsed: any = [];
    array.forEach((item, index) => {
      value = {
        ...value,
        tasks: {
          ...value?.tasks,
          [index + 1]: {
            id: index + 1 + '',
            content: {
              ...item,
              questionText: item.question.replace(/<[^>]+>/g, ''),
            },
          },
        },
        columns: {
          ...value?.columns,
          'column-1': { ...value?.columns['column-1'], taskIds: [] },
          'column-2': { ...value?.columns['column-2'], taskIds: collectIndexOfUsed },
        },
      };
    });

    idOfAlreadySelectedQuestions.forEach((idd) => {
      Object.entries(value.tasks).forEach((item: any, index) => {
        if (idd === item[1].content.id) {
          collectIndexOfUsed.push(item[1].id);
        }
      });
    });

    value.columns['column-2'].taskIds = collectIndexOfUsed;

    return value;
  };

  const constructUpdateData = (removedAlreadySelectedItems: Question[]) => {
    let val: any = '';

    if (removedAlreadySelectedItems.length) {
      const indexes: any = [];
      Object.entries(data.tasks).forEach((item: any) => {
        removedAlreadySelectedItems.forEach((a) => {
          if (a.id === item[1].content.id) {
            indexes.push(item[0]);
          }
        });
      });

      val = {
        ...data,
        columns: {
          ...data?.columns,
          'column-1': { ...data?.columns['column-1'], taskIds: [...indexes] },
        },
      };
    } else {
      val = {
        ...data,
        columns: { ...data?.columns, 'column-1': { ...data?.columns['column-1'], taskIds: [] } },
      };
    }

    return val;
  };

  const [data, setData] = useState(
    constructInitialData(isMCQ ? appQuestions.mcqQuestions : appQuestions.tfQuestions)
  );
  const [show, setShow] = useState({ show: false, message: '', header: '' });

  const openHandler = (msg: any, header: string) => {
    setShow((ps) => ({ ...ps, show: true, message: msg, header: header }));
  };
  const closeHandler = () => {
    setShow((ps) => ({ ...ps, show: false, message: '', header: '' }));
  };

  const onDragEnd = (result: any) => {
    document.body.style.color = 'inherit';

    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);

      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      setData(newState);
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);

    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setData(newState);
  };

  const removeSelectedElement = (taskId: string) => {
    const idsInColumnTwo = data.columns['column-2'].taskIds;

    const updatedIdsInColumnTwo = idsInColumnTwo.filter((i: any) => i !== taskId);

    let val = {
      ...data,
      columns: {
        ...data?.columns,
        'column-2': { ...data?.columns['column-2'], taskIds: updatedIdsInColumnTwo },
        'column-1': {
          ...data?.columns['column-1'],
          taskIds: [...data?.columns['column-1'].taskIds, taskId],
        },
      },
    };

    setData(val);
  };

  const replaceSelectedQuestion = (taskId: string) => {
    //finding out the necessary complexity to replace

    const content = data.tasks[taskId].content;

    const complexityOfQuestion = content.complexity;

    //getting the items in the selected column with the conserned complexity

    const IndexIdsInSelectedColumn = data.columns['column-2'].taskIds;

    const IndexIdsNotToCollect: string[] = [];

    Object.entries(data.tasks).forEach((item: any) => {
      if (
        IndexIdsInSelectedColumn.includes(item[1].id) &&
        item[1].content.complexity === complexityOfQuestion
      ) {
        IndexIdsNotToCollect.push(item[1].id);
      }
    });

    //IdsIndexes that is possible to collect

    const IndexIdsInSearchColumn = data.columns['column-1'].taskIds;

    const IndexIdsPossibleToCollect: string[] = [];

    Object.entries(data.tasks).forEach((item: any) => {
      if (
        IndexIdsInSearchColumn.includes(item[1].id) &&
        item[1].content.complexity === complexityOfQuestion
      ) {
        IndexIdsPossibleToCollect.push(item[1].id);
      }
    });

    if (!IndexIdsPossibleToCollect.length) {
      let complexityText = '';

      if (complexityOfQuestion === 0) {
        complexityText = 'Very Low';
      }

      if (complexityOfQuestion === 25) {
        complexityText = 'Low';
      }

      if (complexityOfQuestion === 50) {
        complexityText = 'Medium';
      }

      if (complexityOfQuestion === 75) {
        complexityText = 'High';
      }
      if (complexityOfQuestion === 100) {
        complexityText = 'Very High';
      }

      openHandler(`There are no questions available with  ${complexityText} complexity`, 'Oops!');
    } else {
      //replace logic
      const possibleIndexesShuffled = shuffle(IndexIdsPossibleToCollect);

      const updateSelectedColumnIndexes = IndexIdsInSelectedColumn.map((i: any) => {
        if (i === taskId) {
          return possibleIndexesShuffled[0];
        } else {
          return i;
        }
      });

      const updatedSearchColumn = IndexIdsInSearchColumn.map((i: any) => {
        if (i === possibleIndexesShuffled[0]) {
          return taskId;
        } else {
          return i;
        }
      });

      let val = {
        ...data,
        columns: {
          ...data?.columns,
          'column-2': { ...data?.columns['column-2'], taskIds: updateSelectedColumnIndexes },
          'column-1': {
            ...data?.columns['column-1'],
            taskIds: updatedSearchColumn,
          },
        },
      };

      setData(val);
    }
  };

  const column1 = data.columns['column-1'];
  const column2 = data.columns['column-2'];
  const tasks1 = column1.taskIds.map((taskId: string) => data.tasks[taskId]);
  const tasks2 = column2.taskIds.map((taskId: string) => data.tasks[taskId]);

  useEffect(() => {
    const indexesToCollect = data.columns['column-2'].taskIds;

    const contentArray: any[] = [];

    indexesToCollect.forEach((i: string) => {
      for (var key of Object.keys(data.tasks)) {
        if (i === data.tasks[key].id) {
          contentArray.push(data.tasks[key].content);
        }
      }
    });

    props.setState((ps) => {
      return { ...ps, questions: { value: contentArray } };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div style={{ marginTop: 50 }} className="manual-paper-screen">
      <div style={{ color: 'red' }} className="manual-paper-screen__top-sec">
        <div className="manual-paper-screen__selected-qtns">{`Selected Questions (${props.state.questions.value.length}/${props.state.totalNoOfQuestions.value})`}</div>
        <SearchBox
          onSearch={(text) => {
            setSearch((ps) => {
              return { ...ps, searchText: text };
            });
          }}
          placeHolderText="Search Question"
          searchClassName="pr-0 ml-auto"
        />
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="manual-paper-screen__dnd">
          <div className="w-50 pr-2">
            <MCQQuestionCardColumn
              key={column2.id}
              column={column2}
              tasks={tasks2}
              type="selected"
              onRemove={removeSelectedElement}
              onReplace={replaceSelectedQuestion}
              searchText={search.searchText}
            />
          </div>
          <div className="w-50 pl-2">
            <MCQQuestionCardColumn
              key={column1.id}
              column={column1}
              tasks={tasks1}
              searchText={search.searchText}
            />
          </div>
        </div>
      </DragDropContext>

      <div className="manual-paper-screen__buttons">
        <ButtonComp
          type="one"
          text="Next"
          onClick={() => {
            const isTally =
              +props.state.totalNoOfQuestions.value === props.state.questions.value.length;

            if (!isTally) {
              return openHandler(
                `Total amount of questions needed is ${props.state.totalNoOfQuestions.value}. Provided ${props.state.questions.value.length}`,
                'Oops!'
              );
            }

            if (props.state.paperCreationMode.value.id === PAPER_CREATION_MODE.AUTOMATIC) {
              const array = props.state.questions.value as Question[];

              const veryLow =
                array.filter((q) => {
                  return q.complexity === 0;
                }).length || 0;
              const Low =
                array.filter((q) => {
                  return q.complexity === 25;
                }).length || 0;
              const medium =
                array.filter((q) => {
                  return q.complexity === 50;
                }).length || 0;
              const high =
                array.filter((q) => {
                  return q.complexity === 75;
                }).length || 0;
              const veryHigh =
                array.filter((q) => {
                  return q.complexity === 100;
                }).length || 0;

              let message1: any = '';
              let message2: any = '';
              let message3: any = '';
              let message4: any = '';
              let message5: any = '';

              let hasError: boolean = false;

              if (props.paperNaviationData.paperComplexity.complexityArray[0] !== veryLow) {
                message1 = (
                  <li>{`Provided very low level question count is ${veryLow}, expected ${props.paperNaviationData.paperComplexity.complexityArray[0]}`}</li>
                );
                hasError = true;
              }

              if (props.paperNaviationData.paperComplexity.complexityArray[1] !== Low) {
                message2 = (
                  <li>{`Provided low level question count is ${Low}, expected ${props.paperNaviationData.paperComplexity.complexityArray[1]}`}</li>
                );
                hasError = true;
              }
              if (props.paperNaviationData.paperComplexity.complexityArray[2] !== medium) {
                message3 = (
                  <li>{`Provided medium level question count is ${medium}, expected ${props.paperNaviationData.paperComplexity.complexityArray[2]}`}</li>
                );
                hasError = true;
              }
              if (props.paperNaviationData.paperComplexity.complexityArray[3] !== high) {
                message4 = (
                  <li>{`Provided high level question count is ${high}, expected ${props.paperNaviationData.paperComplexity.complexityArray[3]}`}</li>
                );
                hasError = true;
              }
              if (props.paperNaviationData.paperComplexity.complexityArray[4] !== veryHigh) {
                message5 = (
                  <li>{`Provided very high level question count is ${veryHigh}, expected ${props.paperNaviationData.paperComplexity.complexityArray[4]}`}</li>
                );
                hasError = true;
              }

              if (hasError) {
                return openHandler(
                  <ul style={{ paddingLeft: 20 }}>
                    {message1}
                    {message2}
                    {message3}
                    {message4}
                    {message5}
                  </ul>,
                  'Oops!'
                );
              }
            }

            props.setPaperNaviationData({
              ...props.paperNaviationData,
              step: PAPER_BREADCRUM_STEPS.STEP_THREE,
              paperComplexity: {
                isComplexityCorrect: true,
                complexityArray: props.paperNaviationData.paperComplexity.complexityArray,
              },
            });
          }}
          className="mr-1"
        />
        <ButtonComp
          type="one"
          text="Back"
          outline
          onClick={() => {
            if (props.state.paperCreationMode.value.id === PAPER_CREATION_MODE.AUTOMATIC) {
              props.setPaperNaviationData({
                ...props.paperNaviationData,
                step: PAPER_BREADCRUM_STEPS.STEP_THREE,
              });
            } else {
              props.setPaperNaviationData({
                ...props.paperNaviationData,
                step: PAPER_BREADCRUM_STEPS.STEP_ONE,
                paperComplexity: {
                  ...props.paperNaviationData.paperComplexity,
                  isComplexityCorrect: true,
                },
              });

              props.setState((ps) => {
                return { ...ps, questions: { ...props.state.questions, value: [] } };
              });
            }
          }}
          className="ml-2"
        />
      </div>
      <AlertPopup
        message={show.message}
        header={show.header}
        isShow={show.show}
        onOk={closeHandler}
        onClose={closeHandler}
        type="OK"
      />
    </div>
  );
};

const MCQTrueFalsePaperQuestionSelection: FC<Props> = (props) => {
  const functions = useFunctions();
  const appUser = useAppUser();

  const createPaperRef = functions.httpsCallable('createPaper');
  const updatePaperRef = functions.httpsCallable('updatePaper');

  const isAddUnits = !(props.state.lessons.value.length > 1);

  const createPaperDTO: CreatePaperDTO = {
    paperTitle: props.state.paperTitle.value,
    description: props.state.description.value,
    grade: props.state.grade.value.id,
    subjectId: props.state.subject.value.id,
    unitId: props.state.unit.value.id,
    lessonId: props.state.lesson.value.id,
    unitIds: isAddUnits ? props.state.units.value.map((u) => u.id) : [],
    lessonIds: props.state.lessons.value.map((l) => l.id),
    paperType: props.state.paperType.value,
    totalNoOfQuestions: +props.state.totalNoOfQuestions.value,
    allocatedTime:
      formatAllocatedTimeHoursAndMinutes(props.state.allocatedTime.value.hours) +
      ':' +
      formatAllocatedTimeHoursAndMinutes(props.state.allocatedTime.value.minutes),
    marks:
      props.state.questions.value
        .map((v) => v.marks)
        .reduce((total, num) => {
          return total + Math.round(num || 1);
        }, 0) + '',
    questions: props.state.questions.value,
    status: props.state.status.value,
    paperCreationMode: props.state.paperCreationMode.value.id,
    active: true,
    authorName: appUser.firestoreUser?.username || '',
  };

  const updatePaperDTO: UpdatePaperDTO = {
    ...createPaperDTO,
    pid: props.paperNaviationData.paperData?.paperId || '',
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const val: any[] = props.state.questions.value;

    const items = Array.from(val);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    props.setState((ps) => {
      return { ...ps, questions: { value: items } };
    });
  };

  const appPapers = usePapers();

  const isMCQ = props.currentSubTab === RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS;

  const [open, setOpen] = useState<{
    show: boolean;
    message: string;
    header: string;
    action?: () => void;
  }>({
    show: false,
    message: '',
    header: '',
  });

  const openHandler = (msg: string, header: string, action?: () => void) => {
    setOpen((ps) => {
      return { ...ps, show: true, message: msg, header: header, action: action };
    });
  };

  const closeHandler = () => {
    setOpen((ps) => {
      return { ...ps, show: false, message: '', header: '' };
    });
  };

  const goToAdjustComplexity = () => {
    props.setPaperNaviationData({
      ...props.paperNaviationData,
      step: PAPER_BREADCRUM_STEPS.STEP_TWO,
      paperComplexity: {
        ...props.paperNaviationData.paperComplexity,
        isComplexityCorrect: false,
      },
    });
  };

  const [stateOfComplexity, setStateOfComplexity] = useState(
    props.paperNaviationData.paperComplexity.complexityArray
  );

  const changeComplexityOnDelete = (complexity: 0 | 25 | 50 | 75 | 100) => {
    const tempArray = [...props.paperNaviationData.paperComplexity.complexityArray];

    if (complexity === 0) {
      tempArray[0] = tempArray[0] - 1;
    }

    if (complexity === 25) {
      tempArray[1] = tempArray[1] - 1;
    }

    if (complexity === 50) {
      tempArray[2] = tempArray[2] - 1;
    }

    if (complexity === 75) {
      tempArray[3] = tempArray[3] - 1;
    }
    if (complexity === 100) {
      tempArray[4] = tempArray[4] - 1;
    }

    setStateOfComplexity(tempArray);
  };

  return (
    <div className="paper-creation-final-sec-wrapper">
      {props.state.loading.value && <LoadingIndicator />}
      <div className="question-card-in-paper">
        <div className="question-card-in-paper__title">Title</div>
        <div className="question-card-in-paper__right">
          <div className="question-card-in-paper__complexity">Complexity</div>
          <div className="question-card-in-paper__marks"> Marks</div>
        </div>
      </div>
      <div className="paper-creation-final-sec">
        <div>
          {props.state.questions.value.length ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="question-card-in-paper-id">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {[...props.state.questions.value].map((q: any, idx: any) => {
                      return (
                        <Draggable key={q.id} draggableId={q.id} index={idx}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <QuestionCardInPaper
                                qtn={q}
                                index={idx}
                                state={props.state}
                                setState={props.setState}
                                isMCQ={isMCQ}
                                changeComplexityOnDelete={changeComplexityOnDelete}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="paper-creation-final-sec__button-wrapper">
        <ButtonComp
          loading={props.state.loading.value}
          type="one"
          text={props.paperNaviationData.mode === 'EDIT' ? 'Update' : 'Save'}
          onClick={() => {
            if (!props.state.questions.value.length) {
              openHandler(
                'Question count is zero. Please satisfy total amount of questions',
                'Operation not allowed',
                goToAdjustComplexity
              );
              return;
            }

            if (props.state.questions.value.length !== +props.state.totalNoOfQuestions.value) {
              openHandler(
                `Please satisfy total amount of questions. Needed total amount of questions is ${props.state.totalNoOfQuestions.value}`,
                'Incorrect Question count',
                goToAdjustComplexity
              );

              return;
            }

            const { complexityArray } = props.paperNaviationData.paperComplexity;

            if (
              stateOfComplexity[0] !== complexityArray[0] ||
              stateOfComplexity[1] !== complexityArray[1] ||
              stateOfComplexity[2] !== complexityArray[2] ||
              stateOfComplexity[3] !== complexityArray[3] ||
              stateOfComplexity[4] !== complexityArray[4]
            ) {
              openHandler(
                `Complexity does not match. Please adjust complexity`,
                'Complexity Mismatch',
                goToAdjustComplexity
              );

              return;
            }

            props.setState((ps: any) => {
              return { ...ps, loading: { value: true } };
            });

            if (props.paperNaviationData.mode === 'EDIT') {
              updatePaperRef({
                ...updatePaperDTO,
                status: PaperStatus.PUBLISHED,
                paperType: isMCQ ? PaperTypes.MCQ : PaperTypes.TRUEFALSE,
              })
                .then(({ data }) => {
                  appPapers.fetchMCQsAndTFs();
                  Notification({
                    isSuccess: true,
                    message: 'Paper updated successfully',
                  });

                  props.setPaperNaviationData({
                    ...props.paperNaviationData,
                    mode: 'PREVIEW',
                    paperData: {
                      paperId: data.data.id,
                      unitId: data.data.unitId,
                      lessonId: data.data.lessonId || '',
                      unitIds: data.data.unitIds || [],
                      lessonIds: data.data.lessonIds || [],
                      subjectId: data.data.subjectId,
                      grade: data.data.grade,
                      labels: data.data.questions[0]?.labels || [],
                      createdBy: data.data.createdBy,
                    },
                  });
                })
                .catch((e) => {
                  Notification({
                    isSuccess: false,
                    message: 'Paper creation failed',
                  });
                })
                .finally(() => {
                  props.setState((ps: any) => {
                    return { ...ps, loading: { value: false } };
                  });
                });
            } else {
              createPaperRef({
                ...createPaperDTO,
                status: PaperStatus.PUBLISHED,
                paperType: isMCQ ? PaperTypes.MCQ : PaperTypes.TRUEFALSE,
              })
                .then(({ data }) => {
                  appPapers.fetchMCQsAndTFs();
                  Notification({
                    isSuccess: true,
                    message: 'Paper created successfully',
                  });

                  props.setPaperNaviationData({
                    ...props.paperNaviationData,
                    mode: 'PREVIEW',
                    paperData: {
                      paperId: data.data.id,
                      subjectId: data.data.subjectId,
                      lessonId: data.data.lessonId || '',
                      unitId: data.data.unitId,
                      lessonIds: data.data.lessonIds || [],
                      unitIds: data.data.unitIds || [],
                      labels: data.data.questions[0]?.labels || [],
                      grade: data.data.grade,
                      createdBy: data.data.createdBy,
                    },
                  });
                })
                .catch((e) => {
                  Notification({
                    isSuccess: false,
                    message: 'Paper creation failed',
                  });
                })
                .finally(() => {
                  props.setState((ps: any) => {
                    return { ...ps, loading: { value: false } };
                  });
                });
            }
          }}
        />

        <ButtonComp
          loading={props.state.loading.value}
          type="one"
          text={props.paperNaviationData.mode === 'EDIT' ? 'Update as Draft' : 'Save as Draft'}
          onClick={() => {
            if (!props.state.questions.value.length) {
              openHandler(
                'Question count is zero. Please satisfy total amount of questions',
                'Operation not allowed',
                goToAdjustComplexity
              );
              return;
            }

            if (props.state.questions.value.length !== +props.state.totalNoOfQuestions.value) {
              openHandler(
                `Please satisfy total amount of questions. Needed total amount of questions is ${props.state.totalNoOfQuestions.value}`,
                'Complexity Mismatch',
                goToAdjustComplexity
              );

              return;
            }

            props.setState((ps: any) => {
              return { ...ps, loading: { value: true } };
            });

            if (props.paperNaviationData.mode === 'EDIT') {
              updatePaperRef({
                ...updatePaperDTO,
                status: PaperStatus.DRAFT,
                paperType: isMCQ ? PaperTypes.MCQ : PaperTypes.TRUEFALSE,
              })
                .then(({ data }) => {
                  Notification({
                    isSuccess: true,
                    message: 'Paper updated successfully as a draft',
                  });
                  appPapers.fetchMCQsAndTFs();
                  props.setPaperNaviationData({
                    ...props.paperNaviationData,
                    mode: 'PREVIEW',
                    paperData: {
                      paperId: data.data.id,
                      subjectId: data.data.subjectId,
                      lessonId: data.data.lessonId || '',
                      unitId: data.data.unitId,
                      lessonIds: data.data.lessonIds || [],
                      unitIds: data.data.unitIds || [],
                      labels: data.data.questions[0]?.labels || [],
                      grade: data.data.grade,
                      createdBy: data.data.createdBy,
                    },
                  });
                })
                .catch((e) => {
                  Notification({
                    isSuccess: true,
                    message: 'Failed to updated paper',
                  });
                })
                .finally(() => {
                  props.setState((ps: any) => {
                    return { ...ps, loading: { value: false } };
                  });
                });
            } else {
              createPaperRef({
                ...createPaperDTO,
                status: PaperStatus.DRAFT,
                paperType: isMCQ ? PaperTypes.MCQ : PaperTypes.TRUEFALSE,
              })
                .then(({ data }) => {
                  Notification({
                    isSuccess: true,
                    message: 'Paper created successfully as a draft',
                  });
                  appPapers.fetchMCQsAndTFs();
                  props.setPaperNaviationData({
                    ...props.paperNaviationData,
                    mode: 'PREVIEW',
                    paperData: {
                      paperId: data.data.id,
                      subjectId: data.data.subjectId,
                      lessonId: data.data.lessonId || '',
                      unitId: data.data.unitId,
                      lessonIds: data.data.lessonIds || [],
                      unitIds: data.data.unitIds || [],
                      labels: data.data.questions[0]?.labels || [],
                      grade: data.data.grade,
                      createdBy: data.data.createdBy,
                    },
                  });
                })
                .catch((e) => {
                  Notification({
                    isSuccess: true,
                    message: 'Paper creation failed',
                  });
                })
                .finally(() => {
                  props.setState((ps: any) => {
                    return { ...ps, loading: { value: false } };
                  });
                });
            }
          }}
        />
        <ButtonComp
          loading={props.state.loading.value}
          type="one"
          text="Back"
          outline
          onClick={() => {
            props.setPaperNaviationData({
              ...props.paperNaviationData,
              step: PAPER_BREADCRUM_STEPS.STEP_TWO,
            });
          }}
        />
      </div>
      <AlertPopup
        message={open.message}
        header={open.header}
        isShow={open.show}
        onOk={() => {
          open.action && open.action();
          closeHandler();
        }}
        onClose={closeHandler}
      />
    </div>
  );
};

const MCQTrueFalsePaperPreview: FC<Props> = (props) => {
  const [paper, setPaper] = useState<Paper | null>(null);

  const fetchPaper = async (id: string) => {
    const doc = await PaperService.getPaperById(id);
    if (doc.exists) {
      const paper = { id: doc.id, ...doc.data() } as Paper;

      setPaper(paper);
    }
  };

  useEffect(() => {
    if (props?.paperNaviationData?.paperData?.paperId) {
      fetchPaper(props?.paperNaviationData?.paperData?.paperId);
    }
  }, [props?.paperNaviationData?.paperData?.paperId]);

  return (
    <div className="paper-preview">
      <PaperPreviewHeaderActionBar
        className="preview"
        paperNaviationData={props.paperNaviationData}
        setPaperNaviationData={props.setPaperNaviationData}
        paper={paper}
        style={{ marginTop: 10 }}
        setState={props.setState}
      />
      <div className="paper-preview__main-content">
        <div className="paper-preview__title">
          <h6>Paper Title</h6>
          <div> {paper?.paperTitle}</div>
        </div>

        {paper?.description ? (
          <div className="paper-preview__description">
            <h6>Description</h6>
            <div>{paper?.description}</div>
          </div>
        ) : (
          <></>
        )}

        <div className="paper-preview__data">
          <div className="paper-preview__total-question">
            <h6>Total Number of Questions</h6>
            <div>{paper?.totalNoOfQuestions}</div>
          </div>
          <div className="paper-preview__allocated-time">
            <h6>Allocated Time</h6>
            <div>{suffixAllocatedTime(paper?.allocatedTime || '')}</div>
          </div>
          <div className="paper-preview__p-type">
            <h6>Paper Creation Type</h6>
            <div>{paper?.paperCreationMode}</div>
          </div>
        </div>
        <div className="paper-preview-question-headers d-flex">
          <div>Type</div>
          <div>Title</div>
          <div>Complexity</div>
        </div>
        <div className="paper-preview__question-list">
          {paper?.questions.map((qtn) => (
            <QuestionCard
              key={qtn.id}
              qtn={qtn}
              complexity={qtn.complexity || 0}
              type={qtn.questionType === 'TRUEFALSE' ? 'T/F' : qtn.questionType}
              onClick={() => {}}
              searchText=""
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MCQTrueFalsePapersTab;
