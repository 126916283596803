import React, {FC} from 'react';
import './StudentExamStatisticsItem.scss';

interface Props {
  value?: number;
  title?: string;
  className?: string;
}

const StudentExamStatisticsItem: FC<Props> = (props) => {
  return (
    <div className={`stu-exam-stats-item ${props.className}`}>
      <div className="stu-exam-stats-item__number">{props.value}</div>
      <div className="stu-exam-stats-item__title">{props.title}</div>
    </div>
  );
};

export default StudentExamStatisticsItem;
