import React, { FC, Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ExamCreationStepOne from '../../components/ExamCreationStepOne';
import ExamCreationStepThreeExtra from '../../components/ExamCreationStepThreeExtra';
import ExamCreationStepThreeLive from '../../components/ExamCreationStepThreeLive';
import ExamCreationStepThreePractise from '../../components/ExamCreationStepThreePractise';
import ExamCreationStepTwo from '../../components/ExamCreationStepTwo';
import LessonSecondaryNav from '../../components/LessonSecondaryNav';
import LoadingIndicator from '../../components/LoadingIndicator';
import PrimaryNav from '../../components/PrimaryNav';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import UnitSecondaryNav from '../../components/UnitSecondaryNav';
import { EXAM_CREATION_STEP } from '../../enums';
import { examCreationStateInitialValue, EXAM_CREATION_STATE } from '../../interfaces';
import LayoutNew from '../../layouts/LayoutNew';
import ContentSecondaryNav from '../../modules/content/screen/adminAndInstructor/ContentSecondaryNav';

interface Props {
  level: 'SUBJECT' | 'LESSON' | 'UNIT';
  unitsPresent?: boolean;
}

const ExamsCreateScreen: FC<Props> = (props) => {
  const [examStep, setExamStep] = useState(EXAM_CREATION_STEP.ONE);

  const [examCreationState, setExamCreationState] = useState<EXAM_CREATION_STATE>(
    examCreationStateInitialValue
  );

  const splittedURL = useLocation().pathname.split('/');
  const examType = splittedURL.pop();
  const examTypeV2 = splittedURL[splittedURL.length - 1];

  let text = '';

  if (examType === 'create-extra-exam') {
    text = 'Create Extra Exam';
  } else if (examType === 'create-live-exam') {
    text = 'Create Live Exam';
  } else if (examType === 'create-practise-exam') {
    text = 'Create Practice Exam';
  }

  let primaryNavigation = <PrimaryNav />;
  let secondaryNavigation =
    props.level === 'SUBJECT' ? (
      <SubjectSecondaryNav />
    ) : props.level === 'LESSON' ? (
      props.unitsPresent ? (
        <LessonSecondaryNav />
      ) : (
        <ContentSecondaryNav />
      )
    ) : (
      <UnitSecondaryNav />
    );
  let appHeader = (
    <Fragment>
      <div className="text-white">{text}</div>
      {examCreationState.isLoading.value ? <LoadingIndicator /> : null}
    </Fragment>
  );

  useEffect(() => {
    setExamStep(EXAM_CREATION_STEP.ONE);
  }, []);

  const moveToFirstStepHandler = () => {
    setExamStep(EXAM_CREATION_STEP.ONE);
  };

  const moveToSecondStepHandler = () => {
    setExamStep(EXAM_CREATION_STEP.TWO);
  };

  const moveToThirdStepHandler = () => {
    setExamStep(EXAM_CREATION_STEP.THREE);
  };

  let appBody = (
    <ExamCreationStepOne
      state={examCreationState}
      setState={setExamCreationState}
      onNext={moveToSecondStepHandler}
      hierarchyLevel={props.level}
    />
  );

  if (examStep === EXAM_CREATION_STEP.TWO) {
    appBody = (
      <ExamCreationStepTwo
        state={examCreationState}
        setState={setExamCreationState}
        onNext={moveToThirdStepHandler}
        onBack={moveToFirstStepHandler}
      />
    );
  } else if (
    examStep === EXAM_CREATION_STEP.THREE &&
    (examType === 'create-extra-exam' || examTypeV2 === 'create-extra-exam')
  ) {
    appBody = (
      <ExamCreationStepThreeExtra
        state={examCreationState}
        setState={setExamCreationState}
        onBack={moveToSecondStepHandler}
      />
    );
  } else if (
    examStep === EXAM_CREATION_STEP.THREE &&
    (examType === 'create-live-exam' || examTypeV2 === 'create-live-exam')
  ) {
    appBody = (
      <ExamCreationStepThreeLive
        state={examCreationState}
        setState={setExamCreationState}
        onBack={moveToSecondStepHandler}
      />
    );
  } else if (
    examStep === EXAM_CREATION_STEP.THREE &&
    (examType === 'create-practise-exam' || examTypeV2 === 'create-practise-exam')
  ) {
    appBody = (
      <ExamCreationStepThreePractise
        state={examCreationState}
        setState={setExamCreationState}
        onBack={moveToSecondStepHandler}
      />
    );
  }

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

export default ExamsCreateScreen;
