import {useEffect, useState} from 'react';

export function useUpcomingCount(startDate: Date) {
  const calculateTimeLeft = () => {
    let difference;

    difference = +startDate - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents: any[] = [];

  Object.keys(timeLeft).forEach((interval: string) => {
    /* @ts-ignore */
    timerComponents.push(timeLeft[interval]);
  });

  return timerComponents;
}
