import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useFunctions} from 'reactfire';
import ClipLoader from 'react-spinners/ClipLoader';
import {toast} from 'react-toastify';
import {
  validateEmail,
  validateMaxLength,
  validateMinLength,
  validatePassword,
  validateString,
  validateStringUserRole,
  validateTermAndConditions,
} from '../validation/Validation';
import Notification from './../components/Notification';
import MainLayout from '../layouts/MainLayout';
import PasswordInput from './PasswordInput';
import TextInput from './TextInput';
import DropDown from './DropDown';
import {Role, ROLES_DROPDOWN_OPTIONS} from '../constants';
import {validationResult} from '../utils';
import './SignUp.scss';
// import {FcGoogle} from 'react-icons/fc';
// import firebase from 'firebase';

const AuthSignUpcomp = () => {
  const signUpRef = useFunctions().httpsCallable('signUpUser');

  // const auth = useAuth();
  // const provider = new firebase.auth.GoogleAuthProvider();
  // provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
  // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

  const history = useHistory();

  const initialState = {
    loading: false,
    lastName: {
      value: '',
      error: '',
      validations: [validateString, validateMaxLength, validateMinLength],
      max: 64,
      min: 3,
      label: 'Last name',
    },
    firstName: {
      value: '',
      error: '',
      validations: [validateString, validateMaxLength, validateMinLength],
      max: 64,
      min: 3,
      label: 'First name',
    },
    username: {
      value: '',
      error: '',
      validations: [validateString, validateMaxLength, validateMinLength],
      max: 64,
      min: 3,
      label: 'Username',
    },
    email: {value: '', error: '', validations: [validateEmail], label: 'email'},
    role: {
      value: {displayValue: '', id: ''},
      error: '',
      validations: [validateStringUserRole],
      label: 'Role',
      type: 'dropdown',
    },
    password: {value: '', error: '', validations: [validatePassword], label: 'Password'},
    isTermsAndConditions: {
      value: false,
      error: '',
      validations: [validateTermAndConditions],
      label: 'Terms and Conditions',
    },
  };

  const [state, setState] = useState(initialState);

  const getUserRole = (role: string) => {
    if (role === Role.ADMIN) {
      return {isAdmin: true, isTeachingAssistant: false, isStudent: false, isInstructor: false};
    }
    if (role === Role.TEACHINGASSITANT) {
      return {isAdmin: false, isTeachingAssistant: true, isStudent: false, isInstructor: false};
    }
    if (role === Role.STUDENT) {
      return {isAdmin: false, isTeachingAssistant: false, isStudent: true, isInstructor: false};
    }
    if (role === Role.INSTRUCTOR) {
      return {isAdmin: false, isTeachingAssistant: false, isStudent: false, isInstructor: true};
    }
  };

  const signupHandler = async () => {
    if (toast.isActive('signUpToastId')) {
      return;
    }

    const validationOutput = validationResult({...state, loading: true});

    setState(validationOutput.state);

    if (!!validationOutput.formValidity) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));

      return;
    }

    const {username, email, password, firstName, lastName, isTermsAndConditions} = state;

    try {
      const response = await signUpRef({
        lastName: lastName.value,
        firstName: firstName.value,
        username: username.value,
        email: email.value,
        userRole: getUserRole(state.role.value.id),
        password: password.value,
        isTermsAndConditions: isTermsAndConditions,
      });

      const responseData = response.data;

      if (responseData.status === 500) {
        setState((pS) => ({
          ...pS,
          loading: false,
        }));

        if (responseData.data === 'Email already exists') {
          responseData.data =
            'Duplicate email: You already have a Scrapbook account with this email. Please login with the existing account or use forget password option if you forget your password';
        }

        Notification({
          isSuccess: false,
          message: responseData.data,
          id: 'signUpToastId',
        });
      } else {
        history.push({
          pathname: '/email-verification',
          state: {...responseData.data, action: 'signUp', password: state.password.value},
        });
      }
    } catch (e: any) {
      console.log('ERROR ', e);
      setState((pS) => ({
        ...pS,
        loading: false,
      }));

      Notification({
        isSuccess: false,
        message: 'Something went wrong. Please try again later',
        id: 'signUpToastId',
      });
    }
  };

  // const socialLoginHandler = async () => {
  //   await auth.signInWithPopup(provider);
  //   history.push('/');
  // };

  const enterKeyDownHandler = (evt: any) => {
    if (evt.key === 'Enter') {
      signupHandler();
    }
  };

  const termsAndConditionsHandler = () => {
    setState((ps) => ({
      ...ps,
      isTermsAndConditions: {
        ...ps.isTermsAndConditions,
        value: !ps.isTermsAndConditions.value,
        error: '',
      },
    }));
  };

  return (
    <MainLayout>
      <div className="signup-auth">
        <div className="signup-auth__header">SIGN UP</div>
        <TextInput
          className="auth-input-margin-signup"
          stateName="firstName"
          stateValue={state.firstName.value}
          state={state}
          setState={setState}
          error={state.firstName.error}
          placeHolder="First name"
          onKeyPress={enterKeyDownHandler}
        />
        <TextInput
          className="textinput-margin-signup"
          stateName="lastName"
          stateValue={state.lastName.value}
          state={state}
          setState={setState}
          error={state.lastName.error}
          placeHolder="Last name"
          onKeyPress={enterKeyDownHandler}
        />
        <TextInput
          className="textinput-margin-signup"
          stateName="email"
          stateValue={state.email.value}
          state={state}
          setState={setState}
          error={state.email.error}
          placeHolder="Email"
          onKeyPress={enterKeyDownHandler}
        />

        <DropDown
          name="Role"
          noValueText="Select your role"
          className="textinput-margin-signup"
          stateName="role"
          stateValue={state.role.value}
          state={state}
          setState={setState}
          error={state.role.error}
          optionsArray={ROLES_DROPDOWN_OPTIONS}
        />

        <TextInput
          className="textinput-margin-signup"
          stateName="username"
          stateValue={state.username.value}
          state={state}
          setState={setState}
          error={state.username.error}
          placeHolder="Username"
          onKeyPress={enterKeyDownHandler}
        />
        <PasswordInput
          className="textinput-margin-signup"
          stateName="password"
          stateValue={state.password.value}
          state={state}
          setState={setState}
          error={state.password.error}
          onKeyPress={enterKeyDownHandler}
        />

        <div
          className="signup-auth__remme-forgetpass"
          style={
            state.password.error.includes('Please')
              ? {marginTop: 50}
              : state.password.error.includes('empty')
              ? {marginTop: 30}
              : {}
          }
        >
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="pretty p-smooth p-svg p-curve mr-1"
              style={{paddingBottom: 2, marginTop: 2}}
            >
              <input
                type="checkbox"
                checked={state.isTermsAndConditions.value}
                onChange={termsAndConditionsHandler}
              />
              <div className="state p-primary">
                <svg className="svg svg-icon" viewBox="0 0 20 20">
                  <path
                    d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                    style={{stroke: 'white', fill: 'white'}}
                  />
                </svg>
                <label
                  className={state.isTermsAndConditions.error ? 'checkbox-highlighted' : ''}
                  style={{fontWeight: 500}}
                >
                  Accept
                </label>
              </div>
            </div>
            <Link to="/signup" className="terms" style={{fontWeight: 500}}>
              Terms and Conditions
            </Link>
            {/* <span
              className="and mr-1 ml-1"
              onClick={termsAndConditionsHandler}
              style={{fontWeight: 500}}
            >
              
            </span>
            <Link to="/" className="conditions" style={{fontWeight: 500}}>
              
            </Link> */}
          </div>
          {state.isTermsAndConditions.error ? (
            <div
              style={{
                textAlign: 'right',
                maxWidth: 163,

                fontSize: 12,
                lineHeight: 2,
                fontWeight: 400,
              }}
              className="m-auto text-danger"
            >
              {state.isTermsAndConditions.error !== 'NO-ERROR' && state.isTermsAndConditions.error}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="signup-auth__buttons">
          <button
            className={`signup-btn ${state.loading && 'signup-btn-dis'}`}
            onClick={() => {
              !state.loading && signupHandler();
            }}
            style={{marginTop: 10}}
          >
            <span className="d-inline-block mr-2">Sign Up</span>{' '}
            <ClipLoader color="purple" loading={state.loading} size={18} />
          </button>
          {/* <button className="google-btn" onClick={socialLoginHandler}>
            <FcGoogle size={20} />
            <div>Sign up with Google</div>
          </button> */}
        </div>
        <Link to="/signin" className="signup-auth__create-atn">
          Already have an account
        </Link>
      </div>
    </MainLayout>
  );
};

export default AuthSignUpcomp;
