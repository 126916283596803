import React, {FC} from 'react';

import './TAExamCardExtra.scss';

interface Props {
  onClick: () => void;
  examTitle?: string;
  assignedTime?: firebase.firestore.Timestamp;
}

const TAExamCardExtraMarkingCompleted: FC<Props> = (props) => {
  // const date = convertTimeStampToDate(props.assignedTime);
  // const formatedDate = moment(date).format('lll');

  return (
    <div className="TA-assigned-papers-extra-exam" onClick={props.onClick}>
      <div className="TA-assigned-papers-extra-exam__exam-card">
        <div className="TA-assigned-papers-extra-exam__exam-card-exam-content">
          <div className="TA-assigned-papers-extra-exam__exam-card-exam-content-title">
            <span>{props.examTitle}</span>
          </div>
          {/* <div className="TA-assigned-papers-extra-exam__exam-card-exam-content-exam-assignedon">
            <span>Assigned on {formatedDate}</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TAExamCardExtraMarkingCompleted;
