import React, { FC, useEffect, useState } from 'react';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FaArchive, FaEdit, FaTrashAlt } from 'react-icons/fa';
import Notification from './../components/Notification';
import './PaperPreviewHeaderActionBar.scss';
import firebase from 'firebase';
import { PAPER_BREADCRUM_STEPS } from '../enums/PaperBreadCrum';
import { PaperData, ResourcePaperState } from '../interfaces';
import { Paper } from '../models/Paper';
import { useAppUser } from '../context/UserContext';
import { usePapers } from '../context/PapersContext';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
import BoxIcon from './BoxIcon';
import Backarrow from './Backarrow';
import ResourceUtility from '../utils/ResourceUtility';
import truncate from 'truncate';
import LoadingIndicator from './LoadingIndicator';
import AlertPopup from './AlertPopup';
import { useUnits } from '../context/UnitsContext';
import { useLessons } from '../context/LessonsContext';
import { Unit } from '../models/Unit';
import { Lesson } from '../models/Lesson';

interface Props {
  className?: string;
  paperNaviationData: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
    paperComplexity: { complexityArray: number[]; isComplexityCorrect: boolean };
  };
  setPaperNaviationData: (para: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
    paperComplexity: { complexityArray: number[]; isComplexityCorrect: boolean };
  }) => void;
  paper: Paper | null;
  style?: React.CSSProperties;
  setState: React.Dispatch<React.SetStateAction<ResourcePaperState>>;
}

const PaperPreviewHeaderActionBar: FC<Props> = (props) => {
  const { firestoreUser } = useAppUser();
  const appPapers = usePapers();
  const appLessons = useLessons();
  const appUnits = useUnits();
  const appAdminsAndInstructors = useAdminsAndInstructors();
  const [state, setState] = useState({
    unit: '',
    lesson: '',
    units: [] as Unit[],
    lessons: [] as Lesson[],
    subject: '',
    loading: false,
    author: '',
  });
  const [show, setShow] = useState(false);

  const showHandler = () => {
    setShow(true);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const deletePaperRef = firebase.functions().httpsCallable('deletePaper');

  const setLoading = (val: boolean) => {
    setState((ps) => {
      return { ...ps, loading: val };
    });
  };

  const onPaperDelete = async (pid: string) => {
    setLoading(true);
    try {
      const { data } = await deletePaperRef({
        pid: pid,
      });

      appPapers.removeById(data.data);

      Notification({
        isSuccess: true,
        message: 'Paper deleted successfully',
      });

      props.setPaperNaviationData({
        ...props.paperNaviationData,
        mode: 'VIEW',
        step: PAPER_BREADCRUM_STEPS.STEP_ONE,
        paperData: {
          subjectId: '',
          lessonId: '',
          unitId: '',
          lessonIds: [],
          unitIds: [],
          createdBy: '',
          grade: '',
          paperId: '',
        },
      });

      setLoading(false);
    } catch (e) {
      Notification({
        isSuccess: false,
        message: 'Failed to delete paper',
      });
      props.setPaperNaviationData({
        ...props.paperNaviationData,
        mode: 'VIEW',
        step: PAPER_BREADCRUM_STEPS.STEP_ONE,
        paperData: {
          subjectId: '',
          lessonId: '',
          unitId: '',
          lessonIds: [],
          unitIds: [],
          createdBy: '',
          grade: '',
          paperId: '',
        },
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (props.paper?.subjectId) {
      const docRef = firebase.firestore().collection('subjects').doc(props.paper?.subjectId);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setState((ps) => {
              return { ...ps, subject: doc.data()?.name };
            });
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error getting document:', error);
          setLoading(false);
        });
    }
  }, [props.paper?.subjectId]);

  const lessons = appLessons.lessons.filter((l) => props.paper?.lessonIds?.includes(l.id || ''));
  const units = appUnits.units.filter((u) => props.paper?.unitIds?.includes(u.id || ''));

  useEffect(() => {
    setLoading(true);

    if (props.paper?.createdBy) {
      firebase
        .firestore()
        .collection('users')
        .get()
        .then((doc) => {
          doc.forEach(() => {});

          setLoading(false);
        })
        .catch((error) => {
          console.log('Error getting document:', error);
          setLoading(false);
        });
    }
  }, [props.paper?.createdBy]);

  let action = (
    <Col className="d-flex" xs={2} style={{ justifyContent: 'flex-end' }}>
      <BoxIcon
        icon={FaArchive}
        color="#fff"
        size={13}
        style={{ width: 30, height: 30, backgroundColor: '#404462' }}
        className="ml-auto"
      />
    </Col>
  );

  if (firestoreUser?.userRole?.isAdmin || firestoreUser?.uid === props?.paper?.createdBy) {
    action = (
      <Col className="d-flex" xs={2} style={{ justifyContent: 'flex-end' }}>
        <BoxIcon
          icon={FaArchive}
          color="#fff"
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#404462', marginLeft: '12%' }}
        />

        <BoxIcon
          icon={FaEdit}
          color="#fff"
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#404462', marginLeft: '12%' }}
          onClick={() => {
            props.setPaperNaviationData({
              ...props.paperNaviationData,
              mode: 'EDIT',
              step: PAPER_BREADCRUM_STEPS.STEP_ONE,
            });
          }}
        />

        <BoxIcon
          icon={FaTrashAlt}
          color="#fff"
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: '12%' }}
          onClick={() => {
            showHandler();
          }}
        />
      </Col>
    );
  }

  const authorName =
    appAdminsAndInstructors.allAdminsAndInstructors.find(
      (user) => user.uid === props.paper?.createdBy
    )?.username || '';

  return (
    <Container
      fluid
      className={`px-0 ${props.className} preview-header`}
      style={{ ...props.style }}
    >
      <Row>
        <Col className="d-flex" xs={2} style={{ overflow: 'hidden' }}>
          <Backarrow
            onClick={() => {
              props.setState(ResourceUtility.INITIAL_STATE_PAPER);
              props.setPaperNaviationData({ ...props.paperNaviationData, mode: 'VIEW' });
            }}
          />
          <div className="pl-4 ml-3">
            <div className="preview-header-ab-t">Grade</div>
            <div className="preview-header-ab-b">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="verifiedId">{props.paper?.grade}</Tooltip>}
              >
                <div>{truncate(props.paper?.grade || '', 20)}</div>
              </OverlayTrigger>
            </div>
          </div>
        </Col>
        <Col xs={2} style={{ overflow: 'hidden' }}>
          <div className="preview-header-ab-t">Subject</div>
          <div className="preview-header-ab-b">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="verifiedId">{state.subject}</Tooltip>}
            >
              <div>{truncate(state.subject, 35)}</div>
            </OverlayTrigger>
          </div>
        </Col>
        <Col xs={2} style={{ overflow: 'hidden' }}>
          <div className="preview-header-ab-t">Lessons</div>
          <div className="preview-header-ab-b">
            {lessons.length ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="verifiedId">
                    {lessons.map((u, idx) => (
                      <span className="mr-1 " key={idx}>
                        {' '}
                        {u.name + (lessons.length - 1 !== idx ? ' ,' : '')}
                      </span>
                    ))}
                  </Tooltip>
                }
              >
                {/* <div>{truncate(state.units, 35)}</div> */}
                <div>
                  {' '}
                  {lessons.map((u, idx) => (
                    <span className="mr-1 " key={idx}>
                      {' '}
                      {truncate(u.name + (lessons.length - 1 !== idx ? ' ,' : ''), 8)}
                    </span>
                  ))}
                </div>
              </OverlayTrigger>
            ) : (
              <div style={{ width: 40, textAlign: 'center' }}>-</div>
            )}
          </div>
        </Col>
        <Col xs={2} style={{ overflow: 'hidden' }}>
          <div className="preview-header-ab-t">Units</div>
          <div className="preview-header-ab-b">
            {units.length ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="verifiedId">
                    {units.map((u, idx) => (
                      <span className="mr-1 " key={idx}>
                        {' '}
                        {u.name + (units.length - 1 !== idx ? ' ,' : '')}
                      </span>
                    ))}
                  </Tooltip>
                }
              >
                {/* <div>{truncate(state.units, 35)}</div> */}
                <div>
                  {' '}
                  {units.map((u, idx) => (
                    <span className="mr-1 " key={idx}>
                      {' '}
                      {truncate(u.name + (units.length - 1 !== idx ? ' ,' : ''), 8)}
                    </span>
                  ))}
                </div>
              </OverlayTrigger>
            ) : (
              <div style={{ width: 40, textAlign: 'center' }}>-</div>
            )}
          </div>
        </Col>
        <Col xs={1} style={{ overflow: 'hidden' }}>
          <div className="preview-header-ab-t">Author</div>
          <div className="preview-header-ab-b">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="verifiedId">{authorName}</Tooltip>}
            >
              <div>{truncate(authorName || '', 14)}</div>
            </OverlayTrigger>
          </div>
        </Col>
        <Col xs={1} className="text-center" style={{ overflow: 'hidden' }}>
          <div className="preview-header-ab-t">Marks</div>
          <div className="preview-header-ab-b">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="verifiedId">{props.paper?.marks}</Tooltip>}
            >
              <div>{truncate(props?.paper?.marks || '', 20)}</div>
            </OverlayTrigger>
          </div>
        </Col>
        {action}
      </Row>
      {state.loading && (
        <Row>
          <Col>
            <LoadingIndicator />
          </Col>
        </Row>
      )}

      <AlertPopup
        message="Are you sure you want to delete this paper?"
        header="Attention"
        isShow={show}
        primaryButtonText="Confirm"
        onOk={() => {
          onPaperDelete(props?.paper?.id || '');
        }}
        onClose={closeHandler}
      />
    </Container>
  );
};

export default PaperPreviewHeaderActionBar;
