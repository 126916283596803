import React from 'react';
import {useParams} from 'react-router-dom';
import ViewNotices from '../../components/ViewNotices';

const TASubjectAnnouncementScreen = () => {
  const params = useParams() as {subjectId: string};

  return <ViewNotices subjectid={params.subjectId} />;
};

export default TASubjectAnnouncementScreen;
