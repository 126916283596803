import React, { useEffect, useState } from 'react';

import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';

import StudentLessonListScreen from './StudentLessonListScreen';
import StudentModalPapersScreen from './StudentModalPapersScreen';
import StudentExamStartScreen from './StudentExamStartScreen';
import StudentSecondaryNavWithLegacyCode from '../../components/StudentSecondaryNavWithLegacyCode';
import { useAppUser } from '../../context/UserContext';
import { ExamToDoDTO } from '../../dtos/ExamToDoDTO';
import { SUBJECT_TAB } from '../../constants';
import PageNotFoundScreen from '../common/PageNotFoundScreen';
import StudentPackageListScreen from './StudentPackageListScreen';
import StudentSubjectHomeScreen from './StudentSubjectHomeScreen';
import { useFirestore } from 'reactfire';
import StudentSubjectAnnouncementsScreen from './StudentSubjectAnnouncementsScreen';

const StudentSubjectViewScreen = () => {
  const store = useFirestore();

  const history = useHistory();

  const appUser = useAppUser();

  const params = useParams() as { subjectId: string; examId: string };

  const [loading, setLoading] = useState(false);

  const setSelectedExamsHandlers = (examination: ExamToDoDTO | null) => {
    setLoading(true);

    const examRef = store
      .collection('exams')
      .doc(examination?.id || examination?.examId || '')
      .get();
    const completedExamRef = store
      .collection('completedExams')
      .where('examId', '==', examination?.id || examination?.examId || '')
      .where('userId', '==', appUser.firestoreUser?.uid || '')
      .get();

    completedExamRef
      .then((completedExamResponse) => {
        examRef
          .then((examResponse) => {
            const exam = examination
              ? ({ id: examination.id, examId: examination.id, ...examination } as any)
              : {};
            examResponse.ref
              .collection('userExamCommencedTime')
              .get()
              .then((res) => {
                const list: any[] = res.docs.map((d) => ({ ...d.data() }));
                const hasUserStartedTheExam = list.find(
                  (u) => u.userId === appUser.firestoreUser?.uid || ''
                );
                exam.usersExamState = list;

                if (!completedExamResponse.empty) {
                  history.push({
                    pathname: `/my-subjects/subject/${params.subjectId}/exam/result/${
                      exam?.id || exam?.examId
                    }`,
                    state: { exam: exam },
                  });
                } else if (hasUserStartedTheExam) {
                  history.push({
                    pathname: `/my-subjects/subject/${params.subjectId}/exam/${
                      exam?.id || exam?.examId
                    }`,
                    state: { exam: exam },
                  });
                } else {
                  history.push({
                    pathname: `/my-subjects/subject/${params.subjectId}/Examinations/${
                      exam?.id || exam?.examId
                    }`,
                    state: { exam: exam },
                  });
                }

                setLoading(false);
              })
              .catch(() => {
                console.log('Error');
                setLoading(false);
              });
          })
          .catch(() => {
            console.log('Error');
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log('Error');
        setLoading(false);
      });
  };

  const [state, setState] = useState({
    currentTab: SUBJECT_TAB.LESSONS,
    currentSubTab: 'Live Exams',
  });

  const changeCurrentTabHandler = (tab: string) => {
    let setText = tab;
    if (tab === 'Model Papers') {
      setText = 'Examinations';
    }

    history.push(`/my-subjects/subject/${params.subjectId}/${setText}`);

    setState({ ...state, currentTab: tab });
  };

  const changeCurrentSubTabHandler = (subTab: string) => {
    // setState({...state, currentSubTab: subTab});
    history.push(`/my-subjects/subject/${params.subjectId}/Examinations?name=${subTab}`);
  };

  const location = useLocation();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    const array = location.pathname.split('/');
    const endSegment = array[array.length - 1];

    if (endSegment === SUBJECT_TAB.LESSONS) {
      setState((ps) => {
        return { ...ps, currentTab: SUBJECT_TAB.LESSONS };
      });
    } else if (endSegment === SUBJECT_TAB.MODAL_PAPERS) {
      setState((ps) => {
        return { ...ps, currentTab: SUBJECT_TAB.MODAL_PAPERS };
      });
    } else if (endSegment === SUBJECT_TAB.ANNOUNCEMENTS) {
      setState((ps) => {
        return { ...ps, currentTab: SUBJECT_TAB.ANNOUNCEMENTS };
      });
    } else if (endSegment === SUBJECT_TAB.SETTINGS) {
      setState((ps) => {
        return { ...ps, currentTab: SUBJECT_TAB.SETTINGS };
      });
    } else if (endSegment === SUBJECT_TAB.SUBJECT_HOME) {
      setState((ps) => {
        return { ...ps, currentTab: SUBJECT_TAB.SUBJECT_HOME };
      });
    }

    if (query.get('name') === 'Extra Exams' || location.state === 'extra') {
      setState((ps) => {
        return { ...ps, currentSubTab: 'Extra Exams' };
      });
    } else if (query.get('name') === 'Practice Exams' || location.state === 'practice') {
      setState((ps) => {
        return { ...ps, currentSubTab: 'Practice Exams' };
      });
    } else if (query.get('name') === 'Live Exams' || location.state === 'live') {
      setState((ps) => {
        return { ...ps, currentSubTab: 'Live Exams' };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const secNav = (
    <StudentSecondaryNavWithLegacyCode
      onCurrentTabChange={changeCurrentTabHandler}
      onCurrentSubTabChange={changeCurrentSubTabHandler}
      currentTab={state.currentTab}
      currentSubTab={state.currentSubTab}
      screen={''}
    />
  );

  return (
    <Switch>
      <Route exact path="/my-subjects/subject/:subjectId/Subject Home">
        <StudentSubjectHomeScreen secondaryNav={secNav} />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Lessons">
        <StudentLessonListScreen secondaryNav={secNav} />
      </Route>
      <Route
        exact
        path="/my-subjects/subject/:subjectId/Examinations/:examId"
        component={() => {
          return (
            <StudentExamStartScreen
              secondaryNav={secNav}
              setSelectedExamsHandlers={setSelectedExamsHandlers}
            />
          );
        }}
      />
      <Route exact path="/my-subjects/subject/:subjectId/Examinations">
        <StudentModalPapersScreen
          secondaryNav={secNav}
          selectedSubMenuItem={state.currentSubTab}
          setSelectedExamsHandlers={setSelectedExamsHandlers}
          loading={loading}
        />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Announcements">
        <StudentSubjectAnnouncementsScreen secondaryNav={secNav} />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Packages">
        <StudentPackageListScreen />
      </Route>
      <Route path="*" component={PageNotFoundScreen} />
    </Switch>
  );
};

export default StudentSubjectViewScreen;
