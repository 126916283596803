import React from 'react';
import { ExamToDoDTO } from '../dtos/ExamToDoDTO';
import { ExamProgressState } from '../enums/ExamProgressState';
import { ExamType } from '../enums/ExamType';
import StudentLiveExamCard from './StudentLiveExamCard';
import './StudentLiveExamView.scss';

const StudentLiveExamFilter = (props: {
  ExamsList: any[];
  isLoading: boolean;
  setSelectedExamsHandlers?: (exam: ExamToDoDTO | null) => void;
  filterValue: string;
  className: string;
}) => {
  return (
    <div className={props.className}>
      {props.ExamsList.filter((ex: { examType: ExamType }) => ex.examType === ExamType.LIVE_EXAM)
        .filter((text, index, array) => {
          if (
            props.filterValue === '' ||
            props.filterValue === null ||
            props.filterValue === undefined
          ) {
            return array;
          } else {
            return text.examTitle
              .toLowerCase()
              .trim()
              .includes(props.filterValue.toLowerCase().trim());
          }
        })
        .sort((a, b) => {
          return new Date(b.finishTime).getTime() - new Date(a.finishTime).getTime();
        })
        .map((exam: ExamToDoDTO, idx: React.Key | null | undefined) => {
          return (
            <StudentLiveExamCard
              type={ExamProgressState.COMPLETED}
              exam={exam}
              isLoading={props.isLoading}
              key={idx}
            />
          );
        })}
    </div>
  );
};

export default StudentLiveExamFilter;
