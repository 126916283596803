import React, {FC} from 'react';
import './StudentExamStatistics.scss';
import StudentExamStatisticsItem from './StudentExamStatisticsItem';

interface Props {
  className?: string;
  entrolled: number;
  submitted: number;
  notSubmitted: number;
}

const StudentExamStatistics: FC<Props> = (props) => {
  return (
    <div className={`stu-exam-stats ${props.className}`}>
      <div className="stu-exam-stats__section">
        <StudentExamStatisticsItem
          value={props.entrolled}
          title="Number of students enrolled to the Exam"
        />
        <StudentExamStatisticsItem className="ml-auto" />
      </div>
      <div className="stu-exam-stats__section">
        <StudentExamStatisticsItem value={props.submitted} title="Number of students submitted" />
        <StudentExamStatisticsItem className="ml-auto" />
      </div>
      <div className="stu-exam-stats__section">
        <StudentExamStatisticsItem
          value={props.notSubmitted}
          title="Number of students not submitted"
        />
        <StudentExamStatisticsItem className="ml-auto" />
      </div>
      {/* <div className="stu-exam-stats__section">
        <StudentExamStatisticsItem />
        <StudentExamStatisticsItem className="ml-auto" />
      </div> */}
    </div>
  );
};

export default StudentExamStatistics;
