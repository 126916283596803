import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EXAM_TYPE_TABS } from '../enums';
import { getUrlsForExamTypeTabs } from '../utils';
import './ExamTypesTabs.scss';

interface Props {
  tab: EXAM_TYPE_TABS;
  onTabChangeClearFilters: () => void;
  presentUnits?: boolean;
}

interface Props {
  subjectId?: string;
  lessonId?: string;
  unitId?: string;
}

const ExamTypesTabs: FC<Props> = (props) => {
  const { LIVE, PRACTISE, EXTRA } = EXAM_TYPE_TABS;

  const history = useHistory();
  const params = useParams() as { subjectId: string; lessonId: string; unitId: string };

  return (
    <div className="et-tabs">
      <div
        className={`et-tabs__item ${props.tab === LIVE && 'et-tabs__item--active'}`}
        onClick={() => {
          history.push(
            getUrlsForExamTypeTabs(
              params?.subjectId || props.subjectId,
              params?.lessonId || props.lessonId,
              params?.unitId || props.unitId,
              LIVE,
              props.presentUnits
            )
          );
          props.onTabChangeClearFilters();
        }}
      >
        Live Exam
      </div>
      <div
        className={`et-tabs__item  ${props.tab === PRACTISE && 'et-tabs__item--active'}`}
        onClick={() => {
          history.push(
            getUrlsForExamTypeTabs(
              params?.subjectId || props.subjectId,
              params?.lessonId || props.lessonId,
              params?.unitId || props.unitId,
              PRACTISE,
              props.presentUnits
            )
          );
          props.onTabChangeClearFilters();
        }}
      >
        Practice Exam
      </div>
      <div
        className={`et-tabs__item  ${props.tab === EXTRA && 'et-tabs__item--active'}`}
        onClick={() => {
          history.push(
            getUrlsForExamTypeTabs(
              params?.subjectId || props.subjectId,
              params?.lessonId || props.lessonId,
              params?.unitId || props.unitId,
              EXTRA,
              props.presentUnits
            )
          );
          props.onTabChangeClearFilters();
        }}
      >
        Extra Exam
      </div>
    </div>
  );
};

export default ExamTypesTabs;
