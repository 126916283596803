import {FC, useEffect, useState} from 'react';
import {FaTrashAlt} from 'react-icons/fa';
import './AssignSubjectsCardsList.scss';
import {useFunctions} from 'reactfire';
import {UserFirestore} from '../models/UserFirestore';
import BoxIcon from './BoxIcon';
import Notification from './../components/Notification';

interface Props {
  users: UserFirestore[];
  subjectId: string;
  fetchUsers: () => void;
  onRemove: (id: string) => void;
  setLoading: (value: boolean) => void;
}

const AssignSubjectsCardsList: FC<Props> = (props) => {
  const removeAssignSubjectsFromInstructorAndTARef = useFunctions().httpsCallable(
    'removeAssignSubjectsFromInstructorAndTA'
  );

  const [users, setUsers] = useState<UserFirestore[]>([]);

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  return (
    <ul className="assign-subject-card-list">
      {users.map((u, idx) => (
        <li
          className="assign-subject-card-list-item d-flex justify-content-between align-items-center"
          key={idx}
        >
          <div className="assign-subject-card-list-item__name">{u.username}</div>
          <div className="assign-subject-card-list-item__icon">
            <BoxIcon
              icon={FaTrashAlt}
              color="#fff"
              size={13}
              style={{width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: '12%'}}
              onClick={() => {
                props.setLoading(true);

                removeAssignSubjectsFromInstructorAndTARef({
                  assignedSubject: props.subjectId,
                  uid: u.uid,
                })
                  .then((data) => {
                    props.onRemove(data.data.data);

                    Notification({
                      isSuccess: true,
                      message: `Removed ${u.username} from the subject`,
                      id: 'tid',
                    });
                    props.setLoading(false);
                    // props.fetchUsers();
                  })
                  .catch(() => {
                    props.setLoading(false);
                    Notification({
                      isSuccess: false,
                      message: `Error Removing ${u.username} from the subject`,
                      id: 'tid',
                    });
                  });
              }}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default AssignSubjectsCardsList;
