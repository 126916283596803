import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import firebase, {auth} from 'firebase/app';
import {useAuth, useFirestore} from 'reactfire';
import {UserFirestore} from '../models/UserFirestore';

export interface UserContext {
  fireUser: firebase.User | null | undefined;
  firestoreUser: UserFirestore | null;
  setCurrentUser: (user: firebase.User | null) => void;
  setUserFirestore: (user: UserFirestore | null) => void;
  signIn: (email: string, password: string) => Promise<firebase.User | null>;
  signInWithUsername: (
    value: string,
    password: string,
    history: any
  ) => Promise<firebase.User | null>;
  resetPassword: (email: string) => Promise<firebase.User | null>;
  logOut: () => Promise<any>;
}

interface State {
  fireUser: firebase.User | null | undefined;
  firestoreUser: UserFirestore | null;
}

const initialContext: UserContext = {
  fireUser: undefined,
  firestoreUser: null,
  setCurrentUser: (user: firebase.User | null) => {},
  setUserFirestore: (user: UserFirestore | null) => {},
  signIn: (email: string, password: string): any => {},
  signInWithUsername: (value: string, password: string, history: any): any => {},
  resetPassword: (email: string): any => {},
  logOut: (): any => {},
};

const initialState: State = {
  fireUser: undefined,
  firestoreUser: null,
};

const Context = createContext<UserContext>(initialContext);

export const UserProvider: FC = ({children}) => {
  const auth = useAuth();
  const [state, setState] = useState(initialState);

  const signIn = async (email: string, password: string): Promise<any> => {
    return (await auth.signInWithEmailAndPassword(email, password)).user;
  };

  const firestore = useFirestore();

  const signInWithUsername = async (
    value: string,
    password: string,
    history: any
  ): Promise<any> => {
    const signInRequest = firebase.functions().httpsCallable('signInRequest');

    try {
      const val = await signInRequest(value);

      if (val.data.isUserActive) {
        try {
          await firebase.auth().signInWithEmailAndPassword(val.data.userEmail, password);
          history.push('/');
        } catch (e: any) {
          throw new Error(e);
        }
      } else {
        return history.push({
          pathname: '/email-verification',
          state: {
            email: val.data.userEmail,
            uid: val.data.userUid,
            action: 'signIn',
            password: password,
          },
        });
      }
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const logOut = async (): Promise<any> => {
    auth.signOut();
    sessionStorage.removeItem('SUBJECT');
    sessionStorage.removeItem('ACCOUNT');
    sessionStorage.removeItem('grade');
    sessionStorage.removeItem('EXAM-TABS');
    sessionStorage.removeItem('subjectId');
    sessionStorage.removeItem('SELECTED-EX');
    sessionStorage.removeItem('RESOURCE');
    sessionStorage.removeItem('SETTINGS');
    sessionStorage.removeItem('SUBJECTS');
  };

  const resetPassword = async (email: string): Promise<any> => {
    return auth.sendPasswordResetEmail(email);
  };

  const setCurrentUser = (user: firebase.User | null) => {
    setState((pS) => ({
      ...pS,
      fireUser: user,
    }));
  };

  const setUserFirestore = (user: UserFirestore | null) => {
    setState((pS) => ({
      ...pS,
      firestoreUser: user,
    }));
  };

  useEffect(() => {
    let unsub: any = null;

    if (state.firestoreUser?.id) {
      unsub = firestore
        .collection('users')
        .doc(state.firestoreUser?.id)
        .onSnapshot((sp) => {
          const user = {id: sp.id, ...sp.data()} as UserFirestore;
          const enabled = user?.enabled;

          if (enabled === false) {
            firebase.auth().signOut();
          }
          if (user) {
            setUserFirestore(user);
          }
        });
    }

    return () => {
      unsub && unsub();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, state.firestoreUser?.id]);

  useEffect(() => {
    const unSubscribe = firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    const userId = firebase.auth().currentUser?.uid;

    firestore
      .collection('users')
      .where('uid', '==', userId || '')
      .get()
      .then((data) => {
        if (data?.docs[0]) {
          const docData = (data.docs[0]?.data() as UserFirestore) || null;

          const userData = {id: data.docs[0]?.id, ...docData};

          setUserFirestore(userData);
        }
      })
      .catch((e) => {
        console.log('Error ', e);
      });

    return unSubscribe;
  }, [state.fireUser, firestore]);

  return (
    <Context.Provider
      value={{
        ...state,
        setCurrentUser,
        setUserFirestore,
        signIn,
        signInWithUsername,
        resetPassword,
        logOut,
      }}
    >
      <>
        <SESSION />
        {children}
      </>
    </Context.Provider>
  );
};

const SESSION: FC = () => {
  const parseRemeberMe = () => {
    let rememberMe = false;
    try {
      let storedValue = JSON.parse(localStorage.getItem('rememberMe')!);

      if (typeof rememberMe === 'boolean') {
        return (rememberMe = storedValue);
      } else {
        localStorage.setItem('rememberMe', JSON.stringify(false));
        return false;
      }
    } catch (e) {
      localStorage.setItem('rememberMe', JSON.stringify(false));
      return false;
    }
  };

  if (parseRemeberMe()) {
    firebase.auth().setPersistence(auth.Auth.Persistence.LOCAL);
  } else {
    firebase.auth().setPersistence(auth.Auth.Persistence.SESSION);
  }

  return <></>;
};

export const useAppUser = (): UserContext => useContext(Context);
