import {Button, Modal} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

const PackageViewDeleteModal = (props: {
  deletePackageData(id: string): unknown;
  packageId: string;
  modalHandler: (arg0: boolean) => any;
  show: boolean | undefined;
}) => {
  const handleClose = () => props.modalHandler(false);
  const handleDelete = (id: string) => {
    props.deletePackageData(id);
  };

  const history = useHistory();
  const lastIndex = history.location.pathname.lastIndexOf('/');
  const path = history.location.pathname.slice(0, lastIndex);
  const style = {background: '#181924', color: '#ffff', borderColor: '#1F2130'};
  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton style={style}>
          <Modal.Title>Package Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body style={style}>Are you sure you want to delete this package?</Modal.Body>
        <Modal.Footer style={style}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleClose();
              handleDelete(props.packageId);
              history.push(path);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PackageViewDeleteModal;
