import React, { FC, Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Unit } from '../models/Unit';
import { FaEllipsisV } from 'react-icons/fa';
import './UnitCard.scss';
import CreateUnitModel from './CreateUnitModel';
import { useFirestore, useFunctions } from 'reactfire';
import { useUnits } from '../context/UnitsContext';
import Notification from './Notification';
import AlertPopup from './AlertPopup';
import UnitCardOptions from './UnitCardOptions';
import { useAppContent } from '../modules/content/context/ContentContext';

interface Props {
  unit: Unit;
  backgroundStyle?: string;
  loadingHandler?: (value: boolean) => void;
}

const UnitCard: FC<Props> = (props) => {
  const appContent = useAppContent();
  const history = useHistory();
  const { subjectId, lessonId } = useParams() as { subjectId: string; lessonId: string };

  const appUnits = useUnits();
  const [collapse, setCollapse] = useState(false);

  const deleteUnitRef = useFunctions().httpsCallable('deleteUnit');
  const store = useFirestore();

  const [modalState, setModalState] = useState({
    notDeletableText: '',
    deletableText: '',
    openUpdateModal: false,
  });

  const openUpdateModaHandler = (value: boolean) => {
    setModalState((ps) => ({
      ...ps,
      openUpdateModal: value,
    }));
  };

  const deletableTexttHandler = (value: string) => {
    setModalState((ps) => ({
      ...ps,
      deletableText: value,
    }));
  };

  const clearDeletableTexttHandler = () => {
    setModalState((ps) => ({
      ...ps,
      deletableText: '',
    }));
  };

  const notDeletableTextHandler = (value: string) => {
    setModalState((ps) => ({
      ...ps,
      notDeletableText: value,
    }));
  };

  const clearNotDeletableTextHandler = () => {
    setModalState((ps) => ({
      ...ps,
      notDeletableText: '',
    }));
  };

  const isUnitDeletable = () => {
    props.loadingHandler && props.loadingHandler(true);

    return store
      .collection('questions')
      .where('unitId', '==', props.unit.id)
      .get()
      .then((data) => {
        if (data.size) {
          notDeletableTextHandler(
            'This unit is not deletable because there are questions associated with it'
          );
          props.loadingHandler && props.loadingHandler(false);
          return false;
        } else {
          deletableTexttHandler('Are you sure you want to delete this unit?');
          props.loadingHandler && props.loadingHandler(false);
          return true;
        }
      })
      .catch(() => {
        return false;
      });
  };

  const onUnitDelete = async (unitId: string) => {
    props.loadingHandler && props.loadingHandler(true);

    try {
      await deleteUnitRef({
        unitId: unitId,
      });

      appUnits.removeUnit(unitId);

      Notification({
        isSuccess: true,
        message: 'Unit deleted successfully',
      });

      props.loadingHandler && props.loadingHandler(false);
    } catch (e) {
      console.log('ERROR: ', e);

      Notification({
        isSuccess: false,
        message: 'Failed to delete the unit',
      });

      props.loadingHandler && props.loadingHandler(false);
    }
  };

  return (
    <Fragment>
      <div
        className="unit1-card"
        onClick={() => {
          if (props.unit.id) appUnits.setActiveUnitFun(props.unit.id);

          appContent.setPreviousPathToPreview(
            `/my-subjects/subject/${subjectId}/lesson/${lessonId}/unit/${props.unit.id}/Content`
          );

          history.push(
            `/my-subjects/subject/${subjectId}/lesson/${lessonId}/unit/${props.unit.id}/Content`
          );
        }}
      >
        <div className="d-flex">
          <div>
            <img
              className="unit1-card__img"
              src={
                props.unit.coverImage.length >= 7
                  ? props.unit.coverImage
                  : 'https://img.freepik.com/free-vector/lesson-concept-illustration_114360-7917.jpg?w=2000'
              }
              alt={props.unit.name || ''}
            />
          </div>
          <div className="unit1-card__set-space">
            <div className="unit1-card__content">
              <div className="unit1-card__name">{props.unit.name}</div>
              <div className="unit1-card__desc">{props.unit.description}</div>
            </div>
          </div>

          <div className="unit1-card__icon">
            <FaEllipsisV
              size={15}
              onClick={(e) => {
                setCollapse(!collapse);
                e.stopPropagation();
              }}
            />
          </div>
          {collapse ? (
            <UnitCardOptions
              setCollapse={setCollapse}
              isUnitDeletable={isUnitDeletable}
              openUpdateModaHandler={openUpdateModaHandler}
            />
          ) : null}
        </div>
      </div>

      <CreateUnitModel
        show={modalState.openUpdateModal}
        unitModelHandler={openUpdateModaHandler}
        selectedUnit={props.unit}
      />

      <AlertPopup
        message={modalState.deletableText}
        header="Attention"
        isShow={!!modalState.deletableText}
        primaryButtonText="Confirm"
        onOk={() => {
          if (!appUnits.isLoading) {
            onUnitDelete(props.unit.id || '');
            clearDeletableTexttHandler();
          }
        }}
        onClose={clearDeletableTexttHandler}
      />

      <AlertPopup
        header="Warning"
        message={modalState.notDeletableText}
        isShow={!!modalState.notDeletableText}
        onOk={clearNotDeletableTextHandler}
        onClose={clearNotDeletableTextHandler}
        type="NO_BUTTON"
      />
    </Fragment>
  );
};

export default UnitCard;
