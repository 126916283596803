/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import {PaperTypes} from '../../enums/paperTypes';
import {Exam} from '../../models/Exam';

const MenuInstance = (props: {
  unitNames: any[];
  lessonName: any;
  menuState: boolean;
  exams: any[];
}) => {
  const [menuState, setMenuState] = useState(false);
  const handleMenuState = () => {
    setMenuState((menuState) => !menuState);
  };
  let svgState = menuState ? 'rotate' : '';
  let menuHeight = menuState ? 'menu-item__sub' : 'menu-item__sub height';
  useEffect(() => {
    if (props.menuState) {
      setMenuState(true);
    } else {
      setMenuState(false);
    }
  }, [props.menuState]);

  useEffect(() => {
    setMenuState(false);
  }, []);

  return (
    <li className="menu-item">
      <a className="btn-list" onClick={handleMenuState}>
        <i>
          <svg
            className={svgState}
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.657841 15.2915C0.604121 15.2379 0.5615 15.1742 0.532418 15.1041C0.503338 15.0341 0.488369 14.9589 0.488369 14.8831C0.488369 14.8072 0.503338 14.732 0.532418 14.662C0.5615 14.5919 0.604121 14.5282 0.657841 14.4746L7.17285 7.96079L0.65784 1.44694C0.549522 1.33862 0.48867 1.19171 0.48867 1.03852C0.48867 0.885338 0.549522 0.738427 0.65784 0.630109C0.766158 0.52179 0.913068 0.460939 1.06625 0.460939C1.21944 0.460939 1.36635 0.52179 1.47467 0.630108L8.39693 7.55237C8.45065 7.60596 8.49327 7.66961 8.52236 7.7397C8.55144 7.80978 8.56641 7.88491 8.56641 7.96079C8.56641 8.03666 8.55144 8.11179 8.52236 8.18188C8.49328 8.25196 8.45065 8.31562 8.39693 8.3692L1.47467 15.2915C1.42108 15.3452 1.35743 15.3878 1.28734 15.4169C1.21726 15.446 1.14213 15.4609 1.06625 15.4609C0.990378 15.4609 0.915248 15.446 0.845166 15.4169C0.775084 15.3878 0.711426 15.3452 0.657841 15.2915Z"
              fill="white"
            />
          </svg>
        </i>
        {props.lessonName}
      </a>
      <div className={menuHeight}>
        {props.unitNames?.map((value: any) => {
          return <a key={value.id}>{value.name}</a>;
        })}
        {props.exams.map((value) => {
          const typeSet = new Set<string>();
          const getExamType = (exam: Exam): string => {
            exam.papers.forEach((p) => {
              if (p.paperType === PaperTypes.STRUCTURED) {
                typeSet.add('STR');
              } else if (p.paperType === PaperTypes.TRUEFALSE) {
                typeSet.add('TF');
              } else if (p.paperType === PaperTypes.ESSAY) {
                typeSet.add('ESY');
              } else {
                typeSet.add('MCQ');
              }
            });
            return Array.from(typeSet).join(' | ');
          };
          return (
            <ol className="submenu-globalexams">
              <li className="menu-item">
                <a onClick={handleMenuState}>
                  {' '}
                  <label className="submenu-globalexams-label">{getExamType(value)}</label>{' '}
                  {value.examTitle}
                </a>
              </li>
            </ol>
          );
        })}
      </div>
    </li>
  );
};

export default MenuInstance;
