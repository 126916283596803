import firebase from 'firebase';

export const enrollToSubject = (
  subID: string,
  onSuccess?: (data: firebase.functions.HttpsCallableResult) => void,
  onCatch?: (err: any) => void
) => {
  const enrollToSubjectDto = {subID: subID};
  const updateUserDetails = firebase.functions().httpsCallable('enrollToSubject');
  updateUserDetails(enrollToSubjectDto)
    .then((data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onCatch) {
        onCatch(err);
      }
    });
};
