import React from 'react';
import {useAppUser} from '../context/UserContext';
import {Subject} from '../models/Subject';
import {gradeOrder} from './CreateNotice';

import './FilterPopup.scss';

export interface Filters {
  grades: string[];
  subject?: Subject | 'All';
  status: string;
}

export interface IFilterState {
  subject?: Subject | 'All';
  status: 'All' | 'Active' | 'Inactive' | 'Upcoming';
  grades: {selected: boolean; grade: string}[];
  subjects: Subject[];
}

export const initialState: IFilterState = {
  subject: 'All',
  status: 'All',
  grades: [],
  subjects: [],
};

interface Iprops {
  onClose: () => void;
  onApplyFilters: (filters: Filters) => void;
  isSubjectBy: boolean;
  selectedSubject?: Subject;
  filterState: IFilterState;
  isShow: boolean;
  onGradeChange: (item: {selected: boolean; grade: string}) => void;
  onSubjectChange: (value: string) => void;
  onClear: () => void;
  onStateChange: (value: string) => void;
}
const FilterPopup = (props: Iprops) => {
  const {firestoreUser} = useAppUser();

  return (
    <div className={`filter-popup position-absolute ${props.isShow ? 'd-block' : 'd-none'}`}>
      <div className="fq-popup__grade">
        <div className="grade">Grade</div>
        <div className="d-flex">
          {props.filterState.grades
            .sort((a, b) => {
              const [an] = gradeOrder.filter((s) => s[0] === a.grade);
              const [bn] = gradeOrder.filter((s) => s[0] === b.grade);
              return (an[1] as number) - (bn[1] as number);
            })
            .map((item, idx) => (
              <div key={idx}>
                <div className="pretty p-smooth p-svg p-curve">
                  <input
                    style={{ width: '1rem' }}
                    type="checkbox"
                    disabled={props.isSubjectBy}
                    checked={item.selected}
                    onChange={(e) => {
                      props.onGradeChange(item);
                    }}
                  />
                  <div className="state p-primary">
                    <svg
                      className="svg svg-icon"
                      style={{
                        display: 'none',
                      }}
                    >
                      <path
                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                        style={{ stroke: 'white', fill: 'white' }}
                      ></path>
                    </svg>
                    <label
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      {item.grade}
                    </label>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <br />

      <div className="filter-group2">
        {!props.isSubjectBy ? (
          <div className="filter-subject">
            <div className="flabel">Subject</div>
            <select
              value={(props.filterState.subject as Subject)?.id || 'All'}
              onChange={(e) => {
                props.onSubjectChange(e.target.value);
              }}
            >
              <option value="all">All</option>
              {props.filterState.subjects?.map((s, index) => (
                <option value={s.id} key={index}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="filter-subject">
            <div className="flabel">Subject</div>
            <select
              disabled={props.isSubjectBy}
              value={(props.filterState.subject as Subject)?.id || 'All'}
              onChange={(e) => {
                props.onSubjectChange(e.target.value);
              }}
            >
              <option value="all">{props.selectedSubject?.name}</option>
            </select>
          </div>
        )}
        <div className="filter-status">
          {(firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) && (
            <>
              <div className="flabel">Status</div>
              <select
                value={props.filterState.status}
                onChange={(e) => {
                  props.onStateChange(e.target.value);
                }}
              >
                <option value="all">All</option>
                <option value="Active">Active</option>
                <option value="Upcoming">Upcoming</option>
                <option value="Inactive">Inactive</option>
              </select>
            </>
          )}
        </div>
      </div>

      <div className="fq-popup__btns">
        <div
          onClick={() => {
            props.onClear();
          }}
        >
          Clear Filters
        </div>
        <div>
          <div style={{ cursor: 'pointer' }} onClick={props.onClose}>
            Cancel
          </div>
          <button
            className="apply"
            onClick={() => {
              props.onApplyFilters({
                grades: props.filterState.grades.filter((g) => g.selected).map((g) => g.grade),
                status: props.filterState.status,
                subject: props.filterState.subject,
              });
              props.onClose();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPopup;
