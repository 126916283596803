import React, {FC} from 'react';
import {IconType} from 'react-icons';

import './BoxIcon.scss';

interface Props {
  icon: IconType;
  size: number;
  color?: string;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const BoxIcon: FC<Props> = (props) => {
  return (
    <div
      className={'box-icon ' + props.className}
      style={props.style}
      onClick={(e) => {
        props.onClick && props.onClick();

        e.stopPropagation();
      }}
    >
      <props.icon size={props.size} color={props.color} className="icon" />
    </div>
  );
};

export default BoxIcon;
