import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import AlertPopup from '../../components/AlertPopup';
import Backarrow from '../../components/Backarrow';
import HamburgerIcon from '../../components/HamburgerIcon';
import MarkDistribution from '../../components/MarkDistribution';
import PrimaryNav from '../../components/PrimaryNav';
import StudentPaperResultCard from '../../components/StudentPaperResultCard';
import STULeaderboard from '../../components/STULeaderboard';
import { SCREEN } from '../../constants';
import { useAppUser } from '../../context/UserContext';
import { ExamToDoDTO } from '../../dtos/ExamToDoDTO';
import { ExamType } from '../../enums/ExamType';
import { PaperTypes } from '../../enums/paperTypes';
import LayoutNew from '../../layouts/LayoutNew';
import Overlay from '../../shared/Overlay';
import {
  allocatedTimeHoursAndMinutes,
  getAbbrivation,
  removeDecimalSection,
  round,
  timeTakenFormatted,
} from '../../utils';
import './StudentResultScreen.scss';

const StudentResultScreen = () => {
  const getCompletedExamRef = useFunctions().httpsCallable('getCompletedExam');

  const history = useHistory();

  const params = useParams() as { subjectId: string; examId: string };
  const { exam } = useLocation().state as { exam: ExamToDoDTO };
  const [loading, setLoading] = useState(false);
  const [commenceLoading, setCommenceLoading] = useState(false);

  const hideOverallMark = exam.papers.filter((p) => {
    if (
      (p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED) &&
      !p.structEssayData?.answerSheetUrl
    ) {
      return false;
    } else if (
      (p.paperType === PaperTypes.ESSAY || p.paperType === PaperTypes.STRUCTURED) &&
      !p.released
    ) {
      return true;
    }
    return false;
  }).length;

  let examScore: string | undefined;

  if (exam?.gradingType === 'Percentage') {
    examScore = (exam.score ? round(exam.score, 2, 2).toString() : '00.00') + '%';
  } else {
    examScore = exam.points + '/' + exam.totalPoints;
  }

  const handleRetakeQuiz = () => {
    if (appUser.firestoreUser?.userRole?.isStudent) {
      setCommenceLoading(true);
      getCompletedExamRef({ subjectId: exam?.subjectId, examId: exam?.examId })
        .then((res) => {

          if (res.data.status === 410) {

            setAlert(res.data.data)
            setCommenceLoading(false);

          } else {
            sessionStorage.removeItem(`EXAM_SESSION_${res.data.examId}USER_${appUser?.fireUser?.uid}`);
            history.push({
              pathname: `/my-subjects/subject/${params.subjectId}/exam/${exam?.id || exam.examId}`,
              state: {
                exam: res.data.data,
                userExamStartTime: new Date(),
                redo: true,
                examinationType: ExamType.EXTRA_EXAM,
                subjectId: exam?.subjectId,
              },
            });
          }



        })
        .catch((e) => {
          console.log('ERROR -', e);
          setCommenceLoading(false);
        });
    }
  };

  //hooks
  const appUser = useAppUser();

  //callbles
  const getExamUserStatsRef = useFunctions().httpsCallable('getExamUserStats');

  let responseData = {
    ranking: [],
    meanMark: 0,
    lowestMark: 0,
    highestMark: 0,
    stillToMark: false,
    isPoints: false,
    graph: {
      distribution: [],
      youTag: 0,
    },
  };

  const [state, setState] = useState<{
    ranking: { active: boolean; position: number; score: number; uid: string }[];
    meanMark: number | undefined;
    lowestMark: number | undefined;
    highestMark: number | undefined;
    stillToMark: boolean;
    isPoints: boolean;
    graph: {
      distribution: { percentage: number; count: number }[];
      youTag: number;
    };
  }>(responseData);

  useEffect(() => {
    setLoading(true);

    getExamUserStatsRef({
      uid: appUser.firestoreUser?.uid,
      examId: exam.examId,
    })
      .then((data) => {
        setLoading(false);
        setState(data.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log('ERROR ', err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const primaryNav = <PrimaryNav />;
  const secondaryNav = null;
  const appHeader = (
    <div style={{ color: 'white' }} className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <Backarrow
          onClick={() => {
            //setShowPapers({exam: null});

            console.log("AVA")

            const state = exam.examType === ExamType.EXTRA_EXAM ? "extra" : exam.examType === ExamType.LIVE_EXAM ? "live" : "practise"

            history.push({ pathname: `/my-subjects/subject/${params?.subjectId}/Examinations`, state: state });
          }}
          className="mr-3"
        />
        <div style={{ fontSize: 25 }}>{exam.examTitle}</div>
      </div>
      <HamburgerIcon
        onClick={() => {
          // appMobileNav.setOpenMobileNav(!appMobileNav.openMobileNav);
        }}
        className="d-md-none"
      />
    </div>
  );

  const [alert, setAlert] = useState('');

  const appBody = (
    <>

      {commenceLoading ? <Overlay msg="Commencing" /> : <></>}
      <div className="student-marks mt-4 justify-content-center">
        <div className={hideOverallMark ? 'student-marks__pending ' : 'student-marks__papers'}>
          <div className="student-marks__grade">
            <h4 className="student-marks__grade-value">
              {hideOverallMark ? 'Pending...' : `${examScore}`}
            </h4>
            <h4 className="student-marks__grade-text">Overall Grade</h4>
            {exam.examType === ExamType.EXTRA_EXAM ? (
              <div
                className="student-marks__grade-retake-txt"
                onClick={() => {
                  handleRetakeQuiz();
                }}
              >
                Retake
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="student-marks__time-taken">
            <div className="student-marks__time-taken-text">Time Taken</div>
            <div className="student-marks__time-taken-value">
              {timeTakenFormatted(exam.timeTaken || '00:00')}
            </div>
          </div>
          <div className="student-marks__papers-section">
            <div className="student-marks__papers-scroll">
              {exam.papers.map((p, idx) => {
                let paperScore: string | undefined;
                let stillToMark = true;

                if (p.paperType === PaperTypes.MCQ || p.paperType === PaperTypes.TRUEFALSE) {
                  stillToMark = false;
                  if (exam?.gradingType === 'Percentage') {
                    paperScore = removeDecimalSection(p.score?.toString() || '') + '%';
                  } else {
                    paperScore = p.paperPoints + '/' + p.marks;
                  }
                } else {
                  if (exam?.gradingType === 'Percentage') {
                    if (!p.structEssayData?.answerSheetUrl) {
                      paperScore = 'NOT UPLOADED';
                    } else if (!p.structEssayData?.markedAnswerSheetUrl) {
                      paperScore = 'NOT MARKED';
                    } else if (!p.released) {
                      paperScore = 'NOT RELEASED';
                    } else {
                      stillToMark = false;
                      paperScore = removeDecimalSection(p.score?.toString() || '') + '%';
                    }
                  } else {
                    if (!p.structEssayData?.answerSheetUrl) {
                      paperScore = 'NOT UPLOADED';
                    } else if (!p.structEssayData?.markedAnswerSheetUrl) {
                      paperScore = 'NOT MARKED';
                    } else if (!p.released) {
                      paperScore = 'NOT RELEASED';
                    } else {
                      stillToMark = false;
                      paperScore = p.paperPoints + '/' + p.marks;
                    }
                  }
                }

                let isDisabled: boolean | undefined = false;
                let isExamNotFinished: boolean | undefined = false;

                if (exam.examType !== ExamType.EXTRA_EXAM && exam.finishTime) {
                  isExamNotFinished = +new Date() <= +new Date(exam.finishTime || new Date());

                  if (isExamNotFinished) {
                    paperScore = '-';

                    if (!alert) {
                      setAlert(
                        'This exam is still in progress. Marks will be shown once the exam is over'
                      );
                    }
                  }

                  isDisabled = isExamNotFinished || stillToMark;

                  // console.log('isExamNotFinished ', isExamNotFinished);
                  // console.log('stillToMark ', stillToMark);
                  // console.log('isDisabled ', isDisabled);
                } else {
                  isDisabled = stillToMark;
                }

                return (
                  <StudentPaperResultCard
                    stillToMark={stillToMark}
                    className="student-marks__papers-card-mb student-marks__papers-card-mr"
                    key={idx}
                    title={p.paperTitle}
                    type={getAbbrivation(p.paperType)}
                    marks={paperScore || ''}
                    noOfQuestions={p.totalNoOfQuestions}
                    time={{
                      hour: allocatedTimeHoursAndMinutes(p.allocatedTime)[0],
                      minutes: allocatedTimeHoursAndMinutes(p.allocatedTime)[1],
                    }}
                    onClick={() => {
                      history.push({
                        pathname: `/my-subjects/student/${params.subjectId}/exam/result/${params.examId}/paper/${p.id}`,
                        state: {
                          paper: p,
                          exam: exam,
                        },
                      });
                      // onClickPaper(p);
                    }}
                    paperType={p.paperType}
                    isDisabled={isDisabled}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {hideOverallMark ? (
          <></>
        ) : (
          <div className="student-marks__stats">
            <STULeaderboard
              totalPoints={exam.totalPoints}
              isPoint={exam.gradingType !== 'Percentage'}
              stillToMark={state.stillToMark}
              ranking={state.ranking.sort(function (a, b) {
                if (a?.score && b?.score) {
                  return b.score - a.score;
                } else {
                  return 0;
                }
              })}
            />
            <MarkDistribution
              totalMarks={exam.totalPoints}
              className="mb-2 mt-3"
              meanMark={state.meanMark}
              lowestMark={state.lowestMark}
              highestMark={state.highestMark}
              youTag={state.graph.youTag}
              distribution={state.graph.distribution}
              gradingType={exam?.gradingType}
              style={{ width: 'auto' }}
            />
          </div>
        )}

        <AlertPopup
          message={alert}
          header="Attention"
          isShow={!!alert}
          onClose={() => {

            if (alert === 'This exam is still in progress. Marks will be shown once the exam is over') {
              history.goBack();
            }
            setAlert('');

          }}
          type="NO_BUTTON"
        />
      </div>

    </>
  );

  return (
    <LayoutNew
      isLoading={loading}
      primaryNav={primaryNav}
      header={appHeader}
      body={appBody}
      screen={SCREEN.SCORE_SCREEN}
      secondaryNav={secondaryNav}
    />
  );
};

export default StudentResultScreen;
