import React, {FC} from 'react';
import './QuestionBody.scss';
import renderHTML from 'react-render-html';

interface Props {
  questionContent: string;
}

const QuestionBody: FC<Props> = (props) => {
  const renderedText = renderHTML(props.questionContent || '');

  return <div className="question-body">{renderedText}</div>;
};

export default QuestionBody;
