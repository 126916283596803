import React, { useState } from 'react';
import ComplexityIndicator from './ComplexityIndicator';
import './QuestionCard.scss';
import truncate from 'truncate';
import { FaCheckCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import renderHTML from 'react-render-html';
import { decodeHtml } from '../utils';
import { QuestionTypes } from '../enums/questionTypes';

interface Props {
  qtn: any;
  complexity: number;
  type: string;
  onClick: () => void;
  searchText: string;
}

const QuestionCard = (props: Props) => {
  const { qtn, complexity, type } = props;

  const renderedText = qtn?.question?.replace(/<[^>]+>/g, '');

  const [collapse, setCollapse] = useState(true);

  return (
    props.qtn && (
      <div className="q-card mt-2 text-white d-flex" onClick={props.onClick}>
        <div className="d-flex question-type mr-auto">
          <span>{type}</span>
        </div>

        <div className="question-text">
          {collapse ? (
            // <Highlighter
            //   highlightClassName="question-text-highlight"
            //   searchWords={props.searchText.split(' ')}
            //   autoEscape={true}
            //   textToHighlight={
            //     truncate(decodeHtml(renderedText?.trim()) || 'Question with visual content', 150) ||
            //     ''
            //   }
            // />

            <div>
              {truncate(decodeHtml(renderedText?.trim()) || 'Question with visual content', 150) ||
                ''}
            </div>
          ) : (
            <>
              <div>{renderHTML(qtn?.question || '')}</div>

              {props.qtn.questionType === QuestionTypes.MCQ ? (
                qtn.answers.map((ans: any, index: number) => (
                  <div className="d-flex align-items-center" key={index}>
                    <div style={{ width: 25 }}>
                      {ans.isCorrect ? <FaCheckCircle size={20} color="20D167" /> : <></>}
                    </div>
                    <span className="open-collapse-answer-no">{index + 1 + '.'}</span>
                    <div className="open-collapse-answer-text">{renderHTML(ans.answerText)}</div>
                  </div>
                ))
              ) : (
                <>
                  <div className="d-flex align-items-center">
                    <div style={{ width: 25 }}>
                      {props.qtn.answer ? <FaCheckCircle size={20} color="20D167" /> : <></>}
                    </div>
                    <span className="open-collapse-answer-no">{1 + '.'}</span>
                    <div className="open-collapse-answer-text">True</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div style={{ width: 25 }}>
                      {!props.qtn.answer ? <FaCheckCircle size={20} color="20D167" /> : <></>}
                    </div>
                    <span className="open-collapse-answer-no">{2 + '.'}</span>
                    <div className="open-collapse-answer-text">False</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className="d-flex question-complexity align-items-center pr-4">
          <div className="ml-auto">
            <ComplexityIndicator value={complexity} />
            {collapse ? (
              <FaChevronUp
                color="grey"
                onClick={(e) => {
                  setCollapse(!collapse);
                  e.stopPropagation();
                }}
              />
            ) : (
              <FaChevronDown
                color="grey"
                onClick={(e) => {
                  setCollapse(!collapse);
                  e.stopPropagation();
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  );
};
export default QuestionCard;
