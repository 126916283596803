import React, {FC} from 'react';
import './TrueFalseAnswer.scss';

interface Props {
  type: boolean;
  onChange: (value: boolean) => void;
  value: boolean;
}

const TrueFalseAnswer: FC<Props> = (props) => {
  return (
    <div
      className="tf-answer"
      onClick={(e: any) => {
        if (props.type) {
          props.onChange(true);
        } else {
          props.onChange(false);
        }
      }}
      style={{cursor: 'pointer'}}
    >
      <div>
        <div className="pretty p-default p-round p-fill">
          <input type="checkbox" checked={props.value === props.type} onChange={() => {}} />
          <div className="state p-primary tf-answer-checkbox" style={{marginTop: 4}}>
            <label style={{fontSize: 20}}>{props.type ? 'TRUE' : 'FALSE'}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrueFalseAnswer;
