import React, {FC} from 'react';
import {FaArrowDown, FaFilePdf} from 'react-icons/fa';

import NavigatorPaperCard from './NavigatorPaperCard';
import './StructuredEssayRightPanelContent.scss';
// import {saveAs} from 'file-saver';
import {PaperState} from '../interfaces';
import Notification from './Notification';
interface Props {
  examPapers: PaperState[];
  selectedIndex: number;
  changePaper: (index: number) => void;
  paperName: string | undefined;
  paperUrl: string | undefined;
}

const StructuredEssayRightPanelContent: FC<Props> = (props) => {

const downloadPdf = () => {
  if (props.paperUrl) {
    fetch(props.paperUrl).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = props.paperName ? props.paperName : '';
        alink.click();
      });
    });
  }else{
    Notification({
      isSuccess: false,
      message: 'Invalid data format!',
    });
  }
};

return (
  <div className="struct-essay-right text-white">
    <div className="struct-essay-right__top">
      <div className="struct-essay-right__title ">Download Paper</div>
      <div className="struct-essay-right__pdf d-flex  align-items-center">
        <div className="d-flex justify-content-center align-items-center struct-essay-right__icon-wrapper">
          <FaFilePdf color="#474A66" size={30} />
        </div>
        <div className="struct-essay-right__paper-name ml-3">{props.paperName}</div>

        <FaArrowDown
          color="#474A66"
          size={30}
          className="ml-auto mr-3"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            downloadPdf();
            // saveAs(props.paperUrl || '', props.paperName || '');
          }}
        />
      </div>
    </div>
    <div style={{ overflow: 'auto', height: 180 }} className="mt-md-0 mt-5">
      {props.examPapers.map((paper, index) => (
        <NavigatorPaperCard
          selectedIndex={props.selectedIndex}
          index={index}
          changePaper={props.changePaper}
          paper={paper}
          key={index}
        />
      ))}
    </div>
  </div>
);
};

export default StructuredEssayRightPanelContent;
