import './NoticeCard.scss';
import renderHTML from 'react-render-html';
import {TbEdit} from 'react-icons/tb';
import {AiFillDelete} from 'react-icons/ai';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

import { useState } from 'react';
import truncate from 'truncate';
import { Announcement } from '../context/AnnouncementContext';
import AlertPopup from './AlertPopup';
import { useAppUser } from '../context/UserContext';
import { useSubjects } from '../context/SubjectsContext';

interface IProps {
  announcement: Announcement;
  subjectId?: string;
  onDelete?: (announcement: Announcement) => void;
  onEdit?: (announcement: Announcement) => void;
  onRead?: (announcement: Announcement) => void;
}

const NoticeCard = (props: IProps) => {
  const { firestoreUser } = useAppUser();
  const { push } = useHistory();
  const [showDPopup, setShowDPopup] = useState(false);
  const { allSubjects } = useSubjects();

  const getActions = () => {
    let inactive = false;
    const styleInactive = { cursor: 'not-allowed' };
    if (
      !props.announcement.isActive &&
      !(props.announcement.date.toDate().getTime() > new Date().getTime())
    ) {
      inactive = true;
    }

    if (firestoreUser?.userRole?.isAdmin) {
      return (
        <div className="ed-wrapper">
          <div
            style={inactive ? styleInactive : {}}
            className="edit"
            onClick={() => {
              if (!inactive) {
                props.onEdit && props.onEdit(props.announcement);
              }
            }}
          >
            <TbEdit />
          </div>
          <div
            className="delete"
            onClick={() => {
              setShowDPopup(true);
            }}
          >
            <AiFillDelete />
          </div>
        </div>
      );
    } else if (firestoreUser?.userRole?.isInstructor) {
      if (props.announcement.createdBy === firestoreUser.email) {
        return (
          <div className="ed-wrapper">
            <div
              style={inactive ? styleInactive : {}}
              className="edit"
              onClick={() => {
                if (!inactive) props.onEdit && props.onEdit(props.announcement);
              }}
            >
              <TbEdit />
            </div>
            <div
              className="delete"
              onClick={() => {
                setShowDPopup(true);
              }}
            >
              <AiFillDelete />
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <>
          {!props.announcement.read && (
            <div className="markasread d-none d-md-block">
              <label
                onClick={() => {
                  props.onRead && props.onRead(props.announcement);
                }}
              >
                Mark as Read
              </label>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className="card-wraper">
      <div className="card-l">
        <label>
          {props.announcement.title}{' '}
          {(firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) && (
            <>
              {props.announcement.isActive ? (
                <span className="an-active">Active</span>
              ) : (
                <>
                  {props.announcement.date.toDate().getTime() > new Date().getTime() ? (
                    <span className="an-upcoming">Upcoming</span>
                  ) : (
                    <span className="an-inactive">Inactive</span>
                  )}
                </>
              )}
            </>
          )}
        </label>
        <div
          style={{
            maxHeight: '110px',
            overflowY: 'hidden',
          }}
        >
          {renderHTML(truncate(props.announcement.description || '', 400))}
        </div>
      </div>
      <div className="card-r">
        <div>
          <label className="stitle">Announced on:</label>
          <label className="svalue">
            {moment(props.announcement.date?.toDate()).format('MM/DD/YYYY hh:mm a')}
          </label>
        </div>
        <div>
          <label className="stitle">Announced by:</label>
          <label className="svalue">{props.announcement.createdByName}</label>
        </div>
        <div>
          <label className="stitle">Attachments:</label>
          <label className="svalue">{props.announcement.fileURLs?.length}</label>
        </div>
        <div className="mt-2 actions">
          {getActions()}

          <button
            className="buttonx"
            onClick={() => {
              if (props.subjectId) {
                const [subject] = allSubjects.filter((s) => s.id === props.subjectId);
                push(
                  `/my-subjects/subject/${props.subjectId}/Announcements/${props.announcement.id}`,
                  {
                    subjectName: subject.name,
                    subjectId: props.subjectId,
                  }
                );
              } else {
                push(`/announcements/${props.announcement.id}`);
              }
            }}
          >
            View
          </button>
          {firestoreUser?.userRole?.isStudent && !props.announcement.read && (
            <div className="markasread d-block d-md-none text-center">
              <label
                onClick={() => {
                  props.onRead && props.onRead(props.announcement);
                }}
              >
                Mark as Read
              </label>
            </div>
          )}
        </div>
      </div>
      <AlertPopup
        message="Do you want to delete this announcement?"
        header="Attention"
        isConformation
        primaryButtonText="Yes"
        isShow={showDPopup}
        onOk={() => {
          props.onDelete && props.onDelete(props.announcement);
          setShowDPopup(false);
        }}
        onClose={() => {
          setShowDPopup(false);
        }}
      />
    </div>
  );
};

export default NoticeCard;
