import firebase from 'firebase';
import React, { FC, useEffect, useState } from 'react';
import { FaFilter, FaPlusCircle } from 'react-icons/fa';
import FilterPapersPopUp from './FilterPapersPopUp';
import StructuredPaperCard from './StructuredPaperCard';
import { Col, Row } from 'react-bootstrap';
import './StructuredPapersTab.scss';
import StructuredEssayPaperPreviewHeaderActionBar from './StructuredEssayPaperPreviewHeaderActionBar';
import StructuredEssayPDFPreview from './StructuredEssayPDFPreview';
import { PaperData, ResourcePaperState } from '../interfaces';
import { ST_ES_PAPER_BREADCRUM_STEPS } from '../enums/PaperBreadCrum';
import { GRADES, RESOURCE_TAB_SUB_PAPERS } from '../constants';
import { usePapers } from '../context/PapersContext';
import { useAppUser } from '../context/UserContext';
import { useSubjects } from '../context/SubjectsContext';
import Notification from './../components/Notification';
import {
  getSTRPapersBasedOnRole,
  splitArrayIntoChunksOfLen,
  suffixAllocatedTime,
  validationResult,
} from '../utils';
import { StructEssayPaper } from '../models/StructEssayPaper';
import SearchBox from './SearchBox';
import LoadingIndicator from './LoadingIndicator';
import { Paper } from '../models/Paper';
import Pagination from './Pagination';
import AlertPopup from './AlertPopup';
import PaperService from '../services/PaperService';
import DropDown from './DropDown';
import TextInput from './TextInput';
import TextFieldInput from './TextFieldInput';
import NumberInput from './NumberInput';
import TimeInputComp from './TimeInputComp';
import ButtonComp from './ButtonComp';
import StructuredPaperFileUploader from './StructuredPaperFileUploader';
import { useLessons } from '../context/LessonsContext';
import SubjectDropDown from './SubjectDropDown';
import UnitDropDownMultiple from './UnitDropDownMultiple';
import LessonDropDownMultiple from './LessonDropDownMultiple';

interface Props {
  state: ResourcePaperState;
  setState: React.Dispatch<React.SetStateAction<ResourcePaperState>>;
  structuredEssayPaperNaviationData: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: ST_ES_PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
  };
  setStructuredEssayPaperNaviationData: (para: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: ST_ES_PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
  }) => void;
  units: { displayValue: string; id: string }[];
  lessons: { displayValue: string; id: string }[];
  subjects: { displayValue: string; id: string }[];
  currentSubTab: string;
}

export interface StateDisplayStructuredPapers {
  papers: any;
  loading: boolean;
  searchText: string;
  grade: string | null;
  subject?: { value: { displayValue: string; id: string } };
  lesson?: { value: { displayValue: string; id: string } };
  unit?: { value: { displayValue: string; id: string } };
  user?: { value: { displayValue: string; id: string } };
  complexity?: number;
  page: number;
  totalPages: number;
  filtered: boolean;
  status?: string;
}

const StructuredPapersTab: FC<Props> = (props) => {
  let content = <></>;

  if (props.structuredEssayPaperNaviationData.mode === 'VIEW') {
    if (props.currentSubTab === RESOURCE_TAB_SUB_PAPERS.STRUCTURED_PAPERS) {
      content = <DisplayStructuredPapers {...props} />;
    }
  } else if (
    (props.structuredEssayPaperNaviationData.mode === 'CREATE' ||
      props.structuredEssayPaperNaviationData.mode === 'EDIT') &&
    props.structuredEssayPaperNaviationData.step === ST_ES_PAPER_BREADCRUM_STEPS.STEP_ONE
  ) {
    content = <CreateStructuredPaper {...props} />;
  } else if (
    (props.structuredEssayPaperNaviationData.mode === 'CREATE' ||
      props.structuredEssayPaperNaviationData.mode === 'EDIT') &&
    props.structuredEssayPaperNaviationData.step === ST_ES_PAPER_BREADCRUM_STEPS.STEP_TWO
  ) {
    content = (
      <StructuredPaperFileUploader
        state={props.state}
        setState={props.setState}
        structuredEssayPaperNaviationData={props.structuredEssayPaperNaviationData}
        setStructuredEssayPaperNaviationData={props.setStructuredEssayPaperNaviationData}
      />
    );
  } else if (
    (props.structuredEssayPaperNaviationData.mode === 'CREATE' ||
      props.structuredEssayPaperNaviationData.mode === 'EDIT') &&
    props.structuredEssayPaperNaviationData.step === ST_ES_PAPER_BREADCRUM_STEPS.STEP_TWO
  ) {
    return content;
  } else if (props.structuredEssayPaperNaviationData.mode === 'PREVIEW') {
    content = <StructuredPaperPreview {...props} />;
  } else {
    return content;
  }
  return content;
};

//---------------Display Structured Papers-----------------------
const DisplayStructuredPapers: FC<Props> = (props) => {
  const appPapers = usePapers();
  const appUser = useAppUser();
  const appSubjects = useSubjects();

  const [showAlert, setShowAlert] = useState(false);

  const setLoading = (val: boolean) => {
    setState((ps) => {
      return { ...ps, loading: val };
    });
  };

  const showAlertHandler = () => {
    setShowAlert(true);
  };

  const closeAlertHandler = () => {
    setShowAlert(false);
  };

  //Delete a paper
  const deletePaperRef = firebase.functions().httpsCallable('deleteStructEssayPaper');

  const onPaperDelete = async (pid: string) => {
    setLoading(true);
    try {
      const { data } = await deletePaperRef({
        pid: pid,
      });

      appPapers.removeById(data.data);

      Notification({
        isSuccess: true,
        message: 'Paper deleted successfully',
      });
      setLoading(false);
      closeAlertHandler();
    } catch (e) {
      Notification({
        isSuccess: false,
        message: 'Failed to delete paper',
      });
      setLoading(false);
      closeAlertHandler();
    }
  };

  const initialState: StateDisplayStructuredPapers = {
    papers: [],
    loading: false,
    searchText: '',
    grade: null,
    subject: { value: { displayValue: '', id: '' } },
    lesson: { value: { displayValue: '', id: '' } },
    unit: { value: { displayValue: '', id: '' } },
    user: { value: { displayValue: '', id: '' } },
    page: 0,
    totalPages: 0,
    filtered: false,
  };

  const [state, setState] = useState<StateDisplayStructuredPapers>(initialState);

  const setCurrentPage = (page: number) => {
    setState((ps) => {
      return { ...ps, page: page };
    });
  };

  const [show, setShow] = useState(false);

  const [deleteId, setDeleteId] = useState('');

  const openModalHandler = () => {
    setShow(true);
  };

  const closeModalHander = () => {
    setShow(false);
  };

  const fetchDataCall = (filter?: boolean) => {
    if (typeof state.totalPages === 'number') {
      setState((ps) => {
        return { ...ps, loading: true };
      });

      const pageToSend = filter ? 0 : state.page - 1 < 0 ? 0 : state.page - 1;

      let filteredList = getSTRPapersBasedOnRole(
        appUser.firestoreUser?.uid || '',
        appSubjects.allSubjects,
        appUser.firestoreUser?.userRole,
        appPapers.structuredPapers
      );

      if (state?.grade?.length) {
        filteredList = filteredList.filter((item) => state?.grade?.includes(item.grade));
      }

      if (state?.subject?.value.id) {
        filteredList = filteredList.filter((item) => item.subjectId === state?.subject?.value.id);
      }

      if (state.lesson?.value.id) {
        filteredList = filteredList.filter((item) =>
          item.lessonIds?.includes(state.lesson?.value.id || '')
        );
      }

      if (state.unit?.value.id) {
        filteredList = filteredList.filter((item) =>
          item.unitIds?.includes(state.unit?.value.id || '')
        );
      }

      if (state?.user?.value.id) {
        filteredList = filteredList.filter((item) => item.createdBy === state?.user?.value.id);
      }
      if (state?.status === 'PUBLISHED' || state?.status === 'DRAFT') {
        filteredList = filteredList.filter((item) => item.status === state?.status);
      }

      // let searchTextResult: StructEssayPaper[] = [];
      let duplicatesRemovedArray: StructEssayPaper[];

      if (state?.searchText?.trim()) {
        // const words = state?.searchText.trim()?.split(' ');

        // for (const word in words) {
        //   searchTextResult = [
        //     ...searchTextResult,
        //     ...filteredList.filter(
        //       (item) => matchSorter(item.paperTitle.split(' '), words[word].trim() || '').length
        //     ),
        //   ];
        // }

        // duplicatesRemovedArray = Array.from(new Set(searchTextResult));

        duplicatesRemovedArray = filteredList.filter((item) =>
          item.paperTitle
            .replace(/<[^>]+>/g, '')
            .replace(/&nbsp;/g, '')
            .toUpperCase()
            .startsWith(state?.searchText?.trim().toUpperCase())
        );
      } else {
        duplicatesRemovedArray = filteredList;
      }

      let pagesList: StructEssayPaper[] = [];

      if (duplicatesRemovedArray.length) {
        pagesList = splitArrayIntoChunksOfLen(duplicatesRemovedArray, 10);
      }

      const totalPages = pagesList.length;

      setState((ps) => {
        return {
          ...ps,
          loading: false,
          papers: pagesList[pageToSend] || [],
          totalPages: totalPages,
          filtered: filter ? true : false,
        };
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (appPapers.allStructuredEssayPapers.length) {
      if (isMounted) {
        fetchDataCall();
      }
    }

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.structuredEssayPaperNaviationData.mode,
    state.page,
    appPapers.allStructuredEssayPapers.length,
    state?.searchText,
  ]);

  const applyFiltersHandler = () => {
    fetchDataCall(true);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setState((ps) => {
        return { ...ps, filtered: false };
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filtered]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchDataCall(true);
    }

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchText]);

  return (
    <div style={{ marginTop: 90 }}>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <div className="d-flex position-relative">
          <SearchBox
            onSearch={(text) => {
              setState((ps) => {
                return { ...ps, searchText: text };
              });
            }}
            placeHolderText="Search Papers"
          />
          <button className="filter_button" onClick={openModalHandler}>
            Filters &nbsp;
            <FaFilter />
          </button>
          <FilterPapersPopUp
            show={show}
            onCloseModal={closeModalHander}
            setState={setState}
            state={state}
            initialState={initialState}
            onApplyfilter={applyFiltersHandler}
          />
        </div>
        <button
          className="create-question-btn"
          onClick={() => {
            props.setStructuredEssayPaperNaviationData({
              ...props.structuredEssayPaperNaviationData,
              mode: 'CREATE',
              step: ST_ES_PAPER_BREADCRUM_STEPS.STEP_ONE,
              paperData: null,
            });
          }}
        >
          <FaPlusCircle /> {'Create Structured Paper'}
        </button>
      </div>
      {state.loading && <LoadingIndicator className="mt-3" />}
      <div
        className="q-card-table-header d-flex"
        style={{ marginTop: state.loading ? 30 : 45, transition: 'none' }}
      >
        <div>Title</div>
      </div>

      {/* ----Papercard mapping starts here---- */}
      <div className="paper-card-list">
        {state.papers.map((paper: Paper, idx: any) => (
          <StructuredPaperCard
            key={paper.id}
            paperName={paper.paperTitle}
            onClick={() => {
              console.log('PAPER UNITID ', paper.unitId);
              props.setStructuredEssayPaperNaviationData({
                ...props.structuredEssayPaperNaviationData,
                mode: 'PREVIEW',
                paperData: {
                  unitId: paper.unitId || '',
                  lessonId: paper.lessonId || '',
                  unitIds: paper.unitIds || [],
                  lessonIds: paper.lessonIds || [],
                  subjectId: paper.subjectId,
                  createdBy: paper.createdBy || '',
                  paperId: paper.id || '',
                  grade: paper.grade,
                },
              });
            }}
            paperData={{
              unitId: paper.unitId || '',
              lessonId: paper.lessonId || '',
              unitIds: paper.unitIds || [],
              lessonIds: paper.lessonIds || [],
              subjectId: paper.subjectId,
              createdBy: paper.createdBy || '',
              paperId: paper.id || '',
              grade: paper.grade,
            }}
            setStructuredEssayPaperNaviationData={props.setStructuredEssayPaperNaviationData}
            structuredEssayPaperNaviationData={props.structuredEssayPaperNaviationData}
            searchText={state.searchText}
            status={paper.status}
            paperAuthor={paper.createdBy}
            onDelete={() => {
              showAlertHandler();
              setDeleteId(paper.id || '');
            }}
          />
        ))}
      </div>
      {!state.filtered && state.totalPages > 1 ? (
        <Pagination pages={state.totalPages} setCurrentPage={setCurrentPage} />
      ) : (
        <></>
      )}
      <AlertPopup
        message="Are you sure you want to delete this paper?"
        header="Attention"
        isShow={showAlert}
        primaryButtonText="Confirm"
        onOk={() => {
          onPaperDelete(deleteId);
          closeModalHander();
        }}
        onClose={closeAlertHandler}
      />
    </div>
  );
};

//------------------Preview of a paper-------------------------------
const StructuredPaperPreview: FC<Props> = (props) => {
  const [paper, setPaper] = useState<StructEssayPaper | null>(null);

  const fetchQuestion = async (id: string) => {
    const doc = await PaperService.getStructuredEssayPaperById(id);
    if (doc.exists) {
      const paper = { id: doc.id, ...doc.data() } as StructEssayPaper;

      setPaper(paper);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (props?.structuredEssayPaperNaviationData?.paperData?.paperId) {
        fetchQuestion(props?.structuredEssayPaperNaviationData?.paperData?.paperId);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [props?.structuredEssayPaperNaviationData?.paperData?.paperId]);

  return (
    <div className="paper-preview">
      <StructuredEssayPaperPreviewHeaderActionBar
        className="preview"
        structuredEssayPaperNaviationData={props.structuredEssayPaperNaviationData}
        setStructuredEssayPaperNaviationData={props.setStructuredEssayPaperNaviationData}
        paper={paper}
        style={{ marginTop: 10 }}
        setState={props.setState}
      />
      <div className="paper-preview__main-content">
        <div className="paper-preview__title">
          <h6>Paper Title</h6>
          <div> {paper?.paperTitle}</div>
        </div>

        {paper?.description ? (
          <div className="paper-preview__description">
            <h6>Description</h6>
            <div>{paper?.description}</div>
          </div>
        ) : (
          <></>
        )}

        <div className="paper-preview__data">
          <div className="paper-preview__total-question">
            <h6>Total Number of Questions</h6>
            <div>{paper?.totalNoOfQuestions}</div>
          </div>
          <div className="paper-preview__allocated-time">
            <h6>Allocated Time</h6>
            <div>{suffixAllocatedTime(paper?.allocatedTime || '')}</div>
          </div>
          <div className="paper-preview__p-type">
            <div></div>
          </div>
        </div>
        <div className="paper-preview-question-headers d-flex">
          <Col className="">Question Paper</Col>
          <Col>Marking Scheme</Col>
        </div>
        <div className="paper-preview__question-list">
          <StructuredEssayPDFPreview
            paperURL={paper?.paperUrl}
            paperName={paper?.paperName}
            markingSchemeURL={paper?.markingSchemeUrl}
            markingSchemeName={paper?.markingSchemeName}
          />
        </div>
      </div>
    </div>
  );
};

//------------------Create a Structured Paper-------------------

const CreateStructuredPaper: FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const appLessons = useLessons();
  const resetErrorStates = () => {
    props.setState((ps) => {
      return {
        ...ps,
        paperTitle: { ...ps.paperTitle, error: '' },
        description: { ...ps.description, error: '' },
        grade: { ...ps.grade, error: '' },
        subject: { ...ps.subject, error: '' },
        lesson: { ...ps.lesson, error: '' },
        unit: { ...ps.unit, error: '' },
        lessons: { ...ps.lessons, error: '' },
        units: { ...ps.units, error: '' },
        totalNoOfQuestions: { ...ps.totalNoOfQuestions, error: '' },
        allocatedTime: { ...ps.allocatedTime, error: '' },
        marks: { ...ps.marks, error: '' },
      };
    });
  };

  const func = () => {};

  return (
    <div className="create-structured-paper">
      <div className="create-structured-paper__main">
        <div className="create-structured-paper__fields">
          <Row className="no-gutters">
            {props.state.loading.value && <LoadingIndicator />}
            <Col md={3}>
              <DropDown
                name="Grade"
                noValueText="Select grade"
                className="textinput-margin-signup px-0"
                stateName="grade"
                stateValue={props.state.grade.value}
                state={props.state}
                setState={props.setState}
                error={props.state.grade.error}
                optionsArray={GRADES}
              />
            </Col>
            <Col md={8} className="mx-auto">
              {/* <DropDown
                name="Subject"
                noValueText="Select subject"
                className="textinput-margin-signup"
                stateName="subject"
                stateValue={props.state.subject.value}
                state={props.state}
                setState={props.setState}
                error={props.state.subject.error}
                optionsArray={props.subjects}
              /> */}
              <SubjectDropDown
                name="Subject"
                noValueText="Select subject"
                className="textinput-margin-signup"
                stateName="subject"
                stateValue={props.state.subject.value}
                state={props.state}
                setState={props.setState}
                error={props.state.subject.error}
                optionsArray={props.subjects}
              />
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col md={6}>
              {/* <DropDown
                name="Lesson"
                noValueText="Select lesson"
                className="textinput-margin-signup"
                stateName="lesson"
                stateValue={props.state.lesson.value}
                state={props.state}
                setState={props.setState}
                error={props.state.lesson.error}
                optionsArray={props.lessons}
              /> */}
              {/* <LessonDropDown
                name="Lesson"
                noValueText="Select lesson"
                className="textinput-margin-signup"
                stateName="lesson"
                stateValue={props.state.lesson.value}
                state={props.state}
                setState={props.setState}
                error={props.state.lesson.error}
                optionsArray={props.lessons}
              /> */}
              <LessonDropDownMultiple
                name="Lessons"
                noValueText="Select lessons"
                className="textinput-margin-signup"
                stateName="lessons"
                stateValue={props.state.lessons.value}
                state={props.state}
                setState={props.setState}
                error={props.state.lessons.error}
                optionsArray={props.state.subject.value.id === '' ? [] : props.lessons}
                truncate={15}
                lessonLimit={true}
              />
            </Col>
            {props.state.lessons.value.length === 1 &&
            // appLessons.lessons.find((l) => l.id === props.state.lessons.value[0]?.id)
            //   ?.isLessonContainsUnits
            props.units.length > 0 ? (
              <Col md={5} className="mx-auto">
                {/* <DropDown
                  name="Unit"
                  noValueText="Select unit"
                  className="textinput-margin-signup"
                  stateName="unit"
                  stateValue={props.state.unit.value}
                  state={props.state}
                  setState={props.setState}
                  error={props.state.unit.error}
                  optionsArray={props.units}
                /> */}
                {/* <UnitDropDown
                  name="Unit"
                  noValueText="Select unit"
                  className="textinput-margin-signup"
                  stateName="unit"
                  stateValue={props.state.unit.value}
                  state={props.state}
                  setState={props.setState}
                  error={props.state.unit.error}
                  optionsArray={props.units}
                /> */}

                <UnitDropDownMultiple
                  name="Units"
                  noValueText="Select units"
                  className="textinput-margin-signup"
                  stateName="units"
                  stateValue={props.state.units.value}
                  state={props.state}
                  setState={props.setState || func}
                  error={props.state.units.error}
                  optionsArray={props.units}
                  truncate={15}
                  unitLimit={true}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <TextInput
            className="textinput-margin-signup"
            stateName="paperTitle"
            stateValue={props.state.paperTitle.value}
            state={props.state}
            setState={props.setState}
            error={props.state.paperTitle.error}
            placeHolder="Paper Title"
            style={{ marginTop: 25 }}
          />

          <TextFieldInput
            name="Description"
            stateName="description"
            stateValue={props.state.description.value}
            state={props.state}
            setState={props.setState}
            error={props.state.description.error}
            style={{ marginTop: 25, width: '96%' }}
            limit={100}
          />

          <Row className="no-gutters" style={{ marginTop: 25 }}>
            <Col md={3}>
              <NumberInput
                className="textinput-margin-signup"
                stateName="totalNoOfQuestions"
                stateValue={props.state.totalNoOfQuestions.value}
                state={props.state}
                setState={props.setState}
                error={props.state.totalNoOfQuestions.error}
                placeHolder="Total number of questions"
              />
            </Col>
            <Col md={4}>
              <TimeInputComp
                text="Allocated Time"
                stateName="allocatedTime"
                state={props.state}
                setState={props.setState}
                dateTime
                style={{ marginTop: 6 }}
                error={props.state.allocatedTime.error}
                layoutStyles={{ width: 150, margin: 'auto' }}
              />
            </Col>
            <Col md={4} className="ml-auto">
              <NumberInput
                className="textinput-margin-signup"
                stateName="marks"
                stateValue={props.state.marks.value + ''}
                state={props.state}
                setState={props.setState}
                error={props.state.marks.error}
                placeHolder="Marks"
                style={{ width: '94%' }}
              />
            </Col>
          </Row>
        </div>
        <div className="create-mcq-tf-paper__buttons">
          <ButtonComp
            type="one"
            text="Next"
            style={{ marginTop: 'auto' }}
            onClick={() => {
              const validationOutput = validationResult(props.state);

              const hours = props.state.allocatedTime.value.hours || '00';
              const minutes = props.state.allocatedTime.value.minutes || '00';

              let error = '';

              if (+hours) {
                error = 'NO-ERROR';
              } else if (+minutes < 30) {
                error = `Allocated time should be greater than 30 minutes`;
              } else {
                error = 'NO-ERROR';
              }

              const intermediateState = {
                ...validationOutput.state,
                allocatedTime: {
                  ...validationOutput.state.allocatedTime,
                  error: error,
                },
              };

              let hasError = false;

              if (error !== 'NO-ERROR') {
                hasError = true;
              }

              props.setState(intermediateState);

              if (!!validationOutput.formValidity || hasError) {
                props.setState((pS: any) => ({
                  ...pS,
                  loading: false,
                }));
                return;
              }

              resetErrorStates();

              props.setStructuredEssayPaperNaviationData({
                ...props.structuredEssayPaperNaviationData,
                step: ST_ES_PAPER_BREADCRUM_STEPS.STEP_TWO,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StructuredPapersTab;
