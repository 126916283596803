import React, { FC, useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import './UnitDropDownMultiple.scss';

import UnitDropdownCardMultiple from './UnitDropdownCardMultiple';
import { useUnits } from '../context/UnitsContext';
import truncate from 'truncate';

interface Props {
  name: string;
  noValueText: string;
  className?: string;
  style?: React.CSSProperties;
  stateName: string;
  stateValue: { displayValue: string; id: string; selected?: boolean }[];
  state: any;
  setState: Function;
  optionsArray: { displayValue: string; id: string; selected?: boolean }[];
  error?: string;
  truncate?: number;
  labelSize?: React.CSSProperties;
  disabled?: boolean;
  fireOnclick?: () => void;
  unitLimit?: boolean;
}

const UnitDropDownMultiple: FC<Props> = (props) => {
  const appUnits = useUnits();

  const [state, setState] = useState(false);

  const addInputHandler = (
    state: any,
    name: string,
    value: { displayValue: string; id: string }
  ) => {
    let resultArray = [...state[name].value];

    const hasItem = resultArray.find((a) => a.id === value.id);

    if (hasItem) {
      resultArray = resultArray.filter((a) => a.id !== value.id);
    } else {
      resultArray.push(value);
    }

    props.setState({
      ...state,
      [name]: { ...state[name], value: resultArray },
    });

    props.fireOnclick && props.fireOnclick();
  };

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: { ...state[name], error: '' },
    });
  };

  useEffect(() => {
    var ignoreClickOnMeElement = document.getElementById(`id${props.stateName}`);

    document.addEventListener('click', function (event) {
      var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target as Node);
      if (!isClickInsideElement) {
        setState(false);
      }
    });
  }, [props.stateName]);

  let borderColor =
    props.error === 'NO-ERROR'
      ? { borderBottom: '1px solid #20D167' }
      : props.error === ''
      ? {}
      : { borderBottom: '1px solid #dc3545' };

  // A comparer used to determine if two entries are equal.
  const isSameUser = (a: any, b: any) => a.id === b.id && a.displayValue === b.displayValue;

  // Get items that only occur in the left array,
  // using the compareFunction to determine equality.
  const onlyInLeft = (left: any, right: any, compareFunction: (a: any, b: any) => void) => {
    return left.filter(
      (leftValue: any) => !right.some((rightValue: any) => compareFunction(leftValue, rightValue))
    );
  };

  const OPTIONSLEFT = onlyInLeft(props.optionsArray, props.stateValue, isSameUser);

  useEffect(() => {
    const a = [...props.stateValue];

    a.forEach((v) => {
      v.selected = true;
    });

    // const b = [...props.optionsArray]

    // b.forEach((v) =>{
    //   v.selected = true
    // })

    // const result = [...a,...b]

    props.setState({
      ...props.state,
      [props.stateName]: { ...props.state[props.stateName], value: a },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`custom-dropdown ${props.className}`}
      id={`id${props.stateName}`}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        setState(true);
        resetError(props.state, props.stateName);
      }}
      style={{ ...props.style, marginTop: '20px' }}
    >
      <div className="custom-dropdown__line" style={borderColor}>
        <div style={{ paddingTop: 3, paddingBottom: 2.5 }}>
          {/* {truncate(props.stateValue.displayValue || props.noValueText, props.truncate || 30)} */}
          {props.stateValue.length ? (
            <div className={`d-flex ${props.unitLimit ? 'dropdown-wrap' : ''}`}>
              {props.stateValue.map((v, idx) => {
                return (
                  <div
                    className="mr-1 px-2 text-white d-flex align-items-center"
                    style={{ backgroundColor: '#262835', borderRadius: 34, height: 27 }}
                    key={idx}
                  >
                    <div
                      className="mr-1 ml-1"
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {' '}
                      {props.truncate ? truncate(v.displayValue, props.truncate) : v.displayValue}
                    </div>
                    {/* <FaTimes
                      color="#fff"
                      onClick={() => {
                        addInputHandler(props.state, props.stateName, {
                          displayValue: v?.displayValue || '',
                          id: v?.id || '',
                        });
                      }}
                    /> */}
                  </div>
                );
              })}
            </div>
          ) : (
            'Select Units'
          )}
        </div>

        <FaChevronDown
          color="#246BFD"
          size={12}
          className="eye"
          style={{ position: 'absolute', right: 0, top: -4 }}
        />
      </div>

      <div className="custom-dropdown__name" style={props.labelSize}>
        {props.name}
      </div>

      <div
        className="position-absolute custom-dropdown-list  lesson-list-dp"
        style={{
          display: state ? 'block' : 'none',
        }}
      >
        {props.stateValue.map((item, index) => {
          let className = 'dropdown-item-first';
          if (index) {
            className = 'dropdown-item-rest';
          }

          // const lesson = appLessons.lessons.find((sub) => sub.id === item.id);

          const unit = appUnits.units.find((unit) => unit.id === item.id);

          return (
            <UnitDropdownCardMultiple
              className={className}
              inputChangeHandler={addInputHandler}
              setState={setState}
              id={item.id}
              state={props.state}
              noValueText={props.noValueText}
              stateName={props.stateName}
              unit={unit}
              key={index}
              selected={true}
            />
          );
        })}

        {props.stateValue.length ? (
          <div className="py-1" style={{ backgroundColor: '#fff', borderRadius: 2 }}></div>
        ) : (
          <></>
        )}

        {OPTIONSLEFT.map((item: any, index: any) => {
          let className = 'dropdown-item-first';
          if (index) {
            className = 'dropdown-item-rest';
          }

          const unit = appUnits.units.find((sub) => sub.id === item.id);

          return (
            <UnitDropdownCardMultiple
              className={className}
              inputChangeHandler={addInputHandler}
              setState={setState}
              id={item.id}
              state={props.state}
              noValueText={props.noValueText}
              stateName={props.stateName}
              unit={unit}
              key={index}
              selected={false}
            />
          );
        })}
      </div>

      {props.error && props.error !== 'NO-ERROR' && (
        <div
          style={{ fontSize: 11, lineHeight: 1, top: 48, left: 0, fontWeight: 400, marginTop: 8 }}
          className="text-danger"
        >
          {props.error || 'error'}
        </div>
      )}
    </div>
  );
};

export default UnitDropDownMultiple;
