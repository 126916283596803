import { FC, useEffect, useState } from 'react';
import { FaExclamation, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import { useFunctions } from 'reactfire';
import { useLabels } from '../context/LabelContext';
import Notification from './../components/Notification';
import './AddLabelModal.scss';
import BoxIcon from './BoxIcon';

interface Props {
  subjectId: string;
  show: boolean;
  closeModal: () => void;
  setIsLoading: (value: boolean) => void;
  updateId: string;
  labelName: string;
}

const AddLabelModal: FC<Props> = (props) => {
  const createLabelRef = useFunctions().httpsCallable('createLabel');
  const updateLabelRef = useFunctions().httpsCallable('updateLabel');
  const regex = /^(\d+-)*(\d+)$/;

  const appLabels = useLabels();

  const [labelName, setLabelName] = useState('');

  useEffect(() => {
    const lName = appLabels.labelList.find((lab) => lab.id === props.updateId)?.labelName || '';
    setLabelName(lName);
  }, [props.updateId, appLabels.labelList]);

  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="create-label-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="create-label-modal__body">
        <div className="d-flex">
          <h4>{props.updateId ? 'Update Label' : 'Add Label'}</h4>
          <BoxIcon
            icon={FaTimes}
            color="#fff"
            size={20}
            className="ml-auto"
            style={{ width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: 10 }}
            onClick={() => {
              props.closeModal();
            }}
          />
        </div>
        <div className="d-flex justify-content-between mt-2 create-label-modal__actions">
          <input
            className="create-label-modal__input"
            onChange={(e) => {
              setLabelName(e.target.value);
            }}
            value={labelName}
          />
          <button
            className="create-label-modal__btn"
            onClick={() => {
              props.setIsLoading(true);
              const labels = appLabels.labelList.filter(
                (val) => val.subjectId === props.subjectId && val.id !== props.updateId
              );
              if (labels.find((lab) => lab.labelName === labelName)) {
                props.setIsLoading(false);
                Notification({
                  isSuccess: false,
                  message: `Label ${labelName} already exists for this subject`,
                  icon: FaExclamation,
                });
              } else if (labelName.trim().length === 0) {
                props.setIsLoading(false);
                setLabelName('');
                Notification({
                  isSuccess: false,
                  message: `Label cannot be empty`,
                  icon: FaExclamation,
                });
              } else if (props.updateId) {
                updateLabelRef({ id: props.updateId, labelName: labelName })
                  .then((data) => {
                    if (regex.test(labelName.trim())) {
                      props.setIsLoading(false);
                      setLabelName('');
                      Notification({
                        isSuccess: false,
                        message: `Update unsuccessfull. Label cannot contain only numbers`,
                        icon: FaExclamation,
                      });
                    } else if (labelName.trim().length === 0) {
                      props.setIsLoading(false);
                      setLabelName('');
                      Notification({
                        isSuccess: false,
                        message: `Update unsuccessfull. Label cannot be empty`,
                        icon: FaExclamation,
                      });
                    } else {
                      let index = 0;

                      let list = [...appLabels.labelList];

                      list.forEach((la, idx) => {
                        if (la.id === data.data.data.id) {
                          index = idx;
                        }
                      });

                      const updatedData = data.data.data;
                      updatedData.createdAt = null;
                      updatedData.updatedAt = null;

                      list[index] = data.data.data;

                      props.setIsLoading(false);

                      appLabels.setLabelList(list);

                      Notification({
                        isSuccess: true,
                        message: `Label updated successfully`,
                      });
                    }
                  })
                  .catch(() => {});
              } else if (labelName.trim().length === 0) {
                props.setIsLoading(false);
                setLabelName('');
                Notification({
                  isSuccess: false,
                  message: `Label cannot be empty`,
                  icon: FaExclamation,
                });
              } else if (regex.test(labelName.trim())) {
                props.setIsLoading(false);
                setLabelName('');
                Notification({
                  isSuccess: false,
                  message: `Label cannot contain only numbers`,
                  icon: FaExclamation,
                });
              } else {
                createLabelRef({ subjectId: props.subjectId, labelName: labelName })
                  .then((data) => {
                    if (data.data.data === 'label exists') {
                      props.setIsLoading(false);
                      setLabelName('');
                      return Notification({
                        isSuccess: false,
                        message: `Label ${labelName} already exists for this subject`,
                        icon: FaExclamation,
                      });
                    }

                    const updatedData: any = data.data.data;
                    updatedData.createdAt = null;
                    updatedData.updatedAt = null;

                    const list = [updatedData, ...appLabels.labelList];

                    props.setIsLoading(false);
                    setLabelName('');

                    appLabels.setLabelList(list);

                    Notification({
                      isSuccess: true,
                      message: `Label added successfully`,
                    });
                  })
                  .catch(() => {
                    props.setIsLoading(false);
                  });
              }

              props.closeModal();
            }}
          >
            {props.updateId ? 'Update Label' : 'Add Label'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddLabelModal;
