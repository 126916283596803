import React, {FC} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import './MobileNav.scss';
import PrimaryNavMobile from './PrimaryNavMobile';

interface Props {
  className?: string;
  secondaryNav?: string;
  loading?: boolean;
}

const MobileNav: FC<Props> = (props) => {
  return (
    <>
      <div className={`mobile-nav ${props.className}`}>
        <div className="mobile-nav__primary">
          <PrimaryNavMobile />
        </div>
        <div className="mobile-nav__secondary mt-4">
          {props.secondaryNav ? <div>{props.secondaryNav}</div> : <></>}
        </div>
      </div>

      {props.loading ? (
        <>
          <div className="mobile-loading-overlay d-md-none">
            <div className="mobile-loading-overlay__spinner-wrapper">
              <div className="mobile-loading-overlay__spinner">
                <ClipLoader color="#246BFD" loading={true} size={100} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MobileNav;
