import React, { FC, useState } from 'react';
import './ExamPapersInformation.scss';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { PaperState } from '../interfaces';
import { ExamType } from '../enums/ExamType';
import PrimaryNav from './PrimaryNav';
import AlertPopup from './AlertPopup';
import TimeBlock from './TimeBlock';
import { getAbbrivation } from '../utils';
import { useMobileNav } from '../context/MobNavContext';
import Backarrow from './Backarrow';
import HamburgerIcon from './HamburgerIcon';
import LayoutNew from '../layouts/LayoutNew';
import { SCREEN } from '../constants';
import ExamPapersInformationCard from './ExamPapersInformationCard';

interface Props {
  papers: PaperState[];
  onClick: (idx: number) => void;
  timeArray: number[];
  examinationFinishTime: string | undefined;
  examName: string;
  examType: ExamType | undefined;
  loading: boolean;
}

const ExamPapersInformation: FC<Props> = (props) => {
  const history = useHistory();
  const params = useParams() as { subjectId: string };

  const [show, setShow] = useState(false);

  const primaryNav = <PrimaryNav />;
  const secondaryNav = <PrimaryNav />;
  const body = (
    <>
      <AlertPopup
        message="Time has run out for this exam!"
        header="Attention"
        isShow={show}
        onOk={() => {
          setShow(false);
        }}
        onClose={() => {
          setShow(false);
        }}
        type="OK"
      />
      <div className="exam-p-info">
        <Row>
          <Col className="exam-p-info__time"></Col>
        </Row>
        <Row>
          <Col className="exam-p-info__counter">
            {props.loading ? (
              <ClipLoader color="#246bfd" loading={true} size={30} />
            ) : (
              <TimeBlock timeArray={props.timeArray} />
            )}
          </Col>
        </Row>

        <div className="mx-md-5 px-md-4 exam-p-info__cards d-md-flex">
          {props.papers.map((p, idx) => {
            return (
              <ExamPapersInformationCard
                paperTitle={p.paperName || ''}
                tag={getAbbrivation(p.paperType)}
                numberOfQtns={p.totalNoOfQuestions}
                hours={p.hours}
                minutes={p.minutes}
                className="mr-3"
                onClick={() => {
                  if (
                    props.examType === ExamType.EXTRA_EXAM ||
                    (props.examinationFinishTime &&
                      +new Date(props.examinationFinishTime) > +new Date())
                  ) {
                    props.onClick && props.onClick(idx);
                  } else {
                    setShow(true);
                  }
                }}
                paperStarted={p.started}
                key={idx}
              />
            );
          })}
        </div>
      </div>
    </>
  );

  const appMobileNav = useMobileNav();

  const header = (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <Backarrow
          onClick={() => {
            const state = props.examType === ExamType.EXTRA_EXAM ? "extra" : props.examType === ExamType.LIVE_EXAM ? "live" : "practice"

            console.log("APPLE")

            history.push({ pathname: `/my-subjects/subject/${params?.subjectId}/Examinations`, state: state });
          }}
        />
        <span className="text-white ml-3" style={{ fontSize: 25 }}>
          {props.examName}
        </span>
      </div>

      <HamburgerIcon
        onClick={() => {
          appMobileNav.setOpenMobileNav(!appMobileNav.openMobileNav);
        }}
        className="d-md-none"
      />
    </div>
  );

  return (
    <LayoutNew
      primaryNav={primaryNav}
      secondaryNav={secondaryNav}
      header={header}
      body={body}
      screen={SCREEN.EXAM_INFO_SCREEN}
      isLoading={false}
    />
  );
};

export default ExamPapersInformation;
