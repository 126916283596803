import React, {FC} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import truncate from 'truncate';
import {PaperState} from '../interfaces';
import {getAbbrivation} from '../utils';
import './NavigatorPaperCard.scss';

interface Props {
  selectedIndex: number;
  index: number;
  changePaper: (index: number) => void;
  paper: PaperState;
}

const NavigatorPaperCard: FC<Props> = (props) => {
  return (
    <div
      className={`navigator-paper-card ${
        props.selectedIndex === props.index && 'navigator-paper-card--selected'
      } `}
      onClick={() => {
        props.changePaper(props.index);
      }}
    >
      <div className="navigator-paper-card__tag">{getAbbrivation(props.paper.paperType)}</div>
      <div className="navigator-paper-card__paper-name">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="verifiedId">{props.paper.paperName}</Tooltip>}
        >
          <div>{truncate(`${props.paper.paperName}`, 40)}</div>
        </OverlayTrigger>
      </div>
      <button className="navigator-paper-card__exam-button">
        {props.paper.started ? 'Continue' : 'Start'}
      </button>
    </div>
  );
};

export default NavigatorPaperCard;
