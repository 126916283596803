import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import CreateLessonsModal from '../../components/CreateLessonsModal';
import LessonCard from '../../components/LessonCard';
import PrimaryNav from '../../components/PrimaryNav';
import SubjectDirectiveHeader from '../../components/SubjectDirectiveHeader';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import { useLessons } from '../../context/LessonsContext';
import LayoutNew from '../../layouts/LayoutNew';
import { Lesson } from '../../models/Lesson';
import './AdminLessonListScreen.scss';

interface Props {
  onClickAddPackage?: () => void;
}

const AdminLessonListScreen = (props: Props) => {
  const appLessons = useLessons();
  const { subjectId } = useParams() as { subjectId: string };

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const lessonModelHandler = (value: boolean) => {
    setOpen(value);
  };

  const loadingHandler = (value: boolean) => {
    setLoading(value);
  };

  const predicate = (lesson: Lesson) => lesson.subjectId === subjectId;
  let primaryNavigation = <PrimaryNav />;
  let secondaryNavigation = <SubjectSecondaryNav />;
  const appBody = (
    <div className="lesson-list-1">
      <div className="lesson-list-1__title">Lessons</div>
      <div style={{ height: 'calc(100vh - 165px)', overflow: 'auto' }}>
        <div className="lesson-list-1__list">
          {appLessons.lessons.filter(predicate).map((lesson) => {
            return <LessonCard lesson={lesson} key={lesson.id} loadingHandler={loadingHandler} />;
          })}
        </div>
      </div>
    </div>
  );
  let appHeader = (
    <SubjectDirectiveHeader
      onClickAddPackage={props.onClickAddPackage}
      lessonModelHandler={lessonModelHandler}
    />
  );

  return (
    <Fragment>
      <LayoutNew
        isLoading={loading}
        primaryNav={primaryNavigation}
        secondaryNav={secondaryNavigation}
        header={appHeader}
        body={appBody}
      />
      <CreateLessonsModal show={open} lessonModelHandler={lessonModelHandler} />
    </Fragment>
  );
};

export default AdminLessonListScreen;
