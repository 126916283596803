import "./CourseContentInfo.scss";

const CourseContentInfo = (props: any) => {
  return (
    <div className="content3">
      <h2 className="content3__header">Course Content</h2>
      <p className="content3__units">
        {+props.lessonNo < 10 ? '0' + props.lessonNo : props.lessonNo} Lessons -{' '}
        {+props.unitsNo < 10 ? '0' + props.unitsNo : props.unitsNo} Units -{' '}
        {+props.examCount < 10 ? '0' + +props.examCount : +props.examCount} Exams
      </p>
    </div>
  );
};

export default CourseContentInfo;
