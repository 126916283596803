import React, {FC, Fragment} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator';
import SearchWidget from '../../components/SearchWidget';
import TAExamCard from '../../components/TAExamCard';
import TAExamCardExtra from '../../components/TAExamCardExtra';
import TAExamCardExtraMarkingCompleted from '../../components/TAExamCardExtraMarkingCompleted';
import TAExamCardMarkingCompleted from '../../components/TAExamCardMarkingCompleted';
import {StructEssayDataPaperStatus} from '../../dtos/StructEssayData';
import {EXAM_TYPE_TABS} from '../../enums';
import {Exam} from '../../models/Exam';
import {getExamTypeQueryParam} from '../../utils';
import './TASubjectExamList.scss';

interface Props {
  loading: boolean;
  taData: [
    {type: EXAM_TYPE_TABS.EXTRA; approved: Exam[]; rest: Exam[]},
    {type: EXAM_TYPE_TABS.LIVE; approved: Exam[]; rest: Exam[]},
    {
      type: EXAM_TYPE_TABS.PRACTISE;
      approved: Exam[];
      rest: Exam[];
    }
  ];
  tabSelection: EXAM_TYPE_TABS;
  searchText: string;
  searchHandler: (text: string) => void;
}

const TASubjectExamList: FC<Props> = (props) => {
  const history = useHistory();

  const params = useParams() as {subjectId: string};

  return (
    <Fragment>
      {props.loading ? <LoadingIndicator /> : <></>}
      <SearchWidget
        onSearch={(term) => {
          props.searchHandler(term);
        }}
        placeHolderText="Search exam"
      />

      <div className="ta-exams">
        <div
          className="ta-exams__notmarked-rejected"
          style={{
            paddingRight: 10,
          }}
        >
          <div className="ta-exams__notmarked-rejected-title">Marking not completed</div>
          <div
            style={{
              height: 'calc(100vh - 300px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {props.taData
              .find((ex) => ex.type === props.tabSelection)
              ?.rest.filter((ex) =>
                ex.examTitle.toLowerCase().includes(props.searchText.toLowerCase())
              )
              .sort((a, b) => {
                if (
                  a.studentTAAssignmentInitialAssignment &&
                  b.studentTAAssignmentInitialAssignment
                ) {
                  return a.studentTAAssignmentInitialAssignment >
                    b.studentTAAssignmentInitialAssignment
                    ? -1
                    : 1;
                } else return 0;
              })
              .map((e, idx) => {
                return props.tabSelection === EXAM_TYPE_TABS.EXTRA ? (
                  <TAExamCardExtra
                    onClick={() => {
                      history.push(
                        `/my-subjects/subject/${params.subjectId}/Examinations/${
                          e.id
                        }/dashboard${getExamTypeQueryParam(e.examType)}`
                      );
                    }}
                    key={idx}
                    examTitle={e.examTitle}
                    assignedTime={e.studentTAAssignmentInitialAssignment}
                    rejected={
                      e.isAnyPaperRejectedByAdminOrInstructor ===
                      StructEssayDataPaperStatus.REJECTED
                    }
                  />
                ) : (
                  <TAExamCard
                    onClick={() => {
                      history.push(
                        `/my-subjects/subject/${params.subjectId}/Examinations/${
                          e.id
                        }/dashboard${getExamTypeQueryParam(e.examType)}`
                      );
                    }}
                    key={idx}
                    examTitle={e.examTitle}
                    assignedTime={e.studentTAAssignmentInitialAssignment}
                    rejected={
                      e.isAnyPaperRejectedByAdminOrInstructor ===
                      StructEssayDataPaperStatus.REJECTED
                    }
                    startDate={e.startDate}
                    startTime={e.startTime}
                    endDate={e.endDate}
                    endTime={e.endTime}
                  />
                );
              })}
          </div>
        </div>
        <div className="ta-exams__approved">
          <div className="ta-exams__approved-title">Marking completed</div>
          <div
            style={{
              height: 'calc(100vh - 300px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {props?.taData
              .find((ex) => ex.type === props.tabSelection)
              ?.approved.filter((ex) =>
                ex.examTitle.toLowerCase().includes(props.searchText.toLowerCase())
              )
              .sort((a, b) => {
                if (
                  a.studentTAAssignmentInitialAssignment &&
                  b.studentTAAssignmentInitialAssignment
                ) {
                  return a.studentTAAssignmentInitialAssignment >
                    b.studentTAAssignmentInitialAssignment
                    ? -1
                    : 1;
                } else return 0;
              })
              .map((e, index) => {
                return props.tabSelection === EXAM_TYPE_TABS.EXTRA ? (
                  <TAExamCardExtraMarkingCompleted
                    onClick={() => {
                      history.push(
                        `/my-subjects/subject/${params.subjectId}/Examinations/${
                          e.id
                        }/dashboard${getExamTypeQueryParam(e.examType)}`
                      );
                    }}
                    examTitle={e.examTitle}
                    key={index}
                    assignedTime={e.studentTAAssignmentInitialAssignment}
                  />
                ) : (
                  <TAExamCardMarkingCompleted
                    onClick={() => {
                      history.push(
                        `/my-subjects/subject/${params.subjectId}/Examinations/${
                          e.id
                        }/dashboard${getExamTypeQueryParam(e.examType)}`
                      );
                    }}
                    examTitle={e.examTitle}
                    key={index}
                    startDate={e.startDate}
                    startTime={e.startTime}
                    endDate={e.endDate}
                    endTime={e.endTime}
                    assignedTime={e.studentTAAssignmentInitialAssignment}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TASubjectExamList;
