import React, {FC, Fragment} from 'react';

interface Props {
  timeArray: number[];
}

const TimeBlock: FC<Props> = (props) => {
  let timeBlock = props.timeArray.map((value, idx) => {
    if (value) {
      if (idx === 0) {
        return (
          <Fragment key={idx}>
            {value}
            <span>D</span>{' '}
          </Fragment>
        );
      } else if (idx === 1) {
        return (
          <Fragment key={idx}>
            {value}
            <span>H</span>{' '}
          </Fragment>
        );
      } else if (idx === 2) {
        return (
          <Fragment key={idx}>
            {value}
            <span>M</span>{' '}
          </Fragment>
        );
      } else {
        return (
          <Fragment key={idx}>
            {value}
            <span>S</span>
          </Fragment>
        );
      }
    } else {
      return <Fragment key={idx}> </Fragment>;
    }
  });

  return <>{timeBlock}</>;
};

export default TimeBlock;
