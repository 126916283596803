import {useState} from 'react';
import './ExpandAll.scss';

const ExpandAll = (props: any) => {
  const [menuState, setMenuState] = useState(true);

  const expandHandler = () => {
    props.menuHandler(() => {
      setMenuState(!menuState);
      return menuState;
    });
  };

  return (
    <div className="content4">
      <button className="btn-expand" onClick={expandHandler}>
        Expand All
      </button>
    </div>
  );
};

export default ExpandAll;
