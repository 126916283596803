import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminLessonListScreen from './AdminLessonListScreen';
import AdminPackageListScreen from './AdminPackageListScreen';
import AdminSubjectAnnouncementsScreen from './AdminSubjectAnnouncementsScreen';
import ExamsCreateScreen from './ExamsCreateScreen';
import AdminSubjectModalPapersScreen from './AdminSubjectModalPapersScreen';
import AdminSubjectSettingsScreen from './AdminSubjectSettingsScreen';
import AdminUpdateExamScreen from './AdminUpdateExamScreen';
import AdminPackageViewScreen from './AdminPackageViewScreen';
import PageNotFoundScreen from '../common/PageNotFoundScreen';
import AdminExamDetailsAndStatsScreen from './AdminExamDetailsAndStatsScreen';

const AdminSubjectScreen = () => {
  return (
    <Switch>
      <Route exact path="/my-subjects/subject/:subjectId/Lessons">
        <AdminLessonListScreen />
      </Route>
      <Route path="/my-subjects/subject/:subjectId/Examinations/:examId">
        <AdminExamDetailsAndStatsScreen />
      </Route>
      <Route path="/my-subjects/subject/:subjectId/Examinations">
        <AdminSubjectModalPapersScreen />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/settings">
        <AdminSubjectSettingsScreen />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Announcements">
        <AdminSubjectAnnouncementsScreen />
      </Route>
      <Route
        exact
        path={[
          '/my-subjects/subject/:subjectId/create-extra-exam',
          '/my-subjects/subject/:subjectId/create-live-exam',
          '/my-subjects/subject/:subjectId/create-practise-exam',
        ]}
      >
        <ExamsCreateScreen level="SUBJECT" />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/update-exam/:examId/Examinations">
        <AdminUpdateExamScreen level="SUBJECT" />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Packages">
        <AdminPackageListScreen />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Packages/:packageId">
        <AdminPackageViewScreen />
      </Route>
      <Route path="*" component={PageNotFoundScreen} />
    </Switch>
  );
};

export default AdminSubjectScreen;
