import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

interface Props {
  children?: ReactNode;
  subjectId?: string;
  lessonId?: string;
  prevPath?: string;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Redirect
          to={this.props.prevPath || `/my-subjects/subject/${this.props.subjectId}/Lessons`}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
