import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import ExamTypesTabs from '../../components/ExamTypesTabs';
import { useAppUser } from '../../context/UserContext';
import { EXAM_TYPE_TABS } from '../../enums';
import { ExamType } from '../../enums/ExamType';
import { Exam } from '../../models/Exam';
import { convertTimeStampToDate } from '../../utils';
import TASubjectExamList from './TASubjectExamList';

const TASubjectModalPapersScreen = () => {
  const { subjectId } = useParams() as { subjectId: string };
  const appUser = useAppUser();

  const [state, setState] = useState({ tab: EXAM_TYPE_TABS.LIVE, loading: false, searchText: '' });
  const getTAAssignedExamsRef = useFunctions().httpsCallable('getTAAssignedExams');

  const [taAssignedExams, setTaAssignedExams] = useState<{
    exams: Exam[];
    taData: [
      { type: EXAM_TYPE_TABS.EXTRA; approved: Exam[]; rest: Exam[] },
      { type: EXAM_TYPE_TABS.LIVE; approved: Exam[]; rest: Exam[] },
      {
        type: EXAM_TYPE_TABS.PRACTISE;
        approved: Exam[];
        rest: Exam[];
      }
    ];
  }>({
    exams: [],
    taData: [
      { type: EXAM_TYPE_TABS.EXTRA, approved: [], rest: [] },
      { type: EXAM_TYPE_TABS.LIVE, approved: [], rest: [] },
      {
        type: EXAM_TYPE_TABS.PRACTISE,
        approved: [],
        rest: [],
      },
    ],
  });

  const setLoading = (value: boolean) => {
    setState((ps) => {
      return { ...ps, loading: value };
    });
  };

  const setSearchHandler = (text: string) => {
    setState((ps) => {
      return { ...ps, searchText: text };
    });
  };

  const sortExamsBasedOnAssignedTimes = (array: Exam[]) => {
    const a = [...array];
    a.sort(function (a, b) {
      if (
        +convertTimeStampToDate(a.studentTAAssignmentInitialAssignment) !== undefined &&
        +convertTimeStampToDate(b.studentTAAssignmentInitialAssignment) !== undefined
      ) {
        if (
          +convertTimeStampToDate(a.studentTAAssignmentInitialAssignment) >
          +convertTimeStampToDate(b.studentTAAssignmentInitialAssignment)
        ) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 0;
      }
    });
    return a;
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    if (query.get('name') === 'extra') {
      setState((ps) => {
        return { ...ps, tab: EXAM_TYPE_TABS.EXTRA };
      });
    } else if (query.get('name') === 'practise') {
      setState((ps) => {
        return { ...ps, tab: EXAM_TYPE_TABS.PRACTISE };
      });
    } else if (query.get('name') === 'live') {
      setState((ps) => {
        return { ...ps, tab: EXAM_TYPE_TABS.LIVE };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {

    const exType = state.tab === EXAM_TYPE_TABS.LIVE ?
      ExamType.LIVE_EXAM : state.tab === EXAM_TYPE_TABS.PRACTISE ?
        ExamType.PRACTICE_EXAM : ExamType.EXTRA_EXAM



    setLoading(true);
    getTAAssignedExamsRef({
      userUid: appUser.fireUser?.uid || '',
      subjectID: subjectId || '',
      examType: exType
    })
      .then((data) => {
        setLoading(false);

        const d = data.data.data as {
          exams: Exam[];
          taData: [
            { type: EXAM_TYPE_TABS.EXTRA; approved: Exam[]; rest: Exam[] },
            { type: EXAM_TYPE_TABS.LIVE; approved: Exam[]; rest: Exam[] },
            {
              type: EXAM_TYPE_TABS.PRACTISE;
              approved: Exam[];
              rest: Exam[];
            }
          ];
        };

        d.taData[0].approved = sortExamsBasedOnAssignedTimes(d.taData[0].approved);
        d.taData[0].rest = sortExamsBasedOnAssignedTimes(d.taData[0].rest);
        d.taData[0].type = EXAM_TYPE_TABS.EXTRA;

        d.taData[1].approved = sortExamsBasedOnAssignedTimes(d.taData[1].approved);
        d.taData[1].rest = sortExamsBasedOnAssignedTimes(d.taData[1].rest);
        d.taData[1].type = EXAM_TYPE_TABS.LIVE;

        d.taData[2].approved = sortExamsBasedOnAssignedTimes(d.taData[2].approved);
        d.taData[2].rest = sortExamsBasedOnAssignedTimes(d.taData[2].rest);
        d.taData[2].type = EXAM_TYPE_TABS.PRACTISE;

        setTaAssignedExams(d);
      })
      .catch(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tab]);

  return (
    <Fragment>
      <ExamTypesTabs tab={state.tab} onTabChangeClearFilters={() => { }} />
      <TASubjectExamList
        loading={state.loading}
        taData={taAssignedExams.taData}
        tabSelection={state.tab}
        searchText={state.searchText}
        searchHandler={setSearchHandler}
      />
    </Fragment>
  );
};

export default TASubjectModalPapersScreen;
