import React, {FC, useEffect, useState} from 'react';
import {FaEllipsisH, FaHome} from 'react-icons/fa';
import {useHistory, useLocation} from 'react-router';
import './BreadCrum.scss';
import {FaChevronRight} from 'react-icons/fa';
import {convertToTitleCaseAndRemoveHypens} from '../utils';

interface Props {
  className?: string;
  restrictions?: {value: boolean; onClick: Function};
}

const BreadCrum: FC<Props> = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState('');
  const [breadCrum, setBreadCrum] = useState<{segment: string; route: string}[]>([]);

  const titleCaseSegments = [
    'my-subjects',
    'account',
    'resource',
    'settings',
    'verify-phoneno',
    'announcements',
  ];

  useEffect(() => {
    const pathsArray = location.pathname?.split('/') || [];

    pathsArray.shift();

    if (!(state === location.pathname)) {
      setState(location.pathname);
      const array: {segment: string; route: string}[] = [];
      let route = '';
      for (let i = 0; i < pathsArray.length; i++) {
        route = route + '/' + pathsArray[i];

        array.push({
          segment: pathsArray[i],
          route: route,
        });
      }
      setBreadCrum(array);
    }
  }, [location.pathname, state]);

  const generatePath = () => {
    return breadCrum.map(({segment, route}, idx) => {
      if (titleCaseSegments.includes(segment)) {
        segment = convertToTitleCaseAndRemoveHypens(segment);
      } else {
        // segment = removeHypens(segment);
      }

      if (idx > breadCrum.length - 4 && idx < breadCrum.length - 2) {
        return (
          <span
            onClick={() => {
              history.push(route);
            }}
            key={idx}
          >
            <FaChevronRight
              size={15}
              style={{fontWeight: 200, marginLeft: 12, marginRight: 12}}
              color="#73847f"
            />
            <FaEllipsisH
              size={15}
              style={{fontWeight: 200, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}
            />
          </span>
        );
      }

      if (idx === breadCrum.length - 1) {
        return (
          <span
            onClick={() => {
              history.push(route);
            }}
            key={idx}
          >
            <FaChevronRight
              size={15}
              style={{fontWeight: 200, marginLeft: 12, marginRight: 12}}
              color="#73847f"
            />
            <span style={{cursor: 'pointer'}}>{segment}</span>
          </span>
        );
      }
      if (idx > breadCrum.length - 4) {
        return (
          <span
            onClick={() => {
              history.push(route);
            }}
            key={idx}
          >
            <FaChevronRight
              size={15}
              style={{fontWeight: 200, marginLeft: 12, marginRight: 12}}
              color="#73847f"
            />
            <span style={{color: '#73847f', cursor: 'pointer'}}>{segment}</span>
          </span>
        );
      }

      if (!idx) {
        return <span style={{color: '#73847f', cursor: 'pointer'}}>{segment}</span>;
      }

      return null;
    });
  };

  return (
    <div className={`breadcrum ${props.className}`}>
      <div className="home-icon-bg">
        <FaHome
          size={18}
          color="#fff"
          className="home-icon"
          onClick={() => {
            if (!props.restrictions || !props.restrictions.value) {
              history.push('/');
            } else {
              props.restrictions.onClick();
            }
          }}
        />
      </div>
      <div className="d-flex align-items-center">{generatePath()}</div>
    </div>
  );
};

export default BreadCrum;
