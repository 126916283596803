import React, { useEffect, useState } from 'react';
import './ThreeView.scss';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { LessonHierarchy, SubjectHierarchy, UnitHierarchy } from '../dtos/HierarchyDTO';
import { Exam } from '../models/Exam';
import { PaperTypes } from '../enums/paperTypes';
import UnitIcon from './../assets/units.png';

export enum SELECT_TYPE {
  FULL = 'full',
  PARTIAL = 'partial',
  NONE = 'none',
}

const Card = (props: {
  data: any;
  onClickSelect: (id: string) => void;
  onExpend: () => void;
  isOpen?: boolean;
  level?: number;
  isExpendable?: boolean;
  selectType?: SELECT_TYPE;
  className?: string;
}) => {
  const getSelectColor = () => {
    console.log(props.selectType);
    if (props.selectType === SELECT_TYPE.FULL) {
      return '#246BFD';
    }
    if (props.selectType === SELECT_TYPE.PARTIAL) {
      return '#5DCAED';
    }
    return 'transparent';
  };

  return (
    <div className={`defaultCard align-items-center ${props.className ? props.className : ''}`}>
      <div className=" d-flex mr-3 align-items-center">
        <div style={{ width: 50 }} onClick={props.onExpend}>
          {props.isExpendable &&
            (props.isOpen ? (
              <FaChevronDown size={12} color="#979797" style={{ marginRight: 12 }} />
            ) : (
              <FaChevronRight size={12} color="#979797" style={{ marginRight: 12 }} />
            ))}
        </div>
        <div>
          <div
            className={'defaultCard__checkbox '}
            style={{ backgroundColor: getSelectColor() }}
            onClick={() => props.onClickSelect(props.data.id)}
          ></div>
        </div>
      </div>
      <div className="mr-3 defaultCard-img-container">
        {props.level && props.level > 1 ? (
          <img
            className="defaultCard-img defaultCard-img-unit"
            alt="unit cover"
            src={
              props.data.coverImage && props.data.coverImage.includes('firebasestorage')
                ? props.data.coverImage
                : UnitIcon
            }
            width={60}
            height={50}
            data-role={
              props.data.coverImage && props.data.coverImage.includes('firebasestorage')
                ? 'image'
                : 'dummy'
            }
          />
        ) : (
          <img
            className="defaultCard-img defaultCard-img-lesson"
            alt="lesson cover"
            src={props.data.coverImage ? props.data.coverImage : UnitIcon}
            width={97}
            height={68}
          />
        )}
      </div>
      <div className="text-white">
        <div className="defaultCard__title">{props.data.name}</div>
        {props.data.description && (
          <div className="defaultCard___content">{props.data.description}</div>
        )}
      </div>
    </div>
  );
};

// const UnitCard = (props: {data: any, onClickSelect: () => void, onExpend: () => void, isOpen?: boolean, level?: number, isExpendable?: boolean, selectType?: SELECT_TYPE}) => {
//
//   const getSelectColor = () => {
//     if (props.selectType === SELECT_TYPE.FULL) {
//       return '#246BFD';
//     }
//     if (props.selectType === SELECT_TYPE.PARTIAL) {
//       return '#5DCAED';
//     }
//     return 'transparent';
//   };
//
//   return (
//     <div className='defaultCard align-items-center'>
//       <div className=' d-flex mr-3 align-items-center'>
//         <div style={{width: 50}} onClick={props.onExpend}>
//           {props.isExpendable && (
//             props.isOpen ?
//               <FaChevronDown size={12} color='#979797' style={{marginRight: 12}} />
//               :
//               <FaChevronRight size={12} color='#979797' style={{marginRight: 12}} />
//           )}
//         </div>
//         <div>
//
//           <div onClick={props.onClickSelect} className={'defaultCard__checkbox '}
//                style={{backgroundColor: getSelectColor()}}></div>
//         </div>
//       </div>
//       <div className='mr-3'>
//           <img alt='unit cover' src='https://picsum.photos/97/68' width={60} height={50} />
//       </div>
//       <div className='text-white'>
//         <div className='defaultCard__title'>{props.data.name}</div>
//         {props.data.description && <div className='defaultCard___content'>{props.data.description}</div>}
//       </div>
//
//     </div>
//   );
// };
//

const MaterialCard = (props: {
  data: Exam;
  isSelected?: boolean;
  onSelect?: (id: string) => void;
  setSelectType?: (e: React.SetStateAction<SELECT_TYPE>) => void;
  className?: string;
}) => {
  const typeSet = new Set<string>();
  const getExamType = (exam: Exam): string => {
    exam.papers.forEach((p) => {
      if (p.paperType === PaperTypes.STRUCTURED) {
        typeSet.add('STR');
      } else if (p.paperType === PaperTypes.TRUEFALSE) {
        typeSet.add('TF');
      } else if (p.paperType === PaperTypes.ESSAY) {
        typeSet.add('ESY');
      } else {
        typeSet.add('MCQ');
      }
    });
    return Array.from(typeSet).join(' | ');
  };

  useEffect(() => {
    if (props.isSelected && props.setSelectType) {
      props.setSelectType(SELECT_TYPE.FULL);
    } else {
      props.setSelectType && props.setSelectType(SELECT_TYPE.NONE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSelected]);

  return (
    <div className={`defaultCard align-items-center ${props.className ? props.className : ''}`}>
      <div className=" d-flex mr-3 align-items-center">
        <div style={{ width: 50 }}></div>
        <div>
          <div
            className={'defaultCard__checkbox '}
            onClick={() => {
              props.onSelect && props.data.id && props.onSelect(props.data.id);
            }}
            style={{ backgroundColor: props.isSelected ? '#246BFD' : 'transparent' }}
          ></div>
        </div>
      </div>
      <div className="mr-3">
        <div className="defaultCard__exam_type">{getExamType(props.data)}</div>
      </div>
      <div className="text-white">
        <div className="defaultCard__title">{props.data.examTitle}</div>
      </div>
    </div>
  );
};

const UnitSection = (props: {
  data: UnitHierarchy;
  onSelect: (id: string) => void;
  lessonPair?: SelectedPair | null;
}) => {
  const [open, setOpen] = useState(false);

  const getSelectType = (selectedPair?: SelectedPair | null, id?: string): SELECT_TYPE => {
    if (selectedPair && selectedPair.Units && id) {
      console.log(selectedPair.Units.includes(id));
      if (selectedPair.Units.includes(id)) {
        return SELECT_TYPE.FULL;
      }
    }
    return SELECT_TYPE.NONE;
  };
  return (
    <div>
      <Card
        data={props.data}
        onClickSelect={() => {
          props.data.id && props.onSelect(props.data.id);
        }}
        onExpend={() => {
          setOpen(!open);
        }}
        isExpendable={props.data.materials && props.data.materials.exam.length > 0}
        level={2}
        isOpen={open}
        selectType={getSelectType(props.lessonPair, props.data.id)}
        className="defaultCard-sub-units  testing-units"
      />
      {open && (
        <div className="ml-5" style={{ paddingTop: '.5rem' }}>
          {props.data.materials &&
            props.data.materials.exam.map((exam, index: any) => {
              return (
                <div key={index}>
                  <MaterialCard
                    data={exam}
                    isSelected={getSelectType(props.lessonPair, props.data.id) === SELECT_TYPE.FULL}
                    className={'defaultCard-sub-units-exams_2'}
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

const LessonSection = (props: {
  data: LessonHierarchy;
  setSelectedPair: React.Dispatch<React.SetStateAction<SelectedPair[]>>;
  selectedPair: SelectedPair[];
}) => {
  console.log(props.data);
  const [isOpen, setOpen] = useState(false);
  const [lessonPair, setLessonPair] = useState<SelectedPair | null>();

  useEffect(() => {
    if (props.data.id) {
      const pair = props.selectedPair.find((p) => p.lessonId === props.data.id);
      if (pair) {
        console.log(pair);
        setLessonPair(pair);
      }
    }
  }, [props.selectedPair, props.data.id]);

  // useEffect(()=>{
  //
  // },[])

  const onUnitSelect = (
    unitId: string,
    Spair?: SelectedPair[],
    isLessonNotSelected?: boolean
  ): SelectedPair[] => {
    let pair = lessonPair;
    if (pair) {
      if (pair.Units) {
        const index = pair.Units.findIndex((u) => u === unitId);
        if (index === -1) {
          if (!isLessonNotSelected) {
            pair.Units.push(unitId);
          }

          if (props.data.units) {
            const allUnits = props.data.units.map((u) => u.id);
            if (
              allUnits.every((u) => {
                return pair && pair.Units && u && pair.Units.includes(u);
              })
            ) {
              pair.selectedType = SELECT_TYPE.FULL;
            } else {
              pair.selectedType = SELECT_TYPE.PARTIAL;
            }
          }
        } else {
          pair.Units.splice(index, 1);
          if (pair.Units.length === 0) {
            pair.selectedType = SELECT_TYPE.NONE;
          } else {
            pair.selectedType = SELECT_TYPE.PARTIAL;
          }
          console.log(pair);
        }
      } else {
        pair.Units = [unitId];
        pair.selectedType = SELECT_TYPE.PARTIAL;
      }
      let newPair = [...props.selectedPair];
      if (Spair) {
        newPair = [...Spair];
      }

      const index = newPair.findIndex((p) => p.lessonId === props.data.id);
      if (index === -1) {
        newPair.push(pair);
      } else {
        newPair[index] = pair;
      }
      return newPair;
    }
    return Spair || props.selectedPair;
  };

  const onSelectMaterial = (materialId?: string, lPair?: any) => {
    if (materialId) {
      let pair = lPair || lessonPair;
      if (pair) {
        if (pair.materialId) {
          const index = pair.materialId.findIndex((m: string) => m === materialId);
          if (index === -1) {
            pair.materialId.push(materialId);
          } else {
            pair.materialId.splice(index, 1);
          }
        } else {
          pair.materialId = [materialId];
        }
        const newPair = [...props.selectedPair];
        const index = newPair.findIndex((p) => p.lessonId === props.data.id);
        if (index === -1) {
          newPair.push(pair);
        } else {
          newPair[index] = pair;
        }
        props.setSelectedPair(newPair);
      }
    }
  };
  const getIsMaterialSelected = (materialId?: string): boolean => {
    if (materialId && lessonPair && lessonPair.materialId) {
      return lessonPair.materialId.includes(materialId);
    }
    return false;
  };
  const onSelectLesson = () => {
    console.log('onSelectLesson');
    if (props.data.id) {
      let pair = lessonPair;
      let selectedPair = [...props.selectedPair];
      let isLessonNotSelected = false;
      if (pair) {
        if (pair.selectedType === SELECT_TYPE.NONE) {
          pair.selectedType = SELECT_TYPE.FULL;
          isLessonNotSelected = false;
        } else {
          console.log('none');
          pair.selectedType = SELECT_TYPE.NONE;
          isLessonNotSelected = true;
        }
        props.data.units &&
          props.data.units.forEach((u) => {
            if (u.id) {
              selectedPair = onUnitSelect(u.id, selectedPair, isLessonNotSelected);
            }
          });
        const newPair = [...props.selectedPair];
        const index = newPair.findIndex((p) => p.lessonId === props.data.id);
        if (index === -1) {
          newPair.push(pair);
        }
        props.setSelectedPair(newPair);
        // const newPair = [...props.selectedPair];
        // const index = newPair.findIndex(p => p.lessonId === props.data.id);
        // if (index === -1) {
        //   newPair.push(pair);
        // }else {
        //   newPair[index] = pair;
        // }
        // props.setSelectedPair(newPair);
      }
    }
  };

  // const firstchild = 'defaultCard-sub-units-exams-container_first-child';
  const unitwiseExaminataions = {
    materials: props.data.materials,
    name: 'Lesson Wise Examintaions',
    description: 'All lesson wise examinations',
  } as UnitHierarchy;
  const [openUnit, setOpenUnit] = useState(false);
  return (
    <>
      {console.log(lessonPair?.selectedType)}
      <Card
        data={props.data}
        onClickSelect={(id) => {
          onSelectLesson();
        }}
        onExpend={() => {
          setOpen(!isOpen);
        }}
        selectType={lessonPair?.selectedType}
        isOpen={isOpen}
        isExpendable={props.data.units && props.data.units.length > 0}
      />
      {isOpen && (
        <div className="defaultCard-sub-units" style={{ borderTop: '1px solid #262844' }}>
          {props.data.units &&
            props.data.units.map((unit, index: any) => {
              return (
                <div key={index}>
                  <UnitSection
                    data={unit}
                    onSelect={(id) => {
                      props.setSelectedPair(onUnitSelect(id));
                    }}
                    lessonPair={lessonPair}
                  />
                </div>
              );
            })}
          {props.data.materials.exam && (
            <>
              <div className="lessonwise-exams" style={{ paddingTop: '.2rem' }}>
                <div>
                  <Card
                    data={unitwiseExaminataions}
                    onExpend={() => {
                      setOpenUnit(!openUnit);
                    }}
                    onClickSelect={() => {}}
                    isExpendable={props.data.materials && props.data.materials.exam.length > 0}
                    level={2}
                    isOpen={openUnit}
                    selectType={SELECT_TYPE.NONE}
                    className="defaultCard-sub-units  testing-units"
                  />
                  {openUnit && (
                    <div className="ml-5 unit-exams-padding" style={{ paddingTop: '.5rem' }}>
                      {props.data.materials &&
                        props.data.materials.exam.map((exam, index: any) => {
                          return (
                            <div key={index}>
                              <MaterialCard
                                data={exam}
                                onSelect={() => {
                                  onSelectMaterial(exam.id);
                                }}
                                isSelected={getIsMaterialSelected(exam.id)}
                                className={'defaultCard-sub-units-exams_2'}
                              />
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>

              {/* <div
                    key={index}
                    className={
                      index === 0
                        ? 'defaultCard-sub-units-exams-container ' + firstchild
                        : 'defaultCard-sub-units-exams-container'
                    }
                  >
                    <MaterialCard
                      data={exam}
                      onSelect={() => {
                        onSelectMaterial(exam.id);
                      }}
                      isSelected={getIsMaterialSelected(exam.id)}
                      className="defaultCard-sub-units-exams"
                    />
                  </div> */}
            </>
          )}
        </div>
      )}
    </>
  );
};

export interface SelectedPair {
  lessonId?: string;
  Units?: string[];
  selectedType?: SELECT_TYPE;
  materialId?: string[];
}

interface ThreeViewProps {
  data: SubjectHierarchy;
  setSelectedPairs: React.Dispatch<React.SetStateAction<SelectedPair[]>>;
  setSubjectLevelMaterial: React.Dispatch<React.SetStateAction<string[]>>;
  subjectLevelMaterial: string[];
  selectedPairs: SelectedPair[];
}

function TreeView(props: ThreeViewProps) {
  const { data, setSelectedPairs, selectedPairs, setSubjectLevelMaterial, subjectLevelMaterial } =
    props;
  console.log(subjectLevelMaterial);
  const [isShowPapers, setShowPapers] = useState(false);
  useEffect(() => {
    if (data.lessons) {
      let lessonPair = data.lessons.map((lesson) => {
        return {
          lessonId: lesson.id,
          selectedType: SELECT_TYPE.NONE,
          Units: [],
          materialId: [],
        } as SelectedPair;
      });
      lessonPair = lessonPair.map(
        (obj) => selectedPairs.find((o) => o.lessonId === obj.lessonId) || obj
      );
      console.log(lessonPair);
      setSelectedPairs(lessonPair);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const onClickMaterial = (materialId: string) => {
    if (subjectLevelMaterial.includes(materialId)) {
      setSubjectLevelMaterial(subjectLevelMaterial.filter((id) => id !== materialId));
    } else {
      setSubjectLevelMaterial([...subjectLevelMaterial, materialId]);
    }
  };
  // const onSelectLesson = (lessonId: string, type: SELECT_TYPE) => {
  //   const newPair = [...selectedPairs];
  //   const index = newPair.findIndex(p => p.lessonId === lessonId);
  //   if (index !== -1) {
  //     newPair[index].selectedType = type;
  //   }
  // };

  const [tempSelectType, setTempSelectType] = useState(SELECT_TYPE.NONE);

  const selectHandler = (e: React.SetStateAction<SELECT_TYPE>) => {
    setTempSelectType(e);
  };

  return (
    <div className="treeview_container">
      {data.lessons &&
        data.lessons.length > 0 &&
        data.lessons.map((item, index: any) => {
          return (
            <LessonSection
              key={index}
              data={item}
              setSelectedPair={setSelectedPairs}
              selectedPair={selectedPairs}
            />
          );
        })}
      <div style={{ paddingTop: '0' }}>
        {data.materials && (
          <div>
            <Card
              data={{ name: 'Subject Wise Examinations' }}
              onClickSelect={() => {}}
              isExpendable={true}
              onExpend={() => {
                setShowPapers(!isShowPapers);
              }}
              isOpen={isShowPapers}
              selectType={tempSelectType}
              className="treeview-model-border"
            />
            {isShowPapers && (
              <div>
                {data.materials.exam.map((exam, index: any) => {
                  return (
                    <div key={index}>
                      <MaterialCard
                        data={exam}
                        isSelected={!!(exam.id && subjectLevelMaterial.includes(exam.id))}
                        onSelect={onClickMaterial}
                        setSelectType={selectHandler}
                        className={'defaultCard-sub-exams'}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TreeView;
