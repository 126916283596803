import React, { FC } from 'react';
import FilterRadioButton from './FilterRadioButton';
import { ManageUsersState } from './SettingsManageUsersBody';
import './UserRoasterFilterPopup.scss';

interface Props {
  show: boolean;
  onCloseModal: () => void;
  setState: (state: any) => void;
  state: ManageUsersState;
  initialState: ManageUsersState;
  onApplyfilter: () => void;
  tab: string;
  clearFilterHandler?: () => void;
}

const UserRoasterFilterPopup: FC<Props> = (props) => {
  const roles = [
    { name: 'Instructor', label: 'Instructor' },
    { name: 'Teaching Assistant', label: 'Teaching Assistant' },
  ];

  const status = [
    { name: 'Active', label: 'Active' },
    { name: 'Inactive', label: 'Inactive' },
  ];

  // const submissionType = [
  //   { name: 'Admin Added', label: 'Admin-Added' },
  //   { name: 'Online Paid', label: 'Online-Paid' },
  // ];

  return (
    <div className={`muf-pop-up position-absolute ${props.show ? 'd-block' : 'd-none'}`}>
      {props.tab === 'INSTRUCTORS-AND-TA' ? (
        <div className="muf-pop-up__user-type">
          <div className="user-type">User Type</div>
          <div className="d-flex">
            {roles.map((item, idx) => (
              <div key={idx} style={{ marginRight: '20px' }}>
                <FilterRadioButton
                  onClick={() => {
                    props.setState((ps: any) => {
                      return { ...ps, role: item.name };
                    });
                  }}
                  item={item}
                  value={props.state.role}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {/* <div className="muf-pop-up__user-type mt-5">
        <div className="user-type">Submission Type</div>
        <div className="d-flex">
          {submissionType.map((item, idx) => (
            <div key={idx} style={{marginRight: '40px'}}>
              <FilterRadioButton
                onClick={() => {
                  props.setState((ps: any) => {
                    return {...ps, submissionType: item.name};
                  });
                }}
                item={item}
                // value={props.state.submissionType}
              />
            </div>
          ))}
        </div>
      </div> */}

      {props.tab === 'STUDENTS' ? (
        <div className="muf-pop-up__status">
          <div className="pop-up-status">Status</div>
          <div className="d-flex">
            {status.map((item, idx) => (
              <div key={idx}>
                <FilterRadioButton
                  onClick={() => {
                    props.setState(() => {
                      return { ...props.state, enabled: item.name };
                    });
                  }}
                  item={item}
                  value={props.state.enabled}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}

      <div className="muf-pop-up__btns">
        <div
          onClick={() => {
            props.clearFilterHandler && props.clearFilterHandler();
          }}
        >
          Clear Filters
        </div>
        <div>
          <div style={{ cursor: 'pointer' }} onClick={props.onCloseModal}>
            Cancel
          </div>
          <button
            className="apply"
            onClick={() => {
              props.onApplyfilter();
              props.onCloseModal();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserRoasterFilterPopup;
