import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import AssignSubjects from '../../components/AssignSubjects';
import PrimaryNav from '../../components/PrimaryNav';

import SubjectDirectiveHeader from '../../components/SubjectDirectiveHeader';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import LayoutNew from '../../layouts/LayoutNew';

const AdminSubjectSettingsScreen = () => {
  const {subjectId} = useParams() as {subjectId: string};

  const [loading, setLoading] = useState<boolean>(false);

  const setLoadingHandler = (value: boolean) => {
    setLoading(value);
  };

  const appBody = (
    <AssignSubjects subjectId={subjectId} setIsLoading={setLoadingHandler} loading={loading} />
  );
  let primaryNavigation = <PrimaryNav />;
  let secondaryNavigation = <SubjectSecondaryNav />;
  let appHeader = <SubjectDirectiveHeader isLoading={loading} />;

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

export default AdminSubjectSettingsScreen;
