import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useUnits } from '../context/UnitsContext';

import { Unit } from '../models/Unit';
import UnitCard from './UnitCard';
import './UnitList.scss';

interface Props {
  loadingHandler?: (value: boolean) => void;
}

const UnitList: FC<Props> = (props) => {
  const appUnits = useUnits();
  const { subjectId, lessonId } = useParams() as { subjectId: string; lessonId: string };

  const predicate = (unit: Unit) => unit.subjectId === subjectId && unit.lessonId === lessonId;

  return (
    <>
      <div className="unit-list">
        <div className="unit-list__title">Units</div>
        <div style={{ height: 'calc(100vh - 165px)', overflow: 'auto' }}>
          <div className="unit-list__list">
            {appUnits.units.filter(predicate).map((unit) => {
              return <UnitCard unit={unit} key={unit.id} loadingHandler={props.loadingHandler} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitList;
