import React, {FC} from 'react';
import './ImageField.scss';
import TextInput from './TextInput';

interface Props {
  style?: React.CSSProperties;
  stateName: string;
  stateValue: string;
  state: any;
  setState: Function;
  error: string;
  placeHolder: 'Cover Image';
  formLoading: boolean;
  fileLoading: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disableTextField?: React.CSSProperties;
  readonly?: boolean;
}

const ImageField: FC<Props> = (props) => {
  return (
    <div className="cover-image" style={{...props.style}}>
      <TextInput
        stateName="coverImage"
        stateValue={props.stateValue}
        state={props.state}
        setState={props.setState}
        error={props.error}
        placeHolder={props.placeHolder}
        style={props.disableTextField}
        readonly={props.readonly}
      />

      <div className="add-cover-image-btn-wrap position-relative">
        <input
          type="file"
          className="position-absolute"
          style={{opacity: 0}}
          onChange={(e) => {
            if (!(props.formLoading || props.fileLoading)) {
              props.onChange(e);
            }
          }}
        />
        <button className="add-cover-image-btn">Upload Image</button>
      </div>
    </div>
  );
};

export default ImageField;
