import React, {FC, ForwardedRef, forwardRef} from 'react';
import DatePicker, {CalendarContainer} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerWithIcon.scss';
import {FaCalendarAlt} from 'react-icons/fa';
import {Col, Row} from 'react-bootstrap';

interface Props {
  name: string;
  className?: string;
  stateName: string;
  stateValue: Date;
  state: any;
  setState: Function;
  error: string;
  minDate?: Date;
}

const DatePickerWithIcon: FC<Props> = (props) => {
  let borderColor =
    props.error === 'NO-ERROR'
      ? {borderBottom: '1px solid #20D167'}
      : props.error === ''
      ? {}
      : {borderBottom: '1px solid #dc3545'};

  const inputChangeHandler = (state: any, name: string, value: Date | null) => {
    props.setState({
      ...state,
      [name]: {...state[name], value: value},
    });
  };
  const CustomInput = forwardRef((props: any, ref: ForwardedRef<any>) => (
    <>
      <Row>
        <Col>
          <input
            {...props}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            ref={ref}
            style={borderColor}
            className="date-text w-100"
          />
        </Col>
        <Col className="col-auto">
          <FaCalendarAlt color={'#246BFD'} onClick={props.onClick} />
        </Col>
      </Row>
    </>
  ));
  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: {...state[name], error: ''},
    });
  };
  let errorJSX = null;

  if (props.error !== 'NO-ERROR') {
    errorJSX = <div className={`w-100 err-styles mt-2`}>{props.error}</div>;
  }
  const MyContainer = (props: any) => {
    return (
      <div style={{padding: '0px', color: '#fff', right: 0}}>
        <CalendarContainer className={props.className}>
          <div style={{position: 'relative'}}>{props.children}</div>
        </CalendarContainer>
      </div>
    );
  };
  return (
    <div>
      <div className={`datepicker-wrapper ${props.className}`} style={borderColor}>
        <div className="datepicker-name">{props.name}</div>

        <DatePicker
          // minDate={props.minDate}
          className="datepicker-comp"
          selected={props.stateValue}
          onChange={(date: Date) => {
            inputChangeHandler(props.state, props.stateName, date);
          }}
          dateFormat="dd / MM / yyyy"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={<CustomInput />}
          calendarContainer={MyContainer}
          onFocus={() => {
            resetError(props.state, props.stateName);
          }}
        />
      </div>
      {errorJSX}
    </div>
  );
};

export default DatePickerWithIcon;
