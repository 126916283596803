import React, { FC, useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';
import useDebounce from './../hooks/useDebounce';
import './SearchWidget.scss';

interface Props {
  className?: string;
  onSearch: (searchTerm: string) => void;
  style?: React.CSSProperties;
  placeHolderText: string;
  onClickMenu?: (e: any) => void;
  isShowMenu?: boolean;
  data?: any
}

const SearchWidget: FC<Props> = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  useDebounce(searchTerm, 1000, (val) => {
    props.onSearch(val);
  });

  useEffect(() => {

    if (props.data) {

      setSearchTerm('')

    }

  }, [props.data])

  return (
    <div className={`search-widget ${props.className}`} style={props.style}>
      {props.onClickMenu && props.isShowMenu && (
        <div
          style={{ color: 'white', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={props.onClickMenu}
        >
          <BsThreeDotsVertical />
        </div>
      )}
      <input
        style={{
          color: 'white',
        }}
        className="search-widget__input"
        onChange={(event) => {
          setSearchTerm(event.target.value);
        }}
        placeholder={props.placeHolderText}
        value={searchTerm}
      />
      <div className="search-widget__icon">
        <FaSearch color="#fff" size={15} />
      </div>
    </div>
  );
};

export default React.memo(SearchWidget);
