import firebase from 'firebase';
import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {Subject} from '../models/Subject';
import {sortBySubjectName} from '../utils';
import {useAppUser} from './UserContext';

export interface SubjectsContext {
  allSubjects: Subject[] | null;
  userSubjects: Subject[] | null;
  isLoading: boolean;
  fetchSubjects: () => void;
  setLoading: (value: boolean) => void;
  addSubject: (subject: Subject) => void;
  updateSubject: (subject: Subject) => void;
  removeSubject: (id: string) => void;
  setSubjects: (subjects: Subject[]) => void;
}

interface State {
  allSubjects: Subject[] | null;
  userSubjects: Subject[] | null;
  isLoading: boolean;
}

const initialContext: SubjectsContext = {
  allSubjects: null,
  userSubjects: null,
  isLoading: false,
  fetchSubjects: () => {},
  setLoading: (value: boolean) => {},
  addSubject: (subject: Subject) => {},
  updateSubject: (subject: Subject) => {},
  removeSubject: (id: string) => {},
  setSubjects: (subjects: Subject[]) => {},
};

const initialState: State = {
  allSubjects: [],
  userSubjects: [],
  isLoading: false,
};

const Context = createContext<SubjectsContext>(initialContext);

export const SubjectProviderUpdated: FC = ({children}) => {
  const appUser = useAppUser();

  const [state, setState] = useState(initialState);

  useEffect(() => {
    fetchSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubjects = () => {
    const subjects: Subject[] = [];

    setState((ps) => {
      return {
        ...ps,
        isLoading: true,
      };
    });

    firebase
      .firestore()
      .collection('subjects')
      .get()
      .then((data) => {
        data.forEach((doc) => {
          subjects.push({id: doc.id, ...doc.data()} as any);
        });

        const userSubs = subjects.filter((sub) => sub.createdBy === appUser.fireUser?.uid);

        setState((ps) => {
          return {
            ...ps,
            allSubjects: sortBySubjectName(subjects),
            userSubjects: sortBySubjectName(userSubs),
            isLoading: false,
          };
        });
      });
  };

  const addSubject = (subject: Subject) => {
    const currentSubjects = state.allSubjects ? [...state.allSubjects] : null;
    if (currentSubjects) {
      currentSubjects.push(subject);
      setState((ps) => {
        return {
          ...ps,
          allSubjects: sortBySubjectName(currentSubjects),
          isLoading: false,
        };
      });
    }
  };

  const updateSubject = (subject: Subject) => {
    const currentSubjects = state.allSubjects ? [...state.allSubjects] : null;

    if (currentSubjects) {
      const updatedArray = currentSubjects.filter((sub) => sub.id !== subject.id);

      updatedArray.push(subject);
      setState((ps) => {
        return {
          ...ps,
          allSubjects: sortBySubjectName(updatedArray),
          isLoading: false,
        };
      });
    }
  };

  const removeSubject = (id: string) => {
    const currentSubjects = state.allSubjects ? [...state.allSubjects] : null;

    if (currentSubjects) {
      const updatedArray = currentSubjects.filter((sub) => sub.id !== id);

      setState((ps) => {
        return {
          ...ps,
          allSubjects: sortBySubjectName(updatedArray),
          isLoading: false,
        };
      });
    }
  };

  const setLoading = (value: boolean) => {
    setState((ps) => ({
      ...ps,
      isLoading: value,
    }));
  };

  const setSubjects = (subjects: Subject[]) => {
    setState((ps) => {
      return {
        ...ps,
        allSubjects: sortBySubjectName(subjects),
        isLoading: false,
      };
    });
  };

  return (
    <Context.Provider
      value={{
        ...state,
        fetchSubjects,
        setLoading,
        addSubject,
        updateSubject,
        removeSubject,
        setSubjects,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppSubjects = (): SubjectsContext => useContext(Context);
