import { ExamToDoDTO } from "../dtos/ExamToDoDTO";
import { ExamProgressState } from "../enums/ExamProgressState";
import { ExamType } from "../enums/ExamType";
import StudentLiveExamCard from "./StudentLiveExamCard";
import './StudentLiveExamView.scss';

const StudentIncompleteLiveExamFilter = (props: {
    ExamsList: any[];
    isLoading: boolean;
    setSelectedExamsHandlers?: (exam: ExamToDoDTO | null) => void;
    filterValue: string;
    className: string;
    type: ExamProgressState;
}) => {
    return (
        <div className={props.className}>
            {props.ExamsList.filter(
                (ex) => ex.examType === ExamType.LIVE_EXAM && ex.examProgressState === props.type
            )
                .filter((text, index, array) => {
                    if (
                        props.filterValue === '' ||
                        props.filterValue === null ||
                        props.filterValue === undefined
                    ) {
                        return array;
                    } else {
                        return text.examTitle
                            .toLowerCase()
                            .trim()
                            .includes(props.filterValue.toLowerCase().trim());
                    }
                })
                .map((exam: ExamToDoDTO, idx: React.Key | null | undefined) => {
                    return (
                        <StudentLiveExamCard
                            type={props.type}
                            exam={exam}
                            isLoading={props.isLoading}
                            setSelectedExamsHandlers={props.setSelectedExamsHandlers}
                            key={idx}
                        />
                    );
                })}
        </div>
    );
};


export default StudentIncompleteLiveExamFilter