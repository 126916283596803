import React, {useState} from 'react';
import {ClipLoader} from 'react-spinners';
import './VerifyPhoneNumber.scss';
import OtpInput from 'react-otp-input';
import {Modal} from 'react-bootstrap';


const VerifyPhoneNumber = () => {
  const initialState = {
    loading: false,
    otp: ''
  };
  const [state, setState] = useState(initialState);
  const handleChange = (otp: any) => {
    setState((pS) => ({
      ...pS,
      otp: otp
    }));
  };

  return (
    <div>
      <Modal className='verify-phone' show={true}>
        <div className='verify-phone'>
          <div className='verify-phone__title'>Verify Phone Number</div>
          <div className='verify-phone__subtitle'>
            Enter the OTP sent to your phone
          </div>
          <div className='d-flex flex-row mt-5 otp-input-wrapper'>
            <OtpInput
              value={state.otp}
              onChange={handleChange}
              numInputs={4}
              isInputNum={true}
              className='otp-input'
            />
          </div>
          <div className='verify-phone__btn'>
            <span className='d-inline-block mr-2'>Verify</span>{' '}
            <ClipLoader color={'purple'} loading={state.loading} size={18} />
          </div>
        </div>
      </Modal>

    </div>
  );
};
export default VerifyPhoneNumber;
