import React, { FC } from 'react';
import './CustomProgressBar.scss';

interface Props {
  progress: number;
  width?: number;
  color?: string;
}

const CustomProgressBar: FC<Props> = (props) => {
  return (
    <div
      className="custom-progress-bar"
      style={
        props.color === 'green'
          ? { width: props.width ? `${props.width}%` : '70%', backgroundColor: '#565463' }
          : { width: props.width ? `${props.width}%` : '70%' }
      }
    >
      <div
        className="custom-progress-bar__line"
        style={
          props.color === 'green'
            ? { width: props.progress + '%', backgroundColor: '#20D167' }
            : { width: props.progress + '%' }
        }
      ></div>
    </div>
  );
};

export default CustomProgressBar;
