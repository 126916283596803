import firebase from 'firebase';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GradingType } from '../constants';
import { useAppUser } from '../context/UserContext';
import './ExamDashboard.scss';
import LoadingIndicator from './LoadingIndicator';
import MarkDistribution from './MarkDistribution';
import StudentExamStatistics from './StudentExamStatistics';
import TopStudents from './TopStudents';

interface Props {
  gradeType: string | undefined;
  isLoading: boolean;
}

const ExamDashboard: FC<Props> = (props) => {
  const getExamStatsRef = firebase.functions().httpsCallable('getExamStats');

  // const appSubjects = useSubjects();

  const params = useParams() as { subjectId: string; lessonId: string; examId: string };

  const appUser = useAppUser();

  const [state, setState] = useState<{
    topStudents: {
      rank: { uid: string; score: number }[];
      stillToMark: boolean;
    };
    distribution: { percentage: number; count: number }[];
    meanMark: number;
    lowestMark: number;
    highestMark: number;
    totalMark: number;
    stats: {
      enrolled: number;
      submitted: number;
      notSubmitted: number;
      markingCompleted: number;
      notMarked: number;
    };
    loading: boolean;
    isExamEnded: boolean;
  }>({
    topStudents: {
      rank: [],
      stillToMark: false,
    },
    distribution: [
      { percentage: 0, count: 0 },
      { percentage: 0, count: 0 },
      { percentage: 0, count: 0 },
      { percentage: 0, count: 0 },
      { percentage: 0, count: 0 },
      { percentage: 0, count: 0 },
      { percentage: 0, count: 0 },
      { percentage: 0, count: 0 },
      { percentage: 0, count: 0 },
      { percentage: 0, count: 0 },
    ],
    meanMark: 0,
    lowestMark: 0,
    highestMark: 0,
    totalMark: 0,
    stats: {
      enrolled: 0,
      submitted: 0,
      notSubmitted: 0,
      markingCompleted: 0,
      notMarked: 0,
    },
    loading: false,
    isExamEnded: false,
  });

  const setLoading = (value: boolean) => {
    setState((ps) => {
      return { ...ps, loading: value };
    });
  };

  useEffect(() => {
    if (
      appUser.firestoreUser?.userRole?.isAdmin ||
      appUser.firestoreUser?.userRole?.isInstructor ||
      appUser.firestoreUser?.userRole?.isTeachingAssistant
    ) {
      setLoading(true);
      getExamStatsRef({
        examId: params.examId,
      })
        .then((res) => {
          setState({ ...res.data.data, loading: false });
        })
        .catch(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(state.loading || props.isLoading) && <LoadingIndicator />}
      <div className="instructor-dashboard">
        <div className="instructor-dashboard__right">
          <MarkDistribution
            totalMarks={state.totalMark}
            className="instructor-dashboard__graph"
            utilityClass="two"
            highestMark={state.highestMark}
            meanMark={state.meanMark}
            lowestMark={state.lowestMark}
            distribution={state.distribution}
            gradingType={props.gradeType || GradingType.PERCENTAGE}
          />
          <StudentExamStatistics
            className="instructor-dashboard__stats"
            entrolled={state.stats.enrolled}
            notSubmitted={state.stats.notSubmitted}
            submitted={state.stats.submitted}
          />
        </div>
        <div className="instructor-dashboard__left">
          <TopStudents
            totalMark={state.totalMark}
            list={state.topStudents.rank}
            isExamEnded={state.isExamEnded}
            className="instructor-dashboard__topstudents"
            stillToMark={state.topStudents.stillToMark}
            gradeType={props.gradeType}
          />
        </div>
      </div>
    </>
  );
};

export default ExamDashboard;
