import React from 'react';
import {useParams} from 'react-router-dom';
import PrimaryNav from '../../components/PrimaryNav';

import SubjectDirectiveHeader from '../../components/SubjectDirectiveHeader';
// import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import ViewNotices from '../../components/ViewNotices';
import LayoutNew from '../../layouts/LayoutNew';

interface Props {
  secondaryNav: any;
}

const StudentSubjectAnnouncementsScreen = (props:Props) => {
  const {subjectId} = useParams() as {subjectId: string};
  const appBody = <ViewNotices subjectid={subjectId} />;
  let primaryNavigation = <PrimaryNav />;
  // let secondaryNavigation = <SubjectSecondaryNav />;
  let appHeader = <SubjectDirectiveHeader />;

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={props.secondaryNav}
      header={appHeader}
      body={appBody}
    />
  );
};

export default StudentSubjectAnnouncementsScreen;
