import React from 'react';
import PageNotFound from '../../components/PageNotFound';
import PageNotFoundHeader from '../../components/PageNotFoundHeader';
import PrimaryNav from '../../components/PrimaryNav';
import LayoutNew from '../../layouts/LayoutNew';

const PageNotFoundScreen = () => {
  const primaryNavigation = <PrimaryNav />;
  const appBody = <PageNotFound />;
  const header = <PageNotFoundHeader />;

  return <LayoutNew header={header} primaryNav={primaryNavigation} body={appBody} />;
};

export default PageNotFoundScreen;
