import React, { useEffect, useState } from 'react';
import useSessionStorage from '../../hooks/useSessionStorage';
import { useFirestore } from 'reactfire';
import { Subject } from '../../models/Subject';
import { useAppUser } from '../../context/UserContext';
import { useSubjects } from '../../context/SubjectsContext';
import { SCREEN } from '../../constants';
import { getRole, sortBySubjectName, sortSubjects } from '../../utils';
import SubjectsHeader from '../../components/SubjectsHeader';
import PrimaryNav from '../../components/PrimaryNav';
import firebase from 'firebase';
import Notification from './../../components/Notification';
import LoadingIndicator from '../../components/LoadingIndicator';
import SearchBox from '../../components/SearchBox';
import { FaFilter } from 'react-icons/fa';
import SubjectCard from '../../components/SubjectCard';
import AddSubjectModal from '../../components/AddSubjectModal';
import AlertPopup from '../../components/AlertPopup';
import LayoutNew from '../../layouts/LayoutNew';
import EnrollToSubjectModal from '../../components/EnrollToSubjectModal';
import ListLayout from '../../layouts/ListLayout';
import PaginationNew from '../../components/PaginationNew';
import SubjectModalFilter from '../../components/SubjectModalFilter';
import AddFirstSubjectButton from '../../components/AddFirstSubjectButton';
import './Subjects.scss';

export interface SubjectState {
  subjects: Subject[];
  initSubjects: Subject[];
  show: boolean;
  filterPopUpOn: boolean;
  filters: { grade: string | null; year: string | null };
  selectedSubject: Subject | null;
  isLoading: boolean;
  enrollSearchText: string;
  subjectSearchText: string;
}
const initialPagination = {
  total_pages: 0,
  page_number: 0,
};
const ITEM_COUNT_IN_PAGE: number = 10;

const Subjects = () => {
  const user = useAppUser();
  const appSubjects = useSubjects();

  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [pagination, setPagination] = useState(initialPagination);

  const [state] = useSessionStorage(SCREEN.SUBJECTS, {
    currentTab: 'My Subjects',
  });

  const [subjectsState, setSubjectState] = useState<SubjectState>({
    subjects: [],
    initSubjects: [],
    show: false,
    filterPopUpOn: false,
    filters: { grade: null, year: null },
    selectedSubject: null,
    isLoading: false,
    enrollSearchText: '',
    subjectSearchText: '',
  });

  const [isClearSearch, setIsClearSearch] = useState<boolean>(false);

  const clearSearchHandler = (value: boolean) => {
    setIsClearSearch(value);
  };

  const showAlertHandler = () => {
    setShowAlert(true);
  };

  const closeAlertHandler = () => {
    setShowAlert(false);
  };

  const openModalHandler = () => {
    setSubjectState((ps) => {
      return { ...ps, show: true };
    });
  };

  const closeModalHandler = (resetModalState?: () => void) => {
    setSubjectState((ps) => {
      return { ...ps, show: false, enrollSearchText: '', subjectSearchText: '' };
    });
    removeSelectedSubject();
    clearSearchHandler(true);
    if (!user.firestoreUser?.userRole?.isStudent) {
      resetModalState && resetModalState();
    }
  };

  const searchTextHandler = (text: string) => {
    setSubjectState((ps) => {
      return { ...ps, subjectSearchText: text };
    });
  };

  const changeEnrollSearchText = (value: string) => {
    setSubjectState((ps) => {
      return { ...ps, enrollSearchText: value };
    });
  };

  const store = useFirestore();

  useEffect(() => {
    const subjects: Subject[] = [];

    setSubjectState((ps) => {
      return {
        ...ps,
        isLoading: true,
      };
    });

    store
      .collection('subjects')
      .get()
      .then((data) => {
        data.forEach((doc) => {
          subjects.push({ id: doc.id, ...doc.data() } as any);
        });

        const subjectIdsArray = subjects
          .filter((sub) => sub.assignedUsers?.includes(user.firestoreUser?.uid || ''))
          .map((s) => s.id);

        const { isAdmin, isInstructor, isTeachingAssistant, isStudent } = getRole(
          user.firestoreUser?.userRole
        );

        const userRoleBasedList = isAdmin
          ? subjects
          : isInstructor
          ? subjects.filter(
              (sub) =>
                sub.createdBy === user.firestoreUser?.uid || subjectIdsArray?.includes(sub.id || '')
            )
          : isStudent
          ? subjects.filter((sub) => sub.enrolledStudents?.includes(user.firestoreUser?.uid || ''))
          : isTeachingAssistant
          ? subjects.filter((sub) => subjectIdsArray?.includes(sub.id || ''))
          : [];

        const sortedList = sortBySubjectName(userRoleBasedList);

        setSubjectState((ps) => {
          return {
            ...ps,
            isLoading: false,
            subjects: sortedList,
            initSubjects: sortedList,
          };
        });
      })
      .catch(() => {
        setSubjectState((ps) => {
          return {
            ...ps,
            isLoading: false,
          };
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSS = () => {
    const subjects: Subject[] = [];

    setSubjectState((ps) => {
      return {
        ...ps,
        isLoading: true,
      };
    });

    store
      .collection('subjects')
      .get()
      .then((data) => {
        data.forEach((doc) => {
          subjects.push({ id: doc.id, ...doc.data() } as any);
        });

        const subjectIdsArray = subjects
          .filter((sub) => sub.assignedUsers?.includes(user.firestoreUser?.uid || ''))
          .map((s) => s.id);

        const { isAdmin, isInstructor, isTeachingAssistant, isStudent } = getRole(
          user.firestoreUser?.userRole
        );

        const userRoleBasedList = isAdmin
          ? subjects
          : isInstructor
          ? subjects.filter(
              (sub) =>
                sub.createdBy === user.firestoreUser?.uid || subjectIdsArray?.includes(sub.id || '')
            )
          : isStudent
          ? subjects.filter((sub) => sub.enrolledStudents?.includes(user.firestoreUser?.uid || ''))
          : isTeachingAssistant
          ? subjects.filter((sub) => subjectIdsArray?.includes(sub.id || ''))
          : [];

        const sortedList = sortBySubjectName(userRoleBasedList);

        setSubjectState((ps) => {
          return {
            ...ps,
            isLoading: false,
            subjects: sortedList,
            initSubjects: sortedList,
          };
        });
      })
      .catch(() => {
        setSubjectState((ps) => {
          return {
            ...ps,
            isLoading: false,
          };
        });
      });
  };

  const fetchSubjects = () => {
    if (user.firestoreUser?.userRole?.isStudent) {
      const userEnrolledSubjects = sortSubjects(subjectsState.initSubjects || []).filter((sub) =>
        sub.enrolledStudents?.includes(user.firestoreUser?.uid || '')
      );

      setSubjectState((ps) => {
        return { ...ps, subjects: userEnrolledSubjects, isLoading: false };
      });
    } else {
      let searchResult: any[] = [];
      let duplicatesRemovedArray: any[];

      searchResult = [...subjectsState.initSubjects];

      if (subjectsState.filters.grade) {
        searchResult = searchResult.filter((item) => item.grade === subjectsState.filters.grade);
      }

      if (subjectsState.filters.year) {
        searchResult = searchResult.filter((item) => item.year === subjectsState.filters.year);
      }

      if (subjectsState.subjectSearchText?.trim()) {
        duplicatesRemovedArray = searchResult.filter((item) =>
          item.name.toUpperCase().startsWith(subjectsState.subjectSearchText?.trim().toUpperCase())
        );

        setSubjectState((ps) => {
          return { ...ps, subjects: [...duplicatesRemovedArray] };
        });
      } else {
        setSubjectState((ps) => {
          return { ...ps, subjects: [...searchResult] };
        });
      }
    }
  };

  const header = <SubjectsHeader currentTab={state.currentTab} openModal={openModalHandler} />;
  const primaryNav = <PrimaryNav />;

  const applyFilterHandler = () => {
    fetchSubjects();
  };

  const openFilterHandler = () => {
    setSubjectState((ps) => {
      return { ...ps, filterPopUpOn: true };
    });
  };

  const deleteSubjectRef = firebase.functions().httpsCallable('deleteSubject');

  const onSubjectDelete = async (sId: string) => {
    // appSubjects.setLoading(true);

    setLoading();

    try {
      await deleteSubjectRef({
        sId: sId,
      });

      appSubjects.removeSubject(sId);

      endLoading();
      removeSubject(sId);

      Notification({
        isSuccess: true,
        message: 'Subject deleted successfully',
      });

      closeAlertHandler();
      // appSubjects.setLoading(false);
    } catch (e) {
      Notification({
        isSuccess: false,
        message: 'Failed to delete the subject',
      });
      endLoading();
      closeAlertHandler();
      // appSubjects.setLoading(false);
    }
  };

  const removeSelectedSubject = () => {
    setSubjectState((ps) => {
      return {
        ...ps,
        selectedSubject: null,
      };
    });
  };

  useEffect(() => {
    fetchSubjects();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectsState.subjectSearchText]);

  useEffect(() => {
    if (subjectsState.subjects.length % ITEM_COUNT_IN_PAGE === 0) {
      const totalPages = subjectsState.subjects.length / ITEM_COUNT_IN_PAGE;
      setPagination((ps) => ({ ...ps, total_pages: totalPages }));
    } else {
      const totalPages = Math.floor(subjectsState.subjects.length / ITEM_COUNT_IN_PAGE) + 1;
      setPagination((ps) => ({ ...ps, total_pages: totalPages }));
    }
  }, [subjectsState.subjects]);

  const setLoading = () => {
    setSubjectState((ps) => ({
      ...ps,
      isLoading: true,
    }));
  };

  const endLoading = () => {
    setSubjectState((ps) => ({
      ...ps,
      isLoading: false,
    }));
  };

  const setUpdatedSubject = (subject: Subject) => {
    const removedSubjectArray = [...subjectsState.initSubjects].filter(
      (sub) => sub.id !== subject.id
    );

    removedSubjectArray.push(subject);

    setSubjectState((ps) => {
      return {
        ...ps,
        initSubjects: sortBySubjectName(removedSubjectArray),
        subjects: sortBySubjectName(removedSubjectArray),
      };
    });
  };

  const addSubject = (sub: Subject) => {
    const currentArray = [...subjectsState.initSubjects];

    currentArray.push(sub);

    setSubjectState((ps) => {
      return {
        ...ps,
        initSubjects: sortBySubjectName(currentArray),
        subjects: sortBySubjectName(currentArray),
      };
    });
  };

  const removeSubject = (id: string) => {
    const updatedInitSubjects = subjectsState.initSubjects.filter((value) => value.id !== id);
    const updatedSubject = subjectsState.subjects.filter((value) => value.id !== id);

    setSubjectState((ps) => {
      return {
        ...ps,
        initSubjects: sortBySubjectName(updatedInitSubjects),
        subjects: sortBySubjectName(updatedSubject),
      };
    });
  };

  const paginate = (array: Subject[], page_number: number, page_size: number): Subject[] =>
    array.slice(page_number * page_size, (page_number + 1) * page_size);

  const intructorBody = (
    <>
      {subjectsState.isLoading && <LoadingIndicator />}

      <div
        className="d-flex justify-content-between justify-content-sm-start subjects-filter-cont position-relative"
        style={{ marginTop: 50 }}
      >
        <SearchBox
          onSearch={(text) => {
            searchTextHandler(text);
          }}
          widthClassName="search-width"
          placeHolderText="Search Subjects"
          clearSearchHandler={clearSearchHandler}
          isClearSearch={isClearSearch}
        />
        <button className="filter_button" onClick={openFilterHandler}>
          Filters &nbsp;
          <FaFilter />
        </button>
        <SubjectModalFilter
          show={subjectsState.filterPopUpOn}
          setState={setSubjectState}
          state={subjectsState}
          onApplyFilters={applyFilterHandler}
        />
        {/* <button
          onClick={() => {
            history.push('/ruban');
          }}
        >
          GO TO RUBAN
        </button> */}
      </div>
      <ListLayout className="list-layout--full-width">
        {subjectsState.subjects.length > 0 ? (
          paginate(subjectsState.subjects, pagination.page_number, ITEM_COUNT_IN_PAGE).map(
            (subject, index) => {
              return (
                <SubjectCard
                  name={subject.name}
                  imageURL={subject.coverImage}
                  grade={subject.grade}
                  year={subject.year}
                  key={index}
                  subId={subject.id}
                  onDelete={() => {
                    showAlertHandler();
                    setDeleteId(subject.id || '');
                  }}
                  createdBy={subject.createdBy}
                  onEdit={() => {
                    setSubjectState((ps) => ({
                      ...ps,
                      selectedSubject: subject,
                      show: true,
                    }));
                  }}
                  setLoading={setLoading}
                  endLoading={endLoading}
                />
              );
            }
          )
        ) : (
          <AddFirstSubjectButton openModal={openModalHandler} />
        )}
      </ListLayout>
      <div>
        <PaginationNew
          pages={pagination.total_pages}
          setCurrentPage={(pageNumber) => {
            setPagination({ ...pagination, page_number: pageNumber - 1 });
          }}
          clear={pagination.total_pages}
        />
      </div>
      <AddSubjectModal
        show={subjectsState.show}
        closeHandler={closeModalHandler}
        selectedSubject={subjectsState.selectedSubject}
        mode={subjectsState.selectedSubject ? 'EDIT' : 'CREATE'}
        setUpdatedSubject={setUpdatedSubject}
        addSubject={addSubject}
        fetchSubjects={fetchSubjects}
      />
    </>
  );

  const StudentBody = (
    <>
      {subjectsState.isLoading && <LoadingIndicator />}

      <div className="d-flex justify-content-between justify-content-sm-start subjects-filter-cont position-relative"></div>
      <ListLayout
        className="list-layout--full-width subject-list-layout"
        style={{ height: 'calc(100vh - 180px)' }}
      >
        {subjectsState.subjects.length > 0 ? (
          paginate(subjectsState.subjects, pagination.page_number, ITEM_COUNT_IN_PAGE).map(
            (subject, index) => (
              <SubjectCard
                name={subject.name}
                imageURL={subject.coverImage}
                grade={subject.grade}
                year={subject.year}
                key={index}
                subId={subject.id}
                createdBy={subject.createdBy}
              />
            )
          )
        ) : (
          <></>
        )}
      </ListLayout>
      <div>
        <PaginationNew
          pages={pagination.total_pages}
          setCurrentPage={(pageNumber) => {
            setPagination({ ...pagination, page_number: pageNumber - 1 });
          }}
          clear={pagination.total_pages}
        />
      </div>

      <EnrollToSubjectModal
        onClose={closeModalHandler}
        show={subjectsState.show}
        fetchSubjects={fetchSS}
        setSubjectState={setSubjectState}
        subjectsState={subjectsState}
        setEnrollSearch={changeEnrollSearchText}
      />
    </>
  );

  const body =
    user.firestoreUser?.userRole?.isStudent || user.firestoreUser?.userRole?.isTeachingAssistant
      ? StudentBody
      : intructorBody;

  return (
    <>
      <LayoutNew header={header} primaryNav={primaryNav} body={body} />
      <AlertPopup
        message="Are you sure you want to delete this subject?"
        header="Attention"
        isShow={showAlert}
        primaryButtonText="Confirm"
        onOk={() => {
          onSubjectDelete(deleteId);
          closeModalHandler();
        }}
        onClose={closeAlertHandler}
      />
    </>
  );
};

export default Subjects;
