import React from 'react';
import './ComplexityIndicator.scss';

const ComplexityIndicator = (props: {value: number; style?: React.CSSProperties}) => {
  const {value} = props;

  const getConcreateValue = () => {
    let complexity = 0;

    if (value < 25) {
      complexity = 1;
    } else if (value < 50) {
      complexity = 2;
    } else if (value < 75) {
      complexity = 3;
    } else if (value < 100) {
      complexity = 4;
    } else {
      complexity = 5;
    }

    return complexity;
  };

  return (
    <>
      <div className="box-body">
        <div className={['indicator', value >= 0 ? 'bg-' + getConcreateValue() : ''].join(' ')} />
        <div className={['indicator', value >= 25 ? 'bg-' + getConcreateValue() : ''].join(' ')} />
        <div className={['indicator', value >= 50 ? 'bg-' + getConcreateValue() : ''].join(' ')} />
        <div className={['indicator', value >= 75 ? 'bg-' + getConcreateValue() : ''].join(' ')} />
        <div
          className={['indicator mr-0', value >= 100 ? 'bg-' + getConcreateValue() : ''].join(' ')}
        />
      </div>
    </>
  );
};
export default ComplexityIndicator;
