import { FaPlus } from 'react-icons/fa';
import randomString from 'randomstring';
import { IoCaretBack, IoDocumentText } from 'react-icons/io5';
import './CreateNotice.scss';
import { MdClose } from 'react-icons/md';
import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useStorage } from 'reactfire';
import { useHistory, useParams } from 'react-router-dom';
import truncate from 'truncate';
import { Announcement, FileContent, useAnnouncements } from '../context/AnnouncementContext';
import { useAppUser } from '../context/UserContext';
import { useSubjects } from '../context/SubjectsContext';
import { Subject } from '../models/Subject';
import LoadingIndicator from './LoadingIndicator';
import SwitchBtn from './SwitchBtn';
import FormValidator from '../utils/FormValidator';
import TinyMCEEditor from './TinyMCEEditor';
import Notification from './../components/Notification';

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
export interface AddAnnouncementDTO {
  id: string;
  title: string;
  description: string;
  date: string;
  duration: number;
  documents: string[];
}

export const gradeOrder = [
  ['Five', 1],
  ['Six', 2],
  ['Seven', 3],
  ['Eight', 4],
  ['Nine', 5],
  ['Ten', 6],
  ['Eleven', 7],
  ['O/L', 8],
  ['A/L', 9],
];
export interface IState {
  title: string;
  titleError: string;
  description: string;
  descriptionError: string;
  date: Date | null;
  dateError: string;
  time: Time;
  timeError: string;
  duration: number;
  durationError: string;
  fileURLs: FileContent[];
  countriesError: string;
  loading: boolean;
  subjectError: string;
  gradeError: string;
}

interface Time {
  h: number;
  m: number;
}

const initialState: IState = {
  title: '',
  titleError: '',
  description: '',
  descriptionError: '',
  date: null,
  dateError: '',
  time: { h: 0, m: 0 },
  timeError: '',
  duration: 0,
  durationError: '',
  subjectError: '',
  fileURLs: [],
  countriesError: '',
  loading: false,
  gradeError: '',
};

interface IProps {
  type: 'update' | 'create';
}

const CreateNotice = (props: IProps) => {
  const [uploadElement, setUploadElement] = useState(null as any);
  const [selectedFiles, setSelectedFiles] = useState<
    { id: string; file?: File; url: string; old?: boolean }[]
  >([]);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const storage = useStorage().ref();
  const user = useAppUser();
  const [subjects, setSubjects] = useState<{ selected: boolean; subject: Subject }[]>([]);
  const [grades, setGrades] = useState<{ selected: boolean; grade: string }[]>([]);
  const [selectAllSubject, setSelectAllSubject] = useState(false);
  const [sendToAll, setSendToAll] = useState(false);
  const { push: navigate, goBack } = useHistory();
  const [rState, setRState] = useState<{ data: Announcement }>();

  const { id, subjectId } = useParams() as any;
  const announcementsProvider = useAnnouncements();
  useEffect(() => {
    const [a] = announcementsProvider.announcements.filter((x) => x.id === id);
    setRState({ data: a });
  }, [id, announcementsProvider.announcements]);

  const { allSubjects } = useSubjects();
  const getSelectedGrade = () => {
    return grades.filter((val) => {
      return val.selected === true;
    });
  };

  useEffect(() => {
    if (sendToAll)
      setState((ps) => {
        return { ...ps, gradeError: '', subjectError: '' };
      });
  }, [sendToAll]);

  useEffect(() => {
    //announcement edit All sujbects/send to All check box selected status
    if (
      subjects
        .filter((subject) => {
          const selectedGrades = getSelectedGrade();

          return selectedGrades.find((val) => {
            return val.grade === subject.subject.grade;
          });
        })
        .every((subject) => subject.selected === true)
    ) {
      setSelectAllSubject(true);
      if (
        grades.every((grade) => {
          return grade.selected === true;
        })
      ) {
        setSendToAll(true);
      } else {
        setSendToAll(false);
      }
      //when all selected grades are false, select all subject status
      if (
        grades.every((grade) => {
          return grade.selected === false;
        })
      ) {
        setSelectAllSubject(true);
      }
    } else {
      setSelectAllSubject(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjects, grades]);

  useEffect(() => {
    if (id && rState) {
      setState((ps) => ({
        ...ps,
        title: rState?.data?.title,
        description: rState?.data?.description,
        date: rState?.data?.date?.toDate(),
        time: {
          h: rState?.data?.date.toDate().getHours(),
          m: rState?.data?.date.toDate().getMinutes(),
        },
        duration: rState?.data?.duration,
        fileURLs: rState?.data?.fileURLs || [],
      }));
      setSelectedFiles(
        rState?.data?.fileURLs?.map((fu) => {
          return { id: fu.fileName, url: fu.url, old: true };
        }) || []
      );
      setGrades(rState?.data?.grades?.map((g) => ({ selected: true, grade: g })) || []);
    }
  }, [id, rState]);
  useEffect(() => {
    let subjects = allSubjects;
    if (!user.firestoreUser?.userRole?.isAdmin) {
      subjects = subjects.filter(
        (s) =>
          s.createdBy === user.fireUser?.uid || s.assignedUsers?.includes(user.fireUser?.uid || '')
      );
    }
    let nSubjects = subjects.map((s) => {
      const [available] = rState?.data?.subjects?.filter((ss) => s.id === ss) || [];
      return {
        selected: available ? true : false,
        subject: s,
      };
    });
    if (subjectId) {
      nSubjects = nSubjects.map((s) => {
        if (s.subject.id === subjectId) {
          return { ...s, selected: true };
        }
        return s;
      });
    }
    setSubjects(nSubjects);
    let grade: any;
    const a = nSubjects.map((s) => {
      if (s.subject.id === subjectId) {
        grade = s.subject.grade;
        return {
          selected: true,
          grade: s.subject.grade,
        };
      } else {
        return {
          selected: false,
          grade: s.subject.grade,
        };
      }
    });

    const grades = a.filter(function (value, index, self) {
      return index === self.findIndex((t) => t.grade === value.grade);
    });

    setGrades(
      grades
        .map((gg) => {
          if (grade === gg.grade) {
            return { ...gg, selected: true };
          } else {
            return gg;
          }
        })
        .map((g) => {
          const [available] = rState?.data?.grades?.filter((ss) => g.grade === ss) || [];
          return { ...g, selected: available ? true : g.selected };
        })
    );
  }, [user.fireUser?.uid, user.firestoreUser?.userRole, allSubjects, rState, subjectId]);

  const validateData = (): boolean => {
    //title
    const titleValidation = FormValidator.validateString(state.title, 'Title', 3, 100);
    //date
    const dateValidation =
      state.date === null
        ? { message: 'Please set a valid Date.', isValid: false }
        : FormValidator.validateWorkshopStartDate(state.date, 'Date');
    //time
    const today = new Date();
    const timeValidation =
      state.date &&
      state.time &&
      dateValidation.isValid &&
      FormValidator.areDatesEqual(new Date(), state.date)
        ? today.getHours() < state.time.h
          ? { message: '', isValid: true }
          : today.getHours() === state.time.h && today.getMinutes() < state.time.m
          ? { message: '', isValid: true }
          : { message: 'Please set a valid Start Time.', isValid: false }
        : { message: '', isValid: true };
    //duration
    const durationValidation =
      (state.duration && state.duration > 0 && state.duration % 1 === 0) || !dateValidation.isValid
        ? { message: '', isValid: true }
        : { message: 'Please set a valid duration', isValid: false };
    //description
    const descriptionValidation = FormValidator.validateQuestionBody(
      state.description,
      'Message',
      3,
      2100
    );
    let subjectValidation =
      subjects.filter((s) => s.selected).length > 0
        ? { message: '', isValid: true }
        : { message: 'Please select at least one subject', isValid: false };

    const gradeValidation =
      grades.filter((s) => s.selected).length > 0
        ? { message: '', isValid: true }
        : { message: 'Please select at least one grade', isValid: false };

    if (subjectValidation.isValid) {
      subjectValidation =
        subjects
          .filter((s) => {
            const gradesTemp = getSelectedGrade();
            return gradesTemp.find((grade) => {
              return grade.grade === s.subject.grade;
            });
          })
          .filter((s) => s.selected).length > 0
          ? { message: '', isValid: true }
          : { message: 'Please select at least one subject', isValid: false };
    }

    setState({
      ...state,
      titleError: titleValidation.message,
      descriptionError: descriptionValidation.message,
      dateError: dateValidation.message,
      durationError: durationValidation.message,
      timeError: timeValidation.message,
      subjectError: subjectValidation.message,
      gradeError: gradeValidation.message,
    });
    return (
      titleValidation.isValid &&
      descriptionValidation.isValid &&
      dateValidation.isValid &&
      durationValidation.isValid &&
      subjectValidation.isValid &&
      gradeValidation.isValid &&
      timeValidation.isValid
    );
  };

  const addNew = () => {
    if (validateData()) {
      setLoading(true);
      if (selectedFiles.length > 0) {
        const downloadUrlTasks: { file: File; task: Promise<string> }[] = [];
        const tasks = selectedFiles.map((f) => {
          if (f.file) {
            const task = storage.child(`sessionresources/${f.file?.name}`).put(f.file);
            task.on(
              firebase.storage.TaskEvent.STATE_CHANGED,
              (snapshot: any) => {
                const progress =
                  ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / selectedFiles.length;
                if (snapshot.state === firebase.storage.TaskState.RUNNING) {
                  console.log(`Progress: ${progress}% - ${f.file?.name}`);
                }
              },
              (error: any) => console.log(error.message),
              async () => {
                f.file &&
                  downloadUrlTasks.push({
                    file: f.file,
                    task: task.snapshot.ref.getDownloadURL(),
                  });
              }
            );

            return task;
          }
          return null;
        });

        const urls: FileContent[] = [];
        Promise.all(tasks)
          .then(async () => {
            for (let utask of downloadUrlTasks) {
              const downloadURL = await utask.task;
              console.log(downloadURL);
              urls.push({
                url: downloadURL,
                fileName: utask.file?.name,
                type: utask.file.name.substr(utask.file.name.lastIndexOf('.') + 1),
              });
            }

            const dateAt = state.date;
            const fullDate = new Date(
              dateAt?.getFullYear() || 0,
              dateAt?.getMonth() || 0,
              dateAt?.getDate() || -1,
              state.time.h,
              state.time.m
            );

            const data = {
              title: state.title,
              description: state.description,
              date: fullDate?.toString(),
              duration: state.duration,
              fileURLs: urls,
              grades: grades.filter((g) => g.selected).map((g) => g.grade),
              subjects: subjects
                .filter((s) =>
                  grades
                    .filter((g) => g.selected)
                    .map((g) => g.grade)
                    .includes(s.subject.grade)
                )
                .filter((s) => s.selected)
                .map((s) => {
                  return s.subject.id || '';
                }),
            };
            announcementsProvider.addAnnouncement(data, (status, id) => {
              if (status) {
                Notification({
                  isSuccess: true,
                  message: 'Notice Successfully Saved!',
                });
                // app.pushBackURL("/dashboard/announcements/newannouncement");
                // push(`/dashboard/announcements/announcement/${id}`);
                if (subjectId) {
                  const [subject] = allSubjects.filter((s) => s.id === subjectId);
                  if (subject)
                    navigate(`/my-subjects/subject/${subject.id}/Announcements`, {
                      subjectName: subject.name,
                      subjectId: subject.id,
                    });
                } else {
                  navigate('/announcements');
                }
                setLoading(false);
              } else {
                Notification({
                  isSuccess: false,
                  message: 'Notice Failed to Create!',
                });
                setLoading(false);
              }
            });
          })
          .catch((err) => {
            setLoading(false);
            // UserNotification({
            //   isSuccess: false,
            //   message: "Notice Failed to Create!",
            // });
          });
      } else {
        const dateAt = state.date;
        const fullDate = new Date(
          dateAt?.getFullYear() || 0,
          dateAt?.getMonth() || 0,
          dateAt?.getDate() || -1,
          state.time.h,
          state.time.m
        );

        const data = {
          title: state.title,
          description: state.description,
          date: fullDate?.toString(),
          duration: state.duration,
          grades: grades.filter((g) => g.selected).map((g) => g.grade),
          subjects: subjects
            .filter((s) =>
              grades
                .filter((g) => g.selected)
                .map((g) => g.grade)
                .includes(s.subject.grade)
            )
            .filter((s) => s.selected)
            .map((s) => {
              return s.subject.id || '';
            }),
        };
        announcementsProvider.addAnnouncement(data, (status, id) => {
          if (status) {
            Notification({
              isSuccess: true,
              message: 'Notice Successfully Saved!',
            });
            // app.pushBackURL("/dashboard/announcements/newannouncement");
            // push(`/dashboard/announcements/announcement/${id}`);
            if (subjectId) {
              const [subject] = allSubjects.filter((s) => s.id === subjectId);
              if (subject)
                navigate(`/my-subjects/subject/${subject.id}/Announcements`, {
                  subjectName: subject.name,
                  subjectId: subject.id,
                });
            } else {
              navigate('/announcements');
            }

            setLoading(false);
          } else {
            Notification({
              isSuccess: false,
              message: 'Notice Failed to Create!',
            });
            setLoading(false);
          }
        });
      }
    }
  };
  const update = () => {
    if (validateData()) {
      setLoading(true);
      if (selectedFiles.length > 0) {
        const downloadUrlTasks: { file: File; task: Promise<string> }[] = [];
        const tasks = selectedFiles
          .filter((f) => !f.old)
          .map((f) => {
            if (f.file) {
              const task = storage.child(`sessionresources/${f.file?.name}`).put(f.file);
              task.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot: any) => {
                  const progress =
                    ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) /
                    selectedFiles.length;
                  if (snapshot.state === firebase.storage.TaskState.RUNNING) {
                    console.log(`Progress: ${progress}% - ${f.file?.name}`);
                  }
                },
                (error: any) => console.log(error.message),
                async () => {
                  f.file &&
                    downloadUrlTasks.push({
                      file: f.file,
                      task: task.snapshot.ref.getDownloadURL(),
                    });
                }
              );

              return task;
            }
            return null;
          });

        const urls: FileContent[] = state.fileURLs || [];
        Promise.all(tasks)
          .then(async () => {
            for (let utask of downloadUrlTasks) {
              const downloadURL = await utask.task;
              console.log(downloadURL);
              urls.push({
                url: downloadURL,
                fileName: utask.file?.name,
                type: utask.file.name.substr(utask.file.name.lastIndexOf('.') + 1),
              });
            }

            const dateAt = state.date;
            const fullDate = new Date(
              dateAt?.getFullYear() || 0,
              dateAt?.getMonth() || 0,
              dateAt?.getDate() || -1,
              state.time.h,
              state.time.m
            );

            const data = {
              id,
              title: state.title,
              description: state.description,
              date: fullDate?.toString(),
              duration: state.duration,
              fileURLs: urls,
              grades: grades.filter((g) => g.selected).map((g) => g.grade),
              subjects: subjects.filter((s) => s.selected).map((s) => s.subject.id || ''),
            };
            announcementsProvider.updateAnnouncement(data, (status) => {
              if (status) {
                Notification({
                  isSuccess: true,
                  message: 'Notice Successfully Updated!',
                });
                if (subjectId) {
                  const [subject] = allSubjects.filter((s) => s.id === subjectId);
                  if (subject)
                    navigate(`/my-subjects/subject/${subject.id}/Announcements`, {
                      subjectName: subject.name,
                      subjectId: subject.id,
                    });
                } else {
                  navigate(`/announcements`);
                }

                setLoading(false);
              } else {
                setLoading(false);
              }
            });
          })
          .catch((err) => {
            Notification({
              isSuccess: false,
              message: 'Notice Failed to Update!',
            });
            setLoading(false);
          });
      } else {
        const dateAt = state.date;
        const fullDate = new Date(
          dateAt?.getFullYear() || 0,
          dateAt?.getMonth() || 0,
          dateAt?.getDate() || -1,
          state.time.h,
          state.time.m
        );

        const data = {
          id,
          title: state.title,
          description: state.description,
          date: fullDate?.toString(),
          duration: state.duration,
          grades: grades.filter((g) => g.selected).map((g) => g.grade),
          subjects: subjects.filter((s) => s.selected).map((s) => s.subject.id || ''),
        };
        announcementsProvider.updateAnnouncement(data, (status) => {
          if (status) {
            Notification({
              isSuccess: true,
              message: 'Notice Successfully Updated!',
            });
            if (subjectId) {
              const [subject] = allSubjects.filter((s) => s.id === subjectId);
              if (subject)
                navigate(`/my-subjects/subject/${subject.id}/Announcements`, {
                  subjectName: subject.name,
                  subjectId: subject.id,
                });
            } else {
              navigate(`/announcements`);
            }
            setLoading(false);
          } else {
            Notification({
              isSuccess: false,
              message: 'Notice Failed to Update!',
            });
            setLoading(false);
          }
        });
      }
    }
  };

  return (
    <>
      <div className="mt-4" style={{ position: 'fixed', width: '85vw' }}>
        {loading && <LoadingIndicator />}
      </div>
      <div className="create-notice-waper">
        <div className="s0">
          <div className="btn-back" onClick={() => goBack()}>
            <IoCaretBack />
          </div>
          <div className="text-white" style={{ marginLeft: '1rem' }}>
            {`${props.type === 'create' ? 'Create' : 'Edit'} Announcement`}
          </div>
        </div>

        <div className="cn-main-content">
          <div className="s2">
            <div className="cn-label">
              Title <span style={{ color: '#F05A5A' }}>*</span>
            </div>

            <input
              className="cn-input"
              value={state.title}
              onChange={(e) => {
                setState((ps) => {
                  return { ...ps, title: e.target.value, titleError: '' };
                });
              }}
            />
            <div>
              <label className="error-message">{state.titleError}</label>
            </div>
          </div>
          <div className="s2">
            <div className="cn-label">
              Message <span style={{ color: '#F05A5A' }}>*</span>
            </div>
            <div style={{ minHeight: '200px' }}>
              <TinyMCEEditor
                onChange={(txt, idx) => {
                  setState((ps) => ({ ...ps, description: txt, descriptionError: '' }));
                }}
                className="mt-1"
                value={state.description}
                error={''}
                onFocus={(index) => {}}
              />
              <label className="error-message">{state.descriptionError}</label>
            </div>
          </div>
          <div className="s3">
            <div className="cn-date">
              <div className="cn-label">
                Date <span style={{ color: '#F05A5A' }}>*</span>
              </div>
              <input
                type="date"
                value={state.date?.toISOString().slice(0, 10)}
                onChange={(e) => {
                  setState({ ...state, date: new Date(e.target.value), dateError: '' });
                }}
              />
              <div>
                <label className="error-message">{state.dateError}</label>
              </div>
            </div>
            <div className="cn-time">
              <div className="cn-label">
                Time <span style={{ color: '#F05A5A' }}>*</span>
              </div>
              <input
                type="time"
                value={zeroPad(state.time.h, 2) + ':' + zeroPad(state.time.m, 2)}
                onChange={(e) => {
                  const time = e.target.value.split(':');
                  setState({
                    ...state,
                    time: { ...state.time, h: +time[0], m: +time[1] },
                    timeError: '',
                  });
                }}
              />
              <div>
                <label className="error-message">{state.timeError}</label>
              </div>
            </div>
            <div className="cn-duration">
              <div className="cn-label">
                Duration ( Days ) <span style={{ color: '#F05A5A' }}>*</span>
              </div>
              <input
                type="number"
                value={state.duration === 0 ? '' : state.duration}
                min={1}
                step={1}
                onChange={(e) => {
                  setState({
                    ...state,
                    duration: +e.target.value,
                    durationError: '',
                  });
                }}
                onKeyUp={(event) => {
                  console.log(event.charCode);
                  const e = (event.target as HTMLInputElement).value;
                  if (/[^0-9]+/.test(e)) {
                    (event.target as HTMLInputElement).value = e.replace(/[^0-9]*/g, '');
                  }
                }}
              />
              <div>
                <label className="error-message">{state.durationError}</label>
              </div>
            </div>
          </div>
          <div className="s4">
            <div className="cn-label">Additional Materials</div>
            <div className="file-list">
              {selectedFiles.map((f, index) => (
                <FileIcon
                  file={f}
                  key={index}
                  onClose={() => {
                    setSelectedFiles((ps) => {
                      return Object.assign(
                        [],
                        ps.filter((f2) => f2.id !== f.id)
                      );
                    });
                  }}
                />
              ))}
              <div
                className="add-new-m"
                onClick={() => {
                  uploadElement.click();
                }}
              >
                <div className="an-icon">
                  <FaPlus />
                </div>
                <div className="an-text">
                  Add <br />
                  Materials
                </div>
              </div>
              <input
                type="file"
                ref={(input) => setUploadElement(input)}
                accept=".xls, .pptx, .ppt, .png, .pdf, .jpg, .jpeg, .gif, .docx, .doc, .xlsx, .txt, .md"
                className="d-none"
                onChange={(e) => {
                  const file: any = e.target.files ? e.target.files[0] : null;
                  const fileType = file?.name.split('.').pop().toLowerCase();
                  const exList = 'xls,pptx,ppt,png,pdf,jpg,jpeg,gif,docx,doc,xlsx,txt,md'.split(
                    ','
                  );

                  let b1 = file && exList.filter((ext) => ext === fileType).length > 0;
                  let b2 = file && file?.size <= 105255200;
                  let b3 =
                    selectedFiles.length !== 0 &&
                    file &&
                    selectedFiles.find((ps) => ps.file && ps.file.name === file.name);
                  let errorMessage = 'No file was selected';
                  if (b1) errorMessage = 'Invalid file format';
                  if (b2) errorMessage = ' Invalid file size';
                  if (b3) errorMessage = 'File already exists';

                  if (b1 && b2 && !b3) {
                    if (file) {
                      setSelectedFiles((ps) => {
                        ps.push({
                          file: file,
                          id: randomString.generate(),
                          url: '',
                        });
                        return Object.assign([], ps);
                      });
                    }
                  } else {
                    console.log(errorMessage);
                    Notification({
                      isSuccess: false,
                      message: errorMessage,
                    });
                  }
                  e.target.value = '';
                }}
              />
            </div>
          </div>
          <div className="s5">
            <div className="cn-label">Send To</div>
            <div className="cn-ch-content">
              {!subjectId && (
                <div className="cn-row">
                  <div className="cn-sub-label">Send to all</div>
                  <div>
                    <SwitchBtn
                      showOutLine
                      checked={sendToAll}
                      changeHandler={(e) => {
                        setSendToAll((ps) => !ps);
                        setSelectAllSubject(!sendToAll);
                        setSubjects((ps) => {
                          return [...ps.map((sub) => ({ ...sub, selected: !sendToAll }))];
                        });
                        setGrades((ps) => {
                          return [...ps.map((grade) => ({ ...grade, selected: !sendToAll }))];
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="cn-row">
                <div
                  className="cn-sub-label"
                  style={{
                    marginRight: '18px',
                  }}
                >
                  Grade <span style={{ color: '#F05A5A' }}>*</span>
                </div>
                <div className="gredes">
                  {grades
                    .sort((a, b) => {
                      const [an] = gradeOrder.filter((s) => s[0] === a.grade);
                      const [bn] = gradeOrder.filter((s) => s[0] === b.grade);
                      return (an[1] as number) - (bn[1] as number);
                    })
                    .map((g, index) => (
                      <div className="pretty p-smooth p-svg p-curve" key={index}>
                        <input
                          style={{ width: 'auto' }}
                          type="checkbox"
                          disabled={!!subjectId}
                          checked={g.selected}
                          onChange={(e) => {
                            setGrades((ps) => {
                              return ps.map((x) => {
                                if (x.grade === g.grade) {
                                  return {
                                    ...x,
                                    selected: !x.selected,
                                  };
                                } else {
                                  return x;
                                }
                              });
                            });
                            setState((ps) => {
                              return { ...ps, subjectError: '', gradeError: '' };
                            });
                          }}
                        />
                        <div className="state p-primary">
                          <svg
                            className="svg svg-icon"
                            style={{
                              display: 'none',
                            }}
                          >
                            <path
                              d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                              style={{ stroke: 'white', fill: 'white' }}
                            ></path>
                          </svg>
                          <label
                            style={{
                              fontSize: '14px',
                            }}
                          >
                            {g.grade}
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <label className="error-message">{state.gradeError}</label>
              </div>
              {grades.filter((g) => g.selected).length > 0 && (
                <>
                  <div className="cn-row">
                    <div className="cn-sub-label">
                      Subjects <span style={{ color: '#F05A5A' }}>*</span>
                    </div>
                    <div className="subjects">
                      <div className="pretty p-smooth p-svg p-curve">
                        <input
                          style={{ width: 'auto' }}
                          type="checkbox"
                          disabled={!!subjectId}
                          checked={selectAllSubject}
                          onChange={() => {
                            setSelectAllSubject((ps) => !ps);
                            setSubjects((ps) => {
                              return [
                                ...ps.map((sub) => ({ ...sub, selected: !selectAllSubject })),
                              ];
                            });
                            setState((ps) => {
                              return { ...ps, subjectError: '' };
                            });
                          }}
                        />
                        <div className="state p-primary">
                          <svg
                            className="svg svg-icon"
                            style={{
                              display: 'none',
                            }}
                          >
                            <path
                              d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                              style={{ stroke: 'white', fill: 'white' }}
                            ></path>
                          </svg>
                          <label
                            style={{
                              fontSize: '14px',
                              fontWeight: 600,
                            }}
                          >
                            All Subjects
                          </label>
                        </div>
                      </div>
                      {subjects
                        .filter((s) =>
                          grades
                            .filter((g) => g.selected)
                            .map((g) => g.grade)
                            .includes(s.subject.grade)
                        )
                        .map((s, index) => (
                          <div className="pretty p-smooth p-svg p-curve" key={index}>
                            <input
                              style={{ width: 'auto' }}
                              type="checkbox"
                              checked={s.selected}
                              disabled={!!subjectId}
                              onChange={() => {
                                setSubjects((ps) => {
                                  return ps.map((x) => {
                                    if (x.subject.id === s.subject.id) {
                                      return {
                                        ...x,
                                        selected: !x.selected,
                                      };
                                    } else {
                                      return x;
                                    }
                                  });
                                });
                              }}
                            />
                            <div className="state p-primary">
                              <svg
                                className="svg svg-icon"
                                style={{
                                  display: 'none',
                                }}
                              >
                                <path
                                  d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                  style={{ stroke: 'white', fill: 'white' }}
                                ></path>
                              </svg>
                              <label
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                {truncate(s.subject.name || '', 30)}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div>
                    <label className="error-message">{state.subjectError}</label>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className="s2"
            style={{
              paddingBottom: '20px',
            }}
          >
            {loading === false && (
              <button
                className="f-button"
                onClick={() => {
                  if (id) {
                    update();
                  } else {
                    addNew();
                  }
                }}
              >
                {id ? 'Update' : 'Send'}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNotice;

export interface FileData {
  id: string;
  file?: File;
  fileName?: string;
  url: string;
  old?: boolean;
}
export interface IFileIconProps {
  file?: FileData;
  onClose(member: FileData): void;
}

export const FileIcon = (props: IFileIconProps) => {
  return (
    <div className="file-w">
      <div className="file-icon">
        <div>
          <MdClose
            className="close"
            onClick={() => {
              props.file && props.onClose(props.file);
            }}
          />
          <IoDocumentText className="micon" />
        </div>
      </div>
      <div className="file-name">
        {props.file?.file?.name || props.file?.fileName || props.file?.id}
      </div>
    </div>
  );
};
