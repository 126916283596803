import React, { FC } from 'react';
import './EnrollSubjectCard.scss';
import truncate from 'truncate';
import { Subject } from '../models/Subject';

interface Props {
  subject: Subject;
  onEnroll: (subjectID: string) => void;
  isLoading?: boolean;
  searchText?: string;
  authorImage?: string;
}

const EnrollSubjectCard: FC<Props> = (props) => {
  return (
    <div className="enroll-subject-card">
      {/* <div
        className="enroll-subject-card__image"
        style={{ backgroundImage: `url(${props.subject.coverImage})` }}
      ></div>
      <div className="enroll-subject-card__content">
        <div className="enroll-subject-card__name">
          <Highlighter
            highlightClassName="question-text-highlight"
            searchWords={props.searchText?.split(' ')}
            autoEscape={true}
            textToHighlight={
              truncate(props.subject.name || 'Question with visual content', 150) || ''
            }
          />
        </div>
        <div className="enroll-subject-card__year-author">
          <div className={`enroll-subject-card__year ${props.subject.year ? '' : 'd-none'}`}>
            {props.subject.year}
          </div>
          <div className="enroll-subject-card__author">{`by ${truncate(
            props.subject.authorName || '',
            20
          )}`}</div>
        </div>
      </div>
      <div className="enroll-subject-card__enroll-btn">
        <button
          disabled={props.isLoading}
          onClick={() => {
            props.subject.id && props.onEnroll(props.subject.id);
          }}
          className={`enroll-button ${props.isLoading && 'disabled'}`}
        >
          Enroll
        </button>
      </div> */}

      <div
        className="enroll-subject-card__image"
        style={{ backgroundImage: `url(${props.subject.coverImage})` }}
      >
        {/* <img src={props.subject.coverImage} alt="" /> */}

        <div className="enroll-subject-card__image-grade">
          <div className="enroll-subject-card__image-grade-text">{props.subject.grade}</div>
        </div>
      </div>
      <div className="enroll-subject-card__content">
        <div className="enroll-subject-card__content-name">
          {/* <Highlighter
            highlightClassName="question-text-highlight"
            searchWords={props.searchText?.split(' ')}
            autoEscape={true}
            textToHighlight={
              truncate(props.subject.name || 'Question with visual content', 150) || ''
            }
          /> */}
          {truncate(props.subject.name || 'Question with visual content', 150) || ''}
        </div>

        <div className={`enroll-subject-card__content-year ${props.subject.year ? '' : 'd-none'}`}>
          {props.subject.year}
        </div>

        <div className="enroll-subject-card__content-author">
          <div className="enroll-subject-card__content-author-image">
            <img
              src={
                props.authorImage ||
                'https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg'
              }
              alt=""
            />
          </div>
          <div className="enroll-subject-card__content-author-name">
            {`by ${truncate(props.subject.authorName || '', 20)}`}
          </div>
        </div>
      </div>
      <div className="enroll-subject-card__enroll-btn">
        <button
          disabled={props.isLoading}
          onClick={() => {
            props.subject.id && props.onEnroll(props.subject.id);
          }}
          className={`enroll-button ${props.isLoading && 'disabled'}`}
        >
          Enroll
        </button>
      </div>
    </div>
  );
};

export default EnrollSubjectCard;
