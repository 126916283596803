import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ExamBody from '../../components/ExamBody';
import ExamTypesTabs from '../../components/ExamTypesTabs';
import PrimaryNav from '../../components/PrimaryNav';
import SubjectDirectiveHeader from '../../components/SubjectDirectiveHeader';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import { EXAM_TYPE_TABS } from '../../enums';
import useExamsFetch from '../../hooks/useExamsFetch';
import {
  DropDown,
  FilterBucket,
  subjectDirectiveInitialState,
  SUBJECT_DIRECTIVE_STATE,
} from '../../interfaces';
import LayoutNew from '../../layouts/LayoutNew';

const InstructorSubjectModalPapersScreen = () => {
  const params = useParams() as { subjectId: string; lessonId: string; unitId: string };
  const [data, setData] = useState<SUBJECT_DIRECTIVE_STATE>(subjectDirectiveInitialState);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const { EXTRA, PRACTISE, LIVE } = EXAM_TYPE_TABS;

  useEffect(() => {
    if (query.get('name') === EXTRA) {
      setData((ps) => {
        return { ...ps, tab: EXTRA };
      });
    } else if (query.get('name') === PRACTISE) {
      setData((ps) => {
        return { ...ps, tab: PRACTISE };
      });
    } else if (query.get('name') === LIVE) {
      setData((ps) => {
        return { ...ps, tab: LIVE };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  //hooks
  const STATE = useExamsFetch(data, setData, params?.subjectId);

  const onHideGradedExams = (isHideGradedExams: boolean) => {
    setData((ps) => ({
      ...ps,
      filterOptions: { ...ps.filterOptions, isHideGradedExams: isHideGradedExams },
    }));
  };

  const searchHander = (searchText: string) => {
    setData((ps) => ({
      ...ps,
      filterOptions: { ...ps.filterOptions, searchText: searchText },
    }));
  };

  const openFilterHander = (value: boolean) => {
    setData((ps) => ({
      ...ps,
      isOpenFilterBox: value,
    }));
  };

  const clearFiltersHandler = () => {
    setData((ps) => ({
      ...ps,
      filterOptions: {
        ...ps.filterOptions,
        lesson: { displayValue: '', id: '' },
        unit: { displayValue: '', id: '' },
      },
    }));
  };

  const applyFiltersHandler = () => {
    const filterBucket: FilterBucket = {
      lessonId: data.filterOptions.lesson.id,
      unitId: data.filterOptions.unit.id,
    };

    setData((ps) => ({
      ...ps,
      filterBucket: filterBucket,
      isApplyFiltersOn: true,
      isOpenFilterBox: false,
    }));
  };

  const onTabChangeClearFilters = () => {
    setData((ps) => ({
      ...ps,
      filterOptions: {
        ...ps.filterOptions,
        lesson: { displayValue: '', id: '' },
        unit: { displayValue: '', id: '' },
        searchText: ''
      },
      filterBucket: {
        lessonId: '',
        unitId: '',
      },
      isApplyFiltersOn: true,
      isOpenFilterBox: false,
    }));
  };

  const onSelectLessonFilterHandler = (value: DropDown) => {
    setData((ps) => ({
      ...ps,
      filterOptions: { ...ps.filterOptions, lesson: value },
      // isApplyFiltersOn: false,
    }));
  };

  const onSelectUnitFilterHandler = (value: DropDown) => {
    setData((ps) => ({
      ...ps,
      filterOptions: { ...ps.filterOptions, unit: value },
      // isApplyFiltersOn: false,
    }));
  };

  const lessonModelHandler = (value: boolean) => {
    setData((ps) => ({
      ...ps,
      isOpenLessonModel: value,
    }));
  };

  let appHeader = (
    <SubjectDirectiveHeader lessonModelHandler={lessonModelHandler} isLoading={STATE.isLoading} />
  );
  let primaryNavigation = <PrimaryNav />;
  let secondaryNavigation = <SubjectSecondaryNav />;
  let appBody = (
    <Fragment>
      <ExamTypesTabs tab={STATE.tab} onTabChangeClearFilters={onTabChangeClearFilters} />
      <ExamBody
        {...STATE}
        onHideGradedExams={onHideGradedExams}
        searchHander={searchHander}
        openFilterHander={openFilterHander}
        clearFiltersHandler={clearFiltersHandler}
        applyFiltersHandler={applyFiltersHandler}
        onSelectLessonFilterHandler={onSelectLessonFilterHandler}
        onSelectUnitFilterHandler={onSelectUnitFilterHandler}
      />
    </Fragment>
  );

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

export default InstructorSubjectModalPapersScreen;
