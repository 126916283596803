import React, { FC, Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaSortAmountDown, FaSortAmountDownAlt } from 'react-icons/fa';
import truncate from 'truncate';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
import { ExamToDoDTO } from '../dtos/ExamToDoDTO';
import { PaperDTO } from '../dtos/PaperDTO';
import { PaperTypes } from '../enums/paperTypes';
import { Exam } from '../models/Exam';
import { removeDecimalSection, round } from '../utils';
import './GradesTabMarks.scss';

interface Props {
  exam?: Exam | null;
  list: ExamToDoDTO[];
  searchText?: string;
  isLoading: boolean;
  sortHandler: () => void;
  rankSorted: boolean;
  currentPageNumber: number;
}

const GradesTabMarks: FC<Props> = (props) => {
  const appUsers = useAdminsAndInstructors();

  let studentIndex: number = (props.currentPageNumber - 1) * 10;

  const getPaperStatus = (paperDTO: PaperDTO) => {
    let paperScore = '';

    if (paperDTO.paperType === PaperTypes.STRUCTURED || paperDTO.paperType === PaperTypes.ESSAY) {
      if (!paperDTO.structEssayData?.answerSheetUrl) {
        paperScore = 'NOT UPLOADED';
      } else if (!paperDTO.structEssayData?.markedAnswerSheetUrl) {
        paperScore = 'NOT MARKED';
      } else if (!paperDTO.released) {
        paperScore = 'NOT RELEASED';
      } else {
        if (props.exam?.gradingType !== 'Percentage') {
          paperScore = removeDecimalSection(paperDTO.paperPoints?.toString() || '');
          paperScore = Math.round(parseInt(paperScore)).toString();
        } else {
          if (paperDTO.score === 0) {
            paperScore = '0%';
          }
          if (paperDTO.score) {
            paperScore =
              removeDecimalSection(
                (Math.round((+paperDTO?.score + Number.EPSILON) * 100) / 100).toString() || ''
              ) + '%';
          }
        }
      }
    } else {
      if (props.exam?.gradingType !== 'Percentage') {
        paperScore = removeDecimalSection(paperDTO.paperPoints?.toString() || '');
        paperScore = Math.round(parseInt(paperScore)).toString();
      } else {
        paperScore =
          removeDecimalSection(parseFloat(paperDTO.score?.toFixed(2) || '0').toString()) + '%';
      }
    }

    return paperScore;
  };

  return (
    <div
      className="grades-tab-marks"
      style={props.isLoading ? { height: 'calc(100vh - 290px)' } : {}}
    >
      <div className="grades-tab-marks__header">
        <div className="grades-tab-marks__header-students">
          <div className="grades-tab-marks__header-student_index" />
          <span>Student Name</span>
          <span style={{ marginLeft: '5px', cursor: 'pointer' }}></span>
        </div>
        {props.exam?.papers.map((paper, idx) => (
          <div className="grades-tab-marks__header-paper-name" key={paper.id}>
            <span>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="verifiedId">{paper.paperTitle}</Tooltip>}
              >
                <div>
                  <div>{truncate(paper.paperTitle || '', 20)}</div>
                  <div
                    style={{
                      fontSize: '10px',
                      marginTop: '5px',
                      textAlign: 'center',
                    }}
                  >
                    {props.exam?.gradingType !== 'Percentage' && `Total Points: ${paper.marks}`}
                  </div>
                </div>
              </OverlayTrigger>
            </span>
            <span style={{ marginLeft: '5px', cursor: 'pointer' }}></span>
          </div>
        ))}

        <div className="grades-tab-marks__header-total-marks">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div>
              <span> Total Marks </span>
            </div>
            <div
              style={{
                fontSize: '10px',
                marginTop: '5px',
              }}
            >
              {props.exam?.gradingType !== 'Percentage' &&
                `Total Points: ${props.exam?.overallMark}`}
            </div>
          </div>

          <span style={{ marginLeft: '5px', cursor: 'pointer' }}></span>
        </div>

        <div className="grades-tab-marks__header-rank">
          <span>Rank</span>
          <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
            {props.rankSorted ? (
              <FaSortAmountDownAlt onClick={props.sortHandler} />
            ) : (
              <FaSortAmountDown onClick={props.sortHandler} />
            )}
          </span>
        </div>
      </div>

      <div className="grades-tab-marks__body">
        {props.list
          .filter((val) => {
            let flag = true;

            val.papers.forEach((val) => {
              if (val.paperType === PaperTypes.STRUCTURED || val.paperType === PaperTypes.ESSAY) {
                if (
                  !val.structEssayData?.answerSheetUrl ||
                  val.structEssayData.answerSheetUrl === ''
                ) {
                  flag = true;
                } else if (!val.structEssayData?.markedAnswerSheetUrl || !val.released) {
                  flag = false;
                }
              }
            });

            return flag;
          })

          .map((exam, examIndex) => {
            let totalMarks = 0;
            studentIndex = studentIndex + 1;
            return (
              <div
                className="grades-tab-marks__body__row"
                style={{ marginTop: '7px' }}
                key={examIndex}
              >
                <div className="grades-tab-marks__body__row__student-name">
                  <div className="grades-tab-marks__body__row__student_index">{studentIndex}</div>
                  <span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="verifiedId">
                          {
                            appUsers.allAdminsAndInstructors.find((val) => val.uid === exam.userId)
                              ?.username
                          }
                        </Tooltip>
                      }
                    >
                      <div>
                        {truncate(
                          `${
                            appUsers.allAdminsAndInstructors.find((val) => val.uid === exam.userId)
                              ?.username
                          }` || '',
                          25
                        )}
                      </div>
                    </OverlayTrigger>
                  </span>
                </div>
                {exam.papers.map((paper, index) => {
                  if (paper.paperPoints) {
                    totalMarks += paper.paperPoints;
                  }

                  return (
                    <Fragment key={index}>
                      <div className="grades-tab-marks__body__row__marks">
                        <span> {getPaperStatus(paper)} </span>
                      </div>
                      {index === exam.papers.sort().length - 1 ? (
                        <>
                          <div className="grades-tab-marks__body__row__total-marks">
                            {props.exam?.gradingType === 'Percentage' ? (
                              <span>{exam.score && round(exam.score, 2, 2)}%</span>
                            ) : (
                              <span>{exam.score && Math.round(totalMarks)}</span>
                            )}
                          </div>
                          <div className="grades-tab-marks__body__row__rank">
                            <span>{exam.rank}</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default GradesTabMarks;
