import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { TA_SUBJECT_SECONDARY_NAV_MAIN_ITEMS } from '../constants';
import { useSubjects } from '../context/SubjectsContext';
import Backarrow from './Backarrow';

const TASubjectSecondaryNav = () => {
  const { MODAL_PAPERS, ANNOUCEMENTS } = TA_SUBJECT_SECONDARY_NAV_MAIN_ITEMS;
  const navItems = [MODAL_PAPERS, ANNOUCEMENTS];

  const { subjectId } = useParams() as { subjectId: string };
  const appSubjects = useSubjects();
  const history = useHistory();

  const subjectName = appSubjects.allSubjects.find((sub) => sub.id === subjectId)?.name || '';

  const localBaseUrl = `my-subjects/subject/${subjectId}`;
  const splittedUrl = history.location.pathname.split('/');

  const uRILength = splittedUrl.length;
  const lastSegment = decodeURI(splittedUrl.pop() || '');
  const oneBeforeTheLastSegment = decodeURI(splittedUrl[uRILength - 2]);
  const threeFromLast = decodeURI(splittedUrl[uRILength - 3]);

  const specialCheck =
    lastSegment === 'create-extra-exam' ||
    lastSegment === 'create-practise-exam' ||
    lastSegment === 'create-live-exam';

  return (
    <aside className="subject-snav">
      <header className="subject-snav__header">
        <Backarrow
          onClick={() => {
            history.push('/my-subjects');
          }}
        />
        <h6>{subjectName}</h6>
      </header>
      <li className="subject-snav__items">
        {navItems.map((item, idx) => (
          <ul
            className={`subject-snav__item  ${
              (lastSegment === item ||
                (specialCheck && item === 'Examinations') ||
                (oneBeforeTheLastSegment === 'Examinations' && item === 'Examinations') ||
                (oneBeforeTheLastSegment === 'update-exam' && item === 'Examinations') ||
                (threeFromLast === 'Examinations' && item === 'Examinations')) &&
              'subject-snav__item--active'
            }`}
            onClick={() => {
              history.push(`/${localBaseUrl}/${item}`);
            }}
            key={idx}
          >
            {item}
          </ul>
        ))}
      </li>
    </aside>
  );
};

export default TASubjectSecondaryNav;
