import React, {FC} from 'react';
import {ClipLoader} from 'react-spinners';
import './Overlay.scss';

interface Props {
  msg?: string;
  transparent?: boolean;
}

const Overlay: FC<Props> = (props) => {
  return (
    <div className="app-overlay" style={props.transparent ? {backgroundColor: 'transparent'} : {}}>
      <div className={`app-overlay__loader ${props.transparent && 'd-none'}`}>
        {props.msg ? (
          <div className="app-overlay__msg">{props.msg}</div>
        ) : (
          <ClipLoader color="#246bfd" loading={true} size={100} />
        )}
      </div>
    </div>
  );
};

// style={{backgroundColor: 'transparent'}}

export default Overlay;
