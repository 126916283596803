import React, { FC } from 'react';
import { FaEye, FaTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useAppContent } from '../context/ContentContext';
import './ChapterPreviewHeader.scss';

const ChapterPreviewHeader: FC = (props) => {
  const appContent = useAppContent();

  const history = useHistory();

  const title = appContent.contentData.find(
    (val) => val.droppableId === appContent.droppableId
  )?.contentTitle;

  return (
    <header className="content-main-preview-header">
      <h5 className="content-main-preview-header__title">{title?.value || ''}</h5>
      <button
        className="content-main-preview-header__btn"
        onClick={() => {
          history.push(appContent.previousPathToPreview);
        }}
        style={{ cursor: 'pointer' }}
      >
        <FaEye className="mr-2" />
        Previewing
        <FaTimes className="ml-2" />
      </button>
    </header>
  );
};

export default ChapterPreviewHeader;
