import React, {FC} from 'react';
import './NumberBox.scss';

interface Props {
  style?: React.CSSProperties;
  number: number;
}

const NumberBox: FC<Props> = (props) => {
  return (
    <div className="number-box" style={props.style}>
      {props.number}
    </div>
  );
};

export default NumberBox;
