import React, { FC, useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import truncate from 'truncate';
import './DropDown.scss';
import { StateDisplayMCQPapers } from './MCQTrueFalsePapersTab';

interface Props {
  name: string;
  noValueText: string;
  className?: string;
  style?: React.CSSProperties;
  stateName: string;
  stateValue: { displayValue: string; id: string };
  state: any;
  setState: Function;
  optionsArray: { displayValue: string; id: string }[];
  error?: string;
  truncate?: number;
  labelSize?: React.CSSProperties;
  disabled?: boolean;
  fireOnclick?: () => void;
  searchable?: boolean;
  initialState?: StateDisplayMCQPapers;
}

const DropDown: FC<Props> = (props) => {
  const [state, setState] = useState(false);
  const [dropdownValue, setDropdownValue] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dropdown, setDropDown] = useState(props.optionsArray);

  const inputChangeHandler = (
    state: any,
    name: string,
    value: { displayValue: string; id: string }
  ) => {
    props.setState({
      ...state,
      [name]: { ...state[name], value: value },
    });

    props.fireOnclick && props.fireOnclick();
  };

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: { ...state[name], error: '' },
      unit:
        props.state.stateName === 'lesson' || props.state.stateName === 'lessons'
          ? { ...state.unit, value: [] }
          : { ...state.unit },
    });
  };

  useEffect(() => {
    if (props.state.subject && props.state.subject.value.displayValue === '') {
      setDropdownValue('');
    }
  }, [props.state]);

  // useEffect(() => {
  //   setDropDown(
  //     props.optionsArray.filter((text) => {
  //       if (dropdownValue === '') {
  //         return true;
  //       }
  //       return text.displayValue.toLowerCase().trim().startsWith(dropdownValue.toLowerCase());
  //     })
  //   );
  //   if (dropdownValue === '') {
  //     props.setState &&
  //       props.initialState &&
  //       props.setState({
  //         ...props.initialState,
  //         papers: props.state.papers,
  //         page: props.state.page,
  //         totalPages: props.state.totalPages,
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dropdownValue, props.optionsArray]);

  //searchable  input handler
  const elementHandler = () => {
    if (props.searchable) {
      return (
        <input
          className="search_text"
          type="text"
          style={{ border: 'none' }}
          placeholder={truncate(props.noValueText, props.truncate || 30)}
          value={truncate(dropdownValue, props.truncate || 30)}
          onChange={(e) => {
            setDropdownValue(e.target.value);
          }}
        ></input>
      );
    }
    return truncate(props.stateValue.displayValue || props.noValueText, props.truncate || 30);
  };

  //searchable dropdown list handler
  let list = props.optionsArray;
  if (props.searchable) {
    list = dropdown;
  }

  useEffect(() => {
    var ignoreClickOnMeElement = document.getElementById(`id${props.stateName}`);

    document.addEventListener('click', function (event) {
      var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target as Node);
      if (!isClickInsideElement) {
        setState(false);
      }
    });
  }, [props.stateName]);

  let borderColor =
    props.error === 'NO-ERROR'
      ? { borderBottom: '1px solid #20D167' }
      : props.error === ''
      ? {}
      : { borderBottom: '1px solid #dc3545' };

  return (
    <div
      className={`custom-dropdown ${props.className}`}
      id={`id${props.stateName}`}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        setState(true);
        resetError(props.state, props.stateName);
      }}
      style={{ ...props.style, cursor: props.disabled ? 'not-allowed' : 'pointer' }}
    >
      <div className="custom-dropdown__line" style={borderColor}>
        <div style={{ paddingTop: 3, paddingBottom: 2.5 }}>
          {/* {truncate(props.stateValue.displayValue || props.noValueText, props.truncate || 30)} */}
          {elementHandler()}
        </div>

        <FaChevronDown
          color="#246BFD"
          size={12}
          className="eye"
          style={{ position: 'absolute', right: 0, top: -4 }}
        />
      </div>

      <div className="custom-dropdown__name" style={props.labelSize}>
        {props.name}
      </div>

      <div
        className="position-absolute custom-dropdown-list"
        style={{
          display: state ? 'block' : 'none',
        }}
      >
        {list.map((item, index) => {
          let className = 'dropdown-item-first';
          if (index) {
            className = 'dropdown-item-rest';
          }

          return (
            <div
              className={className}
              onClick={(e) => {
                const evt: any = e.target;
                if (props.searchable) setDropdownValue(evt.innerHTML);
                inputChangeHandler(props.state, props.stateName, {
                  displayValue: evt.innerHTML,
                  id: evt.id,
                });
                setState(false);
                e.stopPropagation();
              }}
              key={index}
              id={item.id}
            >
              {item.displayValue === '' || item.id === ''
                ? props.noValueText
                : truncate(item.displayValue, props.truncate || 40)}
            </div>
          );
        })}
      </div>

      {props.error && props.error !== 'NO-ERROR' && (
        <div
          style={{ fontSize: 11, lineHeight: 1, top: 48, left: 0, fontWeight: 400, marginTop: 8 }}
          className="text-danger"
        >
          {props.error || 'error'}
        </div>
      )}
    </div>
  );
};

export default DropDown;
