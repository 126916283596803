import React, {FC} from 'react';
import {FaPlusCircle} from 'react-icons/fa';
import './AddFirstSubjectButton.scss';

interface Props {
  openModal: () => void;
}

const AddFirstSubjectButton: FC<Props> = (props) => {
  return (
    <div className="add-first-card" onClick={props.openModal}>
      <FaPlusCircle />
      <div className="text-white mt-3">Add your first</div>
      <div className="text-white ">subject</div>
    </div>
  );
};

export default AddFirstSubjectButton;
