import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { UNIT_SECONDARY_NAV_MAIN_ITEMS } from '../constants';
import { useLessons } from '../context/LessonsContext';
import { useSubjects } from '../context/SubjectsContext';
import { useUnits } from '../context/UnitsContext';
import { useAppUser } from '../context/UserContext';
import { isValidLessonForUser, isValidSubjectForUser } from '../utils';
import Backarrow from './Backarrow';
import './UnitSecondaryNav.scss';

const UnitSecondaryNav = () => {
  const { CONTENT, MODAL_PAPERS } = UNIT_SECONDARY_NAV_MAIN_ITEMS;
  const navItems = [CONTENT, MODAL_PAPERS];

  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUnits = useUnits();
  const user = useAppUser().firestoreUser;
  const history = useHistory();

  const { subjectId, lessonId, unitId } = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
  };

  useEffect(() => {
    const isValidSubject = isValidSubjectForUser({
      user: user,
      subjectId: subjectId,
      subjects: appSubjects.allSubjects,
    });

    const { isValidLesson } = isValidLessonForUser({
      lessonId: lessonId,
      lessons: appLessons.lessons,
      subjectId: subjectId,
    });

    if (
      !isValidSubject &&
      !isValidLesson &&
      appSubjects.allSubjects.length &&
      appLessons.lessons.length
    ) {
      history.push('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSubjects.allSubjects, appLessons.lessons]);

  const localBaseUrl = `my-subjects/subject/${subjectId}/lesson/${lessonId}/unit/${unitId}`;
  const lastSegment = decodeURI(history.location.pathname.split('/').pop() || '');

  const unitName = appUnits.units.find((u) => u.id === unitId)?.name;
  const lessonName = appLessons.lessons.find((l) => l.id === lessonId)?.name;

  return (
    <aside className="unit-snav">
      <header className="unit-snav__header">
        <Backarrow
          onClick={() => {
            if (lessonName && unitName) {
              history.push(`/my-subjects/subject/${subjectId}/lesson/${lessonId}/My Units`);
            } else if (lessonName) {
              history.push(`/my-subjects/subject/${subjectId}/Lessons`);
            }
          }}
        />
        <h6>{unitName}</h6>
      </header>
      <li className="unit-snav__items">
        {navItems.map((item, idx) => (
          <ul
            className={`unit-snav__item  ${lastSegment === item && 'unit-snav__item--active'} ${
              item === 'Content' && lastSegment === 'Create' && 'unit-snav__item--active'
            } ${history.location.pathname.includes(item) && 'unit-snav__item--active'} `}
            onClick={() => {
              history.push(`/${localBaseUrl}/${item}`);
            }}
            key={idx}
          >
            {item}
          </ul>
        ))}
      </li>
    </aside>
  );
};

export default UnitSecondaryNav;
