import React, { FC, Fragment, useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRedoAlt, FaWindowClose } from 'react-icons/fa';
import { useFunctions } from 'reactfire';
import truncate from 'truncate';
import Modal from 'react-modal';
import './StudentRoster.scss';
import { Paper } from '../models/Paper';
import { ExamToDoDTO } from '../dtos/ExamToDoDTO';
import { UserFirestore } from '../models/UserFirestore';
import { PaperTypes } from '../enums/paperTypes';
import { StudentTAAssignmentInfo } from './ExamTAAssignmentChart';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
// import {useSubjects} from '../context/SubjectsContext';
import { useAppUser } from '../context/UserContext';
import { PaperInfoData, PaperStats } from '../interfaces';
import { getStatusOfPaper, sortByExamToDoDTOArrayUsername } from '../utils';
import SwitchBtn from './SwitchBtn';
import DropDown from './DropDown';
import { ADMIN_PAPERSTATE_FILTERS, TA_PAPERSTATE_FILTERS } from '../constants';
import Notification from './../components/Notification';
// import ModelExamGradingPreview from './ModelExamGradingPreview';
import { StatusOfPaper } from '../enums';
import ModelExamGradingPreview from './ModelExamGradingPreview';
import { useSubjects } from '../context/SubjectsContext';
// TODO : setIsAbleToRelease might be able to be removed
interface Props {
  papers: Paper[];
  completedExams: ExamToDoDTO[];
  usersYetToSubmit: UserFirestore[];
  completedUsers: UserFirestore[];
  paperInfoData: PaperInfoData;
  subjectId: string;
  examId: string;
  reload?: boolean;
  setPaperInfoData: (value: PaperInfoData | null) => void;
  setCompletedExams: (exams: ExamToDoDTO[]) => void;
  setIsLoading: (value: boolean) => void;
  reloadHandler?: () => void;
  isLoading: boolean;
  studentTAAssignmentInfo?: StudentTAAssignmentInfo[];
}

const removeTrailingZerosFromPercentage = (score: string) => {
  return score.includes('%') ? (+score.replace('%', '')).toString() + '%' : score;
};

const StudentRoster: FC<Props> = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const [isReleased, setIsReleased] = useState(false);
  // const [isAbleToRelease, setIsAbleToRelease] = useState(false);
  const users = useAdminsAndInstructors();
  const subjects = useSubjects();
  const appUser = useAppUser();

  const toggleTab = (index: React.SetStateAction<number>) => {
    setToggleState(index);
  };

  const [openModal, setOpenModal] = useState(false);

  const bulkReleaseRef = useFunctions().httpsCallable('bulkRelease');
  const bulkReleaseChangeRef = useFunctions().httpsCallable('bulkUnRelease');

  const getTAOnlyExamsAndUsers = (
    comExams: ExamToDoDTO[]
  ): [ExamToDoDTO[], (UserFirestore | null)[]] => {
    const taId = appUser?.firestoreUser?.uid;
    const c: ExamToDoDTO[] = [];
    const u: (UserFirestore | null)[] = [];
    comExams.forEach((cE) => {
      const array = props.studentTAAssignmentInfo?.map((v) => {
        if (
          v.papers.find(
            (p) => p.paperId === props.paperInfoData.paperId && p.assignedTAUid?.taUid === taId
          )
        ) {
          return v.stuUid;
        } else {
          return '';
        }
      });

      if (array?.includes(cE.userId || '')) {
        u.push(users.allAdminsAndInstructors.find((a) => a.uid === cE.userId) || null);
        c.push(cE);
      }
    });

    return [c, u];
  };

  const [paperStats, setPaperStats] = useState<PaperStats>({
    approved: 0,
    rejected: 0,
    pending: 0,
    notAssigned: 0,
    notMarked: 0,
  });

  const pT = props.papers.find((p) => p.id === props.paperInfoData.paperId)?.paperType;

  const [state, setState] = useState({
    paperState: {
      value: { displayValue: '', id: '' },
      label: 'Paper State',
      type: 'dropdown',
      error: '',
    },
  });

  const SELECTEDPAPERTYPE = props.papers.find(
    (val) => val.id === props.paperInfoData.paperId
  )?.paperType;

  useEffect(() => {
    // if (props.completedExams) {
    //   props.completedExams.forEach((ex) => {
    //     const structEssayPaper = ex.papers
    //       .filter((p) => p.paperType === PaperTypes.STRUCTURED || p.paperType === PaperTypes.ESSAY)
    //       .find((p) => p.id === props.paperInfoData.paperId);

    //     let isMarked = structEssayPaper?.structEssayData?.markedAnswerSheetUrl;
    //     const isUploaded = structEssayPaper?.structEssayData?.answerSheetUrl;
    //     if (isMarked && isUploaded) {
    //       setIsAbleToRelease(true);
    //     }
    //   });
    // }

    const exam = props.completedExams.filter((exam) => exam.examId === props.examId);
    if (exam.length > 0) {
      let flag = false;
      exam.forEach((exam) => {
        const paper = exam.papers.find((paper) => paper.id === props.paperInfoData.paperId);
        if (paper) {
          if (!!paper.released) {
            flag = true;
          }
        }
      });
      setIsReleased(flag);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.completedExams, props.paperInfoData.paperId]);

  useEffect(() => {
    const paperStats: PaperStats = {
      approved: 0,
      notAssigned: 0,
      notMarked: 0,
      pending: 0,
      rejected: 0,
    };

    const role = appUser.firestoreUser?.userRole?.isTeachingAssistant ? 'TA' : 'ADMININS';
    const userId = appUser.firestoreUser?.uid;

    props.completedExams?.forEach((ex) => {
      const isGradeTypePercentage = ex.gradingType === 'Percentage';
      const PaperTypeMCQorTF = ex.papers
        .filter((p) => p.paperType === PaperTypes.MCQ || p.paperType === PaperTypes.TRUEFALSE)
        .find((p) => p.id === props.paperInfoData.paperId);

      if (role === 'TA') {
        let structEssayPaper: any;
        // let paperNotAssingedToThisTA: any;

        // student id array
        const array = props.studentTAAssignmentInfo?.map((v) => {
          if (
            v.papers.find(
              (p) => p.paperId === props.paperInfoData.paperId && p.assignedTAUid?.taUid === userId
            )
          ) {
            return v.stuUid;
          } else {
            return '';
          }
        });
        if (array?.includes(ex.userId || '')) {
          structEssayPaper = ex.papers
            .filter(
              (p) => p.paperType === PaperTypes.STRUCTURED || p.paperType === PaperTypes.ESSAY
            )
            .find((p) => p.id === props.paperInfoData.paperId);
        }
        const paperData = getStatusOfPaper(
          role,
          isGradeTypePercentage,
          structEssayPaper,
          false,
          !!PaperTypeMCQorTF
        );

        if (paperData?.status === StatusOfPaper.APPROVED) {
          paperStats.approved = paperStats.approved + 1;
        } else if (paperData?.status === StatusOfPaper.REJECTED) {
          paperStats.rejected = paperStats.rejected + 1;
        } else if (paperData?.status === StatusOfPaper.PENDING) {
          paperStats.pending = paperStats.pending + 1;
        } else if (paperData?.status === StatusOfPaper.ASSIGNED) {
          paperStats.notMarked = paperStats.notMarked + 1;
        }

        // below code is for Admin
      } else {
        let onlyAdmin: any;
        let structEssayPaper = ex.papers
          .filter((p) => p.paperType === PaperTypes.STRUCTURED || p.paperType === PaperTypes.ESSAY)
          .find((p) => p.id === props.paperInfoData.paperId);
        const array = props.studentTAAssignmentInfo?.map((v, o) => {
          if (
            v.papers.find((p) => {
              return p.assignedTAUid?.taUid && p.paperId === props.paperInfoData.paperId;
            })
          ) {
            return v.stuUid;
          } else {
            return '';
          }
        });
        if (array?.includes(ex.userId || '')) {
          onlyAdmin = 'ASSIGNED';
        }

        const paperData = getStatusOfPaper(
          role,
          isGradeTypePercentage,
          structEssayPaper,
          !!onlyAdmin,
          !!PaperTypeMCQorTF
        );

        if (
          paperData?.status === StatusOfPaper.APPROVED ||
          paperData?.status === StatusOfPaper.RELEASED_ASSIGNED
        ) {
          paperStats.approved = paperStats.approved + 1;
        } else if (paperData?.status === StatusOfPaper.REJECTED) {
          paperStats.rejected = paperStats.rejected + 1;
        } else if (paperData?.status === StatusOfPaper.PENDING) {
          paperStats.pending = paperStats.pending + 1;
        } else if (
          paperData?.status === StatusOfPaper.NOT_MARKED_NOT_ASSIGNED ||
          paperData?.status === StatusOfPaper.MARKED_NOT_ASSIGNED
        ) {
          paperStats.notAssigned = paperStats.notAssigned + 1;
        }
      }
    });

    setPaperStats({ ...paperStats });
  }, [
    appUser.firestoreUser,
    props.completedExams,
    props.paperInfoData.paperId,
    props.studentTAAssignmentInfo,
  ]);

  const isReadyToRelease = () => {
    let countToRelase = 0;

    const role = appUser.firestoreUser?.userRole?.isTeachingAssistant ? 'TA' : 'ADMININS';

    props.completedExams?.forEach((ex) => {
      const isGradeTypePercentage = ex.gradingType === 'Percentage';
      const PaperTypeMCQorTF = ex.papers
        .filter((p) => p.paperType === PaperTypes.MCQ || p.paperType === PaperTypes.TRUEFALSE)
        .find((p) => p.id === props.paperInfoData.paperId);

      let onlyAdmin: any;
      let structEssayPaper = ex.papers
        .filter((p) => p.paperType === PaperTypes.STRUCTURED || p.paperType === PaperTypes.ESSAY)
        .find((p) => p.id === props.paperInfoData.paperId);
      const array = props.studentTAAssignmentInfo?.map((v, o) => {
        if (
          v.papers.find((p) => {
            return p.assignedTAUid?.taUid && p.paperId === props.paperInfoData.paperId;
          })
        ) {
          return v.stuUid;
        } else {
          return '';
        }
      });
      if (array?.includes(ex.userId || '')) {
        onlyAdmin = 'ASSIGNED';
      }

      const paperData = getStatusOfPaper(
        role,
        isGradeTypePercentage,
        structEssayPaper,
        !!onlyAdmin,
        !!PaperTypeMCQorTF
      );

      if (paperData?.status === StatusOfPaper.APPROVED) {
        countToRelase++;
      } else if (paperData?.status === StatusOfPaper.MARKED_NOT_ASSIGNED) {
        countToRelase++;
      }
    });

    return !!countToRelase;
  };

  const isVisibleToActive = () => {
    let countToRelase = 0;
    const role = appUser.firestoreUser?.userRole?.isTeachingAssistant ? 'TA' : 'ADMININS';

    props.completedExams?.forEach((ex) => {
      const isGradeTypePercentage = ex.gradingType === 'Percentage';
      const PaperTypeMCQorTF = ex.papers
        .filter((p) => p.paperType === PaperTypes.MCQ || p.paperType === PaperTypes.TRUEFALSE)
        .find((p) => p.id === props.paperInfoData.paperId);

      let onlyAdmin: any;
      let structEssayPaper = ex.papers
        .filter((p) => p.paperType === PaperTypes.STRUCTURED || p.paperType === PaperTypes.ESSAY)
        .find((p) => p.id === props.paperInfoData.paperId);
      const array = props.studentTAAssignmentInfo?.map((v, o) => {
        if (
          v.papers.find((p) => {
            return p.assignedTAUid?.taUid && p.paperId === props.paperInfoData.paperId;
          })
        ) {
          return v.stuUid;
        } else {
          return '';
        }
      });
      if (array?.includes(ex.userId || '')) {
        onlyAdmin = 'ASSIGNED';
      }

      const paperData = getStatusOfPaper(
        role,
        isGradeTypePercentage,
        structEssayPaper,
        !!onlyAdmin,
        !!PaperTypeMCQorTF
      );

      if (paperData?.status === StatusOfPaper.APPROVED) {
        countToRelase++;
      } else if (paperData?.status === StatusOfPaper.MARKED_NOT_ASSIGNED) {
        countToRelase++;
      } else if (
        paperData?.status === StatusOfPaper.RELEASED ||
        paperData?.status === StatusOfPaper.RELEASED_ASSIGNED
      ) {
        countToRelase++;
      }
    });

    return !!countToRelase;
  };

  return (
    <>
      <nav className={`nav-menu-test ${props.paperInfoData ? 'active' : ''}`}>
        <div className="roaster-slider">
          <div className="roaster-slider-header">
            <div className="roaster-slider-header-title">{props.paperInfoData.paperTitle}</div>
            <div className="roaster-slider-header-close">
              <FaWindowClose
                color="#FF4C6C"
                size={20}
                onClick={() => {
                  props.setPaperInfoData(null);
                }}
              />
            </div>
          </div>

          {pT === PaperTypes.ESSAY || pT === PaperTypes.STRUCTURED ? (
            <div
              className="roaster-slider-body"
              style={{
                marginTop: '50px',
              }}
            >
              <div className="roaster-slider-body-item-1">
                <div className="total-student-count">{paperStats.approved}</div>
                <div className="relavant-name">Approved</div>
              </div>

              <div className="roaster-slider-body-item-2">
                <div className="total-student-count">{paperStats.rejected}</div>
                <div className="relavant-name">Rejected</div>
              </div>

              <div className="roaster-slider-body-item-3">
                <div className="total-student-count">{paperStats.pending}</div>
                <div className="relavant-name">Pending</div>
              </div>

              {appUser.firestoreUser?.userRole?.isTeachingAssistant ? (
                <div className="roaster-slider-body-item-5">
                  <div className="total-student-count">{paperStats.notMarked}</div>
                  <div className="relavant-name">Not Marked</div>
                </div>
              ) : (
                <div className="roaster-slider-body-item-4">
                  <div className="total-student-count">{paperStats.notAssigned}</div>
                  <div className="relavant-name">Not Assigned</div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          {appUser.firestoreUser?.userRole?.isAdmin ||
          appUser.firestoreUser?.userRole?.isInstructor ? (
            <div
              className="publish-btn"
              style={{
                width: '90%',
                marginLeft: '25px',
                marginTop: '40px',
              }}
            >
              {props.paperInfoData.paperType === PaperTypes.MCQ ||
              props.paperInfoData.paperType === PaperTypes.TRUEFALSE ? (
                <></>
              ) : (
                <>
                  <Button
                    className="pb-all"
                    onClick={() => {
                      if (!props.isLoading && props.completedExams?.length) {
                        props.setIsLoading(true);
                        bulkReleaseRef({
                          examId: props.examId,
                          paperId: props.paperInfoData.paperId,
                        })
                          .then(() => {
                            Notification({
                              isSuccess: true,
                              message: 'All Marks Released Successfully',
                            });
                            props.reloadHandler && props.reloadHandler();
                            props.setIsLoading(false);
                          })
                          .catch(() => {
                            Notification({
                              isSuccess: false,
                              message: 'Failed to bulk release',
                            });
                            props.setIsLoading(false);
                          });
                      }
                    }}
                    style={
                      props.isLoading ? { backgroundColor: 'grey', cursor: 'not-allowed' } : {}
                    }
                    disabled={
                      props.isLoading || !props.completedExams?.length || !isReadyToRelease()
                    }
                  >
                    Publish All Marks
                  </Button>
                  <div
                    className="visibility-container"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <SwitchBtn
                      disabled={
                        props.isLoading || !props.completedExams?.length || !isVisibleToActive()
                      }
                      changeHandler={(event) => {
                        if (!props.isLoading) {
                          props.setIsLoading(true);
                          bulkReleaseChangeRef({
                            examId: props.examId,
                            paperId: props.paperInfoData.paperId,
                            isUnRelease: !event,
                          })
                            .then(() => {
                              if (event) {
                                Notification({
                                  isSuccess: true,
                                  message: 'All Marks Released Successfully',
                                });
                              } else {
                                Notification({
                                  isSuccess: true,
                                  message: 'All Marks Unreleased Successfully',
                                });
                              }
                              props.reloadHandler && props.reloadHandler();
                            })
                            .catch(() => {
                              if (event) {
                                Notification({
                                  isSuccess: false,
                                  message: 'Failed to bulk release',
                                });
                              } else {
                                Notification({
                                  isSuccess: false,
                                  message: 'Failed to bulk Unreleased',
                                });
                              }
                              props.setIsLoading(false);
                            })
                            .finally(() => {
                              props.setIsLoading(false);
                            });
                        }
                      }}
                      checked={isReleased}
                      loading={props.isLoading || !props.completedExams?.length}
                    />
                    Visibility
                  </div>
                </>
              )}
            </div>
          ) : (
            <></>
          )}

          <div
            className="submission-toggle-switch"
            style={{
              marginTop: '40px',
            }}
          >
            <div
              className="submission-toggle-switch-item-left"
              onClick={() => {
                toggleTab(1);
              }}
            >
              <button
                className={
                  toggleState === 1
                    ? 'left-button active-btn-submissions'
                    : 'inactive-btn-submissions'
                }
              >
                Submitted <br />
                Students
              </button>
            </div>
            <div
              className="submission-toggle-switch-item-right"
              onClick={() => {
                toggleTab(2);
              }}
            >
              <button
                className={
                  toggleState === 2
                    ? 'right-btn active-btn-submissions'
                    : 'inactive-btn-submissions'
                }
              >
                Not Submitted <br />
                Students
              </button>
            </div>
            <FaRedoAlt
              className="ml-2 text-white "
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.reloadHandler && props.reloadHandler();
              }}
            />
          </div>

          {/* ----------------Submission Content----------------------- */}

          {SELECTEDPAPERTYPE === PaperTypes.STRUCTURED || SELECTEDPAPERTYPE === PaperTypes.ESSAY ? (
            <div
              style={{
                padding: '0 30px',
                marginTop: '30px',
              }}
            >
              <DropDown
                className="account-margin"
                name="Paper Status"
                noValueText="All"
                stateName="paperState"
                stateValue={state.paperState.value}
                state={state}
                setState={setState}
                optionsArray={
                  appUser.firestoreUser?.userRole?.isTeachingAssistant
                    ? TA_PAPERSTATE_FILTERS
                    : ADMIN_PAPERSTATE_FILTERS
                }
                error={state.paperState.error}
              />
            </div>
          ) : (
            <></>
          )}

          {toggleState === 1 ? (
            <div
              className="student-submission-content"
              style={{
                marginTop: '20px',
              }}
            >
              <div className="content-header">
                <div className="left-topic-student">Student</div>
                <div className="right-topic-marks">Marks</div>
              </div>

              <div className="marks-details">
                {sortByExamToDoDTOArrayUsername(
                  props.completedExams?.map((ex) => {
                    const userWhoDidTheExam = users.allAdminsAndInstructors.find(
                      (u) => ex.userId === u.uid
                    )?.username;
                    ex.userWhoDidTheExam = userWhoDidTheExam;
                    return ex;
                  })
                ).map((ex, index) => {
                  // const PaperTypeMCQorTF = ex.papers
                  //   .filter(
                  //     (p) => p.paperType === PaperTypes.MCQ || p.paperType === PaperTypes.TRUEFALSE
                  //   )
                  //   .find((p) => p.id === props.paperInfoData.paperId);

                  const mcqTFPaper = ex.papers
                    .filter(
                      (p) => p.paperType === PaperTypes.MCQ || p.paperType === PaperTypes.TRUEFALSE
                    )
                    .find((p) => p.id === props.paperInfoData.paperId);

                  let structEssayPaper;
                  let paperNotAssingedToThisTA = '';
                  let onlyAdmin = '';

                  if (appUser.firestoreUser?.userRole?.isTeachingAssistant) {
                    const taId = appUser.firestoreUser.uid;

                    //check if the ta is assigned to this paper
                    const array = props.studentTAAssignmentInfo?.map((v) => {
                      if (
                        v.papers.find(
                          (p) =>
                            p.paperId === props.paperInfoData.paperId &&
                            p.assignedTAUid?.taUid === taId
                        )
                      ) {
                        return v.stuUid;
                      } else {
                        return '';
                      }
                    });

                    if (array?.includes(ex.userId || '')) {
                      structEssayPaper = ex.papers
                        .filter(
                          (p) =>
                            p.paperType === PaperTypes.STRUCTURED ||
                            p.paperType === PaperTypes.ESSAY
                        )
                        .find((p) => p.id === props.paperInfoData.paperId);
                    } else {
                      paperNotAssingedToThisTA = 'NOTASSIGNED';
                    }
                  } else {
                    structEssayPaper = ex.papers
                      .filter(
                        (p) =>
                          p.paperType === PaperTypes.STRUCTURED || p.paperType === PaperTypes.ESSAY
                      )
                      .find((p) => p.id === props.paperInfoData.paperId);

                    //check if the ta is assigned to this paper
                    const array = props.studentTAAssignmentInfo?.map((v, o) => {
                      if (
                        v.papers.find((p) => {
                          return (
                            p.assignedTAUid?.taUid && p.paperId === props.paperInfoData.paperId
                          );
                        })
                      ) {
                        return v.stuUid;
                      } else {
                        return '';
                      }
                    });

                    if (array?.includes(ex.userId || '')) {
                      onlyAdmin = 'ASSIGNED';
                    }
                  }

                  const isGradeTypePercentage = ex.gradingType === 'Percentage';

                  const role = appUser.firestoreUser?.userRole?.isTeachingAssistant
                    ? 'TA'
                    : 'ADMININS';

                  const mcqTFScore = isGradeTypePercentage
                    ? mcqTFPaper?.score?.toString().split('.')[1]?.length
                      ? mcqTFPaper?.score === 100
                        ? '100%'
                        : (mcqTFPaper?.score || 0).toString().substring(0, 5) + '%'
                      : mcqTFPaper?.score + '%'
                    : mcqTFPaper?.paperPoints + '/' + mcqTFPaper?.marks;

                  if (paperNotAssingedToThisTA) {
                    return <Fragment key={index}></Fragment>;
                  }

                  const data = getStatusOfPaper(
                    role,
                    isGradeTypePercentage,
                    structEssayPaper,
                    !!onlyAdmin,
                    !!mcqTFPaper
                  );

                  if (
                    data?.status !== state.paperState.value.id &&
                    state.paperState.value.id !== '' &&
                    !mcqTFPaper
                  ) {
                    return <Fragment key={index}></Fragment>;
                  }

                  return (
                    <div
                      className="row-details"
                      onClick={() => {
                        setOpenModal(true);

                        props.setPaperInfoData({
                          ...props.paperInfoData,
                          uid: ex.userId || '',
                          examId: ex.examId || '',
                          index: index,
                          assigned: !!onlyAdmin,
                        });
                      }}
                      key={index}
                    >
                      {data?.icon ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="verifiedId1">{data?.status}</Tooltip>}
                        >
                          <>
                            <data.icon.iconVal color={data.icon.color} />
                          </>
                        </OverlayTrigger>
                      ) : (
                        <></>
                      )}

                      <div className="student-name ml-2">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="verifiedId">
                              {
                                users.allAdminsAndInstructors.find((u) => u.uid === ex.userId)
                                  ?.username
                              }
                            </Tooltip>
                          }
                        >
                          <div>
                            {truncate(
                              users.allAdminsAndInstructors.find((u) => u.uid === ex.userId)
                                ?.username || '',
                              15
                            )}
                          </div>
                        </OverlayTrigger>
                      </div>

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="verifiedId">
                            {mcqTFPaper ? mcqTFScore + '' : data?.status}
                          </Tooltip>
                        }
                      >
                        <div className="student-marks mt-0">
                          <>
                            {' '}
                            {truncate(
                              mcqTFPaper
                                ? removeTrailingZerosFromPercentage(mcqTFScore) + ''
                                : removeTrailingZerosFromPercentage(
                                    data?.score ? data?.score : ''
                                  ) + '',
                              15
                            )}
                          </>
                        </div>
                      </OverlayTrigger>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="student-not-submitted-content">
              <div className="content-header">
                <div className="left-topic-student">Student Name</div>
              </div>

              <div className="marks-details">
                {props.usersYetToSubmit.map((user, idx) => (
                  <div className="row-details" key={idx}>
                    <div className="student-name">{user?.username}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </nav>

      <Modal
        isOpen={openModal}
        onRequestClose={() => {
          setOpenModal(false);
        }}
        className="modal-grading"
        contentLabel="Example Modal"
        overlayClassName="grading-and-review-overlay"
        ariaHideApp={true}
        closeTimeoutMS={500}
      >
        <ModelExamGradingPreview
          paperInfoData={props.paperInfoData}
          setPaperInfoData={props.setPaperInfoData}
          subjectName={subjects.allSubjects.find((sub) => sub.id === props.subjectId)?.name || ''}
          completedExams={sortByExamToDoDTOArrayUsername(
            appUser.firestoreUser?.userRole?.isTeachingAssistant
              ? getTAOnlyExamsAndUsers(props.completedExams)[0]
              : props.completedExams
          )}
          setOpenModal={setOpenModal}
          examId={props.examId}
          setCompletedExams={props.setCompletedExams}
          paperStats={paperStats}
          setPaperStatsHandler={setPaperStats}
          studentTAAssignmentInfo={props.studentTAAssignmentInfo}
          reloadHandler={props.reloadHandler}
          reload={props.reload}
        />
      </Modal>
    </>
  );
};

export default StudentRoster;
