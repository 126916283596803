import PrimaryNav from '../../components/PrimaryNav';
import StudentSubjectHomeView from '../../components/StudentSubjectHomeView';
import LayoutNew from '../../layouts/LayoutNew';
import BreadCrumbUpdate from '../../shared/BreadCrumbUpdate';

const StudentSubjectHomeScreen = (props: { secondaryNav: any }) => {
  const primaryNav = <PrimaryNav />;
  const secondaryNavigation = props.secondaryNav;
  const body = <StudentSubjectHomeView />;

  return (
    <LayoutNew
      header={<BreadCrumbUpdate />}
      primaryNav={primaryNav}
      secondaryNav={secondaryNavigation}
      body={body}
    />
  );
};

export default StudentSubjectHomeScreen;
