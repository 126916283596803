import React, {FC} from 'react';
import {PaperTypes} from '../enums/paperTypes';
import './FilterCheckBox.scss';

interface Props {
  onClick: () => void;
  item: {
    label: string;
    name: PaperTypes;
  };
  active?: boolean;
  isDisabled?: boolean;
}

const FilterCheckBox: FC<Props> = (props) => {
  return (
    <>
      <div className="checkbox-container" style={props.isDisabled ? {cursor: 'default'} : {}}>
        <div className="d-inline-block checkbox-text" onClick={props.onClick}>
          <span>{props.item.label}</span>
        </div>
        <div
          onClick={props.onClick}
          className={`tick-mark d-inline-block ${props.active && 'checked'}`}
        />
      </div>
    </>
  );
};

export default FilterCheckBox;
