import React, { FC } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useMobileNav } from '../context/MobNavContext';
import './HamburgerIcon.scss';

interface Props {
  className?: string;
  onClick?: () => void;
}

const HamburgerIcon: FC<Props> = (props) => {
  const appMobileNav = useMobileNav();

  return (
    <div
      className={`hamburger ${props.className}`}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      {!appMobileNav.openMobileNav ? (
        <>
          <div className="hamburger__bar" />
          <div className="hamburger__bar" />
          <div className="hamburger__bar" />
        </>
      ) : (
        <FaTimes className="text-white" size={20} />
      )}
    </div>
  );
};

export default HamburgerIcon;
