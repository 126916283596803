import React, {createContext, FC, useContext, useState} from 'react';

export interface MobNavContext {
  openMobileNav: boolean;
  setOpenMobileNav: (value: boolean) => void;
}

interface State {
  openMobileNav: boolean;
}

const initialContext: MobNavContext = {
  openMobileNav: false,
  setOpenMobileNav: (value: boolean) => {},
};

const initialState: State = {
  openMobileNav: false,
};

const Context = createContext<MobNavContext>(initialContext);

export const MobileNavProvider: FC = ({children}) => {
  const [state, setState] = useState(initialState);
  const setOpenMobileNav = (value: boolean) => {
    setState((pS) => ({
      ...pS,
      openMobileNav: value,
    }));
  };

  return (
    <Context.Provider
      value={{
        ...state,
        setOpenMobileNav,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useMobileNav = (): MobNavContext => useContext(Context);
