import {FaSortAmountDownAlt} from 'react-icons/fa';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import React, {FC, Fragment, useEffect, useState} from 'react';
import {useFunctions} from 'reactfire';
import './TAPaymentTab.scss';
import LoadingIndicator from './LoadingIndicator';
import {TAPaymentsCard} from './TAPaymentCard';

interface Props {
  examId?: string;
}

interface TAPaymentPaperInfo {
  paperId: string;
  paperTitle: string;
  totalPayment: number;
  pendingPayment: number;
  totalMarkedAndFree: number;
  totalPending: number;
  totalRejected: number;
  totalPayedAmount: number;
  paperPayment?: {amount: number; isFree: boolean};
  totalPayedPapers: number;
  totalAssigned: number;
  notMarked: number;
  totalApproved: number;
}

interface TAPaymentInfo {
  taUid: string;
  papers: TAPaymentPaperInfo[];
}

const TAPaymentTab: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taPayments, setTAPayments] = useState<TAPaymentInfo[]>([]);
  const [papers, setPapers] = useState<any[]>([]);

  const getTaPaymentDashboardRef = useFunctions().httpsCallable('getTaPaymentDashboard');
  const makeTaPaymentsRef = useFunctions().httpsCallable('makeTaPayments');
  useEffect(() => {
    if (props.examId) {
      setIsLoading(true);

      getTaPaymentDashboardRef({examId: props.examId})
        .then((result) => {
          setPapers([...result.data.data.papers]);
          setTAPayments([...result.data.data.TAPaymentInfo]);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.examId]);

  const onClickPay = (taUid: string) => {
    setIsLoading(true);
    makeTaPaymentsRef({examId: props.examId, taUid: taUid})
      .then((result) => {
        setPapers([...result.data.data.papers]);
        setTAPayments([...result.data.data.TAPaymentInfo]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Fragment>
        {isLoading && <LoadingIndicator />}
        <section className="taa-payment" style={{width: '100%', overflowX: 'auto'}}>
          <section className="taa-payment__tas">
            <table
              className="taa-payment-table"
              style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}
            >
              <thead>
                <tr>
                  <th className="taa-payment__tas-stu-name">
                    Teaching Assistant{' '}
                    {
                      <FaSortAmountDownAlt
                        onClick={() => {
                          // const arr = sortHander(state.studentAssignmentInfoList, false);
                          // const arr1 = splitArrayIntoChunksOfLen(arr, PAGINATION);
                          // setState((ps) => {
                          //   return {...ps, studentAssignmentInfoList: arr, paginatedSegments: arr1};
                          // });
                          //
                          // setState((ps) => ({...ps, sort: false}));
                        }}
                        style={{cursor: 'pointer'}}
                      />
                    }
                  </th>
                  {papers.map((paper, index) => (
                    <th key={index}>
                      <>
                        <div className="taa-payment__tas-name-container text-center">
                          <div className="taa-payment__tas-ta-name">
                            {/* {
                            appUsers.allAdminsAndInstructors.find((ta) => ta.uid === taUid)
                              ?.username
                          } */}
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="verifiedId">{paper.paperTitle}</Tooltip>}
                            >
                              <div>{paper.paperTitle}</div>
                            </OverlayTrigger>
                          </div>
                          <div className="taa-payment__tas-assigned">
                            (Amount per paper : {paper.taPayment.amount})
                          </div>
                        </div>
                      </>
                    </th>
                  ))}

                  <th>
                    <div className="taa-payment__tas-name-container text-center">
                      <div className="taa-payment__tas-ta-name">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="verifiedId">Total</Tooltip>}
                        >
                          <div className="text-center">Total Amount</div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </th>
                  <th></th>
                  <th>
                    <div className="taa-payment__tas-name-container text-center">
                      <div className="taa-payment__tas-ta-name">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="verifiedId">Payment Completed</Tooltip>}
                        >
                          <div>Payment Completed</div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {taPayments.map((taPayment, index) => (
                  <TAPaymentsCard taPayment={taPayment} onClickPay={onClickPay} key={index} />
                ))}
              </tbody>
            </table>
          </section>
        </section>
        {/*<Pagination*/}
        {/*  pages={state.paginatedSegments.length}*/}
        {/*  setCurrentPage={setCurrentPage}*/}
        {/*  clear={state.trigger}*/}
        {/*/>*/}
      </Fragment>
    </>
  );
};

export default TAPaymentTab;
