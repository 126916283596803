import React, { FC } from 'react';
import { useParams } from 'react-router';
import MainLayout from '../layouts/MainLayout';
import './ResetEmailSent.scss';

const ResetEmailSent: FC = () => {
  const params = useParams() as { email: string };

  return (
    <MainLayout>
      <div className="reset-password_description">
        Password <span>Recovery email </span> has been <br></br>sent to your email address
        <div className="reset-password_sub_description">
          Please check your email <span> {params.email}</span>
        </div>
      </div>
    </MainLayout>
  );
};

export default ResetEmailSent;
