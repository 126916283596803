import moment from 'moment';
import {useEffect, useMemo, useState} from 'react';
import {FaDownload} from 'react-icons/fa';
import {useFirestore, useFunctions} from 'reactfire';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import {useSubjects} from '../context/SubjectsContext';
import {PaperTypes} from '../enums/paperTypes';
import {Exam} from '../models/Exam';
import {Subject} from '../models/Subject';
import {UserFirestore} from '../models/UserFirestore';
import Notification from './../components/Notification';
import ExcelHelper, {LectureReport, PapperMarkingReport} from '../utils/ExcelHelper';
import './Reports.scss';
import Select from 'react-select';
import LoadingIndicator from './LoadingIndicator';

interface FormDataErrors {
  reportTypeError: string;
  instructorError: string;
  subjectError: string;
  gradeError: string;
  timeFrameError: string;
  startDateError: string;
  endDateError: string;
}

interface ITaPaymentInfo {
  paperId: string;
  paperTitle: string;
  paperType?: PaperTypes;
  totalPayment: number;
  totalFree: number;
  totalPaid: number;
  totalNotMarked: number;
  totalMarked: number;
  totalMarkedAndPaid: number;
  totalMarkedAndFree: number;
  totalPapers: number;
  amount: number;
  isFree: boolean;
  totalAproved: number;
  totalRejected: number;
  totalPending: number;
}

enum ReportTypes {
  LectureReport = 'Lecturer report (A Monthly Report)',
  PapperMarkerReport = 'Paper Marker Report',
}

const Report = () => {
  const [loading, setLoading] = useState(false);
  const { allAdminsAndInstructors } = useAdminsAndInstructors();
  const [reportType, setReportType] = useState<ReportTypes>();
  const [instructors, setInstructors] = useState<UserFirestore[]>([]);
  const [selectedInstructor, setSelectedInstructor] = useState<UserFirestore | 'none'>();
  const { allSubjects } = useSubjects();
  const [subjects, setSubjects] = useState<Subject[]>();
  const [selectedSubject, setSelectedSubject] = useState<Subject | 'none'>();
  const [grades, setGrades] = useState<{ subjectId?: string; grade: string }[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<
    { subjectId?: string; grade: string } | 'none'
  >();
  const [timeframe, setTimeframe] = useState<'thisMonth' | 'custom' | 'All' | 'none'>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const [errors, setErrors] = useState<FormDataErrors>({
    reportTypeError: '',
    instructorError: '',
    subjectError: '',
    gradeError: '',
    timeFrameError: '',
    startDateError: '',
    endDateError: '',
  });
  console.log(loading);
  useEffect(() => {
    //resetting start/end date on timeframe is custom
    if (timeframe === 'custom') {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [timeframe]);

  const store = useFirestore();
  const getPaymentData = useFunctions().httpsCallable('getTaPaymentData');

  const gradesToNumbers = (grade: string) => {
    switch (grade.toLowerCase()) {
      case 'one': {
        return 1;
      }
      case 'two': {
        return 2;
      }
      case 'three': {
        return 3;
      }
      case 'four': {
        return 4;
      }
      case 'five': {
        return 5;
      }
      case 'six': {
        return 6;
      }
      case 'seven': {
        return 7;
      }
      case 'eight': {
        return 8;
      }
      case 'nine': {
        return 9;
      }
      case 'o/l': {
        return 10;
      }
      case 'a/l': {
        return 11;
      }
      default: {
        return 0;
      }
    }
  };

  useEffect(() => {
    const instructorsTemp = allAdminsAndInstructors
      .filter((u) => {
        const subjects = allSubjects.filter((s) => s.assignedUsers?.includes(u.uid));
        if (subjects.length > 0) {
          if (reportType === ReportTypes.PapperMarkerReport) {
            return u.userRole?.isTeachingAssistant && u.active;
          } else {
            return u.userRole?.isInstructor && u.active;
          }
        } else {
          return false;
        }
      })
      .sort((a, b) =>
        (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName)
      );

    setInstructors(instructorsTemp);
  }, [allAdminsAndInstructors, reportType, allSubjects]);

  useEffect(() => {
    if (selectedInstructor !== 'none' && selectedInstructor?.uid) {
      const subjects = allSubjects.filter(
        (s) =>
          s.assignedUsers?.includes(selectedInstructor?.uid) ||
          s.createdBy === selectedInstructor.uid
      );
      setSubjects([...subjects]);
      const grades = subjects
        .map((s) => ({ subjectId: s.id, grade: s.grade }))
        .filter(function (value, index, self) {
          return index === self.findIndex((t) => t.grade === value.grade);
        });
      console.log('grades:', grades);
      setGrades(grades);
    }
  }, [allSubjects, selectedInstructor]);

  const validateData = () => {
    const v1 = reportType !== undefined;
    const v2 = !(selectedInstructor === undefined || selectedInstructor === 'none');
    const v3 = !(selectedGrade === undefined || selectedGrade === 'none');
    const v4 = !(selectedSubject === undefined || selectedSubject === 'none');
    const v8 = !(timeframe === undefined || timeframe === 'none');
    let v9 = true;
    let v10 = true;
    let v11 = true;
    if (v8 && timeframe === 'custom') {
      console.log(startDate, endDate);
      v9 = startDate !== undefined;
      v10 = endDate !== undefined;
      if (v9 && v10) {
        console.log(startDate?.getTime() || 0, endDate?.getTime() || 0);
        if ((startDate?.getTime() || 0) >= (endDate?.getTime() || 0)) {
          v11 = false;
        }
      }
    }
    setErrors({
      reportTypeError: v1 ? '' : 'Select a report type',
      instructorError: v2 ? '' : 'Invalid value',
      subjectError: v4 ? '' : 'Select a subject',
      gradeError: v3 ? '' : 'Select a grade',
      timeFrameError: v8 ? '' : 'Select a timeframe',
      startDateError: v9 && v11 ? '' : 'Select a valid start date',
      endDateError: v10 && v11 ? '' : 'Select a valid end date',
    });
    if (v8 && timeframe === 'custom') {
      console.log(v1, v2, v3, v4, v8, v9, v10, v11);
      return v1 && v2 && v3 && v4 && v8 && v9 && v10 && v11;
    } else {
      return v1 && v2 && v3 && v4 && v8;
    }
  };

  const getLectureReportData = async () => {
    const query = store
      .collection('exams')
      .where('subjectId', '==', (selectedSubject as Subject)?.id);
    const snap = await query.get();
    const exams: Exam[] = [];
    snap.forEach((doc) => {
      exams.push(doc.data() as any);
    });

    const getData = () => {
      if (timeframe === 'thisMonth') {
        return exams
          .filter((ex) => {
            return ex.createdAt?.toDate().getMonth() === new Date().getMonth();
          })
          .map((e) => ({
            date: moment(e.createdAt?.toDate()).format('MM/DD/YYYY'),
            examName: e.examTitle,
            examType: e.examType + '',
            noOfStudents: (e.completedUsers?.length || 0) + '',
            price: +e.price ? `Rs. ${e.price}` : '',
            papers: e.papers.map((p) => p.paperTitle).join(','),
          }));
      } else if (timeframe === 'custom') {
        return exams
          .filter((ex) => {
            return (
              (ex.createdAt?.toDate().getTime() || 0) >= (startDate?.getTime() || 0) &&
              (ex.createdAt?.toDate().getTime() || 0) <= (endDate?.getTime() || 0)
            );
          })
          .map((e) => ({
            date: moment(e.createdAt?.toDate()).format('MM/DD/YYYY'),
            examName: e.examTitle,
            examType: e.examType + '',
            noOfStudents: (e.completedUsers?.length || 0) + '',
            price: +e.price ? `Rs. ${e.price}` : '',
            papers: e.papers.map((p) => p.paperTitle).join(','),
          }));
      } else {
        return exams.map((e) => ({
          date: moment(e.createdAt?.toDate()).format('MM/DD/YYYY'),
          examName: e.examTitle,
          examType: e.examType + '',
          noOfStudents: (e.completedUsers?.length || 0) + '',
          price: +e.price ? `Rs. ${e.price}` : '',
          papers: e.papers.map((p) => p.paperTitle).join(','),
        }));
      }
    };

    const getTimeFrame = () => {
      switch (timeframe) {
        case 'custom':
          return `${moment(startDate).format('MM/DD/YYYY')} to ${moment(endDate).format(
            'MM/DD/YYYY'
          )}`;
        case 'thisMonth':
          return moment(new Date()).format('MMMM');
        default:
          return 'All';
      }
    };

    const reportData: LectureReport = {
      assistantName:
        (selectedInstructor as UserFirestore)?.firstName +
        ' ' +
        (selectedInstructor as UserFirestore)?.lastName,
      subject: (selectedSubject as Subject)?.name || '',
      timeFrame: getTimeFrame(),
      gradeAndYear:
        (selectedGrade as { subjectId?: string; grade: string }).grade +
          ' ' +
          (selectedSubject as Subject)?.year || '',
      data: getData(),
    };
    return reportData;
  };
  const getPapperMarkingReportData = async () => {
    //get all completed exams too
    const query1 = store
      .collection('completedExams')
      .where('subjectId', '==', (selectedSubject as Subject)?.id);
    const query2 = store
      .collection('exams')
      .where('subjectId', '==', (selectedSubject as Subject)?.id);
    const snap1 = await query1.get();
    const snap2 = await query2.get();
    const exams: Exam[] = [];
    const completedExams: Exam[] = [];
    snap1.forEach((doc) => {
      completedExams.push({ ...(doc.data() as any), id: doc.id });
    });
    snap2.forEach((doc) => {
      exams.push({ ...(doc.data() as any), id: doc.id });
    });

    const getData = () => {
      if (timeframe === 'thisMonth') {
        return exams
          .filter((ex) => {
            console.log(ex.createdAt?.toDate().getMonth(), new Date().getMonth());
            return ex.createdAt?.toDate().getMonth() === new Date().getMonth();
          })
          .map(async (e) => {
            const exData = await getExamData(e);
            return {
              date: moment(e.createdAt?.toDate()).format('MM/DD/YYYY'),
              asignedExam: e.examTitle,
              examType: e.examType + '',
              papers: getPapers(exData) || '',
              noOfStudents: getNumberOfStudentAssign(e),
              approvedPappers: getApprovedPappers(exData),
              totalPayments: getTotalPayments(exData),
            };
          });
      } else if (timeframe === 'custom') {
        return exams
          .filter((ex) => {
            console.log((ex.createdAt?.seconds || 0) * 1000, startDate?.getTime() || 0);
            console.log((ex.createdAt?.seconds || 0) * 1000, endDate?.getTime() || 0);
            return (
              (ex.createdAt?.seconds || 0) * 1000 >= (startDate?.getTime() || 0) &&
              (ex.createdAt?.seconds || 0) * 1000 <= (endDate?.getTime() || 0)
            );
          })
          .map(async (e) => {
            const exData = await getExamData(e);
            return {
              date: moment(e.createdAt?.toDate()).format('MM/DD/YYYY'),
              asignedExam: e.examTitle,
              examType: e.examType + '',
              papers: getPapers(exData) || '',
              noOfStudents: getNumberOfStudentAssign(e),
              approvedPappers: getApprovedPappers(exData),
              totalPayments: getTotalPayments(exData),
            };
          });
      } else {
        return exams.map(async (e) => {
          //Papers
          //exam studentTainfor[] find pappers assign to TA
          //--------------------
          //approvedPappers
          ///studentTainfor[] find pappers assign to TA
          /// complete exam . marked aswersheet url
          /// then check the status
          //--------------------
          //totalPayments
          //complleted exams taPayment isCompleted taPayment sum
          const exData = await getExamData(e);
          return {
            date: moment(e.createdAt?.toDate()).format('MM/DD/YYYY'),
            asignedExam: e.examTitle,
            examType: e.examType + '',
            papers: getPapers(exData) || '',
            noOfStudents: getNumberOfStudentAssign(e),
            approvedPappers: getApprovedPappers(exData),
            totalPayments: getTotalPayments(exData),
          };
        });
      }
    };

    const getExamData = async (exam: Exam) => {
      return getPaymentData({
        examId: exam.id,
        taUid: (selectedInstructor as UserFirestore).uid,
      })
        .then((result) => {
          const data = (result.data as any).data as ITaPaymentInfo[];
          return data;
        })
        .catch((error) => {
          return undefined;
        });
    };

    const getNumberOfStudentAssign = (exam: Exam) => {
      let totalPapers = 0;
      let taUidsArray: string[] = [];

      exam.studentTAAssignmentInfo?.forEach((v) => {
        totalPapers = totalPapers + v.papers.length;
        v.papers?.forEach((v) => {
          taUidsArray.push(v.assignedTAUid?.taUid || '');
        });
      });

      const count = taUidsArray.filter(
        (v) => v === (selectedInstructor as UserFirestore)?.uid
      ).length;
      return count + '';
    };
    const getPapers = (examData?: ITaPaymentInfo[]) => {
      return examData
        ?.filter((d) => d.totalPapers > 0)
        .map((d) => d.paperTitle)
        .join(',');
    };
    const getApprovedPappers = (examData?: ITaPaymentInfo[]) => {
      return examData?.map((d) => d.totalAproved).reduce((a, b) => a + b, 0) + '';
    };
    const getTotalPayments = (examData?: ITaPaymentInfo[]) => {
      return examData?.map((d) => d.totalPayment).reduce((a, b) => a + b, 0) + '';
    };

    const getTimeFrame = () => {
      switch (timeframe) {
        case 'custom':
          return `${moment(startDate).format('MM/DD/YYYY')} to ${moment(endDate).format(
            'MM/DD/YYYY'
          )}`;
        case 'thisMonth':
          return moment(new Date()).format('MMMM');
        default:
          return 'All';
      }
    };

    const reportData: PapperMarkingReport = {
      assistantName:
        (selectedInstructor as UserFirestore)?.firstName +
        ' ' +
        (selectedInstructor as UserFirestore)?.lastName,
      subject: (selectedSubject as Subject)?.name || '',
      timeFrame: getTimeFrame(),
      gradeAndYear:
        (selectedGrade as { subjectId?: string; grade: string })?.grade +
          ' ' +
          (selectedSubject as Subject)?.year || '',
      data: await Promise.all(getData()),
    };
    return reportData;
  };

  const getSubjects = useMemo(() => {
    const x = subjects
      ?.filter((s) => s.grade === (selectedGrade as { subjectId?: string; grade: string })?.grade)
      ?.map((i) => <option value={i.id}>{i.name}</option>);
    if (x) {
      return <>{x}</>;
    } else {
      <></>;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGrade]);
  return (
    <div className="reaport-wrapper">
      {loading && <LoadingIndicator />}
      <div className="rw-title">Reports</div>
      <div>
        <div className="s2">
          <div className="cn-label">
            Report Type <span style={{ color: '#F05A5A' }}>*</span>
          </div>

          <select
            className="cn-input"
            value={reportType}
            onChange={(e) => {
              setReportType(e.target.value as ReportTypes);
              setSelectedInstructor('none');
              setSelectedGrade('none');
              setSelectedSubject('none');
              setTimeframe('none');
              setStartDate(undefined);
              setEndDate(undefined);
              setErrors((ps) => {
                return { ...ps, reportTypeError: '' };
              });
            }}
            style={{
              fontSize: '16px',
            }}
          >
            <option disabled selected>
              Select Report type
            </option>
            <option value={ReportTypes.LectureReport}>Lecturer Report</option>
            <option value={ReportTypes.PapperMarkerReport}>Paper Marker Report</option>
          </select>
          <div>
            <label className="error-message">{errors.reportTypeError}</label>
          </div>
        </div>
        <div className="s2">
          <div className="cn-label">
            {reportType === ReportTypes.PapperMarkerReport ? 'Teaching Assistant' : 'Instructor'}{' '}
            <span style={{ color: '#F05A5A' }}>*</span>
          </div>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isDisabled={!reportType}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#246bfd',
                primary: '#246bfd',
                neutral0: '#181924',
                neutral60: 'white',
                neutral80: 'white',
              },
            })}
            isClearable={true}
            value={
              selectedInstructor && selectedInstructor === 'none'
                ? { value: '', label: 'Select' }
                : {
                    value: selectedInstructor?.uid,
                    label:
                      (selectedInstructor?.firstName || 'Select') +
                      ' ' +
                      (selectedInstructor?.lastName || ''),
                  }
            }
            isSearchable={true}
            name="color"
            onChange={(e) => {
              const [si] = instructors.filter((i) => i.uid === e?.value || '');
              console.log(e, si);
              if (si) {
                setSelectedInstructor({ ...si });
                setSelectedGrade('none');
                setSelectedSubject('none');
                setTimeframe('none');
                setStartDate(undefined);
                setEndDate(undefined);
                setErrors((ps) => {
                  return {
                    ...ps,
                    instructorError: '',
                  };
                });
              }
            }}
            options={instructors.map((i) => {
              return { value: i.uid, label: i.firstName + ' ' + i.lastName };
            })}
          />
          {/* <select
            disabled={!reportType}
            className="cn-input"
            style={{
              fontSize: '16px',
            }}
            value={selectedInstructor === 'none' ? selectedInstructor : selectedInstructor?.uid}
            onChange={(e) => {
              const [si] = instructors.filter((i) => i.uid === e.target.value);
              if (si) {
                setSelectedInstructor({...si});
                setSelectedGrade('none');
                setSelectedSubject('none');
                setTimeframe('none');
                setErrors((ps) => {
                  return {
                    ...ps,
                    instructorError: '',
                    subjectError: '',
                    gradeError: '',
                    timeFrameError: '',
                    startDateError: '',
                    endDateError: '',
                  };
                });
              }
            }}
          >
            <option disabled selected value={'none'}>
              {reportType === ReportTypes.PapperMarkerReport
                ? 'Select Teaching Assistant'
                : 'Select Instructor'}{' '}
            </option>
            {instructors.map((i) => (
              <option value={i.uid}>{i.firstName + ' ' + i.lastName}</option>
            ))}
          </select> */}
          <div>
            <label className="error-message">{errors.instructorError}</label>
          </div>
        </div>
        <div className="grade-subject">
          <div className="s3" style={{ paddingRight: '10px' }}>
            <div
              className="cn-label"
              style={{
                color: '#979797',
              }}
            >
              Grade <span style={{ color: '#F05A5A' }}>*</span>
            </div>

            <select
              disabled={!selectedInstructor}
              className="cn-input"
              style={{
                fontSize: '16px',
              }}
              value={selectedGrade === 'none' ? selectedGrade : selectedGrade?.grade}
              onChange={(e) => {
                const [grade] = grades.filter((g) => g.grade === e.target.value);
                if (grade) {
                  setSelectedGrade(grade);
                  setStartDate(undefined);
                  setEndDate(undefined);
                  setErrors((ps) => {
                    return {
                      ...ps,
                      gradeError: '',
                    };
                  });
                }
              }}
            >
              <option value={'none'} disabled>
                Select Grade
              </option>
              {grades
                .sort((a, b) => {
                  if (gradesToNumbers(a.grade) > gradesToNumbers(b.grade)) {
                    return 1;
                  } else if (gradesToNumbers(a.grade) < gradesToNumbers(b.grade)) {
                    return -1;
                  }
                  return 0;
                })
                .map((i) => {
                  return <option value={i.grade}>{i.grade}</option>;
                })}
            </select>
            <div>
              <label className="error-message">{errors.gradeError}</label>
            </div>
          </div>
          <div className="s3">
            <div
              className="cn-label"
              style={{
                color: '#979797',
              }}
            >
              Subject <span style={{ color: '#F05A5A' }}>*</span>
            </div>

            <select
              disabled={!selectedGrade}
              style={{
                fontSize: '16px',
              }}
              className="cn-input"
              value={selectedSubject === 'none' ? selectedSubject : selectedSubject?.id}
              onChange={(e) => {
                const [s] = subjects?.filter((s) => s.id === e.target.value) || [];
                if (s) {
                  setSelectedSubject(s);
                  setStartDate(undefined);
                  setEndDate(undefined);
                  setErrors((ps) => {
                    return {
                      ...ps,
                      subjectError: '',
                    };
                  });
                }
              }}
            >
              <option value={'none'} disabled selected>
                Select Subject
              </option>
              {getSubjects}
            </select>
            <div>
              <label className="error-message">{errors.subjectError}</label>
            </div>
          </div>
        </div>
        <div className="timeframes">
          <div className="s4" style={{ paddingRight: '10px' }}>
            <div
              className="cn-label"
              style={{
                color: '#979797',
              }}
              onClick={() => {
                console.log(
                  reportType === undefined,
                  selectedInstructor === (undefined || 'none'),
                  selectedGrade === (undefined || 'none'),
                  selectedSubject === (undefined || 'none')
                );
              }}
            >
              Timeframe <span style={{ color: '#F05A5A' }}>*</span>
            </div>

            <select
              disabled={
                reportType === undefined ||
                selectedInstructor === (undefined || 'none') ||
                selectedGrade === (undefined || 'none') ||
                selectedSubject === (undefined || 'none')
              }
              className="cn-input"
              style={{ marginTop: '6px', fontSize: '16px' }}
              value={timeframe}
              onChange={(e) => {
                setTimeframe(e.target.value as any);
                setErrors((ps) => {
                  return {
                    ...ps,
                    timeFrameError: '',
                  };
                });
              }}
            >
              <option value="none" disabled selected>
                Select Timeframe
              </option>
              <option value="all">All</option>
              <option value="thisMonth">This Month</option>
              <option value="custom">Custom</option>
            </select>
            <div>
              <label className="error-message">{errors.timeFrameError}</label>
            </div>
          </div>
          <div
            className="s4"
            style={{ paddingRight: '10px', display: timeframe === 'custom' ? 'block' : 'none' }}
          >
            <div className="cn-label">
              Start Date <span style={{ color: '#F05A5A' }}>*</span>
            </div>

            <input
              disabled={timeframe !== 'custom'}
              type="date"
              min={moment((selectedInstructor as UserFirestore)?.createdAt?.toDate()).format(
                'YYYY-MM-DD'
              )}
              max={moment(new Date()).format('YYYY-MM-DD')}
              style={{
                fontSize: '16px',
              }}
              className="cn-input"
              value={startDate ? moment(startDate).format('yyyy-MM-DD') : ''}
              onChange={(e) => {
                const date = new Date(e.target.value);
                date.setHours(0, 0, 0, 0);
                setStartDate(date);
                setErrors((ps) => {
                  return {
                    ...ps,
                    startDateError: '',
                  };
                });
              }}
            />

            <div>
              <label className="error-message">{errors.startDateError}</label>
            </div>
          </div>
          <div className="s4" style={{ display: timeframe === 'custom' ? 'block' : 'none' }}>
            <div className="cn-label">
              End Date <span style={{ color: '#F05A5A' }}>*</span>
            </div>

            <input
              disabled={timeframe !== 'custom'}
              type="date"
              min={moment((selectedInstructor as UserFirestore)?.createdAt?.toDate()).format(
                'YYYY-MM-DD'
              )}
              max={moment(new Date()).format('YYYY-MM-DD')}
              style={{
                fontSize: '16px',
              }}
              className="cn-input"
              value={endDate ? moment(endDate).format('yyyy-MM-DD') : ''}
              onChange={(e) => {
                const date = new Date(e.target.value);
                date.setHours(23, 59, 59, 999);
                setEndDate(date);
                setErrors((ps) => {
                  return {
                    ...ps,
                    endDateError: '',
                  };
                });
              }}
            />
            <div>
              <label className="error-message">{errors.endDateError}</label>
            </div>
          </div>
        </div>
        <div>
          <button
            className={loading ? 'f-button-d' : 'f-button'}
            disabled={loading}
            onClick={async (e) => {
              if (validateData()) {
                if (reportType === ReportTypes.LectureReport) {
                  setLoading(true);
                  try {
                    const data = await getLectureReportData();
                    ExcelHelper.getLectureReport(
                      e,
                      'xlsx',
                      timeframe === 'All'
                        ? 'All Data Report'
                        : timeframe === 'thisMonth'
                        ? 'Monthly Report'
                        : 'Custom Data Report',
                      data
                    );
                    Notification({
                      isSuccess: true,
                      message: 'Report was successfully generated',
                    });
                  } catch (e) {
                    console.log(e);
                    Notification({
                      isSuccess: false,
                      message: `Error in generating the report! Please try again later`,
                    });
                  } finally {
                    setLoading(false);
                  }
                } else if (reportType === ReportTypes.PapperMarkerReport) {
                  setLoading(true);
                  getPapperMarkingReportData()
                    .then((data) => {
                      const realData = data.data.filter((d) => d.papers.trim().length > 0);
                      console.log(realData);
                      if (realData.length > 0) {
                        ExcelHelper.getPaperMarkingReport(
                          e,
                          'xlsx',
                          timeframe === 'All'
                            ? 'All Data Report'
                            : timeframe === 'thisMonth'
                            ? 'Monthly Report'
                            : 'Custom Data Report',
                          { ...data, data: realData }
                        );
                        Notification({
                          isSuccess: true,
                          message: 'Report was successfully generated',
                        });
                      } else {
                        Notification({
                          isSuccess: false,
                          errorHeader: 'No data',
                          message: 'No data available for this report',
                          id: 'tid',
                        });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      Notification({
                        isSuccess: false,
                        errorHeader: 'Error',
                        message: 'Something went wrong while generating the report',
                        id: 'tid',
                      });
                    })
                    .finally(() => setLoading(false));
                }
              }
            }}
          >
            {loading ? 'Generating...' : 'Generate Report'}
            {!loading && (
              <span className="ml-3">
                <FaDownload style={{ fontSize: '14px', transform: 'translateY(-2px)' }} />
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Report;
