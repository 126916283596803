import firebase from 'firebase';
import React, {FC, useEffect, useState} from 'react';
import {Col, Container, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {FaArchive, FaEdit, FaTrashAlt} from 'react-icons/fa';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import {useQuestions} from '../context/QuestionsContext';
import {useAppUser} from '../context/UserContext';
import {QuestionData} from '../interfaces';
import {MCQ, TFQuestion} from '../models/Question';
import './PreviewHeaderActionBar.scss';
import Notification from './../components/Notification';
import BoxIcon from './BoxIcon';
import Backarrow from './Backarrow';
import truncate from 'truncate';
import LoadingIndicator from './LoadingIndicator';
import AlertPopup from './AlertPopup';
interface Props {
  className?: string;
  onAction: (
    value: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT',
    questionData: QuestionData | null
  ) => void;
  question: MCQ | TFQuestion | null;
  style?: React.CSSProperties;
}

const PreviewHeaderActionBar: FC<Props> = (props) => {
  const {firestoreUser} = useAppUser();
  const appQuestions = useQuestions();
  const appAdminsAndInstructors = useAdminsAndInstructors();
  const [state, setState] = useState({lesson: '', subject: '', unit: '', loading: false});
  const [show, setShow] = useState(false);

  const showHandler = () => {
    setShow(true);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const qeleteQuestionRef = firebase.functions().httpsCallable('qeleteQuestion');

  const setLoading = (val: boolean) => {
    setState((ps) => {
      return {...ps, loading: val};
    });
  };

  const onQuestionDelete = async (questionid: string) => {
    setLoading(true);
    try {
      await qeleteQuestionRef({
        qid: questionid,
      });

      appQuestions.fetchQtns();

      props.onAction('VIEW', null);
      Notification({
        isSuccess: true,
        message: 'The question deleted successfully',
      });
      setLoading(false);
    } catch (e) {
      Notification({
        isSuccess: false,
        message: 'Failed to delete question',
      });
      props.onAction('VIEW', null);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (props.question?.subjectId) {
      const docRef = firebase.firestore().collection('subjects').doc(props?.question?.subjectId);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setState((ps) => {
              return {...ps, subject: doc.data()?.name};
            });
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error getting document:', error);
          setLoading(false);
        });
    }
  }, [props.question?.subjectId]);

  useEffect(() => {
    setLoading(true);
    if (props.question?.lessonId) {
      const docRef = firebase.firestore().collection('lessons').doc(props?.question?.lessonId);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setState((ps) => {
              return {...ps, lesson: doc.data()?.name};
            });
          } else {
            // doc.data() will be undefined in this case
            setLoading(false);
            console.log('No such document!');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error getting document:', error);
          setLoading(false);
        });
    }
  }, [props.question?.lessonId]);

  useEffect(() => {
    setLoading(true);
    if (props?.question?.unitId) {
      const docRef = firebase.firestore().collection('units').doc(props?.question?.unitId);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setState((ps) => {
              return {...ps, unit: doc.data()?.name};
            });
          } else {
            // doc.data() will be undefined in this case
            setLoading(false);
            console.log('No such document!');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error getting document:', error);
          setLoading(false);
        });
    }
  }, [props.question?.unitId]);

  let action = (
    <Col className="d-flex" xs={2} style={{justifyContent: 'flex-end'}}>
      <BoxIcon
        icon={FaArchive}
        color="#fff"
        size={13}
        style={{width: 30, height: 30, backgroundColor: '#404462'}}
        className="ml-auto"
      />
    </Col>
  );

  if (firestoreUser?.userRole?.isAdmin || firestoreUser?.uid === props?.question?.createdBy) {
    action = (
      <Col
        className="d-flex"
        xs={2}
        style={{justifyContent: 'flex-end', width: '100%', marginLeft: 'auto'}}
      >
        <BoxIcon
          icon={FaArchive}
          color="#fff"
          size={13}
          style={{width: 30, height: 30, backgroundColor: '#404462', marginLeft: '12%'}}
        />

        <BoxIcon
          icon={FaEdit}
          color="#fff"
          size={13}
          style={{width: 30, height: 30, backgroundColor: '#404462', marginLeft: '12%'}}
          onClick={() => {
            props.onAction('EDIT', {
              questionId: props?.question?.id || '',
              subjectId: props?.question?.subjectId || '',
              lessonId: props?.question?.lessonId || '',
              unitId: props?.question?.unitId || '',
              grade: props?.question?.grade || '',
              createdBy: props?.question?.createdBy || '',
              labelIds: props.question?.labels || [],
            });
          }}
        />

        <BoxIcon
          icon={FaTrashAlt}
          color="#fff"
          size={13}
          style={{width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: '12%'}}
          onClick={() => {
            showHandler();
          }}
        />
      </Col>
    );
  }

  const authorName =
    appAdminsAndInstructors.allAdminsAndInstructors.find(
      (user) => user.uid === props.question?.createdBy
    )?.username || '';

  return (
    <Container
      fluid
      className={`px-0 ${props.className} preview-header`}
      style={{ ...props.style }}
    >
      <Row>
        <Col className="d-flex" xs={2} style={{ overflow: 'hidden' }}>
          <Backarrow
            onClick={() => {
              props.onAction('VIEW', null);
            }}
          />
          <div className="pl-4 ml-3">
            <div className="preview-header-ab-t">Grade</div>
            <div className="preview-header-ab-b">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="verifiedId">{props.question?.grade}</Tooltip>}
              >
                <div className="text-white">{truncate(props.question?.grade || '', 35)}</div>
              </OverlayTrigger>
            </div>
          </div>
        </Col>
        <Col xs={2} style={{ overflow: 'hidden' }}>
          <div className="preview-header-ab-t">Subject</div>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="verifiedId">{state.subject}</Tooltip>}
          >
            <div className="text-white">{truncate(state.subject || '', 35)}</div>
          </OverlayTrigger>
        </Col>

        <Col xs={2} style={{ overflow: 'hidden' }}>
          <div className="preview-header-ab-t">Lesson</div>
          <div className="preview-header-ab-b">
            {state.lesson ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="verifiedId">{state.lesson}</Tooltip>}
              >
                <div>{truncate(state.lesson || '', 35)}</div>
              </OverlayTrigger>
            ) : (
              <div style={{ width: 40, textAlign: 'center' }}>-</div>
            )}
          </div>
        </Col>

        {state.unit !== '' && (
          <Col xs={2} style={{ overflow: 'hidden' }}>
            <div className="preview-header-ab-t">Unit</div>
            <div className="preview-header-ab-b">
              {state.lesson ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="verifiedId">{state.unit}</Tooltip>}
                >
                  <div>{truncate(state.unit || '', 35)}</div>
                </OverlayTrigger>
              ) : (
                <div style={{ width: 40, textAlign: 'center' }}>-</div>
              )}
            </div>
          </Col>
        )}
        <Col xs={2} style={{ overflow: 'hidden' }}>
          <div className="preview-header-ab-t">Author</div>
          <div className="preview-header-ab-b">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="verifiedId">{authorName}</Tooltip>}
            >
              <div>{truncate(authorName || '', 20)}</div>
            </OverlayTrigger>
          </div>
        </Col>
        {action}
      </Row>
      {state.loading && (
        <Row>
          <Col>
            <LoadingIndicator />
          </Col>
        </Row>
      )}

      <AlertPopup
        message="Are you sure you want to delete this question?"
        header="Attention"
        isShow={show}
        primaryButtonText="Confirm"
        onOk={() => {
          onQuestionDelete(props?.question?.id || '');
        }}
        onClose={closeHandler}
      />
    </Container>
  );
};

export default PreviewHeaderActionBar;
