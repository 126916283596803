import React from 'react';
import Modal from 'react-modal';
import {FaWindowClose} from 'react-icons/fa';
import './PaperPreviewModel.scss';
import QuestionResult from './QuestionResult';
import StructuredEssayPDFPreview from './StructuredEssayPDFPreview';
import {PaperTypes} from '../enums/paperTypes';

const PaperPreview = (props: {paper: any}) => {
  if (
    props.paper.paperType === PaperTypes.ESSAY ||
    props.paper.paperType === PaperTypes.STRUCTURED
  ) {
    return (
      <div className="paper-preview">
        <div>
          <StructuredEssayPDFPreview
            paperName={props.paper.paperName}
            paperURL={props.paper.paperUrl}
            markingSchemeName={props.paper.markingSchemeName}
            markingSchemeURL={props.paper.markingSchemeUrl}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="paper-preview">
        {props.paper.questions?.map((q: any, idx: any) => {
          return <QuestionResult key={idx} idx={idx} question={q} hideSelectedAnswer={true} />;
        })}
      </div>
    );
  }
};

const PaperPreviewModel = (props: {
  paper: any;
  isShow: boolean;
  setIsShow: (show: boolean) => void;
}) => {
  const getPaperType = (type?: PaperTypes) => {
    switch (type) {
      case PaperTypes.MCQ:
        return 'MCQ';
      case PaperTypes.STRUCTURED:
        return 'STR';
      case PaperTypes.ESSAY:
        return 'ESSAY';
      case PaperTypes.TRUEFALSE:
        return 'T/F';
      default:
        return '';
    }
  };
  return (
    <Modal
      isOpen={props.isShow}
      contentLabel="Example Modal"
      className="paper-preview-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="paper-preview-modal__header">
        <div className="paper-preview-modal__title">
          <div className="paper-preview-modal__title--type">
            <span>{getPaperType(props.paper.paperType)}</span>
          </div>
          <div className="paper-preview-modal__title--text">{props.paper.paperTitle}</div>
        </div>
        <FaWindowClose
          color="#FF4C6C"
          onClick={() => {
            props.setIsShow(false);
          }}
          size={25}
          style={{cursor: 'pointer'}}
        />
      </div>
      <div className="paper-preview-modal__content">
        <PaperPreview paper={props.paper} />
      </div>
    </Modal>
  );
};
export default PaperPreviewModel;
