import React, {FC} from 'react';
import {FaChevronRight} from 'react-icons/fa';
import NumberBox from './NumberBox';

import './PaperExamBreadCrum.scss';
import {PACKAGE_BREADCRUMB_STEPS} from '../enums/packageBreadCrum';
import {PAPER_BREADCRUM_STEPS} from '../enums/PaperBreadCrum';

interface Props {
  active: PACKAGE_BREADCRUMB_STEPS | PAPER_BREADCRUM_STEPS;
  stepOneName: string;
  stepTwoName: string;
  stepThreeName: string;
}

const PaperExamBreadCrum: FC<Props> = (props) => {
  return (
    <div className="paper-exam-breadcrum">
      <div className="paper-exam-breadcrum__general">
        <NumberBox
          number={1}
          style={
            props.active === PACKAGE_BREADCRUMB_STEPS.STEP_ONE || props.active === PAPER_BREADCRUM_STEPS.STEP_ONE
              ? {backgroundColor: '#246bfd', color: '#fff'}
              : {}
          }
        />
        <div className="paper-exam-breadcrum__general-text">{props.stepOneName}</div>
        <FaChevronRight size={12} color="#979797" style={{marginRight: 12}} />
      </div>
      <div className="paper-exam-breadcrum__creation">
        <NumberBox
          number={2}
          style={
            props.active === PACKAGE_BREADCRUMB_STEPS.STEP_TWO || props.active === PAPER_BREADCRUM_STEPS.STEP_TWO
              ? {backgroundColor: '#246bfd', color: '#fff'}
              : {}
          }
        />
        <div className="paper-exam-breadcrum__creation-text">{props.stepTwoName}</div>
        <FaChevronRight size={12} color="#979797" style={{marginRight: 12}} />
      </div>
      <div className="paper-exam-breadcrum__paper-exam">
        <NumberBox
          number={3}
          style={
            props.active === PACKAGE_BREADCRUMB_STEPS.STEP_THREE || props.active === PAPER_BREADCRUM_STEPS.STEP_THREE
              ? {backgroundColor: '#246bfd', color: '#fff'}
              : {}
          }
        />
        <div className="paper-exam-breadcrum__paper-exam-text">{props.stepThreeName}</div>
      </div>
    </div>
  );
};

export default PaperExamBreadCrum;
