import React, {FC} from 'react';
import {useMobileNav} from '../context/MobNavContext';
import {useAppUser} from '../context/UserContext';

import './AccountHeader.scss';
import BreadCrum from './BreadCrum';
import HamburgerIcon from './HamburgerIcon';

interface Props {
  restrictions?: {value: boolean; onClick: Function};
}

const AccountHeader: FC<Props> = (props) => {
  const appUser = useAppUser();
  const appMobileNav = useMobileNav();

  let userRoleText = 'Student';

  if (appUser.firestoreUser?.userRole?.isAdmin) {
    userRoleText = 'Admin';
  } else if (appUser.firestoreUser?.userRole?.isTeachingAssistant) {
    userRoleText = 'Teaching Assistant';
  }

  return (
    <div className="d-sm-flex justify-content-between align-items-center acc-header-wrapper">
      <div className="acc-header d-none d-md-block">
        <BreadCrum className="d-none d-sm-flex" restrictions={props.restrictions} />
      </div>
      <div className="d-md-none mb-3 d-flex justify-content-between w-100">
        <div>
          <div style={{lineHeight: 1, fontWeight: 600}} className="text-white">
            My Account
          </div>
          <div className="text-white mt-1 text-left" style={{lineHeight: 1, fontSize: 14}}>
            {userRoleText}
          </div>
         
        </div>
        <HamburgerIcon
          onClick={() => {
            appMobileNav.setOpenMobileNav(!appMobileNav.openMobileNav);
          }}
        />
      </div>
    </div>
  );
};

export default AccountHeader;
