import React from 'react';
import {ExamToDoDTO} from '../dtos/ExamToDoDTO';
import {ExamType} from '../enums/ExamType';
import StudentExtraExamCard from './StudentExtraExamCard';
import './StudentExtraExamView.scss';
import {useAppUser} from '../context/UserContext';

const StudentCurrentExtraExamFilter = (props: {
  examsList: any[];
  isLoading: boolean;
  setSelectedExamsHandlers: (exam: ExamToDoDTO | null) => void;
  filterValue: string;
}) => {
  const appUser = useAppUser();

  return (
    <div className="tab-stu-extra-exam__current-cards margin-top-1rem">
      {props.examsList
        .filter(
          (ex) =>
            !ex.completedUsers?.includes(appUser.fireUser?.uid || '') &&
            ex.examType === ExamType.EXTRA_EXAM
        )
        .filter((text, index, array) => {
          if (
            props.filterValue === '' ||
            props.filterValue === null ||
            props.filterValue === undefined
          ) {
            return array;
          } else {
            return text.examTitle
              .toLowerCase()
              .trim()
              .includes(props.filterValue.toLowerCase().trim());
          }
        })
        .map((exam: ExamToDoDTO, idx: React.Key | null | undefined) => {
          return (
            <StudentExtraExamCard
              exam={exam}
              isLoading={props.isLoading}
              setSelectedExamsHandlers={props.setSelectedExamsHandlers}
              notCompleted
              key={idx}
            />
          );
        })}
    </div>
  );
};

export default StudentCurrentExtraExamFilter;
