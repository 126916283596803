import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Backarrow from '../../../components/Backarrow';
import { UNIT_SECONDARY_NAV_MAIN_ITEMS } from '../../../constants';
import { useLessons } from '../../../context/LessonsContext';
import { useSubjects } from '../../../context/SubjectsContext';
import { useUnits } from '../../../context/UnitsContext';
import { useAppUser } from '../../../context/UserContext';
import { isValidLessonForUser, isValidSubjectForUser } from '../../../utils';

interface Props {
  subjectId: string;
  lessonId: string;
  unitId: string;
}

const AdminContentSecondaryNav: FC<Props> = (props) => {
  const { CONTENT, MODAL_PAPERS } = UNIT_SECONDARY_NAV_MAIN_ITEMS;
  const navItems = [CONTENT, MODAL_PAPERS];

  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUnits = useUnits();
  const user = useAppUser().firestoreUser;
  const history = useHistory();

  useEffect(() => {
    const isValidSubject = isValidSubjectForUser({
      user: user,
      subjectId: props.subjectId,
      subjects: appSubjects.allSubjects,
    });

    const { isValidLesson } = isValidLessonForUser({
      lessonId: props.lessonId,
      lessons: appLessons.lessons,
      subjectId: props.subjectId,
    });

    if (
      !isValidSubject &&
      !isValidLesson &&
      appSubjects.allSubjects.length &&
      appLessons.lessons.length
    ) {
      history.push('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSubjects.allSubjects, appLessons.lessons]);

  const localBaseUrl = `my-subjects/subject/${props.subjectId}/lesson/${props.lessonId}/unit/${props.unitId}`;
  const lastSegment = decodeURI(history.location.pathname.split('/').pop() || '');

  const unitName = appUnits.units.find((u) => u.id === props.unitId)?.name;
  const lessonName = appLessons.lessons.find((l) => l.id === props.lessonId)?.name;
  const consernedLesson = appLessons.lessons.find((l) => l.id === props.lessonId);

  const examinations = history.location.pathname.split('/')[6] === 'Examinations';

  const contentUpdate =
    history.location.pathname.split('/')[9] === 'Update' &&
    history.location.pathname.split('/')[8] === 'Content';

  const contentUpdateLessonWithNoUnits =
    history.location.pathname.split('/')[6] === 'Content' &&
    history.location.pathname.split('/')[7] === 'Update';

  return (
    <aside className="unit-snav">
      <header className="unit-snav__header">
        <Backarrow
          onClick={() => {
            if (lessonName && unitName) {
              history.push(
                `/my-subjects/subject/${props.subjectId}/lesson/${props.lessonId}/My Units`
              );
            } else if (lessonName) {
              history.push(`/my-subjects/subject/${props.subjectId}/Lessons`);
            }
          }}
        />
        <h6>{unitName || lessonName}</h6>
      </header>
      <li className="unit-snav__items">
        {navItems.map((item, idx) => (
          <ul
            className={`unit-snav__item  ${lastSegment === item && 'unit-snav__item--active'} ${
              item === 'Content' && lastSegment === 'Create' && 'unit-snav__item--active'
            } ${examinations && item === navItems[1] && 'unit-snav__item--active'} ${
              contentUpdate && item === navItems[0] && 'unit-snav__item--active'
            } ${
              contentUpdateLessonWithNoUnits && item === navItems[0] && 'unit-snav__item--active'
            }`}
            onClick={() => {
              if (consernedLesson?.isLessonContainsUnits) {
                history.push(`/${localBaseUrl}/${item}`);
              } else {
                history.push(
                  `/my-subjects/subject/${props.subjectId}/lesson/${props.lessonId}/Content/${item}`
                );
              }
            }}
            key={idx}
          >
            {item}
          </ul>
        ))}
      </li>
    </aside>
  );
};

export default AdminContentSecondaryNav;
