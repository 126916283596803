import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
import { useSubjects } from '../context/SubjectsContext';
import { ExamToDoDTO } from '../dtos/ExamToDoDTO';
import { PaperDTO } from '../dtos/PaperDTO';
import { ExamType } from '../enums/ExamType';
import { PaperTypes } from '../enums/paperTypes';
import { Exam } from '../models/Exam';
import {
  rankingHandler,
  removeDecimalSection,
  sortExamsByRank,
  splitArrayIntoChunksOfLen,
} from '../utils';
import ExcelHelper from '../utils/ExcelHelper';
import GradesTabMarks from './GradesTabMarks';
import LoadingIndicator from './LoadingIndicator';
import Pagination from './Pagination';
import SearchWidget from './SearchWidget';

interface Props {
  selectedExam: Exam | null;
}

const ExamGrades: FC<Props> = (props) => {
  const params = useParams() as { subjectId: string; examId: string };

  const [state, setState] = useState<{
    searchText: string;
    page: number;
    totalPages: number;
    completedExams: ExamToDoDTO[];
    totalCompletedExams: ExamToDoDTO[];
    sortedExams: ExamToDoDTO[];
    loading: boolean;
    started: boolean;
  }>({
    searchText: '',
    page: 1,
    totalPages: 0,
    completedExams: [],
    totalCompletedExams: [],
    sortedExams: [],
    loading: false,
    started: false,
  });
  const { allAdminsAndInstructors: users } = useAdminsAndInstructors();
  const setCurrentPage = (page: number) => {
    setState((ps) => {
      return { ...ps, page: page };
    });
  };

  const [rankSorted, setRankSorted] = useState<boolean>(true);

  const getPaperStatus = (paperDTO: PaperDTO) => {
    let paperScore = '';

    if (paperDTO.paperType === PaperTypes.STRUCTURED || paperDTO.paperType === PaperTypes.ESSAY) {
      if (!paperDTO.structEssayData?.answerSheetUrl) {
        paperScore = 'NOT UPLOADED';
      } else if (!paperDTO.structEssayData?.markedAnswerSheetUrl) {
        paperScore = 'NOT MARKED';
      } else if (!paperDTO.released) {
        paperScore = 'NOT RELEASED';
      } else {
        if (props.selectedExam?.gradingType !== 'Percentage') {
          paperScore = removeDecimalSection(paperDTO.paperPoints?.toString() || '');
          paperScore = Math.round(parseInt(paperScore)).toString();
        } else {
          paperScore = removeDecimalSection(paperDTO.score?.toString() || '') + '%';
        }
      }
    } else {
      if (props.selectedExam?.gradingType !== 'Percentage') {
        paperScore = removeDecimalSection(paperDTO.paperPoints?.toString() || '');
        paperScore = Math.round(parseInt(paperScore)).toString();
      } else {
        paperScore = removeDecimalSection(paperDTO.score?.toString() || '') + '%';
      }
    }

    return paperScore;
  };

  const round = (value: number, minimumFractionDigits: number, maximumFractionDigits: number) => {
    const formattedValue = value.toLocaleString('en', {
      useGrouping: false,
      minimumFractionDigits,
      maximumFractionDigits,
    });
    return Number(formattedValue);
  };

  //Search handler
  const searchTextHandler = (text: string) => {
    setState((ps) => ({
      ...ps,
      searchText: text,
    }));
  };

  const store = useFirestore();

  const appUsers = useAdminsAndInstructors();

  const { allSubjects } = useSubjects();

  const setLoading = (val: boolean) => {
    setState((ps) => {
      return { ...ps, loading: val, started: true };
    });
  };

  useEffect(() => {
    const exams: ExamToDoDTO[] = [];
    setLoading(true);

    setState((ps) => {
      return { ...ps, started: true };
    });

    store
      .collection('completedExams')
      .where('examId', '==', params.examId)
      .get()
      .then((data) => {
        data.forEach((val) => {
          let flag = true;
          const ex = { id: val.id, ...val.data() } as ExamToDoDTO;
          ex.papers.forEach((v) => {
            if (
              (v.paperType === PaperTypes.STRUCTURED || v.paperType === PaperTypes.ESSAY) &&
              !v.structEssayData?.markedAnswerSheetUrl &&
              v.structEssayData?.answerSheetUrl &&
              !v.released
            ) {
              flag = false;
            }
          });

          if (flag) {
            exams.push({ id: val.id, ...val.data() } as ExamToDoDTO);
          }
        });
        const filteredExams = filterRelesed(exams);

        const temp = [...filteredExams];

        const isPercentage = props.selectedExam?.gradingType === 'Percentage';

        const array1 = temp.map(
          (ex) =>
            parseFloat(isPercentage ? ex.score?.toString() || '0' : ex.points?.toString() || '0') ||
            0
        );

        const rankingArray = rankingHandler(array1);

        temp.forEach((exam, i) => {
          exam.rank = rankingArray[i];
        });

        const sortedExams = sortExams(temp);

        setState((ps) => {
          return {
            ...ps,
            totalPages: splitArrayIntoChunksOfLen(sortedExams || [], 10)?.length || 0,
            completedExams: splitArrayIntoChunksOfLen(sortedExams || [], 10)[state.page - 1] || [],
            totalCompletedExams: exams,
            sortedExams: sortedExams,
            loading: false,
            started: false,
          };
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log('ERR ', err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortRank = () => {
    setState((ps) => {
      return {
        ...ps,
        completedExams:
          splitArrayIntoChunksOfLen(sortExamsByRank(state.sortedExams, rankSorted) || [], 10)[
            state.page - 1
          ] || [],
      };
    });
  };

  const sortHandler = () => {
    setRankSorted(!rankSorted);
    sortRank();
  };

  const sortExams = (exams: ExamToDoDTO[]) => {
    exams.sort(rankingCompare);
    return exams;
  };

  function rankingCompare(a: ExamToDoDTO, b: ExamToDoDTO) {
    if (a?.rank && b?.rank) {
      if (a.rank < b.rank) {
        return -1;
      }
      if (a.rank > b.rank) {
        return 1;
      }
      return 0;
    } else {
      return 0;
    }
  }

  const filterRelesed = (exams: ExamToDoDTO[]) => {
    const filteredExams = exams.filter((ex) => {
      const isReleased = ex.papers.some((paper) => {
        if (paper.paperType === PaperTypes.STRUCTURED || paper.paperType === PaperTypes.ESSAY) {
          if (
            !paper.structEssayData?.answerSheetUrl ||
            paper.structEssayData.answerSheetUrl === ''
          ) {
            return false;
          }
          return !paper.released;
        } else {
          return false;
        }
      });

      return !isReleased;
    });

    return filteredExams;
  };
  const fetchDataCall = (searchTxt?: string) => {
    let pagesList: ExamToDoDTO[] = [];

    if ([...state.totalCompletedExams].length) {
      let searchTextList = [...state.totalCompletedExams].filter((exam) => {
        if (searchTxt && searchTxt?.trim() === '') {
          return exam;
        } else if (
          appUsers.allAdminsAndInstructors
            .find((val) => val.uid === exam.userId)
            ?.username.toLowerCase()
            .includes(searchTxt?.toLowerCase().trim() || '')
        ) {
          return exam;
        }

        return false;
      });
      const filteredExams = filterRelesed(searchTextList);

      const temp = [...filteredExams];

      const array1 = temp.map((ex) => parseFloat(ex.score?.toString() || '0') || 0);

      const rankingArray = rankingHandler(array1);

      temp.forEach((exam, i) => {
        exam.rank = rankingArray[i];
      });

      console.log('TEMPPP ', temp);

      const sortedExams = sortExams(temp);
      pagesList = splitArrayIntoChunksOfLen(sortedExams, 10)[state.page - 1];

      setState((ps) => {
        return {
          ...ps,
          completedExams: pagesList || [],
          totalPages: splitArrayIntoChunksOfLen(sortedExams, 10).length,
          started: false,
        };
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    setState((ps) => {
      return { ...ps, started: true };
    });

    if (isMounted) {
      fetchDataCall(state.searchText);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.searchText]);

  useEffect(() => {
    sortRank();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, rankSorted]);

  const getExamDate = () => {
    if (props.selectedExam?.examType === ExamType.EXTRA_EXAM) {
      console.log('exam date: extra:', props.selectedExam?.createdAt);
      return props.selectedExam?.createdAt
        ? moment(props.selectedExam?.createdAt?.toDate()).format('MMMM/Do/YYYY h:mm:ss a')
        : '-';
    } else {
      console.log('exam date: not extra:', props.selectedExam?.startDate);
      return props.selectedExam?.startDate
        ? moment(props.selectedExam?.startDate?.toDate()).format('MMMM/Do/YYYY h:mm:ss a')
        : '-';
    }
  };

  return (
    <div className="student-grades">
      <SearchWidget
        isShowMenu={filterRelesed(state.totalCompletedExams).length > 0}
        onClickMenu={(e) => {
          const [subject] = allSubjects.filter((s) => s.id === props.selectedExam?.subjectId);
          const [mcqx] =
            props.selectedExam?.papers.filter((p) => p.paperType === PaperTypes.MCQ) || [];
          const [esyx] =
            props.selectedExam?.papers.filter((p) => p.paperType === PaperTypes.ESSAY) || [];
          const [strx] =
            props.selectedExam?.papers.filter((p) => p.paperType === PaperTypes.STRUCTURED) || [];
          const [tfx] =
            props.selectedExam?.papers.filter((p) => p.paperType === PaperTypes.TRUEFALSE) || [];
          ExcelHelper.getReport(e, 'xlsx', {
            grade: props.selectedExam?.grade || '',
            lectureName: props.selectedExam?.authorName || '',
            subject: subject?.name || '',
            year: props.selectedExam?.subjectYear || '',
            ExamName: props.selectedExam?.examTitle || '',
            pepersIncluded:
              props.selectedExam?.papers.map((p) => p.paperType?.toString() || '') || [],
            examDate: getExamDate(),
            ismcq: !!mcqx,
            isesy: !!esyx,
            isstr: !!strx,
            istf: !!tfx,
            selectedExam: props.selectedExam,
            esyTitle:
              esyx?.paperTitle +
              '&' +
              (props.selectedExam?.gradingType !== 'Percentage' && `Total Points: ${esyx?.marks}`),
            mcqTitle:
              mcqx?.paperTitle +
              '&' +
              (props.selectedExam?.gradingType !== 'Percentage' && `Total Points: ${mcqx?.marks}`),
            tfTitle:
              tfx?.paperTitle +
              '&' +
              (props.selectedExam?.gradingType !== 'Percentage' && `Total Points: ${tfx?.marks}`),
            strTitle:
              strx?.paperTitle +
              '&' +
              (props.selectedExam?.gradingType !== 'Percentage' && `Total Points: ${strx?.marks}`),
            data: sortExams(filterRelesed(state.totalCompletedExams)).map((exam) => {
              const [mcq] = exam.papers.filter((p) => p.paperType === PaperTypes.MCQ);
              const [esy] = exam.papers.filter((p) => p.paperType === PaperTypes.ESSAY);
              const [str] = exam.papers.filter((p) => p.paperType === PaperTypes.STRUCTURED);
              const [tf] = exam.papers.filter((p) => p.paperType === PaperTypes.TRUEFALSE);
              // const msqV = (mcq ? mcq.score : 0) || 0;
              // const esyV = (esy ? esy.score : 0) || 0;
              // const strV = (str ? str.score : 0) || 0;
              // const tfV = (tf ? tf.score : 0) || 0;
              let totalMarks = '';
              if (props.selectedExam?.gradingType === 'Percentage') {
                totalMarks = exam.score ? round(exam.score, 2, 2) + '%' : '0';
              } else {
                const x = Math.round(
                  exam.papers.reduce((a, b) => {
                    return a + (b.paperPoints || 0);
                  }, 0)
                );
                totalMarks = x + '';
              }

              const msqVS = mcq ? getPaperStatus(mcq) : '-1';
              const esyVS = esy ? getPaperStatus(esy) : '-1';
              const strVS = str ? getPaperStatus(str) : '-1';
              const tfVS = tf ? getPaperStatus(tf) : '-1';
              const [stId] = users.filter((u) => u.uid === exam.userId);
              return {
                studentId: stId?.userIndex || '',
                studentName:
                  appUsers.allAdminsAndInstructors.find((val) => val.uid === exam.userId)
                    ?.username || '',
                mcq: msqVS,
                esy: esyVS,
                str: strVS,
                tf: tfVS,
                rank: exam.rank + '' || '0',
                totalMarks: totalMarks,
                marker: '-',
                sudentContact:
                  appUsers.allAdminsAndInstructors.find((val) => val.uid === exam.userId)
                    ?.phoneNumber || '-',
              };
            }),
          });
        }}
        onSearch={(searchTerm) => {
          searchTextHandler(searchTerm);
        }}
        placeHolderText="Search Student"
        className="ml-auto"
        style={{ width: 240 }}
      />
      {state.loading && <LoadingIndicator />}
      <GradesTabMarks
        exam={props.selectedExam}
        list={state.completedExams}
        searchText={state.searchText}
        isLoading={state.loading}
        sortHandler={sortHandler}
        rankSorted={rankSorted}
        currentPageNumber={state.page}
      />
      {!state.started && <Pagination pages={state.totalPages} setCurrentPage={setCurrentPage} />}
    </div>
  );
};

export default ExamGrades;
