import React, { FC, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useFirestore } from 'reactfire';
import truncate from 'truncate';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
import { useAppUser } from '../context/UserContext';
import AlertPopup from './AlertPopup';

import './SubjectCard.scss';

interface Props {
  imageURL: string;
  name: string;
  grade: string;
  year?: string;
  subId?: string;
  createdBy?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  loading?: boolean;
  setLoading?: () => void;
  endLoading?: () => void;
}

const SubjectCard: FC<Props> = (props) => {
  const history = useHistory();
  const [showWarning, setShowWarning] = useState(false);
  const { firestoreUser } = useAppUser();
  const store = useFirestore();

  const appAdminsAndInstructors = useAdminsAndInstructors();

  const authorName =
    appAdminsAndInstructors.allAdminsAndInstructors.find((user) => user.uid === props.createdBy)
      ?.username || '';

  let grade = props.grade;

  if (props.grade === 'A/L') {
    grade = 'A. LEVEL';
  }
  if (props.grade === 'O/L') {
    grade = 'O. LEVEL';
  }

  const showWarningHandler = () => {
    setShowWarning(true);
  };

  const closeWarningHandler = () => {
    setShowWarning(false);
  };

  const isSubjectDeletable = async () => {
    return store
      .collection('questions')
      .where('subjectId', '==', props.subId)
      .get()
      .then((data) => {
        if (data.size) {
          return false;
        } else {
          return store
            .collection('papers')
            .where('subjectId', '==', props.subId)
            .get()
            .then((data) => {
              if (data.size) {
                return false;
              } else {
                return store
                  .collection('exams')
                  .where('subjectId', '==', props.subId)
                  .get()
                  .then((data) => {
                    if (data.size) {
                      return false;
                    } else {
                      return store
                        .collection('structuredEssayPapers')
                        .where('subjectId', '==', props.subId)
                        .get()
                        .then((data) => {
                          if (data.size) {
                            return false;
                          } else {
                            return true;
                          }
                        })
                        .catch(() => {
                          return false;
                        });
                    }
                  })
                  .catch(() => {
                    return false;
                  });
              }
            })
            .catch(() => {
              return false;
            });
        }
      })
      .catch(() => {
        return false;
      });
  };

  let attachBody = <></>;

  if (
    firestoreUser?.userRole?.isAdmin ||
    firestoreUser?.uid === props?.createdBy ||
    firestoreUser?.userRole?.isInstructor
  ) {
    attachBody = (
      <div className="subject-card-img__overlay">
        <div
          onClick={(e) => {
            props.setLoading && props.setLoading();
            isSubjectDeletable()
              .then((result) => {
                if (!result) {
                  showWarningHandler();
                } else {
                  props.onDelete && props.onDelete();
                }
                props.endLoading && props.endLoading();
              })
              .catch(() => {
                props.endLoading && props.endLoading();
              });
            e.stopPropagation();
          }}
        >
          <FaTrashAlt color="#F05A5A" size={20} />
        </div>
        <div
          onClick={(e) => {
            props.onEdit && props.onEdit();
            e.stopPropagation();
          }}
        >
          <FaEdit color="#FFFFFF" size={20} />
        </div>
      </div>
    );
  }

  const appUser = useAppUser();

  return (
    <>
      <div
        className="subject-card"
        onClick={() => {
          if (appUser.firestoreUser?.userRole?.isTeachingAssistant) {
            history.push({
              pathname: `/my-subjects/subject/${props.subId}/Examinations`,
            });
          } else {
            history.push({
              pathname: `/my-subjects/subject/${props.subId}/Lessons`,
            });
          }
        }}
      >
        <div className="subject-card-img" style={{ backgroundImage: `url(${props.imageURL})` }}>
          {attachBody}
          <div className="subject-card-img__grade">{grade.toUpperCase()}</div>
        </div>
        <div className="subject-card-description">
          <div>{props.name}</div>
          <div className="mt-1" style={{ whiteSpace: 'nowrap' }}>
            {'by ' + truncate(authorName || '', 20)}
          </div>
          <div className="mt-2 subject-card-year">{props.year}</div>
        </div>
      </div>

      <AlertPopup
        header="Warning"
        message="This subject cannot be deleted. It contains papers & questions."
        isShow={showWarning}
        onOk={closeWarningHandler}
        onClose={closeWarningHandler}
        type="NO_BUTTON"
      />
    </>
  );
};

export default SubjectCard;
