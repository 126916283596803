import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import { useAppUser } from '../context/UserContext';
import { StructEssayDataPaperStatus } from '../dtos/StructEssayData';
import { PaperTypes } from '../enums/paperTypes';
import { StudentTAAssignmentInfo } from './ExamTAAssignmentChart';

import './ExamTAPaymentsTab.scss';
import LoadingIndicator from './LoadingIndicator';

interface StudentTAAssignmentInfoWithStatus extends StudentTAAssignmentInfo {
  papers: {
    isMarked?: boolean;
    paperId: string;
    paperTitle: string;
    paperStatus?: StructEssayDataPaperStatus | undefined;
    taPayment?: {
      amount?: number | undefined;
      paid: boolean;
      paidAt?: any;
    };
    assignedTAUid:
      | {
          taUid: string;
          assignedTime?: any;
          changed?: boolean; //this attribute is only there to set assignedTime if necessary.
        }
      | null
      | undefined;
  }[];
}

interface ITaPaymentInfo {
  paperId: string;
  paperTitle: string;
  paperType: string;
  totalPayment: number;
  totalFree: number;
  totalPaid: number;
  totalNotMarked: number;
  totalMarked: number;
  totalMarkedAndPaid: number;
  totalMarkedAndFree: number;
  totalPapers: number;
  amount: number;
  isFree: boolean;
  totalAproved: number;
  totalRejected: number;
  totalPending: number;
  selectedStudent: StudentTAAssignmentInfoWithStatus[];
}

const ExamTAPaymentsTab: FC = () => {
  const params = useParams() as { examId: string };
  const user = useAppUser().firestoreUser;
  const [taPayments, setTAPayments] = React.useState<ITaPaymentInfo[]>([]);
  const [selectedPayment, setSelectedPayment] = React.useState<ITaPaymentInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const getTaPaymentData = useFunctions().httpsCallable('getTaPaymentData');
  useEffect(() => {
    if (params.examId && user) {
      setLoading(true);
      getTaPaymentData({ examId: params.examId, taUid: user.uid }).then((result) => {
        setTAPayments([...result.data.data]);
        setSelectedPayment(result.data.data[0]);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.examId, user]);

  const getTotalExamPayment = () => {
    let total = 0;
    taPayments.forEach((payment) => {
      total += payment.totalPayment;
    });
    return total;
  };

  return (
    <div className="TA-payments-tab">
      {loading && <LoadingIndicator />}
      <div className="TA-payments-tab__body">
        <div className="TA-payments-tab__body-column">
          <div className="TA-payments-tab__body-column-header">Papers</div>
          <div
            style={{
              overflowY: 'auto',
              paddingRight: '10px',
            }}
          >
            {taPayments.map((paper, index) => (
              <div
                className="TA-payments-tab__body-column-item"
                onClick={() => setSelectedPayment(paper)}
                style={{
                  backgroundColor:
                    selectedPayment && paper.paperId === selectedPayment.paperId ? '#343857' : '',
                }}
                key={index}
              >
                <span className="TA-payments-tab__body-column-item-type">
                  <span className="TA-payments-tab__body-column-item-type-card">
                    {paper.paperType === PaperTypes.STRUCTURED
                      ? 'STR'
                      : paper.paperType === PaperTypes.ESSAY
                      ? 'ESY'
                      : ''}
                  </span>
                </span>
                <span className="TA-payments-tab__body-column-item-type-card-vertical"></span>
                <span className="TA-payments-tab__body-column-item-paperName">
                  {paper.paperTitle}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="TA-payments-tab__body-column2">
          {selectedPayment && (
            <div className="TA-payments-tab__body-column2-body">
              <div className="TA-payments-tab__body-column2-body-row">
                <div className="TA-payments-tab__body-column2-body-row-item">
                  <span className="TA-payments-tab__body-column2-body-row-item-number">
                    {(selectedPayment && selectedPayment.totalAproved) || '-'}
                  </span>
                  <span className="TA-payments-tab__body-column2-body-row-item-content">
                    Approved Papers
                  </span>
                </div>
                <div className="TA-payments-tab__body-column2-body-row-item">
                  <span className="TA-payments-tab__body-column2-body-row-item-number">
                    {' '}
                    {(selectedPayment && selectedPayment.amount) || '-'}
                  </span>
                  <span className="TA-payments-tab__body-column2-body-row-item-content">
                    Cost Per Paper (Rs.)
                  </span>
                </div>
              </div>
              <div className="TA-payments-tab__body-column2-body-row">
                <div className="TA-payments-tab__body-column2-body-row-item">
                  <span className="TA-payments-tab__body-column2-body-row-item-number">
                    {' '}
                    {selectedPayment.totalMarkedAndPaid || '-'}
                  </span>
                  <span className="TA-payments-tab__body-column2-body-row-item-content">
                    Payment received Papers Count
                  </span>
                </div>
                <div
                  className="TA-payments-tab__body-column2-body-row-item"
                  style={{
                    backgroundColor: '#E7AF1F',
                  }}
                >
                  <span className="TA-payments-tab__body-column2-body-row-item-number">
                    {' '}
                    {selectedPayment.totalPayment || '-'}
                  </span>
                  <span className="TA-payments-tab__body-column2-body-row-item-content text-dark">
                    Total Received Payment for the paper (Rs.)
                  </span>
                </div>
              </div>

              <div className="TA-payments-tab__body-column2-body-row">
                <div
                  className="TA-payments-tab__body-column2-body-row-item"
                  style={{
                    border: 'none',
                    backgroundColor: '#20D167',
                    color: '#fff',
                    width: '420px',
                  }}
                >
                  <span className="TA-payments-tab__body-column2-body-row-item-number">
                    Rs.{getTotalExamPayment()}
                  </span>
                  <span className="TA-payments-tab__body-column2-body-row-item-content text-dark">
                    Total Payment Received for the Exam
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExamTAPaymentsTab;
