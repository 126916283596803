import React, { FC } from 'react'
import { FaExpandArrowsAlt, FaTrashAlt } from 'react-icons/fa';
import BoxIcon from '../../../components/BoxIcon';
import { useAppContent } from '../context/ContentContext';
import { CONTENT_MEDIA_TYPE } from '../utils';
import './AddContentItemShell.scss'

interface Props {
    componentName: string;
    componentType: CONTENT_MEDIA_TYPE;
    contentItemDroppableId: string;
    droppableId: string;
    className?: string;
}

const AddContentItemShell: FC<Props> = (props) => {

    const appContent = useAppContent()

    const CONTENT =
      props.componentType === CONTENT_MEDIA_TYPE.TEXT ||
      props.componentType === CONTENT_MEDIA_TYPE.LINKS ? (
        <div className="add-content-item-shell">
          <div className="add-content-item-shell__move">
            <FaExpandArrowsAlt className="add-content-item-shell__icon" />
          </div>
          <div className="add-content-item-shell__main">
            <div className="add-content-item-shell__top" style={{ marginBottom: '1rem' }}>
              <div className="add-content-item-shell__com-name">{props.componentName}</div>
              <BoxIcon
                icon={FaTrashAlt}
                color="#fff"
                size={13}
                style={{ width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: '12%' }}
                onClick={() => {
                  if (props.componentType === CONTENT_MEDIA_TYPE.TEXT) {
                    appContent.removeNewTextContentSlotHandler(
                      props.droppableId,
                      props.contentItemDroppableId
                    );
                  } else if (props.componentType === CONTENT_MEDIA_TYPE.LINKS) {
                    appContent.removeNewLinkSlotHandler(
                      props.droppableId,
                      props.contentItemDroppableId
                    );
                  }
                }}
              />
            </div>
            <div className="add-content-item-shell__children">{props.children}</div>
          </div>
        </div>
      ) : (
        <div className="add-content-item-shell">
          <div className="add-content-item-shell__move">
            <FaExpandArrowsAlt className="add-content-item-shell__icon" />
          </div>
          <div className="add-content-item-shell__main">
            <div className="add-content-item-shell__com-name">{props.componentName}</div>
            <div className="add-content-item-shell__children">{props.children}</div>
          </div>
          <div className="add-content-item-shell__remove">
            <BoxIcon
              icon={FaTrashAlt}
              color="#fff"
              size={13}
              style={{ width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: '12%' }}
              onClick={() => {}}
            />
          </div>
        </div>
      );

    return <div className={props.className}>{CONTENT}</div>
}

export default AddContentItemShell