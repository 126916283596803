export interface Validation {
  isValid: boolean;
  message: string;
  options?: string[];
}

class FormValidator {
  public static validateString(
    value: string = "",
    name: string,
    min: number = -1,
    max: number = -1
  ): Validation {
    let message: string = "";
    let isValid: boolean = true;

    if (value.trim().length <= 0) {
      isValid = false;
      message = `${name} cannot be empty.`;
    } else if (max !== -1 && value.trim().length < min) {
      isValid = false;
      message = `${name} must be at least ${min} characters.`;
    }

    if (max !== -1 && value.trim().length > max) {
      isValid = false;
      message = `${name} cannot be longer than ${max} characters.`;
    }
    return {
      isValid: isValid,
      message: message,
    };
  }

  public static validateQuestionBody(
    value: string = "",
    name: string = "",
    min: number,
    max: number
  ): Validation {
    let message: string = "";
    let isValid: boolean = true;
    const x = value.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, "");
    const imageRegx = new RegExp(/<img.*?src="(.*?)"[^>]+>/g);
    // if (x.trim().length < 1 && !imageRegx.test(value)) {
    //   isValid = false;
    //   message = `Body is missing.`;
    // }
    const validateX = this.validateString(x.trim(), name, min, max);
    // if (x.length > 10000) {
    //   isValid = false;
    //   message = ` Body is limited to 10000 characters.`;
    // }

    if (!validateX.isValid && !imageRegx.test(value)) {
      isValid = validateX.isValid;
      message = validateX.message;
    }

    return {
      isValid: isValid,
      message: message,
    };
  }

  public static validateDob(
    value: Date = new Date(),
    name: string
  ): Validation {
    let message: string = "";
    let isValid: boolean = true;

    console.log("value in validate- ", value);
    console.log("value type in validate- ", typeof value);
    if (
      value.toDateString() === new Date().toDateString() ||
      value >= new Date()
    ) {
      isValid = false;
      message = `Please set a valid Date of birth.`;
    }

    return {
      isValid: isValid,
      message: message,
    };
  }

  public static validateWorkshopStartDate(
    startDate: Date | undefined,
    name: string
  ): Validation {
    let message: string = "";
    let isValid: boolean = true;

    if (startDate === undefined || !this.isDateInTheFuture(startDate)) {
      isValid = false;
      message = `Please set a valid ${name}.`;
    }

    return {
      isValid: isValid,
      message: message,
    };
  }

  public static isDateInTheFuture(date: Date) {
    if (date.getFullYear() > new Date().getFullYear()) {
      return true;
    } else if (date.getFullYear() < new Date().getFullYear()) {
      return false;
    } else if (date.getMonth() > new Date().getMonth()) {
      return true;
    } else if (date.getMonth() < new Date().getMonth()) {
      return false;
    } else if (date.getDate() > new Date().getDate()) {
      return true;
    } else return date.getDate() >= new Date().getDate();
  }

  public static areDatesEqual(date1: Date, date2: Date) {
    console.log(date1, date2);
    if (date1?.getFullYear() > date2?.getFullYear()) {
      return false;
    } else if (date1?.getFullYear() < date2?.getFullYear()) {
      return false;
    } else if (date1?.getMonth() > date2?.getMonth()) {
      return false;
    } else if (date1?.getMonth() < date2?.getMonth()) {
      return false;
    } else return date1?.getDate() === date2?.getDate();
  }

  public static validateYoutubeURL(url: string = ""): Validation {
    let message: string = "";
    let isValid: boolean = true;

    if (url.trim().length < 1) {
      // isValid = false;
      // message = `YouTube url cannot be empty.`;
      isValid = true;
    } else {
      const regex: RegExp = new RegExp(
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
      );
      const match = url.match(regex);
      if (!(match && match[2].length === 11)) {
        isValid = false;
        message = `Invalid link`;
      }
    }

    return {
      isValid: isValid,
      message: message,
    };
  }
  public static validateURL(url: string = ""): Validation {
    let message: string = "";
    let isValid: boolean = true;

    if (url.trim().length < 1) {
      // isValid = false;
      // message = `YouTube url cannot be empty.`;
      isValid = false;
      message = `Invalid link`;
    } else {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); //

      if (!pattern.test(url)) {
        isValid = false;
        message = `Invalid link`;
      }
    }

    return {
      isValid: isValid,
      message: message,
    };
  }

  public static validateApplicationEmails(
    email: string,
    memberEmails: string[]
  ): Validation {
    let message: string = "";
    let isValid: boolean = true;

    const emailValidationResult: Validation = this.validateEmail(email);

    if (!emailValidationResult.isValid) {
      return emailValidationResult;
    }

    if (
      memberEmails.filter((memberEmail) => memberEmail === email).length > 0
    ) {
      isValid = false;
      message = `Duplicate emails found.`;
    }

    return {
      isValid: isValid,
      message: message,
    };
  }

  public static validateEmail(email: string = ""): Validation {
    let message: string = "";
    let isValid: boolean = true;

    const regexp: RegExp = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (!regexp.test(email)) {
      isValid = false;
      message = `Invalid email address.`;
    }
    if (email.trim().length < 1) {
      isValid = false;
      message = `Email address cannot be empty.`;
    }

    return {
      isValid: isValid,
      message: message,
    };
  }

  public static validatePhoneNumber(phoneNo: string = ""): Validation {
    let message: string = "";
    let isValid: boolean = true;

    const regex = /^((0\d)\d{8}|(\+\d)\d{10})$/gm;

    if (!phoneNo.replace(/ /g, "").match(regex)) {
      isValid = false;
      message = `Invalid phone number!`;
    }

    if (phoneNo.length < 1) {
      isValid = false;
      message = "Contact number cannot be empty.";
    }

    return {
      isValid,
      message,
    };
  }

  public static validateJamaicaPhoneNumber(phoneNo: string = ""): Validation {
    let message: string = "";
    let isValid: boolean = true;

    const regex = /^((00)\d{10}|(0\d)\d{9}|(\+1)\d{10}|(8)\d{9})$/gm;

    if (!phoneNo.replace(/ /g, "").match(regex)) {
      isValid = false;
      message = `Invalid phone number!`;
    }

    if (phoneNo.length < 1) {
      isValid = false;
      message = "Contact number cannot be empty.";
    }

    return {
      isValid,
      message,
    };
  }

  public static validatePassword(password: string = ""): Validation {
    let message: any = "";
    let isValid: boolean = true;
    let options: string[] = [];
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@!"#(*.,/\\:;<?`|{~}[\]>)'$%^&+\-_=]).{8,}$/gm;
    const regexp: RegExp = new RegExp(regex);

    const latter = /^(?=.*[a-zA-Z])/gm;
    const numericReg = /^(?=.*[0-9])/gm;
    const specialCharacterReg = /^(?=.*[@!"#(*.,/\\:;<?`|{~}[\]>)'$%^&+\-_=])/gm;
    const lengthReg = /^(?=.{8,})/gm;

    if (password.trim().length < 1) {
      isValid = false;
      message = `Password cannot be empty.`;
    } else {
      if (!regexp.test(password)) {
        isValid = false;
        message = `Please add one of the following things to make your password
                stronger:`;
        if (!latter.test(password)) options.push("Letters");
        if (!numericReg.test(password)) options.push("Numbers");
        if (!specialCharacterReg.test(password))
          options.push("Special characters");
        if (!lengthReg.test(password)) options.push("8 Characters");
      }
    }
    return {
      isValid: isValid,
      message: message,
      options: options,
    };
  }

  public static validateDobFromAccount(
    value: Date = new Date(),
    name: string
  ): Validation {
    let message: string = "";
    let isValid: boolean = true;

    if (
      this.areDatesEqual(value, new Date()) ||
      this.isDateInTheFuture(value)
    ) {
      isValid = false;
      message = `Please set a valid Date of Birth.`;
    }

    return {
      isValid: isValid,
      message: message,
    };
  }

  public static validateStringFromAccount(
    value: string = "",
    name: string,
    min: number = -1,
    max: number = -1
  ): Validation {
    let message: string = "";
    let isValid: boolean = true;

    if (value.trim().length <= 0) {
      isValid = true;
    } else if (max !== -1 && value.trim().length < min) {
      isValid = false;
      message = `${name} must be at least ${min} characters.`;
    }

    if (max !== -1 && value.trim().length > max) {
      isValid = false;
      message = `${name} cannot be longer than ${max} characters.`;
    }
    return {
      isValid: isValid,
      message: message,
    };
  }

  public static validatePhoneNumberFromAccount(
    phoneNo: string = ""
  ): Validation {
    let message: string = "";
    let isValid: boolean = true;

    const regex = /^((0\d)\d{8}|(\+\d)\d{10})$/gm;

    if (!phoneNo.replace(/ /g, "").match(regex)) {
      isValid = false;
      message = `Invalid phone number!`;
    }

    if (phoneNo.length < 1) {
      isValid = false;
      message = `Phone Number cannot be empty.`;
    }

    return {
      isValid,
      message,
    };
  }

  public static validateJamaicaPhoneNumberFromAccount(
    phoneNo: string = ""
  ): Validation {
    let message: string = "";
    let isValid: boolean = true;

    const regex = /^((00)\d{10}|(0\d)\d{9}|(\+1)\d{10}|(8)\d{9})$/gm;

    if (!phoneNo.replace(/ /g, "").match(regex)) {
      isValid = false;
      message = `Invalid phone number!`;
    }

    if (phoneNo.length < 1) {
      isValid = false;
      message = `Phone Number cannot be empty.`;
    }

    return {
      isValid,
      message,
    };
  }
}

export default FormValidator;
