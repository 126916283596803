import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {QuestionTypes} from '../enums/questionTypes';
import {MCQ, Question, TFQuestion} from '../models/Question';
import QuestionService from '../services/QuestionService';

export interface QuestionsContext {
  allQuestions: Question[];
  mcqQuestions: MCQ[];
  tfQuestions: TFQuestion[];
  fetchQtns: () => void;
}

interface State {
  allQuestions: Question[];
  mcqQuestions: MCQ[];
  tfQuestions: TFQuestion[];
}

const initialContext: QuestionsContext = {
  allQuestions: [],
  mcqQuestions: [],
  tfQuestions: [],
  fetchQtns: () => {},
};

const initialState: State = {
  allQuestions: [],
  mcqQuestions: [],
  tfQuestions: [],
};

const Context = createContext<QuestionsContext>(initialContext);

export const QuestionsProvider: FC = (props) => {
  const [state, setState] = useState(initialState);

  const fetchQtns = async () => {
    try {
      const allQuestions: Question[] = [];

      const response = await QuestionService.getAllQtns();

      response.forEach((doc) => {
        const question = {id: doc.id, ...doc.data()} as Question;

        allQuestions.push(question);
      });

      const mcqs: MCQ[] = allQuestions.filter((q) => q.questionType === QuestionTypes.MCQ) as MCQ[];
      const tfs: TFQuestion[] = allQuestions.filter(
        (q) => q.questionType === QuestionTypes.TRUEFALSE
      ) as TFQuestion[];

      setState((ps) => ({
        ...ps,
        allQuestions: allQuestions,
        mcqQuestions: mcqs,
        tfQuestions: tfs,
      }));
    } catch (e) {
      console.log('Fetch Error - ', e);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchQtns();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Context.Provider
      value={{
        ...state,
        fetchQtns,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const useQuestions = (): QuestionsContext => useContext(Context);
