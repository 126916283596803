import React, {FC} from 'react';
import './Button.scss';

interface Props {
  type: 'one';
  text: string;
  outline?: boolean;
  outlineColor?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}

const ButtonComp: FC<Props> = (props) => {
  let content = null;

  if (props.type === 'one') {
    content = (
      <button
        disabled={props.disabled}
        className={`button ${props.outline && 'button-outline'} ${
          (props.loading || props.disabled) && 'button-disable'
        } ${props.className}`}
        style={{...props.style, borderColor: props.outlineColor}}
        onClick={() => {
          !props.loading && props.onClick();
        }}
      >
        <div className="button__text">{props.text}</div>
      </button>
    );
  }

  return content;
};

export default ButtonComp;
