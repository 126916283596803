import React, {FC} from 'react';
import {useMobileNav} from '../context/MobNavContext';
import {useAppSubjectUI} from '../context/subjectsUI';

import './PrimaryNavItem.scss';

interface Props {
  icon: any;
  name?: string;
  active: boolean;
  label?: string;
  navigationHandler: () => void;
  className?: string;
}

const SideNavItem: FC<Props> = (props) => {
  const appMobileNav = useMobileNav();
  const SUBJECTNAV = useAppSubjectUI();

  return (
    <div className={`side-nav-item ${props.className}`}>
      <div
        className={`side-nav-item__icon ${props.active ? 'active' : ''}`}
        onClick={() => {
          SUBJECTNAV.onClear();
          props.navigationHandler();
          appMobileNav.setOpenMobileNav(false);
        }}
      >
        {props.label && props.label !== '0' && <div className="count-label">{props.label}</div>}
        {props.icon}
      </div>
      <div
        className="side-nav-item__name"
        onClick={() => {
          SUBJECTNAV.onClear();
          props.navigationHandler();
          appMobileNav.setOpenMobileNav(false);
        }}
      >
        {props.name}
      </div>
    </div>
  );
};

export default SideNavItem;
