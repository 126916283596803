import React, { FC, useEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import ExamDashboard from '../../components/ExamDashboard';
import ExamTAPaymentsTab from '../../components/ExamTAPaymentsTab';
import PrimaryNav from '../../components/PrimaryNav';
import SelectedExamDashboardHeader from '../../components/SelectedExamDashboardHeader';
import StudentRoster from '../../components/StudentRoster';
import TASubjectSecondaryNav from '../../components/TASubjectSecondaryNav';
import { useAdminsAndInstructors } from '../../context/AdminsAndInstructors';
import { ExamToDoDTO } from '../../dtos/ExamToDoDTO';
import { PaperInfoData } from '../../interfaces';
import IncludeDashboardGradeSettingsTATabs from '../../layouts/IncludeDashboardGradeSettingsTATabs';
import LayoutNew from '../../layouts/LayoutNew';
import { Exam } from '../../models/Exam';
import PageNotFoundScreen from '../common/PageNotFoundScreen';
import TAExamSettings from './TAExamSettings';

interface State {
  exam: Exam | null;
  isLoading: boolean;
  reload: boolean;
  completedExams: ExamToDoDTO[];
  paperInfoData: PaperInfoData | null;
}

const initialState = {
  exam: null,
  isLoading: false,
  reload: false,
  completedExams: [],
  paperInfoData: null,
};

interface Props {
  hierarchyLeveL?: "LESSON" | "UNIT"
}

const TAExamDetailsAndStatsScreen: FC<Props> = (props) => {
  const params = useParams() as { examId: string };

  const store = useFirestore();

  const [state, setState] = useState<State>(initialState);

  //methods
  const setLoading = (value: boolean) => {
    setState((ps) => {
      return { ...ps, isLoading: value };
    });
  };

  const setPapersInfoDataHandler = (value: PaperInfoData | null) => {
    setState((ps) => {
      return { ...ps, paperInfoData: value };
    });
  };

  const setCompletedExamsHandler = (exams: ExamToDoDTO[]) => {
    setState((ps) => {
      return { ...ps, completedExams: exams };
    });
  };

  useEffect(() => {
    setLoading(true);

    store
      .collection('exams')
      .doc(params.examId || '')
      .get()
      .then((response) => {
        const exam = { id: response.id, ...response.data() } as Exam;

        store
          .collection('completedExams')
          .where('examId', '==', params.examId)
          .get()
          .then((res) => {
            const examsToDo: ExamToDoDTO[] = [];

            res.forEach((doc) => {
              const examToPush = { ...doc.data(), id: doc.id } as ExamToDoDTO;

              examsToDo.push(examToPush);
            });

            setState((ps) => {
              return {
                ...ps,
                exam: exam,
                isLoading: false,
                completedExams: examsToDo,
              };
            });
          })
          .catch(() => { });
      })

      .catch(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reload]);

  const primaryNavigation = <PrimaryNav />;
  const secondaryNavigation = <TASubjectSecondaryNav />;
  const appHeader = (
    <SelectedExamDashboardHeader
      exam={state.exam}
      loading={state.isLoading}
      setLoading={setLoading}
      setPapersInfoDataHandler={setPapersInfoDataHandler}
      hierarchyLevel={props.hierarchyLeveL}
    />
  );

  const appAdminsAndInstructors = useAdminsAndInstructors();

  const reloadHandler = () => {
    setState((ps) => ({
      ...ps,
      reload: !state.reload,
    }));
  };

  const [sta, setSta] = useState(() => {
    const completedUsers = appAdminsAndInstructors.allAdminsAndInstructors.filter((u) =>
      state.exam?.completedUsers?.includes(u.uid || '')
    );

    const yetToSubmitIds = state.exam?.usersExamState
      ?.filter((u) => !state.exam?.completedUsers?.includes(u.userId || ''))
      .map((mU) => mU.userId);

    const yetToSubmitUsers = appAdminsAndInstructors.allAdminsAndInstructors.filter((u) =>
      yetToSubmitIds?.includes(u.uid || '')
    );

    return { completedUsers, yetToSubmitUsers };
  });

  useEffect(() => {
    const completedUsers = appAdminsAndInstructors.allAdminsAndInstructors.filter((u) =>
      state.exam?.completedUsers?.includes(u.uid || '')
    );

    const yetToSubmitIds = state.exam?.usersExamState
      ?.filter((u) => !state.exam?.completedUsers?.includes(u.userId || ''))
      .map((mU) => mU.userId);

    const yetToSubmitUsers = appAdminsAndInstructors.allAdminsAndInstructors.filter((u) =>
      yetToSubmitIds?.includes(u.uid || '')
    );

    setSta({ completedUsers, yetToSubmitUsers });
  }, [state.exam, appAdminsAndInstructors.allAdminsAndInstructors]);

  const appBody = (
    <IncludeDashboardGradeSettingsTATabs
      essayOrStructuredPaperPresent={!!state.exam?.papers.find((val) => val.paperUrl)}
      hierarchyLeveL={props.hierarchyLeveL}
    >
      <Switch>
        <Route exact path="/my-subjects/subject/:subjectId/Examinations/:examId/dashboard">
          <ExamDashboard isLoading={state.isLoading} gradeType={state.exam?.gradingType} />
        </Route>
        <Route exact path="/my-subjects/subject/:subjectId/Examinations/:examId/payments">
          <ExamTAPaymentsTab />
        </Route>
        <Route exact path="/my-subjects/subject/:subjectId/Examinations/:examId/settings">
          <TAExamSettings selectedExam={state.exam} />
        </Route>

        <Route path="*" component={PageNotFoundScreen} />
      </Switch>

      {state.paperInfoData ? (
        <StudentRoster
          papers={state.exam?.papers || []}
          subjectId={state.exam?.subjectId || ''}
          examId={state.exam?.id || ''}
          completedExams={state.completedExams}
          usersYetToSubmit={sta.yetToSubmitUsers}
          completedUsers={sta.completedUsers}
          paperInfoData={state.paperInfoData}
          setPaperInfoData={setPapersInfoDataHandler}
          setCompletedExams={setCompletedExamsHandler}
          setIsLoading={setLoading}
          isLoading={state.isLoading}
          studentTAAssignmentInfo={state.exam?.studentTAAssignmentInfo}
          reloadHandler={reloadHandler}
          reload={state.reload}
        />
      ) : (
        <></>
      )}
    </IncludeDashboardGradeSettingsTATabs>
  );

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

export default TAExamDetailsAndStatsScreen;
