import React, {FC} from 'react';
import './StudentTAAssigmentRadioBtn.scss';

interface Props {
  className?: string;
  onClick: () => void;
  active: boolean;
  disabled?: boolean;
}

const StudentTAAssigmentRadioBtn: FC<Props> = (props) => {
  return (
    <div className={`${props.className}`}>
      <div
        className={` stu-taa-radio-btn ${props.active ? props.disabled ? 'stu-taa-radio-btn--active--disabled' : 'stu-taa-radio-btn--active' : props.disabled ? 'stu-taa-radio-btn--active--nopointer' : ''} `}
        onClick={() => {
          if (!props.disabled) {
            props.onClick();
          }
        }}
      ></div>
    </div>
  );
};

export default StudentTAAssigmentRadioBtn;
