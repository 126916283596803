import React, {FC} from 'react';
import './AnswerCard.scss';
import renderHTML from 'react-render-html';
import {FaCheckCircle} from 'react-icons/fa';

interface Props {
  className?: string;
  answerText?: string;
  answerNo?: number;
  type: 'MCQ' | 'TF';
  name?: 'TRUE' | 'FALSE';
  isCorrect?: boolean;
}

const AnswerCard: FC<Props> = (props) => {
  const renderedText = renderHTML(props.answerText || '');

  return props.type === 'MCQ' ? (
    <div
      className={`answer-card-mcq ${props.className} justify-content-between align-items-center`}
    >
      <div>
        <div>{props.answerNo}.</div>
        <div>{renderedText}</div>
      </div>
      <div>{props.isCorrect ? <FaCheckCircle color="#20D167" size={20} /> : <></>}</div>
    </div>
  ) : (
    <div className={`answer-card-tf ${props.className}  justify-content-between`}>
      <div>{props.name}</div>
      <div>{props.isCorrect ? <FaCheckCircle color="#20D167" size={20} /> : <></>}</div>
    </div>
  );
};

export default AnswerCard;
