import React, {FC, useState} from 'react';
import {FaChevronRight, FaSearch} from 'react-icons/fa';
import {ExamToDoDTO} from '../dtos/ExamToDoDTO';
import {ExamProgressState} from '../enums/ExamProgressState';
import StudentPracticeExamFilter, {
  StudentPracticeCompletedExamFilter,
} from './StudentPracticeExamFilter';
import './StudentPractiseExamView.scss';

interface Props {
  examsList: ExamToDoDTO[];
  completedExamsList: ExamToDoDTO[];
  subjectId: string | undefined;
  isLoading: boolean;
  setSelectedExamsHandlers: (exam: ExamToDoDTO | null) => void;
}

const StudentPractiseExamView: FC<Props> = (props) => {
  const [collapseExamTabs, setCollapseExamTabs] = useState<{
    currentExamTab: boolean;
    upcomingExamTab: boolean;
    completedExamTab: boolean;
  }>({currentExamTab: true, upcomingExamTab: true, completedExamTab: true});

  const [upcomingFilter, setUpcomingFilter] = useState('');
  const [currentFilter, setCurrentFilter] = useState('');
  const [completedFilter, setCompletedFilter] = useState('');

  return (
    <div className="tab-stu-exam-practise">
      <div className="stdsearchwrapper">
        <div
          onClick={() => {
            setCollapseExamTabs((ps) => ({
              ...ps,
              currentExamTab: !collapseExamTabs.currentExamTab,
            }));
          }}
          className="tab-stu-exam-practise__current-title"
        >
          <span>Current Exams</span>{' '}
          <FaChevronRight
            style={
              collapseExamTabs.currentExamTab
                ? {transform: 'rotate(90deg)'}
                : {transform: 'rotate(0deg)'}
            }
          />
        </div>
        <div className="stdsearchbar extracurrenttop">
          <div className="search">
            <input
              type="text"
              className="searchTerm"
              value={currentFilter}
              placeholder="Search"
              onChange={(e) => {
                setCurrentFilter(e.target.value);
              }}
            />
            <button type="submit" className="searchButton">
              <div className="stdsearch__icon">
                <FaSearch color="#fff" size={15} />
              </div>
            </button>
          </div>
        </div>
      </div>

      {collapseExamTabs.currentExamTab && (
        <StudentPracticeExamFilter
          className={'tab-stu-exam-practise__current-cards margin-top-1rem'}
          examsList={props.examsList}
          isLoading={props.isLoading}
          type="CURRENT"
          setSelectedExamsHandlers={props.setSelectedExamsHandlers}
          examState={ExamProgressState.IN_PROGRESS}
          filterValue={currentFilter}
        />
      )}
      <div className="stdsearchwrapper">
        <div
          onClick={() => {
            setCollapseExamTabs((ps) => ({
              ...ps,
              upcomingExamTab: !collapseExamTabs.upcomingExamTab,
            }));
          }}
          className="tab-stu-exam-practise__upcoming-title"
        >
          <span>Upcoming Exams</span>{' '}
          <FaChevronRight
            style={
              collapseExamTabs.upcomingExamTab
                ? {transform: 'rotate(90deg)'}
                : {transform: 'rotate(0deg)'}
            }
          />
        </div>
        <div className="stdsearchbar">
          <div className="search">
            <input
              type="text"
              className="searchTerm"
              value={upcomingFilter}
              placeholder="Search"
              onChange={(e) => {
                setUpcomingFilter(e.target.value);
              }}
            />
            <button type="submit" className="searchButton">
              <div className="stdsearch__icon">
                <FaSearch color="#fff" size={15} />
              </div>
            </button>
          </div>
        </div>
      </div>
      {collapseExamTabs.upcomingExamTab && (
        <StudentPracticeExamFilter
          className={'tab-stu-exam-practise__upcoming-cards'}
          examsList={props.examsList}
          isLoading={props.isLoading}
          type="UPCOMING"
          setSelectedExamsHandlers={props.setSelectedExamsHandlers}
          examState={ExamProgressState.UPCOMING}
          filterValue={upcomingFilter}
        />
      )}
      <div className="stdsearchwrapper">
        <div
          onClick={() => {
            setCollapseExamTabs((ps) => ({
              ...ps,
              completedExamTab: !collapseExamTabs.completedExamTab,
            }));
          }}
          className="tab-stu-exam-practise__completed-title"
        >
          <span>Completed Exams</span>{' '}
          <FaChevronRight
            style={
              collapseExamTabs.completedExamTab
                ? {transform: 'rotate(90deg)'}
                : {transform: 'rotate(0deg)'}
            }
          />
        </div>
        <div className="stdsearchbar">
          <div className="search">
            <input
              type="text"
              className="searchTerm"
              value={completedFilter}
              placeholder="Search"
              onChange={(e) => {
                setCompletedFilter(e.target.value);
              }}
            />
            <button type="submit" className="searchButton">
              <div className="stdsearch__icon">
                <FaSearch color="#fff" size={15} />
              </div>
            </button>
          </div>
        </div>
      </div>
      {collapseExamTabs.completedExamTab && (
        <StudentPracticeCompletedExamFilter
          className={'tab-stu-exam-practise__completed-cards'}
          examsList={props.completedExamsList}
          isLoading={props.isLoading}
          type="COMPLETED"
          setSelectedExamsHandlers={props.setSelectedExamsHandlers}
          examState={ExamProgressState.COMPLETED}
          filterValue={completedFilter}
        />
      )}
    </div>
  );
};

export default StudentPractiseExamView;
