import React, {FC} from 'react';
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa';
import './InstructorBadge.scss';

interface Props {
  userType?: {text: string; validated: boolean | undefined | null};
}

const InstructorBadge: FC<Props> = (props) => {
  let userRoleContent = null;

  if (props.userType?.text === 'Instructor') {
    if (props.userType.validated) {
      userRoleContent = (
        <div className="d-flex">
          <div className="role-badge-text">{props.userType?.text}</div>
          <div className="role-badge-icon">
            <FaCheckCircle
              size={15}
              color="#269553"
              className="position-absolute instructor-verified-tick"
            />
          </div>
        </div>
      );
    } else {
      userRoleContent = (
        <div className="d-flex">
          <div className="role-badge-text">{props.userType?.text}</div>
          <div className="role-badge-icon">
            <FaTimesCircle
              size={15}
              color="#9D4141"
              className="position-absolute instructor-verified-tick"
            />
          </div>
        </div>
      );
    }
  } else if (props.userType?.text === 'Teaching Assistant') {
    if (props.userType.validated) {
      userRoleContent = (
        <div className="d-flex">
          <div className="role-badge-text">{props.userType?.text}</div>
          <div className="role-badge-icon">
            <FaCheckCircle
              size={15}
              color="#269553"
              className="position-absolute instructor-verified-tick"
            />
          </div>
        </div>
      );
    } else {
      userRoleContent = (
        <div className="d-flex">
          <div className="role-badge-text">{props.userType?.text}</div>
          <div className="role-badge-icon">
            <FaTimesCircle
              size={15}
              color="#9D4141"
              className="position-absolute instructor-verified-tick"
            />
          </div>
        </div>
      );
    }
  } else {
    userRoleContent = <div className="role-badge-text">{props.userType?.text}</div>;
  }

  return userRoleContent;
};

export default InstructorBadge;
