import React, { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { EXAM_CREATION_STATE } from '../interfaces';
import {
  getModifiedLessonAndSubjectOptions,
  getModifiedSubjectAndSubjectOption,
  validationResult,
} from '../utils';
import { useParams } from 'react-router-dom';
import { useSubjects } from '../context/SubjectsContext';
import { useLessons } from '../context/LessonsContext';
import DropDown from './DropDown';
import { GRADES } from '../constants';
import TextFieldInput from './TextFieldInput';
import TinyMCEEditor from './TinyMCEEditor';
import './ExamCreationStepOne.scss';
import { useUnits } from '../context/UnitsContext';
import SubjectDropDown from './SubjectDropDown';
import LessonDropDownMultiple from './LessonDropDownMultiple';
import UnitDropDownMultiple from './UnitDropDownMultiple';
import ButtonComp from './ButtonComp';

interface Props {
  state: EXAM_CREATION_STATE;
  hierarchyLevel: 'SUBJECT' | 'LESSON' | 'UNIT' | 'NONE';
  setState: React.Dispatch<React.SetStateAction<EXAM_CREATION_STATE>>;
  onNext: () => void;
}

const ExamCreationStepOne: FC<Props> = (props) => {
  const { state, setState } = props;

  const { subjectId, lessonId, unitId } = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
  };
  const subjects = useSubjects();
  const lessons = useLessons();
  const units = useUnits();

  const { mappedSubjects, selectedSubject, selectedGrade } = getModifiedSubjectAndSubjectOption(
    subjectId,
    subjects.allSubjects
  );

  const { mappedLessons, selectedLesson } = getModifiedLessonAndSubjectOptions(
    subjectId,
    lessons.lessons,
    lessonId
  );

  const mappUnits = units.units
    .filter((u) => props.state.lessons.value.map((v) => v.id).includes(u.lessonId))
    .map((u) => ({ displayValue: u?.name || '', id: u?.id || '' }));

  useEffect(() => {
    props.setState((ps) => {
      return {
        ...ps,
        subject: { ...ps.subject, value: selectedSubject },
        lesson: { ...ps.lesson, value: selectedLesson },
        grade: { ...ps.grade, value: selectedGrade },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjects.allSubjects, lessons.lessons]);

  useEffect(() => {
    let selectedUnits: { displayValue: string; id: string }[] = [];

    if (unitId && state.lesson.value.id) {
      const sU = units.units.filter(
        (u) => u.subjectId === subjectId && u.lessonId === state.lesson.value.id && u.id === unitId
      );

      selectedUnits = sU.map((u) => {
        return { id: u.id || '', displayValue: u.name || '' };
      });
    }

    props.setState((ps) => {
      return {
        ...ps,
        units: {
          ...ps.units,
          value: selectedUnits,
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lessons.value.length]);

  const localNextButtonHandler = () => {
    const picked = (({ grade, subject, lesson, examTitle, description }) => ({
      grade,
      subject,
      lesson,
      examTitle,
      description,
    }))(state);

    const validationOutput = validationResult({ ...picked, loading: true });

    setState({ ...state, ...validationOutput.state });

    if (
      props.state.lessons.value.length === 1 &&
      lessons.lessons.find((l) => l.id === props.state.lessons.value[0]?.id)
        ?.isLessonContainsUnits &&
      !state.units.value.length
    ) {
      return setState((ps) => {
        return {
          ...ps,
          units: { ...ps.units, error: 'The unit cannot be empty' },
        };
      });
    }

    if (validationOutput.formValidity.trim().length > 0) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    props.onNext();
  };

  useEffect(() => {
    if (props.hierarchyLevel === 'LESSON') {
      props.setState((ps) => {
        return {
          ...ps,
          lessons: {
            value: [ps.lesson.value],
            error: '',
            validations: [],
            label: 'Lesson',
            type: 'dropdown',
          },
        };
      });
    } else if (props.hierarchyLevel === 'UNIT') {
      props.setState((ps) => {
        return {
          ...ps,
          lessons: {
            value: [ps.lesson.value],
            error: '',
            validations: [],
            label: 'Lesson',
            type: 'dropdown',
          },
          units: {
            value: [ps.unit.value],
            error: '',
            validations: [],
            label: 'Units',
            type: 'dropdown',
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="pt-1 mt-2 pb-3 section-container">
        <Col>
          <Row className="mt-5">
            <Col>
              <DropDown
                name="Grade"
                noValueText="Select Grade"
                stateName="grade"
                stateValue={props.state.grade.value}
                state={props.state}
                error={props.state.grade.error}
                setState={props.setState}
                disabled
                optionsArray={GRADES}
              />
            </Col>
            <Col>
              {/* <DropDown
                name="Subject"
                noValueText="Select Subject"
                stateName="subject"
                stateValue={state.subject.value}
                error={state.subject.error}
                state={state}
                setState={setState}
                optionsArray={mappedSubjects}
                disabled
              /> */}
              <SubjectDropDown
                name="Subject"
                noValueText="Select Subject"
                stateName="subject"
                stateValue={state.subject.value}
                error={state.subject.error}
                state={state}
                setState={setState}
                optionsArray={mappedSubjects}
                disabled
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              {/* <DropDown
                name="Lesson"
                noValueText="Select Lesson"
                stateName="lesson"
                stateValue={state.lesson.value}
                state={state}
                error={state.lesson.error}
                setState={setState}
                optionsArray={mappedLessons}
                disabled={!!lessonId}
              /> */}
              {props.hierarchyLevel === 'LESSON' ||
              props.hierarchyLevel === 'SUBJECT' ||
              props.hierarchyLevel === 'UNIT' ||
              props.hierarchyLevel === 'NONE' ? (
                <LessonDropDownMultiple
                  name="Lesson"
                  noValueText="Select Lessons"
                  stateName="lessons"
                  stateValue={state.lessons.value}
                  state={state}
                  error={state.lessons.error}
                  setState={setState}
                  optionsArray={mappedLessons}
                  IgnoreUnits
                  truncate={30}
                  lessonLimit={true}
                  disabled={props.hierarchyLevel === 'LESSON' || props.hierarchyLevel === 'UNIT'}
                />
              ) : (
                <></>
              )}
            </Col>
            <Col xs={4}>
              {/* <DropDown
                name="Unit"
                noValueText="Select unit"
                stateName="lesson"
                stateValue={state.unit.value}
                state={state}
                error={state.unit.error}
                setState={setState}
                optionsArray={mappedLessons}
                disabled={!!unitId}
              /> */}
              {props.state.lessons.value.length === 1 &&
              lessons.lessons.find((l) => l.id === props.state.lessons.value[0]?.id)
                ?.isLessonContainsUnits ? (
                <UnitDropDownMultiple
                  name="Units"
                  noValueText="Select units"
                  stateName="units"
                  stateValue={state.units.value}
                  state={state}
                  error={state.units.error}
                  setState={setState}
                  optionsArray={mappUnits}
                  disabled={props.hierarchyLevel === 'UNIT'}
                  truncate={30}
                  unitLimit={true}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <TextFieldInput
                name="Exam Title"
                stateName="examTitle"
                stateValue={state.examTitle.value}
                state={state}
                setState={setState}
                error={state.examTitle.error}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="pt-4 section-title">Description</div>
              <TinyMCEEditor
                onFocus={() => {
                  setState({
                    ...state,
                    description: { ...state.description, error: '' },
                  });
                }}
                error={state.description.error}
                value={state.description.value}
                onChange={(value) => {
                  setState({
                    ...state,
                    description: {
                      ...state.description,
                      value: value,
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              {/* <AddMaterialComp
                onRemoveMaterial={onRemoveMaterial}
                materials={state.materials.materials}
                onAddMaterial={onAddMaterial}
              /> */}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="position-absolute bottom-0 button-container">
        <Col>
          <ButtonComp type="one" text="Next" onClick={localNextButtonHandler} />
        </Col>
      </Row>
    </>
  );
};

export default ExamCreationStepOne;
