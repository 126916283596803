import React, {FC, useEffect, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import './MarksGraph.scss';
import YouLabel from './YouLabel';

interface Props {
  list?: {percentage: number; count: number}[];
  youTag?: number;
}

const MarksGraph: FC<Props> = (props) => {
  const [state, setState] = useState<
    {
      percentage: number;
      count: number;
      label: JSX.Element;
      youTag?: boolean | undefined;
    }[]
  >([]);

  const arrayOne: {percentage: number; count: number; label: JSX.Element; youTag?: boolean}[] = [
    {
      percentage: 0,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">0-</span>
          <span>10</span>
        </>
      ),
      youTag: false,
    },
    {
      percentage: 10,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">11-</span>
          <span>20</span>
        </>
      ),
      youTag: false,
    },
    {
      percentage: 20,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">21-</span>
          <span>30</span>
        </>
      ),
      youTag: false,
    },
    {
      percentage: 30,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">31-</span>
          <span>40</span>
        </>
      ),
      youTag: false,
    },
    {
      percentage: 60,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">41-</span>
          <span>50</span>
        </>
      ),
      youTag: false,
    },
    {
      percentage: 70,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">51-</span>
          <span>60</span>
        </>
      ),
      youTag: true,
    },
    {
      percentage: 100,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">61-</span>
          <span>70</span>
        </>
      ),
      youTag: false,
    },
    {
      percentage: 40,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">71-</span>
          <span>80</span>
        </>
      ),
      youTag: false,
    },
    {
      percentage: 20,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">81-</span>
          <span>90</span>
        </>
      ),
      youTag: false,
    },
    {
      percentage: 10,
      count: 0,
      label: (
        <>
          <span className="mrk-dist-graph__range-segment">91-</span>
          <span>100</span>
        </>
      ),
      youTag: false,
    },
  ];

  useEffect(() => {
    props.list &&
      props.list.forEach(({percentage, count}, idx) => {
        arrayOne[idx].percentage = percentage;
        arrayOne[idx].count = count;
      });

    setState(arrayOne);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.list]);

  return (
    <div className="mrk-dist-graph">
      {state.map((v, idx) => {
        let color = '#E06B6B';

        if (idx === 5) {
          color = '#FFA95A';
        }

        if (idx === 6 || idx === 7) {
          color = '#FCFE8A';
        }

        if (idx === 8 || idx === 9 || idx === 10) {
          color = '#62FFA1';
        }

        return (
          <div className="mrk-dist-graph__bar-container" key={idx}>
            {props.youTag === idx && <YouLabel />}
            <OverlayTrigger placement="top" overlay={<Tooltip id="verifiedId">{v.count}</Tooltip>}>
              <div
                className={`mrk-dist-graph__bar ${`mrk-dist-graph__bar--${Math.trunc(
                  v.percentage
                )}`}`}
                style={{backgroundColor: color}}
              ></div>
            </OverlayTrigger>

            <div className="mrk-dist-graph__range">{v.label}</div>
          </div>
        );
      })}
    </div>
  );
};

export default MarksGraph;
