import React, { FC } from 'react';
import { FaCircle, FaFilter, FaPlusCircle } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import { EXAM_TYPE_TABS } from '../enums';
import { DropDown, FilterBucket, SUBJECT_DIRECTIVE_STATE_FILTER_OPTIONS } from '../interfaces';
import { Exam } from '../models/Exam';
import Button from '../shared/Button';
import { filterExams, getButtonTextAndTab, getExamTypeQueryParam } from '../utils';
import './ExamBody.scss';
import ExamCard from './ExamCard';
import SearchWidget from './SearchWidget';
import SubjectDirectiveFilterBox from './SubjectDirectiveFilterBox';
import SwitchBtn from './SwitchBtn';

interface Props {
  tab?: EXAM_TYPE_TABS;
  publishedList: Exam[];
  draftList: Exam[];
  isLoading?: boolean;
  filterOptions?: SUBJECT_DIRECTIVE_STATE_FILTER_OPTIONS;
  filterBucket?: FilterBucket;
  isOpenFilterBox?: boolean;
  isApplyFiltersOn?: boolean;
  hierarchyLevel?: 'LESSON' | 'UNIT';
  noUnitsPresent?: boolean;
  subjectId?: string;
  lessonId?: string;
  unitId?: string;
  onHideGradedExams?: (showIsGraded: boolean) => void;
  searchHander?: (value: string) => void;
  openFilterHander?: (value: boolean) => void;
  clearFiltersHandler?: () => void;
  applyFiltersHandler?: () => void;
  onSelectLessonFilterHandler?: (value: DropDown) => void;
  onSelectUnitFilterHandler?: (value: DropDown) => void;
}

const ExamBody: FC<Props> = (props) => {
  const { subjectId, lessonId, unitId } = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
  };

  const { EXTRA, LIVE, PRACTISE } = EXAM_TYPE_TABS;
  const { text, tab } = getButtonTextAndTab(props.tab);
  const history = useHistory();

  return (
    <div className="exam-body">
      <div className="exam-body__filters">
        <SearchWidget
          onSearch={props.searchHander ? props.searchHander : () => {}}
          placeHolderText="Search Exams"
          data={props.tab}
        />
        <Button
          text="Filters"
          onClick={() => {
            props.openFilterHander && props.openFilterHander(true);
          }}
          icon={{ icon: FaFilter, isLeft: false, size: 15, gap: 10 }}
          className="ml-3"
        />
        <SubjectDirectiveFilterBox
          open={props.isOpenFilterBox}
          selectedLesson={props.filterOptions?.lesson}
          selectedUnit={props.filterOptions?.unit}
          openFilterHander={props.openFilterHander}
          clearFiltersHandler={props.clearFiltersHandler}
          applyFiltersHandler={props.applyFiltersHandler}
          onSelectLessonFilterHandler={props.onSelectLessonFilterHandler}
          onSelectUnitFilterHandler={props.onSelectUnitFilterHandler}
          hierarchyLevel={props.hierarchyLevel}
        />
        <Button
          text={text}
          onClick={() => {
            if (!props.hierarchyLevel) {
              if (tab === EXTRA) {
                history.push(`/my-subjects/subject/${subjectId}/create-extra-exam`);
              } else if (tab === LIVE) {
                history.push(`/my-subjects/subject/${subjectId}/create-live-exam`);
              } else if (tab === PRACTISE) {
                history.push(`/my-subjects/subject/${subjectId}/create-practise-exam`);
              }
            } else if (props.hierarchyLevel === 'LESSON') {
              if (tab === EXTRA) {
                if (props.noUnitsPresent) {
                  history.push(
                    `/my-subjects/subject/${subjectId || props.subjectId}/lesson/${
                      lessonId || props.lessonId
                    }/Content/Examinations/create-extra-exam/Examinations`
                  );
                } else {
                  history.push(
                    `/my-subjects/subject/${subjectId}/lesson/${lessonId}/create-extra-exam/Examinations`
                  );
                }
              } else if (tab === LIVE) {
                if (props.noUnitsPresent) {
                  history.push(
                    `/my-subjects/subject/${subjectId || props.subjectId}/lesson/${
                      lessonId || props.lessonId
                    }/Content/Examinations/create-live-exam/Examinations`
                  );
                } else {
                  history.push(
                    `/my-subjects/subject/${subjectId}/lesson/${lessonId}/create-live-exam/Examinations`
                  );
                }
              } else if (tab === PRACTISE) {
                if (props.noUnitsPresent) {
                  history.push(
                    `/my-subjects/subject/${subjectId || props.subjectId}/lesson/${
                      lessonId || props.lessonId
                    }/Content/Examinations/create-practise-exam/Examinations`
                  );
                } else {
                  history.push(
                    `/my-subjects/subject/${subjectId}/lesson/${lessonId}/create-practise-exam/Examinations`
                  );
                }
              }
            } else if (props.hierarchyLevel === 'UNIT') {
              if (tab === EXTRA) {
                history.push(
                  `/my-subjects/subject/${subjectId}/lesson/${lessonId}/unit/${unitId}/create-extra-exam/Examinations`
                );
              } else if (tab === LIVE) {
                history.push(
                  `/my-subjects/subject/${subjectId}/lesson/${lessonId}/unit/${unitId}/create-live-exam/Examinations`
                );
              } else if (tab === PRACTISE) {
                history.push(
                  `/my-subjects/subject/${subjectId}/lesson/${lessonId}/unit/${unitId}/create-practise-exam/Examinations`
                );
              }
            }
          }}
          icon={{ icon: FaPlusCircle, isLeft: true, size: 15, gap: 10 }}
          className="ml-auto"
        />
      </div>
      <div className="exam-body__column-titles">
        <div className={`exam-body__date ${props.tab === EXTRA && 'exam-body__date--extra'}`}>
          {props.tab === EXTRA ? 'Title' : 'Date'}
        </div>
        <div className={`exam-body__title ${props.tab === EXTRA && 'd-none'}`}>Title</div>
        <div className={`exam-body__type  ${props.tab === EXTRA && 'exam-body__type--extra'}`}>
          Type
        </div>
      </div>
      <div className="exam-body__lists">
        <div className="exam-body__published">
          <div className="exam-body__published-header">
            <div className="exam-body__p-text">Published</div>
            <div className="exam-body__switch">
              Hide graded exams
              <SwitchBtn
                checked={props.filterOptions?.isHideGradedExams}
                changeHandler={() => {
                  props.onHideGradedExams &&
                    props.onHideGradedExams(!props.filterOptions?.isHideGradedExams);
                }}
                loading={props.isLoading}
                className="ml-2"
                lableClassName="label-2"
              />
            </div>
            <div className="exam-body__metrics">
              <div className="exam-body__graded">
                <FaCircle className="mr-1" color="#1fa35A" />
                Graded
              </div>
              <div className="exam-body__to-review">
                <FaCircle className="mr-1" color="#FD9716" />
                To Review
              </div>
              <div className="exam-body__not-submitted">
                <FaCircle className="mr-1" />
                Not Submitted
              </div>
            </div>
          </div>
          <div className="exam-body__published-list">
            {filterExams(
              props.publishedList,
              props.filterOptions?.isHideGradedExams,
              props.isApplyFiltersOn,
              props.filterBucket,
              props.filterOptions?.searchText
            )?.map((exam, idx) => (
              <ExamCard
                exam={exam}
                onClick={() => {
                  if (props.hierarchyLevel === 'LESSON') {
                    history.push(
                      `/my-subjects/subject/${subjectId || props.subjectId}/lesson/${
                        lessonId || props.lessonId
                      }/Examinations/${exam.id || ''}/dashboard${getExamTypeQueryParam(
                        exam.examType
                      )}`
                    );
                  } else if (props.hierarchyLevel === 'UNIT') {
                    history.push(
                      `/my-subjects/subject/${subjectId || props.subjectId}/lesson/${
                        lessonId || props.lessonId
                      }/unit/${unitId}/Examinations/${
                        exam.id || ''
                      }/dashboard${getExamTypeQueryParam(exam.examType)}`
                    );
                  } else {
                    history.push(
                      `/my-subjects/subject/${subjectId || props.subjectId}/Examinations/${
                        exam.id || ''
                      }/dashboard${getExamTypeQueryParam(exam.examType)}`
                    );
                  }
                }}
                key={idx}
              />
            ))}
          </div>
        </div>
        <div className="exam-body__unpublished">
          <div className="exam-body__p-text">Unpublished</div>

          {filterExams(
            props.draftList,
            props.filterOptions?.isHideGradedExams,
            props.isApplyFiltersOn,
            props.filterBucket,
            props.filterOptions?.searchText
          )?.map((exam, idx) => (
            <ExamCard
              exam={exam}
              onClick={() => {
                if (props.hierarchyLevel === 'LESSON') {
                  history.push(
                    `/my-subjects/subject/${subjectId || props.subjectId}/lesson/${
                      lessonId || props.lessonId
                    }/Examinations/${exam.id || ''}/dashboard${getExamTypeQueryParam(
                      exam.examType
                    )}`
                  );
                } else if (props.hierarchyLevel === 'UNIT') {
                  history.push(
                    `/my-subjects/subject/${subjectId || props.subjectId}/lesson/${
                      lessonId || props.lessonId
                    }/unit/${unitId}/Examinations/${exam.id || ''}/dashboard${getExamTypeQueryParam(
                      exam.examType
                    )}`
                  );
                } else {
                  history.push(
                    `/my-subjects/subject/${subjectId}/Examinations/${
                      exam.id || ''
                    }/dashboard${getExamTypeQueryParam(exam.examType)}`
                  );
                }
              }}
              key={idx}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamBody;
