import React, {FC} from 'react';
import './AddSubjectBtn.scss';

interface Props {
  text: string;
  className?: string;
  icon?: any;
  onClick: () => void;
}

const AddSubjectBtn: FC<Props> = (props) => {
  return (
    <button className={`add-subject-btn  ${props.className}`} onClick={props.onClick}>
      {props.icon && <props.icon size={16} color={'#fff'} />}
      <span> {props.text}</span>
    </button>
  );
};

export default AddSubjectBtn;
