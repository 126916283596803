enum EXAM_TYPE_TABS {
  LIVE = 'live',
  PRACTISE = 'practise',
  EXTRA = 'extra',
}

enum HIERARCHY {
  SUBJECT,
  LESSON,
  UNIT,
  MATERIAL,
}

enum EXAM_CREATION_STEP {
  NONE,
  ONE,
  TWO,
  THREE,
}

enum CHECKBOXSTATUS {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  NONE = 'NONE',
  DISABLED = 'DISABLED',
}

enum StatusOfPaper {
  NOT_UPLOADED = 'NOT UPLOADED',
  RELEASED = 'RELEASED',
  RELEASED_ASSIGNED = 'RELEASED ASSIGNED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  NOT_MARKED = 'NOT MARKED',
  PENDING = 'PENDING',
  NOT_MARKED_NOT_ASSIGNED = 'NOT MARKED',
  ASSIGNED = 'ASSIGNED',
  MARKED_NOT_ASSIGNED = 'MARKED',
  MARKED_BY_TA = 'MARKED BY TA',
}

enum PACKAGE_TYPE_TABS {
  CURRENT_PACKAGES = 'CURRENT PACKAGES',
  EXPIRED_PACKAGES = 'EXPIRED PACKAGES',
  MY_PACKAGES = 'MY PACKAGES',
  ALL_PACKAGES = 'ALL PACKAGES',
}

export {
  EXAM_TYPE_TABS,
  HIERARCHY,
  EXAM_CREATION_STEP,
  CHECKBOXSTATUS,
  StatusOfPaper,
  PACKAGE_TYPE_TABS,
};
