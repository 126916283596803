import './TAPaymentCard.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React from 'react';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';

interface TAPaymentPaperInfo {
  paperId: string;
  paperTitle: string;
  totalPayment: number;
  pendingPayment: number;
  totalMarkedAndFree: number;
  totalPending: number;
  totalRejected: number;
  totalPayedAmount: number;
  paperPayment?: { amount: number; isFree: boolean };
  totalPayedPapers: number;
  totalAssigned: number;
  notMarked: number;
  totalApproved: number;
}

interface TAPaymentInfo {
  taUid: string;
  papers: TAPaymentPaperInfo[];
}

interface IProps {
  taPayment: TAPaymentInfo;
  onClickPay: (taUid: string) => void;
}

export const TAPaymentsCard = (props: IProps) => {
  const appUser = useAdminsAndInstructors();

  const trancate = (str: string, length: number) => {
    return str.length > length ? str.substring(0, length) + '...' : str;
  };

  const PaymentAmount = (props: {
    amount: number;
    approved: number;
    isFree?: boolean;
    payedAmount?: number;
  }) => {
    return (
      <div className="ta-payments-card__payment-amount d-flex justify-content-center">
        <div>
          {props.amount > 0 ? (
            <>
              <div className="ta-payments-card__payment-amount-value">Rs. {props.amount}</div>
              <div className="ta-payments-card__payment-amount-label">
                Paper Count: {props.approved}
              </div>
            </>
          ) : props.isFree ? (
            <>
              <div className="ta-payments-card__payment-amount-value">FREE</div>
            </>
          ) : (
            <>
              <div className="ta-payments-card__payment-amount-value text-success">
                {props.payedAmount ? (
                  <div>
                    Paid <br></br>
                    {props.payedAmount}
                  </div>
                ) : (
                  <span
                    style={{
                      color: '#246bfd',
                    }}
                  >
                    {'-'}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
  const getTotalPayment = (papers: any[]) => {
    let total = 0;
    papers.forEach((paper) => {
      total += paper.totalPayment;
    });
    return total;
  };
  const completedPayments = () => {
    let total = 0;
    props.taPayment.papers.forEach((paper) => {
      total += paper.totalPayedAmount;
    });
    return total;
  };

  const getPendingPaymentsPaperCount = () => {
    let total = 0;
    props.taPayment.papers.forEach((paper) => {
      total = total + paper.pendingPayment;
    });
    return total;
  };
  const getTotalPayedPaperCount = () => {
    let total = 0;
    props.taPayment.papers.forEach((paper) => {
      total = total + paper.totalPayedPapers;
    });
    return total;
  };

  return (
    <tr className="ta-payments-card">
      <td className="ta-payments-card__name">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="verifiedId">
              {
                appUser.allAdminsAndInstructors.find((user) => {
                  return user.uid === props.taPayment.taUid;
                })?.username
              }
            </Tooltip>
          }
        >
          <div>
            {trancate(
              `${
                appUser.allAdminsAndInstructors.find((user) => {
                  return user.uid === props.taPayment.taUid;
                })?.username
              }` || '',
              30
            )}
          </div>
        </OverlayTrigger>
      </td>
      {props.taPayment.papers.map((paper, idx) => (
        <td key={idx}>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="verifiedId">
                <div>Approved : {paper.totalApproved}</div>
                <div>Pending : {paper.totalPending}</div>
                <div>Rejected : {paper.totalRejected}</div>
                <div>
                  Not Marked : {paper.notMarked}
                  {/* Equation is total assigned - (total pending + total rejected + total approved) */}
                </div>
                <br />
                <div>Total Assigned : {paper.totalAssigned}</div>
                <br />
              </Tooltip>
            }
          >
            {getTotalPayment(props.taPayment.papers) > 0 || completedPayments() < 1 ? (
              paper.totalAssigned > 0 ? (
                <div>
                  <PaymentAmount
                    amount={paper.totalPayment}
                    approved={paper.pendingPayment}
                    isFree={paper.paperPayment?.isFree}
                    payedAmount={paper.totalPayedAmount}
                  />
                </div>
              ) : (
                <div>
                  <PaymentAmount amount={0} approved={0} payedAmount={paper.totalPayedAmount} />
                </div>
              )
            ) : paper.paperPayment?.isFree ? (
              <div style={{ fontSize: 12 }} className="text-white text-center">
                FREE
              </div>
            ) : paper.totalAssigned > 0 && paper.totalPayedPapers > 0 ? (
              <div style={{ fontSize: 12 }} className="text-white text-center">
                PAID
              </div>
            ) : (
              <div style={{ fontSize: 12 }} className="text-success text-center">
                {paper.totalPayedAmount ? (
                  <div>
                    Paid <br></br>
                    {paper.totalPayedAmount}
                  </div>
                ) : (
                  '-'
                )}
              </div>
            )}
          </OverlayTrigger>
        </td>
      ))}
      <td>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="verifiedId">
              {props.taPayment.papers.map((p, idx) => (
                <div key={idx}>
                  {p.paperTitle} : {p.pendingPayment}
                </div>
              ))}
            </Tooltip>
          }
        >
          <div>
            <PaymentAmount
              amount={getTotalPayment(props.taPayment.papers)}
              approved={getPendingPaymentsPaperCount()}
            />
          </div>
        </OverlayTrigger>
      </td>

      <td className="d-flex justify-content-center">
        <div className="ta-payments-card--btn-container">
          {getTotalPayment(props.taPayment.papers) > 0 || completedPayments() > 0 ? (
            <button
              className={`ta-payments-card--btn-pay`}
              disabled={getTotalPayment(props.taPayment.papers) < 1}
              onClick={() => props.onClickPay(props.taPayment.taUid)}
            >
              <div className="ta-payments-card--btn-pay-label">
                {getTotalPayment(props.taPayment.papers) < 1 ? (
                  <>
                    <div style={{ fontSize: 10, lineHeight: '15px', margin: '3px 0px' }}>
                      Payment Completed
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      margin: '2px 2px',
                    }}
                  >
                    <div style={{ fontSize: 12, marginTop: 2 }}>Pay</div>
                    <div style={{ fontSize: 12, marginTop: -2, marginBottom: 2 }}>
                      RS. {getTotalPayment(props.taPayment.papers)}
                    </div>
                  </div>
                )}
              </div>
            </button>
          ) : (
            <span className="text-white"> - </span>
          )}
        </div>
      </td>
      <td>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="verifiedId">
              {props.taPayment.papers.map((p, idx) => (
                <div key={idx}>
                  {p.paperTitle}:{p.totalPayedPapers}
                </div>
              ))}
            </Tooltip>
          }
        >
          <div>
            <PaymentAmount amount={completedPayments()} approved={getTotalPayedPaperCount()} />
          </div>
        </OverlayTrigger>
      </td>
    </tr>
  );
};
