import { useRef, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './StudentSubjectHomeView.scss';

const StudentSubjectHomeView = () => {
  let squares = [];

  for (let i = 1; i < 365; i++) {
    squares.push(<li key={i} data-level={Math.floor(Math.random() * 3)}></li>);
  }

  const sliderOffsetLeft = useRef<HTMLDivElement>(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeftState, setScrollLeftState] = useState(0);
  const [grabStyle, setGrabStyle] = useState({ cursor: 'grab' });

  const [isDownGraph, setIsDownGraph] = useState(false);
  const [startXGraph, setStartXGraph] = useState(0);
  const [scrollLeftStateGraph, setScrollLeftStateGraph] = useState(0);
  const [grabStyleGraph, setGrabStyleGraph] = useState({ cursor: 'grab' });
  const sliderOffsetLeftGraph = useRef<HTMLDivElement>(null);

  return (
    <>
      <Container fluid>
        <Row>
          <h1
            className="stu-subject-home_title"
            style={{ marginBottom: '4rem', marginTop: '.5rem' }}
          >
            History of Information Technology
          </h1>
        </Row>
        <Row>
          <h2 className="stu-subject-home_progress-title">45% Lessons Completed</h2>
        </Row>
        <Row style={{ marginBottom: '1.5rem', marginTop: '-.5rem' }}>
          <h4 className="stu-subject-home_progress-text">Continue learning from recent lessons</h4>
        </Row>
        <Row style={{ maxWidth: '1360px' }}>
          <Col style={{ padding: '0' }} md="4" xs="2" lg="1">
            <img
              className="stu-subject-home_Card-image"
              alt="Subject"
              src="https://picsum.photos/seed/picsum/200/300"
            />
          </Col>
          <Col style={{ padding: '0' }} md="8" xs="10" lg="10">
            <Row className="stu-subject-home_Card-context" xs={1} md={2}>
              <Col xl={9} md={8}>
                <h4 className="stu-subject-home_Card-title">
                  Fields of Information Technology Introduction
                </h4>
                <p className="stu-subject-home_Card-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam inventore modi
                  voluptatum numquam ea quisquam vitae non quod error distinctio! Sint repellat
                </p>
              </Col>
              <Col xl={3} md={4}>
                <Button className="stu-subject-home_Card-btn">Continue</Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <h2
            className="stu-subject-home_progress-title"
            style={{ marginTop: '4rem', marginBottom: '1.5rem' }}
          >
            Progress
          </h2>
        </Row>
        <Row>
          <div
            style={grabStyle}
            className="progress-container"
            onTouchStart={(e) => {
              setIsDown(true);
              if (sliderOffsetLeft && sliderOffsetLeft.current) {
                const { offsetLeft, scrollLeft } = sliderOffsetLeft.current;
                setStartX(e.touches[0].pageX - offsetLeft);
                setScrollLeftState(scrollLeft);
              }
            }}
            onTouchMove={(e) => {
              if (!isDown) return;

              if (sliderOffsetLeft && sliderOffsetLeft.current) {
                const { offsetLeft } = sliderOffsetLeft.current;
                const x = e.touches[0].pageX - offsetLeft;
                const dist = x - startX;
                sliderOffsetLeft.current.scrollLeft = scrollLeftState - dist;
              }
            }}
            onTouchEnd={(e) => {
              setIsDown(false);
            }}
            onMouseMove={(e) => {
              if (!isDown) return;

              if (sliderOffsetLeft && sliderOffsetLeft.current) {
                const { offsetLeft } = sliderOffsetLeft.current;
                const x = e.pageX - offsetLeft;
                const dist = x - startX;
                sliderOffsetLeft.current.scrollLeft = scrollLeftState - dist;
              }
            }}
            onMouseDown={(e) => {
              setIsDown(true);
              setGrabStyle({ cursor: 'grabbing' });
              if (sliderOffsetLeft && sliderOffsetLeft.current) {
                const { offsetLeft, scrollLeft } = sliderOffsetLeft.current;
                setStartX(e.pageX - offsetLeft);
                setScrollLeftState(scrollLeft);
              }
            }}
            onMouseUp={() => {
              setIsDown(false);
              setGrabStyle({ cursor: 'grab' });
            }}
            ref={sliderOffsetLeft}
          >
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 1</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 2</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 3</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 4</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 5</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 6</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 7</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 8</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 9</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 10</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 11</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle incomplete">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 12</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle incomplete">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 13</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle incomplete">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 14</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle incomplete">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 15</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle incomplete">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 16</h1>
            </div>
            <div className="progress-content">
              <div className="progress-circle incomplete">
                <div className="progress-checkmark"></div>
              </div>
              <h1 className="progress-text">Lesson 17</h1>
            </div>
          </div>
        </Row>
        <Row>
          <h2
            className="stu-subject-home_progress-title"
            style={{ marginTop: '3rem', marginBottom: '1.5rem' }}
          >
            Upcoming Events
          </h2>
        </Row>
        <Row
          className="stu-subject-home_exam-card align-content-center"
          style={{ maxWidth: '1100px' }}
        >
          <Col md="5" xs="4">
            <div className="stu-subject-home_exam-card_start-date">
              Start&nbsp;<span>25 / 10 / 2021</span>&nbsp;12.30 pm
            </div>
            <div className="stu-subject-home_exam-card_end-date">
              End &nbsp;<span> 25 / 10 / 2021</span>&nbsp;12.30 pm
            </div>
            <div className="stu-subject-home_exam-card_mobile-date">25 / 10 / 2021</div>
            <div className="stu-subject-home_exam-card_mobile-time">10.30 am - 12.30 pm</div>
          </Col>
          <Col md="5" xs="6">
            ICT Introduction Exam 01{' '}
          </Col>
          <Col md="2" xs="2">
            MCQ{' '}
            <div className="stu-subject-home_exam-card_icon">
              <svg
                width="11"
                height="16"
                viewBox="0 0 11 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.1588 8.84526L3.57599 15.6474C3.12101 16.1175 2.38529 16.1175 1.93514 15.6474L0.841239 14.517C0.386254 14.0469 0.386253 13.2867 0.841239 12.8215L5.50726 8L0.841239 3.17849C0.386253 2.70835 0.386253 1.94811 0.841238 1.48296L1.93514 0.352611C2.39012 -0.117537 3.12585 -0.117537 3.57599 0.35261L10.1588 7.15474C10.6137 7.61488 10.6137 8.37512 10.1588 8.84526Z"
                  fill="#687674"
                />
              </svg>
            </div>
          </Col>
        </Row>
        <Row>
          <h2
            className="stu-subject-home_progress-title"
            style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
          >
            Learning Activities
          </h2>
        </Row>
        <Row>
          <div
            className="graph"
            style={grabStyleGraph}
            ref={sliderOffsetLeftGraph}
            onMouseMove={(e) => {
              if (!isDownGraph) return;

              if (sliderOffsetLeftGraph && sliderOffsetLeftGraph.current) {
                const { offsetLeft } = sliderOffsetLeftGraph.current;
                const x = e.pageX - offsetLeft;
                const dist = x - startXGraph;
                sliderOffsetLeftGraph.current.scrollLeft = scrollLeftStateGraph - dist;
              }
            }}
            onMouseDown={(e) => {
              setIsDownGraph(true);
              setGrabStyleGraph({ cursor: 'grabbing' });
              if (sliderOffsetLeftGraph && sliderOffsetLeftGraph.current) {
                const { offsetLeft, scrollLeft } = sliderOffsetLeftGraph.current;
                setStartXGraph(e.pageX - offsetLeft);
                setScrollLeftStateGraph(scrollLeft);
              }
            }}
            onMouseUp={() => {
              setIsDownGraph(false);
              setGrabStyleGraph({ cursor: 'grab' });
            }}
            onTouchMove={(e) => {
              if (!isDownGraph) return;

              if (sliderOffsetLeftGraph && sliderOffsetLeftGraph.current) {
                const { offsetLeft } = sliderOffsetLeftGraph.current;
                const x = e.touches[0].pageX - offsetLeft;
                const dist = x - startXGraph;
                sliderOffsetLeftGraph.current.scrollLeft = scrollLeftStateGraph - dist;
              }
            }}
            onTouchStart={(e) => {
              setIsDownGraph(true);
              setGrabStyleGraph({ cursor: 'grabbing' });
              if (sliderOffsetLeftGraph && sliderOffsetLeftGraph.current) {
                const { offsetLeft, scrollLeft } = sliderOffsetLeftGraph.current;
                setStartXGraph(e.touches[0].pageX - offsetLeft);
                setScrollLeftStateGraph(scrollLeft);
              }
            }}
            onTouchEnd={() => {
              setIsDownGraph(false);
              setGrabStyleGraph({ cursor: 'grab' });
            }}
          >
            <ul className="months">
              <li>Jan</li>
              <li>Feb</li>
              <li>Mar</li>
              <li>Apr</li>
              <li>May</li>
              <li>Jun</li>
              <li>Jul</li>
              <li>Aug</li>
              <li>Sep</li>
              <li>Oct</li>
              <li>Nov</li>
              <li>Dec</li>
            </ul>
            <ul className="squares">{squares.map((value) => value)}</ul>
            <p className="graph-text" style={{ left: '3rem' }}>
              Longest Streak: 1 week
            </p>
            <p className="graph-text" style={{ left: '14rem' }}>
              Current Streak: 1 week
            </p>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default StudentSubjectHomeView;
