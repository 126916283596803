import React, {FC, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useFirestore} from 'reactfire';
import {EXAM_CREATION_STATE} from '../interfaces';
import {arraysEqual, getModifiedSubjectAndSubjectOption} from '../utils';
import FilterCheckBox from './FilterCheckBox';
import {useParams} from 'react-router-dom';
import {PaperTypes} from '../enums/paperTypes';
import SearchBox from './SearchBox';
import Notification from './../components/Notification';
import {useSubjects} from '../context/SubjectsContext';
import {PaperStatus} from '../enums/PaperStatus';
import DragAndDrop from './DragAndDrop';
import {Paper} from '../models/Paper';
import ButtonComp from './ButtonComp';
interface Props {
  state: EXAM_CREATION_STATE;
  setState: React.Dispatch<React.SetStateAction<EXAM_CREATION_STATE>>;
  onNext: () => void;
  onBack: () => void;
}

const ExamCreationStepTwo: FC<Props> = (props) => {
  const {ESSAY, STRUCTURED, MCQ, TRUEFALSE} = PaperTypes;

  const [localState, setLocalState] = useState<{
    papers: any[];
    filters: PaperTypes[];
    searchText: string;
    selectedPaperIds: string[];
    leftPapers: any[];
    totalMarks: number;
    durationInMinutes: number;
  }>({
    papers: [],
    filters: [ESSAY, STRUCTURED, MCQ, TRUEFALSE],
    searchText: '',
    selectedPaperIds: [],
    leftPapers: [],
    totalMarks: 0,
    durationInMinutes: 0,
  });

  const {state, setState} = props;

  const store = useFirestore();

  const subjects = useSubjects();
  const {subjectId} = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
  };
  const {selectedGrade} = getModifiedSubjectAndSubjectOption(subjectId || '', subjects.allSubjects);

  //useEffects

  useEffect(() => {
    let totalDurationInMinuets = 0;
    let totalMarks = 0;

    if (localState.papers.length > 0 && localState.selectedPaperIds.length > 0) {
      const tempPapers = localState.papers.filter((p) =>
        localState.selectedPaperIds.includes(p.id)
      );

      tempPapers.forEach((p) => {
        const alocatedTimeArr = p.allocatedTime.split(':');

        totalDurationInMinuets =
          totalDurationInMinuets +
          (parseInt(alocatedTimeArr[0]) * 60 + parseInt(alocatedTimeArr[1]));
        totalMarks = totalMarks + parseInt(p.marks);
      });

      // console.log('TD ', totalDurationInMinuets);
      // console.log('TM ', totalMarks);

      setDurationAndTotalMarks(totalDurationInMinuets, totalMarks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.papers, localState.selectedPaperIds]);

  // console.log('LESSON ID ', state.lesson.value.id);

  useEffect(() => {
    const papers: any[] = [];

    if (selectedGrade.id !== '') {
      setState((ps) => {
        return {
          ...ps,
          isLoading: {value: true},
        };
      });

      let qury = store
        .collection('papers')
        .where('status', '==', PaperStatus.PUBLISHED)
        .where('grade', '==', selectedGrade.id)
        .where('subjectId', '==', subjectId);

      qury.get().then((data) => {
        if (state.lessons.value.length && state.units.value.length) {
          data.forEach((doc) => {
            const p = {id: doc.id, ...doc.data()} as Paper;

            if (
              state.lessons.value.length === p.lessonIds?.length &&
              arraysEqual(
                [...state.lessons.value.map((l) => l.id)],
                p.lessonIds ? [...p.lessonIds] : []
              ) &&
              state.units.value.length === p.unitIds?.length &&
              arraysEqual([...state.units.value.map((l) => l.id)], p.unitIds ? [...p.unitIds] : [])
            ) {
              papers.push({id: doc.id, ...doc.data()});
            }
          });
        } else if (state.lessons.value.length) {
          data.forEach((doc) => {
            const p = {id: doc.id, ...doc.data()} as Paper;
            if (
              state.lessons.value.length === p.lessonIds?.length &&
              arraysEqual(
                [...state.lessons.value.map((l) => l.id)],
                p.lessonIds ? [...p.lessonIds] : []
              )
            ) {
              papers.push({id: doc.id, ...doc.data()});
            }
          });
        } else {
          data.forEach((doc) => {
            papers.push({id: doc.id, ...doc.data()});
          });
        }

        let structuredQuery = store
          .collection('structuredEssayPapers')
          .where('status', '==', PaperStatus.PUBLISHED)
          .where('grade', '==', selectedGrade.id)
          .where('subjectId', '==', subjectId);

        structuredQuery.get().then((data) => {
          if (state.lessons.value.length && state.units.value.length) {
            data.forEach((doc) => {
              const p = {id: doc.id, ...doc.data()} as Paper;

              if (
                state.lessons.value.length === p.lessonIds?.length &&
                arraysEqual(
                  [...state.lessons.value.map((l) => l.id)],
                  p.lessonIds ? [...p.lessonIds] : []
                ) &&
                state.units.value.length === p.unitIds?.length &&
                arraysEqual(
                  [...state.units.value.map((l) => l.id)],
                  p.unitIds ? [...p.unitIds] : []
                )
              ) {
                papers.push({id: doc.id, ...doc.data()});
              }
            });
          } else if (state.lessons.value.length) {
            data.forEach((doc) => {
              const p = {id: doc.id, ...doc.data()} as Paper;
              if (
                state.lessons.value.length === p.lessonIds?.length &&
                arraysEqual(
                  [...state.lessons.value.map((l) => l.id)],
                  p.lessonIds ? [...p.lessonIds] : []
                )
              ) {
                papers.push({id: doc.id, ...doc.data()});
              }
            });
          } else {
            data.forEach((doc) => {
              papers.push({id: doc.id, ...doc.data()});
            });
          }

          setState((ps) => {
            return {
              ...ps,
              isLoading: {value: false},
            };
          });

          setLocalState((ps) => {
            return {
              ...ps,
              papers: papers,
            };
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.subject.value.id, selectedGrade.id]);

  useEffect(() => {
    const paperIds = state.papers.value.map((p) => p.id);

    if (localState.papers.length > 0) {
      setLocalState((ps) => {
        return {
          ...ps,
          selectedPaperIds: paperIds,
        };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.papers), JSON.stringify(localState.papers)]);

  useEffect(() => {
    const newFilteredPapers = localState.papers.filter((paper) => {
      if (
        localState.selectedPaperIds.length > 0 &&
        localState.selectedPaperIds.includes(paper.id)
      ) {
        return true;
      }

      if (localState.filters.length > 0) {
        if (!localState.filters.includes(paper.paperType)) {
          return false;
        }
      } else {
        return false;
      }
      if (localState.searchText.trim().length > 0) {
        if (!paper.paperTitle.toLowerCase().includes(localState.searchText.toLowerCase())) {
          return false;
        }
      }
      return true;
    });

    setFilteredPapers(newFilteredPapers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.filters, localState.selectedPaperIds, localState.papers, localState.searchText]);

  //methods
  const setSelectedPapers = (selectedPaperIds: string[]) => {
    setLocalState((ps) => {
      return {
        ...ps,
        selectedPaperIds: selectedPaperIds,
      };
    });
  };

  const setFilteredPapers = (leftPapers: any[]) => {
    setLocalState((ps) => {
      return {
        ...ps,
        leftPapers: leftPapers,
      };
    });
  };

  const setDurationAndTotalMarks = (duration: number, marks: number) => {
    setState((ps) => ({
      ...ps,
      overallMark: {...ps.overallMark, value: marks.toString()},
      examDuration: {...ps.examDuration, value: duration.toString()},
    }));
  };

  const searchHandler = (text: string) => {
    setLocalState((ps) => {
      return {
        ...ps,
        searchText: text,
      };
    });
  };

  const setPapersHandler = (papers: any) => {
    const papersArray: any[] = [];

    localState.selectedPaperIds.forEach((id) => {
      const paper = papers.find((p: any) => p.id === id);
      if (paper) {
        papersArray.push(paper);
      }
    });

    setState((ps) => {
      return {
        ...ps,
        papers: {...ps.papers, value: papersArray},
      };
    });
  };

  const updateFilters = (paperType: PaperTypes) => {
    const currentFilters = [...localState.filters];

    let updatedFilters: PaperTypes[] = [];
    if (currentFilters.includes(paperType)) {
      updatedFilters = currentFilters.filter((pT) => pT !== paperType);
    } else {
      updatedFilters = [...currentFilters];
      updatedFilters.push(paperType);
    }

    setLocalState((ps) => {
      return {
        ...ps,
        filters: updatedFilters,
      };
    });
  };

  const onReplacePaper = (id: string, type: PaperTypes) => {
    const sameTypePapers = localState.leftPapers.filter((paper) => paper.paperType === type);
    const paperNotInSelected = sameTypePapers.filter(
      (paper) => !localState.selectedPaperIds.includes(paper.id)
    );

    if (paperNotInSelected.length > 0) {
      const newSelectedPaper = [...localState.selectedPaperIds];
      newSelectedPaper.splice(
        localState.selectedPaperIds.indexOf(id),
        1,
        paperNotInSelected[Math.floor(Math.random() * paperNotInSelected.length)].id
      );
      setSelectedPapers(newSelectedPaper);
    } else {
      Notification({
        isSuccess: false,
        errorHeader: 'No Paper Replaced',
        message: 'No more paper of this type',
        id: 'tid',
      });
    }

    // const replacedPapers = selectedPaper.map(item=> item===id?sameTypePapers[Math.floor(Math.random()*sameTypePapers.length)].id:item);
    // setSelectedPaper([...replacedPapers]);
  };
  const onRemovePaper = (id: string) => {
    const newSelectedPaper = localState.selectedPaperIds.filter((item) => item !== id);
    setSelectedPapers([...newSelectedPaper]);
  };

  return (
    <>
      <Row className="pt-5 mt-3 pb-3 section-container">
        <Col>
          <Row className="mt-4">
            <Col>
              <span className="section-title">Question type</span>
            </Col>
          </Row>
          <Row className="mt-2 text-white">
            <Col className="col-auto mr-5">
              <FilterCheckBox
                onClick={() => {
                  updateFilters(PaperTypes.MCQ);
                }}
                item={{
                  label: 'MCQ',
                  name: PaperTypes.MCQ,
                }}
                active={!!localState.filters.find((val) => val === PaperTypes.MCQ)}
              />
            </Col>
            <Col className="col-auto mr-5">
              <FilterCheckBox
                onClick={() => {
                  updateFilters(PaperTypes.TRUEFALSE);
                }}
                item={{
                  label: 'True/False',
                  name: PaperTypes.TRUEFALSE,
                }}
                active={!!localState.filters.find((val) => val === PaperTypes.TRUEFALSE)}
              />
            </Col>
            <Col className="col-auto mr-5">
              <FilterCheckBox
                onClick={() => {
                  updateFilters(PaperTypes.STRUCTURED);
                }}
                item={{
                  label: 'Structured',
                  name: PaperTypes.STRUCTURED,
                }}
                active={!!localState.filters.find((val) => val === PaperTypes.STRUCTURED)}
              />
            </Col>
            <Col className="col-auto">
              <FilterCheckBox
                onClick={() => {
                  updateFilters(PaperTypes.ESSAY);
                }}
                item={{
                  label: 'Essay',
                  name: PaperTypes.ESSAY,
                }}
                active={!!localState.filters.find((val) => val === PaperTypes.ESSAY)}
              />
            </Col>
          </Row>
          <Row className="mt-5  justify-content-between">
            <Col className="col-auto ">
              <span className="section-title">Selected Paper</span>
            </Col>
            <Col className="col-auto pr-0">
              <SearchBox
                onSearch={(v) => {
                  searchHandler(v);
                }}
                placeHolderText="Search Papers"
              />
            </Col>
          </Row>
          <Row>
            <Col className="pl-4">
              <DragAndDrop
                replacePaper={onReplacePaper}
                removePaper={onRemovePaper}
                selectedIs={localState.selectedPaperIds}
                onChane={(p) => {
                  if (p !== localState.selectedPaperIds) {
                    setSelectedPapers(p);
                  }
                }}
                data={localState.leftPapers}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="position-absolute bottom-0 button-container">
        <Col className="col-auto  pr-1">
          <ButtonComp
            onClick={() => {
              setPapersHandler(localState.papers);
              props.onNext();
            }}
            text="Next"
            disabled={!localState.selectedPaperIds.length}
            type={'one'}
          />
        </Col>
        <Col className="col-auto pr-1">
          <ButtonComp
            style={{background: 'transparent', gap: '20px'}}
            onClick={() => {
              setPapersHandler(localState.papers);
              props.onBack();
            }}
            text="Back"
            type={'one'}
          />
        </Col>
      </Row>
    </>
  );
};

export default ExamCreationStepTwo;
