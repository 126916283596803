import React, {FC} from 'react';
import {formatScore, roundNumberWithDigits} from '../utils';

import './MarkDistribution.scss';
import MarksGraph from './MarksGraph';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  utilityClass?: 'two';
  highestMark?: number;
  lowestMark?: number;
  meanMark?: number;
  distribution?: {percentage: number; count: number}[];
  youTag?: number;
  gradingType?: string;
  totalMarks?: number;
}
const removeTraillingZero = (number: number | string) => {
  return number.toString().replace(/\.00$/, '');
};

const MarkDistribution: FC<Props> = (props) => {
  return (
    <div className={`${props.utilityClass} `}>
      <div className={`mrk-dist ${props.className} ${props.utilityClass} `} style={props.style}>
        <h6 className="mrk-dist__heading">Marks Distribution</h6>
        <div className="mrk-dist__marks">
          <div className="mrk-dist__mean">
            <div>
              {props.gradingType === 'Percentage'
                ? `${removeTraillingZero(roundNumberWithDigits(props?.meanMark || 0))}%`
                : `${formatScore(props?.meanMark)}/${props.totalMarks && props?.totalMarks}`}
            </div>
            <div>Mean Mark</div>
          </div>
          <div className="mrk-dist__lowest">
            <div>
              {props.gradingType === 'Percentage'
                ? `${removeTraillingZero(roundNumberWithDigits(props?.lowestMark || 0))}%`
                : `${formatScore(props?.lowestMark)}/${props.totalMarks && props?.totalMarks}`}
            </div>
            <div>Lowest Mark</div>
          </div>
          <div className="mrk-dist__highest">
            <div>
              {props.gradingType === 'Percentage'
                ? `${removeTraillingZero(roundNumberWithDigits(props?.highestMark || 0))}%`
                : `${formatScore(props?.highestMark)}/${props.totalMarks && props?.totalMarks}`}
            </div>
            <div>Highest Mark</div>
          </div>
        </div>
        <div
          style={{
            paddingTop: '30px',
          }}
        >
          <MarksGraph list={props.distribution} youTag={props.youTag} />
        </div>
        <div className="mrk-dist__x-axis">Marks %</div>
        <div className="mrk-dist__y-axis">Students</div>
      </div>
    </div>
  );
};

export default MarkDistribution;
