import React, { FC, useEffect, useState } from 'react';
import './FilterQuestionsPopUp.scss';
import { StateDisplayMCQ } from './MCQQuestionTab';
import firebase from 'firebase';
import Label from './Label';
import { useSubjects } from '../context/SubjectsContext';
import { useLessons } from '../context/LessonsContext';
import { useAppUser } from '../context/UserContext';
import { Subject } from '../models/Subject';
import {
  sortLessonsByNameUsingContext,
  sortSubjectsByNameUsingContext,
  sortUserByName,
} from '../utils';
import { useLabels } from '../context/LabelContext';
import FilterRadioButton from './FilterRadioButton';
import DropDown from './DropDown';
import ComplexityRangeComp from './ComplexityRangeComp';
import LabelDropDown from './LabelDropDown';
import { useUnits } from '../context/UnitsContext';
import { GRADES_NUMERIC_LABELS } from '../constants';

interface Props {
  show: boolean;
  onCloseModal: () => void;
  setState: (state: any) => void;
  state: StateDisplayMCQ;
  initialState: StateDisplayMCQ;
  onApplyfilter: () => void;
}

const FilterQuestionsPopUp: FC<Props> = (props) => {
  const appUser = useAppUser();
  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUnits = useUnits();

  const [subjects, setSubjects] = useState<{ id: string; displayValue: string }[]>([]);
  const [lessons, setLessons] = useState<{ id: string; displayValue: string }[]>([]);
  const [units, setUnits] = useState<{ id: string; displayValue: string }[]>([]);
  const [labels, setLabels] = useState<{ id: string; displayValue: string }[]>([]);
  const [users, setUsers] = useState<{ id: string; displayValue: string }[]>([]);
  const [gradeChanged, setGradeChanged] = useState(false);
  const [subjectChanged, setSubjectChanged] = useState(false);

  const [userSubjects, setUserSubjects] = useState<Subject[]>([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      firebase
        .firestore()
        .collection('users')
        .get()
        .then((data) => {
          const users = sortUserByName(data);

          setUsers(users);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [appUser.firestoreUser?.uid]);

  const isAdmin = appUser.firestoreUser?.userRole?.isAdmin;
  const isInstructor = appUser.firestoreUser?.userRole?.isInstructor;
  const isTeachingAssistant = appUser.firestoreUser?.userRole?.isTeachingAssistant;

  const inputChangeHandler = (state: any, name: string, id: string) => {
    const array = state[name].value.filter((value: any) => value.id !== id);

    props.setState &&
      props.setState({
        ...state,
        [name]: { ...state[name], value: array },
      });
  };

  const appLabels = useLabels();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (props.state.grade) {
        const subs: Subject[] = appSubjects.allSubjects.filter((s) => {
          return isAdmin
            ? s.grade === props.state.grade
            : isInstructor
            ? (s.grade === props.state.grade && s.createdBy === appUser.firestoreUser?.uid) ||
              (s.grade === props.state.grade &&
                s.assignedUsers?.includes(appUser.firestoreUser?.uid || ''))
            : s.grade === props.state.grade &&
              s.assignedUsers?.includes(appUser.firestoreUser?.uid || '');
        });

        if (!isAdmin) {
          setUserSubjects(subs);
        }

        const items = sortSubjectsByNameUsingContext(subs);

        if (gradeChanged) {
          setSubjectChanged(true);
          props.setState((ps: any) => {
            return { ...ps, subject: { ...ps.subject, value: { displayValue: '', id: '' } } };
          });
        }

        setSubjects(items);
      } else {
        const subs: Subject[] = appSubjects.allSubjects.filter((s) => {
          return isInstructor
            ? s.createdBy === appUser.firestoreUser?.uid ||
                s.assignedUsers?.includes(appUser.firestoreUser?.uid || '')
            : isTeachingAssistant
            ? s.assignedUsers?.includes(appUser.firestoreUser?.uid || '')
            : [];
        });

        if (!isAdmin) {
          setUserSubjects(subs);
        }

        const items = sortSubjectsByNameUsingContext(subs);

        if (gradeChanged) {
          setGradeChanged(false);
          setSubjectChanged(true);
          props.setState((ps: any) => {
            return {
              ...ps,
              subject: {
                ...ps.subject,
                value: { displayValue: '', id: '' },
              },
            };
          });
        }

        setSubjects(items);
      }
    }

    props.setState((ps: any) => {
      return {
        ...ps,
        labels: { ...ps.labels, value: [] },
      };
    });

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state.grade]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (props.state.subject?.value.id) {
        const filteredLessons = appLessons.lessons.filter(
          (les) => les.subjectId === props.state.subject?.value.id
        );

        const sortedLessons = sortLessonsByNameUsingContext(filteredLessons);
        if (subjectChanged) {
          props.setState((ps: any) => {
            return { ...ps, lesson: { ...ps.lesson, value: { displayValue: '', id: '' } } };
          });
        }

        setLessons(sortedLessons);
      } else {
        const subs: Subject[] = appSubjects.allSubjects.filter((s) => {
          return isInstructor
            ? s.createdBy === appUser.firestoreUser?.uid ||
                s.assignedUsers?.includes(appUser.firestoreUser?.uid || '')
            : isTeachingAssistant
            ? s.assignedUsers?.includes(appUser.firestoreUser?.uid || '')
            : [];
        });

        const sortedLessons = sortLessonsByNameUsingContext(
          appLessons.lessons.filter((les) => subs.filter((sub) => les.subjectId === sub.id).length)
        );

        if (subjectChanged) {
          props.setState((ps: any) => {
            return { ...ps, lesson: { ...ps.lesson, value: { displayValue: '', id: '' } } };
          });
        }

        setLessons(sortedLessons);
      }
    }

    const list = appLabels.labelList
      .filter((l) => l.subjectId === props.state.subject?.value.id)
      .map((l) => ({ displayValue: l.labelName, id: l.id }));

    setLabels(list);

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state.subject?.value.id]);

  useEffect(() => {
    const selectedUnits = appUnits.units
      .filter((u) => u.lessonId === props.state.lesson?.value.id)
      ?.map((u) => ({ displayValue: u.name || '', id: u.id || '' }));

    setUnits(selectedUnits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state.lesson?.value.id]);

  return (
    <div className={`fq-popup position-absolute ${props.show ? 'd-block' : 'd-none'}`}>
      <div className="fq-popup__grade">
        <div className="grade">Grade</div>
        <div className="d-flex">
          {GRADES_NUMERIC_LABELS.map((item, idx) => (
            <div key={idx}>
              <FilterRadioButton
                onClick={() => {
                  setGradeChanged(true);
                  props.setState((ps: any) => {
                    return { ...ps, grade: item.name };
                  });
                }}
                item={item}
                value={props.state.grade}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="fq-popup__sub-less">
        <div>
          <DropDown
            name="Subject"
            noValueText="Select subject"
            className="textinput-margin-signup"
            stateName="subject"
            stateValue={props.state.subject?.value || { displayValue: '', id: '' }}
            state={props.state}
            setState={props.setState}
            error={''}
            optionsArray={subjects}
            style={{ width: '100%' }}
            labelSize={{ fontSize: 15, top: -5 }}
            fireOnclick={() => {
              setSubjectChanged(true);
              props.setState((ps: any) => {
                return { ...ps, labels: { ...ps.labels, value: [] } };
              });
            }}
          />
        </div>
        <div>
          <DropDown
            name="Lesson"
            noValueText="Select lesson"
            className="textinput-margin-signup"
            stateName="lesson"
            stateValue={props.state.lesson?.value || { displayValue: '', id: '' }}
            state={props.state}
            setState={props.setState}
            error={''}
            optionsArray={lessons}
            labelSize={{ fontSize: 15, top: -5 }}
          />
        </div>
      </div>
      <div className="fq-popup__sub-less">
        <div>
          <DropDown
            name="Unit"
            noValueText="Select unit"
            className="textinput-margin-signup"
            stateName="unit"
            stateValue={props.state.unit?.value || { displayValue: '', id: '' }}
            state={props.state}
            setState={props.setState}
            error={''}
            optionsArray={units}
            labelSize={{ fontSize: 15, top: -5 }}
          />
        </div>
      </div>
      <div className="fq-popup__cmp-cby fq-popup__cmp-cby__set-border">
        <div>
          <div>Complexity</div>
          <ComplexityRangeComp
            onChange={(value) => {
              props.setState({ ...props.state, complexity: value });
            }}
            value={props.state.complexity}
            activeOnClick
          />
        </div>
        <div>
          <DropDown
            name="Created by"
            noValueText="Select author"
            stateName="user"
            stateValue={props.state.user?.value || { displayValue: '', id: '' }}
            state={props.state}
            setState={props.setState}
            error={''}
            optionsArray={
              isAdmin
                ? users
                : users.filter((u) => !!userSubjects.find((s) => s.createdBy === u.id))
            }
            labelSize={{ fontSize: 15, top: -5 }}
          />
        </div>
      </div>
      <div style={{ marginTop: '15px' }}>
        <LabelDropDown
          name="Label"
          noValueText="Select label"
          className="textinput-margin-signup"
          stateName="labels"
          stateValue={props.state.labels?.value || []}
          state={props.state}
          setState={props.setState}
          error={''}
          optionsArray={
            labels
              ? labels.filter(
                  (l) => !props.state.labels?.value.map((val: any) => val.id).includes(l.id)
                )
              : []
          }
          labelSize={{ fontSize: 15, top: -5 }}
        />
      </div>
      <div className="fq-popup__cmp-cby">
        <div className="d-flex w-100 flex-wrap" style={{ alignItems: 'flex-start' }}>
          {props.state.labels?.value.map((l: any) => (
            <Label
              id={l.id}
              onClose={() => {
                inputChangeHandler(props.state, 'labels', l.id);
              }}
            />
          ))}
        </div>
      </div>
      <div className="fq-popup__btns">
        <div
          onClick={() => {
            props.setState({
              ...props.initialState,
              questions: props.state.questions,
              page: props.state.page,
              totalPages: props.state.totalPages,
            });
            // props.onApplyfilter();
          }}
        >
          Clear Filters
        </div>
        <div>
          <div style={{ cursor: 'pointer' }} onClick={props.onCloseModal}>
            Cancel
          </div>
          <button
            className="apply"
            onClick={() => {
              props.onApplyfilter();
              props.onCloseModal();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterQuestionsPopUp;
