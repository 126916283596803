import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ExamsCreateScreen from './ExamsCreateScreen';
import AdminUnitExaminationsScreen from './AdminUnitExaminationsScreen';
import PageNotFoundScreen from '../common/PageNotFoundScreen';
import AdminExamDetailsAndStatsScreen from './AdminExamDetailsAndStatsScreen';
import AdminAndInstructorContent from '../../modules/content/screen/adminAndInstructor/AdminAndInstructorContent';
import AdminUpdateExamScreen from './AdminUpdateExamScreen';

const AdminUnitScreen = () => {
  return (
    <Switch>
      <Route
        exact
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/create-extra-exam/Examinations',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/create-live-exam/Examinations',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/create-practise-exam/Examinations',
        ]}
      >
        <ExamsCreateScreen level="UNIT" />
      </Route>
      <Route
        exact
        path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/update-exam/:examId/Examinations"
      >
        <AdminUpdateExamScreen level="UNIT" />
      </Route>
      <Route path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Examinations/:examId">
        <AdminExamDetailsAndStatsScreen hierarchyLeveL="UNIT" />
      </Route>
      <Route path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Examinations">
        <AdminUnitExaminationsScreen />
      </Route>
      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId',
          '/my-subjects/subject/:subjectId/lesson/:lessonId',
        ]}
      >
        <AdminAndInstructorContent />
      </Route>

      <Route path="*" component={PageNotFoundScreen} />
    </Switch>
  );
};

export default AdminUnitScreen;
