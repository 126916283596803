import React from 'react';
import {useMobileNav} from '../context/MobNavContext';
import HamburgerIcon from './HamburgerIcon';

const PageNotFoundHeader = () => {
  const mobileNav = useMobileNav();

  return (
    <>
      <div className="d-md-flex justify-content-between align-items-center">
        <div className="d-flex d-md-none justify-content-between">
          <HamburgerIcon
            onClick={() => {
              mobileNav.setOpenMobileNav(!mobileNav.openMobileNav);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PageNotFoundHeader;
