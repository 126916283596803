import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminUpdateExamScreen from '../admin/AdminUpdateExamScreen';
import ExamsCreateScreen from '../admin/ExamsCreateScreen';
import PageNotFoundScreen from '../common/PageNotFoundScreen';
import AdminLessonListScreen from './InstructorLessonListScreen';
import InstructorPackageListScreen from './InstructorPackageListScreen';
import AdminSubjectAnnouncementsScreen from './InstructorSubjectAnnouncementsScreen';
import InstructorSubjectModalPapersScreen from './InstructorSubjectModalPapersScreen';
import AdminSubjectSettingsScreen from './InstructorSubjectSettingsScreen';

const InstructorSubjectScreen = () => {
  return (
    <Switch>
      <Route exact path="/my-subjects/subject/:subjectId/Lessons">
        <AdminLessonListScreen />
      </Route>
      <Route exact path={'/my-subjects/subject/:subjectId/Examinations'}>
        <InstructorSubjectModalPapersScreen />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/settings">
        <AdminSubjectSettingsScreen />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Announcements">
        <AdminSubjectAnnouncementsScreen />
      </Route>
      <Route
        exact
        path={[
          '/my-subjects/subject/:subjectId/create-extra-exam',
          '/my-subjects/subject/:subjectId/create-live-exam',
          '/my-subjects/subject/:subjectId/create-practise-exam',
        ]}
      >
        <ExamsCreateScreen level="SUBJECT" />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/update-exam/:examId/Examinations">
        <AdminUpdateExamScreen level="SUBJECT" />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Packages">
        <InstructorPackageListScreen />
      </Route>
      <Route path="*" component={PageNotFoundScreen} />
    </Switch>
  );
};

export default InstructorSubjectScreen;
