import React, {FC} from 'react';

import {FaCaretLeft} from 'react-icons/fa';
import {useHistory} from 'react-router';
import './Backarrow.scss';

interface Props {
  onClick?: () => void;
  onGoBack?: boolean;
  className?: string;
}

const Backarrow: FC<Props> = (props) => {
  const history = useHistory();

  return (
    <button
      className={`backarrow-button ${props.className}`}
      onClick={() => {
        props.onClick && props.onClick();
        props.onGoBack && history.goBack();
      }}
    >
      <FaCaretLeft color="#246BFD" size={25} className="icon" />
    </button>
  );
};

export default Backarrow;
