import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import './SignIn.scss';
import ClipLoader from 'react-spinners/ClipLoader';
import {toast} from 'react-toastify';
import qs from 'qs';
import * as jwt from 'jsonwebtoken';
import {useAppUser} from '../context/UserContext';
import useLocalStorage from '../hooks/useLocalStorage';
import LoadingIndicator from './LoadingIndicator';
import MainLayout from '../layouts/MainLayout';
import TextInput from './TextInput';
import PasswordInput from './PasswordInput';
import Notification from './../components/Notification';

const SignIn = () => {
  const history = useHistory();

  const initialState = {
    loading: false,
    authLoading: false,
    username: {value: '', error: '', label: 'Username'},
    password: {value: '', error: '', label: 'Password'},
  };

  const {signInWithUsername} = useAppUser();
  const [state, setState] = useState(initialState);

  const [rememberMe, setRememberMe] = useLocalStorage('rememberMe', false);

  const location = useLocation();

  useEffect(() => {
    const searchParas: any = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const payload = jwt.decode(searchParas.token) as {
      uid: string;
      email: string;
      password: string;
    };

    if (payload?.email && payload?.password) {
      setState((ps) => {
        return {...ps, authLoading: true};
      });
      signInWithEmailAutomatically(payload.email, payload.password);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const signInWithEmailAutomatically = async (userNameOrEmail: string, password: string) => {
    try {
      await signInWithUsername(userNameOrEmail, password, history);
      setState((ps) => {
        return {...ps, authLoading: false};
      });
    } catch (e: any) {
      setState((pS) => ({
        ...pS,
        loading: false,
        authLoading: false,
        username: {...pS.username, error: 'ERROR'},
        password: {...pS.password, error: 'ERROR'},
      }));

      if (e.message === 'Error: Error: The user account has been disabled by an administrator.') {
        Notification({
          isSuccess: false,
          id: 'signInToastId',
          message: 'Account has been deactivated. Please contact your institute',
          errorHeader: 'Login Failed',
        });
      } else {
        Notification({
          isSuccess: false,
          id: 'signInToastId',
          message: 'Login Failed : Username, email or the password is incorrect',
          errorHeader: 'Login Failed',
        });
        history.push('/signin');
      }
    }
  };

  const signInHandler = async () => {
    if (toast.isActive('signInToastId')) {
      return;
    }

    setState((pS) => ({
      ...pS,
      loading: true,
    }));

    try {
      await signInWithUsername(state.username.value, state.password.value, history);
    } catch (e: any) {
      setState((pS) => ({
        ...pS,
        loading: false,
        username: {...pS.username, error: 'ERROR'},
        password: {...pS.password, error: 'ERROR'},
      }));

      if (e.message === 'Error: Error: The user account has been disabled by an administrator.') {
        Notification({
          isSuccess: false,
          id: 'signInToastId',
          message: 'Account has been deactivated. Please contact your institute',
          errorHeader: 'Login Failed',
        });
      } else {
        Notification({
          isSuccess: false,
          id: 'signInToastId',
          message: 'Login Failed : Username, email or the password is incorrect',
          errorHeader: 'Login Failed',
        });
      }
    }
  };

  const enterKeyDownHandler = (evt: any) => {
    if (evt.key === 'Enter') {
      signInHandler();
    }
  };

  return (
    <>
      {state.authLoading && <LoadingIndicator style={{position: 'absolute'}} />}
      <MainLayout>
        <div className="signin-auth">
          <div className="signin-auth__header">LOG IN</div>

          <TextInput
            className="textinput-margin"
            stateName="username"
            stateValue={state.username.value}
            state={state}
            setState={setState}
            error={state.username.error}
            placeHolder="Username or Email"
            onKeyPress={enterKeyDownHandler}
            noErrorText
          />

          <PasswordInput
            className="textinput-margin"
            stateName="password"
            stateValue={state.password.value}
            state={state}
            setState={setState}
            error={state.password.error}
            onKeyPress={enterKeyDownHandler}
            style={{marginTop: 25}}
            noErrorText
          />

          <div className="signin-auth__remme-forgetpass">
            <div>
              <div className="pretty p-smooth p-svg p-curve" style={{paddingBottom: 1.5}}>
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => {
                    setRememberMe(!rememberMe);
                  }}
                />
                <div className="state p-primary">
                  <svg className="svg svg-icon" viewBox="0 0 20 20">
                    <path
                      d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                      style={{stroke: 'white', fill: 'white'}}
                    ></path>
                  </svg>
                  <label>Stay signed in</label>
                </div>
              </div>
            </div>
            <Link to="/forgot-password" className="ml-auto">
              Forgot password?
            </Link>
          </div>
          <div className="signin-auth__buttons">
            <button
              className={`login-btn ${state.loading && 'login-btn-dis'}`}
              onClick={() => {
                !state.loading && signInHandler();
              }}
            >
              <span className="d-inline-block mr-2"> SIGN IN</span>{' '}
              <ClipLoader color="purple" loading={state.loading} size={18} />
            </button>
            {/* <button className="google-btn" onClick={socialLoginHandler}>
            <FcGoogle size={20} />
            <div>Sign in with Google</div>
          </button> */}
          </div>
          <Link to="/signup" className="signin-auth__create-atn">
            Create new account
          </Link>
        </div>
      </MainLayout>
    </>
  );
};

export default SignIn;
