import React, {FC} from 'react';

import {ManageUsersState} from './SettingsManageUsersBody';
import './FilterUsersPopUp.scss';
import FilterRadioButton from './FilterRadioButton';

interface Props {
  show: boolean;
  onCloseModal: () => void;
  setState: (state: any) => void;
  state: ManageUsersState;
  initialState: ManageUsersState;
  onApplyfilter: () => void;
}

const FilterUsersPopUp: FC<Props> = (props) => {
  const roles = [
    {name: 'Admin', label: 'Admin'},
    {name: 'Instructor', label: 'Instructor'},
    {name: 'Teaching Assistant', label: 'Teaching Assistant'},
    {name: 'Student', label: 'Student'},
  ];

  const status = [
    {name: 'Active', label: 'Active'},
    {name: 'Inactive', label: 'Inactive'},
  ];

  return (
    <div className={`muf-pop-up position-absolute ${props.show ? 'd-block' : 'd-none'}`}>
      <div className="muf-pop-up__user-type">
        <div className="user-type">User Type</div>
        <div className="d-flex">
          {roles.map((item, idx) => (
            <div key={idx}>
              <FilterRadioButton
                onClick={() => {
                  props.setState((ps: any) => {
                    return {...ps, role: item.name};
                  });
                }}
                item={item}
                value={props.state.role}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="muf-pop-up__status">
        <div className="pop-up-status">Status</div>
        <div className="d-flex">
          {status.map((item, idx) => (
            <div key={idx}>
              <FilterRadioButton
                onClick={() => {
                  props.setState((ps: any) => {
                    return {...ps, enabled: item.name};
                  });
                }}
                item={item}
                value={props.state.enabled}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="muf-pop-up__btns">
        <div
          onClick={() => {
            props.setState({
              ...props.initialState,
              role: null,
              enabled: null,
              totalPages: props.state.totalPages,
            });
            // props.onApplyfilter();
          }}
        >
          Clear Filters
        </div>
        <div>
          <div style={{cursor: 'pointer'}} onClick={props.onCloseModal}>
            Cancel
          </div>
          <button
            className="apply"
            onClick={() => {
              props.onApplyfilter();
              props.onCloseModal();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterUsersPopUp;
