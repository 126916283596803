import React, {FC} from 'react';
import {PaperTypes} from '../enums/paperTypes';
import ButtonComp from './ButtonComp';

import './StudentPaperResultCard.scss';

interface Props {
  title: string;
  type: string;
  marks: string;
  noOfQuestions: number;
  onClick: () => void;
  time: {hour: string; minutes: string};
  paperType?: PaperTypes;
  className?: string;
  stillToMark?: boolean;
  isDisabled: boolean | undefined;
}

const StudentPaperResultCard: FC<Props> = (props) => {
  return (
    <div className={`stu-pr-cd ${props.className}`}>
      <header className="stu-pr-cd__header">
        <h5 className="stu-pr-cd__title">{props.title}</h5>
        <div className="stu-pr-cd__tag">{props.type}</div>
      </header>
      <main className="stu-pr-cd__main">
        <div className="stu-pr-cd__qtns-time">
          <div className="stu-pr-cd__qtns">
            <span className="stu-pr-cd__qtns-number">{props.noOfQuestions}</span>
            <span className="stu-pr-cd__qtns-text">Questions</span>
          </div>
          <div className="stu-pr-cd__time">
            <span className="stu-pr-cd__time-hour">{props.time.hour}</span>
            <span className="stu-pr-cd__time-hour-text">H</span>
            <span className="stu-pr-cd__time-minute">{props.time.minutes}</span>
            <span className="stu-pr-cd__time-minute-text">M</span>
          </div>
        </div>
        <div className="stu-pr-cd__grade">
          <div className="stu-pr-cd__grade-value">{props.marks}</div>
          <div className="stu-pr-cd__grade-text">Grade</div>
        </div>
      </main>
      <footer className="stu-pr-cd__footer">
        <ButtonComp
          disabled={props.isDisabled}
          type="one"
          onClick={() => {
            props.onClick();
          }}
          text="View"
          style={{width: '100%'}}
        />
      </footer>
    </div>
  );
};

export default StudentPaperResultCard;
