import React, {FC} from 'react';
import {CHECKBOXSTATUS} from './StudentTAAssignmentCard';
import './StudentTAAssigmentCheckbox.scss';

interface Props {
  className?: string;
  status: CHECKBOXSTATUS;
  type?: 'TWO';
  style?: React.CSSProperties;
  onAssign?: () => void;
  onRemove?: () => void;
  disabled?: boolean;
}

const StudentTAAssigmentCheckbox: FC<Props> = (props) => {
  return props.type ? (
    <div className={`${props.className}`}>
      <div
        className={` stu-taa-checkbox ${
          props.status === CHECKBOXSTATUS.NONE ? '' : ('stu-taa-checkbox--full')} `}
        style={props.style}
        onClick={() => {
          if (!props.disabled) {
            if (props.status === CHECKBOXSTATUS.NONE) {
              props.onAssign && props.onAssign();
            } else {
              props.onRemove && props.onRemove();
            }
          }
        }}
      ></div>
    </div>
  ) : (
    <div className={`${props.className}`}>
      <div
        className={` stu-taa-checkbox ${
          props.status === CHECKBOXSTATUS.NONE
            ? (props.disabled ? ' stu-taa-checkbox--full--nopointer' : '')
            : props.status === CHECKBOXSTATUS.PARTIAL
              ? 'stu-taa-checkbox--partial' + (props.disabled ? ' stu-taa-checkbox--partial--nopointer' : '')
              : 'stu-taa-checkbox--full' + (props.disabled ? ' stu-taa-checkbox--partial--nopointer' : '')
        }`}
        style={props.style}
        onClick={() => {
          if (!props.disabled) {
            if (props.status === CHECKBOXSTATUS.NONE) {
              props.onAssign && props.onAssign();
            } else {
              props.onRemove && props.onRemove();
            }
          }

        }}
      ></div>
    </div>
  );
};

export default StudentTAAssigmentCheckbox;
