import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaFilter, FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import './MCQQuestionTab.scss';
import QuestionCard from './QuestionCard';
import FilterQuestionsPopUp from './FilterQuestionsPopUp';
import PreviewHeaderActionBar from './PreviewHeaderActionBar';
import QuestionBody from './QuestionBody';
import AnswerCard from './AnswerCard';
import ComplexityIndicator from './ComplexityIndicator';
// import {matchSorter} from 'match-sorter';
import LoadingIndicator from './LoadingIndicator';
import TinyMCEEditor from './TinyMCEEditor';
import BoxIcon from './BoxIcon';
import DropDown from './DropDown';
import { GRADES, QUESTION_SOURCES_OPTIONS } from '../constants';
import { useQuestions } from '../context/QuestionsContext';
import { useAppUser } from '../context/UserContext';
import { QuestionData } from '../interfaces';
import { useSubjects } from '../context/SubjectsContext';
import ComplexityRangeComp from './ComplexityRangeComp';
import { MCQ, Question } from '../models/Question';
import SearchBox from './SearchBox';
import { checkArrayContains, getMCQQuestionBasedOnRole, splitArrayIntoChunksOfLen } from '../utils';
import TextInput from './TextInput';
import QuestionService from '../services/QuestionService';
import Label from './Label';
import { useLabels } from '../context/LabelContext';
import LabelDropDown from './LabelDropDown';
import { useLessons } from '../context/LessonsContext';
import SubjectDropDown from './SubjectDropDown';
import LessonDropDown from './LessonDropDown';
import UnitDropDown from './UnitDropDown';
import PaginationNew from './PaginationNew';

interface Props {
  state?: any;
  subjects: any;
  lessons: any;
  units: any;
  setState?: Function;
  answerTextChangeHandler?: (txt: string, idx?: number) => void;
  changeCorrectAnswerHandler?: (idx: number) => void;
  addNewAnswer?: () => void;
  removeNewAnswer?: (idx: number) => void;
  questionTextChangeHandler?: (e: string) => void;
  onComplexityChange: (complexity: number) => void;
  onChangeDefaultTab: (
    value: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT',
    questionData: QuestionData | null
  ) => void;
  tabState: { mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT'; questionData: QuestionData | null };
  labels: any;
  scrollToTopRef: React.RefObject<HTMLDivElement>;
}

const func = () => {};

const MCQQuestionTab: FC<Props> = (props) => {
  const initialState: StateDisplayMCQ = {
    questions: [],
    loading: false,
    searchText: '',
    grade: null,
    subject: { value: { displayValue: '', id: '' } },
    lesson: { value: { displayValue: '', id: '' } },
    unit: { value: { displayValue: '', id: '' } },
    labels: { value: [] },
    user: { value: { displayValue: '', id: '' } },
    complexity: undefined,
    page: 1,
    totalPages: 0,
    filtered: false,
  };

  const [mCQFilterState, setMCQFilterState] = useState<StateDisplayMCQ>(initialState);
  let content = null;

  if (props.tabState.mode === 'VIEW') {
    content = (
      <DisplayMCQQuestions
        {...props}
        filterState={mCQFilterState}
        setFilterState={setMCQFilterState}
        initialFilterState={initialState}
      />
    );
  }

  if (props.tabState.mode === 'CREATE' || props.tabState.mode === 'EDIT') {
    content = <MCQCreateQuestion {...props} />;
  }

  if (props.tabState.mode === 'PREVIEW') {
    content = <PreviewQuestionMCQ {...props} />;
  }

  return content;
};

const MCQCreateQuestion: FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const appLessons = useLessons();

  const resetAnswerErrors = (idx?: number) => {
    const updateAnswers = props.state.answers.value.map((a: any, index: number) => {
      if (idx === index) {
        a.error = '';
      }
      return a;
    });

    if (idx) {
      props.setState &&
        props.setState((ps: any) => {
          return { ...ps, answers: { ...ps.answers, value: updateAnswers, error: '' } };
        });
    } else {
      props.setState &&
        props.setState((ps: any) => {
          return { ...ps, answers: { ...ps.answers, error: '' } };
        });
    }
  };

  const resetQuestionErrors = () => {
    props.setState &&
      props.setState((ps: any) => {
        return { ...ps, question: { ...ps.question, error: '' } };
      });
  };

  const inputChangeHandler = (state: any, name: string, id: string) => {
    const array = state[name].value.filter((value: any) => value.id !== id);

    props.setState &&
      props.setState({
        ...state,
        [name]: { ...state[name], value: array },
      });
  };

  return (
    <div className="mcq-edit-page">
      <Row className="mcq-dropdown-component no-gutters">
        {props.state.loading && <LoadingIndicator />}
        <Col md={3}>
          <DropDown
            name="Grade"
            noValueText="Select grade"
            className="textinput-margin-signup"
            stateName="grade"
            stateValue={props.state.grade.value}
            state={props.state}
            setState={props.setState || func}
            error={props.state.grade.error}
            optionsArray={GRADES}
          />
        </Col>
        <Col md={4} className="mx-auto">
          <SubjectDropDown
            name="Subject"
            noValueText="Select subject"
            className="textinput-margin-signup"
            stateName="subject"
            stateValue={props.state.subject.value}
            state={props.state}
            setState={props.setState || func}
            error={props.state.subject.error}
            optionsArray={props.subjects}
          />
        </Col>
        <Col md={4}>
          <LessonDropDown
            name="Lesson"
            noValueText="Select lesson"
            className="textinput-margin-signup"
            stateName="lesson"
            stateValue={props.state.lesson.value}
            state={props.state}
            setState={props.setState || func}
            error={props.state.lesson.error}
            optionsArray={props.lessons}
          />
        </Col>
      </Row>
      {
        // appLessons.lessons.find((l) => l.id === props.state.lesson.value.id)
        //   ?.isLessonContainsUnits
        props.units.length > 0 ? (
          <Row className="no-gutters">
            <Col md={3}>
              <UnitDropDown
                name="Unit"
                noValueText="Select unit"
                className="textinput-margin-signup"
                stateName="unit"
                stateValue={props.state.unit.value}
                state={props.state}
                setState={props.setState || func}
                error={props.state.unit.error}
                optionsArray={props.units}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )
      }

      <div className="mcq-answer-container" ref={props.scrollToTopRef}>
        <div className="pt-4" style={{ color: '#73847f' }}>
          Question
        </div>

        <div>
          <TinyMCEEditor
            onChange={props.questionTextChangeHandler || func}
            className="mt-1"
            value={props.state.question.value}
            error={props.state.question.error}
            onFocus={resetQuestionErrors}
          />

          <div className="mt-3 text-right text-danger">
            {props.state.answers.error === 'The answer cannot be empty' ||
            props.state.answers.error === 'NO-ERROR'
              ? ''
              : props.state.answers.error}
          </div>

          {!!props.state.answers?.value?.length &&
            props.state.answers?.value?.map((answer: any, idx: any) => {
              return (
                <div style={{ width: '96%' }} className="ml-auto mt-4 pt-1" key={idx}>
                  <div className="text-white d-flex justify-content-between">
                    <span style={{ color: '#73847f' }}>{`Answer 0${idx + 1}`}</span>

                    <div
                      className="d-flex"
                      onClick={() => {
                        resetAnswerErrors();
                      }}
                    >
                      <div>
                        <div className="pretty p-default p-curve p-fill">
                          <input
                            type="checkbox"
                            onChange={() => {
                              props.changeCorrectAnswerHandler &&
                                props.changeCorrectAnswerHandler(idx);
                            }}
                            checked={answer.isCorrect}
                          />
                          <div className="state p-primary correct-answer" style={{ marginTop: 4 }}>
                            <label style={{ fontSize: 16 }}>Correct Answer</label>
                          </div>
                        </div>
                      </div>
                      <BoxIcon
                        icon={FaTrashAlt}
                        size={15}
                        color="#fff"
                        onClick={() => {
                          props.removeNewAnswer && props.removeNewAnswer(idx);
                        }}
                        style={{ backgroundColor: '#F05A5A' }}
                      />
                    </div>
                  </div>
                  <TinyMCEEditor
                    onChange={props.answerTextChangeHandler || func}
                    className="mt-1"
                    value={answer?.answerText}
                    idx={idx}
                    onFocus={resetAnswerErrors}
                    error={answer?.error}
                  />
                </div>
              );
            })}

          <div
            style={{ width: '96%' }}
            className="ml-auto mt-4 text-right d-flex justify-content-left"
          >
            <div onClick={props.addNewAnswer} style={{ cursor: 'pointer' }}>
              <FaPlusCircle size={20} color="#246bfd" className="mr-1" />
              <span style={{ color: '#246bfd', fontSize: 13 }}>Add Answer</span>
            </div>
          </div>

          <Row className="pt-1 mr-0 ml-auto no-gutters" style={{ marginTop: 80 }}>
            <Col md={4} className="pr-5">
              <DropDown
                name={props.state.sourceOfQuestion.label}
                noValueText="Source of the question"
                className="textinput-margin-signup"
                stateName="sourceOfQuestion"
                stateValue={props.state.sourceOfQuestion.value}
                state={props.state}
                setState={props.setState || func}
                error={props.state.sourceOfQuestion.error}
                optionsArray={QUESTION_SOURCES_OPTIONS}
              />
            </Col>
            <Col md={4}>
              <LabelDropDown
                name="Label"
                noValueText="Select Label"
                className="textinput-margin-signup"
                stateName="labels"
                stateValue={props.state.labels.value}
                state={props.state}
                setState={props.setState || func}
                error={props.state.labels.error}
                optionsArray={
                  props.labels
                    ? [...props.labels].filter(
                        (l) => !props.state.labels.value.map((val: any) => val.id).includes(l.id)
                      )
                    : []
                }
              />
              <div className="d-flex w-100 flex-wrap">
                {props.state.labels.value.map((l: any) => (
                  <Label
                    id={l.id}
                    onClose={() => {
                      inputChangeHandler(props.state, 'labels', l.id);
                    }}
                  />
                ))}
              </div>
            </Col>
            <Col md={4} className="pl-5" style={{ color: '#fff' }}>
              <div className="complexity-label">Complexity</div>
              <ComplexityRangeComp
                onChange={props.onComplexityChange}
                value={props.state.complexity.value}
              />
            </Col>
            <Col md={12}>
              <TextInput
                className="textinput-margin-signup"
                stateName="source"
                stateValue={props.state.source.value}
                state={props.state}
                setState={props.setState || func}
                error={props.state.source.error}
                placeHolder="Source"
              />
            </Col>

            <Col md={12}>
              <TextInput
                className="textinput-margin-signup"
                stateName="resolveGuide"
                stateValue={props.state.resolveGuide.value}
                state={props.state}
                setState={props.setState || func}
                error={props.state.resolveGuide.error}
                placeHolder="Resolve Guide"
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export interface StateDisplayMCQ {
  questions: any;
  loading: boolean;
  searchText: string;
  grade: string | null;
  subject?: { value: { displayValue: string; id: string } };
  lesson?: { value: { displayValue: string; id: string } };
  unit?: { value: { displayValue: string; id: string } };
  labels?: { value: { displayValue: string; id: string }[] };
  user?: { value: { displayValue: string; id: string } };
  complexity?: number;
  page: number;
  totalPages: number;
  filtered: boolean;
}

interface MCQProps extends Props {
  filterState: StateDisplayMCQ;
  setFilterState: React.Dispatch<React.SetStateAction<StateDisplayMCQ>>;
  initialFilterState: StateDisplayMCQ;
}

const DisplayMCQQuestions: FC<MCQProps> = (props) => {
  const appQuestions = useQuestions();
  const appUser = useAppUser();
  const appSubjects = useSubjects();

  const setCurrentPage = (page: number) => {
    props.setFilterState((ps) => {
      return { ...ps, page: page };
    });
  };

  const [show, setShow] = useState(false);

  const openModalHandelr = () => {
    setShow(true);
  };

  const closeModalHander = () => {
    setShow(false);
  };

  const fetchDataCall = (filter?: boolean) => {
    if (typeof props.filterState.totalPages === 'number') {
      const pageToSend = filter
        ? 0
        : props.filterState.page - 1 < 0
        ? 0
        : props.filterState.page - 1;

      let filteredList = getMCQQuestionBasedOnRole(
        appUser.firestoreUser?.uid || '',
        appSubjects.allSubjects,
        appUser.firestoreUser?.userRole,
        appQuestions.mcqQuestions
      );

      if (props.filterState?.grade?.length) {
        filteredList = filteredList.filter((item) =>
          props.filterState?.grade?.includes(item.grade)
        );
      }

      if (props.filterState?.subject?.value.id) {
        filteredList = filteredList.filter(
          (item) => item.subjectId === props.filterState?.subject?.value.id
        );
      }

      if (props.filterState.lesson?.value.id) {
        filteredList = filteredList.filter(
          (item) => item.lessonId === props.filterState.lesson?.value.id
        );
      }

      if (props.filterState.unit?.value.id) {
        filteredList = filteredList.filter(
          (item) => item.unitId === props.filterState.unit?.value.id
        );
      }

      if (props.filterState.labels?.value.length) {
        filteredList = filteredList.filter((item) => {
          return checkArrayContains(
            item.labels || [],
            props.filterState.labels?.value.map((v) => v.id) || []
          );
        });
      }

      if (props.filterState.labels?.value.length) {
        filteredList = filteredList.filter((item) => {
          let value = false;

          props.filterState.labels?.value.forEach((l) => {
            if (item.labels?.includes(l.id)) {
              value = true;
            }
          });

          return value;
        });
      }

      if (
        !(props.filterState?.complexity === undefined || props.filterState?.complexity === null)
      ) {
        filteredList = filteredList.filter(
          (item) => item.complexity === props.filterState.complexity
        );
      }

      if (props.filterState?.user?.value.id) {
        filteredList = filteredList.filter(
          (item) => item.createdBy === props.filterState?.user?.value.id
        );
      }

      // let searchTextResult: Question[] = [];
      let duplicatesRemovedArray: Question[];

      if (props.filterState?.searchText?.trim()) {
        // const words = props.filterState?.searchText.trim()?.split(' ');

        // for (const word in words) {
        //   searchTextResult = [
        //     ...searchTextResult,
        //     ...filteredList.filter(
        //       (item) => matchSorter(item.question.split(' '), words[word].trim() || '').length
        //     ),
        //   ];
        // }

        // duplicatesRemovedArray = Array.from(new Set(searchTextResult));
        duplicatesRemovedArray = filteredList.filter((item) => {
          return item.question
            .replace(/<[^>]+>/g, '')
            .replace(/&nbsp;/g, '')
            .toUpperCase()
            .startsWith(props.filterState?.searchText?.trim().toUpperCase());
        });
      } else {
        duplicatesRemovedArray = filteredList;
      }

      let pagesList: Question[] = [];

      if (duplicatesRemovedArray.length) {
        pagesList = splitArrayIntoChunksOfLen(duplicatesRemovedArray, 10);
      }

      const totalPages = pagesList.length;
      // setIsLoading(false);
      props.setFilterState((ps) => {
        return {
          ...ps,
          questions: pagesList[pageToSend] || [],
          totalPages: totalPages,
          filtered: !!filter,
        };
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchDataCall();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.tabState.mode,
    props.filterState.page,
    props.filterState.searchText,
    props.onChangeDefaultTab,
  ]);

  const applyFiltersHandler = () => {
    fetchDataCall(true);
  };

  return (
    <div style={{ marginTop: 90 }}>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <div className="d-flex position-relative">
          <SearchBox
            onSearch={(text) => {
              props.setFilterState((ps) => {
                return { ...ps, searchText: text };
              });
            }}
            placeHolderText="Search Question"
          />
          <button className="filter_button" onClick={openModalHandelr}>
            Filters &nbsp;
            <FaFilter />
          </button>
          <FilterQuestionsPopUp
            show={show}
            onCloseModal={closeModalHander}
            setState={props.setFilterState}
            state={props.filterState}
            initialState={props.initialFilterState}
            onApplyfilter={applyFiltersHandler}
          />
        </div>
        <button
          className="create-question-btn"
          onClick={() => {
            props.onChangeDefaultTab('CREATE', null);
          }}
        >
          <FaPlusCircle /> Create MCQ
        </button>
      </div>
      {props.filterState.loading && <LoadingIndicator className="mt-3" />}
      <div
        className="q-card-tbl-titles d-flex"
        style={{ marginTop: props.filterState.loading ? 30 : 45, transition: 'none' }}
      >
        <div>Type</div>
        <div>Title</div>
        <div>Complexity</div>
      </div>

      <div className="q-card-list">
        {props.filterState.questions?.map((qtn: any, idx: any) => (
          <QuestionCard
            key={idx}
            qtn={qtn}
            complexity={qtn.complexity}
            type="MCQ"
            onClick={() => {
              const qdata: QuestionData = {
                questionId: qtn.id || '',
                subjectId: qtn.subjectId || '',
                lessonId: qtn.lessonId || '',
                unitId: qtn.unitId || '',
                grade: qtn.grade || '',
                createdBy: qtn.createdBy || '',
                labelIds: qtn.labels || [],
              };

              props.onChangeDefaultTab('PREVIEW', qdata);
            }}
            searchText={props.filterState.searchText}
          />
        ))}
      </div>
      <PaginationNew
        pages={props.filterState.totalPages}
        setCurrentPage={setCurrentPage}
        clear={0}
      />
    </div>
  );
};

const PreviewQuestionMCQ: FC<Props> = (props) => {
  const [question, setQuestion] = useState<MCQ | null>(null);

  const fetchQuestion = async (id: string) => {
    const doc = await QuestionService.getQuestionById(id);
    if (doc.exists) {
      const question = { id: doc.id, ...doc.data() } as MCQ;
      setQuestion(question);
    }
  };

  useEffect(() => {
    fetchQuestion(props?.tabState?.questionData?.questionId || '');
  }, [props?.tabState?.questionData?.questionId]);

  const appLabels = useLabels();

  const labelsArray = appLabels.labelList
    .filter((label) => question?.labels?.includes(label.id))
    .map((l) => {
      return <Label id={l.id} noIcon></Label>;
    });

  return (
    <>
      <PreviewHeaderActionBar
        className="mt-2 preview"
        onAction={props.onChangeDefaultTab}
        question={question}
      />
      <div className="preview-content">
        <QuestionBody questionContent={question?.question || ''} />
        {question?.answers.map((a, idx) => (
          <AnswerCard
            className="mt-4"
            answerNo={idx + 1}
            answerText={a.answerText}
            type="MCQ"
            isCorrect={a.isCorrect}
            key={idx}
          />
        ))}

        <Row className="preview-bottom no-gutters">
          <Col xs={1}>
            <ComplexityIndicator value={question?.complexity || 0} />
          </Col>
          <Col xs={3}>
            <div className="source-of-question">Source of the question</div>
            <div className="source-of-question-val">{question?.sourceOfQuestion || 'Unknown'}</div>
          </Col>
          <Col xs={3} style={{ overflow: 'hidden' }}>
            <div className="source">Source</div>
            <div className="source-val">{question?.source || 'Unknown'}</div>
          </Col>
          <Col xs={4} style={{ overflow: 'hidden' }}>
            <div className="source">Labels</div>
            <div className="source-val">
              {labelsArray.length ? (
                labelsArray
              ) : (
                <div style={{ width: 30 }} className="text-center">
                  -
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default MCQQuestionTab;
