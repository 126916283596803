import React, { FC } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import AdminContentSecondaryNav from '../../components/AdminContentSecondaryNav';
import PreviewChapterSecondaryNav from '../../components/PreviewChapterSecondaryNav';
import ContentMainPreviewSecondaryNav from '../../components/ContentMainPreviewSecondaryNav';
import RoutingPaths from '../../routes/RoutingPaths';

const ContentSecondaryNav: FC = (props) => {
  const params = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
    droppableId: string;
  };

  const PATHS = new RoutingPaths(params.subjectId, params.lessonId, params.unitId);

  return (
    <Switch>
      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/MainPreview',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/MainPreview',
        ]}
      >
        <ContentMainPreviewSecondaryNav />
      </Route>
      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/Preview/:id',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Preview/:id',
        ]}
      >
        <PreviewChapterSecondaryNav
          subjectId={params.subjectId}
          lessonId={params.lessonId}
          unitId={params.unitId}
        />
      </Route>

      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/Create',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Create',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/Update/:droppableId',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Update/:droppableId',
          PATHS.baseURL,
        ]}
      >
        <AdminContentSecondaryNav
          subjectId={params.subjectId}
          lessonId={params.lessonId}
          unitId={params.unitId}
        />
      </Route>
    </Switch>
  );
};

export default ContentSecondaryNav;
