import React, {useEffect, useState} from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import {Col, Row} from 'react-bootstrap';
import {PaperTypes} from '../enums/paperTypes';

import './DragAndDrop.scss';
import DroppableCol from './DroppableCol';

interface IProps {
  data: any[];
  onChane: (ids: string[]) => void;
  selectedIs: string[];
  replacePaper?: (id: string, type: PaperTypes) => void;
  removePaper?: (id: string) => void;
}
const DragAndDrop = (props: IProps) => {
  const initialData: any = {
    tasks: {
      '1': {id: '1', content: 'Monthly exam 2021 may basics of ICT history'},
    },
    columns: {
      'column-1': {
        id: 'column-1',
        taskIds: [],
      },
      'column-2': {
        id: 'column-2',
        taskIds: [],
      },
    },
    columnOrder: ['column-1', 'column-2'],
  };

  const [data, setData] = useState(initialData);
  const column1 = data.columns['column-1'];
  const column2 = data.columns['column-2'];
  const tasks1 = column1.taskIds.map((taskId: string) => data.tasks[taskId]);
  const tasks2 = column2.taskIds.map((taskId: string) => data.tasks[taskId]);

  useEffect(() => {
    let tasks: any = {};
    let ids: string[] = [];
    if (props.data) {
      props.data.map((item: any) => {
        tasks[item.id] = item;
        if (props.selectedIs.includes(item.id)) {
          return item;
        }
        ids.push(item.id);
        return item;
      });
      setData({
        ...data,
        tasks: tasks,
        columns: {
          ...data.columns,
          'column-2': {...data.columns['column-2'], taskIds: ids},
          'column-1': {...data.columns['column-1'], taskIds: props.selectedIs},
        },
        columnOrder: ['column-1', 'column-2'],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.selectedIs]);

  useEffect(() => {
    if (JSON.stringify(data.columns['column-1'].taskIds) === JSON.stringify(props.selectedIs)) {
      return;
    }
    props.onChane(data.columns['column-1'].taskIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.columns['column-1'].taskIds]);

  const onDragEnd = (result: any) => {
    document.body.style.color = 'inherit';

    const {destination, source, draggableId} = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);

      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };
      setData(newState);
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);

    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setData(newState);
  };
  return (
    <div>
      <Row>
        <Col>
          <DragDropContext onDragEnd={onDragEnd}>
            <Row className="justify-content-between">
              <Col className={'column-container'}>
                <DroppableCol
                  replacePaper={props.replacePaper}
                  removePaper={props.removePaper}
                  isShowAction
                  outLined
                  key={column1.id}
                  column={column1}
                  tasks={tasks1}
                />
              </Col>
              <Col className={'column-container'}>
                <DroppableCol key={column2.id} column={column2} tasks={tasks2} />
              </Col>
            </Row>
          </DragDropContext>
        </Col>
      </Row>
    </div>
  );
};
export default DragAndDrop;
