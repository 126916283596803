import React, {createContext, FC, useContext, useState} from 'react';

export interface FlagContext {
  flag: {operation: string; data: string};
  changeFlag: (value: {operation: string; data: string}) => void;
  changeOperation: (operation: string) => void;
}

interface State {
  flag: {operation: string; data: string};
}

const initialContext: FlagContext = {
  flag: {operation: 'none', data: ''},
  changeFlag: (value: {operation: string; data: string}) => {},
  changeOperation: (operation: string) => {},
};

const initialState: State = {
  flag: {operation: 'none', data: ''},
};

const Context = createContext<FlagContext>(initialContext);

export const FlagProvider: FC = ({children}) => {
  const [state, setState] = useState(initialState);
  const changeFlag = (value: {operation: string; data: string}) => {
    setState((pS) => ({
      ...pS,
      flag: value,
    }));
  };

  const changeOperation = (op: string) => {
    const updatedState = {...state.flag};
    updatedState.operation = op;

    setState((pS) => ({
      ...pS,
      flag: updatedState,
    }));
  };

  return (
    <Context.Provider
      value={{
        ...state,
        changeFlag,
        changeOperation,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useFlag = (): FlagContext => useContext(Context);
