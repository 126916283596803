import { useEffect, useState } from 'react';
import { FaFilter, FaPlusCircle, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Announcement, useAnnouncements } from '../context/AnnouncementContext';
import { useMobileNav } from '../context/MobNavContext';
import { useSubjects } from '../context/SubjectsContext';
import { useAppUser } from '../context/UserContext';
import { Subject } from '../models/Subject';
import BreadCrum from './BreadCrum';
import FilterPopup, { Filters, IFilterState, initialState } from './FilterPopup';
import HamburgerIcon from './HamburgerIcon';
import LoadingIndicator from './LoadingIndicator';
import NoticeCard from './NoticeCard';
import PaginationNew from './PaginationNew';
import './ViewNotices.scss';
import Notification from './..//components/Notification';

interface IProps {
  subjectid?: string;
}

const initialPagination = {
  total_pages: 0,
  page_number: 0,
};
const ITEM_COUNT_IN_PAGE: number = 3;
const ViewNotices = (props: IProps) => {
  const { firestoreUser } = useAppUser();
  const { push } = useHistory();
  const {
    announcements: allAnnouncements,
    deleteAnnouncement,
    readAnnouncement,
  } = useAnnouncements();
  const [loading, setLoading] = useState(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [pagination, setPagination] = useState(initialPagination);
  const [searchText, setSearchText] = useState('');
  const user = useAppUser();
  const [subjects, setSubjects] = useState<{ selected: boolean; subject: Subject }[]>([]);
  const [grades, setGrades] = useState<{ selected: boolean; grade: string }[]>([]);
  const { allSubjects } = useSubjects();
  const [filterState, setFilterState] = useState<IFilterState>({
    subject: 'All',
    status: 'All',
    grades: [],
    subjects: [],
  });

  useEffect(() => {
    setFilterState((ps) => {
      return { ...ps, subjects: allSubjects };
    });
  }, [allSubjects]);

  useEffect(() => {
    const subject = allSubjects.find((s) => s.id === props.subjectid);
    const x = grades.map((g) => g.grade).map((g) => ({ selected: subject?.grade === g, grade: g }));

    setFilterState((ps) => ({ ...ps, grades: x }));

    // setFilterState((ps) => {
    //   const subject = allSubjects.find((s) => s.id === props.subjectid);
    //   const x = grades
    //     .map((g) => g.grade)
    //     .map((g) => ({ selected: subject?.grade === g, grade: g }));
    //   return { ...ps, grades: x };

    // });
  }, [grades, props.subjectid, allSubjects]);

  const onGradeChange = (g: { selected: boolean; grade: string }[]) => {
    setFilterState((ps) => {
      ps.subjects = [];
      let s = subjects
        .map((s) => s.subject)
        .filter((s) =>
          g
            .filter((x) => x.selected)
            .map((x) => x.grade)
            .includes(s.grade)
        );
      if (ps.grades.filter((ps) => ps.selected === true).length === 0) {
        s = allSubjects;
      }
      return { ...ps, subjects: s };
    });
  };

  const [showFilterDialog, setShowFilterDialog] = useState(false);

  useEffect(() => {
    let subjects = allSubjects;
    if (user.firestoreUser?.userRole?.isInstructor) {
      subjects = subjects.filter(
        (s) =>
          s.createdBy === user.fireUser?.uid || s.assignedUsers?.includes(user.fireUser?.uid || '')
      );
    } else if (user.firestoreUser?.userRole?.isStudent) {
      subjects = subjects.filter((s) => s.enrolledStudents?.includes(user.fireUser?.uid || ''));
    } else if (user.firestoreUser?.userRole?.isTeachingAssistant) {
      subjects = subjects.filter((s) => s.assignedUsers?.includes(user.fireUser?.uid || ''));
    }

    const nSubjects = subjects.map((s) => {
      return {
        selected: false,
        subject: s,
      };
    });
    setSubjects(nSubjects);
    const a = nSubjects.map((s) => {
      return {
        selected: false,
        grade: s.subject.grade,
      };
    });
    const grades = a.filter(function (value, index, self) {
      return index === self.findIndex((t) => t.grade === value.grade);
    });

    console.log('GA ', grades);
    setGrades(
      grades.map((g) => {
        return { ...g, selected: false };
      })
    );
  }, [user.fireUser?.uid, user.firestoreUser?.userRole, allSubjects]);

  useEffect(() => {
    if (firestoreUser?.userRole?.isStudent || firestoreUser?.userRole?.isTeachingAssistant) {
      if (props.subjectid) {
        setAnnouncements(
          allAnnouncements
            .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
            .filter((a) => a.isActive)
            .filter((a) => a.subjects?.includes(props.subjectid || ''))
        );
      } else {
        setAnnouncements(
          allAnnouncements
            .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
            .filter((a) => a.isActive)
        );
      }
    } else {
      if (props.subjectid) {
        setAnnouncements(
          allAnnouncements
            .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
            .filter((a) => a.subjects?.includes(props.subjectid || ''))
        );
      } else {
        setAnnouncements(
          allAnnouncements.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
        );
      }
    }
  }, [allAnnouncements, firestoreUser?.userRole, props.subjectid]);

  useEffect(() => {
    if (announcements.length % ITEM_COUNT_IN_PAGE === 0) {
      const totalPages = announcements.length / ITEM_COUNT_IN_PAGE;
      setPagination((ps) => ({ ...ps, total_pages: totalPages }));
    } else {
      const totalPages = Math.floor(announcements.length / ITEM_COUNT_IN_PAGE) + 1;
      setPagination((ps) => ({ ...ps, total_pages: totalPages }));
    }
  }, [announcements]);

  const filter = (filters?: Filters) => {
    let f_announcements = allAnnouncements;
    if (firestoreUser?.userRole?.isStudent || firestoreUser?.userRole?.isTeachingAssistant) {
      f_announcements = f_announcements.filter((a) => a.isActive);
    }
    if (props.subjectid) {
      f_announcements = f_announcements.filter((a) => a.subjects?.includes(props.subjectid || ''));
    }
    setAnnouncements([]);

    if (searchText) {
      f_announcements = f_announcements.filter((announcement) => {
        if (announcement.title && searchText) {
          return announcement.title
            .replace(/<[^>]+>/g, '')
            .replace(/&nbsp;/g, '')
            .toLowerCase()
            .startsWith(searchText.trim().toLowerCase());
        } else {
          return true;
        }
      });
    }

    if (filters) {
      switch (filters.status) {
        case 'Active':
          f_announcements = f_announcements.filter((a) => a.isActive);
          break;
        case 'Upcoming':
          f_announcements = f_announcements.filter(
            (a) => !a.isActive && a.date.toDate().getTime() > new Date().getTime()
          );
          break;
        case 'Inactive':
          f_announcements = f_announcements.filter(
            (a) => !a.isActive && a.date.toDate().getTime() < new Date().getTime()
          );
          break;
        default:
      }

      if (filters.subject && filters.subject !== 'All') {
        f_announcements = f_announcements.filter((a) =>
          a.subjects?.includes((filters.subject as any)?.id)
        );
      }

      if (filters.grades.length >= 1 && filters.grades) {
        f_announcements = f_announcements
          .filter((a) => a.grades?.every((value) => filters.grades.includes(value)))
          .filter((a) => a.grades?.length !== 0);
      }
    }

    f_announcements.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

    setAnnouncements(f_announcements);
  };

  useEffect(() => {
    filter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const paginate = (
    array: Announcement[],
    page_number: number,
    page_size: number
  ): Announcement[] => array.slice(page_number * page_size, (page_number + 1) * page_size);

  const mobileNav = useMobileNav();
  const appUser = useAppUser();

  const getSelectedSubject = () => {
    if (props.subjectid) {
      const [subject] = allSubjects.filter((s) => s.id === props.subjectid);
      if (subject) return subject;
    }
    return undefined;
  };

  return (
    <div
      className="view-notices-wraper"
      style={{
        paddingBottom: '60px',
      }}
    >
      <div>
        {!props.subjectid && (
          <>
            {appUser.firestoreUser?.userRole?.isAdmin ||
            appUser.firestoreUser?.userRole?.isInstructor ? (
              <BreadCrum className="d-none d-md-flex" />
            ) : (
              <div className="d-md-flex justify-content-between align-items-center">
                <div className="d-flex d-md-none justify-content-between align-items-center ">
                  {/* <BreadCrum /> */}
                  <HamburgerIcon
                    onClick={() => {
                      mobileNav.setOpenMobileNav(!mobileNav.openMobileNav);
                    }}
                    className="ml-auto"
                  />
                </div>
                <BreadCrum className="d-none d-md-flex" />
              </div>
            )}
          </>
        )}
        {loading && (
          <div className="mt-4">
            <LoadingIndicator />
          </div>
        )}
        <div className="topbar-wrapper">
          <div className="controller-wrapper">
            <div className="search-filed">
              <div className="sf-input">
                <input
                  className="search_text"
                  placeholder="Search Announcements"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    filter();
                  }}
                />
              </div>
              <div>
                <div className="sf-icon search_button" onClick={() => filter()}>
                  <FaSearch />
                </div>
              </div>
            </div>
            {(!props.subjectid ||
              firestoreUser?.userRole?.isAdmin ||
              firestoreUser?.userRole?.isInstructor) && (
              <div className="action-filter" style={{ position: 'relative' }}>
                <button className="f-button" onClick={() => setShowFilterDialog(true)}>
                  Filter <FaFilter className="ml-2" />
                </button>
                <FilterPopup
                  isShow={showFilterDialog}
                  filterState={filterState}
                  isSubjectBy={!!props.subjectid}
                  selectedSubject={getSelectedSubject()}
                  onClose={() => {
                    setShowFilterDialog(false);
                  }}
                  onApplyFilters={(f) => {
                    filter(f);
                  }}
                  onGradeChange={(item) => {
                    setFilterState((ps) => {
                      const g = ps.grades.map((x) => {
                        if (x.grade === item.grade) {
                          return {
                            ...x,
                            selected: !x.selected,
                          };
                        } else {
                          return x;
                        }
                      });
                      onGradeChange(g);
                      return { ...ps, grades: g };
                    });
                  }}
                  onSubjectChange={(value) => {
                    setFilterState((ps) => {
                      const [found] = filterState.subjects?.filter((s) => s.id === value) || [];
                      if (found) {
                        return { ...ps, subject: found };
                      } else {
                        return { ...ps, subject: 'All' };
                      }
                    });
                  }}
                  onClear={() => {
                    setFilterState({
                      ...initialState,
                      grades: grades
                        .map((g) => g.grade)
                        .map((g) => ({ selected: false, grade: g })),
                    });
                  }}
                  onStateChange={(value) => {
                    setFilterState((ps) => {
                      return {
                        ...ps,
                        status: value as 'All' | 'Active' | 'Inactive' | 'Upcoming',
                      };
                    });
                  }}
                />
              </div>
            )}
          </div>
          <div>
            {(firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) &&
              subjects.length > 0 && (
                <button
                  className="f-button d-flex align-items-center justify-content-center gap-2"
                  onClick={() => {
                    if (props.subjectid) {
                      push(`/my-subjects/announcements/create/${props.subjectid}`);
                    } else {
                      push('/announcements/create');
                    }
                  }}
                >
                  <FaPlusCircle className="mr-2" style={{ fontSize: '16px' }} /> New Announcement
                </button>
              )}
          </div>
        </div>
        ;
        {paginate(announcements, pagination.page_number, ITEM_COUNT_IN_PAGE).map((a, index) => (
          <NoticeCard
            key={index}
            announcement={a}
            subjectId={props.subjectid}
            onDelete={(a) => {
              setLoading(true);
              deleteAnnouncement(a.id, () => {
                setLoading(false);
                Notification({
                  isSuccess: true,
                  message: 'Notice Successfully Deleted!',
                });
              });
            }}
            onRead={(a) => {
              setLoading(true);
              readAnnouncement(a.id, () => {
                setLoading(false);
              });
            }}
            onEdit={(a) => {
              if (props.subjectid) {
                push(`/my-subjects/subject/${props.subjectid}/Announcements/edit/${a.id}`);
              } else {
                push(`/announcements/edit/${a.id}`);
              }
            }}
          />
        ))}
      </div>
      <div>
        <PaginationNew
          pages={pagination.total_pages}
          setCurrentPage={(pageNumber) => {
            setPagination({ ...pagination, page_number: pageNumber - 1 });
          }}
          clear={pagination.total_pages}
        />
      </div>
    </div>
  );
};

export default ViewNotices;
