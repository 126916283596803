import React, {FC} from 'react';
import truncate from 'truncate';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import './UnitDropdownCard.scss';
import {Unit} from '../models/Unit';

interface Props {
  className: string;
  inputChangeHandler: (
    state: any,
    name: string,
    value: {
      displayValue: string;
      id: string;
    }
  ) => void;
  setState: (value: React.SetStateAction<boolean>) => void;
  id: string;
  state: any;
  stateName: string;
  noValueText: string;
  unit?: Unit;
  truncateNumber?: number;
}

const UnitDropdownCard: FC<Props> = (props) => {
  const users = useAdminsAndInstructors();
  const user = props.unit?.createdBy ? props.unit?.createdBy : props.unit?.updatedBy;
  const createdBy = users.allAdminsAndInstructors.find((u) => u.uid === user)?.username;

  return (
    <div
      className={`unit-dropdown-card ${props.className}`}
      onClick={(e) => {
        props.inputChangeHandler(props.state, props.stateName, {
          displayValue: props.unit?.name || '',
          id: props.unit?.id || '',
        });
        props.setState(false);
        e.stopPropagation();
      }}
      id={props.id}
    >
      <div className="unit-dropdown-card__img">
        <img src={`${props.unit?.coverImage}`} alt="unit" />
      </div>

      <div className="unit-dropdown-card__right">
        <div className="unit-dropdown-card__right-title">
          {' '}
          {props.unit?.id === ''
            ? props.noValueText
            : truncate(props.unit?.name || '', props.truncateNumber || 40)}
        </div>

        <div className="unit-dropdown-card__right-instructor">By {createdBy}</div>
      </div>
    </div>
  );
};

export default UnitDropdownCard;
