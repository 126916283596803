import React, { FC, useEffect, useState } from 'react';
import { FaCopy, FaImage, FaWindowClose } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { useFunctions, useStorage } from 'reactfire';
import truncate from 'truncate';
import { useUnits } from '../context/UnitsContext';
import { useAppUser } from '../context/UserContext';
import { validationResult } from '../utils';
import Notification from './Notification';
import LoadingIndicator from './LoadingIndicator';
import ImageField from './ImageField';
import TextInput from './TextInput';
import { createUnitInitialState } from '../interfaces';
import Modal from 'react-modal';
import './AddUnitModal.scss';
import TextFieldInput from './TextFieldInput';
import { useParams } from 'react-router-dom';
import { Unit } from '../models/Unit';

interface Props {
  show: boolean;
  selectedUnit?: Unit;
  unitModelHandler?: (value: boolean) => void;
}

const CreateUnitModel: FC<Props> = (props) => {
  const appUnits = useUnits();
  const appUser = useAppUser();

  const params = useParams() as { subjectId: string; lessonId: string };

  const subjectId = params.subjectId;
  const lessonId = params.lessonId;

  const lessonBasedUnitNames = appUnits.units
    .filter((unit) => {
      return unit.lessonId === lessonId && unit.id !== props.selectedUnit?.id;
    })
    .map((unit) => {
      return unit.name.toLowerCase();
    });

  const closeUnitModalInitialState = {
    ...createUnitInitialState,
    subject: { ...createUnitInitialState.subject, value: subjectId },
    lesson: { ...createUnitInitialState.lesson, value: lessonId },
  };

  const updateUnitInitialState = {
    ...createUnitInitialState,
    subject: { ...createUnitInitialState.subject, value: subjectId },
    lesson: { ...createUnitInitialState.lesson, value: lessonId },
    name: { ...createUnitInitialState.name, value: props.selectedUnit?.name || '' },
    coverImage: {
      ...createUnitInitialState.coverImage,
      value: props.selectedUnit?.coverImage || '',
    },
    description: {
      ...createUnitInitialState.description,
      value: props.selectedUnit?.description || '',
    },
  };

  const [state, setState] = useState(() => {
    const updatedCreateUnitInitialState = {
      ...createUnitInitialState,
      subject: { ...createUnitInitialState.subject, value: subjectId },
      lesson: { ...createUnitInitialState.lesson, value: lessonId },
    };
    return updatedCreateUnitInitialState;
  });

  useEffect(() => {
    const updatedCreateUnitInitialState = {
      ...createUnitInitialState,
      subject: { ...createUnitInitialState.subject, value: subjectId },
      lesson: { ...createUnitInitialState.lesson, value: lessonId },
    };

    const updateUnitInitialState = {
      ...createUnitInitialState,
      subject: { ...createUnitInitialState.subject, value: props.selectedUnit?.subjectId || '' },
      lesson: { ...createUnitInitialState.lesson, value: props.selectedUnit?.lessonId || '' },
      name: { ...createUnitInitialState.name, value: props.selectedUnit?.name || '' },
      coverImage: {
        ...createUnitInitialState.coverImage,
        value: props.selectedUnit?.coverImage || '',
      },
      description: {
        ...createUnitInitialState.description,
        value: props.selectedUnit?.description || '',
      },
    };

    const dataToSetToState = props.selectedUnit
      ? updateUnitInitialState
      : updatedCreateUnitInitialState;

    setState(dataToSetToState);
  }, [props.selectedUnit, subjectId, lessonId]);

  const createUnitRef = useFunctions().httpsCallable('createUnit');
  const updateUnitRef = useFunctions().httpsCallable('updateUnit');

  const storage = useStorage();

  const types = ['image/png', 'image/jpeg'];

  const addUnitHandler = async () => {
    if (lessonBasedUnitNames.includes(state.name.value.toLowerCase().trim() || '')) {
      return setState((ps) => {
        return {
          ...ps,
          name: { ...ps.name, error: 'This unit already exists' },
        };
      });
    }

    const validationOutput = validationResult({ ...state, loading: true });

    setState(validationOutput.state);
    let subjectCreateFormValidty = true;

    // guard 1
    if (!!validationOutput.formValidity) {
      subjectCreateFormValidty = false;
    }

    if (!subjectCreateFormValidty) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    try {
      const unt = await createUnitRef({
        name: state.name.value,
        lowerCaseName: state.name.value.toLowerCase(),
        description: state.description.value,
        coverImage: state.coverImage.value || '',
        subjectId: subjectId,
        lessonId: lessonId,
      });

      appUnits.addUnit(unt.data.data);

      Notification({
        isSuccess: true,
        message: 'Unit created successfully',
      });
    } catch (e) {
      console.log('ERROR ', e);

      Notification({
        isSuccess: false,
        message: 'Failed to create unit.Try again later',
      });
    } finally {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));

      setState(closeUnitModalInitialState);

      props.unitModelHandler && props.unitModelHandler(false);
    }
  };

  const updateUnitHandler = async () => {
    if (lessonBasedUnitNames.includes(state.name.value.toLowerCase().trim()) || '') {
      return setState((ps) => {
        return {
          ...ps,
          name: { ...ps.name, error: 'This Unit already exists' },
        };
      });
    }
    const validationOutput = validationResult({ ...state, loading: true });

    setState(validationOutput.state);
    let subjectCreateFormValidty = true;

    //guard 1
    if (!!validationOutput.formValidity) {
      subjectCreateFormValidty = false;
    }

    if (!subjectCreateFormValidty) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    try {
      const unit = await updateUnitRef({
        subjectId: state.subject.value,
        lessonId: state.lesson.value,
        id: props.selectedUnit?.id || '',
        name: state.name.value,
        lowerCaseName: state.name.value.toLowerCase(),
        coverImage: state.coverImage.value || 'image',
        description: state.description.value || '',
      });

      appUnits.updateUnit(unit.data.data);
      Notification({
        isSuccess: true,
        message: 'Unit updated successfully',
      });
    } catch (e) {
      console.log('ERROR ', e);

      Notification({
        isSuccess: false,
        message: 'Failed to updated unit.Try again later',
      });
    } finally {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));

      props.unitModelHandler && props.unitModelHandler(false);
    }
  };

  const changeHandler = (e: any) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      const size = selected.size / 1024 / 1024;
      if (size > 1) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
        return;
      }

      const storageRef = storage.ref().child(`unitImages/${appUser.fireUser?.uid}_${+new Date()}`);

      let unitURL: any = null;

      setState((ps) => ({
        ...ps,
        coverImage: { ...ps.coverImage, loading: true },
      }));

      storageRef.put(selected).on(
        'state_changed',
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          console.log('upload percentage ', percentage);
        },
        (err) => {
          Notification({
            isSuccess: false,
            message: 'Failed to upload image. ',
          });

          setState((ps) => ({
            ...ps,
            coverImage: {
              ...ps.coverImage,
              file: null,
              error: 'Failed to upload image',
              value: '',
              loading: false,
            },
          }));
        },
        async () => {
          unitURL = await storageRef.getDownloadURL();

          setState((ps) => ({
            ...ps,
            coverImage: {
              ...ps.coverImage,
              file: selected,
              error: '',
              value: unitURL || '',
              loading: false,
            },
          }));
        }
      );
    } else {
      setState((ps) => ({
        ...ps,
        coverImage: {
          ...ps.coverImage,
          file: null,
          error: 'Please select an image file (png or jpeg)',
          value: '',
        },
      }));
    }
  };

  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="add-unit-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="add-unit-modal__header">
        {props.selectedUnit ? 'Update Unit' : 'Add Unit'}
        <FaWindowClose
          color="#FF4C6C"
          size={20}
          style={
            !(state.loading || state.coverImage.loading)
              ? { cursor: 'pointer' }
              : { cursor: 'default' }
          }
          onClick={() => {
            if (!(state.loading || state.coverImage.loading)) {
              props.unitModelHandler && props.unitModelHandler(false);
              if (props.selectedUnit) {
                setState(updateUnitInitialState);
              } else {
                setState(createUnitInitialState);
              }
            }
          }}
        />
      </div>
      {state.loading && <LoadingIndicator />}

      <div className="add-unit-copy__btn add-unit-copy__btn--disabled" style={{ marginTop: 28 }}>
        <FaCopy className="mr-1" /> Copy From Existing Unit
      </div>
      <div className="add-unit-modal__main">
        <div className="add-unit-modal-inputs">
          <TextInput
            className="auth-input-margin-signup"
            stateName="name"
            stateValue={state.name.value}
            state={state}
            setState={setState}
            error={state.name.error}
            placeHolder="Unit name"
          />
          <ImageField
            style={{ marginTop: 20 }}
            stateName="coverImage"
            stateValue={truncate(state.coverImage.file?.name || '', 15)}
            state={state}
            setState={setState}
            error={state.coverImage.error}
            placeHolder="Cover Image"
            fileLoading={state.coverImage.loading}
            formLoading={state.loading}
            onChange={changeHandler}
            disableTextField={{ pointerEvents: 'none' }}
          />

          <TextFieldInput
            name="Unit Description (40 words)"
            stateName="description"
            className="add-unit-model-description-field"
            stateValue={state.description.value}
            state={state}
            setState={setState}
            error={state.description.error}
            isUpdate={props.selectedUnit ? true : false}
          />

          <button
            className={`add-unit-modal__btn ${
              state.loading || state.coverImage.loading ? 'add-unit-modal__btn-disabled' : ''
            }`}
            onClick={() => {
              if (!(state.loading || state.coverImage.loading)) {
                if (props.selectedUnit) {
                  updateUnitHandler();
                } else {
                  addUnitHandler();
                }
              }
            }}
          >
            {props.selectedUnit ? 'Update Unit' : 'Add Unit'}
          </button>
        </div>

        <div
          className="add-unit-modal-image position-relative"
          style={{ backgroundImage: `url(${state.coverImage.value})` }}
        >
          {!state.coverImage.value && (
            <FaImage
              color="#474A66"
              style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              className="position-absolute"
              size={40}
            />
          )}
          {state.coverImage.loading && (
            <div
              style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              className="position-absolute"
            >
              <ClipLoader color="#246bfd" loading={true} size={90} speedMultiplier={0.7} />
            </div>
          )}
        </div>

        <button
          className={`add-unit-modal__btn-mobile ${
            state.loading || state.coverImage.loading ? 'add-unit-modal__btn-disabled' : ''
          }`}
          onClick={() => {
            if (!(state.loading || state.coverImage.loading)) {
              if (props.selectedUnit) {
                updateUnitHandler();
              } else {
                addUnitHandler();
              }
            }
          }}
        >
          {props.selectedUnit ? 'Update Unit' : 'Add Unit'}
        </button>
      </div>
    </Modal>
  );
};

export default CreateUnitModel;
