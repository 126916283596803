import React, { FC, useState } from 'react';
import Modal from 'react-modal';
import './UserInformationModal.scss';
import { FaUserCircle, FaWindowClose } from 'react-icons/fa';
import InstructorBadge from './InstructorBadge';
import moment from 'moment';
import { useFirestore, useFunctions } from 'reactfire';
import { UserFirestore } from '../models/UserFirestore';
import LoadingIndicator from './LoadingIndicator';
import ButtonComp from './ButtonComp';
import { truncateText } from '../utils';
import Notification from './Notification';
// import { Tooltip as TooltipNew } from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css';

interface Props {
  isShow: boolean;
  onClose: () => void;
  isInstructorValidation?: boolean;
  isTeachingAssistantApproved?: boolean;
  role?: { text: string; validated: boolean | undefined | null };
  index: number;
  users?: UserFirestore[];
  setUsers?: React.Dispatch<React.SetStateAction<any[]>>;
  user?: UserFirestore;
  changeHandler: (value: boolean) => void;
  loading: boolean | undefined;
}

const UserInformationModal: FC<Props> = (props) => {
  const approveInstructorRef = useFunctions().httpsCallable('approveInstructor');

  const [modalState, setModalState] = useState<{ loading: boolean }>({ loading: false });

  const store = useFirestore();

  const enableDisableBtn = !props.user?.enabled ? (
    <ButtonComp
      type="one"
      onClick={() => {
        props.changeHandler(true);
      }}
      text="Activate"
      style={{ height: 35, borderRadius: 7, width: '100%' }}
      loading={props.loading}
    />
  ) : (
    <ButtonComp
      type="one"
      outline
      outlineColor="#FF4C6C"
      onClick={() => {
        props.changeHandler(false);
      }}
      text="Deactivate"
      style={{ height: 35, borderRadius: 7, width: '100%' }}
      loading={props.loading}
    />
  );

  return (
    <Modal
      isOpen={props.isShow}
      onRequestClose={props.onClose}
      contentLabel="Example Modal"
      className="user-infor-modal-wrapper"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="user-infor-modal">
        {modalState.loading && <LoadingIndicator />}

        <div className="user-infor-modal__header">
          <div className="user-infor-modal__image">
            {props.user?.photoURL ? (
              <div style={{ backgroundImage: `url(${props.user?.photoURL})` }}></div>
            ) : (
              <FaUserCircle size={100} />
            )}
          </div>
          <div className="user-infor-modal__name">
            <div
              className="user-infor-modal__firstname"
              style={{ zIndex: 10000 }}
              data-tooltip-id="tooltip-user-firstname"
              data-tooltip-content={props.user?.firstName}
            >
              <div> {truncateText(props.user?.firstName || '-', 22)}</div>
            </div>
            {/* <TooltipNew
              id="tooltip-user-firstname"
              place="bottom"
              style={{ color: 'white', backgroundColor: 'black' }}
            /> */}

            <div
              className="user-infor-modal__lastname"
              data-tooltip-id="tooltip-user-lastname"
              data-tooltip-content={props.user?.lastName}
            >
              <div> {truncateText(props.user?.lastName || '-', 12)}</div>
            </div>
            {/* <TooltipNew
              id="tooltip-user-lastname"
              place="bottom"
              style={{ color: 'white', backgroundColor: 'black' }}
            /> */}

            <div className="user-infor-modal__role">
              <InstructorBadge userType={props.role} />
            </div>
          </div>
          <div className="user-infor-modal__close">
            <FaWindowClose
              color="#FF4C6C"
              size={20}
              onClick={props.onClose}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>

        <div className="user-infor-modal__body">
          <div className="user-infor-modal__username">
            <div>Username</div>
            <div data-tooltip-id="tooltip-username" data-tooltip-content={props.user?.username}>
              {truncateText(props.user?.username || '-', 20)}
            </div>
          </div>

          {/* <TooltipNew
            id="tooltip-username"
            place="bottom"
            style={{ color: 'white', backgroundColor: 'black' }}
          /> */}

          <div className="user-infor-modal__margin"></div>
          <div
            className="user-infor-modal__email"
            data-tooltip-id="tooltip-user-email"
            data-tooltip-content={props.user?.email}
          >
            <div>Email</div>
            <div>{truncateText(props.user?.email || '-', 20)}</div>
          </div>

          {/* <TooltipNew
            id="tooltip-user-email"
            place="bottom"
            style={{ color: 'white', backgroundColor: 'black' }}
          /> */}

          <div className="user-infor-modal__address">
            <div>Address</div>
            <div>{props.user?.address || '-'}</div>
          </div>
          <div className="user-infor-modal__margin"></div>
          <div className="user-infor-modal__phone">
            <div>Phone</div>
            <div>{props.user?.phoneNumber || '-'}</div>
          </div>

          <div className="user-infor-modal__whatsapp-no">
            <div>Whatsapp Number</div>
            <div>{props.user?.whatsAppNumber || '-'}</div>
          </div>
          <div className="user-infor-modal__dob">
            <div>Date of birth</div>
            <div>{props.user?.dob ? moment(props.user?.dob).format('L') : '-'}</div>
          </div>
        </div>

        <div className="user-infor-modal__footer">
          {props.isInstructorValidation ? (
            <div className="d-flex">
              <ButtonComp
                type="one"
                onClick={() => {
                  setModalState((ps) => ({ ...ps, loading: true }));
                  approveInstructorRef({ id: props.user?.uid, value: true })
                    .then((res) => {
                      if (props.users) {
                        const usersFromDB: any[] = [];
                        store
                          .collection('users')
                          .orderBy('lowercaseFirstName')
                          .get()
                          .then((res: any) => {
                            res.forEach((doc: any) => {
                              usersFromDB.push({ ...doc.data(), id: doc.id });
                            });
                            Notification({
                              isSuccess: true,
                              message: 'User validation successfull',
                            });
                            props.setUsers && props.setUsers([...usersFromDB]);
                          })
                          .catch(() => {
                            console.log('Error occured fetching user from DB');
                            Notification({
                              isSuccess: false,
                              message: 'User validation failed! Please try again later.',
                            });
                          })
                          .finally(() => {
                            setModalState((ps) => ({ ...ps, loading: false }));
                            props.onClose();
                          });
                      }
                    })
                    .catch((e) => {
                      console.log('ERROR ', e);
                    });
                }}
                text="Accept"
                style={{ height: 35, borderRadius: 7, width: '46.5%' }}
                loading={modalState.loading}
              />
              <div style={{ width: '7%' }}></div>
              {props.role?.validated === undefined ? (
                <ButtonComp
                  type="one"
                  outline
                  onClick={() => {
                    setModalState((ps) => ({ ...ps, loading: true }));
                    approveInstructorRef({ id: props.user?.uid, value: false })
                      .then((res) => {
                        if (props.users) {
                          const usersFromDB: any[] = [];

                          store
                            .collection('users')
                            .orderBy('lowercaseFirstName')
                            .get()
                            .then((res: any) => {
                              res.forEach((doc: any) => {
                                usersFromDB.push({ ...doc.data(), id: doc.id });
                              });

                              props.setUsers && props.setUsers([...usersFromDB]);

                              Notification({
                                isSuccess: true,
                                message: 'User validation disapproved successfully',
                              });
                            })
                            .catch(() => {
                              console.log('Error occured fetching user from DB');
                              Notification({
                                isSuccess: false,
                                message:
                                  'User validation disapproval failed! Please try again later.',
                              });
                            })
                            .finally(() => {
                              setModalState((ps) => ({ ...ps, loading: false }));
                              props.onClose();
                            });
                        }
                      })
                      .catch((e) => {
                        console.log('ERROR ', e);
                      });
                  }}
                  text="Decline"
                  outlineColor="#FF4C6C"
                  style={{ height: 35, borderRadius: 7, width: '46.5%' }}
                  loading={modalState.loading}
                />
              ) : (
                <ButtonComp
                  type="one"
                  onClick={() => {}}
                  text="Decline"
                  className={'button-disable_with-cursor'}
                  disabled={true}
                  style={{
                    height: 35,
                    borderRadius: 7,
                    width: '46.5%',
                  }}
                  loading={modalState.loading}
                />
              )}
            </div>
          ) : (
            enableDisableBtn
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UserInformationModal;
