import React, {FC} from 'react';
import {useAppUser} from '../context/UserContext';
import {Package} from '../models/Package';
import {FaCheck} from 'react-icons/fa';

import './PackageCard.scss';
import {PACKAGE_TYPE_TABS} from '../enums';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';

interface Props {
  packge: Package;
  tab?: PACKAGE_TYPE_TABS;
  onClick: () => void;
}

const PackageCard: FC<Props> = (props) => {
  const user = useAppUser();
  const {ALL_PACKAGES} = PACKAGE_TYPE_TABS;
  const users = useAdminsAndInstructors();

  const createdBy = users.allAdminsAndInstructors.find(
    (u) => u.uid === props.packge?.createdBy
  )?.username;
  const style = createdBy ? {} : ({visibility: 'hidden'} as const);
  const endDateStyle = props.packge.type === 'Unlimited' ? ({visibility: 'hidden'} as const) : {};
  return (
    <>
      {user.firestoreUser?.userRole?.isAdmin || user.firestoreUser?.userRole?.isInstructor ? (
        <div className="package-card" onClick={props.onClick}>
          <img
            className="package-card__img"
            style={{
              backgroundImage:
                props.packge.coverImage && props.packge.coverImage !== ''
                  ? `url(${props.packge.coverImage})`
                  : "url( '/book.png')",
            }}
            alt=""
          />
          <div className="package-card__content">
            <div className="package-card__title">{props.packge.title}</div>
            <div className="package-card__desc" style={style}>
              By {createdBy}{' '}
            </div>
            <div className="package-card__footer package-card__space">
              <div className="package-card__date" style={endDateStyle}>
                End Date: {props.packge.endDate}{' '}
              </div>
              <div className="package-card__price">Rs. {props.packge.price} </div>
            </div>
          </div>
        </div>
      ) : null}

      {user.firestoreUser?.userRole?.isStudent ? (
        <div
          className={`package-card ${
            props.packge.subscribed === false && 'package-card--unsbscribe'
          }`}
          onClick={props.onClick}
        >
          <img
            className="package-card__img"
            style={{
              backgroundImage:
                "url( 'https://media.istockphoto.com/photos/tranquil-scene-on-open-ocean-with-calm-seas-picture-id1284942238?s=612x612",
            }}
            alt=""
          />
          <div className="package-card__content">
            <div className="package-card__title">{props.packge.title}</div>
            <div className="package-card__desc">By {props.packge.createdBy} </div>
            <div className="package-card__footer package-card__space">
              {props.packge.subscribed === true ? (
                <div className="package-card__remain-time">
                  Time Left: {props.packge.remainTime}{' '}
                </div>
              ) : null}

              {props.tab === ALL_PACKAGES && props.packge.subscribed === true ? (
                <div className="package-card__subscribed">
                  Subscribed &nbsp;
                  <FaCheck />
                </div>
              ) : null}
            </div>
            <div className="package-card__footer">
              <div className="package-card__date">End Date: {props.packge.endDate} </div>

              {props.packge.subscribed === false ? (
                <div className="package-card__price-box-group">
                  <div className="package-card__price-box  package-card__price package-card__price__box-padding">
                    Subscribe Rs.{props.packge.price}
                  </div>
                </div>
              ) : (
                <div className="package-card__price">Rs. {props.packge.price} </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PackageCard;
