import React, {Fragment, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import PackageList from '../../components/PackageList';
import PackageTypesTabs from '../../components/PackageTypesTabs';
import PrimaryNav from '../../components/PrimaryNav';
import StudentSecondaryNavWithLegacyCode from '../../components/StudentSecondaryNavWithLegacyCode';
import {SUBJECT_TAB} from '../../constants';
import {PACKAGE_TYPE_TABS} from '../../enums';
import {StudentPackageDirectiveInitialState, PACKAGE_DIRECTIVE_STATE} from '../../interfaces';
import LayoutNew from '../../layouts/LayoutNew';
import BreadCrumbUpdate from '../../shared/BreadCrumbUpdate';

const StudentPackageListScreen = () => {
  const history = useHistory();
  const params = useParams() as {subjectId: string; examId: string};
  const [data, setData] = useState<PACKAGE_DIRECTIVE_STATE>(StudentPackageDirectiveInitialState);

  const changeTabHandler = (tab: PACKAGE_TYPE_TABS) => {
    setData((ps) => ({
      ...ps,
      tab: tab,
    }));
  };

  const [state, setState] = useState({
    currentTab: SUBJECT_TAB.LESSONS,
    currentSubTab: 'Live Exams',
  });

  const changeCurrentTabHandler = (tab: string) => {
    let setText = tab;
    if (tab === 'Model Papers') {
      setText = 'Modal Papers';
    }

    history.push(`/my-subjects/subject/${params.subjectId}/${setText}`);

    setState({...state, currentTab: tab});
  };

  const changeCurrentSubTabHandler = (subTab: string) => {
    setState({...state, currentSubTab: subTab});
    history.push(`/my-subjects/subject/${params.subjectId}/Modal Papers`);
  };

  let primaryNavigation = <PrimaryNav />;
  let secondaryNavigation = (
    <StudentSecondaryNavWithLegacyCode
      onCurrentTabChange={changeCurrentTabHandler}
      onCurrentSubTabChange={changeCurrentSubTabHandler}
      currentTab={state.currentTab}
      currentSubTab={state.currentSubTab}
      screen={''}
    />
  );
  let appHeader = <BreadCrumbUpdate />;
  let appBody = (
    <Fragment>
      <PackageTypesTabs changeTabHandelr={changeTabHandler} tab={data.tab} />
      <PackageList tab={data.tab} />;
    </Fragment>
  );
  return (
    <Fragment>
      <LayoutNew
        primaryNav={primaryNavigation}
        secondaryNav={secondaryNavigation}
        header={appHeader}
        body={appBody}
      />
    </Fragment>
  );
};

export default StudentPackageListScreen;
