import React, {FC, useEffect} from 'react';
import {FaClock} from 'react-icons/fa';
import {getDateToSave} from '../utils';
import './TimeInputComp.scss';

interface Props {
  text: string;
  state: any;
  stateName: string;
  setState: Function;
  dateTime?: boolean;
  style: React.CSSProperties;
  error?: string;
  layoutStyles?: React.CSSProperties;
  isDays?: boolean;
  triggerOnChange?: (beginTime: Date, endDate: Date) => void;
}

const TimeInputComp: FC<Props> = (props) => {
  const onKeyDown = (evt: any) => {
    ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault();
  };

  useEffect(() => {
    if (props.state[props.stateName].value.hours > 23 && props.dateTime) {
      props.setState({
        ...props.state,
        [props.stateName]: {
          ...props.state[props.stateName],
          value: {...props.state[props.stateName].value, hours: ''},
        },
      });
    }

    if (props.state[props.stateName].value.minutes > 59) {
      props.setState({
        ...props.state,
        [props.stateName]: {
          ...props.state[props.stateName],
          value: {...props.state[props.stateName].value, minutes: ''},
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state[props.stateName].value]);

  let borderColor =
    props.error === 'NO-ERROR'
      ? {borderBottom: '1px solid #20D167'}
      : props.error === ''
      ? {}
      : {borderBottom: '1px solid #dc3545'};

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: {...state[name], error: ''},
    });
  };

  let errorJSX = null;

  if (props.error !== 'NO-ERROR') {
    errorJSX = (
      <div className={`w-100 err-styles `} style={{marginTop: 8}}>
        {props.error}
      </div>
    );
  }

  const getBeginTime = (hours?: string, minutes?: string) => {
    const finalStartDate = props.state.startDate?.value
      ? new Date(
          props.state.startDate.value.getFullYear(),
          props.state.startDate.value.getMonth(),
          props.state.startDate.value.getDate(),
          0,
          0,
          0,
          0
        )
      : new Date();

    const beginTimeToSave = getDateToSave(finalStartDate, `${hours}:${minutes}`);

    return beginTimeToSave;
  };

  return (
    <div className="time-input" style={props.style}>
      <div style={props.layoutStyles}>
        <div className="time-input__text">{props.text}</div>
        <div className="time-input__main">
          {props.isDays && (
            <input
              placeholder="DD"
              onFocus={(e) => {
                e.target.placeholder = '';
                resetError(props.state, props.stateName);
              }}
              onBlur={(e) => (e.target.placeholder = 'DD')}
              onChange={(e) => {
                if (!(e.target.value.length > 3) && parseInt(e.target.value) >= 0) {
                  let updateInput = e.target.value;

                  const isFirstCharacterZero = e.target.value.charAt(0) === '0';

                  if (e.target.value.length === 1) {
                    updateInput = 0 + e.target.value;
                  } else if (isFirstCharacterZero) {
                    updateInput = e.target.value.substring(1);
                  }

                  props.setState({
                    ...props.state,
                    [props.stateName]: {
                      ...props.state[props.stateName],
                      value: {...props.state[props.stateName].value, days: updateInput},
                    },
                  });
                }
              }}
              type="number"
              onKeyDown={onKeyDown}
              value={props.state[props.stateName].value.days}
              style={borderColor}
            />
          )}
          <input
            placeholder="HH"
            onFocus={(e) => {
              e.target.placeholder = '';
              resetError(props.state, props.stateName);
            }}
            onBlur={(e) => (e.target.placeholder = 'HH')}
            onChange={(e) => {
              if (!(e.target.value.length > 3) && parseInt(e.target.value) >= 0) {
                let updateInput = e.target.value;

                const isFirstCharacterZero = e.target.value.charAt(0) === '0';

                if (e.target.value.length === 1) {
                  updateInput = 0 + e.target.value;
                } else if (isFirstCharacterZero) {
                  updateInput = e.target.value.substring(1);
                }

                props.setState({
                  ...props.state,
                  [props.stateName]: {
                    ...props.state[props.stateName],
                    value: {...props.state[props.stateName].value, hours: updateInput},
                  },
                });
                const beginTime = getBeginTime(
                  updateInput,
                  props.state[props.stateName].value.minutes
                );
                props.triggerOnChange && props.triggerOnChange(beginTime, props.state.endDate);
              }
            }}
            type="number"
            onKeyDown={onKeyDown}
            value={props.state[props.stateName].value.hours}
            style={borderColor}
          />
          <input
            placeholder="MM"
            onFocus={(e) => {
              e.target.placeholder = '';
              resetError(props.state, props.stateName);
            }}
            onBlur={(e) => (e.target.placeholder = 'MM')}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              if (!(e.target.value.length > 3) && !(+e.target.value < 0)) {
                let updateInput = e.target.value;

                const isFirstCharacterZero = e.target.value.charAt(0) === '0';

                if (e.target.value.length === 1) {
                  updateInput = 0 + e.target.value;
                } else if (isFirstCharacterZero) {
                  updateInput = e.target.value.substring(1);
                }

                props.setState({
                  ...props.state,
                  [props.stateName]: {
                    ...props.state[props.stateName],
                    value: {...props.state[props.stateName].value, minutes: updateInput},
                  },
                });

                const beginTime = getBeginTime(
                  props.state[props.stateName].value.hours,
                  updateInput
                );
                props.triggerOnChange && props.triggerOnChange(beginTime, props.state.endDate);
              }
            }}
            type="number"
            value={props.state[props.stateName].value.minutes}
            style={borderColor}
          />
          <FaClock size={20} color="#246BFD" style={{marginLeft: 15}} />
        </div>
        {errorJSX}
      </div>
    </div>
  );
};

export default TimeInputComp;
