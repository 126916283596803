import firebase from 'firebase';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirestore, useFunctions } from 'reactfire';
import AddUnitContentDTO from '../dtos/AddUnitContentDTO';
import DeleteUnitContentDTO from '../dtos/DeleteUnitContentDTO';
import ReorderUnitContentsDTO from '../dtos/ReorderUnitContentsDTO';
import UpdateUnitContentDTO from '../dtos/UpdateUnitContentDTO';
import UpdateUnitContentItemsDTO from '../dtos/UpdateUnitContentItemsDTO';
import { Unit } from '../models/Unit';
import { UnitContent } from '../screens/admin/AdminUnitScreenContent';
import { sortByUnitsName } from '../utils';

export interface UnitsContext {
  units: Unit[];
  isLoading: boolean;
  unitContnents: UnitContent[]
  fetchUnits: () => void;
  setLoading: (value: boolean) => void;
  addUnit: (unit: Unit) => void;
  updateUnit: (unit: Unit) => void;
  removeUnit: (id: string) => void;
  addUnitContent: (dto: AddUnitContentDTO) => void;
  updateUnitContent: (dto: UpdateUnitContentDTO) => void;
  deleteUnitContents: (dto: DeleteUnitContentDTO) => void;
  reorderUnitContents: (dto: ReorderUnitContentsDTO) => void;
  updateUnitContentItems: (dto: UpdateUnitContentItemsDTO) => void;
  setActiveUnitFun: (id: string) => void
}

interface State {
  units: Unit[];
  isLoading: boolean;
}

const initialContext: UnitsContext = {
  units: [],
  isLoading: false,
  unitContnents: [],
  fetchUnits: () => { },
  setLoading: (value: boolean) => { },
  addUnit: (unit: Unit) => { },
  updateUnit: (unit: Unit) => { },
  removeUnit: (id: string) => { },
  addUnitContent: (dto: AddUnitContentDTO) => { },
  updateUnitContent: (dto: UpdateUnitContentDTO) => { },
  deleteUnitContents: (dto: DeleteUnitContentDTO) => { },
  reorderUnitContents: (dto: ReorderUnitContentsDTO) => { },
  updateUnitContentItems: (dto: UpdateUnitContentItemsDTO) => { },
  setActiveUnitFun: (id: string) => { }

};

const initialState: State = {
  units: [],
  isLoading: false,
};

const Context = createContext<UnitsContext>(initialContext);

function getUniqueListBy(arr: any[]) {
  const ids = arr.map((o: any) => o.id);
  const filtered = arr.filter(({ id }: any, index: any) => !ids.includes(id, index + 1));
  return filtered;
}

export const UnitProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialState);
  const [activeUnit, setActiveUnit] = useState<Unit>();
  const [contents, setContents] = useState<UnitContent[]>([]);
  const store = useFirestore();

  const addUnitContentFun = useFunctions().httpsCallable('addUnitContent');
  const updateUnitContentFun = useFunctions().httpsCallable('updateUnitContent');
  const deleteUnitContentFun = useFunctions().httpsCallable('deleteUnitContent');
  const reOrderUnitContentsFun = useFunctions().httpsCallable('reOrderUnitContents');
  const updateUnitContentItemsFun = useFunctions().httpsCallable('updateUnitContentItems');

  const history = useHistory();

  useEffect(() => {
    fetchUnits();
  }, []);

  const setActiveUnitFun = (id: string) => {
    const [unit] = state.units.filter((u) => u.id === id);
    if (unit) setActiveUnit(unit);
  };

  useEffect(() => {
    let unsubscribe: any;
    if (activeUnit?.id) {
      unsubscribe = store
        .collection('unitContents')
        .where('unitId', '==', activeUnit?.id)
        .onSnapshot((snap) => {
          snap.docChanges().forEach((change) => {
            if (change.type === 'added') {
              const data = change.doc.data() as UnitContent;
              setContents((ps) => {
                ps.push({ ...data, id: change.doc.id });
                return [...ps];
              });
            }
            if (change.type === 'modified') {
              const data = change.doc.data() as UnitContent;
              setContents((ps) => {
                const modified = ps.map((a) => {
                  if (a.id === change.doc.id) {
                    return {
                      ...(data as any),
                      id: change.doc.id,
                    };
                  } else {
                    return a;
                  }
                });
                return Object.assign([], modified);
              });
            }
            if (change.type === 'removed') {
              setContents((ps) => {
                const removed = ps.filter((a) => a.id !== change.doc.id);
                return Object.assign([], removed);
              });
            }
          });
        });
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [activeUnit, store]);

  const fetchUnits = () => {
    const units: Unit[] = [];

    firebase
      .firestore()
      .collection('units')
      .get()
      .then((data) => {
        data.forEach((doc) => {
          units.push({ id: doc.id, ...doc.data() } as any);
        });

        setState((ps) => {
          return {
            ...ps,
            units: getUniqueListBy(units),
          };
        });
      })
      .catch(() => {
        console.log('ERROR');
      });
  };

  const addUnit = (unit: Unit) => {
    const currentUnits = [...state.units];
    currentUnits.push(unit);
    setState((ps) => {
      return {
        ...ps,
        units: sortByUnitsName(currentUnits),
      };
    });
  };

  const updateUnit = (unit: Unit) => {
    const currentUnits = [...state.units];

    const updatedArray = currentUnits.filter((sub) => sub.id !== unit.id);

    updatedArray.push(unit);
    setState((ps) => {
      return {
        ...ps,
        units: sortByUnitsName(updatedArray),
      };
    });
  };

  const removeUnit = (id: string) => {
    const currentUnits = [...state.units];
    const removedUnitArray = currentUnits.filter((sub) => sub.id !== id);

    setState((ps) => {
      return {
        ...ps,
        units: sortByUnitsName(removedUnitArray),
      };
    });
  };

  const setLoading = (value: boolean) => {
    setState((ps) => ({
      ...ps,
      isLoading: value,
    }));
  };


  const addUnitContent = (dto: AddUnitContentDTO) => {
    setState((ps) => ({ ...ps, isLoading: true }));
    addUnitContentFun(dto)
      .then(() => { })
      .catch((err) => { })
      .finally(() => {
        setState((ps) => ({ ...ps, isLoading: false }));
      }).then(() => {

        const redirect = history.location.pathname.replace("/Create", "");
        history.push(redirect)

      }).catch((e) => {

      });
  };

  const updateUnitContent = (dto: UpdateUnitContentDTO) => {
    setState((ps) => ({ ...ps, isLoading: true }));
    updateUnitContentFun(dto)
      .then(() => { })
      .catch((err) => { })
      .finally(() => {
        setState((ps) => ({ ...ps, isLoading: false }));
      });
  };

  const deleteUnitContents = (dto: DeleteUnitContentDTO) => {
    setState((ps) => ({ ...ps, isLoading: true }));
    deleteUnitContentFun(dto)
      .then(() => { })
      .catch((err) => { })
      .finally(() => {
        setState((ps) => ({ ...ps, isLoading: false }));
      });
  };

  const reorderUnitContents = (dto: ReorderUnitContentsDTO) => {
    setState((ps) => ({ ...ps, isLoading: true }));
    reOrderUnitContentsFun(dto)
      .then(() => { })
      .catch((err) => { })
      .finally(() => {
        setState((ps) => ({ ...ps, isLoading: false }));
      });
  };

  const updateUnitContentItems = (dto: UpdateUnitContentItemsDTO) => {
    setState((ps) => ({ ...ps, isLoading: true }));
    updateUnitContentItemsFun(dto)
      .then(() => { })
      .catch((err) => { })
      .finally(() => {
        setState((ps) => ({ ...ps, isLoading: false }));
      });
  };

  return (
    <Context.Provider
      value={{
        ...state,
        fetchUnits,
        setLoading,
        unitContnents: contents,
        addUnit,
        updateUnit,
        removeUnit,
        addUnitContent,
        deleteUnitContents,
        reorderUnitContents,
        updateUnitContent,
        updateUnitContentItems,
        setActiveUnitFun

      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useUnits = (): UnitsContext => useContext(Context);
