import React, {Fragment, useState} from 'react';
import CreateUnitModel from '../../components/CreateUnitModel';

import LessonDirectiveHeader from '../../components/LessonDirectiveHeader';
import LessonSecondaryNav from '../../components/LessonSecondaryNav';
import PrimaryNav from '../../components/PrimaryNav';
import UnitList from '../../components/UnitList';
import LayoutNew from '../../layouts/LayoutNew';

const AdminLessonMyUnitScreen = () => {
  let primaryNavigation = null;
  let secondaryNavigation = null;
  let appBody = null;
  let appHeader = null;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const unitModelHandler = (value: boolean) => {
    setOpen(value);
  };

  const loadingHandler = (value: boolean) => {
    setLoading(value);
  };

  primaryNavigation = <PrimaryNav />;
  secondaryNavigation = <LessonSecondaryNav />;
  appBody = <UnitList loadingHandler={loadingHandler} />;
  appHeader = <LessonDirectiveHeader unitModelHandler={unitModelHandler} />;

  return (
    <Fragment>
      <LayoutNew
        isLoading={loading}
        primaryNav={primaryNavigation}
        secondaryNav={secondaryNavigation}
        header={appHeader}
        body={appBody}
      />
      <CreateUnitModel show={open} unitModelHandler={unitModelHandler} />
    </Fragment>
  );
};

export default AdminLessonMyUnitScreen;
