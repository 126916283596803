import React, {createContext, useContext, useEffect, useState} from 'react';
import {useFirestore, useFunctions} from 'reactfire';
import firebase from 'firebase';

import {useInterval} from 'react-interval-hook';
import {useAppUser} from './UserContext';
import {useSubjects} from './SubjectsContext';
import {getAnnouncementStatus, getAnnouncementStatusV2} from '../utils';
import { useAdminsAndInstructors } from './AdminsAndInstructors';

export interface FileContent {
  fileName: string;
  type: string;
  url: string;
}

export interface Announcement {
  id: string;
  title: string;
  description: string;
  date: firebase.firestore.Timestamp;
  duration: number;
  fileURLs?: FileContent[];
  createdBy: string;
  createdByName: string;
  createdAt: firebase.firestore.Timestamp;
  read?: boolean;
  isActive?: boolean;
  grades?: string[];
  subjects?: string[];
}

export interface AddAnnouncementDTO {
  title: string;
  description: string;
  date: string;
  duration: number;
  fileURLs?: FileContent[];
  grades?: string[];
  subjects?: string[];
}

export interface UpdateAnnouncementDTO {
  id: string;
  title: string;
  date: string;
  duration: number;
  description: string;
  fileURLs?: FileContent[];
  grades?: string[];
  subjects?: string[];
}

export interface IAnnouncementContext {
  announcements: Announcement[];
  selectedAnnouncementId: string;
  haveNoties: boolean;
  addAnnouncement: (
    announcement: AddAnnouncementDTO,
    onFinish: (status: boolean, id: string) => void
  ) => void;
  updateAnnouncement: (
    announcement: UpdateAnnouncementDTO,
    onFinish: (status: boolean) => void
  ) => void;
  deleteAnnouncement: (announcementId: string, onFinish: (status: boolean) => void) => void;
  selectAnnouncement: (id: string) => void;
  readAnnouncement: (announcementId: string, onFinsish: (status: boolean) => void) => void;
}

interface IState {
  announcements: Announcement[];
  selectedAnnouncementId: string;
}

const initialContext: IAnnouncementContext = {
  haveNoties: true,
  announcements: [],
  selectedAnnouncementId: '',
  addAnnouncement: (
    announcement: AddAnnouncementDTO,
    onFinsish: (status: boolean, id: string) => void
  ) => {},
  deleteAnnouncement: (announcementId: string, onFinish: (status: boolean) => void) => {},
  selectAnnouncement: (id: string) => {},
  readAnnouncement: (announcementId: string, onFinsish: (status: boolean) => void) => {},
  updateAnnouncement: (
    announcement: UpdateAnnouncementDTO,
    onFinish: (status: boolean) => void
  ) => {},
};

const initialState: IState = {
  announcements: [],
  selectedAnnouncementId: '',
};

const AnnouncmentContext = createContext<IAnnouncementContext>(initialContext);

interface IProps {
  children: any;
}

export const AnouncementProvider = (props: IProps) => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [inactiveAnnouncements, setInactiveAnnouncements] = useState<Announcement[]>([]);
  const [state, setState] = useState(initialState);
  const [haveNoties, setHaveNoties] = useState(false);
  const store = useFirestore();
  const {fireUser, firestoreUser} = useAppUser();
  const {allSubjects} = useSubjects();
  const {allAdminsAndInstructors} = useAdminsAndInstructors();

  useInterval(
    () => {
      if (firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) {
        announcements
          .filter((a) => !a.isActive)
          .forEach((ia, index) => {
            if (getAnnouncementStatus(ia.date.toDate(), ia.duration) === 'active') {
              setAnnouncements((ps) => {
                ps = ps.map((x) => {
                  if (x.id === ia.id) {
                    return {...x, isActive: true};
                  } else {
                    return x;
                  }
                });

                return [...ps];
              });
            }
          });
      } else {
        inactiveAnnouncements.forEach((ia) => {
          if (getAnnouncementStatus(ia.date.toDate(), ia.duration) === 'active') {
            setAnnouncements((ps) => {
              ia.isActive = true;
              ps.push(ia);
              return [...ps];
            });
            setInactiveAnnouncements((ps) => {
              return ps.filter((x) => x.id !== ia.id);
            });
            // UserNotification({
            //   isSuccess: true,
            //   message: 'New notice available!',
            // });
          }
        });
      }
    },
    15000,
    {
      autoStart: true,
      immediate: false,
      selfCorrecting: false,
      onFinish: () => {
        console.log('Callback when timer is stopped');
      },
    }
  );

  useEffect(() => {
    let query: firebase.firestore.Query | firebase.firestore.CollectionReference =
      store.collection('announcements');

    // if (
    //   (!userRole.isAdmin && userRole.isModerator) ||
    //   (!userRole.isAdmin && !userRole.isModerator && userRole.isMember)
    // ) {
    //   query = query.where('countries', 'array-contains', userCountry);
    // }
    setAnnouncements([]);
    setInactiveAnnouncements([]);
    return query.where('active', '==', true).onSnapshot({}, (snapshot) => {
      snapshot
        .docChanges()
        .filter((chenge) => {
          if (firestoreUser?.userRole?.isAdmin) {
            return false;
          } else if (firestoreUser?.userRole?.isInstructor) {
            const an = chenge.doc.data() as Announcement;
            const av =
              an.subjects?.some((value) =>
                allSubjects
                  .filter((s) => s.assignedUsers?.includes(firestoreUser?.uid || ''))
                  .map((s) => s.id)
                  .includes(value)
              ) || false;
            return an.createdBy === firestoreUser.email || av;
          } else {
            return (
              getAnnouncementStatusV2(
                chenge.doc.data().date.toDate(),
                chenge.doc.data().duration
              ) === 'inactive'
            );
          }
        })
        .filter((change) => {
          if (firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) {
            return true;
          } else {
            const a = change.doc.data() as Announcement;
            return (
              a.subjects?.some((value) =>
                allSubjects
                  .filter(
                    (s) =>
                      s.enrolledStudents?.includes(firestoreUser?.uid || '') ||
                      s.assignedUsers?.includes(firestoreUser?.uid || '')
                  )
                  .map((s) => s.id)
                  .includes(value)
              ) || false
            );
          }
        })
        .forEach((change) => {
          if (change.type === 'added') {
            setInactiveAnnouncements((ps) => {
              const userIds: string[] = change.doc.data().readUserIds;
              let read = false;
              if (userIds) {
                read = userIds.includes(fireUser?.uid || '');
              }

              ps.push({
                ...(change.doc.data() as any),
                id: change.doc.id,
                read,
                isActive: false,
              });
              return Object.assign([], ps);
            });
          }
          if (change.type === 'modified') {
            setInactiveAnnouncements((ps) => {
              const userIds: string[] = change.doc.data().readUserIds;
              let read = false;
              if (userIds) {
                read = userIds.includes(fireUser?.uid || '');
              }
              const modified = ps.map((a) => {
                if (a.id === change.doc.id) {
                  return {
                    ...(change.doc.data() as any),
                    id: change.doc.id,
                    read,
                  };
                } else {
                  return a;
                }
              });
              return Object.assign([], modified);
            });
          }
          if (change.type === 'removed') {
            setInactiveAnnouncements((ps) => {
              const removed = ps.filter((a) => a.id !== change.doc.id);
              return Object.assign([], removed);
            });
          }
        });

      snapshot
        .docChanges()
        .filter((chenge) => {
          if (firestoreUser?.userRole?.isAdmin) {
            return true;
          } else if (firestoreUser?.userRole?.isInstructor) {
            const an = chenge.doc.data() as Announcement;
            const av =
              an.subjects?.some((value) =>
                allSubjects
                  .filter((s) => s.assignedUsers?.includes(firestoreUser?.uid || ''))
                  .map((s) => s.id)
                  .includes(value)
              ) || false;
            return an.createdBy === firestoreUser.email || av;
          } else {
            return (
              getAnnouncementStatusV2(
                chenge.doc.data().date.toDate(),
                chenge.doc.data().duration
              ) === 'active'
            );
          }
        })
        .filter((change) => {
          if (firestoreUser?.userRole?.isAdmin || firestoreUser?.userRole?.isInstructor) {
            return true;
          } else {
            const a = change.doc.data() as Announcement;
            return (
              a.subjects?.some((value) =>
                allSubjects
                  .filter(
                    (s) =>
                      s.enrolledStudents?.includes(firestoreUser?.uid || '') ||
                      s.assignedUsers?.includes(firestoreUser?.uid || '')
                  )
                  .map((s) => s.id)
                  .includes(value)
              ) || false
            );
          }
        })

        .forEach((change) => {
          setHaveNoties(true);
          if (change.type === 'added') {
            setAnnouncements((ps) => {
              const userIds: string[] = change.doc.data().readUserIds;
              let read = false;
              if (userIds) {
                read = userIds.includes(fireUser?.uid || '');
              }

              ps.push({
                ...(change.doc.data() as any),
                id: change.doc.id,
                read,
                isActive:
                  getAnnouncementStatus(
                    change.doc.data().date.toDate(),
                    change.doc.data().duration
                  ) === 'active',
              });
              return Object.assign([], ps);
            });
            // setLoading(false);
          }
          if (change.type === 'modified') {
            setAnnouncements((ps) => {
              const userIds: string[] = change.doc.data().readUserIds;
              let read = false;
              if (userIds) {
                read = userIds.includes(fireUser?.uid || '');
              }
              const modified = ps.map((a) => {
                if (a.id === change.doc.id) {
                  return {
                    ...(change.doc.data() as any),
                    createdByName: change.doc.data().createdByName,
                    id: change.doc.id,
                    read,
                    isActive:
                      getAnnouncementStatus(
                        change.doc.data().date.toDate(),
                        change.doc.data().duration
                      ) === 'active',
                  };
                } else {
                  return a;
                }
              });
              return Object.assign([], modified);
            });
          }
          if (change.type === 'removed') {
            setAnnouncements((ps) => {
              const removed = ps.filter((a) => a.id !== change.doc.id);
              return Object.assign([], removed);
            });
          }
        });
    });
  }, [store, fireUser, firestoreUser, allSubjects]);

  useEffect(() => {
    const emailList = announcements
      .map((a) => a.createdBy)
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      });
    
    setState((ps) => ({...ps, announcements: []}));
    if (emailList.length !== 0)

    allAdminsAndInstructors.filter(u=>emailList.includes(u.email||"")).forEach(user=>{
      setState((ps) => {
        let aas: Announcement[] = [];
        if (ps.announcements) {
          aas = announcements.map((a) => {
            const [ax] = ps.announcements.filter((f) => f.id === a.id);
            if (ax) {
              return ax;
            } else {
              return a;
            }
          });
        }
        ps.announcements = aas.map((a) => {
          if (a.createdBy === user.email) {
            const modi = {
              ...a,
            };
            return modi;
          } else {
            return a;
          }
        });

        return {
          ...ps,
          announcements: Object.assign([], ps.announcements),
        };
      });

    })
   
  }, [announcements, allAdminsAndInstructors]);

  const addAnnouncementFunction = useFunctions().httpsCallable('addAnnouncement');

  const updateAnnouncementFunction = useFunctions().httpsCallable('updateAnnouncement');

  const deleteAnnouncementFunction = useFunctions().httpsCallable('deleteAnnouncement');
  const readAnnouncementFunction = useFunctions().httpsCallable('readAnnouncement');

  const addAnnouncement = (
    announcement: AddAnnouncementDTO,
    onFinsish: (status: boolean, id: string) => void
  ) => {
    addAnnouncementFunction(announcement)
      .then((res) => {
        onFinsish(true, res.data.data);
      })
      .catch(() => {
        onFinsish(false, 'none');
      });
  };

  const updateAnnouncement = (
    announcement: UpdateAnnouncementDTO,
    onFinsish: (status: boolean) => void
  ) => {
    updateAnnouncementFunction(announcement)
      .then((res) => {
        onFinsish(true);
      })
      .catch(() => {
        onFinsish(false);
      });
  };

  const readAnnouncement = (announcementId: string, onFinsish: (status: boolean) => void) => {
    const [ann] = announcements.filter((a) => a.id === announcementId);
    if (!ann?.read && fireUser?.uid) {
      readAnnouncementFunction({
        id: announcementId,
        userId: fireUser?.uid,
      })
        .then((res) => {
          onFinsish(true);
          setAnnouncements((ps) => {
            return ps.map((a) => {
              if (a.id === announcementId) {
                return {...a, read: true};
              } else {
                return a;
              }
            });
          });
        })
        .catch(() => {
          onFinsish(false);
        });
    } else {
      onFinsish(true);
    }
  };

  const deleteAnnouncement = (announcementId: string, onFinsish: (status: boolean) => void) => {
    deleteAnnouncementFunction(announcementId)
      .then((res) => {
        setAnnouncements((ps) => ps.filter((a) => a.id !== announcementId));
        onFinsish(true);
      })
      .catch(() => {
        onFinsish(false);
      });
  };

  const selectAnnouncement = (id: string) => {
    setState({...state, selectedAnnouncementId: id});
  };

  return (
    <AnnouncmentContext.Provider
      value={{
        ...state,
        haveNoties,
        addAnnouncement,
        selectAnnouncement,
        deleteAnnouncement,
        readAnnouncement,
        updateAnnouncement,
      }}
    >
      {props.children}
    </AnnouncmentContext.Provider>
  );
};

export const useAnnouncements = (): IAnnouncementContext => useContext(AnnouncmentContext);
