import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import reactRenderer from 'react-render-html';
import BreadCrumbUpdate from '../../shared/BreadCrumbUpdate';
import { useFunctions } from 'reactfire';
import StudentExamStartsTimer from '../../components/StudentExamStartsTimer';
import {
  convertUTCDateToLocalDate,
  getExaminationStartDate,
  getExamStartScreenDetails,
  hasUserInitiatedExam,
} from '../../utils';
import Overlay from '../../shared/Overlay';
import { ExamToDoDTO } from '../../dtos/ExamToDoDTO';
import { useAppUser } from '../../context/UserContext';
import { useAdminsAndInstructors } from '../../context/AdminsAndInstructors';
import { useUpcomingCount } from '../../hooks/useUpcomingCount';
import PrimaryNav from '../../components/PrimaryNav';
import ExamInfoPill from '../../components/ExamInfoPill';
import { ExamType } from '../../enums/ExamType';
import LayoutNew from '../../layouts/LayoutNew';
import './StudentExamStartScreen.scss';

interface Props {
  secondaryNav: any;
  setSelectedExamsHandlers: (exam: ExamToDoDTO | null) => void;
}

const StudentExamStartScreen: FC<Props> = (props) => {

  const location: any = useLocation();

  const examDetailsState = location?.state?.exam

  const [state, setState] = useState({ loading: false, questionCount: 0 });

  //hooks
  const appUser = useAppUser();
  const params = useParams() as { subjectId: string; examId: string };
  const appAdminAndInstructors = useAdminsAndInstructors();
  const history = useHistory();

  //functions
  const recordStudentExamStartTimeRef = useFunctions().httpsCallable('recordStudentExamStartTime');

  //methods
  const setLoading = (value: boolean) => {
    setState((ps) => {
      return { ...ps, loading: value };
    });
  };

  const setCount = (value: number) => {
    setState((ps) => {
      return { ...ps, questionCount: value };
    });
  };

  const updateExamWithUserExamStartedTime = (examId: string) => {
    if (!hasUserInitiatedExam(examDetailsState, appUser.firestoreUser)) {
      setLoading(true);
      recordStudentExamStartTimeRef(examId)
        .then((res) => {

          history.push({
            pathname: `/my-subjects/subject/${params.subjectId}/exam/${params.examId}`,
            state: {
              exam: res.data.data,
              redo: examDetailsState?.completed,
            },
          });

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      history.push({
        pathname: `/my-subjects/subject/${params.subjectId}/exam/${params.examId}`,
        state: {
          exam: examDetailsState,
          redo: examDetailsState?.completed,
        },
      });
    }
  };

  const authorName =
    appAdminAndInstructors.allAdminsAndInstructors.find(
      (u) => u.createdBy === examDetailsState?.createdBy
    )?.username || '';

  //LEGACY DATA SUPPORT
  const time = useUpcomingCount(
    examDetailsState?.beginTime && examDetailsState?.finishTime
      ? convertUTCDateToLocalDate(examDetailsState?.beginTime)
      : getExaminationStartDate(examDetailsState)
  );

  useEffect(() => {
    let val: number = 0;

    examDetailsState?.papers.forEach((p: any) => {
      val = val + p.totalNoOfQuestions;
    });

    setCount(val);
  }, [examDetailsState?.papers]);

  const primaryNav = <PrimaryNav />;
  const secondaryNav = props.secondaryNav;
  const header = <BreadCrumbUpdate />;

  const examDetails = getExamStartScreenDetails(examDetailsState);

  const appBody = (
    <div className="exam-details ">
      {state.loading ? <Overlay msg="Commencing" /> : null}
      <Row>
        <Col className="exam-details__title">{examDetailsState?.examTitle}</Col>
      </Row>
      <Row className="mt-2 px-3 justify-content-between justify-content-lg-start d-none d-md-flex">
        <div className="exam-details__grade">{examDetailsState?.grade}</div>
        <div className="exam-details__subjec-name ml-lg-4">{examDetailsState?.subjectName}</div>
        <div className="exam-details__year ml-lg-4">{examDetailsState?.subjectYear}</div>
      </Row>
      <div className="mt-1 d-md-none">
        <div className="exam-details__subjec-name ">{examDetailsState?.subjectName}</div>
        <div className="d-flex mt-3">
          <div className="exam-details__grade mr-3">{examDetailsState?.grade}</div>

          <div className="exam-details__year">{examDetailsState?.subjectYear}</div>
        </div>
      </div>
      <Row>
        <Col className="exam-details__description mt-2 mb-0">
          {reactRenderer(examDetailsState?.description || '')}
        </Col>
      </Row>
      <Row>
        <Col className="exam-details__author-name">by {authorName}</Col>
      </Row>
      <Row className="mt-2">
        <Col className="exam-details__ex-info ">
          <StudentExamStartsTimer time={time} className="d-lg-none d-flex " />
          <ExamInfoPill type="Q-COUNT" count={state.questionCount} />

          {examDetailsState?.examType !== ExamType.EXTRA_EXAM && (
            <>
              <ExamInfoPill
                type="START-TIME"
                startTime={examDetails.startTime}
                startDate={examDetails.startDate}
              />
              <ExamInfoPill
                type="END-TIME"
                endTime={examDetails.endTime}
                endDate={examDetails.endDate}
              />
              <ExamInfoPill type="DURATION" duration={examDetails.duration} />
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="d-lg-flex align-items-center">
          <button
            className="exam-details__buy-btn"
            style={
              time.length ? { backgroundColor: 'grey', color: 'bebdb8', cursor: 'not-allowed' } : {}
            }
            onClick={() => {
              if (!time.length) {
                updateExamWithUserExamStartedTime(params.examId);
              }
            }}
          >
            <div>Start Exam</div>

            <div className={!examDetailsState?.price ? 'd-none' : ''}>
              <span>Rs.</span> {examDetailsState?.price}
            </div>
          </button>

          <StudentExamStartsTimer time={time} className="d-none d-lg-flex" />
        </Col>
      </Row>
    </div>
  );

  return (
    <LayoutNew primaryNav={primaryNav} secondaryNav={secondaryNav} body={appBody} header={header} />
  );
};

export default StudentExamStartScreen;
