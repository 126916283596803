import React, {FC, Fragment, useState} from 'react';
import {FaEdit, FaTrashAlt} from 'react-icons/fa';
import {useHistory, useParams} from 'react-router-dom';
import {useFirestore, useFunctions} from 'reactfire';
import {useLessons} from '../context/LessonsContext';
import {Lesson} from '../models/Lesson';
import AlertPopup from './AlertPopup';
import Notification from './Notification';
import './LessonCard.scss';
import CreateLessonsModal from './CreateLessonsModal';

interface Props {
  lesson: Lesson;
  loadingHandler?: (value: boolean) => void;
}

const LessonCard: FC<Props> = (props) => {
  const history = useHistory();
  const {subjectId} = useParams() as {subjectId: string};
  const store = useFirestore();

  const appLessons = useLessons();

  const [modalState, setModalState] = useState({
    notDeletableText: '',
    deletableText: '',
    openUpdateModal: false,
  });

  const openUpdateModaHandler = (value: boolean) => {
    setModalState((ps) => ({
      ...ps,
      openUpdateModal: value,
    }));
  };

  const deleteLessonRef = useFunctions().httpsCallable('deleteLesson');

  const notDeletableTextHandler = (value: string) => {
    setModalState((ps) => ({
      ...ps,
      notDeletableText: value,
    }));
  };

  const deletableTexttHandler = (value: string) => {
    setModalState((ps) => ({
      ...ps,
      deletableText: value,
    }));
  };

  const clearDeletableTexttHandler = () => {
    setModalState((ps) => ({
      ...ps,
      deletableText: '',
    }));
  };

  const clearNotDeletableTextHandler = () => {
    setModalState((ps) => ({
      ...ps,
      notDeletableText: '',
    }));
  };

  const isLessonDeletable = () => {
    props.loadingHandler && props.loadingHandler(true);
    return store
      .collection('questions')
      .where('lessonId', '==', props.lesson.id)
      .get()
      .then((data) => {
        if (data.size) {
          notDeletableTextHandler(
            'This lesson is not deletable because there are questions associated with it'
          );
          props.loadingHandler && props.loadingHandler(false);
          return false;
        } else {
          return store
            .collection('papers')
            .where('lessonId', '==', props.lesson.id)
            .get()
            .then((data) => {
              if (data.size) {
                notDeletableTextHandler(
                  'This lesson is not deletable because there are papers associated with it'
                );
                props.loadingHandler && props.loadingHandler(false);
                return false;
              } else {
                return store
                  .collection('exams')
                  .where('lessonId', '==', props.lesson.id)
                  .get()
                  .then((data) => {
                    if (data.size) {
                      notDeletableTextHandler(
                        'This lesson is not deletable because there are exams associated with it'
                      );
                      props.loadingHandler && props.loadingHandler(false);
                      return false;
                    } else {
                      return store
                        .collection('structuredEssayPapers')
                        .where('lessonId', '==', props.lesson.id)
                        .get()
                        .then((data) => {
                          if (data.size) {
                            notDeletableTextHandler(
                              'This lesson is not deletable because there are papers associated with it'
                            );
                            props.loadingHandler && props.loadingHandler(false);
                            return false;
                          } else {
                            deletableTexttHandler('Are you sure you want to delete this lesson?');
                            props.loadingHandler && props.loadingHandler(false);
                            return true;
                          }
                        })
                        .catch(() => {
                          return false;
                        });
                    }
                  })
                  .catch(() => {
                    return false;
                  });
              }
            })
            .catch(() => {
              return false;
            });
        }
      })
      .catch(() => {
        return false;
      });
  };

  const onLessonDelete = async (lessonId: string) => {
    props.loadingHandler && props.loadingHandler(true);

    try {
      await deleteLessonRef({
        lessonId: lessonId,
      });

      appLessons.removeLesson(lessonId);

      Notification({
        isSuccess: true,
        message: 'Lesson deleted successfully',
      });

      props.loadingHandler && props.loadingHandler(false);
    } catch (e) {
      console.log('ERROR: ', e);

      Notification({
        isSuccess: false,
        message: 'Failed to delete the lesson',
      });

      props.loadingHandler && props.loadingHandler(false);
    }
  };

  return (
    <Fragment>
      <div
        className="lesson-card"
        onClick={() => {
          const isLessonContainsUnits = appLessons.lessons.find(
            (l) => l.id === props.lesson.id
          )?.isLessonContainsUnits;

          isLessonContainsUnits
            ? history.push(`/my-subjects/subject/${subjectId}/lesson/${props.lesson.id}/My Units`)
            : history.push(`/my-subjects/subject/${subjectId}/lesson/${props.lesson.id}/Content`);
        }}
      >
        <div
          className="lesson-card__img"
          style={{ backgroundImage: `url(${props.lesson.coverImage})` }}
        ></div>
        <div className="lesson-card__name">{props.lesson.name}</div>
        <div className="lesson-card__overlay">
          <div
            onClick={(e) => {
              isLessonDeletable();
              e.stopPropagation();
            }}
            className="lesson-card__trash-icon"
          >
            <div
              style={{
                height: '50px',
                width: '50px',
                backgroundColor: '#1F2130',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FaTrashAlt color="#F05A5A" size={20} />
            </div>
          </div>

          <div
            onClick={(e) => {
              openUpdateModaHandler(true);
              e.stopPropagation();
            }}
            className="lesson-card__edit-icon"
          >
            <div
              style={{
                height: '50px',
                width: '50px',
                backgroundColor: '#1F2130',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FaEdit color="#FFFFFF" size={20} />
            </div>
          </div>
        </div>
      </div>

      <AlertPopup
        header="Warning"
        message={modalState.notDeletableText}
        isShow={!!modalState.notDeletableText}
        onOk={clearNotDeletableTextHandler}
        onClose={clearNotDeletableTextHandler}
        type="NO_BUTTON"
      />
      <AlertPopup
        message={modalState.deletableText}
        header="Attention"
        isShow={!!modalState.deletableText}
        primaryButtonText="Confirm"
        onOk={() => {
          if (!appLessons.isLoading) {
            onLessonDelete(props.lesson.id || '');

            clearDeletableTexttHandler();
          }
        }}
        onClose={clearDeletableTexttHandler}
      />
      <CreateLessonsModal
        show={modalState.openUpdateModal}
        lessonModelHandler={openUpdateModaHandler}
        selectedLesson={props.lesson}
      />
    </Fragment>
  );
};

export default LessonCard;
