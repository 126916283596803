import React, {FC, useEffect, useState} from 'react';
import {FaCopy, FaImage, FaWindowClose} from 'react-icons/fa';
import {ClipLoader} from 'react-spinners';
import {useFunctions, useStorage} from 'reactfire';
import truncate from 'truncate';
import {createLessonInitialState} from '../interfaces';
import Notification from './../components/Notification';
import Modal from 'react-modal';
import {useParams} from 'react-router-dom';
import {Lesson} from '../models/Lesson';
import {useSubjects} from '../context/SubjectsContext';
import {useLessons} from '../context/LessonsContext';
import {useAppUser} from '../context/UserContext';
import {validationResult} from '../utils';
import LoadingIndicator from './LoadingIndicator';
import TextInput from './TextInput';
import ImageField from './ImageField';
import './CreateLessonsModal.scss';
import SwitchBtn from './SwitchBtn';

interface Props {
  show: boolean;
  selectedLesson?: Lesson;
  lessonModelHandler?: (value: boolean) => void;
}

const CreateLessonsModal: FC<Props> = (props) => {
  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUser = useAppUser();
  const params = useParams() as {subjectId: string};

  const options = appSubjects.allSubjects
    .filter((v: any) => {
      return v.id === params.subjectId;
    })
    .map((sub: any) => {
      return {displayValue: sub.name, id: sub.id};
    });

  const subjectBasedLessonNames = appLessons.lessons
    .filter((lesson) => {
      return lesson.subjectId === params.subjectId && lesson.id !== props.selectedLesson?.id;
    })
    .map((lesson) => {
      return lesson.name.toLowerCase();
    });

  const subjectDropdownDisplayValue = options[0].displayValue;
  const subjectDropdownValue = options[0];

  const updatedCreateLessonInitialState = {
    ...createLessonInitialState,
    subject: {
      ...createLessonInitialState.subject,
      value: subjectDropdownValue,
    },
  };

  const updatedUpdateLessonInitialState = {
    ...createLessonInitialState,
    subject: {...createLessonInitialState.subject, value: subjectDropdownValue},
    lessonName: {
      ...createLessonInitialState.lessonName,
      value: props.selectedLesson?.name || '',
    },
    coverImage: {
      ...createLessonInitialState.coverImage,
      value: props.selectedLesson?.coverImage || '',
    },
    isLessonContainsUnits: {
      ...createLessonInitialState.isLessonContainsUnits,
      value: !!props.selectedLesson?.isLessonContainsUnits,
    },
  };

  const [state, setState] = useState(updatedCreateLessonInitialState);

  useEffect(() => {
    const updatedCreateLessonInitialState = {
      ...createLessonInitialState,
      subject: {...createLessonInitialState.subject, value: subjectDropdownValue},
    };

    const updatedUpdateLessonInitialState = {
      ...createLessonInitialState,
      subject: {...createLessonInitialState.subject, value: subjectDropdownValue},
      lessonName: {
        ...createLessonInitialState.lessonName,
        value: props.selectedLesson?.name || '',
      },
      coverImage: {
        ...createLessonInitialState.coverImage,
        value: props.selectedLesson?.coverImage || '',
      },
      isLessonContainsUnits: {
        ...createLessonInitialState.isLessonContainsUnits,
        value: !!props.selectedLesson?.isLessonContainsUnits,
      },
    };

    const dataToSetToState = props.selectedLesson
      ? updatedUpdateLessonInitialState
      : updatedCreateLessonInitialState;

    setState(dataToSetToState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedLesson?.id]);

  const createLessonRef = useFunctions().httpsCallable('createLesson');
  const updateLessonRef = useFunctions().httpsCallable('updateLesson');

  const storage = useStorage();

  const types = ['image/png', 'image/jpeg'];

  const addLessonHandler = async () => {
    if (subjectBasedLessonNames.includes(state.lessonName.value.toLowerCase().trim()) || '') {
      return setState((ps) => {
        return {
          ...ps,
          lessonName: {...ps.lessonName, error: 'This lesson already exists'},
        };
      });
    }

    const validationOutput = validationResult({...state, loading: true});

    setState(validationOutput.state);
    let subjectCreateFormValidty = true;

    //guard 1
    if (!!validationOutput.formValidity) {
      subjectCreateFormValidty = false;
    }

    if (!subjectCreateFormValidty) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    try {
      const less = await createLessonRef({
        name: state.lessonName.value,
        lowerCaseName: state.lessonName.value.toLowerCase(),
        coverImage: state.coverImage.value || 'image',
        subjectId: state.subject.value.id,
        isLessonContainsUnits: state.isLessonContainsUnits.value,
      });

      appLessons.addLesson(less.data.data);

      Notification({
        isSuccess: true,
        message: 'Lesson created successfully',
      });
    } catch (e) {
      console.log('ERROR ', e);

      Notification({
        isSuccess: false,
        message: 'Failed to create lesson.Try again later',
      });
    } finally {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      setState(updatedCreateLessonInitialState);
      props.lessonModelHandler && props.lessonModelHandler(false);
    }
  };

  const updateLessonHandler = async () => {
    if (subjectBasedLessonNames.includes(state.lessonName.value.toLowerCase().trim()) || '') {
      return setState((ps) => {
        return {
          ...ps,
          lessonName: {...ps.lessonName, error: 'This lesson already exists'},
        };
      });
    }

    const validationOutput = validationResult({...state, loading: true});

    setState(validationOutput.state);
    let subjectCreateFormValidty = true;

    //guard 1
    if (!!validationOutput.formValidity) {
      subjectCreateFormValidty = false;
    }

    if (!subjectCreateFormValidty) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    try {
      const less = await updateLessonRef({
        id: props.selectedLesson?.id || '',
        name: state.lessonName.value,
        lowerCaseName: state.lessonName.value.toLowerCase(),
        coverImage: state.coverImage.value || 'image',
        subjectId: state.subject.value.id,
        isLessonContainsUnits: state.isLessonContainsUnits.value,
      });

      appLessons.updateLesson(less.data.data);
      Notification({
        isSuccess: true,
        message: 'Lesson updated successfully',
      });
    } catch (e) {
      console.log('ERROR ', e);

      Notification({
        isSuccess: false,
        message: 'Failed to updated lesson.Try again later',
      });
    } finally {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));

      props.lessonModelHandler && props.lessonModelHandler(false);
    }
  };

  const changeHandler = (e: any) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      const size = selected.size / 1024 / 1024;
      if (size > 1) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
        return;
      }

      const storageRef = storage
        .ref()
        .child(`lessonImages/${appUser.fireUser?.uid}_${+new Date()}`);

      let lessonURL: any = null;

      setState((ps) => ({
        ...ps,
        coverImage: {...ps.coverImage, loading: true},
      }));

      storageRef.put(selected).on(
        'state_changed',
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          console.log('upload percentage ', percentage);
        },
        (err) => {
          Notification({
            isSuccess: false,
            message: 'Failed to upload image. ',
          });

          setState((ps) => ({
            ...ps,
            coverImage: {
              ...ps.coverImage,
              file: null,
              error: 'Failed to upload image',
              value: '',
              loading: false,
            },
          }));
        },
        async () => {
          lessonURL = await storageRef.getDownloadURL();

          setState((ps) => ({
            ...ps,
            coverImage: {
              ...ps.coverImage,
              file: selected,
              error: '',
              value: lessonURL || '',
              loading: false,
            },
          }));
        }
      );
    } else {
      setState((ps) => ({
        ...ps,
        coverImage: {
          ...ps.coverImage,
          file: null,
          error: 'Please select an image file (png or jpeg)',
          value: '',
        },
      }));
    }
  };

  const isLessonContainsUnitsHandler = () => {
    setState((ps) => ({
      ...ps,
      isLessonContainsUnits: {...ps.isLessonContainsUnits, value: !ps.isLessonContainsUnits.value},
    }));
  };

  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="add-lesson-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="add-lesson-modal__header">
        {props.selectedLesson ? 'Update Lesson' : 'Add Lesson'}
        <FaWindowClose
          color="#FF4C6C"
          size={20}
          style={
            !(state.loading || state.coverImage.loading) ? {cursor: 'pointer'} : {cursor: 'default'}
          }
          onClick={() => {
            if (!(state.loading || state.coverImage.loading)) {
              props.lessonModelHandler && props.lessonModelHandler(false);
              if (!props.selectedLesson) {
                setState(updatedCreateLessonInitialState);
              } else {
                setState(updatedUpdateLessonInitialState);
              }
            }
          }}
        />
      </div>
      {state.loading && <LoadingIndicator />}

      <div className="add-lesson-copy__btn add-lesson-copy__btn--disabled">
        <FaCopy className="mr-1" /> Copy From Existing Lessons
      </div>
      <div className="add-lesson-modal__main">
        <div className="add-lesson-modal-inputs">
          <TextInput
            className="account-margin"
            stateName="subject"
            stateValue={subjectDropdownDisplayValue}
            state={state}
            setState={setState}
            error={state.subject.error}
            style={{pointerEvents: 'none'}}
          />

          {/* <div className="d-flex justify-content-between mt-3 mb-4">
            <div>
              <div className="pretty p-default p-curve p-fill mr-0">
                <input
                  type="checkbox"
                  onChange={(e: any) => {
                    // props.changeCorrectAnswerHandler && props.changeCorrectAnswerHandler(idx);
                  }}
                  checked={false}
                />
                <div className="state p-primary correct-answer" style={{marginTop: 7}}>
                  <label style={{fontSize: 12}} className="ml-1">
                    Copy Settings Only
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className="pretty p-default p-curve p-fill mr-0">
                <input
                  type="checkbox"
                  onChange={(e: any) => {
                    // props.changeCorrectAnswerHandler && props.changeCorrectAnswerHandler(idx);
                  }}
                  checked={false}
                />
                <div className="state p-primary correct-answer" style={{marginTop: 7}}>
                  <label style={{fontSize: 12}} className="ml-1">
                    Copy With Content
                  </label>
                </div>
              </div>
            </div>
          </div> */}

          <TextInput
            className="auth-input-margin-signup  mt-3 "
            stateName="lessonName"
            stateValue={state.lessonName.value}
            state={state}
            setState={setState}
            error={state.lessonName.error}
            placeHolder="Lesson name"
          />
          <ImageField
            style={{marginTop: 20}}
            stateName="coverImage"
            stateValue={truncate(state.coverImage.file?.name || '', 15)}
            state={state}
            setState={setState}
            error={state.coverImage.error}
            placeHolder="Cover Image"
            fileLoading={state.coverImage.loading}
            formLoading={state.loading}
            onChange={changeHandler}
            disableTextField={{pointerEvents: 'none'}}
          />

          <div>
            <div className="d-flex align-items-center" style={{marginTop: 20}}>
              <label className="add-lesson-modal__lsu">Lesson Contains Units</label>
              <SwitchBtn
                checked={state.isLessonContainsUnits.value}
                changeHandler={isLessonContainsUnitsHandler}
                notClickabled={!!props.selectedLesson}
              />
            </div>
            {props.selectedLesson ? (
              <p className="add-lesson-modal__warning">This setting cannot be changed</p>
            ) : (
              <p className="add-lesson-modal__warning">
                This is a permanent setting that can't be changed later. This will be determined
                whether the lesson contains any units
              </p>
            )}
          </div>

          <button
            className={`add-lesson-modal__btn w-100 ${
              state.loading || state.coverImage.loading ? 'add-lesson-modal__btn-disabled' : ''
            }`}
            onClick={() => {
              if (!(state.loading || state.coverImage.loading)) {
                if (props.selectedLesson) {
                  updateLessonHandler();
                } else {
                  addLessonHandler();
                }
              }
            }}
          >
            {props.selectedLesson ? 'Update Lesson' : 'Add Lesson'}
          </button>
        </div>

        <div
          className="add-lesson-modal-image position-relative"
          style={{backgroundImage: `url(${state.coverImage.value})`}}
        >
          {!state.coverImage.value && (
            <FaImage
              color="#474A66"
              style={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}
              className="position-absolute"
              size={40}
            />
          )}
          {state.coverImage.loading && (
            <div
              style={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}
              className="position-absolute"
            >
              <ClipLoader color="#246bfd" loading={true} size={90} speedMultiplier={0.7} />
            </div>
          )}
        </div>

        <button
          className={`add-lesson-modal__btn-mobile ${
            state.loading || state.coverImage.loading ? 'add-lesson-modal__btn-disabled' : ''
          }`}
          onClick={() => {
            if (!(state.loading || state.coverImage.loading)) {
              if (props.selectedLesson) {
                updateLessonHandler();
              } else {
                addLessonHandler();
              }
            }
          }}
        >
          {props.selectedLesson ? 'Update Lesson' : 'Add Lesson'}
        </button>
      </div>
    </Modal>
  );
};

export default CreateLessonsModal;
