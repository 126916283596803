import { useEffect } from 'react';
import '../CourseMenu.scss';
import MenuInstance from './MenuInstance';

const Mainmenu = (props: any) => {
  // const getPackageData = useFunctions().httpsCallable('getPackageDataT');

  useEffect(() => {
    // getPackageData(packageId)
    //   .then((res) => {
    //     setTData({
    //       lessonName: res.data.data.lessons.lessonsNames,
    //       unitNames: {data: res.data.data.units.sortedUnits},
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="list">
      <div className="menu">
        {props.lessons.map((lesson: any, index: number) => {
          // let temp = props.exams.filter(
          //   (value: {grade: string; examTitle: string; lessonId: string}) =>
          //     lesson.id === value.lessonId
          // );
          return (
            <MenuInstance
              key={index}
              lessonName={lesson.name ? lesson.name : 'Engineering Technology'}
              unitNames={props.units[index]}
              menuState={props.menuState}
              exams={lesson.materials.exam}
            />
          );
        })}
        <MenuInstance
          key={100}
          lessonName={'Subject Wise Examinations'}
          unitNames={[]}
          menuState={props.menuState}
          exams={props.globalExams}
        />
      </div>
    </div>
  );
};

export default Mainmenu;
