import React, {FC} from 'react';

interface Props {
  onClick: () => void;
  item: {label: string; name: string};
  value?: string | null;
  isChecked?: boolean;
  disabled?: boolean;
}

const FilterRadioButton: FC<Props> = (props: Props) => {
  return (
    <div className="pretty p-default p-round">
      <input
        type="checkbox"
        onChange={props.onClick}
        checked={props.isChecked || props.item.name === props.value}
        disabled={props.disabled}
      />

      <div className="state p-primary" style={{marginTop: 1}}>
        <label style={{fontSize: 16}}>{props.item.name.trim().length>0? props.item.name : <>&nbsp;</>}</label>
      </div>
    </div>
  );
};

export default FilterRadioButton;
