import { Container, Row } from 'react-bootstrap';

interface Props {
  title: string;
  description: string;
  btnTitle: string;
  btnOnClick: () => void;
  removeBtn?: boolean;
}

const InitialView = (props: Props) => {
  return (
    <Container fluid>
      <Row>
        <div
          className="stu-subject-home-restrict  initialview-box"
          style={{ paddingTop: '4rem', paddingBottom: '6rem' }}
        >
          <h1 className="stu-subject-home_title stu-subject-home-restrict_title">{props.title}</h1>
          <p className="stu-subject-home-restrict_text">{props.description}</p>
          {props.removeBtn ? null : (
            <button className="stu-subject-home-restrict_btn" onClick={props.btnOnClick}>
              {props.btnTitle}
            </button>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default InitialView;
