import React, {FC, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {FaEllipsisV} from 'react-icons/fa';
import {decodeHtml} from '../utils';

import ComplexityIndicator from './ComplexityIndicator';
import './MCQQuestionCardSection.scss';
import QuestionPreviewModal from './QuestionPreviewModal';

interface Props {
  task: any;
  index: number;
  searchText?: string;
}

const MCQQuestionCardSection: FC<Props> = (props) => {
  const [show, setShow] = useState(false);

  const openModalHandler = () => {
    setShow(true);
  };

  const closeModalHandler = () => {
    setShow(false);
  };

  return (
    <>
      <Draggable draggableId={props.task.id} index={props.index}>
        {(provided) => (
          <div
            className="mcq-qtn-paper-card"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={openModalHandler}
          >
            <div className="mcq-qtn-paper-card__main">
              <div className="mcq-qtn-paper-card__complexity">
                <ComplexityIndicator value={props.task.content.complexity} />
              </div>
              <div className="mcq-qtn-paper-card__text">
                {decodeHtml(props.task.content.questionText.trim()) ||
                  'Question with visual content'}
              </div>
              <FaEllipsisV color="#5C5C5C" />
            </div>
          </div>
        )}
      </Draggable>
      <QuestionPreviewModal
        show={show}
        closeModalHandler={closeModalHandler}
        content={props.task.content}
      />
    </>
  );
};

export default MCQQuestionCardSection;
