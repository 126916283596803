import { useEffect, useState } from 'react';
import { ExamType } from '../enums/ExamType';

interface State {
  countDown: any;
  timeSpend: any;
}

export function useCountDown(
  finishTime: Date,
  userExamStartTime: Date | null,
  examType?: ExamType,
  setSubmitted?: Function,
  submitted?: boolean,
  examSessionId?: string
) {
  const calculateTimeLeft = () => {
    let difference;

    difference = +finishTime - +new Date();

    let spendTimeDifference = 0;

    if (userExamStartTime) {
      spendTimeDifference = +new Date() - (+userExamStartTime || +new Date());
    }

    let timeLeft = {};

    let spendTime = {};

    if (difference <= 0 && examType !== ExamType.EXTRA_EXAM && !submitted) {
      setSubmitted && setSubmitted(true);
      sessionStorage.removeItem(examSessionId || '');
    }

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    if (spendTimeDifference > 0) {
      spendTime = {
        days: Math.floor(spendTimeDifference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((spendTimeDifference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((spendTimeDifference / 1000 / 60) % 60),
        seconds: Math.floor((spendTimeDifference / 1000) % 60),
      };
    }

    return [timeLeft, spendTime];
  };

  const [timeLeft, setTimeLeft] = useState<State>({
    countDown: calculateTimeLeft()[0],
    timeSpend: calculateTimeLeft()[1],
  });

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft({ countDown: calculateTimeLeft()[0], timeSpend: calculateTimeLeft()[1] });
    }, 1000);
  });

  const timerComponents: any[] = [];

  const spendTimeComponents: any[] = [];

  Object.keys(timeLeft.countDown).forEach((interval: string) => {
    /* @ts-ignore */
    timerComponents.push(timeLeft.countDown[interval]);
  });

  Object.keys(timeLeft.timeSpend).forEach((interval: string) => {
    /* @ts-ignore */
    spendTimeComponents.push(timeLeft.timeSpend[interval]);
  });

  return [timerComponents, spendTimeComponents];
}
