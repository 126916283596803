import React, {FC, useEffect, useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';
import truncate from 'truncate';
import './LabelDropDown.scss';

interface Props {
  name: string;
  noValueText: string;
  className?: string;
  style?: React.CSSProperties;
  stateName: string;
  stateValue: {displayValue: string; id: string}[];
  state: any;
  setState: Function;
  optionsArray: {displayValue: string; id: string}[];
  error?: string;
  truncate?: number;
  labelSize?: React.CSSProperties;
  disabled?: boolean;
  onInputChange?: () => void;
}

const LabelDropDown: FC<Props> = (props) => {
  const [state, setState] = useState(false);

  const inputChangeHandler = (
    state: any,
    name: string,
    value: {displayValue: string; id: string}
  ) => {
    const array = state[name].value;

    array.push(value);

    props.setState({
      ...state,
      [name]: {...state[name], value: array},
    });

    props.onInputChange && props.onInputChange();
  };

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: {...state[name], error: ''},
    });
  };

  useEffect(() => {
    var ignoreClickOnMeElement = document.getElementById(`id${props.stateName}`);

    document.addEventListener('click', function (event) {
      var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target as Node);
      if (!isClickInsideElement) {
        setState(false);
      }
    });
  }, [props.stateName]);

  let borderColor =
    props.error === 'NO-ERROR'
      ? {borderBottom: '1px solid #20D167'}
      : props.error === ''
      ? {}
      : {borderBottom: '1px solid #dc3545'};

  return (
    <div
      className={`custom-dropdown ${props.className}`}
      id={`id${props.stateName}`}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        setState(true);
        resetError(props.state, props.stateName);
      }}
      style={{...props.style}}
    >
      <div className="custom-dropdown__line" style={borderColor}>
        <div style={{paddingTop: 3, paddingBottom: 2.5}}>
          {/* {truncate(props.stateValue.displayValue || props.noValueText, props.truncate || 30)} */}
          Select Label
        </div>

        <FaChevronDown
          color="#246BFD"
          size={12}
          className="eye"
          style={{position: 'absolute', right: 0, top: -4}}
        />
      </div>

      <div className="custom-dropdown__name" style={props.labelSize}>
        {props.name}
      </div>

      <div
        className="position-absolute custom-dropdown-list"
        style={{
          display: state ? 'block' : 'none',
        }}
      >
        {props.optionsArray.map((item, index) => {
          let className = 'dropdown-item-first d-flex justify-content-between';
          if (index) {
            className = 'dropdown-item-rest d-flex justify-content-between';
          }

          return (
            <div
              className={className}
              onClick={(e) => {
                const evt: any = e.target;

                inputChangeHandler(props.state, props.stateName, {
                  displayValue: evt.innerHTML,
                  id: evt.id,
                });
                setState(false);
                e.stopPropagation();
              }}
              key={index}
              id={item.id}
            >
              {item.displayValue === '' || item.id === ''
                ? props.noValueText
                : truncate(item.displayValue, props.truncate || 30)}
            </div>
          );
        })}
      </div>

      {props.error && props.error !== 'NO-ERROR' && (
        <div
          style={{fontSize: 11, lineHeight: 1, top: 48, left: 0, fontWeight: 400, marginTop: 8}}
          className="text-danger"
        >
          {props.error || 'error'}
        </div>
      )}
    </div>
  );
};

export default LabelDropDown;
