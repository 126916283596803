import { Editor } from '@tinymce/tinymce-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useFirestore, useStorage } from 'reactfire';
import * as firebase from 'firebase/app';
import './TinyMCE.scss';
import { useAppContent } from '../context/ContentContext';

interface Props {
    value: string | null;
    onChange: (txt: string, idx?: number) => void;
    idx?: number;
    style?: React.CSSProperties;
    className?: string;
    onFocus?: (idx?: number) => void;
    error?: string;
}

const TinyMCE: FC<Props> = (props) => {
    const editorRef = useRef<any>(null);
    const storage = useStorage();
    const fire = useFirestore();
    const appContent = useAppContent()

    const [loading, setLoading] = useState<boolean>(true);

    let borderStyles =
        props.error === 'NO-ERROR'
            ? {
                borderBottom: '1px solid #20D167',
                borderRight: '1px solid #20D167',
                borderLeft: '1px solid #20D167',
                borderRadius: 8,
            }
            : props.error === ''
                ? {}
                : {
                    borderBottom: '1px solid #dc3545',
                    borderRight: '1px solid #dc3545',
                    borderLeft: '1px solid #dc3545',
                    borderRadius: 8,
                };

    let errorJSX = null;

    if (props.error !== 'NO-ERROR') {
        errorJSX = <div className="editor-validation-error">{props.error}</div>;
    }

    useEffect(() => {

        setLoading(true)

        setTimeout(
            () => setLoading(false),
            500
        )

    }, [appContent.tinyMCEChangedTimeSnap])

    const editor = loading ? <div className="text-white"> Redering the editor...</div> : <div className={props.className}>
        <div style={props.style} className="position-relative">
            <div
                className={loading ? 'd-block position-absolute' : 'd-none'}
                style={{
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 100,
                    color: '#fff',
                }}
            >
                Redering the editor...
            </div>
            <div className={loading ? 'd-none' : 'd-block'} style={borderStyles}>
                <Editor
                    apiKey="egxe5hqfwkmhm4ewv8yocs2xskxn3ea6eaqboyda0re5o4ot"
                    onInit={(evt, editor) => {
                        setLoading(false);
                        editorRef.current = editor;

                    }}
                    value={props?.value || ''}
                    // initialValue={props.value}
                    init={{
                        preformatted: false,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                        ],
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        automatic_uploads: true,
                        paste_data_images: true,
                        toolbar:
                            'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',

                        images_upload_handler: async function (blobInfo: any, success: any, failure: any) {
                            // console.log('BLOB ', blobInfo);
                            // console.log('File ', blobInfo.filename());
                            const fileName = blobInfo.filename();
                            const extension = fileName.split('.').pop();
                            const modifiedFileName = `${fileName.split('.')[0]
                                }_${new Date().getTime()}.${extension}`;

                            const size = blobInfo.blob().size / 1024 / 1024;

                            if (size > 1) {
                                failure(
                                    `Your file is ${size.toFixed(
                                        2
                                    )} MB large. </br>Maximum allowed image size is 1 MB. </br><span style='font-style: italic'>Tip: Reducing the quality, resolution or cropping your image will help to reduce the size.</span>`
                                );

                                return;
                            }

                            const file = new File([blobInfo.blob()], modifiedFileName, {
                                type: blobInfo.blob().type,
                            });

                            const storageRef = storage.ref(modifiedFileName);
                            const collectionRef = fire.collection('images');

                            storageRef.put(file).on(
                                'state_changed',
                                (snap) => {
                                    //let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
                                    //console.log('Per -', percentage);
                                },
                                (err) => {
                                    failure('Error Uploading');
                                    console.log('Err -', err);
                                },
                                async () => {
                                    const url = await storageRef.getDownloadURL();
                                    const createdAt = firebase.firestore.FieldValue.serverTimestamp();
                                    collectionRef.add({ url, createdAt });
                                    success(url);
                                    //console.log('URL -', url);
                                }
                            );
                        },
                        skin: 'oxide-dark',
                        content_css: 'dark',
                    }}
                    onEditorChange={(value) => {
                        props.onChange(value, props.idx);
                    }}
                    onFocus={() => {
                        props.onFocus && props.onFocus(props.idx);
                    }}
                />
            </div>
            {errorJSX}
        </div>
    </div>

    return editor
};

export default React.memo(TinyMCE);
