import React from 'react';
import {Route, Switch} from 'react-router-dom';
import PrimaryNav from '../../components/PrimaryNav';

import TASubjectSecondaryNav from '../../components/TASubjectSecondaryNav';
import LayoutNew from '../../layouts/LayoutNew';
import BreadCrumbUpdate from '../../shared/BreadCrumbUpdate';
import TASubjectAnnouncementScreen from './TASubjectAnnouncementScreen';
import TASubjectLessonsScreen from './TASubjectLessonsScreen';
import TASubjectModalPapersScreen from './TASubjectModalPapersScreen';

const TASubjectScreen = () => {
  let appHeader = <BreadCrumbUpdate />;
  let primaryNavigation = <PrimaryNav />;
  let secondaryNavigation = <TASubjectSecondaryNav />;
  let appBody = (
    <Switch>
      <Route exact path="/my-subjects/subject/:subjectId/Lessons">
        <TASubjectLessonsScreen />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Examinations">
        <TASubjectModalPapersScreen />
      </Route>
      <Route exact path="/my-subjects/subject/:subjectId/Announcements">
        <TASubjectAnnouncementScreen />
      </Route>
      {/* <Route path="*" component={PageNotFoundScreen} /> */}
    </Switch>
  );

  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

export default TASubjectScreen;
