import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './SearchBox.scss';
import useDebounce from './../hooks/useDebounce';
import { FaSearch } from 'react-icons/fa';

interface Props {
  style?: React.CSSProperties;
  onSearch: (searchTerm: string) => void;
  widthClassName?: string;
  placeHolderText: string;
  searchClassName?: string;
  isFullWidth?: boolean;
  className?: string;
  clearSearchHandler?: (value: boolean) => void;
  isClearSearch?: boolean;
}

const SearchBox: FC<Props> = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  useDebounce(searchTerm, 1000, (val) => {
    props.onSearch(val);
  });

  useEffect(() => {
    if (props.isClearSearch) {
      setSearchTerm('');
      props.clearSearchHandler && props.clearSearchHandler(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isClearSearch]);

  return (
    <Row className={`mx-0 flex-wrap ${props.className}`} style={{ ...props.style }}>
      <Col className={`px-0 ${props.isFullWidth ? '' : 'col-auto'}  ${props.widthClassName}`}>
        <input
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          className={`search_text w-100 ${props.widthClassName}`}
          type="text"
          placeholder={props.placeHolderText}
          value={searchTerm}
        />
      </Col>
      <Col className="pl-0 col-auto">
        <button className={`search_button ${props.searchClassName}`}>
          <FaSearch />
        </button>
      </Col>
    </Row>
  );
};

export default SearchBox;
