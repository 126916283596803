import React, { FC } from 'react';
import { FaArchive, FaEdit, FaTrashAlt } from 'react-icons/fa';
import truncate from 'truncate';
import './PaperCard.scss';
import { PaperStatus } from '../enums/PaperStatus';
import { useAppUser } from '../context/UserContext';
import BoxIcon from './BoxIcon';
import { PAPER_BREADCRUM_STEPS } from '../constants';

interface Props {
  paperName: string;
  onClick: () => void;
  searchText: string;
  status: PaperStatus;
  paperAuthor?: string;
  onDelete?: () => void;
  setPaperNaviationData: Function;
  paperNaviationData: any;
  paperData: any;
}

const PaperCard: FC<Props> = (props) => {
  const renderedText = props.paperName.replace(/<[^>]+>/g, '');

  const { firestoreUser } = useAppUser();

  let actions = (
    <BoxIcon
      icon={FaArchive}
      color="#fff"
      size={13}
      style={{ width: 30, height: 30, backgroundColor: '#404462' }}
    />
  );

  if (firestoreUser?.userRole?.isAdmin) {
    actions = (
      <>
        <BoxIcon
          icon={FaArchive}
          color="#fff"
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#404462' }}
        />
        <BoxIcon
          icon={FaEdit}
          color="#fff"
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#404462', marginLeft: 10 }}
          onClick={() => {
            props.setPaperNaviationData({
              ...props.paperNaviationData,
              mode: 'EDIT',
              step: PAPER_BREADCRUM_STEPS.STEP_ONE,
              paperData: props.paperData,
            });
          }}
        />

        <BoxIcon
          icon={FaTrashAlt}
          color="#fff"
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: 10 }}
          onClick={() => {
            props.onDelete && props.onDelete();
          }}
        />
      </>
    );
  } else if (firestoreUser?.uid === props?.paperAuthor) {
    actions = (
      <>
        <BoxIcon
          icon={FaArchive}
          color="#fff"
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#404462' }}
        />
        <BoxIcon
          icon={FaEdit}
          color="#fff"
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#404462', marginLeft: 10 }}
          onClick={() => {
            props.setPaperNaviationData({
              ...props.paperNaviationData,
              mode: 'EDIT',
              step: PAPER_BREADCRUM_STEPS.STEP_ONE,
              paperData: props.paperData,
            });
          }}
        />
        <BoxIcon
          icon={FaTrashAlt}
          color="#fff"
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: 10 }}
          onClick={() => {
            props.onDelete && props.onDelete();
          }}
        />
      </>
    );
  }

  return (
    <div className="p-card" onClick={props.onClick}>
      <div className="p-card_main">
        <div className="p-card__text">
          {/* <Highlighter
            highlightClassName="question-text-highlight"
            searchWords={props.searchText.split(' ')}
            autoEscape={true}
            textToHighlight={truncate(renderedText || '', 150) || ''}
          /> */}
          <div>{truncate(renderedText || '', 150) || ''}</div>
        </div>
        <div className="p-card__status">
          <span>{props.status}</span>
        </div>
        <div className="p-card__actions">{actions}</div>
      </div>
    </div>
  );
};

export default PaperCard;
