import {FC, useEffect, useState} from 'react';
import {FaWindowClose} from 'react-icons/fa';
import Highlighter from 'react-highlight-words';
import Modal from 'react-modal';
import './AssignSubjectsModal.scss';
import {useFunctions} from 'reactfire';
import truncate from 'truncate';
import Notification from './../components/Notification';
import {UserFirestore} from '../models/UserFirestore';
import {sortUsersFromUserName} from '../utils';
import SearchBox from './SearchBox';
import LoadingIndicator from './LoadingIndicator';

interface Props {
  title: string;
  users: UserFirestore[];
  show: boolean;
  closeModal: () => void;
  subjectId: string;
  fetchUsers: () => void;
  onAssign: (id: string) => void;
  type: 'ASSISTANT' | 'INSTRUCTOR';
}

const AssignSubjectsModal: FC<Props> = (props) => {
  const [searchText, setSearchText] = useState('');
  const [users, setUsers] = useState<UserFirestore[]>(props.users);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  useEffect(() => {
    let searchResult: any[] = [];
    searchResult = sortUsersFromUserName([...props.users]);

    if (searchText?.trim()) {
      searchResult = searchResult.filter(
        (item) => item.username.toUpperCase().indexOf(searchText.toUpperCase()) > -1
      );

      setUsers([...searchResult]);
    } else {
      setUsers([...searchResult]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="assign-user-model"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="assign-user-model__header d-flex justify-content-between">
        <div className="assign-user-model__title text-white">{props.title} </div>
        <FaWindowClose
          color="#FF4C6C"
          size={20}
          onClick={() => {
            if (!loading) {
              props.closeModal();
              setSearchText('');
            }
          }}
        />
      </div>

      <div className="assign-user-model__search">
        <SearchBox
          onSearch={(text) => {
            setSearchText(text);
          }}
          placeHolderText={props.type === 'ASSISTANT' ? 'Search Assistant' : 'Search Instructor'}
          widthClassName="assign-user-model__searchbox"
        />
      </div>
      <>
        {loading && <LoadingIndicator />}{' '}
        <div className="assign-user-model__userlist">
          {users.map((user, idx) => (
            <InstructorTACard
              user={user}
              subjectId={props.subjectId}
              key={idx}
              fetchUsers={props.fetchUsers}
              onAssign={props.onAssign}
              setLoading={setLoading}
              searchText={searchText}
            />
          ))}
        </div>
      </>
    </Modal>
  );
};

export default AssignSubjectsModal;

const InstructorTACard: FC<{
  user: UserFirestore;
  subjectId: string;
  fetchUsers: () => void;
  onAssign: (id: string) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  searchText: string;
}> = (props) => {
  const assignSubjectsToInstructorAndTARef = useFunctions().httpsCallable(
    'assignSubjectsToInstructorAndTA'
  );

  return (
    <>
      <div
        className="instructor-ta-card text-white"
        onClick={() => {
          props.setLoading(true);

          assignSubjectsToInstructorAndTARef({
            assignedSubject: props.subjectId,
            uid: props.user.uid,
          })
            .then((data) => {
              console.log('TA ASSIGNMENT DATA', data.data.data);

              props.onAssign(data.data.data);

              Notification({
                isSuccess: true,
                message: `Assigned ${props.user.username} to the subject`,
                id: 'tid',
              });

              props.setLoading(false);
              // props.fetchUsers();
            })
            .catch(() => {
              props.setLoading(false);
            });
        }}
      >
        <Highlighter
          highlightClassName="question-text-highlight"
          searchWords={props.searchText.split(' ')}
          autoEscape={true}
          textToHighlight={truncate(props.user.username || '', 150) || ''}
        />
      </div>
    </>
  );
};
