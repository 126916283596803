import React, {FC, useEffect, useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';
import truncate from 'truncate';
import {useSubjects} from '../context/SubjectsContext';
import './SubjectDropDown.scss';
import SubjectDropdownCard from './SubjectDropdownCard';

interface Props {
  name: string;
  noValueText: string;
  className?: string;
  style?: React.CSSProperties;
  stateName: string;
  stateValue: {displayValue: string; id: string};
  state: any;
  setState: Function;
  optionsArray: {displayValue: string; id: string}[];
  error?: string;
  truncate?: number;
  labelSize?: React.CSSProperties;
  disabled?: boolean;
  fireOnclick?: () => void;
}

const SubjectDropDown: FC<Props> = (props) => {
  const appSubjects = useSubjects();

  const [state, setState] = useState(false);

  const inputChangeHandler = (
    state: any,
    name: string,
    value: { displayValue: string; id: string }
  ) => {
    props.setState({
      ...state,
      [name]: { ...state[name], value: value },
    });

    props.fireOnclick && props.fireOnclick();
  };

  const resetError = (state: any, name: string) => {
    props.setState({
      ...state,
      [name]: { ...state[name], error: '' },
    });
  };

  useEffect(() => {
    var ignoreClickOnMeElement = document.getElementById(`id${props.stateName}`);

    document.addEventListener('click', function (event) {
      var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target as Node);
      if (!isClickInsideElement) {
        setState(false);
      }
    });
  }, [props.stateName]);

  let borderColor =
    props.error === 'NO-ERROR'
      ? { borderBottom: '1px solid #20D167' }
      : props.error === ''
      ? {}
      : { borderBottom: '1px solid #dc3545' };

  useEffect(() => {
    if (props.stateValue.displayValue) resetError(props.state, props.stateName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stateValue.displayValue]);

  return (
    <div
      className={`custom-dropdown ${props.className}`}
      id={`id${props.stateName}`}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        setState(true);
        // resetError(props.state, props.stateName);
      }}
      style={{ ...props.style }}
    >
      <div className="custom-dropdown__line" style={borderColor}>
        <div style={{ paddingTop: 3, paddingBottom: 2.5 }}>
          {truncate(props.stateValue.displayValue || props.noValueText, props.truncate || 30)}
        </div>

        <FaChevronDown
          color="#246BFD"
          size={12}
          className="eye"
          style={{ position: 'absolute', right: 0, top: -4 }}
        />
      </div>

      <div className="custom-dropdown__name" style={props.labelSize}>
        {props.name}
      </div>

      <div
        className="position-absolute custom-dropdown-list"
        style={{
          display: state ? 'block' : 'none',
        }}
      >
        {props.state.grade.value.displayValue !== '' &&
          props.optionsArray.map((item, index) => {
            let className = 'dropdown-item-first';
            if (index) {
              className = 'dropdown-item-rest';
            }

            const subject = appSubjects.allSubjects.find((sub) => sub.id === item.id);

            return (
              <SubjectDropdownCard
                className={className}
                inputChangeHandler={inputChangeHandler}
                setState={setState}
                id={item.id}
                state={props.state}
                noValueText={props.noValueText}
                stateName={props.stateName}
                subject={subject}
                key={index}
              />
            );
          })}
      </div>

      {props.error && props.error !== 'NO-ERROR' && (
        <div
          style={{
            fontSize: 11,
            lineHeight: 1,
            top: 48,
            left: 0,
            fontWeight: 400,
            marginTop: 8,
          }}
          className="text-danger"
        >
          {props.error || 'error'}
        </div>
      )}
    </div>
  );
};

export default SubjectDropDown;
