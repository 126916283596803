import firebase from 'firebase';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { FaBan, FaSortAmountDown, FaSortAmountDownAlt } from 'react-icons/fa';
import { useFunctions } from 'reactfire';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import truncate from 'truncate';
import TAPaymentTab from './TAPaymentTab';
import { Exam } from '../models/Exam';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSubjects } from '../context/SubjectsContext';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
import { splitArrayIntoChunksOfLen } from '../utils';
import AlertPopup from './AlertPopup';
import SearchWidget from './SearchWidget';
import LoadingIndicator from './LoadingIndicator';
import Notification from './../components/Notification';
import { CHECKBOXSTATUS } from '../enums';
import StudentTAAssigmentCheckbox from './StudentTAAssigmentCheckbox';
import StudentTAAssignmentCard from './StudentTAAssignmentCard';
import PaginationNew from './PaginationNew';
import './ExamTAAssignmentChart.scss';

export interface StudentTAAssignmentInfo {
  stuUid: string;
  stuName: string;
  isMarkedAllPapers?: boolean;
  papers: {
    paperId: string;
    paperTitle: string;
    isMarked?: boolean;
    assignedTAUid:
      | {
          taUid: string;
          assignedTime?: firebase.firestore.Timestamp;
          changed?: boolean;
        }
      | null
      | undefined;
  }[];
}

interface Props {
  updatestudentTAAssignmentInfoHandler?: (input: StudentTAAssignmentInfo[]) => void;
  hierarchyLevel?: 'LESSON' | 'UNIT';
}

const ExamTAAssignmentChart: FC<Props> = (props) => {
  interface TaAssignmentInfo {
    studentAssignmentInfoList: StudentTAAssignmentInfo[];
    paginatedSegments: StudentTAAssignmentInfo[][];
    tasForExam: string[];
    loading: boolean;

    sort: boolean;
    page: number;
    searchTerm: string;
    trigger: number;
  }

  const initialState = {
    studentAssignmentInfoList: [],
    paginatedSegments: [],
    tasForExam: [],
    loading: false,

    sort: true,
    page: 1,
    searchTerm: '',
    trigger: 1, //to clear the pagination
  };

  const [state, setState] = useState<TaAssignmentInfo>(initialState);
  const [tab, setTab] = useState(0);
  const [exam, setExam] = useState<Exam | null>(null);

  const params = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
    examId: string;
  };
  const history = useHistory();
  const subjects = useSubjects();
  const appUsers = useAdminsAndInstructors();
  const PAGINATION = 8;

  const setLoading = (value: boolean) => {
    setState({ ...state, loading: value });
  };
  const getStudentTAAssignmentDataRef = useFunctions().httpsCallable('getStudentTAAssignmentData');
  const saveExaminationStudentTAMarkingChartRef = useFunctions().httpsCallable(
    'saveExaminationStudentTAMarkingChart'
  );

  const [isPaymentInfoCompleted, setIsPaymentInfoCompleted] = React.useState<boolean>(false);

  useEffect(() => {
    if (exam) {
      const papers = exam.papers;
      const strucureEssayPapers = papers.filter(
        (paper) => paper.paperType === 'STRUCTURED' || paper.paperType === 'ESSAY'
      );

      if (strucureEssayPapers.length > 0) {
        const isPaymentCompleted = strucureEssayPapers.every(
          (paper) => paper.taPayment?.isFree || (paper.taPayment && paper.taPayment?.amount > 0)
        );
        setIsPaymentInfoCompleted(!isPaymentCompleted);
      } else {
        setIsPaymentInfoCompleted(false);
      }
    }
  }, [exam]);
  useEffect(() => {
    setLoading(true);

    getStudentTAAssignmentDataRef({ examId: params.examId, subjectId: params.subjectId })
      .then((data) => {
        const updatedStated = { ...state };
        setExam(data.data.data.exam);
        updatedStated.studentAssignmentInfoList = data.data.data.data;

        updatedStated.studentAssignmentInfoList.forEach((value) => {
          const name = appUsers?.allAdminsAndInstructors?.find(
            (val) => val?.uid === value?.stuUid
          )?.username;

          value.stuName = name || '';
        });

        updatedStated.studentAssignmentInfoList = sortHander(
          updatedStated.studentAssignmentInfoList,
          true
        );

        const segments = splitArrayIntoChunksOfLen(
          updatedStated.studentAssignmentInfoList,
          PAGINATION
        );
        updatedStated.paginatedSegments = segments;

        const taArray = subjects.allSubjects.find(
          (val) => val.id === params.subjectId
        )?.assignedUsers;

        const arr: string[] = [];

        taArray?.forEach((val) => {
          const ta = appUsers.allAdminsAndInstructors.find(
            (v) => v.userRole?.isTeachingAssistant && v.uid === val
          )?.uid;
          if (ta) {
            arr.push(ta);
          }
        });

        updatedStated.tasForExam = arr;

        setState(updatedStated);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //methods

  const assignTAsHandler = (value: { stuUid: string; taUid: string; paperId: string }) => {
    const currentState = { ...state };

    const studentAssignmentInfo = currentState.studentAssignmentInfoList.find(
      (val) => val.stuUid === value.stuUid
    );

    if (studentAssignmentInfo) {
      const paper = studentAssignmentInfo.papers.find((p) => p.paperId === value.paperId);

      if (paper) {
        const present = paper.assignedTAUid?.taUid === value.taUid;

        if (present) {
          paper.assignedTAUid = null;
        } else {
          paper.assignedTAUid = {
            taUid: value.taUid,
            changed: true,
          };
        }

        const idx = [...studentAssignmentInfo.papers].findIndex(
          (val) => val.paperId === value.paperId
        );

        studentAssignmentInfo.papers[idx] = paper;

        const idx1 = [...currentState.studentAssignmentInfoList].findIndex(
          (v) => v.stuUid === value.stuUid
        );

        currentState.studentAssignmentInfoList[idx1] = studentAssignmentInfo;
        // currentState.paginatedSegments = splitArrayIntoChunksOfLen(
        //   currentState.studentAssignmentInfoList,
        //   PAGINATION
        // );

        setState({
          ...currentState,
          studentAssignmentInfoList: currentState.studentAssignmentInfoList,
        });
      }
    }
  };

  const assignAllPapersHandler = (taUid: string) => {
    const currentState = { ...state };
    currentState.studentAssignmentInfoList.forEach((val) => {
      val.papers.forEach((p) => {
        if (!p.isMarked) {
          p.assignedTAUid = {
            taUid: taUid,
            changed: true,
          };
        }
      });
    });

    // currentState.paginatedSegments = splitArrayIntoChunksOfLen(
    //   currentState.studentAssignmentInfoList,
    //   PAGINATION
    // );

    setState({
      ...currentState,
    });
  };

  const removeAllPapersHandler = () => {
    const currentState = { ...state };

    currentState.studentAssignmentInfoList.forEach((val) => {
      val.papers.forEach((p) => {
        if (!p.isMarked) {
          p.assignedTAUid = null;
        }
      });
    });

    // currentState.paginatedSegments = splitArrayIntoChunksOfLen(
    //   currentState.studentAssignmentInfoList,
    //   PAGINATION
    // );

    setState({
      ...currentState,
    });
  };

  const assignStudentAllPapersHander = (value: { stuUid: string; taUid: string }) => {
    const currentState = { ...state };
    const studentAssignmentInfo = currentState.studentAssignmentInfoList.find(
      (val) => val.stuUid === value.stuUid
    );

    const index = currentState.studentAssignmentInfoList.findIndex(
      (val) => val.stuUid === value.stuUid
    );

    if (studentAssignmentInfo) {
      studentAssignmentInfo.papers.forEach((val) => {
        if (!val.isMarked) {
          val.assignedTAUid = {
            taUid: value.taUid,
            changed: true,
          };
        }
      });

      currentState.studentAssignmentInfoList[index] = studentAssignmentInfo;

      setState({
        ...currentState,
        studentAssignmentInfoList: currentState.studentAssignmentInfoList,
        // paginatedSegments: splitArrayIntoChunksOfLen(
        //   currentState.studentAssignmentInfoList,
        //   PAGINATION
        // ),
      });
    }
  };

  const removeStudentAllPapersHander = (stuUid: string) => {
    const currentState = { ...state };

    const studentAssignmentInfo = currentState.studentAssignmentInfoList.find(
      (val) => val.stuUid === stuUid
    );

    const index = currentState.studentAssignmentInfoList.findIndex((val) => val.stuUid === stuUid);

    if (studentAssignmentInfo) {
      studentAssignmentInfo.papers.forEach((val) => {
        if (!val.isMarked) {
          val.assignedTAUid = null;
        }
      });

      currentState.studentAssignmentInfoList[index] = studentAssignmentInfo;

      setState({
        ...currentState,
        studentAssignmentInfoList: currentState.studentAssignmentInfoList,
        // paginatedSegments: splitArrayIntoChunksOfLen(
        //   currentState.studentAssignmentInfoList,
        //   PAGINATION
        // ),
      });
    }
  };

  // const minWidth = state.tasForExam.length * 120 + 190;

  const sortHander = (arr: StudentTAAssignmentInfo[], asc: boolean) => {
    return asc
      ? arr.sort(function (a, b) {
          const v1 = appUsers?.allAdminsAndInstructors?.find(
            (val) => val?.uid === a?.stuUid
          )?.username;
          const v2 = appUsers?.allAdminsAndInstructors?.find(
            (val) => val?.uid === b?.stuUid
          )?.username;

          if (v1 && v2 && v1 < v2) {
            return -1;
          }
          if (v1 && v2 && v1 > v2) {
            return 1;
          }
          return 0;
        })
      : arr.sort(function (a, b) {
          const v1 = appUsers?.allAdminsAndInstructors?.find(
            (val) => val?.uid === a?.stuUid
          )?.username;
          const v2 = appUsers?.allAdminsAndInstructors?.find(
            (val) => val?.uid === b?.stuUid
          )?.username;

          if (v1 && v2 && v1 > v2) {
            return -1;
          }
          if (v1 && v2 && v1 < v2) {
            return 1;
          }
          return 0;
        });
  };

  const setCurrentPage = (page: number) => {
    setState((ps) => ({ ...ps, page: page }));
  };

  const searchHandler = (term: string) => {
    setState((ps) => ({ ...ps, searchTerm: term }));
  };

  useEffect(() => {
    if (state.studentAssignmentInfoList.length) {
      const updatedState = { ...state };

      let arr: StudentTAAssignmentInfo[] = [];

      if (state.searchTerm.trim()) {
        arr = updatedState.studentAssignmentInfoList.filter((item) => {
          return item.stuName.toLowerCase().startsWith(state.searchTerm.toLowerCase());
        });

        const segments = splitArrayIntoChunksOfLen(arr, PAGINATION);
        updatedState.paginatedSegments = segments;
        updatedState.page = 1;
        updatedState.trigger = updatedState.trigger - 1;

        setState({ ...updatedState });
      } else {
        const segments = splitArrayIntoChunksOfLen(
          updatedState.studentAssignmentInfoList,
          PAGINATION
        );
        updatedState.paginatedSegments = segments;
        updatedState.page = 1;
        updatedState.trigger = updatedState.trigger + 1;

        setState({ ...updatedState });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchTerm]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    if (query.get('name') === 'Assign') {
      setTab(0);
    } else if (query.get('name') === 'Payments') {
      setTab(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <section className="taa-container">
      <AlertPopup
        message={'Please add paper marking cost in exam Settings'}
        type="OK"
        isShow={isPaymentInfoCompleted}
        onClose={() => {
          if (props.hierarchyLevel === 'LESSON') {
            history.push(
              `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Examinations/${params.examId}/settings`
            );
          } else if (props.hierarchyLevel === 'UNIT') {
            history.push(
              `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations/${params.examId}/settings`
            );
          } else {
            history.push(
              `/my-subjects/subject/${params.subjectId}/Examinations/${params.examId}/settings`
            );
          }
        }}
      />
      <nav className="taa-nav">
        <div className="taa-nav__tabs">
          <div
            className={`${'taa-nav__assignment'}  ${tab === 0 && 'taa-nav__assignment--active'}`}
            onClick={() => {
              if (props.hierarchyLevel === 'LESSON') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Examinations/${params.examId}/ta?name=Assign`
                );
              } else if (props.hierarchyLevel === 'UNIT') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations/${params.examId}/ta?name=Assign`
                );
              } else {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/Examinations/${params.examId}/ta?name=Assign`
                );
              }
            }}
          >
            Assign Assistant
          </div>
          <div
            className={`${'taa-nav__payment'}  ${tab === 1 && 'taa-nav__payment--active'}`}
            onClick={() => {
              if (props.hierarchyLevel === 'LESSON') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Examinations/${params.examId}/ta?name=Payments`
                );
              } else if (props.hierarchyLevel === 'UNIT') {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Examinations/${params.examId}/ta?name=Payments`
                );
              } else {
                history.push(
                  `/my-subjects/subject/${params.subjectId}/Examinations/${params.examId}/ta?name=Payments`
                );
              }
            }}
          >
            Payments
          </div>
        </div>
        <div className="taa-nav__search">
          {tab ? (
            <></>
          ) : (
            <SearchWidget
              onSearch={searchHandler}
              placeHolderText="Search Student"
              className="mt-0"
            />
          )}
        </div>
      </nav>

      {tab ? (
        <TAPaymentTab examId={params.examId} />
      ) : (
        <Fragment>
          {state.loading && <LoadingIndicator />}
          <section className="taa-keys-container">
            <div className="taa-keys">
              <div
                className="taa-keys-one"
                style={{
                  gap: '5px',
                }}
              >
                <div></div>
                All the papers are assigned to the TA
              </div>
              <div
                className="taa-keys-two"
                style={{
                  gap: '5px',
                }}
              >
                <div></div>
                Some of the papers are assigned to the TA
              </div>
              <div
                className="taa-keys-three"
                style={{
                  gap: '5px',
                }}
              >
                <div>
                  <FaBan />
                </div>
                Can't change the TA because the paper is marked
              </div>
            </div>
            <button
              onClick={() => {
                if (!state.loading) {
                  setLoading(true);

                  saveExaminationStudentTAMarkingChartRef({
                    examId: params.examId,
                    data: state.studentAssignmentInfoList,
                  })
                    .then(() => {
                      setLoading(false);

                      props.updatestudentTAAssignmentInfoHandler &&
                        props.updatestudentTAAssignmentInfoHandler(state.studentAssignmentInfoList);
                      Notification({
                        isSuccess: true,
                        message: 'TA assignment information saved successfully.',
                        id: 'tid',
                      });
                    })
                    .catch(() => {
                      setLoading(false);

                      Notification({
                        isSuccess: false,
                        errorHeader: 'Please Update your profile',
                        message: 'Failed to save TA assignment information.',
                        id: 'tid',
                      });
                    });
                }
              }}
              className={state.loading ? 'taa-keys-btn--disabled' : ''}
            >
              Save
            </button>
          </section>
          <section className="taa-chart" style={{ width: '100%', overflowX: 'auto' }}>
            <section className="taa-chart__tas">
              <div className="taa-chart__tas-stu-name">
                Student Name{' '}
                {state.sort ? (
                  <FaSortAmountDownAlt
                    onClick={() => {
                      const arr = sortHander(state.studentAssignmentInfoList, false);
                      const arr1 = splitArrayIntoChunksOfLen(arr, PAGINATION);
                      setState((ps) => {
                        return { ...ps, studentAssignmentInfoList: arr, paginatedSegments: arr1 };
                      });

                      setState((ps) => ({ ...ps, sort: false }));
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <FaSortAmountDown
                    onClick={() => {
                      const arr = sortHander(state.studentAssignmentInfoList, true);
                      const arr1 = splitArrayIntoChunksOfLen(arr, PAGINATION);
                      setState((ps) => {
                        return { ...ps, studentAssignmentInfoList: arr, paginatedSegments: arr1 };
                      });
                      setState((ps) => ({ ...ps, sort: true }));
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
              <div className="taa-chart__tas-checkboxs">
                {state.tasForExam.map((taUid, index) => {
                  const currentState = { ...state };

                  let totalPapers = 0;
                  let taUidsArray: string[] = [];

                  currentState.studentAssignmentInfoList.forEach((v) => {
                    totalPapers = totalPapers + v.papers.length;
                    v.papers.forEach((v) => {
                      taUidsArray.push(v.assignedTAUid?.taUid || '');
                    });
                  });

                  const count = taUidsArray.filter((v) => v === taUid).length;

                  const status =
                    totalPapers === 0
                      ? CHECKBOXSTATUS.NONE
                      : totalPapers === count
                      ? CHECKBOXSTATUS.FULL
                      : CHECKBOXSTATUS.NONE;

                  // const status = totalPapers === count ? CHECKBOXSTATUS.FULL : CHECKBOXSTATUS.NONE;

                  return (
                    <div
                      className="taa-chart__tas-checkbox-item"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      key={index}
                    >
                      <StudentTAAssigmentCheckbox
                        status={status}
                        style={{ height: 12, width: 12, borderRadius: 4 }}
                        onAssign={() => {
                          assignAllPapersHandler(taUid);
                        }}
                        onRemove={() => {
                          removeAllPapersHandler();
                        }}
                        type="TWO"
                      />
                      <div className="taa-chart__tas-name-container">
                        <div className="taa-chart__tas-ta-name">
                          {/* {
                            appUsers.allAdminsAndInstructors.find((ta) => ta.uid === taUid)
                              ?.username
                          } */}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="verifiedId">
                                {
                                  appUsers.allAdminsAndInstructors.find((ta) => ta.uid === taUid)
                                    ?.username
                                }
                              </Tooltip>
                            }
                          >
                            <div>
                              {truncate(
                                appUsers.allAdminsAndInstructors.find((ta) => ta.uid === taUid)
                                  ?.username || '',
                                15
                              )}
                            </div>
                          </OverlayTrigger>
                        </div>
                        <div className="taa-chart__tas-assigned">(Papers Assigned {count})</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
            <section
              className="taa-chart__cards"
              style={{
                overflowX: 'hidden',
                minWidth: '100%',
                width: 'fit-content',
              }}
            >
              {state.paginatedSegments[state.page - 1] &&
                state.paginatedSegments[state.page - 1].map((val, index) => {
                  return (
                    <StudentTAAssignmentCard
                      list={val.papers}
                      studentId={val.stuUid}
                      tasForExam={state.tasForExam}
                      assignTAsHandler={assignTAsHandler}
                      assignStudentAllPapersHander={assignStudentAllPapersHander}
                      removeStudentAllPapersHander={removeStudentAllPapersHander}
                      key={index}
                      isAllPaperMarked={val.isMarkedAllPapers}
                    />
                  );
                })}
            </section>
          </section>
          <PaginationNew
            pages={state.paginatedSegments.length}
            setCurrentPage={setCurrentPage}
            clear={state.trigger}
          />
        </Fragment>
      )}
    </section>
  );
};

export default ExamTAAssignmentChart;
