import React, {FC} from 'react';
import truncate from 'truncate';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import {Lesson} from '../models/Lesson';
import './LessonDropdownCardMultiple.scss';

interface Props {
  className: string;
  inputChangeHandler: (
    state: any,
    name: string,
    value: {
      displayValue: string;
      id: string;
    }
  ) => void;
  setState: (value: React.SetStateAction<boolean>) => void;
  id: string;
  state: any;
  stateName: string;
  noValueText: string;
  lesson?: Lesson;
  truncateNumber?: number;
  selected: boolean;
}

const LessonDropdownCardMultiple: FC<Props> = (props) => {
  const users = useAdminsAndInstructors();
  const user = props.lesson?.createdBy ? props.lesson?.createdBy : props.lesson?.updatedBy;
  const createdBy = users.allAdminsAndInstructors.find((u) => u.uid === user)?.username;

  return (
    <div
      className={`lesson-dropdown-card ${props.className}`}
      onClick={(e) => {
        props.inputChangeHandler(props.state, props.stateName, {
          displayValue: props.lesson?.name || '',
          id: props.lesson?.id || '',
        });

        props.setState(true);
        e.stopPropagation();
      }}
      id={props.id}
    >
      <div className="lesson-dropdown-card__img">
        <img src={`${props.lesson?.coverImage}`} alt="lesson" />
      </div>

      <div className="lesson-dropdown-card__right">
        <div className="lesson-dropdown-card__right-title">
          {props.lesson?.id === ''
            ? props.noValueText
            : truncate(props.lesson?.name || '', props.truncateNumber || 40)}
        </div>

        <div className="lesson-dropdown-card__right-instructor">By {createdBy}</div>
      </div>

      <div className="pretty p-default p-curve p-fill  ml-auto">
        <input
          type="checkbox"
          onChange={() => {
            // props.changeCorrectAnswerHandler && props.changeCorrectAnswerHandler(idx);
          }}
          checked={props.selected}
        />
        <div className="state p-primary correct-answer" style={{marginTop: 4}}>
          <label style={{fontSize: 16}}></label>
        </div>
      </div>
    </div>
  );
};

export default LessonDropdownCardMultiple;
