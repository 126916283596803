import firebase from 'firebase';
import { MCQCreateDTO, MCQUpdateDTO } from '../dtos/QuestionDTO';
import { PAPER_CREATION_MODE } from '../enums/PaperCreationMode';
import { PaperStatus } from '../enums/PaperStatus';
import { PaperTypes } from '../enums/paperTypes';
import { QuestionTypes } from '../enums/questionTypes';
import { DropDown, QuestionData, ResourcePaperState, ResourceQuestionsState } from '../interfaces';
import {
  atLeastOneCorrectAnswer,
  validateAnswerCount,
  validateLessons,
  validateMaxForMarks,
  validateMaxLength,
  validateMinForMarks,
  validateMinimumTime,
  validateMinLength,
  validateMinTotalAmount,
  validateString,
  isContainOnlyNumbers,
  validateStringSpaces,
} from '../validation/Validation';
import { validationResult } from './../utils';
import Notification from './../components/Notification';

const getQuestionByIdAndSetState = (
  id: string,
  setResourceState: (value: React.SetStateAction<ResourceQuestionsState>) => void
) => {
  const docRef = firebase.firestore().collection('questions').doc(id);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        setResourceState((ps: any) => {
          return {
            ...ps,
            loading: false,
            answers: {
              ...ps.answers,
              value: doc.data()?.answers?.map((a: any) => ({ ...a, error: '' })),
            },
            complexity: { value: doc.data()?.complexity },
            grade: {
              ...ps.grade,
              value: {
                id: doc.data()?.grade || '',
                displayValue: doc.data()?.grade
                  ? doc.data()?.grade[0]?.toUpperCase() + doc.data()?.grade.substring(1)
                  : '',
              },
            },
            question: { ...ps.question, value: doc.data()?.question },
            source: { ...ps.source, value: doc.data()?.source },
            resolveGuide: { ...ps.resolveGuide, value: doc.data()?.resolveGuide },
            sourceOfQuestion: {
              ...ps.sourceOfQuestion,
              value: {
                id: doc.data()?.sourceOfQuestion,
                displayValue: doc.data()?.sourceOfQuestion,
              },
            },
            trueFalseAnswer: { ...ps.trueFalseAnswer, value: doc.data()?.answer },
            createdBy: { value: doc.data()?.createdBy },
          };
        });
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
        setResourceState((ps: any) => ({ ...ps, loading: false }));
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
      setResourceState((ps: any) => ({ ...ps, loading: false }));
    });
};

const getPaperByIdAndSetState = (
  id: string,
  setPaperState: (value: React.SetStateAction<ResourcePaperState>) => void
) => {
  const docRef = firebase.firestore().collection('papers').doc(id);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        setPaperState((ps: any) => {
          return {
            ...ps,
            loading: false,
            paperTitle: { ...ps.paperTitle, value: doc.data()?.paperTitle },
            description: { ...ps.description, value: doc.data()?.description },
            grade: {
              ...ps.grade,
              value: {
                id: doc.data()?.grade || '',
                displayValue: doc.data()?.grade
                  ? doc.data()?.grade[0]?.toUpperCase() + doc.data()?.grade.substring(1)
                  : '',
              },
            },
            paperType: { ...ps.paperType, value: doc.data()?.paperType },
            totalNoOfQuestions: {
              ...ps.totalNoOfQuestions,
              value: doc.data()?.totalNoOfQuestions,
            },
            allocatedTime: {
              ...ps.allocatedTime,
              value: {
                hours: doc.data()?.allocatedTime.split(':')[0],
                minutes: doc.data()?.allocatedTime.split(':')[1],
              },
            },
            questions: { ...ps.questions, value: doc.data()?.questions },
            status: { ...ps.status, value: doc.data()?.status },
            paperCreationMode: {
              ...ps.paperCreationMode,
              value: {
                id: doc.data()?.paperCreationMode || '',
                displayValue: doc.data()?.paperCreationMode
                  ? doc.data()?.paperCreationMode[0]?.toUpperCase() +
                    doc.data()?.paperCreationMode.substring(1)
                  : '',
              },
            },

            createdBy: { value: doc.data()?.createdBy },
            active: { value: doc.data()?.active },
            marks: { ...ps.marks, value: doc.data()?.marks },
          };
        });
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
        setPaperState((ps: any) => ({ ...ps, loading: false }));
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
      setPaperState((ps: any) => ({ ...ps, loading: false }));
    });
};

const getStructuredEssayPaperByIdAndSetState = (
  id: string,
  setPaperState: (value: React.SetStateAction<ResourcePaperState>) => void
) => {
  const docRef = firebase.firestore().collection('structuredEssayPapers').doc(id);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        setPaperState((ps: any) => {
          return {
            ...ps,

            loading: false,
            paperTitle: { ...ps.paperTitle, value: doc.data()?.paperTitle },
            description: { ...ps.description, value: doc.data()?.description },
            grade: {
              ...ps.grade,
              value: {
                id: doc.data()?.grade || '',
                displayValue: doc.data()?.grade
                  ? doc.data()?.grade[0]?.toUpperCase() + doc.data()?.grade.substring(1)
                  : '',
              },
            },
            paperType: { ...ps.paperType, value: doc.data()?.paperType },
            totalNoOfQuestions: {
              ...ps.totalNoOfQuestions,
              value: doc.data()?.totalNoOfQuestions,
            },
            allocatedTime: {
              ...ps.allocatedTime,
              value: {
                hours: doc.data()?.allocatedTime.split(':')[0],
                minutes: doc.data()?.allocatedTime.split(':')[1],
              },
            },
            questions: { ...ps.questions, value: doc.data()?.questions },
            status: { ...ps.status, value: doc.data()?.status },
            paperCreationMode: {
              ...ps.paperCreationMode,
              value: {
                id: doc.data()?.paperCreationMode || '',
                displayValue: doc.data()?.paperCreationMode
                  ? doc.data()?.paperCreationMode[0]?.toUpperCase() +
                    doc.data()?.paperCreationMode.substring(1)
                  : '',
              },
            },
            createdBy: { value: doc.data()?.createdBy },
            active: { value: doc.data()?.active },
            paper: { ...ps.paper, value: doc.data()?.paperUrl, paperName: doc.data()?.paperName },
            markingScheme: {
              ...ps.markingScheme,
              value: doc.data()?.markingSchemeUrl,
              markingSchemeName: doc.data()?.markingSchemeName,
            },
            marks: { ...ps.marks, value: doc.data()?.marks },
          };
        });
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
        setPaperState((ps: any) => ({ ...ps, loading: false }));
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
      setPaperState((ps: any) => ({ ...ps, loading: false }));
    });
};

const getSubjects = (
  gradeId: string,
  createdBy: string,
  subjectId: string,
  setPaperState: (value: React.SetStateAction<ResourcePaperState>) => void,
  setSubjects: (
    value: React.SetStateAction<
      {
        id: string;
        displayValue: string;
      }[]
    >
  ) => void
) => {
  let subjects: DropDown[] = [];

  firebase
    .firestore()
    .collection('subjects')
    .where('grade', '==', gradeId)
    .where('createdBy', '==', createdBy || '')
    .get()
    .then((data) => {
      data.forEach((doc) => {
        subjects.push({ id: doc.id, displayValue: doc.data().name });
      });

      const selectedSubject = subjects.find((item) => item.id === subjectId);

      setPaperState((ps: any) => {
        return {
          ...ps,
          subject: {
            ...ps.subject,
            value: {
              id: selectedSubject?.id || '',
              displayValue: selectedSubject?.displayValue || '',
            },
          },
        };
      });
    });
  setSubjects(subjects);
};

const getLessons = (
  createdBy: string,
  subjectId: string,
  lessonId: string,
  setPaperState: (value: React.SetStateAction<ResourcePaperState>) => void,
  setLessons: (
    value: React.SetStateAction<
      {
        id: string;
        displayValue: string;
      }[]
    >
  ) => void
) => {
  let lessons: DropDown[] = [];
  firebase
    .firestore()
    .collection('lessons')
    .where('createdBy', '==', createdBy)
    .where('subjectId', '==', `${subjectId}`)
    .get()
    .then((data) => {
      data.forEach((doc) => {
        lessons.push({ id: doc.id, displayValue: doc.data().name });
      });

      // if (questionData?.lessonId) {
      const selectedLesson = lessons.find((item) => item.id === lessonId);

      setPaperState((ps) => {
        return {
          ...ps,
          lesson: {
            ...ps.lesson,
            value: {
              id: selectedLesson?.id || '',
              displayValue: selectedLesson?.displayValue || '',
            },
          },
        };
      });

      // }

      setLessons(lessons);
    });
};

const resetErrorStateToDefault = (
  setResourceState: (value: React.SetStateAction<ResourceQuestionsState>) => void
) => {
  setResourceState((ps) => {
    return {
      ...ps,
      loading: false,
      grade: { ...ps.grade, error: '' },
      subject: { ...ps.subject, error: '' },
      lesson: { ...ps.lesson, error: '' },
      question: { ...ps.question, error: '' },
      answers: {
        ...ps.answers,
        value: ps.answers?.value?.map((a) => {
          a.error = '';
          return a;
        }),
        error: '',
      },
      trueFalseAnswer: { ...ps.trueFalseAnswer, error: '' },
      sourceOfQuestion: { ...ps.sourceOfQuestion, error: '' },
      source: { ...ps.source, error: '' },
    };
  });
};

const saveHandlerMCQ = async (
  setResourceState: (value: React.SetStateAction<ResourceQuestionsState>) => void,
  resourceState: ResourceQuestionsState,
  questionData: QuestionData | null,
  appQuestions: any,
  changeToDefaultTabHandler: (
    value: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT',
    questionData: QuestionData | null
  ) => void,
  authorName: string,
  mustHaveUnits: boolean,
  hasUnits: boolean,
  scrollToTopHandler?: () => void
) => {
  const createMCQ = firebase.functions().httpsCallable('createMCQ');
  const updateMCQ = firebase.functions().httpsCallable('udpateMCQ');

  setResourceState((ps) => {
    return { ...ps, loading: true };
  });

  const validationOutput = validationResult({
    ...resourceState,
    unit: { ...resourceState.unit, validations: mustHaveUnits ? [validateString] : [] },
    loading: true,
  });

  //implmenting this code inside the validationResult function is complicated so did it here
  validationOutput.state.answers?.value?.map((a: any) => {
    if (
      !a.answerText
        .replace(/<[^>]+>/g, '')
        .replace(/&nbsp;/g, '')
        .trim().length
    ) {
      a.error = 'The answer cannot be empty';
      validationOutput.state.answers.error = 'The answer cannot be empty';
      validationOutput.formValidity = 'The answer cannot be empty';
    } else {
      a.error = 'NO-ERROR';
    }

    return a;
  });

  setResourceState(validationOutput.state);

  if (!hasUnits && mustHaveUnits) {
    Notification({
      isSuccess: false,
      message:
        'Looks like you have not created units for this lesson. Please create a unit and try again',
      autoClose: 7000,
    });
    setResourceState((pS) => ({
      ...pS,
      loading: false,
    }));
    return;
  }

  if (!!validationOutput.formValidity) {
    setResourceState((pS) => ({
      ...pS,
      loading: false,
    }));
    return;
  }

  scrollToTopHandler && scrollToTopHandler();

  if (questionData?.questionId) {
    const mcq: MCQUpdateDTO = {
      qid: questionData?.questionId,
      question: resourceState.question.value.replace(/&nbsp;/g, '').trim(),
      sourceOfQuestion: resourceState.sourceOfQuestion.value.id,
      complexity: resourceState.complexity.value,
      grade: resourceState.grade.value.id,
      subjectId: resourceState.subject.value.id,
      lessonId: resourceState.lesson.value.id,
      unitId: resourceState.unit.value.id,
      answers: resourceState.answers.value.map((a) => ({
        isCorrect: a.isCorrect,
        answerText: a.answerText,
      })),
      questionType: QuestionTypes.MCQ,
      source: resourceState.source.value,
      resolveGuide: resourceState.resolveGuide.value,
      labels: resourceState.labels.value.map((l) => l.id),
    };
    try {
      const response = await updateMCQ(mcq);

      appQuestions.fetchQtns();

      changeToDefaultTabHandler('PREVIEW', {
        questionId: response.data.data.id,
        grade: '',
        lessonId: '',
        subjectId: '',
        unitId: '',
        createdBy: '',
        labelIds: [],
      });
      Notification({
        isSuccess: true,
        message: 'MCQ question updated successfully',
      });
    } catch (e) {
      changeToDefaultTabHandler('VIEW', null);
      Notification({
        isSuccess: false,
        message: 'Failed to update question',
      });
    } finally {
      ResourceUtility.resetErrorStateToDefault(setResourceState);
    }
  } else {
    const mcq: MCQCreateDTO = {
      question: resourceState.question.value.replace(/&nbsp;/g, '').trim(),
      sourceOfQuestion: resourceState.sourceOfQuestion.value.id,
      complexity: resourceState.complexity.value,
      grade: resourceState.grade.value.id,
      subjectId: resourceState.subject.value.id,
      lessonId: resourceState.lesson.value.id,
      unitId: resourceState.unit.value.id,
      answers: resourceState.answers.value.map((a) => ({
        isCorrect: a.isCorrect,
        answerText: a.answerText,
      })),
      questionType: QuestionTypes.MCQ,
      source: resourceState.source.value,
      resolveGuide: resourceState.resolveGuide.value,
      authorName: authorName,
      labels: resourceState.labels.value.map((l) => l.id),
    };

    try {
      const response = await createMCQ(mcq);

      appQuestions.fetchQtns();

      changeToDefaultTabHandler('PREVIEW', {
        questionId: response.data.data.id,
        grade: '',
        lessonId: '',
        subjectId: '',
        unitId: '',
        createdBy: '',
        labelIds: [],
      });
      Notification({
        isSuccess: true,
        message: 'MCQ question created successfully',
      });
    } catch (e) {
      changeToDefaultTabHandler('VIEW', null);
      Notification({
        isSuccess: false,
        message: 'Failed to save question',
      });
    } finally {
      ResourceUtility.resetErrorStateToDefault(setResourceState);
    }
  }
};

const INITIAL_STATE: ResourceQuestionsState = {
  loading: false,
  grade: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Grade',
    type: 'dropdown',
  },
  subject: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Subject',
    type: 'dropdown',
  },
  lesson: {
    value: { displayValue: '', id: '' },
    error: '',
    label: 'Lesson',
    validations: [validateString],
    type: 'dropdown',
  },
  unit: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [],
    label: 'Unit',
    type: 'dropdown',
  },
  labels: {
    value: [],
    error: '',
    label: 'Label',
    type: 'dropdown',
  },
  question: {
    value: '',
    error: '',
    validations: [validateString, isContainOnlyNumbers, validateStringSpaces],
    label: 'Question',
  },
  answers: {
    value: [],
    error: '',
    validations: [validateAnswerCount, atLeastOneCorrectAnswer],
    label: 'Answer',
    type: 'answers',
  },
  trueFalseAnswer: { value: false, label: 'Question' },
  sourceOfQuestion: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Source of the Question',
    type: 'dropdown',
  },
  complexity: {
    value: 50,
  },
  source: {
    value: '',
    error: '',
    validations: [validateString],
    label: 'Source',
  },
  resolveGuide: {
    value: '',
    error: '',
    label: 'resolveGuide',
  },
  createdBy: {
    value: '',
  },
};

const INITIAL_STATE_PAPER: ResourcePaperState = {
  loading: { value: false },
  grade: {
    value: { displayValue: '', id: '' },
    error: '',
    label: 'Grade',
    validations: [validateString],
    type: 'dropdown',
  },
  subject: {
    value: { displayValue: '', id: '' },
    error: '',
    label: 'Subject',
    validations: [validateString],
    type: 'dropdown',
  },
  lesson: {
    value: { displayValue: '', id: '' },
    error: '',
    label: 'Lesson',
    type: 'dropdown',
  },
  lessons: {
    value: [],
    error: '',
    label: 'Lessons',
    validations: [validateLessons],
  },
  unit: {
    value: { displayValue: '', id: '' },
    error: '',
    label: 'Unit',
    type: 'dropdown',
  },
  units: {
    value: [],
    error: '',
    label: 'Units',
  },
  labels: {
    value: [],
    error: '',
    label: 'Lesson',
    type: 'dropdown',
  },

  paperTitle: {
    value: '',
    error: '',
    validations: [validateString, validateMaxLength, validateMinLength, isContainOnlyNumbers],
    label: 'Paper title',
    min: 3,
    max: 100,
  },
  description: {
    value: '',
    error: '',
    validations: [validateString, isContainOnlyNumbers, validateStringSpaces],
    label: 'Description',
  },
  totalNoOfQuestions: {
    value: '',
    error: '',
    validations: [validateString, validateMinTotalAmount],
    label: 'Total no of questions',
  },
  allocatedTime: {
    value: { hours: '', minutes: '' },
    error: '',
    validations: [validateString, validateMinimumTime],
    label: 'Allocated time',
    min: 30,
  },
  paperCreationMode: {
    value: { displayValue: PAPER_CREATION_MODE.AUTOMATIC, id: PAPER_CREATION_MODE.AUTOMATIC },
    type: 'dropdown',
  },
  paperType: {
    value: PaperTypes.MCQ,
  },
  questions: {
    value: [],
  },
  status: {
    value: PaperStatus.DRAFT,
  },

  createdBy: {
    value: '',
  },
  active: {
    value: true,
  },
  paper: {
    file: null,
    value: '',
    error: '',
    validations: [],
    fileUploadedProgress: 0,
    label: 'File Name',
    paperName: '',
    loading: false,
  },
  markingScheme: {
    file: null,
    value: '',
    error: '',
    validations: [],
    fileUploadedProgress: 0,
    label: 'File Name',
    markingSchemeName: '',
    loading: false,
  },
  marks: {
    value: '1',
    error: '',
    validations: [validateMaxForMarks, validateMinForMarks],
    label: 'Marks',
  },
};

const ResourceUtility = {
  getQuestionByIdAndSetState,
  getPaperByIdAndSetState,
  getStructuredEssayPaperByIdAndSetState,
  getSubjects,
  getLessons,
  resetErrorStateToDefault,
  saveHandlerMCQ,
  INITIAL_STATE,
  INITIAL_STATE_PAPER,
};

export default ResourceUtility;
