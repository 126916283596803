import React, {FC, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {FaEllipsisV, FaReply, FaTrashAlt} from 'react-icons/fa';
import {decodeHtml} from '../utils';
import BoxIcon from './BoxIcon';
import ComplexityIndicator from './ComplexityIndicator';
import './MCQQuestionCardSelectedSection.scss';
import QuestionPreviewModal from './QuestionPreviewModal';

interface Props {
  task: any;
  index: number;
  onRemove: (taskId: string) => void;
  onReplace: (taskId: string) => void;
}

const MCQQuestionCardSelectedSection: FC<Props> = (props) => {
  const [show, setShow] = useState(false);

  const openModalHandler = () => {
    setShow(true);
  };

  const closeModalHandler = () => {
    setShow(false);
  };

  return (
    <>
      <Draggable draggableId={props.task.id} index={props.index}>
        {(provided) => (
          <div
            className="mcq-qtn-paper-card-selected"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={openModalHandler}
          >
            <div className="mcq-qtn-paper-card-selected__main">
              <div className="mcq-qtn-paper-card-selected__index">{props.index + 1}</div>
              <div className="mcq-qtn-paper-card-selected__complexity">
                <ComplexityIndicator value={props.task.content.complexity} />
              </div>
              <div className="mcq-qtn-paper-card-selected__text">
                {decodeHtml(props.task.content.questionText.trim()) ||
                  'Question with visual content'}
              </div>
              <div className="mcq-qtn-paper-card-selected__actions">
                <BoxIcon
                  icon={FaReply}
                  color="#979797"
                  size={13}
                  style={{width: 30, height: 30, backgroundColor: '#404462'}}
                  onClick={() => {
                    props.onReplace(props.task.id);
                  }}
                />
                <BoxIcon
                  icon={FaTrashAlt}
                  color="#fff"
                  size={13}
                  style={{width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: 10}}
                  onClick={() => {
                    props.onRemove(props.task.id);
                  }}
                />
                <FaEllipsisV color="#5C5C5C" className="ml-2" />
              </div>
            </div>
          </div>
        )}
      </Draggable>
      <QuestionPreviewModal
        show={show}
        closeModalHandler={closeModalHandler}
        content={props.task.content}
      />
    </>
  );
};

export default MCQQuestionCardSelectedSection;
