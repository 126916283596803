import React, {FC, useEffect, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import './ComplexityRangeComp.scss';

interface Props {
  onChange: (value: number) => void;
  value?: number;
  activeOnClick?: boolean;
}

const ComplexityRangeComp: FC<Props> = (props) => {
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (props.value === undefined && props.activeOnClick) {
      setDisable(true);
    }
  }, [props.value, props.activeOnClick]);

  const content = (
    <div
      className={`d-flex align-items-center w-100 ${
        disable && props.activeOnClick ? 'disabled' : ''
      }`}
      onClick={() => {
        if (props.value === undefined) {
          props.onChange(0);
        }
        setDisable(false);
      }}
    >
      <label className="mr-1 pr-1 mb-0 complexity-easy">Easy</label>
      <div className="position-relative d-flex w-100">
        <div className="w-100">
          <input
            type="range"
            min="0"
            max="100"
            className="w-100 position-relative"
            step={25}
            onChange={(e) => {
              props.onChange(+e.target.value);
            }}
            value={props.value || 0}
          />
          <div className="d-flex justify-content-between position-absolute w-100 cut-container">
            <div></div>
            <div className="cut" style={{opacity: props.value === 25 ? 0 : 1}}></div>
            <div className="cut" style={{opacity: props.value === 50 ? 0 : 1}}></div>
            <div className="cut" style={{opacity: props.value === 75 ? 0 : 1}}></div>
            <div></div>
          </div>
        </div>
      </div>
      <label className="ml-1 pl-1 mb-0 complexity-hard">Hard</label>
    </div>
  );

  return disable ? (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="complexity-comp">click to activate</Tooltip>}
    >
      {content}
    </OverlayTrigger>
  ) : (
    content
  );
};

export default ComplexityRangeComp;
