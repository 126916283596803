import firebase from 'firebase';
import {PaperTypes} from '../enums/paperTypes';

const getPaperById = (
  questionId: string
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> => {
  return firebase.firestore().collection('papers').doc(questionId).get();
};

const getStructuredEssayPaperById = (
  questionId: string
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> => {
  return firebase.firestore().collection('structuredEssayPapers').doc(questionId).get();
};

const fetchAllMCQPapers = (): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> => {
  return firebase
    .firestore()
    .collection('papers')
    .orderBy('createdAt', 'desc')
    .where('paperType', '==', `${PaperTypes.MCQ}`)
    .get();
};

const fetchAllTFPapers = (): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> => {
  return firebase
    .firestore()
    .collection('papers')
    .orderBy('createdAt', 'desc')
    .where('paperType', '==', `${PaperTypes.TRUEFALSE}`)
    .get();
};

const getAllMCQTFPapers = (): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> => {
  return firebase.firestore().collection('papers').orderBy('createdAt', 'desc').get();
};

const getAllStructuredEssayPapers = (): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> => {
  return firebase
    .firestore()
    .collection('structuredEssayPapers')
    .orderBy('createdAt', 'desc')
    .get();
};

const PaperService = {
  getPaperById,
  getStructuredEssayPaperById,
  fetchAllMCQPapers,
  fetchAllTFPapers,
  getAllMCQTFPapers,
  getAllStructuredEssayPapers,
};

export default PaperService;
