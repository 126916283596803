import React, {FC} from 'react';

import './UnderConstruction.scss';
import {FaCaretLeft} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';

interface Props {
  style?: React.CSSProperties;
}

const UnderConstruction: FC<Props> = (props) => {
  const history = useHistory();

  return (
    <div className="construction-page" style={{...props.style, height: 'calc(100% - 100px)'}}>
      <div className="user-construction-img mt-5" style={{height: '40%'}}></div>

      <div className="under-construction " style={{marginTop: 40}}>
        <p className="sec1">This page is</p>
        <p className="sec2">Under Construction</p>
      </div>

      <div className="text-center " style={{marginTop: 60}}>
        <button
          className="back-btn"
          onClick={() => {
            history.push('/');
          }}
        >
          <FaCaretLeft
            className="icon"
            onClick={() => {
              history.push('/');
            }}
          />
          BACK
        </button>
      </div>
    </div>
  );
};

export default UnderConstruction;
