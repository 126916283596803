import React, {FC} from 'react';
import './UnitCardOptions.scss';

interface Props {
  setCollapse: (value: boolean) => void;
  isUnitDeletable: () => void;
  openUpdateModaHandler: (value: boolean) => void;
}

const UnitCardOptions: FC<Props> = (props) => {
  return (
    <>
      <div className="set-option-card">
        <div
          className="option-card"
          onMouseLeave={(e) => {
            props.setCollapse(false);
            e.stopPropagation();
          }}
        >
          <div
            className="option-card__text option-card__text_edit option-card__line"
            onClick={(e) => {
              props.openUpdateModaHandler(true);
              props.setCollapse(false);
              e.stopPropagation();
            }}
          >
            Edit Unit{' '}
          </div>

          <div
            className="option-card__text option-card__text_delete"
            onClick={(e) => {
              props.isUnitDeletable();
              props.setCollapse(false);
              e.stopPropagation();
            }}
          >
            Delete Unit{' '}
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitCardOptions;
