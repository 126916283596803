import React, {FC} from 'react';

interface Props {
  className?: string;
  time: any[];
}

const StudentExamStartsTimer: FC<Props> = (props) => {
  return (
    <div className="mt-lg-3 pt-lg-4 d-flex">
      <div
        style={{color: '#fff', fontSize: 20}}
        className={`ml-lg-4 ${props.time.length ? props.className : 'd-none'}`}
      >
        Exam starts in{' '}
      </div>
      <div
        style={{color: '#fff', fontSize: 20}}
        className={` ${props.time.length ? props.className : 'd-none'}`}
      >
        {props.time.map((t, idx) => {
          if (t) {
            let text;

            if (idx === 0) {
              text = 'D';
            } else if (idx === 1) {
              text = 'H';
            } else if (idx === 2) {
              text = 'M';
            } else {
              text = 'S';
            }

            return (
              <div className="ml-1" key={idx}>
                {t + text}
              </div>
            );
          } else {
            return <></>;
          }
        })}
      </div>
    </div>
  );
};

export default StudentExamStartsTimer;
