import React, {FC} from 'react';
import './ScoreTile.scss';

interface Props {
  score: string;
}

const ScoreTile: FC<Props> = (props) => {
  return (
    <div className="score-title">
      <div>{props.score}</div>
      <div>Grade</div>
    </div>
  );
};

export default ScoreTile;
