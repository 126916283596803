import React, { FC, Fragment, useEffect, useState } from 'react';
import { FaArchive, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './SelectedExamDashboardHeader.scss';
import { useFirestore, useFunctions } from 'reactfire';
import Popup from 'reactjs-popup';
import { PaperInfoData } from '../interfaces';
import ExamPapersPopUp from './ExamPapersPopUp';
import { Exam } from '../models/Exam';
import { useSubjects } from '../context/SubjectsContext';
import Backarrow from './Backarrow';
import BoxIcon from './BoxIcon';
import AlertPopup from './AlertPopup';
import Notification from './../components/Notification';
import { PaperTypes } from '../enums/paperTypes';
import { useAppUser } from '../context/UserContext';
import { StudentTAAssignmentInfo } from '../components/ExamTAAssignmentChart';
import { Paper } from '../models/Paper';
import { ExamType } from '../enums/ExamType';
import { useLessons } from '../context/LessonsContext';

interface Props {
  exam: Exam | null;
  loading: boolean;
  setLoading: (value: boolean) => void;
  setPapersInfoDataHandler: (value: PaperInfoData | null) => void;
  hierarchyLevel?: 'LESSON' | 'UNIT';
}

interface AlertState {
  confirmation: { title: string; msg: string } | null;
  operation: { title: string; msg: string } | null;
}

const initialStateAlertState = {
  confirmation: null,
  operation: null,
};

const SelectedExamDashboardHeader: FC<Props> = (props) => {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const store = useFirestore();

  const history = useHistory();

  const params = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
    examId: string;
  };

  const appLessons = useLessons();

  const lessonContainUnits = appLessons.lessons.find(
    (val) => val.id === params.lessonId
  )?.isLessonContainsUnits;

  const query = useQuery();

  const [backPath, setBackPath] = useState<string | null>(null);

  useEffect(() => {
    if (query.get('name')) {
      setBackPath(query.get('name'));
    }
  }, [query]);

  const functions = useFunctions();

  const subjectName = useSubjects().allSubjects.find((sub) => sub.id === params.subjectId)?.name;

  const deleteExamRef = functions.httpsCallable('deleteExam');

  const [alertState, setAlertState] = useState<AlertState>(initialStateAlertState);

  const btnStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75px',
    height: '30px',
    borderRadius: '4px',
    background: '#246BFD',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    marginLeft: '10px',
  };

  //methods
  const editClickhandler = () => {
    const examReachedEndTime =
      +new Date(props.exam?.finishTime || +new Date().toISOString()) <= +new Date();

    if (!props.loading) {
      if (props.exam?.usersExamState?.length || examReachedEndTime) {
        setAlertState((ps) => {
          return {
            ...ps,
            operation: {
              title: 'Attention',
              msg: examReachedEndTime
                ? 'This exam has ended, so unable to update'
                : "You can't update or delete an exam while it is in progress",
            },
          };
        });
      } else {
        if (params.unitId && params.lessonId && params.subjectId) {
          history.push(
            `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/update-exam/${params.examId}/Examinations`
          );
        } else if (params.lessonId && params.subjectId) {
          history.push(
            `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/update-exam/${params.examId}/Examinations`
          );
        } else {
          history.push(
            `/my-subjects/subject/${params.subjectId}/update-exam/${params.examId}/Examinations`
          );
        }
      }
    }
  };

  const deleteClickHandler = () => {
    props.setLoading(true);

    onExamDeleteHandler(params.examId);
    onClear();
  };

  const confirmationOpenhandler = () => {
    props.setLoading(true);
    if (!props.loading) {
      store
        .collection('exams')
        .doc(props.exam?.id || '')
        .get()
        .then((data) => {
          data.ref
            .collection('userExamCommencedTime')
            .get()
            .then((data1) => {
              props.setLoading(false);
              const exam = data.data() as Exam;
              if (
                +new Date(exam.finishTime || new Date()) <= +new Date() &&
                props.exam?.examType !== ExamType.EXTRA_EXAM
              ) {
                setAlertState((ps) => {
                  return {
                    ...ps,
                    operation: {
                      title: 'Attention',
                      msg: 'The exam has ended, so unable to delete',
                    },
                    confirmation: null,
                  };
                });
              } else if (data1.docs.length) {
                props.setLoading(false);
                setAlertState((ps) => {
                  return {
                    ...ps,
                    operation: {
                      title: 'Attention',
                      msg: `You can't update or delete an exam while it is in progress`,
                    },
                    confirmation: null,
                  };
                });
              } else {
                setAlertState((ps) => {
                  return {
                    ...ps,
                    confirmation: {
                      title: 'Attention',
                      msg: 'Are you sure you want to delete this exam?',
                    },
                  };
                });
              }
            })
            .catch(() => {
              props.setLoading(false);
            });
        })
        .catch(() => {
          props.setLoading(false);
        });
    }

    // if (!props.loading) {

    //   if (
    //     +new Date(props.exam?.finishTime || new Date()) < +new Date() &&
    //     props.exam?.examType !== ExamType.EXTRA_EXAM
    //   ) {
    //     setAlertState((ps) => {
    //       return {
    //         ...ps,
    //         operation: {
    //           title: 'Attention',
    //           msg: 'The exam has ended, so unable to delete',
    //         },
    //         confirmation: null,
    //       };
    //     });

    //   } else {
    //     setAlertState((ps) => {
    //       return {
    //         ...ps,
    //         confirmation: { title: 'Attention', msg: 'Are you sure you want to delete this exam?' },
    //       };
    //     });
    //   }

    // }
  };

  const onClear = () => {
    setAlertState((ps) => {
      return {
        ...ps,
        operation: null,
        confirmation: null,
      };
    });
  };

  const onGoBack = () => {
    console.log('props.hierarchyLevel  ', props.hierarchyLevel);
    if (props.hierarchyLevel === 'LESSON') {
      console.log('MOMOMOM');

      history.push(
        `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Examinations?name=${
          backPath || 'live'
        }`
      );
    } else if (props.hierarchyLevel === 'UNIT') {
      console.log('QQQQQQQQQQQQQQQQ');
      history.push(
        `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${
          params.unitId
        }/Examinations?name=${backPath || 'live'}`
      );
    } else {
      console.log('RRRRRRRRRRRRRRRR');
      history.push(
        `/my-subjects/subject/${params.subjectId}/Examinations?name=${backPath || 'live'}`
      );
    }
  };

  const onExamDeleteHandler = (ExamID?: string) => {
    if (ExamID) {
      props.setLoading(true);
      deleteExamRef({ ExamID })
        .then(() => {
          Notification({
            isSuccess: true,
            errorHeader: 'Exam Deleted Successfully.',
            message: 'Exam Deleted successfully.',
            id: 'tid',
          });

          onGoBack();
        })
        .catch((err) => {
          Notification({
            isSuccess: false,
            errorHeader: 'Exam Deletion Failed.',
            message: err.message,
            id: 'tid',
          });
          props.setLoading(false);
        });
    } else {
      Notification({
        isSuccess: false,
        errorHeader: 'Exam Deletion Failed.',
        message: 'Exam ID Not found.',
        id: 'tid',
      });
    }
  };

  const appUser = useAppUser();

  const showTAExamPaperHandler = (
    paper: Paper,
    uid: string,
    studentTAAssignmentInfo: StudentTAAssignmentInfo[]
  ) => {
    let flag: boolean = false;

    for (let i = 0; i < studentTAAssignmentInfo.length; i++) {
      for (let j = 0; j < studentTAAssignmentInfo[i].papers.length; j++) {
        if (
          studentTAAssignmentInfo[i].papers[j].assignedTAUid?.taUid === uid &&
          studentTAAssignmentInfo[i].papers[j].paperId === paper.id
        ) {
          flag = true;
          break;
        }
      }
    }

    return !flag;
  };

  return (
    <div className="sed-header mr-4">
      <Backarrow
        onClick={() => {
          if (appUser.firestoreUser?.userRole?.isTeachingAssistant) {
            history.push(`/my-subjects/subject/${params.subjectId}/Examinations`);
          } else {
            if (!props.hierarchyLevel) {
              onGoBack();
            } else if (lessonContainUnits) {
              onGoBack();
            } else {
              history.push(
                `/my-subjects/subject/${params.subjectId}/lesson/${
                  params.lessonId
                }/Content/Examinations?name=${backPath || 'live'}`
              );
            }
          }
        }}
      />
      <div className="sed-header__title">
        <div className="sed-header__title-header">Exam Title</div>
        <div className="sed-header__title-val">{props.exam?.examTitle}</div>
      </div>
      <div className="sed-header__subject">
        <div className="sed-header__subject-header">Subject</div>
        <div className="sed-header__subject-val">{subjectName}</div>
      </div>
      <div className="sed-header__grade">
        <div className="sed-header__grade-header">Grade</div>
        <div className="sed-header__grade-val">{props.exam?.grade}</div>
      </div>
      <div
        className="sed-header__actions"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <BoxIcon
          icon={FaArchive}
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#404462', marginRight: 10 }}
          color="#fff"
        />
        <BoxIcon
          icon={FaEdit}
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#404462', marginRight: 10 }}
          color="#fff"
          onClick={editClickhandler}
        />
        <BoxIcon
          icon={FaTrashAlt}
          size={13}
          style={{ width: 30, height: 30, backgroundColor: '#f05a5a' }}
          color="#fff"
          onClick={confirmationOpenhandler}
        />
        <Popup
          contentStyle={{ marginTop: '-5px' }}
          arrowStyle={{ display: 'none' }}
          position="bottom right"
          trigger={<Button style={btnStyles}>Grade</Button>}
        >
          {(close: any) => (
            <>
              {props.exam &&
                props.exam.papers
                  .filter(
                    (p1) =>
                      !(
                        (appUser?.firestoreUser?.userRole?.isTeachingAssistant &&
                          p1.paperType === PaperTypes.MCQ) ||
                        (appUser?.firestoreUser?.userRole?.isTeachingAssistant &&
                          p1.paperType === PaperTypes.TRUEFALSE) ||
                        showTAExamPaperHandler(
                          p1,
                          appUser.fireUser?.uid || '',
                          props.exam?.studentTAAssignmentInfo || []
                        )
                      ) || !appUser?.firestoreUser?.userRole?.isTeachingAssistant
                  )
                  .map((paper, index) => {
                    return (
                      <ExamPapersPopUp
                        paper={paper}
                        setPapersInfoDataHandler={props.setPapersInfoDataHandler}
                        onClose={close}
                        key={index}
                        examId={props.exam?.id || props.exam?.examId || ''}
                      />
                    );
                  })}
            </>
          )}
        </Popup>
      </div>
      <AlertPopup
        message={alertState.confirmation?.msg || ''}
        header={alertState.confirmation?.title || ''}
        isShow={!!alertState.confirmation}
        primaryButtonText="Yes"
        onOk={deleteClickHandler}
        onClose={() => {
          onClear();
        }}
      />
      <AlertPopup
        message={alertState.operation?.msg || ''}
        header={alertState.operation?.title || ''}
        isShow={!!alertState.operation}
        primaryButtonText="Yes"
        onOk={() => {
          onClear();
        }}
        onClose={() => {
          onClear();
        }}
        type="NO_BUTTON"
      />
    </div>
  );
};

export default SelectedExamDashboardHeader;
