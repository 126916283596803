import React, { FC } from 'react';
import { SubjectState } from '../screens/common/Subjects';
import FilterRadioButton from './FilterRadioButton';
import './SubjectModalFilter.scss';
import { GRADES_NUMERIC_LABELS } from '../constants';

interface Props {
  show: boolean;
  setState: Function;
  state: SubjectState;
  onApplyFilters: () => void;
}

const SubjectModalFilter: FC<Props> = (props) => {
  const getYears = () => {
    let currentYear = new Date().getFullYear();
    const endYear = currentYear + 3;
    let years: { name: string; label: string }[] = [];
    while (currentYear <= endYear) {
      years.push({ name: currentYear.toString(), label: currentYear.toString() });
      currentYear++;
    }
    return years;
  };

  return (
    <div className={`subject-modal-filter position-absolute ${props.show ? 'd-block' : 'd-none'}`}>
      <div className="fs-popup__grade">
        <div className="grade">Grade</div>
        <div className="d-flex">
          {GRADES_NUMERIC_LABELS.map((item, idx) => (
            <div key={idx}>
              <FilterRadioButton
                onClick={() => {
                  props.setState(() => {
                    return {
                      ...props.state,
                      filters: { ...props.state.filters, grade: item.name },
                    };
                  });
                }}
                item={item}
                value={props.state.filters.grade}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="fs-popup__year">
        <div className="year">Year</div>
        <div className="d-flex">
          {getYears().map((item, idx) => (
            <div key={idx}>
              <FilterRadioButton
                onClick={() => {
                  props.setState(() => {
                    return { ...props.state, filters: { ...props.state.filters, year: item.name } };
                  });
                }}
                item={item}
                value={props.state.filters.year}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="fq-popup__btns">
        <div
          onClick={() => {
            props.setState(() => {
              return { ...props.state, filters: { grade: null, year: null } };
            });
          }}
        >
          Clear Filters
        </div>
        <div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              props.setState((ps: any) => {
                return { ...ps, filterPopUpOn: false };
              });
            }}
          >
            Cancel
          </div>
          <button
            className="apply"
            onClick={() => {
              props.setState((ps: any) => {
                return { ...ps, filterPopUpOn: false };
              });

              props.onApplyFilters();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubjectModalFilter;
