import React, {FC} from 'react';
import truncate from 'truncate';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import {Lesson} from '../models/Lesson';
import './LessonDropdownCard.scss';

interface Props {
  className: string;
  inputChangeHandler: (
    state: any,
    name: string,
    value: {
      displayValue: string;
      id: string;
    }
  ) => void;
  setState: (value: React.SetStateAction<boolean>) => void;
  id: string;
  state: any;
  stateName: string;
  noValueText: string;
  lesson?: Lesson;
  truncateNumber?: number;
}

const LessonDropdownCard: FC<Props> = (props) => {
  const users = useAdminsAndInstructors();
  const user = props.lesson?.createdBy ? props.lesson?.createdBy : props.lesson?.updatedBy;
  const createdBy = users.allAdminsAndInstructors.find((u) => u.uid === user)?.username;

  return (
    <div
      className={`lesson-dropdown-card ${props.className}`}
      onClick={(e) => {
        props.inputChangeHandler(props.state, props.stateName, {
          displayValue: props.lesson?.name || '',
          id: props.lesson?.id || '',
        });
        props.setState(false);
        e.stopPropagation();
      }}
      id={props.id}
    >
      <div className="lesson-dropdown-card__img">
        <img src={`${props.lesson?.coverImage}`} alt="lesson" />
      </div>

      <div className="lesson-dropdown-card__right">
        <div className="lesson-dropdown-card__right-title">
          {props.lesson?.id === ''
            ? props.noValueText
            : truncate(props.lesson?.name || '', props.truncateNumber || 40)}
        </div>

        <div className="lesson-dropdown-card__right-instructor">By {createdBy}</div>
      </div>
    </div>
  );
};

export default LessonDropdownCard;
