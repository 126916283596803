import React, { useEffect, useState } from 'react';
import './EnrollToSubjectModal.scss';
import Modal from 'react-modal';
import { FaWindowClose } from 'react-icons/fa';
import { SubjectState } from '../screens/common/Subjects';
import { Subject } from '../models/Subject';
import { useAppUser } from '../context/UserContext';
import { useSubjects } from '../context/SubjectsContext';
import { enrollToSubject } from '../services/SubjectService';
import { sortSubjects } from '../utils';
import LoadingIndicator from './LoadingIndicator';
import SearchBox from './SearchBox';
import EnrollSubjectCard from './EnrollSubjectCard';
import Notification from './../components/Notification';
import { useFirestore } from 'reactfire';
import { UserFirestore } from '../models/UserFirestore';

interface Props {
  show: boolean;
  onClose: () => void;
  fetchSubjects: () => void;
  subjectsState: SubjectState;
  setSubjectState: (value: React.SetStateAction<SubjectState>) => void;
  setEnrollSearch: (text: string) => void;
}

interface State {
  subjects: Subject[];
  isLoading: boolean;
}

const EnrollToSubjectModal = (props: Props) => {
  //states
  const [state, setState] = useState<State>({ subjects: [], isLoading: false });
  const { firestoreUser } = useAppUser();
  const firestore = useFirestore();
  const [usersImageUrl, setUsersImageUrl] = useState<any[]>([]);

  //state handing methods
  const setIsLoading = (value: boolean) => {
    setState((ps) => ({
      ...ps,
      isLoading: value,
    }));
  };

  const setSubjects = (subjects: Subject[], userGrade?: string) => {
    setState((ps) => ({
      ...ps,
      subjects: subjects.filter((s) => s.grade === userGrade),
    }));
  };

  //destructuring
  const { subjects, isLoading } = state;

  const user = useAppUser();
  const subs = useSubjects();

  const [appSubjects, setAppSubjects] = useState(
    subs.allSubjects.filter((s) => !s.enrolledStudents?.includes(user.firestoreUser?.uid || ''))
  );

  useEffect(() => {
    setAppSubjects(
      subs.allSubjects.filter((s) => !s.enrolledStudents?.includes(user.firestoreUser?.uid || ''))
    );
  }, [subs.allSubjects, user.firestoreUser?.uid]);

  const fetchUsers = () => {
    if (props.show) {
      setState((ps) => ({
        ...ps,
        isLoading: true,
      }));

      firestore
        .collection('users')
        .orderBy('username')
        .get()
        .then((data) => {
          const usersImageUrls: any[] = [];

          data.forEach((user) => {
            const storeUser = { id: user.id, ...user.data() } as UserFirestore;
            if (!storeUser.userRole?.isStudent) {
              const userImageWithUid = {
                uid: storeUser.uid,
                userImageUrl: storeUser.photoURL || '',
              };
              usersImageUrls.push(userImageWithUid);
            }
          });

          setUsersImageUrl(usersImageUrls);
          setState((ps) => ({
            ...ps,
            isLoading: false,
          }));
        })
        .catch(() => {
          console.log('ERROR retriving users');
          setState((ps) => ({
            ...ps,
            isLoading: false,
          }));
        });
    }
  };

  useEffect(() => {
    fetchUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const onEnrollToSubject = (subjectID: string) => {
    if (user.firestoreUser) {
      setIsLoading(true);
      enrollToSubject(
        subjectID,
        (d) => {
          Notification({
            isSuccess: true,
            id: 'subject-enroll-success',
            message: 'Successfully enrolled to subject ',
          });

          setIsLoading(false);

          const removedArray = [...appSubjects].filter((val) => val.id !== subjectID);

          removedArray.push(d.data.data);

          setAppSubjects(removedArray);

          props.fetchSubjects();

          props.onClose();
        },
        (err) => {
          Notification({
            isSuccess: false,
            id: 'subject-enroll-error',
            message: err.message,
            errorHeader: 'Subject Enroll Error',
          });
          setIsLoading(false);
          console.log(err);
        }
      );
    }
  };

  useEffect(() => {
    let searchResult: any[] = [];
    let searchTextResult: any[] = [];

    searchResult = sortSubjects(appSubjects).filter(
      (value) => !value.enrolledStudents?.includes(user.fireUser?.uid || '')
    );

    if (props.subjectsState.enrollSearchText?.trim()) {
      if (props.subjectsState.enrollSearchText?.trim()) {
        searchTextResult = searchResult.filter((item) =>
          item.name.toUpperCase().startsWith(props.subjectsState.enrollSearchText.toUpperCase())
        );
      }

      setSubjects([...searchTextResult], firestoreUser?.grade);
    } else {
      setSubjects([...searchResult], firestoreUser?.grade);
    }
  }, [props.subjectsState.enrollSearchText, appSubjects, user.fireUser?.uid, firestoreUser?.grade]);

  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="enroll-subject-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      {isLoading && <LoadingIndicator />}

      <div className="enroll-subject-modal__header">
        <div>
          <SearchBox
            isFullWidth={true}
            searchClassName="w-90"
            onSearch={(v) => {
              props.setEnrollSearch(v);
            }}
            placeHolderText="Search Subject"
          />
        </div>
        <div>
          <FaWindowClose
            color="#FF4C6C"
            onClick={() => {
              if (!isLoading) {
                props.onClose();
              }
            }}
            size={25}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      <div className="enroll-subject-modal__list">
        {subjects && subjects?.length ? (
          subjects?.map((sub, index) => {
            const user = usersImageUrl?.find((item) => item.uid === sub.createdBy);
            const authorImage = user?.userImageUrl;

            return (
              <EnrollSubjectCard
                isLoading={isLoading}
                onEnroll={onEnrollToSubject}
                subject={sub}
                key={index}
                searchText={props.subjectsState.enrollSearchText}
                authorImage={authorImage}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default EnrollToSubjectModal;
