import React, {FC} from 'react';
import {useAdminsAndInstructors} from '../context/AdminsAndInstructors';
import './STULeaderboard.scss';
import STUStudentLeaderboardCard from './STUStudentLeaderboardCard';

interface Props {
  ranking: {active: boolean; position: number; score: number; uid: string}[];
  stillToMark: boolean;
  isPoint: boolean;
  totalPoints?: number;
}

const STULeaderboard: FC<Props> = (props) => {
  const appUsers = useAdminsAndInstructors();

  return (
    <div className="stu__leaderboard">
      <div className="d-flex justify-content-between mt-2">
        <span className="stu__leaderboard__heading mb-3">Student Leaderboard</span>
        {props.isPoint && (
          <span
            style={{
              color: '#8B8B8B',
              fontWeight: '400',
              fontSize: '12px',
            }}
          >
            {props.totalPoints && `Total Points: ${props.totalPoints}`}
          </span>
        )}
      </div>

      {props.ranking
        .filter((val) => val.score !== null || val.score !== undefined)
        .map((q, idx) => {
          const i = idx ? 'mt-2' : '';
          const j =
            idx === 1 ? '#F3B619' : idx === 2 ? '#F3B619' : idx === 3 ? '#8B8B8B' : '#F3B619';

          return q.uid ? (
            <STUStudentLeaderboardCard
              isPoint={props.isPoint}
              name={appUsers.allAdminsAndInstructors.find((u) => u.uid === q.uid)?.username || ''}
              uid={q.uid}
              position={q.position}
              score={q.score}
              className={i}
              active={q.active}
              colorOfPosition={{color: j}}
              key={idx}
            />
          ) : (
            <></>
          );
        })}

      {props.stillToMark ? (
        <div className="text-center mt-3 mb-2" style={{fontSize: 12, color: '#246bfd'}}>
          Paper marking is still in progress. <br /> Top students list might change after marking is
          completed.
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default STULeaderboard;
