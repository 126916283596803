import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './StudentLessonViewScreen.scss';
import { useLessons } from '../../context/LessonsContext';
import { Lesson } from '../../models/Lesson';
import { useAppUser } from '../../context/UserContext';
import { useFirestore } from 'reactfire';
import LoadingIndicator from '../../components/LoadingIndicator';

export const StudentLessonViewScreen = () => {
  const history = useHistory();
  const user = useAppUser().firestoreUser;
  const subjectId = history.location.pathname.split('/')[3];
  const appLessons = useLessons();
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const store = useFirestore();
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   appLessons.lessons.filter((lesson) => lesson.subjectId=== subjectId)
  //     .filter((lesson) => lesson.id && user?.authorizedContent?.includes(lesson.id))
  //     .forEach((lesson) => {
  //     setLessons((prev) => [...prev, lesson]);
  //   });
  // },[appLessons, subjectId, user?.authorizedContent]);

  useEffect(() => {
    setIsLoading(true);
    store
      .collection('lessons')
      .where('subjectId', '==', subjectId)
      .get()
      .then((lessonsData) => {
        const lessons = lessonsData.docs.map((data) => ({
          id: data.id,
          ...data.data(),
        })) as Lesson[];
        setLessons(lessons);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log('ERROR ', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLessons, subjectId, user?.authorizedContent]);

  return (
    <>
      <Container fluid>
        {isLoading ? <LoadingIndicator /> : <></>}
        <Row>
          <h1 className="stu-lessons-title">Lessons</h1>
        </Row>
        <div style={{ height: 'calc(100vh - 185px)', overflowY: 'auto', overflowX: 'hidden' }}>
          <Row>
            {lessons.map((lesson, idx) => (
              <Col className="stu-lessons-content" style={{}} md="auto" sm={4} key={idx}>
                <div
                  style={{ cursor: 'pointer' }}
                  className="stu-lessons-card"
                  onClick={() => {
                    if (lesson.isLessonContainsUnits) {
                      history.push(`${history.location.pathname}/${lesson.id}/My Units`);
                    } else {
                      history.push(`${history.location.pathname}/${lesson.id}/Content`);
                    }
                  }}
                >
                  <img
                    className="stu-lessons-card-img"
                    alt="lesson cover"
                    src={lesson.coverImage}
                  />
                  <h4 className="stu-lessons-card-title">{lesson.name}</h4>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
};
