import React, {createContext, FC, useContext} from 'react';
import {IconType} from 'react-icons';
import {FaHome} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {
  LESSON_SECONDARY_NAV_MAIN_ITEMS,
  SUBJECT_SECONDARY_NAV_MAIN_ITEMS,
  UNIT_SECONDARY_NAV_MAIN_ITEMS,
} from '../constants';
import {EXAM_CREATION_STEP, EXAM_TYPE_TABS, HIERARCHY} from '../enums';
import useSessionStorage from '../hooks/useSessionStorage';
import {Exam} from '../models/Exam';
import {useSubjects} from './SubjectsContext';

interface BreadCrumbData {
  name: string;
  onClick: () => void;
  icon?: IconType;
  routeId?: string;
}

export interface SecondaryNavigationData {
  label: HIERARCHY;
  currentTab: string;
}

export interface State {
  breadCrumbData: BreadCrumbData[];
  secondaryNavigationData: SecondaryNavigationData[] | null;
  examData: {
    step: EXAM_CREATION_STEP;
    tab: EXAM_TYPE_TABS | null;
  };
  activeScreen: HIERARCHY;
  isLoading: boolean;
  grade: string;
  selectedExam: Exam | null;
}

const initialSecondaryNavigationData = [
  {label: HIERARCHY.SUBJECT, currentTab: SUBJECT_SECONDARY_NAV_MAIN_ITEMS.LESSONS},
  {label: HIERARCHY.LESSON, currentTab: LESSON_SECONDARY_NAV_MAIN_ITEMS.MY_UNITS},
  {label: HIERARCHY.UNIT, currentTab: UNIT_SECONDARY_NAV_MAIN_ITEMS.CONTENT},
];

export interface SubjectsUIContext {
  data: State | null;
  isRefactored: boolean;
  addPath: (breadCrumbData: BreadCrumbData) => void;
  setCurrentTab: (tab: string) => void;
  onSelectUnit: (input: {name: string; onClick: () => void; routeId: string}) => void;
  onSelectLesson: (input: {name: string; onClick: () => void; routeId: string}) => void;
  onSelectSubject: (input: {name: string; onClick: () => void; routeId: string}) => void;
  onSelectExam: (exam: Exam) => void;
  changeExamStepHandler: (value: EXAM_CREATION_STEP, tab?: EXAM_TYPE_TABS | null) => void;
  setLoading: (value: boolean) => void;
  onGoBack: () => void;
  onClear: () => void;
  onRefactor: () => void;
  getSubjectId: () => string;
  getSubjectName: () => string;
  getLessonId: () => string;
  getLessonName: () => string;
  getUnitId: () => string;
}

const initialContext: SubjectsUIContext = {
  data: {
    breadCrumbData: [],
    secondaryNavigationData: initialSecondaryNavigationData,
    examData: {
      step: EXAM_CREATION_STEP.NONE,
      tab: null,
    },
    activeScreen: HIERARCHY.SUBJECT,
    isLoading: false,
    grade: '',
    selectedExam: null,
  },
  isRefactored: false,
  addPath: (breadCrumbData: BreadCrumbData) => {},
  setCurrentTab: (tab: string) => {},
  onSelectLesson: (input: {name: string; onClick: () => void; routeId: string}) => {},
  onSelectUnit: (input: {name: string; onClick: () => void; routeId: string}) => {},
  onSelectSubject: (input: {name: string; onClick: () => void; routeId: string}) => {},
  onSelectExam: (exam: Exam) => {},
  changeExamStepHandler: (value: EXAM_CREATION_STEP, tab?: EXAM_TYPE_TABS | null) => {},
  setLoading: (value: boolean) => {},
  onGoBack: () => {},
  onClear: () => {},
  onRefactor: () => {},
  getSubjectId: () => {
    return '';
  },
  getSubjectName: () => {
    return '';
  },
  getLessonId: () => {
    return '';
  },
  getLessonName: () => {
    return '';
  },
  getUnitId: () => {
    return '';
  },
};

const Context = createContext<SubjectsUIContext>(initialContext);

export const SubjectsUIProvider: FC = ({children}) => {
  const history = useHistory();
  const appSubjects = useSubjects();

  const routeToHome = () => {
    onClear();
    history.push('/');
  };

  const routeToMySubjects = () => {
    onClear();
    history.push('/');
  };

  const breadCrumbData: BreadCrumbData[] = [];
  breadCrumbData.push({icon: FaHome, name: 'Home', onClick: routeToHome});
  breadCrumbData.push({name: 'My Subjects', onClick: routeToMySubjects});

  const data: State = {
    breadCrumbData,
    secondaryNavigationData: initialSecondaryNavigationData,
    examData: {
      step: EXAM_CREATION_STEP.NONE,
      tab: null,
    },
    activeScreen: HIERARCHY.SUBJECT,
    grade: '',
    isLoading: false,
    selectedExam: null,
  };

  const [state, setState] = useSessionStorage('KEYSUBBB', {data: data, isRefactored: false});

  const addPath = (breadCrumbData: BreadCrumbData) => {
    setState((pS) => ({
      ...pS,
      breadCrumbData: [...state.data.breadCrumbData, breadCrumbData],
    }));
  };

  const setCurrentTab = (tab: string) => {
    const updatedState = {...state};
    if (updatedState.data.secondaryNavigationData) {
      const secondaryNavSegment = updatedState.data.secondaryNavigationData?.find(
        (s) => s.label === state.data.activeScreen
      )!;
      const index = updatedState.data.secondaryNavigationData?.indexOf(secondaryNavSegment!);
      secondaryNavSegment.currentTab = tab;
      updatedState.data.secondaryNavigationData[index] = secondaryNavSegment;
    }

    setState(updatedState);
  };

  const onGoBack = () => {
    const updatedState = {...state};

    if (state.data.activeScreen === HIERARCHY.UNIT) {
      updatedState.data.activeScreen = HIERARCHY.LESSON;

      updatedState.data.breadCrumbData.pop();
      return setState(updatedState);
    }

    if (state.data.activeScreen === HIERARCHY.LESSON) {
      updatedState.data.activeScreen = HIERARCHY.SUBJECT;

      updatedState.data.breadCrumbData.pop();
      return setState(updatedState);
    }

    if (state.data.activeScreen === HIERARCHY.SUBJECT) {
      updatedState.data.breadCrumbData.pop();
      setState(updatedState);
      return history.push('/my-subjects');
    }
  };

  const onSelectSubject = (input: {name: string; onClick: () => void; routeId: string}) => {
    const updatedState = {...state};
    const routeToSubject = () => {
      updatedState.data.breadCrumbData.splice(3);
      updatedState.data.activeScreen = HIERARCHY.SUBJECT;
      updatedState.data.grade =
        appSubjects.allSubjects.find((sub) => sub.id === input.routeId)?.grade || '';
      setState(updatedState);
    };

    const breadCrumb = {name: input.name, onClick: routeToSubject, routeId: input.routeId};
    updatedState.data.activeScreen = HIERARCHY.SUBJECT;

    if (updatedState.data.breadCrumbData.length && updatedState.data.breadCrumbData.splice(2))
      updatedState.data.breadCrumbData.push(breadCrumb);
    setState(updatedState);
  };

  const onSelectLesson = (input: {name: string; onClick: () => void; routeId: string}) => {
    const updatedState = {...state};
    const routeToLesson = () => {
      updatedState.data.breadCrumbData.splice(4);
      updatedState.data.activeScreen = HIERARCHY.LESSON;
      setState(updatedState);
    };
    const breadCrumb = {name: input.name, onClick: routeToLesson, routeId: input.routeId};
    updatedState.data.activeScreen = HIERARCHY.LESSON;

    updatedState.data.breadCrumbData.push(breadCrumb);
    setState(updatedState);
  };

  const onSelectUnit = (input: {name: string; onClick: () => void; routeId: string}) => {
    const updatedState = {...state};
    const breadCrumb = {name: input.name, onClick: input.onClick, routeId: input.routeId};
    updatedState.data.activeScreen = HIERARCHY.UNIT;

    updatedState.data.breadCrumbData.push(breadCrumb);
    setState(updatedState);
  };

  const onClear = () => {
    setState({...state, data: data});
  };

  const onRefactor = () => {
    setState({...state, isRefactored: !state.isRefactored});
  };

  const setLoading = (value: boolean) => {
    setState((ps) => ({
      ...ps,
      data: {...state.data, isLoading: value},
    }));
  };

  const onSelectExam = (exam: Exam) => {
    setState((ps) => ({
      ...ps,
      data: {...state.data, selectedExam: exam},
    }));
  };

  const changeExamStepHandler = (value: EXAM_CREATION_STEP, tab?: EXAM_TYPE_TABS | null) => {
    if (tab) {
      setState((ps) => ({
        ...ps,
        data: {...state.data, examData: {step: value, tab: tab}},
      }));
    } else {
      setState((ps) => ({
        ...ps,
        data: {...state.data, examData: {...state.data.examData, step: value}},
      }));
    }
  };

  const getSubjectId = () => {
    return state.data?.breadCrumbData[2]?.routeId || '';
  };

  const getSubjectName = () => {
    return state.data?.breadCrumbData[2]?.name || '';
  };

  const getLessonId = () => {
    return state.data?.breadCrumbData[3]?.routeId || '';
  };

  const getLessonName = () => {
    return state.data?.breadCrumbData[3]?.name || '';
  };

  const getUnitId = () => {
    return state.data?.breadCrumbData[4]?.routeId || '';
  };

  return (
    <Context.Provider
      value={{
        ...state,
        addPath,
        setCurrentTab,
        onGoBack,
        onSelectSubject,
        onSelectLesson,
        onSelectUnit,
        onSelectExam,
        changeExamStepHandler,
        onClear,
        onRefactor,
        setLoading,
        getSubjectId,
        getSubjectName,
        getLessonId,
        getLessonName,
        getUnitId,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppSubjectUI = (): SubjectsUIContext => useContext(Context);
