import React, {FC} from 'react';
import {FaExclamationTriangle} from 'react-icons/fa';

import './TAExamCardExtra.scss';

interface Props {
  onClick: () => void;
  examTitle: string;
  rejected: boolean;
  assignedTime?: firebase.firestore.Timestamp;
}

const TAExamCardExtra: FC<Props> = (props) => {
  // const date = convertTimeStampToDate(props.assignedTime);
  // const formatedDate = moment(date).format('lll');

  return (
    <div className="TA-assigned-papers-extra-exam" onClick={props.onClick}>
      <div
        className="TA-assigned-papers-extra-exam__exam-card"
        style={{border: props.rejected ? '0.5px solid #f05a5a' : ''}}
      >
        <div className="TA-assigned-papers-extra-exam__exam-card-exam-content">
          <div className="TA-assigned-papers-extra-exam__exam-card-exam-content-title">
            <span>{props.examTitle}</span>
          </div>
          {/* <div className="TA-assigned-papers-extra-exam__exam-card-exam-content-exam-assignedon">
            <span>Assigned on {formatedDate}</span>
          </div> */}
        </div>

        {props.rejected ? (
          <div className="TA-assigned-papers-extra-exam__exam-card-rejected-icon">
            <FaExclamationTriangle />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TAExamCardExtra;
