import React, {Fragment, useEffect, useState} from 'react';
import PackageList from '../../components/PackageList';
import PackageTypesTabs from '../../components/PackageTypesTabs';
import PrimaryNav from '../../components/PrimaryNav';
import SubjectDirectiveHeader from '../../components/SubjectDirectiveHeader';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import {PACKAGE_TYPE_TABS} from '../../enums';
import {PACKAGE_DIRECTIVE_STATE, packageDirectiveInitialState} from '../../interfaces';
import LayoutNew from '../../layouts/LayoutNew';
import PackageHeader from '../../components/PackageHeader';
import {PACKAGE_BREADCRUMB_STEPS} from '../../enums/packageBreadCrum';
import AdminPackageCreate from './AdminPackageCreate';
import {useParams} from 'react-router-dom';
import {useFunctions} from 'reactfire';

const AdminPackageListScreen = () => {
  const {subjectId} = useParams() as {subjectId: string};
  const [data, setData] = useState<PACKAGE_DIRECTIVE_STATE>(packageDirectiveInitialState);
  const [isAddPackage, setIsAddPackage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const changeTabHandler = (tab: PACKAGE_TYPE_TABS) => {
    setData((ps) => ({
      ...ps,
      tab: tab,
    }));
  };
  const loadingHandler = (e: boolean) => {
    setIsLoading(e);
  };

  let initialAppBody = (
    <Fragment>
      <PackageTypesTabs changeTabHandelr={changeTabHandler} tab={data.tab} />
      <PackageList tab={data.tab} loadingHandler={loadingHandler} />;
    </Fragment>
  );

  const initialAppHeader = (
    <SubjectDirectiveHeader
      onClickAddPackage={() => {
        setIsAddPackage(true);
      }}
    />
  );
  const [appBody, setAppBody] = useState(initialAppBody);
  const [appHeader, setAppHeader] = useState(initialAppHeader);
  const [secondaryNavigation, setSecondaryNavigation] = useState<JSX.Element | null>(
    <SubjectSecondaryNav />
  );
  const [packageCreateStep, setPackageCreateStep] = useState<PACKAGE_BREADCRUMB_STEPS>(
    PACKAGE_BREADCRUMB_STEPS.STEP_ONE
  );

  let primaryNavigation = <PrimaryNav />;
  const createPackage = useFunctions().httpsCallable('createPackage');

  const onPackageCreateStepOneSubmit = (data: any) => {
    createPackage(data)
      .then((res) => {
        setIsAddPackage(false);
        setPackageCreateStep(PACKAGE_BREADCRUMB_STEPS.STEP_ONE);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isAddPackage) {
      setAppBody(
        <AdminPackageCreate
          active={packageCreateStep}
          setStep={setPackageCreateStep}
          onSubmit={onPackageCreateStepOneSubmit}
          subjectId={subjectId}
        />
      );
      setAppHeader(
        <PackageHeader
          name={'Create New Package'}
          active={packageCreateStep}
          onBack={() => {
            setIsAddPackage(false);
          }}
        />
      );
      setSecondaryNavigation(null);
    } else {
      setAppBody(initialAppBody);
      setSecondaryNavigation(<SubjectSecondaryNav />);
      setAppHeader(initialAppHeader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddPackage, packageCreateStep, data]);

  return (
    <Fragment>
      <LayoutNew
        isLoading={isLoading}
        primaryNav={primaryNavigation}
        secondaryNav={secondaryNavigation}
        header={appHeader}
        body={appBody}
      />
    </Fragment>
  );
};

export default AdminPackageListScreen;
 