import {useState, useEffect} from 'react';

function getSavedValue<T extends string, K>(key: T, initialValue: K) {
  const savedValue = JSON.parse(sessionStorage.getItem(key)!);
  if (savedValue) return savedValue;

  if (initialValue instanceof Function) return initialValue();
  return initialValue;
}

export default function useSessionStorage<T extends string, K>(
  key: T,
  initialValue: K
): [K, React.Dispatch<React.SetStateAction<K>>] {
  const [value, setValue] = useState<K>(() => {
    return getSavedValue(key, initialValue);
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
}
