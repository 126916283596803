import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-modal';
import './AlertPopup.scss';

interface Iprops {
  message: string;
  onClose?: () => void;
  onOk?: () => void;
  isShow: boolean;
  isConformation?: boolean;
  primaryButtonText?: string;
  header?: string;
  type?: 'OK' | 'NO_BUTTON';
}
const AlertPopup = (props: Iprops) => {
  return (
    <Modal
      isOpen={props.isShow}
      onRequestClose={props.onClose}
      contentLabel="Example Modal"
      className="r-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <Row className="justify-content-between">
        <Col className="col-auto r-modal-header">{props.header || 'Alert'}</Col>
        <Col className="col-auto  mt-2 ">
          <button className="close-btn" onClick={props.onClose}>
            <span>&times;</span>
          </button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>{<div className="modal-body-text">{props.message}</div>}</Col>
      </Row>
      <Row className="justify-content-between mt-3">
        {props.type !== 'NO_BUTTON' &&
          (props.type === 'OK' ? (
            <Col className="col-auto ml-auto">
              <button onClick={props.onClose} className="model-btn  modal-btn-primary">
                OK
              </button>
            </Col>
          ) : (
            <>
              {' '}
              <Col className="col-auto">
                <button onClick={props.onOk} className="model-btn modal-btn-primary">
                  {props.primaryButtonText || 'OK'}
                </button>
              </Col>
              <Col className="col-auto">
                <button onClick={props.onClose} className="model-btn modal-btn-secondary">
                  {props.isConformation ? 'No' : 'Cancel'}
                </button>
              </Col>
            </>
          ))}
      </Row>
    </Modal>
  );
};

export default AlertPopup;
