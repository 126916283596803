import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ClipLoader} from 'react-spinners';
import {useAuth} from 'reactfire';
import {validationResult} from '../utils';
import {validatePassword} from '../validation/Validation';
import './ResetPassword.scss';
import Notification from './../components/Notification';
import MainLayout from '../layouts/MainLayout';
import PasswordInput from './PasswordInput';

const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const ResetPassword = () => {
  const history = useHistory();
  const auth = useAuth();

  const query = useQuery();

  const initialState = {
    loading: false,
    password: {
      value: '',
      error: '',
      validations: [validatePassword],
      label: 'Password',
    },
    confirmPassword: {
      value: '',
      error: '',
    },
  };

  const [state, setState] = useState(initialState);

  const submitHandle = async () => {
    const validationOutput = validationResult({...state, loading: true});

    setState(validationOutput.state);

    //guard - 1
    if (!!validationOutput.formValidity) {
      return setState((pS) => ({
        ...pS,
        loading: false,
      }));
    }

    //guard - 2
    if (!(state.password.value === state.confirmPassword.value)) {
      return setState((pS) => ({
        ...pS,
        confirmPassword: {...pS.confirmPassword, error: 'Passwords do not match'},
        loading: false,
      }));
    }

    try {
      await auth.confirmPasswordReset(query.get('oobCode') || '', state.password.value);
      Notification({
        isSuccess: true,
        message: 'Password reset successful! Login to continue',
      });
      history.push('/signin');
    } catch (e) {
      Notification({
        isSuccess: false,
        message: 'Error Resting Password',
      });

      setState((pS) => ({
        ...pS,
        loading: false,
      }));
    }
  };

  const enterKeyDownHandler = (evt: any) => {
    if (evt.key === 'Enter') {
      submitHandle();
    }
  };

  return (
    <MainLayout>
      <div className="reset-password">
        <div className="reset-password__title">Change Password</div>
        <div className="reset-password__subtitle">Create a new strong password</div>
        <PasswordInput
          placeHolder="Enter New Password"
          stateName="password"
          stateValue={state.password.value}
          state={state}
          setState={setState}
          error={state.password.error}
          errorStyles={state.password.error ? {marginBottom: 40} : {marginBottom: 0}}
          onKeyPress={enterKeyDownHandler}
        />

        <PasswordInput
          placeHolder="Confirm Password"
          stateName="confirmPassword"
          stateValue={state.confirmPassword.value}
          state={state}
          setState={setState}
          error={state.confirmPassword.error}
          className="reset-password-margin"
          onKeyPress={enterKeyDownHandler}
        />
        <div className="reset-password__btn" onClick={submitHandle}>
          <span className="d-inline-block mr-2">Continue</span>{' '}
          <ClipLoader color={'purple'} loading={state.loading} size={18} />
        </div>
      </div>
    </MainLayout>
  );
};

export default ResetPassword;
