import React, {FC} from 'react';
import useDimensions from 'use-element-dimensions';
import './QuestionBankHeader.scss';

interface Props {
  name: string;
  onClick?: () => void;
  questionIdPresent: boolean;
  loading: boolean;
 
}

const QuestionBankHeader: FC<Props> = (props) => {
  const [{width}, ref] = useDimensions();

  return (
    <div ref={ref}>
      <div className="header" style={{width: width}}>
        <div>{props.name}</div>
        <button
          onClick={() => {
            if (!props.loading) {
              props.onClick && props.onClick();
             
            }
          }}
          disabled={props.loading}
          className={props.loading ? 'disabled' : ''}
        >
          {props.questionIdPresent ? 'Update' : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default QuestionBankHeader;
