import React, { FC, useCallback, useEffect, useState } from 'react';
import { FaCheckCircle, FaChevronUp, FaReply, FaTrashAlt } from 'react-icons/fa';
import truncate from 'truncate';
import ComplexityIndicator from './ComplexityIndicator';
import renderHTML from 'react-render-html';
import './QuestionCardInPaper.scss';
import { ResourcePaperState } from '../interfaces';
import { useQuestions } from '../context/QuestionsContext';
import { decodeHtml, shuffle } from '../utils';
import { Question } from '../models/Question';
import BoxIcon from './BoxIcon';
import { QuestionTypes } from '../enums/questionTypes';
import AlertPopup from './AlertPopup';

interface Props {
  qtn: any;
  index: number;
  state: ResourcePaperState;
  setState: Function;
  isMCQ?: boolean;
  changeComplexityOnDelete?: (complexity: 0 | 25 | 50 | 75 | 100) => void;
}

const QuestionCardInPaper: FC<Props> = (props) => {
  const appQuestions = useQuestions();

  const onKeyDown = (evt: any) => {
    ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault();
  };

  const [open, setOpen] = useState({ show: false, message: '', header: '' });

  const openHander = (msg: string, header: string) => {
    setOpen((ps) => ({ ...ps, show: true, message: msg, header: header }));
  };

  const closeHander = () => {
    setOpen((ps) => ({ ...ps, show: false, message: '', header: '' }));
  };

  const [collapse, setCollapse] = useState(true);

  const renderedText = props.qtn.question?.replace(/<[^>]+>/g, '');

  useEffect(() => {
    if (props.qtn?.marks === undefined || props.qtn?.marks === null) {
      props.qtn.marks = 1;
    }
  }, [props.qtn]);

  const filterOutReleventQtnsForGradeSubjectLesson = useCallback(
    (qtns: Question[]) => {
      qtns = qtns.filter(
        (q) =>
          q.grade === props.state.grade.value.id &&
          q.subjectId === props.state.subject.value.id &&
          q.lessonId === props.state.lesson.value.id
      );
      return qtns;
    },
    [props.state.grade.value.id, props.state.lesson.value.id, props.state.subject.value.id]
  );

  return (
    <div className="q-p-card">
      <div className="q-p-card__main" style={!collapse ? { height: 'auto' } : {}}>
        <div className="q-p-card__index">{props.index + 1}</div>
        <div className="q-p-card__question-text">
          {collapse ? (
            <>{decodeHtml(truncate(renderedText.trim() || 'Question with visual content', 100))}</>
          ) : (
            <>
              <div>{renderHTML(props.qtn.question || '')}</div>

              {props.qtn.questionType === QuestionTypes.MCQ ? (
                props.qtn.answers.map((ans: any, index: number) => (
                  <div className="d-flex align-items-center">
                    <div style={{ width: 25 }}>
                      {ans.isCorrect ? <FaCheckCircle size={20} color="20D167" /> : <></>}
                    </div>
                    <span className="q-p-card-open-collapse-answer-no">{index + 1 + '.'}</span>
                    <div className="q-p-card-open-collapse-answer-text">
                      {renderHTML(ans.answerText)}
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="d-flex align-items-center">
                    <div style={{ width: 25 }}>
                      {props.qtn.answer ? <FaCheckCircle size={20} color="20D167" /> : <></>}
                    </div>
                    <span className="q-p-card-open-collapse-answer-no">{1 + '.'}</span>
                    <div className="q-p-card-open-collapse-answer-text">True</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div style={{ width: 25 }}>
                      {!props.qtn.answer ? <FaCheckCircle size={20} color="20D167" /> : <></>}
                    </div>
                    <span className="q-p-card-open-collapse-answer-no">{2 + '.'}</span>
                    <div className="q-p-card-open-collapse-answer-text">False</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className="q-p-card__left-side">
          <div className="q-p-card__complexity">
            <ComplexityIndicator value={props.qtn.complexity} />
          </div>
          <div className="q-p-card__actions">
            <BoxIcon
              icon={FaReply}
              color="#979797"
              size={13}
              style={{ width: 30, height: 30, backgroundColor: '#404462' }}
              onClick={() => {
                const currentQuestion = props.state.questions.value[props.index];

                const sateQtns = [...props.state.questions.value] as Question[];

                const selectedArrayElemeentWithSameComplexity = sateQtns.filter(
                  (i) => i.complexity === currentQuestion.complexity
                );

                const questionSet = props.isMCQ
                  ? filterOutReleventQtnsForGradeSubjectLesson(appQuestions.mcqQuestions)
                  : filterOutReleventQtnsForGradeSubjectLesson(
                      appQuestions.tfQuestions as Question[]
                    );

                const totalQtnsWithTheComplexity = questionSet.filter(
                  (i) => i.complexity === currentQuestion.complexity
                );

                let complexityText = '';

                if (currentQuestion.complexity === 0) {
                  complexityText = 'Very Low';
                }
                if (currentQuestion.complexity === 25) {
                  complexityText = 'Low';
                }
                if (currentQuestion.complexity === 50) {
                  complexityText = 'Medium';
                }
                if (currentQuestion.complexity === 75) {
                  complexityText = 'High';
                }
                if (currentQuestion.complexity === 100) {
                  complexityText = 'Very High';
                }

                if (selectedArrayElemeentWithSameComplexity >= totalQtnsWithTheComplexity) {
                  openHander(
                    `Only one question with complexity ${complexityText} is available`,
                    'Complexity out of bounds'
                  );
                  return;
                }

                const excludedIds = selectedArrayElemeentWithSameComplexity.map((i) => i.id);

                const candidates = totalQtnsWithTheComplexity.filter(
                  (i) => !excludedIds.includes(i.id)
                );

                const shuffledCandidates = shuffle(candidates);

                const value = shuffledCandidates[0];

                sateQtns[props.index] = value;

                return props.setState((ps: any) => ({
                  ...ps,
                  questions: { ...ps.questions, value: [...sateQtns] },
                }));
              }}
            />
            <BoxIcon
              icon={FaTrashAlt}
              color="#fff"
              size={13}
              style={{ width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: 15 }}
              onClick={() => {
                // showHandler();
                const currentQuestion = props.state.questions.value[props.index];

                const questions = props.state.questions.value as Question[];

                const val = questions.filter((q) => q.id !== currentQuestion.id);

                const currentQuestionComplexity = currentQuestion.complexity as
                  | 0
                  | 25
                  | 50
                  | 75
                  | 100;

                if (currentQuestion.complexity) {
                  props.changeComplexityOnDelete &&
                    props.changeComplexityOnDelete(currentQuestionComplexity);
                }

                props.setState((ps: any) => ({
                  ...ps,
                  questions: { ...ps.questions, value: val },
                }));
              }}
            />
          </div>
          <div className="q-p-card__marks">
            <input
              className="q-p-card__marks-input"
              onKeyDown={onKeyDown}
              onChange={(e) => {
                if (isNaN(+e.target.value) || +e.target.value > 100) {
                  const value = [...props.state.questions.value];

                  const targetQuestion = { ...value[props.index] };

                  targetQuestion.marks = 0;

                  value[props.index] = targetQuestion;

                  return props.setState((ps: any) => ({
                    ...ps,
                    questions: { ...ps.questions, value: value },
                  }));
                }

                const value = [...props.state.questions.value];

                const targetQuestion = { ...value[props.index] };

                targetQuestion.marks = Math.abs(+e.target.value);

                value[props.index] = targetQuestion;

                props.setState((ps: any) => ({
                  ...ps,
                  questions: { ...ps.questions, value: value },
                }));
              }}
              value={props.qtn.marks ?? 1}
            />
          </div>

          <FaChevronUp
            color="grey"
            onClick={(e) => {
              setCollapse(!collapse);
              e.stopPropagation();
            }}
            style={
              collapse
                ? { transform: 'rotate(90deg)', marginLeft: 6, alignSelf: 'center' }
                : { transform: 'rotate(0deg)', marginLeft: 6, alignSelf: 'center' }
            }
          />
        </div>
      </div>
      <AlertPopup
        message={open.message}
        header={open.header}
        isShow={open.show}
        onOk={closeHander}
        onClose={closeHander}
        type="OK"
      />
    </div>
  );
};

export default QuestionCardInPaper;
