import React, {Fragment, useState} from 'react';
import PackageList from '../../components/PackageList';
import PackageTypesTabs from '../../components/PackageTypesTabs';
import PrimaryNav from '../../components/PrimaryNav';
import SubjectDirectiveHeader from '../../components/SubjectDirectiveHeader';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import {PACKAGE_TYPE_TABS} from '../../enums';
import {packageDirectiveInitialState, PACKAGE_DIRECTIVE_STATE} from '../../interfaces';
import LayoutNew from '../../layouts/LayoutNew';

const InstructorPackageListScreen = () => {
  const [data, setData] = useState<PACKAGE_DIRECTIVE_STATE>(packageDirectiveInitialState);

  const changeTabHandler = (tab: PACKAGE_TYPE_TABS) => {
    setData((ps) => ({
      ...ps,
      tab: tab,
    }));
  };

  let primaryNavigation = <PrimaryNav />;
  let secondaryNavigation = <SubjectSecondaryNav />;
  let appHeader = <SubjectDirectiveHeader />;
  let appBody = (
    <Fragment>
      <PackageTypesTabs changeTabHandelr={changeTabHandler} tab={data.tab} />
      <PackageList tab={data.tab} />;
    </Fragment>
  );
  return (
    <Fragment>
      <LayoutNew
        primaryNav={primaryNavigation}
        secondaryNav={secondaryNavigation}
        header={appHeader}
        body={appBody}
      />
    </Fragment>
  );
};

export default InstructorPackageListScreen;
