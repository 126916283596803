import LessonSecondaryNav from '../../components/LessonSecondaryNav';
import PrimaryNav from '../../components/PrimaryNav';
import LayoutNew from '../../layouts/LayoutNew';
import BreadCrumbUpdate from '../../shared/BreadCrumbUpdate';
import StudentUnitViewScreen from './StudentUnitViewScreen';

const StudentUnitListScreen = () => {
  const primaryNav = <PrimaryNav />;
  const secondaryNavigation = <LessonSecondaryNav />;
  const body = <StudentUnitViewScreen />;
  let header = <BreadCrumbUpdate />;

  return (
    <LayoutNew
      header={header}
      primaryNav={primaryNav}
      secondaryNav={secondaryNavigation}
      body={body}
    />
  );
};

export default StudentUnitListScreen;
