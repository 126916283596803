import React, {FC} from 'react';
import {FaCheck, FaTimes} from 'react-icons/fa';
import TrueFalseAnswerCard from './TrueFalseAnswerCard';
import renderHTML from 'react-render-html';
import './QuestionResult.scss';
import MCQAnswerCard from './MCQAnswerCard';
import {Question} from '../models/Question';
import {QuestionTypes} from '../enums/questionTypes';
import {PaperQuestionDTO} from '../dtos/PaperQuestionDTO';

interface Props {
  className?: string;
  question?: Question;
  idx?: number;
  hideSelectedAnswer?: boolean;
}

const QuestionResult: FC<Props> = (props) => {
  let content: any = [];

  if (props.question?.questionType === QuestionTypes.MCQ) {
    const question = props.question as any as PaperQuestionDTO;

    content = question.answers.map((a, idx) => {
      return (
        <MCQAnswerCard
          hideSelectedAnswer={props.hideSelectedAnswer}
          answer={a}
          answerNo={idx}
          className="mb-2"
          static
          key={idx}
        />
      );
    });
  } else if (props.question?.questionType === QuestionTypes.TRUEFALSE) {
    const question = props.question as PaperQuestionDTO;
    if (question.answers) {
      content = question.answers.map((a, idx) => {
        return <TrueFalseAnswerCard answer={a} answerNo={idx} className="mb-2" key={idx} static />;
      });
    } else {
      content = (
        <>
          <TrueFalseAnswerCard
            answer={{answerText: 'True', isCorrect: question.answer}}
            answerNo={0}
            className="mb-2"
            key={0}
            static
          />
          <TrueFalseAnswerCard
            answer={{answerText: 'False', isCorrect: !question.answer}}
            answerNo={1}
            className="mb-2"
            key={1}
            static
          />
        </>
      );
    }
  }

  return (
    <div className={`question-result ${props.className} mb-5`}>
      <div className="question-result__question-no">{props.idx ? props.idx + 1 : 1}.</div>
      <div className="question-result__content">
        <div className="question-result__question">
          {renderHTML(props.question?.question || '')}
        </div>
        <div className="question-result__answer-cards">{content}</div>
        <div className="question-result__resolve-answer">
          {!props.hideSelectedAnswer && (
            <div className="question-result__icon">
              {props.question?.incorrect ? (
                <FaTimes color="#e3646c" />
              ) : (
                <FaCheck color="#049b04" />
              )}
            </div>
          )}

          <div className="question-result__resolve-content">
            {!props.hideSelectedAnswer && (
              <div>
                {props.question?.incorrect ? (
                  <span style={{color: '#e3646c'}}>Incorrect</span>
                ) : (
                  <span style={{color: '#049b04'}}>Correct</span>
                )}
              </div>
            )}
            <div>{props.question?.resolveGuide}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionResult;
